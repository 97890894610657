import React, { useReducer, memo, useState } from "react";
import JoyRide, { ACTIONS, EVENTS, STATUS } from "react-joyride";
import { InfoIcon } from "@fluentui/react-icons-northstar";
import { Button } from "@fluentui/react-northstar";
import { useLocation } from "react-router-dom";
import { withTranslation } from "react-i18next";
import { useEffect } from "react";

const MY_EVENTS_TOUR_STEPS = [
  {
    target: ".ant-layout-sider-zero-width-trigger",
    content:
      "Using this button, you can open and close the event menu, where you can discover events, create events, and view registered and bookmarked events.",
    device: "mobile",
    disableBeacon: true,
  },
  {
    target: ".tour-event-sidebar",
    content:
      "This is where you can Discover Event, Create Event, view Registered and Bookmaked Events.",
    device: "web",
    disableBeacon: true, // This makes the tour to start automatically without clicking
  },
  {
    target: ".tour-my-event",
    content:
      "This is where you can view all your created Events. You can also filter Active and Expired Events and use Column filter.",
  },
  {
    target: ".tour-my-event-create-event",
    content: "Click here to create new Event.",
  },
];
const MY_EVENTS_CREATE_EVENT_TOUR_STEPS = [
  {
    target: ".tour-create-event-left-details",
    content:
      "Here you can add Events details. You can also create this Event as Microsoft teams Event.",
    disableBeacon: true,
  },
  {
    target: ".tour-create-event-right-details",
    content:
      "This is where you can add Event Cover image, Event speakers, links and Attachments.",
  },
  {
    target: ".tour-my-event-schedule-event",
    content: "Click here to schedule this Event.",
  },
];

const DISCOVER_EVENTS_TOUR_STEPS = [
  {
    target: ".ant-layout-sider-zero-width-trigger",
    content:
      "Using this button, you can open and close the event menu, where you can discover events, create events, and view registered and bookmarked events.",
    device: "mobile",
    disableBeacon: true,
  },
  {
    target: ".tour-event-sidebar",
    content:
      "This is where you can Discover Event, Create Event, view Registered and Bookmaked Events.",
    device: "web",
    disableBeacon: true, // This makes the tour to start automatically without clicking
  },
  {
    target: ".tour-discover-event",
    content:
      "This is where you can view Events.You can join Event or Register for Event.",
  },
  {
    target: ".tour-discover-event-filter",
    content: "Here you can filter Live Events and use Column filter.",
  },
];

const REGISTERED_EVENTS_TOUR_STEPS = [
  {
    target: ".ant-layout-sider-zero-width-trigger",
    content:
      "Using this button, you can open and close the event menu, where you can discover events, create events, and view registered and bookmarked events.",
    device: "mobile",
    disableBeacon: true,
  },
  {
    target: ".tour-event-sidebar",
    content:
      "This is where you can Discover Event, Create Event, view Registered and Bookmaked Events.",
    device: "web",
    disableBeacon: true, // This makes the tour to start automatically without clicking
  },
  {
    target: ".tour-registered-event",
    content: "This is where you can view Events, in which you have Registered.",
  },
  {
    target: ".tour-registered-event-filter",
    content: "Here you can use Column filter.",
  },
];
const FAVORITE_EVENTS_TOUR_STEPS = [
  {
    target: ".ant-layout-sider-zero-width-trigger",
    content:
      "Using this button, you can open and close the event menu, where you can discover events, create events, and view registered and bookmarked events.",
    device: "mobile",
    disableBeacon: true,
  },
  {
    target: ".tour-event-sidebar",
    content:
      "This is where you can Discover Event, Create Event, view Registered and Bookmaked Events.",
    device: "web",
    disableBeacon: true, // This makes the tour to start automatically without clicking
  },
  {
    target: ".tour-bookmarked-event",
    content: "This is where you can view Events, that you have Bookmarked.",
  },
  {
    target: ".tour-bookmarked-event-filter",
    content: "Here you can use Column filter.",
  },
];

// Tour component
const Tour = ({ t, language }) => {
  const location = useLocation();

  const translation = t("events");
  const tourTranslation = translation.body.tour;

  // const [isMobileDevice, setIsMobileDevice] = useState(false);

  // useEffect(() => {
  //   window.addEventListener("resize", () => {
  //     setIsMobileDevice(window.innerWidth <= 479);
  //   });

  //   return () => {
  //     window.removeEventListener("resize", () => {});
  //   };
  // }, []);

  const getFormattedTourStep = ({
    steps,
    mobileStepTranslation,
    webStepTranslation,
  }) => {
    const isMobileDevice = window.innerWidth <= 479;
    let formattedSteps = steps
      .filter((item) =>
        isMobileDevice ? item.device !== "web" : item.device !== "mobile"
      )
      .map((item, index) => ({
        ...item,
        content: isMobileDevice
          ? mobileStepTranslation[index]
          : webStepTranslation[index],
      }));
    return formattedSteps;
  };

  const getTourSteps = () => {
    let currentPath = location.pathname;
    if (currentPath === "/events/my-events") {
      return getFormattedTourStep({
        steps: MY_EVENTS_TOUR_STEPS,
        mobileStepTranslation: tourTranslation.mobileMyEvents,
        webStepTranslation: tourTranslation.myEvents,
      });
    } else if (currentPath === "/event/new") {
      return MY_EVENTS_CREATE_EVENT_TOUR_STEPS.map((item, index) => ({
        ...item,
        content: tourTranslation.createEvent[index],
      }));
    } else if (currentPath === "/events/discover") {
      return getFormattedTourStep({
        steps: DISCOVER_EVENTS_TOUR_STEPS,
        mobileStepTranslation: tourTranslation.mobileDiscoverEvent,
        webStepTranslation: tourTranslation.discoverEvent,
      });
    } else if (currentPath === "/events/registrations") {
      return getFormattedTourStep({
        steps: REGISTERED_EVENTS_TOUR_STEPS,
        mobileStepTranslation: tourTranslation.mobileRegistered,
        webStepTranslation: tourTranslation.registered,
      });
    } else if (currentPath === "/events/favourites") {
      return getFormattedTourStep({
        steps: FAVORITE_EVENTS_TOUR_STEPS,
        mobileStepTranslation: tourTranslation.mobileFavorite,
        webStepTranslation: tourTranslation.favorite,
      });
    } else {
      return getFormattedTourStep({
        steps: MY_EVENTS_TOUR_STEPS,
        mobileStepTranslation: tourTranslation.mobileMyEvents,
        webStepTranslation: tourTranslation.myEvents,
      });
    }
  };

  const INITIAL_STATE = {
    key: new Date(), // This field makes the tour to re-render when we restart the tour
    run: false,
    continuous: true,
    loading: false,
    stepIndex: 0,
    steps: getTourSteps(),
  };

  const reducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
      case "START":
        return { ...state, run: true };
      case "RESET":
        return { ...state, stepIndex: 0 };
      case "STOP":
        return { ...state, run: false };
      case "NEXT_OR_PREV":
        return { ...state, ...action.payload };
      case "RESTART":
        return {
          ...state,
          stepIndex: 0,
          run: true,
          loading: false,
          key: new Date(),
        };
      case "CHANGE_STEPS":
        return { ...state, steps: action.payload };
      default:
        return state;
    }
  };

  // Reducer will manage updating the local state
  const [tourState, dispatch] = useReducer(reducer, INITIAL_STATE);

  useEffect(() => {
    dispatch({ type: "CHANGE_STEPS", payload: getTourSteps() });
  }, [language]);

  const setTourViewed = () => {
    // localStorage.setItem("tour", "1");
  };

  const callback = (data) => {
    const { action, index, type, status } = data;

    if (
      // If close button clicked, then close the tour
      action === ACTIONS.CLOSE ||
      // If skipped or end tour, then close the tour
      (status === STATUS.SKIPPED && tourState.run) ||
      status === STATUS.FINISHED
    ) {
      setTourViewed();
      dispatch({ type: "STOP" });
    } else if (type === EVENTS.STEP_AFTER || type === EVENTS.TARGET_NOT_FOUND) {
      // Check whether next or back button click and update the step.
      dispatch({
        type: "NEXT_OR_PREV",
        payload: { stepIndex: index + (action === ACTIONS.PREV ? -1 : 1) },
      });
    }
  };

  const startTour = () => {
    // Start the tour manually
    dispatch({ type: "RESTART" });
  };

  return (
    <>
      {/* <li className="items-center" onClick={}>
        <button className="text-gray-800 hover:text-gray-600 text-xs uppercase py-3 font-bold block">
          <i className="fas fa-fingerprint text-gray-500 mr-2 text-sm"></i>{" "}
          Start tour
        </button>
      </li> */}
      <Button
        icon={<InfoIcon circular outline size="large" />}
        iconOnly
        title={tourTranslation.tour}
        onClick={startTour}
      />
      <JoyRide
        {...tourState}
        callback={callback}
        // showSkipButton={true}
        styles={{
          tooltipContainer: {
            textAlign: "left",
          },
          buttonBack: {
            marginRight: 10,
          },
          buttonNext: {
            borderRadius: 4,
            color: "red",
            border: "1px solid #EB5757",
            backgroundColor: "#fff",
          },
        }}
        locale={{
          last: tourTranslation.endTour,
          next: tourTranslation.next,
          back: tourTranslation.back,
        }}
        showProgress
      />
    </>
  );
};

export default withTranslation()(memo(Tour));
