import React, { useState } from "react";
import "./BlockedDates.scss";
import Calendar from "../../../Calender/Calender";
import { Button, Flex, Loader, Text } from "@fluentui/react-northstar";
import { useBooking } from "../../../../../../context/BookingContext";
import axios from "axios";
import { toast } from "react-toastify";
import moment from "moment";
import { useTranslation } from "react-i18next";
import BlockedDatesCalender from "../../../Calender/BlockedDatesCalender";

const BlockedDates = () => {
  const { t } = useTranslation();
  const { blockedDates, user } = useBooking();
  const [isLoading, setIsLoading] = useState(false);
  const handleSaveBlockedDates = async () => {
    setIsLoading(true);
    try {
      const payload = {
        blockedDates: blockedDates
          .map((date) => moment.utc(date).format("YYYY-MM-DD"))
          .join(","),
      };
      const res = await axios.post(
        `${process.env.REACT_APP_EP_URL}/api/tutor/add-blocked-dates${user.slug}`,
        payload,
        {
          headers: {
            Authorization: "Bearer " + user.accessToken,
          },
        }
      );
      if (res.data) {
        toast.success("Blocked dates saved successfully!");
      }
    } catch (error) {
      toast.error("Failed to save blocked dates!");
    } finally {
      setIsLoading(false);
    }
  };

  const translation = t("booking").setting.blockedDatesTab;
  return (
    <Flex column gap="gap.small">
      <Text content={translation.message} />
      <Flex
        column
        hAlign="end"
        gap="gap.medium"
        style={{ width: "fit-content" }}
      >
        {/* <Calendar /> */}
        <BlockedDatesCalender />
        <Button primary onClick={handleSaveBlockedDates} disabled={isLoading}>
          {isLoading ? (
            <Flex gap="gap.small" vAlign="center">
              <Text content={`${t("booking").setMyAvailability.saving}...`} />
              <Loader size="small" />
            </Flex>
          ) : (
            translation.saveBlockedDates
          )}
        </Button>
      </Flex>
    </Flex>
  );
};

export default BlockedDates;
