import { IconButton } from "@fluentui/react";
import { Flex, Loader, Text } from "@fluentui/react-northstar";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { withTranslation } from "react-i18next";
import { useHistory, useParams } from "react-router-dom";
import RubricSection from "./RubricSection";

const Rubric = (props) => {
  const {
    user,
    currentAssignmentData,
    rubricData,
    havePoints,
    isLoading,
    selectedLevels,
    t,
  } = props;

  const { assignmentId, subjectCode } = useParams();
  const history = useHistory();

  const translation = t("assignment").body;
  const rubricPageTranslation = translation.rubricPage;

  // const [selectedLevels, setSelectedLevels] = useState(0);
  // const [rubricData, setRubricData] = useState(null);
  // const [havePoints, setHavePoints] = useState(false);
  // const [isLoading, setIsLoading] = useState(false);

  // useEffect(() => {
  //   const fetchData = async () => {
  //     if (currentAssignmentData?.rubricId) {
  //       const rubricData = await fetchRubricData(
  //         currentAssignmentData.rubricId
  //       );
  //       if (rubricData) {
  //         setRubricData(rubricData.rubricItems);
  //         setSelectedLevels(rubricData.rubricItems?.length);
  //         setHavePoints(
  //           rubricData?.rubricItems?.length
  //             ? rubricData?.rubricItems[0]?.havePoints
  //             : false
  //         );
  //       }
  //     }
  //   };
  //   fetchData();
  // }, [assignmentId, subjectCode]);

  // const fetchRubricData = async (id) => {
  //   setIsLoading(true);
  //   try {
  //     let response = await axios.get(
  //       // `${url}/api/rubric/get-rubric-bank-item${props.user.slug}`,
  //       `${process.env.REACT_APP_EP_URL}/api/rubric/get-rubric-by-rubricId${props.user.slug}&rubricId=${id}`,
  //       {
  //         headers: {
  //           Authorization: "Bearer " + user.accessToken,
  //         },
  //       }
  //     );
  //     if (response.data.status == "success") {
  //       let rubricData = response.data.results;
  //       return Object.keys(rubricData).length
  //         ? {
  //             ...rubricData,
  //             rubricItems: rubricData.rubricItem?.map((item) => ({
  //               ...item,
  //               havePoints: !!item.havePoints,
  //               id: item.rubricItemId,
  //               levels: item.rubricCriteria?.map((criterialData) => ({
  //                 ...criterialData,
  //                 id: criterialData.criteriaId,
  //                 levelTitle: criterialData.criteriaTitle,
  //               })),
  //               title: item.rubricItemTitle,
  //             })),
  //           }
  //         : null;
  //     }
  //   } catch (error) {
  //     console.log("error", error);
  //   } finally {
  //     setIsLoading(false);
  //   }
  // };

  return (
    <div
      className="student-rubric p-2"
      style={{ marginTop: "10px", marginBottom: "15px" }}
    >
      <Flex vAlign="center" style={{ marginLeft: "5px", marginTop: "10px" }}>
        <IconButton
          iconProps={{ iconName: "ChromeBack" }}
          title={translation.back}
          ariaLabel={translation.back}
          onClick={() => {
            history.push({
              pathname: `/${subjectCode}/assignments/${assignmentId}`,
            });
          }}
        />
        <Text
          color="brand"
          size="large"
          weight="semibold"
          style={{ marginRight: "10px" }}
        >
          {rubricPageTranslation.backToAssignment}
        </Text>
      </Flex>
      {isLoading ? (
        <Loader size="medium" />
      ) : rubricData?.length ? (
        <RubricSection {...{ rubricData, havePoints, selectedLevels }} />
      ) : (
        <div>
          <Flex fill hAlign="center" style={{ marginTop: "50px" }}>
            <Text weight="regular" size="large">
              {rubricPageTranslation.noRubricDataAvailable}
            </Text>
          </Flex>
        </div>
      )}
    </div>
  );
};

export default withTranslation()(Rubric);
