import { useState, useEffect, useCallback, useMemo } from "react";
import axios from "axios";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";
import { ValidationState } from "@fluentui/react";

const url = process.env.REACT_APP_EP_URL;

const AddMemberTabTypes = {
  addStudent: "addStudent",
  addMemberByNameEmail: "addMemberByNameEmail",
};

const useAddMember = ({
  user,
  selectedStudentByNameEmail,
  setSelectedStudentByNameEmail,
  allStudentsForGroup,
}) => {
  const [searchByNameQuery, setSearchByNameQuery] = useState("");
  const [debouncedSearchByNameQuery, setDebouncedSearchByNameQuery] =
    useState("");
  const [isLoadingDataByName, setIsLoadingDataByName] = useState(false);
  const [selectedAddMemberTab, setSelectedAddMemberTab] = useState(
    AddMemberTabTypes.addStudent
  );

  const [fetchedMembers, setFetchedMembers] = useState([]);

  const [peopleList, setPeopleList] = useState([]);

  const { t } = useTranslation();
  const translation = t("feedbackModule");
  const commonTranslation = translation.common;

  const feedbackTranslation = translation.body.group;

  useEffect(() => {
    fetchDataByNameHandler(debouncedSearchByNameQuery);
  }, [debouncedSearchByNameQuery]);

  const debounce = (fn, delay) => {
    let timeoutId = null;

    return function (...args) {
      const context = this;
      if (timeoutId) {
        clearTimeout(timeoutId);
      }
      timeoutId = setTimeout(() => {
        fn.apply(context, args);
      }, delay);
    };
  };

  const nameQuerySearchHandler = (value) => {
    setDebouncedSearchByNameQuery(value);
  };

  const debouncedSearchByNameHandler = useCallback(
    debounce(nameQuerySearchHandler, 500),
    []
  );

  const fetchDataByNameHandler = async (query) => {
    try {
      setIsLoadingDataByName(true);
      const { data } = await axios.get(
        `${url}/api/organization-users${user.slug}&search=${query}&limit=10`,
        {
          headers: {
            Authorization: `Bearer ${user.accessToken}`,
          },
        }
      );

      if (Array.isArray(data?.data)) {
        setFetchedMembers([...data.data]);
      }
      const formattedMembers = selectedStudentByNameEmail?.length
        ? selectedStudentByNameEmail.map((item) => item.email)
        : [];
      return data.data?.length
        ? data.data
            .map((item, index) => ({
              header: `${item.firstName} ${item.lastName}`,
              image: item.photo ?? null,
              content: `${item.email}`,
              email: item.email,
              key: item.email,
              role: item.role,
              userID: item.userID,

              imageInitials: `${item.firstName?.[0] ?? ""}${
                item.lastName?.[0] ?? ""
              }`,
              imageUrl: item.photo ?? null,
              key: item.userID,
              secondaryText: item.role,
              text: `${item.firstName} ${item.lastName}`,
            }))
            .filter((data) => !formattedMembers.includes(data.email))
        : [];
    } catch (error) {
      console.log("error", error);
      return [];
    } finally {
      setIsLoadingDataByName(false);
    }
  };

  // const membersList = useMemo(() => {
  //   const formattedMembers = selectedStudentByNameEmail?.length
  //     ? selectedStudentByNameEmail.map((item) => item.email)
  //     : [];
  //   return fetchedMembers?.length
  //     ? fetchedMembers
  //         .map((item, index) => ({
  //           // header: `${item.firstName} ${item.lastName}`,
  //           // image: item.photo ?? null,
  //           // content: `${item.email}`,
  //           email: item.email,
  //           // key: item.email,
  //           // avatar: true,
  //           // role: item.role,
  //           // userID: item.userID,

  //           imageInitials: `${item.firstName?.[0] ?? ""}${
  //             item.lastName?.[0] ?? ""
  //           }`,
  //           imageUrl: item.photo ?? null,
  //           isValid: true,
  //           key: item.userID,
  //           secondaryText: "Designer",
  //           text: `${item.firstName} ${item.lastName}`,
  //         }))
  //         .filter((data) => !formattedMembers.includes(data.email))
  //     : [];
  // }, [fetchedMembers, selectedStudentByNameEmail]);

  // Member selection from drop down handler
  const onMemberSelectHandler = (member) => {
    if (
      allStudentsForGroup.some((data) => data.studentEmailId === member.email)
    ) {
      return toast.warning(feedbackTranslation.addMember.memberAlreadyExist);
    } else {
      setSelectedStudentByNameEmail((prev) => [...prev, member]);
    }
  };

  const onSelectedMemberRemoveHandler = (member) => {
    setSelectedStudentByNameEmail((prev) =>
      prev.filter((data) => data.email !== member.email)
    );
  };

  function getTextFromItem(persona) {
    return persona.text;
  }

  const suggestionProps = {
    noResultsFoundText: feedbackTranslation.addMember.notMatchFound,
    loadingText: `${commonTranslation.loading}...`,
  };

  function validateInput(input) {
    if (input.indexOf("@") !== -1) {
      return ValidationState.valid;
    } else if (input.length > 1) {
      return ValidationState.warning;
    } else {
      return ValidationState.invalid;
    }
  }

  function onInputChange(input) {
    const outlookRegEx = /<.*>/g;
    const emailAddress = outlookRegEx.exec(input);

    if (emailAddress && emailAddress[0]) {
      return emailAddress[0].substring(1, emailAddress[0].length - 1);
    }

    return input;
  }

  const onFilterChanged = (filterText) => {
    return fetchDataByNameHandler(filterText);
  };

  return {
    searchByNameQuery,
    setSearchByNameQuery,
    isLoadingDataByName,
    debouncedSearchByNameHandler,
    selectedAddMemberTab,
    setSelectedAddMemberTab,
    AddMemberTabTypes,
    fetchedMembers,
    onMemberSelectHandler,
    onSelectedMemberRemoveHandler,
    peopleList,
    setPeopleList,
    fetchDataByNameHandler,

    onFilterChanged,
    onInputChange,
    validateInput,
    suggestionProps,
    getTextFromItem,
  };
};

export default useAddMember;
