import styled from "styled-components";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";
import FacebookIcon from "@mui/icons-material/Facebook";
import TwitterIcon from "@mui/icons-material/Twitter";
import LinkedinIcon from "@mui/icons-material/LinkedIn";
import ShareIcon from "@mui/icons-material/Share";
import { useState } from "react";
import { Box, Tooltip } from "@mui/material";

const socials = [
  {
    outlet: "LinkedIn",
    href: "https://web.whatsapp.com/send?text= ",
    background: "#075E54",
    color: "white",
    label: "Share on LinkedIn",
    icon: <WhatsAppIcon />,
  },
  {
    outlet: "Facebook",
    href: "https://www.facebook.com/sharer.php?u=",
    background: "#3b5898",
    color: "white",
    label: "Share on Facebook",
    icon: <FacebookIcon />,
  },
  {
    outlet: "Twitter",
    href: "https://twitter.com/intent/tweet?text=",
    background: "#00aced",
    color: "white",
    label: "Share on Twitter",
    icon: <TwitterIcon />,
  },
  {
    outlet: "Email",
    href: "https://www.linkedin.com/shareArticle?url=",
    background: "#0a66c2",
    color: "white",
    label: "Share via Email",
    icon: <LinkedinIcon />,
  },
];

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const ShareContainer = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
`;
const ShareButton = styled.button`
  display: flex;
  z-index: 1;
  align-items: center;
  justify-content: center;
  background: ${({ background }) => background};
  color: ${({ color }) => color};
  border-radius: 100%;
  outline: none;
  border: 2px solid ${({ background }) => background};
  padding: 8px;
  transform: scale(1);
  transition: all 0.2s, transform 0.2s 0.2s;
  :hover {
    background: white;
    color: ${({ background }) => background};
  }
  cursor: pointer;
`;

const SocialLink = styled(ShareButton)`
  z-index: 0;
  transform: none;
  transition: top 0.2s ${({ position }) => `${position * 50}ms`},
    left 0.2s ${({ position }) => `${position * 50}ms`};
  display: flex;
  gap: 5;
  cursor: pointer;
`;

export const ShareButtons = (props) => {
  const [menuActive, setMenuActive] = useState(false);
  const handleToggleMenu = () => {
    setMenuActive((menuActive) => !menuActive);
  };

  const socialLinks = socials.map((social, index) => {
    return (
      <Box key={index} display="flex" alignItems="center">
        <SocialLink
          as="a"
          href={social.href + props.link}
          target="_blank"
          rel="noreferrer"
          aria-label={social.label}
          role="button"
          background={social.background}
          color={social.color}
          position={index}
          key={index}
          l
        >
          {social.icon}
        </SocialLink>
      </Box>
    );
  });

  return (
    <Container>
      <ShareContainer>
        <Tooltip
          open={menuActive}
          placement="right-end"
          title={
            <Box display="flex" gap={1}>
              {socialLinks}
            </Box>
          }
          componentsProps={{
            tooltip: {
              style: {
                borderRadius: 0,
                backgroundColor: "transparent",
                padding: 0,
                boxShadow: "none",
              },
            },
          }}
          onClick={handleToggleMenu}
        >
          <ShareButton
            isActive={menuActive}
            aria-label="Share Button"
            aria-expanded={menuActive}
            role="button"
            background="rgb(196, 49, 75)"
            color="white"
          >
            <ShareIcon />
          </ShareButton>
        </Tooltip>
      </ShareContainer>
    </Container>
  );
};
