import { Flex } from "@fluentui/react-northstar";
import { withTranslation } from "react-i18next";
import styled from "styled-components";

import { useEvent } from "../../context/EventContext";

const ColumnWrapper = styled(Flex)`
  @media screen and (max-width: 480px) {
    margin-left: ${({ component }) =>
      ["Favourites"].includes(component) ? "30px" : 0};
  }
`;

const UserColumnFilter = ({
  component,
  t,
  status,
  setStatus,
  eventsTranslation,
  isMobileView,
}) => {
  const { selectedColumns, columnSelectHandler } = useEvent();

  const translation = t("events");
  const columnTranslation = translation.body.columnFilter;

  return (
    <ColumnWrapper
      component={component}
      className="switch tour-registered-event-filter tour-bookmarked-event-filter"
      wrap
    >
      {/* {isMobileView && (
        <Switch
          value={status}
          onChange={(value) => setStatus(value ? "active" : "expired")}
          firstState={eventsTranslation.active}
          secondState={eventsTranslation.expired}
        />
      )} */}
      <div
        className={selectedColumns === 6 ? "enable" : "disable"}
        onClick={() => columnSelectHandler(6)}
      >
        6 {columnTranslation.columns}
      </div>
      <div
        className={selectedColumns === 5 ? "enable" : "disable"}
        onClick={() => columnSelectHandler(5)}
      >
        5 {columnTranslation.columns}
      </div>
      <div
        className={selectedColumns === 4 ? "enable" : "disable"}
        onClick={() => columnSelectHandler(4)}
      >
        4 {columnTranslation.columns}
      </div>
    </ColumnWrapper>
  );
};

export default withTranslation()(UserColumnFilter);
