import React from "react";
import { withTranslation } from "react-i18next";
import QuestionResponse from "./QuestionResponse";
import { useEffect } from "react";
import { useState } from "react";

const AllQuestionResponses = ({
  feedbackData,
  FEEDBACK_TYPES,
  currentQuestion,
  setCurrentQuestion,
  isSubmitting,
  completed,
  isFeedbackCreator,
  questionResponses,
  setQuestionResponses,
  submitFeedbackHandler,
  t,
}) => {
  const [response, setResponse] = useState(null);

  useEffect(() => {
    setResponse(
      questionResponses.find((_, index) => index === currentQuestion)
    );
  }, [currentQuestion, questionResponses]);

  return response ? (
    <QuestionResponse
      {...{
        response,
        FEEDBACK_TYPES,
        currentQuestion,
        setCurrentQuestion,
        isSubmitting,
        feedbackData,
        completed,
        currentQuestion,
        isFeedbackCreator,
        questionResponses,
        setQuestionResponses,
        submitFeedbackHandler,
      }}
      key={response.feedbackQuestionSubmissionId}
    />
  ) : (
    <div />
  );
};

export default withTranslation()(AllQuestionResponses);
