import React from "react";
import Avatar from "react-avatar";

const DEFAULT_MAX_AVATARS = 5;

function renderRemaining(props) {
  const {
    avatars = [],
    maxAvatars = DEFAULT_MAX_AVATARS,
    handleAttendees,
    ...other
  } = props;
  const remaining = avatars.length - maxAvatars;

  if (remaining < 1) return null;

  return (
    <Avatar
      onClick={() => handleAttendees()}
      {...other}
      value={`+${remaining}`}
      color="gray"
    />
  );
}

export default function StackedAvatar(props) {
  const {
    avatars = [],
    maxAvatars = DEFAULT_MAX_AVATARS,
    handleAttendees,
    SASToken,
    ...others
  } = props;
  const style = {
    border: "1px solid white",
    ...props.style,
    marginLeft: -(props.size / 3) + "px",
  };

  return (
    <div style={{ marginLeft: props.size / 2 }}>
      {avatars.slice(0, maxAvatars).map((avatar, idx) => {
        return (
          <Avatar
            src={avatar.url + SASToken}
            {...avatar}
            {...others}
            name={
              avatar.name ||
              (avatar.email && avatar.email.split("@")?.[0]) ||
              (avatar.type === "Teacher"
                ? avatar.teacherId?.split("@")?.[0]
                : "")
            }
            key={idx}
            style={style}
            onClick={() => handleAttendees()}
          />
        );
      })}
      {renderRemaining({
        ...props,
        style: { ...style, cursor: "pointer" },
      })}
    </div>
  );
}
