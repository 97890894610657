import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";

import * as XLSX from "xlsx";

const useListEvents = ({ selectedFile, setRows, title }) => {
  const { t } = useTranslation();

  const translation = t("events");
  const eventsTranslation = translation.body.bundles.importFromExcel;

  useEffect(() => {
    onFileAddHandler(selectedFile);
  }, [selectedFile]);

  const onFileAddHandler = (selectedFile) => {
    if (selectedFile) {
      let file = selectedFile;
      try {
        const reader = new FileReader();
        reader.readAsBinaryString(file);
        reader.onload = (event) => {
          const data = event.target.result;
          const workbook = XLSX.read(data, { type: "binary" });
          const sheetName = workbook.SheetNames[0];

          const sheet = workbook.Sheets[sheetName];

          const parsedData = XLSX.utils.sheet_to_json(sheet);
          if (parsedData?.length) {
            setRows(
              parsedData.map((item, index) => ({
                ...item,
                categories: item.categories?.split(","),
                tags: item.tags?.split(","),
                id: index + 1,
              }))
            );
          } else {
            setRows([]);
            toast.error(eventsTranslation.fileParsingError);
          }
        };
      } catch (error) {
        console.log(error);
        setRows([]);
      }
    } else {
      setRows([]);
    }
  };

  return {};
};

export default useListEvents;
