import React, { useState, useEffect } from "react";

import {
  Button,
  Checkbox,
  Flex,
  Label,
  Table,
  Text,
  CardHeader,
  CardBody,
  Card,
  Image,
  TextArea,
  Dialog,
  FlexItem,
  Video,
  Segment,
} from "@fluentui/react-northstar";

import { IconButton } from "@fluentui/react";
import { TextField } from "@fluentui/react/lib/TextField";
import axios from "axios";
import styled from "styled-components";

import userSvg from "../../../../Assets/images/svg/user.svg";

import "./ViewResponse.scss";

const PollOptionGraph = styled(Flex)`
  height: 35px;
  background-color: #6264a7;
  line-height: 32px;
  width: ${({ percentage }) => `${percentage}%`};
`;

const NoResponnseText = styled(Text)`
  margin-top: 150px;
  font-size: 21px;
  color: #d1d1d1;
  text-align: center;

  @media screen and (max-width: 1024px) {
    font-size: 16px;
    margin-top: 100px;
  }
  @media screen and (max-width: 640px) {
    margin: 70px 0;
  }
`;

const url = process.env.REACT_APP_EP_URL;

const PollFeedbackResponse = (props) => {
  const { feedback, feedbackTranslation, currentQuestion, allResponses, user } =
    props;
  const [selectedOption, setSelectedOption] = useState(null);
  const [allStudentResponse, setAllStudentResponse] = useState([]);
  const [filteredResponse, setFilteredResponse] = useState([]);

  useEffect(() => {
    if (currentQuestion) {
      setAllStudentResponse(
        Array.isArray(allResponses) && allResponses ? allResponses : []
      );
      setSelectedOption(
        Array.isArray(currentQuestion.pollOptions) &&
          currentQuestion.pollOptions.length
          ? currentQuestion.pollOptions[0]?.value
          : null
      );
    }
  }, [currentQuestion, allResponses]);

  useEffect(() => {
    setFilteredResponse(
      allStudentResponse.filter(
        (option) => option.pollResponse === selectedOption
      )
    );
  }, [allStudentResponse, selectedOption]);

  return (
    <Flex column style={{ padding: "10px" }}>
      {allStudentResponse.length ? (
        <Flex column>
          <Flex column>
            <Text weight="bold">{feedbackTranslation.result}:</Text>
            <Flex column gap="gap.medium" style={{ marginTop: "10px" }}>
              {currentQuestion.pollOptions?.map((option) => {
                return (
                  <Flex
                    key={option.value}
                    vAlign="center"
                    style={{ width: "80%" }}
                  >
                    <Flex fill style={{ backgroundColor: "#E9EAF6" }}>
                      <PollOptionGraph
                        allStudentResponseCount={allStudentResponse.length}
                        selectedResponseCount={
                          allStudentResponse.filter(
                            (item) => item.pollResponse === option.value
                          ).length
                        }
                        percentage={
                          allStudentResponse.length
                            ? (allStudentResponse.filter(
                                (item) => item.pollResponse === option.value
                              ).length /
                                allStudentResponse.length) *
                              100
                            : 0
                        }
                      >
                        <Text style={{ color: "white", paddingLeft: "10px" }}>
                          {option.value}
                        </Text>
                      </PollOptionGraph>
                    </Flex>
                    <IconButton
                      iconProps={{
                        iconName: "BarChart4",
                        style: { fontSize: "15px", color: "black" },
                      }}
                      title={option.value}
                      ariaLabel={option.value}
                      style={{ fontWeight: "bold" }}
                    />
                    <Text
                      content={
                        allStudentResponse.filter(
                          (item) => item.pollResponse === option.value
                        ).length
                      }
                    />
                  </Flex>
                );
              })}
            </Flex>
          </Flex>

          <Flex column style={{ marginTop: "15px" }}>
            <Text weight="bold">{feedbackTranslation.votes}:</Text>
            <Flex style={{ marginTop: "10px" }}>
              {currentQuestion.pollOptions?.map((option) => {
                return (
                  <Button
                    key={option.value}
                    primary={selectedOption === option.value}
                    text
                    onClick={() => setSelectedOption(option.value)}
                    style={{
                      borderBottom:
                        selectedOption === option.value
                          ? "3px solid #6264A7"
                          : "",
                      borderRadius: "0",
                      minWidth: "fit-content",
                    }}
                  >
                    <Text weight="bold">{option.value}</Text>
                  </Button>
                );
              })}
            </Flex>
            <Flex wrap>
              {filteredResponse.map((item, index) => (
                <Segment
                  key={`${index}${item.name}`}
                  content={
                    <Flex style={{ flexDirection: "column" }}>
                      <Image
                        src={
                          item.imageUrl
                            ? item.imageUrl + user.SASToken
                            : userSvg
                        }
                        onError={(e) => {
                          e.target.src = userSvg;
                        }}
                        width="200px"
                        height="200px"
                        className="img"
                      />
                      <Flex
                        style={{ width: "200px" }}
                        wrap
                        className="poll-response-card-name-section"
                      >
                        <Text
                          align="center"
                          content={
                            item.name ?? item.studentEmailId?.split("@")?.[0]
                          }
                          className="Karla fw6 m1 tcap"
                        />
                      </Flex>
                    </Flex>
                  }
                  className="seg"
                />
              ))}
            </Flex>
          </Flex>
        </Flex>
      ) : (
        <Flex column hAlign="center" vAlign="center" fill>
          <NoResponnseText weight="bold">
            {
              allStudentResponse.length
                ? feedbackTranslation.noStudentResponseForThisFilter
                : // feedback.sharedWith?.length ?
                  feedbackTranslation.responseWillAppearHere
              // : feedbackTranslation.noStudentAssigned
            }
          </NoResponnseText>
        </Flex>
      )}
    </Flex>
  );
};

export default PollFeedbackResponse;
