import { LogLevel } from "@azure/msal-browser";

// console.log(
//   process.env.REACT_APP_APP_ID,
//   process.env.REACT_APP_AUTH,
//   process.env.REACT_APP_URI
// );

export const msalConfig = {
  auth: {
    clientId: process.env.REACT_APP_APP_ID,
    authority: process.env.REACT_APP_AUTH,
    redirectUri: process.env.REACT_APP_URI,
  },
  cache: {
    cacheLocation: "localStorage",
    storeAuthStateInCookie: true,
  },
  system: {
    loggerOptions: {
      loggerCallback: (level, message, containsPii) => {
        if (containsPii) {
          return;
        }
        switch (level) {
          case LogLevel.Error:
            console.error(message);
            return;
          case LogLevel.Info:
            // console.info(message);
            return;
          case LogLevel.Verbose:
            console.debug(message);
            return;
          case LogLevel.Warning:
            console.warn(message);
            return;
        }
      },
    },
  },
};

export const loginRequest = {
  scopes: JSON.parse(process.env.REACT_APP_GRAPH_SCOPES),
};

export const graphConfig = {
  graphMeEndpoint: "https://graph.microsoft.com/v1.0/me",
  graphMePhotoEndpoint: "https://graph.microsoft.com/v1.0/me/photo/$value",
};
