import moment from "moment";

export const generateTimeSlots = (startTime, duration, endTime) => {
  const slots = [];
  let currentTime = moment(startTime, "h:mm A");
  const endDateTime = endTime
    ? moment(endTime, "h:mm A")
    : currentTime.clone().add(2, "hours");
  while (currentTime.isBefore(endDateTime)) {
    const start = currentTime.clone().format("h:mm A");
    const end = currentTime.clone().add(duration, "minutes").format("h:mm A");

    const slotKey = getSlotKey(currentTime);
    slots.push({
      startTime: start,
      endTime: end,
      key: slotKey,
      isCommon: true,
      isDeleted: false,
      isRemoved: false,
      isNew: true,
    });
    currentTime.add(duration, "minutes");
  }

  return slots;
};

export const getSlotKey = (time) => {
  const startTime = moment(time, "h:mm A");
  if (startTime.isBefore(moment("08:00 AM", "h:mm A"))) return 1;
  if (startTime.isBefore(moment("10:00 AM", "h:mm A"))) return 2;
  if (startTime.isBefore(moment("12:00 PM", "h:mm A"))) return 3;
  if (startTime.isBefore(moment("02:00 PM", "h:mm A"))) return 4;
  if (startTime.isBefore(moment("04:00 PM", "h:mm A"))) return 5;
  return 6; // For slots after 4:00 PM
};

export const generateAvailabilityDays = () => {
  // const timeSlots = generateTimeSlots(startTime, endTime, duration);

  const daysOfWeek = [
    { day: "monday", label: "Mondays" },
    { day: "tuesday", label: "Tuesdays" },
    { day: "wednesday", label: "Wednesdays" },
    { day: "thursday", label: "Thursdays" },
    { day: "friday", label: "Fridays" },
    { day: "saturday", label: "Saturdays" },
    { day: "sunday", label: "Sundays" },
  ];

  return daysOfWeek.map((day) => ({
    day: day.day,
    label: day.label,
    isSelected: false,
    isCommonSlots: false,
    isDisabled: false,
    timeSlots: [],
    selectedTimeSlots: { 1: { startTime: "", endTime: "" } },
  }));
};

export const getShiftFromStartTime = (startTime) => {
  const time = moment(startTime);
  if (time.isBefore(moment(time).hour(12))) {
    return "morning";
  } else if (time.isBefore(moment(time).hour(17))) {
    return "afternoon";
  } else {
    return "evening";
  }
};

export const getDatesToScheduleAvailability_ = (
  availabilityTimes,
  tutorEmailId
) => {
  const currentDate = moment();
  const endOfMonth = moment().endOf("month");
  const timeSlotsToSchedule = [];

  availabilityTimes.forEach((availabilityTime) => {
    const { day, isSelected, timeSlots } = availabilityTime;
    if (isSelected) {
      // Set the date to the next occurrence of the specified day
      let date = currentDate.clone().day(day);

      // If the calculated date is in the past, move to the next week
      if (date.isBefore(currentDate, "day")) {
        date.add(1, "week");
      }

      // Loop through the weeks until the end of the month
      while (date.isSameOrBefore(endOfMonth, "day")) {
        timeSlots.forEach((slot) => {
          if (slot.isNew && !slot.isDeleted) {
            const startTime = moment(date)
              .hour(moment(slot.startTime, "HH:mm").hour())
              .minute(moment(slot.startTime, "HH:mm").minute())
              .second(0);
            const endTime = moment(date)
              .hour(moment(slot.endTime, "HH:mm").hour())
              .minute(moment(slot.endTime, "HH:mm").minute())
              .second(0);

            // Ensure the startTime is before endTime
            if (startTime.isBefore(endTime)) {
              timeSlotsToSchedule.push({
                startTime: startTime.toISOString(),
                endTime: endTime.toISOString(),
                shift: getShiftFromStartTime(startTime),
                tutorEmailId,
              });
            }
          }
        });
        // Move to the next week
        date.add(1, "week");
      }
    }
  });

  return timeSlotsToSchedule;
};

export const getDatesToScheduleAvailability = (
  availabilityTimes,
  tutorEmailId,
  selectedDates,
  scheduledSlots
) => {
  const timeSlotsToSchedule = [];

  availabilityTimes.forEach((availabilityTime) => {
    const { day, isSelected, timeSlots } = availabilityTime;
    if (isSelected) {
      const datesOfDay = selectedDates.filter(
        (date) =>
          moment(date).format("dddd").toLowerCase() === day.toLowerCase()
      );
      timeSlots.forEach((slot) => {
        datesOfDay.forEach((date) => {
          if (slot.isNew && !slot.isDeleted) {
            const startTime = moment(date)
              .hour(moment(slot.startTime, "h:mm A").hour())
              .minute(moment(slot.startTime, "h:mm A").minute())
              .second(0)
              .utc();
            const endTime = moment(date)
              .hour(moment(slot.endTime, "h:mm A").hour())
              .minute(moment(slot.endTime, "h:mm A").minute())
              .second(0)
              .utc();

            const isSlotScheduled = timeSlots.some((scheduledSlot) => {
              return (
                moment(scheduledSlot.startTime).isSame(startTime) ||
                moment(scheduledSlot.endTime).isSame(endTime)
              );
            });

            // Ensure the startTime is before endTime
            if (startTime.isBefore(endTime) && !isSlotScheduled) {
              timeSlotsToSchedule.push({
                startTime: startTime.toISOString(),
                endTime: endTime.toISOString(),
                shift: getShiftFromStartTime(startTime),
                tutorEmailId,
              });
            }
          }
        });
      });
    }
  });

  return timeSlotsToSchedule;
};

export const findBiggestEndTime = (timeSlots, key) => {
  const slotsWithKey = timeSlots.filter((timeSlot) => timeSlot.key === key);
  const comparisonStartTime = slotsWithKey[0].isNew
    ? slotsWithKey[0].endTime
    : moment(slotsWithKey[0].endTime).format("h:mm A");
  return slotsWithKey.reduce((latest, slot) => {
    const currentTime = moment();
    const slotEndTime = slot.isNew
      ? currentTime.clone().set({
          hour: moment(slot.endTime, "h:mm A").hour(),
          minute: moment(slot.endTime, "h:mm A").minute(),
        })
      : currentTime.clone().set({
          hour: moment(slot.endTime).hour(),
          minute: moment(slot.endTime).minute(),
        });
    const latestEndTime = slot.isNew
      ? currentTime.clone().set({
          hour: moment(latest, "h:mm A").hour(),
          minute: moment(latest, "h:mm A").minute(),
        })
      : currentTime.clone().set({
          hour: moment(latest).hour(),
          minute: moment(latest).minute(),
        });
    return slotEndTime.isAfter(latestEndTime) ? slot.endTime : latest;
  }, comparisonStartTime);
};

export const findSmallestStartTime = (timeSlots, key) => {
  const slotsWithKey = timeSlots.filter((timeSlot) => timeSlot.key === key);
  const comparisonStartTime = slotsWithKey[0].isNew
    ? slotsWithKey[0].startTime
    : moment(slotsWithKey[0].startTime).format("h:mm A");
  return slotsWithKey.reduce((earliest, slot) => {
    const slotStartTime = slot.isNew
      ? moment(slot.startTime, "h:mm A") // For new slots with "h:mm A" format
      : moment(slot.startTime); // For slots with ISO date string format

    const earliestStartTime = moment(earliest, "h:mm A"); // Parse earliest as "h:mm A" format

    return slotStartTime.isBefore(earliestStartTime)
      ? slot.startTime
      : earliest;
  }, comparisonStartTime);
};
