import React, { useEffect, useState } from "react";
import axios from "axios";
import { NotificationAlert } from "../../../../../components/Notification/Notification";
import { generateGptCreditRequest, updateUserGptCredit } from "../../../../../Assets/api/api";
import { toast } from "react-toastify";

const FEEDBACK_TYPES_OPTIONS = [
  {
    label: "Text",
    value: "TEXT",
  },
  {
    label: "Poll",
    value: "POLL",
  },
];

const API_URL = process.env.REACT_APP_EP_URL;

const useMagicBar = ({
  responseHandler,
  type,
  isMixMode,
  FEEDBACK_TYPES,
  user,
  setUser,
}) => {
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [language, setLanguage] = useState("English");

  const [title, setTitle] = useState("");
  const [feedbackType, setFeedbackType] = useState(
    type?.toLowerCase() ?? "text"
  );
  const [noOfFeedback, setNoOfFeedback] = useState(5);
  const [noOfOption, setNoOfOption] = useState(5);
  const [isOpenValidationDialog, setIsOpenValidationDialog] = useState(false);
  const [prompList, setPrompList] = useState([]);

  useEffect(() => {
    fetchGptPrompt();
  }, []);

  const buttonClickHandler = () => {
    setIsDialogOpen(true);

    setTitle("");
    setNoOfFeedback(5);
    setNoOfOption(5);
    setLanguage("English");
  };

  const closeDialogHandler = () => {
    setIsDialogOpen(false);
  };

  const languages = [
    "English",
    "Español",
    "Português",
    "Français",
    "Deutsch",
    "हिन्दी",
  ];

  const feedbackTypes = FEEDBACK_TYPES_OPTIONS.map((item) => item.label);

  const noOfOptions = Array(5)
    .fill(1)
    .map((_, i) => i + 1);

  const noOfFeedbackQuestions = Array(20)
    .fill(1)
    .map((_, i) => i + 1);

  const isNotMultiItem =
    [FEEDBACK_TYPES.audio, FEEDBACK_TYPES.video].includes(type) ||
    (FEEDBACK_TYPES.any === type && !isMixMode);

    const handleGenerateGptCreditRequest = async () => {
      try {
        setIsLoading(true);
        const payload = {
          credit: 5,
          TeacherEmailID: user.mail,
          TeacherName: user.name,
          role: user.role,
          status: "pending",
        };
        await generateGptCreditRequest(payload, user.slug, user.accessToken);
        setUser((prev) => ({
          ...prev,
          isRequestedForGptCredit: true,
        }));
        setIsLoading(false);
        toast.success("Gpt Credit request generated successfully!");
      } catch (error) {
        setIsLoading(false);
        toast.error(error.message);
      }
    };

  const handleUpdateUserCredit = async () => {
    try {
      const payload = {
        credit: user.credit - 1,
        mail: user.mail,
        role: user.role,
      };
      await updateUserGptCredit(payload, user.slug, user.accessToken);
      setUser((prev) => ({
        ...prev,
        credit: user.credit - 1,
      }));
    } catch (error) {
      console.log("error from handleUpdateUserCredit", error.message);
    }
  };

  async function generatePrompt(event) {
    // Alert user about adding text
    if (!title) return setIsOpenValidationDialog(true);

    setIsLoading(true);

    const formattedType = type?.toLowerCase();

    axios
      .post(`${API_URL}/api/gpt/generate-content`, {
        type: formattedType,
        isNotMultiItem,
        title,
        questions: ["poll", "text"].includes(formattedType) ? noOfFeedback : 1,
        options: noOfOption,
        language,
      })
      .then(async ({ data }) => {
        if (data?.data) {
          handleUpdateUserCredit();
          responseHandler(data.data);
        }
        closeDialogHandler();
      })
      .catch((err) => {
        NotificationAlert(
          err?.response?.data?.message ||
            err?.message ||
            "Something went wrong",
          "error"
        );
        console.log("Error:", err);
      })
      .finally(() => {
        setIsLoading(false);
      });
  }

  const fetchGptPrompt = async () => {
    try {
      const { data } = await axios(
        `${API_URL}/api/gpt/get-gpt-prompts${user.slug}&category=feedback`
      );
      setPrompList(data?.data?.length ? data.data : []);
    } catch (error) {
      console.log(error);
    }
  };

  return {
    isDialogOpen,
    buttonClickHandler,
    generatePrompt,
    languages,

    isLoading,
    setIsLoading,
    language,
    setLanguage,

    title,
    setTitle,

    feedbackTypes,
    feedbackType,
    setFeedbackType,
    noOfFeedbackQuestions,
    noOfFeedback,
    setNoOfFeedback,
    closeDialogHandler,
    noOfOption,
    setNoOfOption,
    noOfOptions,
    isNotMultiItem,

    isOpenValidationDialog,
    setIsOpenValidationDialog,
    handleGenerateGptCreditRequest,
    prompList,
  };
};

export default useMagicBar;
