import * as React from "react";
import {
  Box,
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  Divider,
  Typography,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import { withTranslation } from "react-i18next";

const useStyles = makeStyles({
  dialog: {
    boxShadow: "-10px 12px 40px rgba(121, 121, 121, 0.12)",
    borderRadius: "10px !important",
    padding: "16px 40px 32px 40px",
  },
});

export const AlertDialog = withTranslation()(
  ({
    handleClose,
    handleOk,
    open,
    warningMessage,
    topMessage,
    undoMessage,
    t,
  }) => {
    const translation = t("alertDialog");

    const warningMessageText = warningMessage
      ? warningMessage
      : translation.warningMessage;
    const topMessageText = topMessage ? topMessage : translation.topMessage;
    const undoMessageText = undoMessage ? undoMessage : translation.undoMessage;

    const classes = useStyles();
    return (
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        fullWidth
        maxWidth="xs"
        classes={{
          paper: classes.dialog,
        }}
      >
        <DialogTitle id="alert-dialog-title">
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Box
              sx={{
                height: 84,
                width: 84,
                background: "#FDF7F7",
                borderRadius: "100%",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              {/* <Icons name="alertDelete" sx={{ maxHeight: "35px" }} /> */}
              <svg
                style={{ height: 34, width: 26 }}
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
              >
                <path
                  d="M1.99984 29.8333C1.99984 31.85 3.64984 33.5 5.6665 33.5H20.3332C22.3498 33.5 23.9998 31.85 23.9998 29.8333V7.83333H1.99984V29.8333ZM5.6665 11.5H20.3332V29.8333H5.6665V11.5ZM19.4165 2.33333L17.5832 0.5H8.4165L6.58317 2.33333H0.166504V6H25.8332V2.33333H19.4165Z"
                  fill={"#DB675F"}
                />
              </svg>
            </Box>
          </Box>
          <Typography
            variant="h3"
            fontWeight={600}
            mt={3}
            sx={{
              textAlign: "center",
              fontSize: 18,
              lineHeight: "22px",
              whiteSpace: { md: "nowrap" },
            }}
          >
            {topMessageText}
          </Typography>
        </DialogTitle>
        <DialogContent>
          <Box
            display={"grid"}
            alignItems="center"
            justifyContent={"center"}
            mb={1}
          >
            <Typography
              variant="subtitle4"
              sx={{
                fontWeight: 600,
                fontSize: 15,
                textAlign: "center",
              }}
            >
              {warningMessageText}
            </Typography>
            <Typography
              variant="subtitle4"
              sx={{
                fontWeight: 600,
                fontSize: 15,
                textAlign: "center",
                mt: 1,
                whiteSpace: { md: "nowrap" },
              }}
            >
              {undoMessageText}
            </Typography>
          </Box>
        </DialogContent>
        <Divider sx={{ borderColor: "#EAEEF4" }} />
        <Box
          sx={{
            pt: 2,
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Button
            onClick={(e) => {
              e.stopPropagation();
              handleClose();
            }}
            sx={{
              mr: 2,
              color: "black",
              fontSize: 12,
              background: "#ffffff",
              height: 28,
              border: `1px solid #0000`,
              fontWeight: 600,
              width: 100,
              ":hover": {
                background: "#ffffff",
              },
            }}
          >
            {translation.cancel}
          </Button>
          <Button
            onClick={(e) => {
              e.stopPropagation();
              handleOk();
              handleClose();
            }}
            sx={{
              color: "#ffff",
              fontSize: 12,
              background: "rgb(219, 103, 95)",
              height: 28,
              fontWeight: 600,
              width: 100,

              ":hover": {
                background: "rgb(219, 103, 95)",
              },
            }}
          >
            {translation.yes}
          </Button>
        </Box>
      </Dialog>
    );
  }
);
