import React from "react";

import "./ImportSteps.scss";
import SuccessConfirmation from "./SuccessConfirmation/SuccessConfirmation";
import ImportHomeStep from "./ImportHomeStep/ImportHomeStep";
import ListParents from "./ListParents/ListParents";

const ImportSteps = ({ step, onFileSelectHandler, selectedFile, ...rest }) => {
  const getStepContent = () => {
    switch (step) {
      case 1:
        return (
          <ImportHomeStep
            onFileSelectHandler={onFileSelectHandler}
            selectedFile={selectedFile}
            {...rest}
          />
        );
      case 2:
        return <ListParents selectedFile={selectedFile} {...rest} />;
      case 3:
        return <SuccessConfirmation {...rest}/>;
    }
  };

  return (
    <div className="import-steps-wrapper">
      <div>{getStepContent()}</div>
    </div>
  );
};

export default ImportSteps;
