import { Dialog } from "@fluentui/react-northstar";
import React, { useEffect, useState } from "react";

const FeedbackDialog = (props) => {
  const {
    onOpen,
    onCancel,
    onConfirm,
    confirmButton,
    content,
    header,
    headerAction,
    button,
    cancelButton,
    ...rest
  } = props;
  const [isOpen, setIsOpen] = useState(false);
  // const [scale, setScale] = useState(1);

  // useEffect(() => {
  //   const updateScale = () => {
  //     const zoomLevel = Math.round((window.outerWidth / window.innerWidth) * 100) / 100;
  //     const devicePixelRatio = window.devicePixelRatio || 1;
  //     console.log("zoomLevel", zoomLevel, "devicePixelRatio", devicePixelRatio);
  //     setScale(1 / (zoomLevel * devicePixelRatio));
  //   };

  //   window.addEventListener('resize', updateScale);
  //   window.addEventListener('load', updateScale);

  //   // Initial update
  //   updateScale();

  //   return () => {
  //     window.removeEventListener('resize', updateScale);
  //     window.removeEventListener('load', updateScale);
  //   };
  // }, []);

  return (
    <Dialog
      className="feedback-dialog"
      open={isOpen}
      onOpen={() => {
        if (onOpen) {
          onOpen();
        }
        setIsOpen(true);
      }}
      onCancel={() => {
        if (onCancel) {
          onCancel();
        }
      }}
      onConfirm={() => {
        if (onConfirm) {
          onConfirm();
        }
        setIsOpen(false);
      }}
      confirmButton={confirmButton}
      cancelButton={cancelButton}
      content={content}
      header={header}
      headerAction={{
        ...headerAction,
        onClick: () => {
          if (headerAction.onClick) {
            headerAction.onClick();
          }
          setIsOpen(false);
        },
      }}
      trigger={button}
      {...rest}
    />
  );
};

export default FeedbackDialog;
