import { Box, Divider, Grid, Skeleton } from "@mui/material";
import React, { useEffect, useState } from "react";

function AttendanceSkeleton() {
  const [isMobileView, setIsMobileView] = useState(window.innerWidth <= 767);
  const [isTabletView, setIsTabletView] = useState(window.innerWidth <= 991);

  useEffect(() => {
    const handleResize = () => {
      setIsMobileView(window.innerWidth <= 767);
      setIsTabletView(window.innerWidth <= 991);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <>
      {isMobileView || isTabletView ? (
        <div>
          <Skeleton
            sx={{
              height: 49,
              width: "100%",
              backgroundColor: "white",
              boxShadow: "0 15px 18px 0px rgb(200 200 200 / 30%)",
              borderBottomRightRadius: "20px",
            }}
            variant="rectangular"
          />
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              position: "absolute",
              top: isMobileView ? "1%" : "2%",
              width: "100%",
            }}
          >
            <Skeleton
              animation="wave"
              variant="rectangular"
              sx={{
                height: 20,
                width: 20,
                marginRight: "18px",
                marginLeft: "15px",
                borderRadius: "4px",
                padding: "5px",
              }}
            />
            <Skeleton
              animation="wave"
              variant="rectangular"
              sx={{
                height: 20,
                width: 20,
                borderRadius: "4px",
              }}
            />
            <Skeleton
              animation="wave"
              height={20}
              width="36%"
              sx={{
                marginLeft: "22px",
              }}
            />
            {isMobileView && (
              <Box
                sx={{
                  justifyContent: "end",
                  display: "flex",
                  width: "36%",
                }}
              >
                <Skeleton
                  sx={{
                    marginLeft: "88px",
                  }}
                  animation="wave"
                  variant="circular"
                  width={35}
                  height={35}
                />
              </Box>
            )}
          </Box>
          {[...Array(5)].map((index) => (
            <Box
              key={index}
              sx={{
                position: "relative",
                marginBottom: "5px",
              }}
            >
              <Skeleton
                sx={{
                  height: 154,
                  width: "100%",
                  marginTop: "2px",
                  position: "absolute",
                  background: "white",
                }}
                animation="wave"
                variant="rectangular"
              />
              <Divider light sx={{ marginTop: "10px" }} />
              <Box>
                <Box
                  sx={{
                    display: "flex",
                    marginTop: "7px",
                    alignItems: "center",
                  }}
                >
                  <Skeleton
                    animation="wave"
                    variant="rectangular"
                    sx={{
                      height: 20,
                      width: 20,
                      marginRight: "18px",
                      marginLeft: "15px",
                      borderRadius: "4px",
                      padding: "5px",
                    }}
                  />
                  <Skeleton
                    animation="wave"
                    variant="rectangular"
                    sx={{
                      height: 20,
                      width: 20,
                      borderRadius: "4px",
                    }}
                  />
                  <Skeleton
                    animation="wave"
                    variant="circular"
                    width={40}
                    height={40}
                    sx={{
                      marginLeft: "20px",
                      backgroundColor: "rgb(243, 242, 241)",
                    }}
                  />
                  <Skeleton
                    animation="wave"
                    height={20}
                    width="30%"
                    sx={{
                      marginLeft: "10px",
                    }}
                  />
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    gap: "50px",
                    marginTop: "5%",
                    marginBottom: "5%",
                  }}
                >
                  <Skeleton
                    animation="wave"
                    variant="circular"
                    width={35}
                    height={35}
                    sx={{
                      marginLeft: "15px",
                      backgroundColor: "rgb(243, 242, 241)",
                    }}
                  />
                  <Skeleton
                    animation="wave"
                    variant="circular"
                    width={35}
                    height={35}
                    sx={{
                      marginLeft: "15px",
                      backgroundColor: "rgb(243, 242, 241)",
                    }}
                  />
                  <Skeleton
                    animation="wave"
                    variant="circular"
                    width={35}
                    height={35}
                    sx={{
                      marginLeft: "15px",
                      backgroundColor: "rgb(243, 242, 241)",
                    }}
                  />
                </Box>
                <Skeleton
                  sx={{
                    height: 30,
                    marginTop: "10px",
                    marginLeft: "15px",
                    marginRight: "15px",
                    borderRadius: "5px",
                    backgroundColor: "rgb(243, 242, 241)",
                  }}
                  animation="wave"
                  variant="rectangular"
                />
              </Box>
            </Box>
          ))}
        </div>
      ) : (
        <Box>
          <div>
            <Skeleton
              sx={{
                height: 50,
                width: "100%",
                backgroundColor: "white",
                boxShadow: "0 15px 18px 0px rgb(200 200 200 / 30%)",
              }}
              variant="rectangular"
            />
            <Grid
              container
              justifyContent="space-between"
              alignItems="center"
              sx={{
                position: "absolute",
                top: isMobileView ? "1%" : "2.5%",
                width: "100%",
              }}
            >
              <Grid item container alignItems="center">
                <Skeleton
                  animation="wave"
                  variant="rectangular"
                  sx={{
                    height: 20,
                    width: 20,
                    marginRight: "18px",
                    marginLeft: "15px",
                    borderRadius: "4px",
                    padding: "5px",
                  }}
                />
                <Skeleton
                  animation="wave"
                  variant="rectangular"
                  sx={{
                    height: 20,
                    width: 20,
                    borderRadius: "4px",
                  }}
                />
                <Box sx={{ display: "flex", width: "86%" }}>
                  <Skeleton
                    animation="wave"
                    height={20}
                    width="15%"
                    sx={{
                      marginLeft: "25px",
                    }}
                  />

                  <Skeleton
                    animation="wave"
                    height={20}
                    width="15%"
                    sx={{
                      marginLeft: "136px",
                    }}
                  />

                  <Skeleton
                    animation="wave"
                    height={20}
                    width="15%"
                    sx={{
                      marginLeft: "27%",
                    }}
                  />
                </Box>
              </Grid>
            </Grid>
            {[...Array(12)].map((index) => (
              <Box
                key={index}
                sx={{
                  position: "relative",
                  marginBottom: "5px",
                }}
              >
                <Skeleton
                  sx={{
                    height: 154,
                    width: "100%",
                    marginTop: "2px",
                    position: "absolute",
                    background: "white",
                  }}
                  animation="wave"
                  variant="rectangular"
                />
                <Divider light sx={{ marginTop: "10px" }} />
                <Box>
                  <Grid
                    container
                    justifyContent="space-between"
                    alignItems="center"
                    sx={{
                      marginTop: "7px",
                    }}
                  >
                    <Grid item container alignItems="center">
                      <Skeleton
                        animation="wave"
                        variant="rectangular"
                        sx={{
                          height: 20,
                          width: 20,
                          marginRight: "18px",
                          marginLeft: "15px",
                          borderRadius: "4px",
                          padding: "5px",
                        }}
                      />
                      <Skeleton
                        animation="wave"
                        variant="rectangular"
                        sx={{
                          height: 20,
                          width: 20,
                          borderRadius: "4px",
                        }}
                      />
                      <Skeleton
                        animation="wave"
                        variant="circular"
                        width={32}
                        height={32}
                        sx={{
                          marginLeft: "20px",
                          backgroundColor: "rgb(243, 242, 241)",
                        }}
                      />
                      <Skeleton
                        animation="wave"
                        height={20}
                        width="10%"
                        sx={{
                          marginLeft: "10px",
                        }}
                      />
                      <Box
                        sx={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          width: "50%",
                        }}
                      >
                        <Skeleton
                          animation="wave"
                          variant="circular"
                          width={32}
                          height={32}
                          sx={{
                            marginLeft: "15px",
                            backgroundColor: "rgb(243, 242, 241)",
                          }}
                        />
                        <Skeleton
                          animation="wave"
                          height={20}
                          width="10%"
                          sx={{ marginLeft: "10px" }}
                        />
                        <Skeleton
                          animation="wave"
                          variant="circular"
                          width={32}
                          height={32}
                          sx={{
                            marginLeft: "15px",
                            backgroundColor: "rgb(243, 242, 241)",
                          }}
                        />
                        <Skeleton
                          animation="wave"
                          height={20}
                          width="10%"
                          sx={{ marginLeft: "10px" }}
                        />
                        <Skeleton
                          animation="wave"
                          variant="circular"
                          width={32}
                          height={32}
                          sx={{
                            marginLeft: "15px",
                            backgroundColor: "rgb(243, 242, 241)",
                          }}
                        />
                        <Skeleton
                          animation="wave"
                          height={20}
                          width="10%"
                          sx={{ marginLeft: "10px" }}
                        />
                      </Box>
                      <Skeleton
                        sx={{
                          height: 30,
                          marginTop: "10px",
                          borderRadius: "5px",
                          backgroundColor: "rgb(243, 242, 241)",
                          width: "17%",
                        }}
                        animation="wave"
                        variant="rectangular"
                      />
                    </Grid>
                  </Grid>
                </Box>
              </Box>
            ))}
          </div>
        </Box>
      )}
    </>
  );
}

export default AttendanceSkeleton;
