import { LoadingButton } from "@mui/lab";
import { Avatar, Badge, styled } from "@mui/material";
import verify from "../../Assets/images/verify.png";
import unpublished_icon from "../../Assets/images/unpublished_icon.png";
import React from "react";
import "./gptcredits.css";

function History({ creditHistory, isTabletView, isMobileView }) {
  const SmallAvatar = styled(Avatar)(({ theme }) => ({
    width: 18,
    height: 18,
    backgroundColor: "white",
    borderRadius: "50%",
  }));

  return (
    <div className="d-flex flex-column">
      <div className={isMobileView ? "mobile-container" : "container"}>
        <ul className={isMobileView ? "mobile-table" : "responsive-table"}>
          {!isMobileView && (
            <li className="table-header">
              <div className="col col-1">#</div>
              <div className="col col-2">Image</div>
              <div className="col col-3">Name</div>
              <div className="col col-4">Email</div>
              <div className="col col-5">Role</div>
              <div className="col col-6">Credits</div>
              <div className={isTabletView ? "col col-9" : "col col-8"}>
                Status
              </div>
            </li>
          )}
          <div
            style={{
              overflow: "auto",
              height: isMobileView ? "calc(100vh - 166px)" : "calc(100vh - 405px)",
              //   padding: "20px",
              //   paddingRight: "7px",
            }}
          >
            {creditHistory.map((teacher, index) => {
              return (
                <li className="table-row" key={teacher.TeacherEmailID}>
                  <div
                    className="col col-1"
                    data-label="Index"
                    style={{ padding: "18px" }}
                  >
                    {index + 1}
                  </div>
                  <div
                    className="col col-2"
                    data-label="Image"
                    style={{
                      padding: "8px",
                      display: "flex",
                      gap: "10px",
                      alignItems: "center",
                    }}
                  >
                    <Badge
                      overlap="circular"
                      style={{ zIndex: "0" }}
                      anchorOrigin={{
                        vertical: "top",
                        horizontal: "right",
                      }}
                      badgeContent={
                        teacher.status !== "pending" && (
                          <SmallAvatar
                            alt="icon"
                            src={
                              (teacher.status === "rejected" &&
                                unpublished_icon) ||
                              (teacher.status === "approved" && verify)
                            }
                          />
                        )
                      }
                    >
                      <Avatar>{teacher.UrlPhoto}</Avatar>
                    </Badge>
                  </div>
                  <div
                    className="col col-3"
                    data-label="Name"
                    style={{ padding: "18px" }}
                  >
                    {teacher.role.toLowerCase() === "student"
                      ? teacher.StudentName
                      : teacher.TeacherName}
                  </div>
                  <div
                    className="col col-4"
                    data-label="Email"
                    style={{ padding: "18px" }}
                  >
                    {teacher.role.toLowerCase() === "student"
                      ? teacher.StudentEmailID
                      : teacher.TeacherEmailID}
                  </div>
                  <div
                    className="col col-5"
                    data-label="Role"
                    style={{ padding: "18px" }}
                  >
                    {teacher.role}
                  </div>
                  <div
                    className="col col-6"
                    data-label="Available Credits"
                    style={{ padding: "18px" }}
                  >
                    {teacher.credit}
                  </div>
                  <div
                    className={isTabletView ? "col col-9" : "col col-8"}
                    data-label="Action"
                    style={{
                      padding: "10px",
                      paddingRight: teacher.status
                        ? teacher.status === "approved"
                          ? "51px"
                          : "56px"
                        : "10px",
                    }}
                  >
                    {teacher.status && (
                      <LoadingButton
                        disabled
                        loadingPosition="start"
                        className={
                          teacher.status === "approved"
                            ? "iconButton"
                            : "rejectIcon"
                        }
                        sx={{
                          color:
                            teacher.status === "approved" ? "green" : "red",
                          height: "35px",
                          textTransform: "capitalize",
                        }}
                        endIcon={
                          teacher.status === "approved" ? (
                            <img
                              src={verify}
                              style={{
                                marginRight: "5px",
                                width: "22px",
                                height: "22px",
                              }}
                            />
                          ) : (
                            <img
                              src={unpublished_icon}
                              style={{
                                marginRight: "5px",
                                width: "22px",
                                height: "22px",
                              }}
                            />
                          )
                        }
                      >
                        {teacher.status === "rejected"
                          ? "Rejected"
                          : teacher.id
                          ? "Approved"
                          : "Assigned"}
                      </LoadingButton>
                    )}
                  </div>
                </li>
              );
            })}
          </div>
        </ul>
      </div>
    </div>
  );
}

export default History;
