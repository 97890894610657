import React, { useEffect } from "react";
import { useBooking } from "../../../../context/BookingContext";
import TutorCard from "../Tutors/TutorCard/TutorCard";
import { Col, Row } from "antd";
import { Flex, Image, Loader } from "@fluentui/react-northstar";
import { useTranslation } from "react-i18next";

const Starred = () => {
  const { t } = useTranslation();
  const translation = t("booking").starredTutors;

  const {
    starredTutors,
    getStarredTutors,
    handleGetTutors,
    tutors,
    isLoadingStarredTutors,
    isLoadingTutors,
  } = useBooking();
  useEffect(() => {
    handleGetTutors();
    getStarredTutors();
  }, []);
  return (
    <div>
      <h1 style={{ color: "#6264a7", fontWeight: "bold" }}>
        {translation.header}
      </h1>
      {isLoadingStarredTutors || isLoadingTutors ? (
        <Flex hAlign="center" vAlign="center" style={{ height: "100%" }}>
          <Loader size="medium" />
        </Flex>
      ) : starredTutors.length ? (
        <Row
          gutter={[20, 20]}
          style={{
            margin: 0,
            maxHeight: "calc(100vh - 165px)",
            overflow: "auto",
          }}
        >
          {starredTutors.map((tutor, i) => {
            const data = tutors.find(
              (tutor_) => tutor.tutorEmailId === tutor_.email
            );
            return (
              <Col key={`tutor-${i + 1}`} xxl={4} xl={6} md={12} sm={24}>
                <TutorCard
                  tutor={{ ...tutor, ...data }}
                  isStarred={true}
                  isFromStarredPage={true}
                />
              </Col>
            );
          })}
        </Row>
      ) : (
        <Flex column hAlign="center" vAlign="center">
          <Image
            style={{ maxWidth: "280px", width: "100%" }}
            src={"/Images/Events/startled-pana.svg"}
          />
          <span>{translation.noTutors}</span>
        </Flex>
      )}
    </div>
  );
};

export default Starred;
