import { Panel, PanelType } from "@fluentui/react";
import React, { useEffect } from "react";
import { useState } from "react";
import ArrowLeftIcon from "@mui/icons-material/ArrowLeft";
import AvailbleClass from "./AvailbleClass";
import {
  Button,
  IndentIcon,
  Label,
  NumberListIcon,
} from "@fluentui/react-northstar";
import "./Attendance.css";

function Drawer({
  isCompactMode,
  state,
  myCard,
  isOpen,
  openPanel,
  dismissPanel,
}) {
  // const [isOpen, setIsOpen] = useState(false);
  const [isMobileView, setIsMobileView] = useState(window.innerWidth <= 767);

  useEffect(() => {
    const handleResize = () => {
      setIsMobileView(window.innerWidth <= 767);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  // const openPanel = () => {
  //   setIsOpen(true);
  // };

  // const dismissPanel = () => {
  //   setIsOpen(false);
  // };

  return (
    <>
      {isMobileView ? (
        <>
          <Button
            // style={{
            //   position: "fixed",
            //   left: "calc(100% - 30px)",
            //   bottom: "calc(100% - 156px)",
            // }}
            icon={isOpen ? <IndentIcon /> : <NumberListIcon />}
            iconOnly
            onClick={openPanel}
          />
          <Panel
            // headerText="Availble Class"
            isOpen={isOpen}
            type={PanelType.smallFixedFar}
            onDismiss={dismissPanel}
            customWidth="100%"
            customHeight="100%"
            isLightDismiss
            className="panel-drawer"
          >
            <AvailbleClass
              isCompactMode={isCompactMode}
              state={state}
              myCard={myCard}
              isMobileView={isMobileView}
            />
          </Panel>
        </>
      ) : null}
    </>
  );
}

export default Drawer;
