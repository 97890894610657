import {
  Button,
  Checkbox,
  Divider,
  Flex,
  Loader,
  Text,
} from "@fluentui/react-northstar";
import { Col, Row } from "antd";
import React from "react";
import MeetingDuration from "../MeetingDuration/MeetingDuration";
import MeetingTimeSlots from "../MeetingTimeSlots/MeetingTimeSlots";
import { getDatesToScheduleAvailability } from "../../utils/utils";
import { useBooking } from "../../../../../../context/BookingContext";
import { useTranslation } from "react-i18next";
import ScheduleDatesCalender from "../../../Calender/ScheduleDatesCalender";

const AvailableHours = (props) => {
  const { t } = useTranslation();
  const translation = t("booking").setting.availableHoursTab;
  const {
    availabilityTimes,
    handleToggleWeekDay,
    handleAddTime,
    handleRemoveTime,
    handleChangeTime,
    handleUpdateDuration,
    handleToggleAllWeekDays,
  } = props;

  const {
    user,
    handleScheduleSlots,
    isScheduling,
    isShowingScheduledSlots,
    isEditing,
    handleStartEditingSlots,
    handleCancelEditing,
    setIsScheduling,
    handleUpdateScheduledSlots,
    selectedDates,
    scheduledSlots,
    isLoadingSlots,
  } = useBooking();

  const handleSave = () => {
    const timeSlotsToSchedule = getDatesToScheduleAvailability(
      availabilityTimes,
      user.mail,
      selectedDates,
      scheduledSlots
    );
    handleScheduleSlots(timeSlotsToSchedule);
  };

  const handleSaveChanges = async () => {
    setIsScheduling(true);
    let allTimeSlots = [];
    let oldRemovedSlotsIds = [];
    let newSlots = [];
    availabilityTimes.forEach((availabilityTime) => {
      allTimeSlots = [...allTimeSlots, ...availabilityTime.timeSlots];
    });
    const removedTimeSlots = allTimeSlots.filter((timeSlot) => {
      return timeSlot.isRemoved || timeSlot.isDeleted;
    });
    const timeSlotsToSchedule = getDatesToScheduleAvailability(
      availabilityTimes,
      user.mail,
      selectedDates,
      scheduledSlots
    );

    if (timeSlotsToSchedule.length) {
      newSlots = timeSlotsToSchedule;
    }

    if (removedTimeSlots.length) {
      oldRemovedSlotsIds = removedTimeSlots.map((timeSlot) => timeSlot.id);
    }

    handleUpdateScheduledSlots(newSlots, oldRemovedSlotsIds);
  };

  return (
    <Flex column gap="gap.large">
      <Text content={translation.message} />
      <Row>
        <Col xxl={12} xl={13} lg={24} md={24} sm={24} style={{ width: "100%" }}>
          <Flex column gap="gap.large">
            <div>
              <Checkbox
                toggle
                checked={availabilityTimes.every(
                  (availabilityTime) => availabilityTime.isSelected
                )}
                onChange={(e, val) => {
                  handleToggleAllWeekDays(val.checked);
                }}
                label={translation.selectAllDays}
                disabled={
                  isLoadingSlots ||
                  isScheduling ||
                  (!isEditing && isShowingScheduledSlots) ||
                  availabilityTimes.some(
                    (availabilityTime) => availabilityTime?.isDisabled
                  )
                }
              />
              {availabilityTimes.map((availabilityTime, i) => (
                <React.Fragment key={i}>
                  <MeetingTimeSlots
                    availabilityTime={availabilityTime}
                    index={i}
                    handleToggleWeekDay={handleToggleWeekDay}
                    handleAddTime={handleAddTime}
                    handleRemoveTime={handleRemoveTime}
                    handleChangeTime={handleChangeTime}
                  />
                  <Divider />
                </React.Fragment>
              ))}
            </div>
            <div>
              {isShowingScheduledSlots ? (
                isEditing ? (
                  <Flex gap="gap.small">
                    <Button
                      onClick={handleCancelEditing}
                      disabled={isScheduling}
                    >
                      Cancel
                    </Button>
                    <Button
                      primary
                      onClick={handleSaveChanges}
                      disabled={isScheduling}
                    >
                      {isScheduling ? (
                        <Flex gap="gap.small" vAlign="center">
                          <Text content={`${translation.saving}...`} />{" "}
                          <Loader size="small" />
                        </Flex>
                      ) : (
                        translation.saveChanges
                      )}
                    </Button>
                  </Flex>
                ) : (
                  <Button primary onClick={handleStartEditingSlots}>
                    {translation.edit}
                  </Button>
                )
              ) : (
                <Button
                  primary
                  onClick={handleSave}
                  disabled={isScheduling || isShowingScheduledSlots}
                >
                  {isScheduling ? (
                    <Flex gap="gap.small" vAlign="center">
                      <Text content={`${translation.scheduling}...`} />{" "}
                      <Loader size="small" />
                    </Flex>
                  ) : (
                    translation.saveSchedule
                  )}
                </Button>
              )}
            </div>
          </Flex>
        </Col>
        <Col xxl={12} xl={11} lg={24} md={24} sm={24} style={{ width: "100%" }}>
          <MeetingDuration handleUpdateDuration={handleUpdateDuration} />
          <ScheduleDatesCalender />
        </Col>
      </Row>
    </Flex>
  );
};

export default AvailableHours;
