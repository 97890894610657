import {
  Button,
  CloseIcon,
  Dialog,
  Flex,
  Text,
} from "@fluentui/react-northstar";
import React from "react";

import { withTranslation } from "react-i18next";

import useMagicBar from "./useMagicBar";

import "./MagicBar.scss";
import { generateGptCreditRequest } from "../../../../Assets/api/api";
import { toast } from "react-toastify";
import { CoinIcon } from "../../../../Assets/icons/icons";

const DropDown = ({ options, selected, onChange, classes, label }) => {
  return (
    <Flex column>
      <label>{label}</label>
      <select
        value={selected}
        className={`gpt-form-select gpt-form-select-sm mb-3 ${classes}`}
        aria-label=".gpt-form-select-sm example"
        onChange={(e) => onChange(e.target.value)}
      >
        {Array.isArray(options) && options.length
          ? options.map((option, index) => (
              <option value={option} key={index}>
                {option}
              </option>
            ))
          : []}
      </select>
    </Flex>
  );
};

const EventMagicBar = ({
  responseHandler,
  user,
  setUser,
  isOpen,
  onCloseHandler,
  type,
  isSavingData,
  t,
}) => {
  const {
    languages,

    isLoading,
    setIsLoading,
    language,
    setLanguage,

    title,
    setTitle,

    generatePrompt,
    prompList,
    isOpenValidationDialog,
    setIsOpenValidationDialog,
  } = useMagicBar({
    responseHandler,
    isOpen,
    user,
    onCloseHandler,
    type,
    setUser,
  });

  const translation = t("events");
  const commonTranslation = translation.common;

  const eventTranslation = translation.body.eventForm.magicBar;

  const handleGenerateGptCreditRequest = async () => {
    try {
      setIsLoading(true);
      const payload = {
        credit: 5,
        TeacherEmailID: user.mail,
        TeacherName: user.name,
        role: user.role,
        status: "pending",
      };
      await generateGptCreditRequest(payload, user.slug, user.accessToken);
      setUser((prev) => ({
        ...prev,
        isRequestedForGptCredit: true,
      }));
      setIsLoading(false);
      toast.success("Gpt Credit request generated successfully!");
    } catch (error) {
      setIsLoading(false);
      toast.error(error.message);
    }
  };

  return (
    <>
      <Dialog
        open={isOpenValidationDialog}
        onConfirm={() => setIsOpenValidationDialog(false)}
        header={commonTranslation.warning}
        content={
          <Flex column>
            <Text>{eventTranslation.magicBarPlaceHolder}</Text>
          </Flex>
        }
        confirmButton={commonTranslation.okay}
        headerAction={{
          icon: <CloseIcon />,
          title: commonTranslation.close,
          onClick: () => {
            setIsOpenValidationDialog(false);
          },
        }}
        styles={{ maxWidth: "600px" }}
      />

      <Dialog
        open={isOpen}
        content={{
          content: (
            <div className={`event-gpt-dialog`} id="gpt-model">
              <div className="title-wrapper">
                <h5 className="title mb-0">Magic ACV bar ✨</h5>

                <Button
                  className="close-button"
                  text
                  icon={<CloseIcon />}
                  onClick={() => {
                    onCloseHandler();
                  }}
                />
              </div>
              <Flex
                style={{
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <p className="description d-flex">
                  <span className="mt-1">
                    {eventTranslation.magicBarInputTitle}
                  </span>
                </p>
                <div className="credit-limit-wrapper ">
                  <div className="credit-icon-wrapper">{CoinIcon()}</div>
                  <div
                    className="credit-score px-2 fw-bold text-muted"
                    style={{
                      display: "flex",
                      flexWrap: "wrap",
                      alignContent: "center",
                    }}
                  >
                    {user.credit}
                  </div>
                </div>
              </Flex>
              <textarea
                value={title}
                rows={3}
                className={`w-100 myTextarea`}
                placeholder={`${eventTranslation.magicBarPlaceHolder}`}
                onChange={(e) => setTitle(e.target.value)}
              ></textarea>

              <div className="dropdown-wrapper">
                <span>
                  <DropDown
                    options={languages}
                    selected={language}
                    onChange={setLanguage}
                    classes={""}
                    label={eventTranslation.language}
                  />
                </span>
              </div>

              <div className="condition-generate-btn-wrapper">
                <p className="condition-text">
                  {eventTranslation.reviewAILimits}
                  <a
                    href="https://www.acv.app/terms"
                    target="_blank"
                    className="tos-text ms-1"
                  >
                    {eventTranslation.termOfService}
                  </a>
                  .
                </p>
                {user.credit <= 0 ? (
                  user.role.toLowerCase() !== "superadmin" ? (
                    <Button
                      loading={isLoading}
                      primary
                      disabled={isLoading || user.isRequestedForGptCredit}
                      className={`generate-button`}
                      onClick={handleGenerateGptCreditRequest}
                      content={
                        user.isRequestedForGptCredit ? "Requested" : "Request"
                      }
                    />
                  ) : (
                    <Button
                      loading={isLoading || isSavingData}
                      primary
                      disabled={isLoading || isSavingData}
                      className={`generate-button`}
                      onClick={() => {
                        window.open(
                          "https://www.acv.app/purchase-credit",
                          "_blank"
                        );
                      }}
                      content={"Purchase Credit"}
                    />
                  )
                ) : (
                  <Button
                    loading={isLoading || isSavingData}
                    primary
                    disabled={isLoading || isSavingData}
                    className={`generate-button`}
                    onClick={(e) => generatePrompt(e)}
                    content={
                      isLoading || isSavingData
                        ? commonTranslation.loading
                        : `${eventTranslation.GenerateIt} 🌟`
                    }
                  />
                )}
              </div>
            </div>
          ),
          styles: {
            marginBottom: 0,
          },
        }}
      />
    </>
  );
};

export default withTranslation()(EventMagicBar);
