import { useEffect, useState } from "react";
import {
  Text,
  Flex,
  CardHeader,
  CardFooter,
  CardBody,
  Card,
  CardColumn,
  Grid,
  Alert,
  InfoIcon,
} from "@fluentui/react-northstar";
import { useHistory, useParams } from "react-router-dom";
import { IconButton } from "@fluentui/react";
import axios from "axios";
import moment from "moment";
import styled from "styled-components";
import { withTranslation } from "react-i18next";

import { useStudentSubmission } from "../../../../../context/StudentSubmissionContext";

import NoAssignmentFound from "../../../../../Assets/images/svg/NoAssignmentFound.svg";

import "./Todos.scss";

// const url = "https://acv-app-api-preprod.azurewebsites.net";
const url = process.env.REACT_APP_EP_URL;

const isAllAssignment = "isAllAssignment";

const AssignmentsSection = styled(Grid)`
  grid-template-columns: repeat(auto-fill, minmax(270px, 1fr));
  grid-gap: 10px;
  margin-top: 15px;
`;

const SkeletonCard = styled(Flex)`
  height: 140px;
  border-radius: 7px !important;
  cursor: pointer;
  @media screen and (max-width: 880px) {
    &.card-md-none {
      display: none;
    }
  }
  @media screen and (max-width: 600px) {
    &.card-sm-none {
      display: none;
    }
  }
`;

const AssignmentTitle = styled(Text)`
  margin-top: 6px;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  height: 24px;
`;
const SubjectTitle = styled(Text)`
  margin-top: 6px;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  height: 24px;
`;

const Todos = (props) => {
  const { user, t } = props;
  const history = useHistory();

  const { subjectCode } = useParams();

  const {
    setCurrentAssignmentData,
    setCurrentPage,
    globalSearch,
    dateFilter,
    setDateFilter,
    selectedSubject,
    selectedTeacher,
    teacherList,
  } = useStudentSubmission();

  const translation = t("assignment").body;
  const assignmentListTranslation = translation.assignmentList;

  const [assignmentList, setAssignmentList] = useState([]);
  const [filteredAssignmentList, setFilteredAssignmentList] = useState([]);
  const [currentAssignmentFilter, setCurrentAssignmentFilter] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [showAlert, setShowAlert] = useState(false);

  useEffect(() => {
    setCurrentPage("todos");
    setDateFilter({ start: null, end: null });
  }, []);

  useEffect(() => {
    const fetchAssignmentList = async () => {
      setIsLoading(true);
      try {
        let response = await axios({
          method: "get",
          url: `${url}/api/assignment/get-student-assignment-by-status${
            user.slug
          }&status=Pending&studentEmailId=${
            user.username || user.mail
          }&subject=${subjectCode}`,
          headers: {
            Authorization: "Bearer " + user.accessToken,
          },
        });

        if (response.data.results?.length) {
          let data = response.data.results
            .filter(
              (assignment) =>
                assignment.AssingmentStudents?.[0]?.submissionStatus
            )
            .map((assignment) => {
              let attachmentOfStudent = assignment.Attachments?.filter(
                (item) => item.role === "student"
              );
              let attachmentOfTeacher = assignment.Attachments?.filter(
                (item) => item.role === "teacher"
              );
              return {
                ...assignment,
                id: assignment.id,
                dueDate: convertToLocalDateString(assignment.dueDate),

                assignmentTitle: assignment.title,
                instruction: assignment.instruction,

                rubricId: assignment.rubricBankId,

                studentAttachments: [
                  {
                    type: "text",
                    info: attachmentOfStudent?.find(
                      (item) => item.type == "text"
                    )?.url,
                    id:
                      attachmentOfStudent?.find((item) => item.type == "text")
                        ?.id || 0,
                  },
                  {
                    type: "voice",
                    info:
                      attachmentOfStudent?.find((item) => item.type == "voice")
                        ?.url || "",
                    id:
                      attachmentOfStudent?.find((item) => item.type == "voice")
                        ?.id || 0,
                  },
                  {
                    type: "video",
                    info:
                      attachmentOfStudent?.find((item) => item.type == "video")
                        ?.url || "",
                    id:
                      attachmentOfStudent?.find((item) => item.type == "video")
                        ?.id || 0,
                  },
                  {
                    type: "link",
                    info:
                      attachmentOfStudent?.find((item) => item.type == "link")
                        ?.url || "",
                    id:
                      attachmentOfStudent?.find((item) => item.type == "link")
                        ?.id || 0,
                  },
                  {
                    type: "file",
                    info: attachmentOfStudent
                      ?.find((item) => item.type == "file")
                      ?.url?.split(","),
                    id:
                      attachmentOfStudent?.find((item) => item.type == "file")
                        ?.id || 0,
                  },
                ],

                attachments: [
                  {
                    type: "text",
                    info: attachmentOfTeacher?.find(
                      (item) => item.type == "text"
                    )?.url,
                  },
                  {
                    type: "voice",
                    info:
                      attachmentOfTeacher?.find((item) => item.type == "voice")
                        ?.url || "",
                  },
                  {
                    type: "video",
                    info:
                      attachmentOfTeacher?.find((item) => item.type == "video")
                        ?.url || "",
                  },
                  {
                    type: "link",
                    info: attachmentOfTeacher?.find(
                      (item) => item.type == "link"
                    )?.url,
                  },
                  {
                    type: "file",
                    info: attachmentOfTeacher
                      ?.find((item) => item.type == "file")
                      ?.url?.split(","),
                  },
                ],

                submittedOn: convertToLocalDateString(
                  assignment.AssingmentStudents[0]?.submissionDate
                ),

                gradedOn: convertToLocalDateString(
                  assignment.AssingmentStudents[0]?.gradedOn
                ),
                submissionStatus:
                  assignment.AssingmentStudents[0]?.submissionStatus,
              };
            });
          let groupedData = data.reduce((group, assignment) => {
            const { submissionStatus } = assignment;
            let newStatus = ["Pending", "Resubmit"].includes(submissionStatus)
              ? "Remaining"
              : "Submitted";
            group[newStatus] = group[newStatus] ?? [];
            group[newStatus].push(assignment);
            return group;
          }, {});
          const sortedRemaining =
            groupedData.Remaining?.sort(
              (a, b) => new Date(a.dueDate) - new Date(b.dueDate)
            ) || [];
          const sortedSubmitted =
            groupedData.Submitted?.sort(
              (a, b) => new Date(a.dueDate) - new Date(b.dueDate)
            ) || [];
          let sortedData = [...sortedRemaining, ...sortedSubmitted];
          setAssignmentList(sortedData);
          setFilteredAssignmentList(sortedData);
        } else {
          setAssignmentList([]);
          setFilteredAssignmentList([]);
        }
      } catch (error) {
        console.log("error", error);
      } finally {
        setIsLoading(false);
        setCurrentAssignmentData(null);
      }
    };
    fetchAssignmentList();
  }, []);

  useEffect(() => {
    setFilteredAssignmentList(() => {
      let data = assignmentList;

      if (globalSearch) {
        data = data.filter((item) =>
          item.title?.toLowerCase()?.includes(globalSearch.toLowerCase())
        );
      }

      if (dateFilter?.start && moment(dateFilter.start).isValid()) {
        data = data.filter((item) =>
          item.dueDate && moment(item.dueDate).isValid()
            ? moment(item.dueDate) >= moment(dateFilter.start)
            : false
        );
      }
      if (dateFilter?.end && moment(dateFilter.end).isValid()) {
        data = data.filter((item) =>
          item.dueDate && moment(item.dueDate).isValid()
            ? moment(item.dueDate) <= moment(dateFilter.end)
            : false
        );
      }
      if (selectedSubject) {
        data = data.filter(
          (item) => item.LessonDatum?.ClassName === selectedSubject
        );
      }
      if (selectedTeacher) {
        let teacherEmailId = teacherList?.find(
          (item) => item.TeacherName === selectedTeacher
        )?.TeacherEmailID;
        if (teacherEmailId) {
          data = data.filter((item) =>
            item.createdBy
              ? item.createdBy === teacherEmailId
              : item.LessonDatum?.TeacherEmailAlias === teacherEmailId
          );
        }
      }
      if (!dateFilter?.start && !dateFilter?.end) {
        setShowAlert(isAllAssignment);
      } else {
        setShowAlert(false);
      }
      return data;
    });
  }, [
    currentAssignmentFilter,
    assignmentList,
    globalSearch,
    dateFilter,
    selectedSubject,
    selectedTeacher,
  ]);

  const convertToLocalDateString = (date) => {
    // let time = moment.utc().format("HH:mm");
    // return date && moment(date).isValid()
    //   ? moment.utc(`${date} ${time}`).local().format("YYYY-MM-DD")
    //   : null;
    return date && moment(date).isValid() ? moment.utc(date).local() : null;
  };

  const assignmentClickHandler = async (assignData) => {
    history.push(
      `/${assignData.LessonDatum?.ClassName}/assignments/${assignData.id}?pending=true`
    );
  };

  const getSubmissionStatus = (submissionStatus) => {
    const statusTranslation = translation.studentSubmissionStatus;
    switch (submissionStatus) {
      case "Pending":
        return statusTranslation.pending;
      case "Grades Pending":
        return statusTranslation.gradesPending;
      case "Resubmit":
        return statusTranslation.resubmit;
      case "Completed":
        return statusTranslation.completed;
      default:
        return submissionStatus;
    }
  };

  const myCard = (assign) => {
    return (
      <Card
        className="mt-3"
        elevated
        styles={{
          borderRadius: "10px",
          ":hover": { backgroundColor: "white" },
          backgroundColor: "white",
          cursor: "pointer",
          boxShadow:
            "0 3.2px 7.2px 0 rgb(0 0 0 / 13%), 0 0.6px 1.8px 0 rgb(0 0 0 / 11%)",
        }}
        onClick={async () => {
          await assignmentClickHandler(assign);
        }}
        fluid
      >
        <CardColumn>
          <CardHeader>
            <AssignmentTitle
              content={assign.title}
              size="large"
              weight="bold"
            />
            <SubjectTitle
              content={assign.LessonDatum.ClassName || ""}
              weight="semibold"
            />
          </CardHeader>
          <CardBody>
            <div>
              <Flex column>
                <Flex gap="gap.smaller">
                  <Text
                    className="text-blue"
                    weight="semibold"
                    content={
                      assign.submissionStatus === "Completed"
                        ? `${assignmentListTranslation.gradedOn}: `
                        : assign.submissionStatus === "Grades Pending"
                        ? `${assignmentListTranslation.submittedOn}: `
                        : `${assignmentListTranslation.due}: `
                    }
                    size="small"
                  />
                  <Text
                    weight="semibold"
                    content={
                      moment(
                        assign.submissionStatus === "Completed"
                          ? assign.gradedOn
                          : assign.submissionStatus === "Grades Pending"
                          ? assign.submittedOn
                          : assign.dueDate
                      ).isValid()
                        ? moment(
                            assign.submissionStatus === "Completed"
                              ? assign.gradedOn
                              : assign.submissionStatus === "Grades Pending"
                              ? assign.submittedOn
                              : assign.dueDate
                          ).format("MMM DD, YYYY | HH:mm")
                        : translation.NA
                    }
                    size="small"
                  />
                </Flex>
              </Flex>
            </div>
          </CardBody>
          <CardFooter>
            <Text
              align="start"
              className={
                assign.submissionStatus == "Pending"
                  ? "submission-pending"
                  : assign.submissionStatus == "Grades Pending"
                  ? "grades-pending"
                  : assign.submissionStatus == "Resubmit"
                  ? "resubmit"
                  : "completed"
              }
              weight="semibold"
              content={
                assign.submissionStatus === "Pending"
                  ? assignmentListTranslation.submissionPending
                  : getSubmissionStatus(assign.submissionStatus)
              }
              size="medium"
              style={{ height: "20px" }}
            />
          </CardFooter>
        </CardColumn>
      </Card>
    );
  };

  return (
    <div className="student-pending-assignment-list">
      <div className="tour-all-assignments assignment-list p-2">
        <Flex vAlign="center" wrap>
          <IconButton
            iconProps={{ iconName: "ChromeBack" }}
            title={translation.back}
            ariaLabel={translation.back}
            onClick={() => {
              history.push({
                pathname: "/",
              });
            }}
          />
          <Text
            color="brand"
            size="larger"
            weight="semibold"
            style={{ marginLeft: "10px", fontSize: "1.4rem" }}
          >
            {assignmentListTranslation.pendingAssignments}
          </Text>
        </Flex>

        <Flex column className="tour-pending-assignments">
          {isLoading ? (
            <AssignmentsSection>
              <SkeletonCard className="skeleton" />
              <SkeletonCard className="card-md-none card-sm-none skeleton" />
              <SkeletonCard className="card-sm-none skeleton" />
            </AssignmentsSection>
          ) : null}
          {filteredAssignmentList?.length && !isLoading ? (
            <div>
              {showAlert ? (
                <Flex hAlign="end">
                  <Alert
                    style={{
                      backgroundColor: "#e6f7ff",
                      border: "1px solid #91d5ff",
                    }}
                    content={
                      <Text>
                        <InfoIcon outline style={{ marginRight: "7px" }} />
                        {showAlert === isAllAssignment
                          ? assignmentListTranslation.allPendingAssignments
                          : ""}
                      </Text>
                    }
                    fitted
                  />
                </Flex>
              ) : null}
              <AssignmentsSection>
                {filteredAssignmentList.map((assignment) => {
                  return (
                    <Flex fill key={assignment.id}>
                      {myCard(assignment)}
                    </Flex>
                  );
                })}
              </AssignmentsSection>
            </div>
          ) : assignmentList.length && !isLoading ? (
            <div>
              <figure
                className="w-100 mt-5 mb-2"
                style={{
                  display: "flex",
                  height: "100%",
                  margin: "auto",
                  justifyContent: "center",
                }}
              >
                <img
                  alt="ACV - Tangible IT"
                  src={NoAssignmentFound}
                  style={{
                    maxWidth: "50%",
                  }}
                  draggable="false"
                />
              </figure>
              <Text
                size="larger"
                weight="semibold"
                content={assignmentListTranslation.noAssignmentForFilter}
                align="center"
              />
            </div>
          ) : !isLoading ? (
            <div>
              <figure
                className="w-100 mt-5 mb-2"
                style={{
                  display: "flex",
                  height: "100%",
                  margin: "auto",
                  justifyContent: "center",
                }}
              >
                <img
                  alt="ACV - Tangible IT"
                  src={NoAssignmentFound}
                  style={{
                    maxWidth: "50%",
                  }}
                  draggable="false"
                />
              </figure>
              <Text
                size="large"
                weight="semibold"
                content={assignmentListTranslation.noAssignmentForSubject}
                align="center"
              />
            </div>
          ) : null}
        </Flex>
      </div>
    </div>
  );
};
export default withTranslation()(Todos);
