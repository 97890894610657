import axios from "axios";


export const getFileFromImageUrl = async (imageUrl, fileName) => {
  try {
    const response = await axios.get(imageUrl, { responseType: "blob" });
    const blob = response.data;

    const file = new File([blob], fileName, { type: blob.type });
    return { success: true, file };
  } catch (error) {
    return { success: false, message: error.message };
  }
};
