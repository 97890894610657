import React from "react";
import { useLayoutEffect } from "react";
import { useState } from "react";
import ReactDOM from "react-dom";

function Portal({ containerId, children }) {
  const [portalContainer, setPortalContainer] = useState(null);

  useLayoutEffect(() => {
    const container = document.getElementById(containerId);

    if (container) {
      setPortalContainer(container);
    }
  }, [containerId]);

  if (!portalContainer) {
    return children || null;
  }

  return ReactDOM.createPortal(children, portalContainer);
}

export default Portal;
