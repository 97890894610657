import {
  Button,
  CloseIcon,
  Dialog,
  Flex,
  Pill,
} from "@fluentui/react-northstar";
import { TextField } from "@fluentui/react";
import { Row } from "antd";
import { withTranslation } from "react-i18next";
import moment from "moment";

import { useEvent } from "../../context/EventContext";

const UserFilter = (props) => {
  const {
    setTags,
    setCategories,
    setIsAudio,
    setIsVideoConf,
    setIsInplace,
    setIsFilterApplied,
    appliedFilter,
    setAppliedFilter,
    setSelectedUserFilter,
    setIsSaveFilterModalOpen,
    deleteUserFilter,
    isSaveFilterModalOpen,
    setIsValidateFilterName,
    filterName,
    saveUserFilter,
    setFilterName,
    isValidateFilterName,
    changeStartDate,
    changeEndDate,
  } = useEvent();

  const { t } = props;

  const translation = t("events");
  const userFilterTranslation = translation.body.userFilter;

  return (
    <>
      <Dialog
        open={isSaveFilterModalOpen}
        onConfirm={() => {
          setIsValidateFilterName(true);
          if (filterName?.length) {
            saveUserFilter();
            setIsSaveFilterModalOpen(false);
          }
        }}
        confirmButton={translation.common.save}
        content={
          <TextField
            value={filterName}
            onChange={(e, value) => {
              setFilterName(value);
            }}
            label={userFilterTranslation.filterName}
            style={{ background: "#F5F5F5", borderRadius: "5px" }}
            borderless={true}
            required
            errorMessage={
              isValidateFilterName
                ? filterName?.length
                  ? ""
                  : userFilterTranslation.validation
                : null
            }
          />
        }
        header={userFilterTranslation.header}
        headerAction={{
          icon: <CloseIcon />,
          title: "Close",
          onClick: () => {
            setIsSaveFilterModalOpen(false);
            setFilterName("");
            setIsValidateFilterName(false);
          },
        }}
      />
      <Row wrap={true} gutter={12} style={{ marginTop: "10px" }}>
        {appliedFilter.categories?.length
          ? appliedFilter.categories
              .filter((item) => item.checked)
              ?.map((item) => (
                <Pill
                  actionable
                  onDismiss={(e, data) => {
                    setCategories((prevData) =>
                      prevData.map((item) =>
                        item.title === data.children
                          ? { ...item, checked: false }
                          : item
                      )
                    );
                    setAppliedFilter((prevData) => ({
                      ...prevData,
                      categories: prevData.categories.filter(
                        (item) => item.title !== data.children
                      ),
                      userFilter: null,
                    }));
                  }}
                  style={{
                    backgroundColor: "#ff00e5d9",
                    color: "white",
                  }}
                  size="small"
                >
                  {item.title}
                </Pill>
              ))
          : null}
        {appliedFilter.tags?.length
          ? appliedFilter.tags
              .filter((item) => item.checked)
              ?.map((item) => (
                <Pill
                  actionable
                  onDismiss={(e, data) => {
                    setTags((prevData) =>
                      prevData.map((item) =>
                        item.title === data.children
                          ? { ...item, checked: false }
                          : item
                      )
                    );
                    setAppliedFilter((prevData) => ({
                      ...prevData,
                      tags: prevData.tags.filter(
                        (item) => item.title !== data.children
                      ),
                      userFilter: null,
                    }));
                  }}
                  style={{
                    backgroundColor: "#5E17EB",
                    color: "white",
                  }}
                  size="small"
                >
                  {item.title}
                </Pill>
              ))
          : null}
        {appliedFilter.isAudio ? (
          <Pill
            actionable
            onDismiss={(e, data) => {
              setIsAudio(false);
              setAppliedFilter((prevData) => ({
                ...prevData,
                isAudio: false,
                userFilter: null,
              }));
            }}
            style={{
              backgroundColor: "#4d4e79",
              color: "white",
            }}
            size="small"
          >
            {userFilterTranslation.audioOnly}
          </Pill>
        ) : null}
        {appliedFilter.isVideoConf ? (
          <Pill
            actionable
            onDismiss={(e, data) => {
              setIsVideoConf(false);
              setAppliedFilter((prevData) => ({
                ...prevData,
                isVideoConf: false,
                userFilter: null,
              }));
            }}
            style={{
              backgroundColor: "#4d4e79",
              color: "white",
            }}
            size="small"
          >
            {userFilterTranslation.videoOnly}
          </Pill>
        ) : null}
        {appliedFilter.isInplace ? (
          <Pill
            actionable
            onDismiss={(e, data) => {
              setIsInplace(false);
              setAppliedFilter((prevData) => ({
                ...prevData,
                isInplace: false,
                userFilter: null,
              }));
            }}
            style={{
              backgroundColor: "#4d4e79",
              color: "white",
            }}
            size="small"
          >
            {userFilterTranslation.inPlace}
          </Pill>
        ) : null}
        {appliedFilter.startDate ? (
          <Pill
            actionable
            onDismiss={(e, data) => {
              changeStartDate(null);
              setAppliedFilter((prevData) => ({
                ...prevData,
                startDate: null,
                userFilter: null,
              }));
            }}
            style={{
              backgroundColor: "#4d4e79",
              color: "white",
            }}
            size="small"
          >
            {userFilterTranslation.startDate} :{" "}
            {moment(appliedFilter.startDate).format("MMMM, DD YYYY")}
          </Pill>
        ) : null}
        {appliedFilter.endDate ? (
          <Pill
            actionable
            onDismiss={(e, data) => {
              changeEndDate(null);
              setAppliedFilter((prevData) => ({
                ...prevData,
                endDate: null,
                userFilter: null,
              }));
            }}
            style={{
              backgroundColor: "#4d4e79",
              color: "white",
            }}
            size="small"
          >
            {userFilterTranslation.endDate}:{" "}
            {moment(appliedFilter.endDate).format("MMMM, DD YYYY")}
          </Pill>
        ) : null}
        <Flex gap="gap.small">
          {appliedFilter.userFilter ? (
            <Button
              onClick={() => {
                deleteUserFilter();
              }}
              content={userFilterTranslation.removeFromSaved}
            />
          ) : (
            <Button
              onClick={() => {
                setIsSaveFilterModalOpen(true);
              }}
              content={userFilterTranslation.saveThisFilter}
            />
          )}
          {
            <Button
              content={userFilterTranslation.removeAll}
              styles={{
                backgroundColor: "#C4314B",
                color: "white",
              }}
              onClick={() => {
                setIsFilterApplied(false);
                setAppliedFilter({
                  categories: [],
                  tags: [],
                  isAudio: false,
                  isVideoConf: false,
                  isInplace: false,
                  userFilter: null,
                });
                setSelectedUserFilter(null);
                setCategories((prevData) =>
                  prevData.map((item) => ({ ...item, checked: false }))
                );
                setTags((prevData) =>
                  prevData.map((item) => ({ ...item, checked: false }))
                );
                setIsAudio(false);
                setIsVideoConf(false);
                setIsInplace(false);
                changeStartDate(null);
                changeEndDate(null);
              }}
            />
          }
        </Flex>
      </Row>
    </>
  );
};

export default withTranslation()(UserFilter);
