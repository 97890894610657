import React, { useState } from "react";
import { Dialog } from "@fluentui/react-northstar";

const RubricConfirmationDialog = (props) => {
  const {
    onOpen,
    onCancel,
    onConfirm,
    confirmButton,
    content,
    header,
    headerAction,
    button,
    ...rest
  } = props;
  const [isOpen, setIsOpen] = useState(false);
  return (
    <Dialog
      open={isOpen}
      onOpen={() => {
        if (onOpen) {
          onOpen();
        }
        setIsOpen(true);
      }}
      onCancel={() => {
        if (onCancel) {
          onCancel();
        }
        setIsOpen(false);
      }}
      onConfirm={() => {
        if (onConfirm) {
          onConfirm();
        }
        setIsOpen(false);
      }}
      confirmButton={confirmButton}
      content={content}
      header={header}
      headerAction={{
        ...headerAction,
        onClick: () => {
          if (headerAction.onClick) {
            headerAction.onClick();
          }
          setIsOpen(false);
        },
      }}
      trigger={button}
      {...rest}
    />
  );
};

export default RubricConfirmationDialog;
