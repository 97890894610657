import { Button, Flex } from "@fluentui/react-northstar";
import { withTranslation } from "react-i18next";

const Footer = ({ step, backHandler, nextHandler, totalStep, loading, t }) => {
  const translation = t("feedback").common;

  return (
    <footer>
      <Flex gap="gap.smaller" wrap style={{ rowGap: "10px" }} hAlign="center">
        <Button
          disabled={loading}
          content={step === 1 ? translation.cancel : translation.back}
          onClick={backHandler}
        />

        <Button
          disabled={loading}
          primary={!loading}
          type="submit"
          content={
            loading
              ? translation.loading
              : step === totalStep
              ? translation.confirm
              : step === totalStep - 1
              ? translation.submit
              : translation.next
          }
          onClick={nextHandler}
          loading={loading}
        />
      </Flex>
    </footer>
  );
};

export default withTranslation()(Footer);
