import React, { useRef } from "react";
import ReactExport from "react-export-excel";
import axios from "axios";

const url = process.env.REACT_APP_EP_URL;

import { Button, DownloadIcon, ExcelIcon } from "@fluentui/react-northstar";
import { useTranslation } from "react-i18next";
import moment from "moment";

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

const ExportClassData = (props) => {
  const { t } = useTranslation();

  const convertUtcToLocalTime = (utcDate, utcTime) => {
    return moment.utc(`${utcDate} ${utcTime}`).local().format("HH:mm");
  };

  return (
    <ExcelFile
      filename="AttendanceReport"
      element={
        <Button
          icon={<DownloadIcon />}
          iconOnly
          title="Export Attendance Report"
          primary
        />
      }
    >
      <ExcelSheet data={props.dataset} name="Attendance Report">
        <ExcelColumn
          label={t("export").attendance[0]}
          value={(col) => col.LessonId}
        />
        <ExcelColumn
          label={t("export").attendance[2]}
          value={(col) =>
            moment(col.LessonDate).format("YYYY-MM-DD") +
            convertUtcToLocalTime(
              moment(col.LessonDate).format("YYYY-MM-DD"),
              col.StartTime
            )
          }
        />
        <ExcelColumn
          label={t("export").attendance[1]}
          value={(col) => col.ClassName}
        />
        <ExcelColumn label={"Class Mode"} value={(col) => col.ClassType} />
        <ExcelColumn
          label={t("export").attendance[3]}
          value={(col) => col.StudentName}
        />
        <ExcelColumn
          label={t("export").attendance[4]}
          value={(col) => col.StudentEmailId}
        />
        <ExcelColumn
          label={t("export").attendance[5]}
          value={(col) =>
            col.Attendance === "Presente"
              ? t("export").allStudents[3]
              : col.Attendance === "Ausente"
              ? t("export").allStudents[4]
              : col.Attendance === "Retardo"
              ? t("export").allStudents[5]
              : col.Attendance === "Sickness"
              ? t("attendance").controls.reasons[0]
              : col.Attendance === "Family emergency"
              ? t("attendance").controls.reasons[1]
              : col.Attendance === "Not feel well"
              ? t("attendance").controls.reasons[2]
              : col.Attendance === "NULL"
              ? ""
              : ""
          }
        />
        <ExcelColumn
          label={t("export").attendance[6]}
          value={(col) =>
            col.Notes === null || col.Notes === "NULL" ? "" : col.Notes
          }
        />
        <ExcelColumn
          label={t("export").attendance[7]}
          value={(col) => col.AttendanceTakenBy}
        />
      </ExcelSheet>
    </ExcelFile>
  );
};

const ExportBulkData = (props) => {
  const { t } = useTranslation();
  return (
    <ExcelFile
      filename={
        "AllStudentReport " + props.dates[0] + " to " + props.dates[1]
        // "AllStudentReport " +
        // props.dates[0]?.toISOString().substring(0, 10) +
        // " to " +
        // props.dates[1]?.toISOString().substring(0, 10)
      }
      element={
        <Button
          icon={<DownloadIcon />}
          iconOnly
          title="Export All Students Report"
          primary
        />
      }
    >
      <ExcelSheet data={props.dataset} name="All Student Report">
        <ExcelColumn
          label={t("export").allStudents[0]}
          value={(col) => col.StudentName}
        />
        <ExcelColumn
          label={t("export").allStudents[1]}
          value={(col) => col.StudentEmailId}
        />
        <ExcelColumn
          label={t("export").allStudents[2]}
          value={(col) => col.ClassName}
        />
        <ExcelColumn
          label={t("export").allStudents[3]}
          value={(col) => col.Presente}
        />
        <ExcelColumn
          label={t("export").allStudents[4]}
          value={(col) => col.Ausente}
        />
        <ExcelColumn
          label={t("export").allStudents[5]}
          value={(col) => col.Retardo}
        />
        <ExcelColumn
          label={t("export").allStudents[6]}
          value={(col) => col.Justificado}
        />
        <ExcelColumn
          label={"Attendance taken"}
          value={(col) =>
            Number(col.Presente) +
            Number(col.Ausente) +
            Number(col.Retardo) +
            Number(col.Justificado)
          }
        />
        <ExcelColumn label={"Total lessons"} value={(col) => col.Total} />
      </ExcelSheet>
    </ExcelFile>
  );
};

const StudentExport = (props) => {
  const { t } = useTranslation();
  const [data, setData] = React.useState([]);
  const [dataset, setDataset] = React.useState({});
  const buttonRef = useRef(null);
  function onSubmit() {
    const cnst = concentValidation();
    if (cnst === false) return;

    setDataset(props.dataset.data.LessonDate);
    const { dataset = {} } = props;
    const { data, head, slug } = dataset;
    axios
      .post(`${url}/api/student/export-student${slug}`, data, head)
      .then((res) => {
        setData(res.data.result);
        buttonRef.current.click();
      });
  }
  return (
    <>
      <Button
        icon={<ExcelIcon />}
        iconOnly
        title="Export Student Report"
        style={{ borderRadius: "4px" }}
        onClick={onSubmit}
      />
      <ExcelFile
        filename={
          "StudentsReport " + dataset[0] + " to " + dataset[1]
          // "StudentsReport " +
          // dataset[0]?.toISOString().substring(0, 10) +
          // " to " +
          // dataset[1]?.toISOString().substring(0, 10)
        }
        element={<div ref={buttonRef} />}
      >
        {data.length && (
          <ExcelSheet
            data={data}
            name={
              "Report " + dataset[0] + " to " + dataset[1]
              // "Report " +
              // dataset[0]?.toISOString().substring(0, 10) +
              // " to " +
              // dataset[1]?.toISOString().substring(0, 10)
            }
          >
            <ExcelColumn
              label={t("export").student[0]}
              value={(col) => col.LessonId}
            />
            <ExcelColumn
              label={t("export").student[1]}
              value={(col) => col.ClassName}
            />
            <ExcelColumn
              label={t("export").student[2]}
              value={(col) => col.LessonDate}
            />
            <ExcelColumn
              label={t("export").student[3]}
              value={(col) => col.StudentName}
            />
            <ExcelColumn
              label={t("export").student[4]}
              value={(col) => col.StudentEmailId}
            />
            <ExcelColumn
              label={t("export").student[5]}
              value={(col) =>
                col.Attendance === "Presente"
                  ? t("export").allStudents[3]
                  : col.Attendance === "Ausente"
                  ? t("export").allStudents[4]
                  : col.Attendance === "Retardo"
                  ? t("export").allStudents[5]
                  : col.Attendance === "Sickness"
                  ? t("attendance").controls.reasons[0]
                  : col.Attendance === "Family emergency"
                  ? t("attendance").controls.reasons[1]
                  : col.Attendance === "Not feel well"
                  ? t("attendance").controls.reasons[2]
                  : col.Attendance === "NULL"
                  ? ""
                  : ""
              }
            />
            <ExcelColumn
              label={t("export").student[6]}
              value={(col) =>
                col.Notes === null || col.Notes === "NULL" ? "" : col.Notes
              }
            />
            <ExcelColumn
              label={t("export").student[7]}
              value={(col) => col.AttendanceTakenBy}
            />
          </ExcelSheet>
        )}
      </ExcelFile>
    </>
  );
};

const LessonExport = (props) => {
  const { t } = useTranslation();

  const [data, setData] = React.useState([]);
  const [dataSet, setdataset] = React.useState({});
  const buttonRef = useRef(null);
  function onSubmit() {
    const { dataset } = props;
    setdataset(props?.dataset?.dateData);
    const { dateData, head, slug } = dataset;
    axios
      .post(`${url}/api/startup/export-lesson-data${slug}`, dateData, head)
      .then((res) => {
        setData(res.data.message);
        buttonRef.current.click();
      });
  }

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const day = String(date.getDate()).padStart(2, "0");
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const year = date.getFullYear();
    return `${day}-${month}-${year}`;
  };
  const formattedDate = "LessonDataUpdate_" + formatDate(dataSet.startDate);

  return (
    <>
      {/* <Button
        icon={<ExcelIcon />}
        iconOnly
        title="Export Student Report"
        style={{ borderRadius: "4px" }}
        onClick={onSubmit}
      /> */}
      <Button
        icon={<DownloadIcon />}
        content={t("startUp").controls.action[0]}
        title={t("startUp").controls.action[0]}
        onClick={onSubmit}
      />
      <ExcelFile filename={formattedDate} element={<span ref={buttonRef} />}>
        {data.length && (
          // <ExcelSheet
          //   data={data}
          //   name={
          //     "Report " + dataSet.startDate + " to " + dataSet.endDate
          //     // "Report " +
          //     // dataSet.startDate?.toISOString().substring(0, 10) +
          //     // " to " +
          //     // dataSet.endDate?.toISOString().substring(0, 10)
          //   }
          // >
          //   <ExcelColumn label="Title" value={(col) => col.Title} />
          //   <ExcelColumn label="ClassID" value={(col) => col.ClassID} />
          //   <ExcelColumn label="LessonDate" value={(col) => col.LessonDate} />
          //   <ExcelColumn label="ClassName" value={(col) => col.ClassName} />
          //   <ExcelColumn label="StartTime" value={(col) => col.StartTime} />
          //   <ExcelColumn label="EndTime" value={(col) => col.EndTime} />
          //   <ExcelColumn
          //     label="TeacherEmailAlias"
          //     value={(col) => col.TeacherEmailAlias}
          //   />
          //   {/* <ExcelColumn
          //     label="StartTimeNumber"
          //     value={(col) => col.StartTimeNumber}
          //   />
          //   <ExcelColumn
          //     label="EndTimeNumber"
          //     value={(col) => col.EndTimeNumber}
          //   /> */}
          //   <ExcelColumn
          //     label="FacultadMateria"
          //     value={(col) => col.FacultadMateria}
          //   />
          //   <ExcelColumn label="ClassType" value={(col) => col.ClassType} />
          //   <ExcelColumn label="Tema" value={(col) => col.Tema} />
          //   <ExcelColumn label="Tarea" value={(col) => col.Tarea} />
          // </ExcelSheet>
          <ExcelSheet
            data={data}
            name={"Report " + dataSet.startDate + " to " + dataSet.endDate}
          >
            <ExcelColumn
              label={"LessonDate"}
              value={(col) => {
                const dateString = col.LessonDate;
                const indexOfT = dateString.indexOf("T");
                const dateWithoutTime = dateString.substring(0, indexOfT);
                return dateWithoutTime;
              }}
            />
            <ExcelColumn label={"StartTime"} value={(col) => col.StartTime} />
            <ExcelColumn label={"EndTime"} value={(col) => col.EndTime} />
            {/* <ExcelColumn label={"ClassName"} value={(col) => col.ClassName} /> */}
            <ExcelColumn
              label={"SubjectType"}
              value={(col) => {
                const classNameParts = col.ClassName.split(" - ");
                return classNameParts[0];
              }}
            />
            <ExcelColumn
              label={"SubjectName"}
              value={(col) => {
                const classNameParts = col.ClassName.split(" - ");
                return classNameParts[1];
              }}
            />
            <ExcelColumn
              label={"SubjectCode"}
              value={(col) => {
                const classNameParts = col.ClassName.split(" - ");
                return classNameParts[2];
              }}
            />
            <ExcelColumn label={"Title"} value={(col) => col.Title} />
            <ExcelColumn
              label={"TeacherEmailAlias"}
              value={(col) => col.TeacherEmailAlias}
            />
            <ExcelColumn
              label={"FacultadMateria"}
              value={(col) => col.FacultadMateria}
            />
            <ExcelColumn label={"Tema"} value={(col) => col.Tema} />
          </ExcelSheet>
        )}
      </ExcelFile>
    </>
  );
};

const concentValidation = () => {
  let consent = window.localStorage.getItem("consent");
  // console.log("concent::", consent, window.localStorage.getItem("consent"));
  if ((consent && consent === false) || !consent) {
    if (confirm("Are you sure you want to download this file?") === true) {
      window.localStorage.setItem("consent", true);
      return true;
    } else {
      return false;
    }
  } else if (consent) {
    return true;
  } else {
    return false;
  }
};

export { ExportClassData, ExportBulkData, StudentExport, LessonExport };
