import axios from "axios";
import { createContext, useContext, useEffect, useState } from "react";
import userSvg from "../Assets/images/svg/user.svg";
import DefaultGroupImage from "../Assets/images/group-default.png";

const url = process.env.REACT_APP_EP_URL;

const FeedBack = createContext();

export const useFeedBack = () => {
  return useContext(FeedBack);
};

export const FeedBackContext = (props) => {
  const { children, user } = props;
  const [search, setSearch] = useState(""); // Search input state
  const [groups, setGroups] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [classrooms, setClassrooms] = useState([]);
  const [pagination, setPagination] = useState({
    totalPages: 1,
    currentPage: 1,
    limit: 20,
  });

  const getGroups = async (search = "") => {
    setIsLoading(true);
    try {
      const { data } = await axios({
        method: "GET",
        url: `${url}/api/feedback/group/get-group-by-userid/${user.mail}${user.slug}&search=${search}`,
        headers: {
          Authorization: `Bearer ${user.accessToken}`,
        },
      });
      if (Array.isArray(data?.result) && data.result.length) {
        let formattedData = data.result.map(
          ({ groupId, groupImage, groupName, GroupUsers, ...rest }) => {
            return {
              ...rest,
              groupId,
              groupImageUrl: groupImage ? groupImage : DefaultGroupImage,
              groupName,
              students: GroupUsers?.length
                ? GroupUsers.map(({ users, ...rest }) => {
                    let { email, firstName, lastName, photo } = users ?? {};

                    return {
                      ...rest,
                      studentEmailId: email,
                      name: `${firstName} ${lastName ?? ""}`,
                      studentImageUrl: photo ? photo + user?.SASToken : userSvg,
                    };
                  })
                : [],
            };
          }
        );
        setGroups(formattedData);
      } else {
        setGroups([]);
      }
    } catch (error) {
      console.log(error);
    } finally {
      setIsLoading(false);
    }
  };

  const getClassrooms = async (limit, page, search) => {
    setIsLoading(true);
    try {
      const { data } = await axios({
        url: `${url}/api/classroom${user.slug}&limit=${limit}&page=${page}&search=${search}`,
        headers: {
          Authorization: `Bearer ${user.accessToken}`,
        },
      });
      const classroomsData = data?.result?.data;
      const paginationData = data?.result?.pagination;
      if (paginationData) {
        setPagination({
          ...paginationData,
        });
      }
      if (classroomsData?.length) {
        setClassrooms(
          classroomsData.map(
            ({ ClassID, ClassName, StudentData, ImageURL }) => ({
              classroomId: ClassID,
              classroomTitle: ClassName,
              students: StudentData?.length
                ? StudentData.map(
                    ({ firstName, lastName, email, photo, ...rest }) => ({
                      ...rest,
                      studentEmailId: email,
                      name: `${firstName}${lastName ?? ""}`,
                      studentImageUrl: photo
                        ? photo +
                          process.env.REACT_APP_AZURE_SAS_TOKEN.replace(
                            /"/g,
                            ""
                          )
                        : null,
                    })
                  )
                : [],
              classroomsImageUrl: ImageURL,
            })
          )
        );
      } else {
        setClassrooms([]);
      }
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
    }
  };

  return (
    <FeedBack.Provider
      value={{
        groups,
        isLoading,
        getGroups,
        classrooms,
        pagination,
        setPagination,
        search,
        setSearch,
        getClassrooms,
      }}
    >
      {children}
    </FeedBack.Provider>
  );
};
