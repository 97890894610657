import React, { useEffect, useState } from "react";
import "./MonthSlider.css";
import { useBooking } from "../../../../context/BookingContext";

const months = [
  //   "Jan",
  //   "Feb",
  //   "Mar",
  //   "Apr",
  //   "May",
  //   "Jun",
  // "Jul",
  "Aug",
  "Sep",
  "Oct",
  "Nov",
  "Dec",
];

const MonthSlider = () => {
  const [selectedMonth, setSelectedMonth] = useState(0);
  const [months, setMonths] = useState([]);

  const handleMonthClick = (index) => {
    setSelectedMonth(index);
  };

  const { date, handleChangeMonth, handleResetDays, getNextSixMonths } =
    useBooking();

  useEffect(() => {
    const months = getNextSixMonths();
    setMonths(months);
  }, []);

  return (
    <div className="month-slider">
      <div className="month-labels">
        {months.map((month, index) => (
          <span
            key={index}
            className={`month-label ${
              selectedMonth === index ? "selected" : ""
            }`}
            style={{
              left: `calc(${index * (100 / (months.length - 1))}% - 10px)`,
            }}
            onClick={() => {
              handleResetDays(date.clone().month());
              handleMonthClick(index);
              handleChangeMonth(month);
            }}
          >
            {month}
          </span>
        ))}
      </div>
      <div className="slider-track">
        <div
          className="slider-thumb"
          style={{
            left: `calc(${
              selectedMonth * (100 / (months.length - 1))
            }% - 10px)`,
          }}
        />
      </div>
    </div>
  );
};

export default MonthSlider;
