import React, { Component } from "react";
// import { bool, func, string } from 'prop-types';
import {
  BookmarkIcon,
  Button,
  CalendarIcon,
  CallVideoIcon,
  Dialog,
  EditIcon,
  Flex,
  LocationIcon,
  MicIcon,
  ShareAltIcon,
  Text,
  TrashCanIcon,
  UserFriendsIcon,
} from "@fluentui/react-northstar";
import { Tag, Tooltip } from "antd";
import moment from "moment";
import { withTranslation } from "react-i18next";
import styled from "styled-components";
import { ResponsiveImage } from "../blocks/Images";
import StackedAvatar from "../blocks/StackedAvatar";
import "./Event.css";

import ShareEvent from "../../pages/Events/Components/ShareEvent";
import { NotificationAlert } from "../Notification/Notification";

const Section = styled.div`
  width: 100%;
  /* height: 402px; */
  margin-top: 24px;
  background: #ffffff;
  box-shadow: 0px 0.3px 0.9px rgba(0, 0, 0, 0.07),
    0px 1.6px 3.6px rgba(0, 0, 0, 0.11);
  border-radius: 8px;
  position: relative;
  border-bottom: ${({ isBundleEditingTheEvent }) =>
    isBundleEditingTheEvent ? "3.5px solid #1890ff" : "none"};
  .second {
    margin: 20px 20px 10px 20px;
  }
`;

const FirstSection = styled.div`
  height: 133.44px;
  display: flex;
  flex-direction: row;
  align-items: start;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  background: ${(props) => `url(${!!props.coverImage && props.coverImage})`};
  background-size: cover !important;
  position: relative;
  :hover {
    .event-tag,
    .bookmark-icon {
      z-index: 2;
    }
    .event-type-overlay {
      opacity: 0.9;
    }
  }
`;

const EventTypeOverLay = styled.div`
  position: absolute;
  opacity: 0;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  color: #fff;
  background: linear-gradient(
    0deg,
    rgba(255, 255, 255, 1) 3%,
    rgba(170, 80, 254, 1) 54%,
    rgba(132, 1, 254, 1) 100%
  );
  opacity: 0;
  .text-wrapper {
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    .event-type-text {
      margin-top: 10px;
    }
  }
`;

const Favourite = styled.button`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 8px;
  position: absolute;
  width: 24px;
  height: 24px;
  right: 16px;
  top: 0px;
  margin-top: 16px;
  background: #ffffff;
  border-radius: 50px;
  cursor: pointer;
  border: none;
`;

const FavouriteIcon = styled.div`
  position: absolute;
  left: 24.33%;
  right: 0%;
  top: 20.86%;
  bottom: 10.41%;

  background: ${(props) =>
    props.isFavourite
      ? "url(/Images/Events/favourite.svg)"
      : "url(/Images/Events/shape.svg)"};
  background-repeat: no-repeat;
  cursor: pointer;

  :hover {
    background: ${(props) =>
    props.isFavourite
      ? "url(/Images/Events/shape.svg)"
      : "url(/Images/Events/favourite.svg)"};
    background-repeat: no-repeat;
  }
`;

const EventTag = styled.div`
  display: flex;
  flex-direction: row;
  padding: 4px 12px 4px 6px;
  margin-top: 16px;

  background: rgba(255, 0, 229, 0.85);
  backdrop-filter: blur(8px);

  border-radius: 0px 12px 12px 0px;

  p {
    width: ${({ selectedColumns }) =>
    selectedColumns === 4 ? "65px" : "56px"};
    height: 16px;
    left: 6px;
    top: 4px;

    font-style: normal;
    font-weight: 700;
    font-size: ${({ selectedColumns }) =>
    selectedColumns === 4 ? "11px" : "9px"};
    line-height: 16px;

    letter-spacing: 0.02em;
    text-transform: uppercase;
    text-overflow: ellipsis;

    color: #ffffff;

    flex: none;
    order: 0;
    flex-grow: 0;
    margin: auto;
    text-align: center;
    overflow: hidden;
  }
`;

const EventStatus = styled.div`
  position: absolute;
  padding: 3px 6px 3px 3px;

  background: ${({ isEventPublished }) =>
    isEventPublished ? "#1db9c3" : "#ffb900"};
  backdrop-filter: blur(8px);

  border-radius: 0px 12px 12px 0px;
  top: 47px;
  left: 0;

  p {
    font-weight: 700;
    font-size: 9px;

    text-transform: uppercase;
    text-overflow: ellipsis;
    color: #ffffff;
    margin-bottom: 0;
  }
`;

const LiveMark = styled.div`
  width: 8px;
  background-color: red;
  height: 8px;
  border-radius: 50%;
  margin-right: 2px;
`;

const LiveText = styled(Text)`
  font-size: ${({ selectedColumns }) =>
    selectedColumns === 4 ? "14px" : "12px"};
`;

const EventTitle = styled.div`
  font-style: normal;
  font-weight: 700;
  font-size: ${({ selectedColumns }) =>
    selectedColumns === 4 ? "19px" : "16px"};
  line-height: 20px;
  color: #242424;
  flex: none;
  order: 0;
  flex-grow: 0;
  margin: 8px 0px;
  cursor: pointer;
  @media screen and (max-width: 768px) {
    font-size: 16px;
  }
`;

const Timing = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0px;

  position: static;
  width: 115px;
  height: 29px;
  left: 0px;
  top: 0px;

  .icon {
    position: static;
    width: 20px;
    height: 20px;
    left: calc(50% - 20px / 2 - 47.5px);
    top: calc(50% - 20px / 2);
  }

  .text {
    font-style: normal;
    font-weight: 500;
    font-size: ${({ selectedColumns }) =>
    selectedColumns === 4 ? "17px" : "14px"};
    line-height: 23px;
    color: #424242;
    flex: none;
    order: 1;
    flex-grow: 0;
    margin: 0px 9px;
    // for overflow text
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    width: 175px;
  }
  @media screen and (max-width: 768px) {
    .text {
      font-size: 14px;
    }
  }
`;

const Attendees = styled.div`
  margin-top: 8px;
  margin-bottom: 10px;
  .icon {
    width: 19px;
    height: 19px;
  }
  .second-node {
    margin-left: 10px;
  }
`;

const RegisterButton = styled.button`
  background: #ffffff;
  border: 1px solid #c7c7c7;
  box-sizing: border-box;
  box-shadow: 0px 0.3px 0.9px rgba(0, 0, 0, 0.07),
    0px 1.6px 3.6px rgba(0, 0, 0, 0.11);
  border-radius: 4px;
  font-family: "Segoe UI";
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 19px;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  padding: 5px 5px;
`;

const DateBox = styled.div`
  position: absolute;
  left: 16px;
  top: 104px;
  background: #ffffff;
  box-shadow: 0px 3.2px 7.2px rgba(0, 0, 0, 0.13),
    0px 0.6px 1.8px rgba(0, 0, 0, 0.11);
  border-radius: 8px;
  padding: 6px 12px;

  .day {
    font-family: "Karla";
    font-style: normal;
    font-weight: 700;
    font-size: ${({ selectedColumns }) =>
    selectedColumns === 4 ? "22px" : "18px"};
    line-height: 19px;
    text-align: center;
    color: #242424;
  }

  .month {
    font-family: "Karla";
    font-style: normal;
    font-weight: 400;
    font-size: ${({ selectedColumns }) =>
    selectedColumns === 4 ? "13px" : "10px"};
    line-height: 13px;
    text-align: center;
    text-transform: uppercase;
    color: #242424;
  }
  @media screen and (max-width: 768px) {
    .day {
      font-size: 18px;
    }
    .month {
      font-size: 10px;
    }
  }
`;

const CategoryTag = styled.span`
  font-size: ${({ selectedColumns }) =>
    selectedColumns === 4 ? "14px" : "10px"};
  @media screen and (max-width: 768px) {
    font-size: 10px;
  }
`;

const MeetLinkSeatsSection = styled.div`
  min-height: 58px;
`;

const SeatsAvilable = styled.div`
  min-height: 29px;
  display: flex;

  font-size: ${({ selectedColumns }) =>
    selectedColumns === 4 ? "17px" : "14px"};
  p {
    margin: 0 0 0 9px;

    span {
      font-weight: bold;
    }
  }
`;

const RegistrationCloseDate = styled.div`
  position: absolute;
  top: 104px;
  right: 0px;
  box-shadow: 0px 3.2px 7.2px rgba(0, 0, 0, 0.13),
    0px 0.6px 1.8px rgba(0, 0, 0, 0.11);
  border-radius: 8px 0 0 8px;
  padding: 6px 3px 6px 6px;
  background: #ffffff;

  p {
    margin-bottom: 0;
  }

  .time {
    font-weight: 700;
    font-size: 12px;
    line-height: 19px;
    text-align: center;
    color: #242424;
  }
  .text {
    font-weight: 400;
    font-size: 10px;
    line-height: 13px;
    text-align: center;
    color: #242424;
  }
`;

const RegistrationClosedText = styled.p`
  margin-bottom: 0;
  font-weight: 600;
`;

const RegistrationCancelButton = styled(Button)`
  min-width: unset;
  border-radius: 4px;
  font-family: "Segoe UI";
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 19px;
  padding: 5px 5px;
`;

const RegisterEventButton = styled(Button)`
  min-width: unset;
  border-radius: 4px;
  font-family: "Segoe UI";
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 19px;
  padding: 5px 5px;
`;

const registered = "registered";
const attended = "attended";

class Event extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isFavourite: props.data?.isFavourite || false,
      selectedCategories: [],
      selectedTags: [],
    };
  }

  formatCategoryTags = ({ data, categories, tags }) => {
    let dataCategories = [];
    let dataTags = [];
    if (typeof data.categories === "object" && Array.isArray(data.categories)) {
      dataCategories = data.categories;
    } else {
      try {
        let items = JSON.parse(data.categories);
        if (typeof items === "object" && Array.isArray(items)) {
          dataCategories = items;
        }
      } catch (error) {
        if (data.categories && typeof data.categories === "string") {
          let items = [data.categories];
          dataCategories = items;
        }
      }
    }
    const listCat = dataCategories?.map((c) => {
      const [temp] = categories.filter((s) => s.id === c);
      return temp;
    });
    if (typeof data.tags === "object" && Array.isArray(data.tags)) {
      dataTags = data.tags;
    } else {
      try {
        let items = JSON.parse(data.tags);
        if (typeof items === "object" && Array.isArray(items)) {
          dataTags = items;
        }
      } catch (error) {
        if (data.tags && typeof data.tags === "string") {
          let items = [data.tags];
          dataTags = items;
        }
      }
    }
    const listTags = dataTags?.map((c) => {
      const [temp] = tags.filter((s) => s.id === c);
      return temp;
    });
    return { selectedCategories: listCat, selectedTags: listTags };
  };

  componentDidMount() {
    const { data, categories, tags } = this.props;
    const { selectedCategories, selectedTags } = this.formatCategoryTags({
      data,
      categories,
      tags,
    });
    this.setState({ selectedCategories, selectedTags });
  }

  componentDidUpdate(prevProps, prevState) {
    const { data, categories, tags } = this.props;

    const { selectedCategories, selectedTags } = this.formatCategoryTags({
      data,
      categories,
      tags,
    });

    if (
      JSON.stringify(prevState.selectedCategories) !==
      JSON.stringify(selectedCategories) ||
      JSON.stringify(prevState.selectedTags) !== JSON.stringify(selectedTags)
    ) {
      this.setState({ selectedCategories, selectedTags });
    }
  }

  updateFavourite = (isFavourite) => {
    const { data, updateFavourite } = this.props;
    this.setState({
      isFavourite,
    });
    data.isFavourite = isFavourite;
    updateFavourite(data);
  };

  shareEventHandler = async (eventTranslation) => {
    const eventId = this.props.data?.id;
    const eventUrl = `${process.env.REACT_APP_URI}/event/details/${eventId}`;
    if (eventId) {
      if (navigator.share) {
        try {
          await navigator.share({
            text: eventTranslation.event,
            title: eventTranslation.event,
            url: eventUrl,
          });
        } catch {
          // navigator.clipboard.writeText(eventUrl);
          // NotificationAlert("Event link copied successfully", "success");
        }
      } else {
        navigator.clipboard.writeText(eventUrl);
        NotificationAlert(eventTranslation.linkCopied, "success");
      }
    }
  };

  render() {
    const {
      data,
      type,
      isActiveStatus,
      onRegister,
      onDelete,
      onCancelRegistration,
      attendeesModal,
      selectedColumns,
      loading = {},
      editButtonClickHandler,
      isBundleEditingTheEvent,
      t,
    } = this.props;

    const { isFavourite, selectedCategories, selectedTags } = this.state;

    const startDate =
      data.startDate && moment(data.startDate).isValid()
        ? moment(data.startDate).format("hh:mm A")
        : "N/A";
    const endDate =
      data.endDate && moment(data.endDate).isValid()
        ? moment(data.endDate).format("hh:mm A")
        : "N/A";

    const renderCategories = selectedCategories?.slice(0, 2);
    const remainingCategories =
      selectedCategories?.slice(2).length &&
      selectedCategories?.slice(2).map((s) => s?.title);

    const renderTags = selectedTags?.slice(0, 2);
    const remainingTags =
      selectedTags?.slice(2).length &&
      selectedTags?.slice(2).map((s) => s?.title);

    const coverImages = !!data.coverImage
      ? `${data.coverImage}${this.props.user?.SASToken}`
      : "/Images/Events/unsplash_3l3RwQdHRHg.png";

    const translation = t("events");
    const commonTranslation = translation.common;
    const eventTranslation = translation.body.eventCard;

    const isLive =
      // !!data.meetingURL &&
      data.teacherId !== this.props.user?.mail &&
      moment(data.startDate).isValid &&
      moment(data.endDate).isValid &&
      moment().isSameOrAfter(
        moment(moment.utc(data.startDate).local().format())
      ) &&
      moment().isSameOrBefore(
        moment(moment.utc(data.endDate).local().format())
      );

    const renderMeetingLocation = () => {
      return !!data.isMSTeamMeeting
        ? eventTranslation.MSTeamsMeeting
        : !!data.isGoogleMeetMeeting
          ? eventTranslation.GoogleMeetMeeting
          : data.location;
    };

    return (
      <Section isBundleEditingTheEvent={isBundleEditingTheEvent}>
        <FirstSection coverImage={coverImages}>
          {renderCategories?.length ? (
            <EventTag selectedColumns={selectedColumns} className="event-tag">
              <Tooltip
                overlayStyle={{ fontSize: 10, height: 16 }}
                placement="bottom"
                title={renderCategories?.[0]?.title}
              >
                <p>{renderCategories?.[0]?.title}</p>
              </Tooltip>
            </EventTag>
          ) : null}
          <EventStatus
            selectedColumns={selectedColumns}
            className="event-tag"
            isEventPublished={data?.status?.toLowerCase() === "published"}
          >
            <Tooltip
              overlayStyle={{ fontSize: 10, height: 16 }}
              placement="bottom"
              title={data?.status}
            >
              <p>
                {data?.status === "PUBLISHED"
                  ? commonTranslation.status.published
                  : commonTranslation.status.draft}
              </p>
            </Tooltip>
          </EventStatus>
          {data?.status?.toLowerCase() !== "draft" && (
            <Favourite
              className="bookmark-icon"
              onClick={() => this.updateFavourite(!isFavourite)}
              disabled={loading.isLoading}
            >
              <BookmarkIcon
                outline={!isFavourite}
                size="small"
                style={{ color: isFavourite ? "#F1A100" : "" }}
              />
            </Favourite>
          )}
          {!!data.isMSTeamMeeting ? (
            <EventTypeOverLay
              type={data.eventType}
              className="event-type-overlay"
            >
              <Flex column className="text-wrapper" fill>
                {data.eventType === "VIDEO_CONFERENCE" ? (
                  <>
                    <Flex>
                      <CallVideoIcon size="large" />
                    </Flex>
                    <Flex>
                      <Text className="event-type-text" size="large">
                        Video Conference
                      </Text>
                    </Flex>
                  </>
                ) : data.eventType === "AUDIO" ? (
                  <>
                    <Flex>
                      <MicIcon size="large" />
                    </Flex>
                    <Flex>
                      <Text className="event-type-text" size="large">
                        Audio Only
                      </Text>
                    </Flex>
                  </>
                ) : null}
              </Flex>
            </EventTypeOverLay>
          ) : !!data.isGoogleMeetMeeting ? (
            <EventTypeOverLay className="event-type-overlay">
              <Flex column className="text-wrapper" fill>
                <Flex>
                  <CallVideoIcon size="large" />
                </Flex>
                <Flex>
                  <Text className="event-type-text" size="large">
                    Google Meet
                  </Text>
                </Flex>
              </Flex>
            </EventTypeOverLay>
          ) : null}
        </FirstSection>
        {!!data.startDate && (
          <DateBox selectedColumns={selectedColumns}>
            <div className="day">{moment(data.startDate).date()}</div>
            <div className="month">{moment(data.startDate).format("MMMM")}</div>
          </DateBox>
        )}
        {!data.isRegistrationClosed &&
          data.registrationCloseDate &&
          moment(data.registrationCloseDate).isValid() &&
          moment(data.registrationCloseDate).isAfter(moment()) ? (
          <RegistrationCloseDate>
            <p className="time">
              {moment(data.registrationCloseDate).format("hh:mm A | DD, MMM")}
            </p>
            <p className="text">
              <span> {eventTranslation.registrationClosing}</span>
            </p>
          </RegistrationCloseDate>
        ) : null}
        <div className="second">
          <Flex space="between" vAlign="center">
            {selectedTags?.length ? (
              <div>
                <Flex wrap style={{ rowGap: "5px" }}>
                  {!!renderTags &&
                    renderTags.map((s) => (
                      <Tooltip
                        key={s?.id}
                        overlayStyle={{ fontSize: 10, height: 16 }}
                        placement="bottom"
                        title={s?.title}
                      >
                        <Tag
                          style={{
                            padding: "1px 7px",
                            color: "white",
                            backgroundColor: "#5E17EB",
                            borderRadius: "6px",
                            marginRight: "5px",
                          }}
                          checked
                        >
                          <CategoryTag selectedColumns={selectedColumns}>
                            {isLive && selectedTags.length > 2
                              ? s?.title?.length > 6
                                ? `${s?.title.substring(0, 6)}...`
                                : s?.title
                              : s?.title?.length > 9
                                ? `${s?.title.substring(0, 9)}...`
                                : s?.title}
                          </CategoryTag>
                        </Tag>
                      </Tooltip>
                    ))}
                  {!!remainingTags && remainingTags.length ? (
                    <Tooltip
                      overlayStyle={{ fontSize: 10, height: 16 }}
                      placement="bottom"
                      title={remainingTags.join(", ")}
                    >
                      <Tag
                        style={{
                          padding: "1px 1px",
                          borderRadius: "6px",
                          marginRight: "3px",
                        }}
                        checked
                      >
                        <CategoryTag
                          selectedColumns={selectedColumns}
                        >{`+${remainingTags.length}`}</CategoryTag>
                      </Tag>
                    </Tooltip>
                  ) : (
                    <div></div>
                  )}
                </Flex>
              </div>
            ) : (
              <div style={{ height: "20px" }}></div>
            )}
            {isLive ? (
              <Flex vAlign="center">
                <LiveMark />
                <LiveText weight="bold" selectedColumns={selectedColumns}>
                  {eventTranslation.live}
                </LiveText>
              </Flex>
            ) : null}
          </Flex>
          <div
            className="pointer"
            onClick={() => {
              if (data?.bundleId) {
                this.props.history.push(
                  `/event/details/${data.id}?events=${type}&bundleId=${data.bundleId}`
                )
              }else{
                this.props.history.push(
                  `/event/details/${data.id}?events=${type}`
                )

              }
            }
            }
          >
            <EventTitle
              style={{
                height: "42px",
                overflow: "hidden",
                wordBreak: "break-word",
                WebkitLineClamp: "2",
                display: "-webkit-box",
                WebkitBoxOrient: "vertical",
              }}
              selectedColumns={selectedColumns}
            >
              <Tooltip
                overlayStyle={{ fontSize: 10, height: 16 }}
                placement="bottom"
                title={data.title}
              >
                <span> {data.title}</span>
              </Tooltip>
            </EventTitle>
            <Timing selectedColumns={selectedColumns}>
              <CalendarIcon outline style={{ color: "#6264a7" }} />
              <span className="text">{`${data.startDate
                ? moment(data.startDate).format("DD, MMM")
                : "N/A"
                } - ${data.endDate ? moment(data.endDate).format("DD, MMM") : "N/A"
                }`}</span>
            </Timing>
            <Timing selectedColumns={selectedColumns}>
              <ResponsiveImage src="/Images/Events/clock.svg" />
              <span className="text">{`${startDate} - ${endDate}`}</span>
            </Timing>
            <MeetLinkSeatsSection>
              {!!data.isMSTeamMeeting ||
                !!data.isGoogleMeetMeeting ||
                data.location?.length ? (
                <Timing selectedColumns={selectedColumns}>
                  <LocationIcon outline style={{ color: "#6264a7" }} />
                  <Tooltip
                    overlayStyle={{ fontSize: 10, height: 16 }}
                    placement="bottom"
                    title={renderMeetingLocation()}
                  >
                    <span className="text">{renderMeetingLocation()}</span>
                  </Tooltip>
                </Timing>
              ) : (
                <div style={{ height: "20px" }}></div>
              )}

              <SeatsAvilable selectedColumns={selectedColumns}>
                {!data.isRegistrationClosed && (
                  <Flex wrap vAlign="center">
                    <UserFriendsIcon outline />

                    <p>
                      {data.isLimitedSeats &&
                        data.maxSeats - data.attendees?.length > 0 ? (
                        <span>{data.maxSeats - data.attendees?.length}</span>
                      ) : (
                        ""
                      )}{" "}
                      {eventTranslation.seatsAvailable}
                    </p>
                  </Flex>
                )}
              </SeatsAvilable>
            </MeetLinkSeatsSection>
          </div>

          <Attendees>
            {!!data.attendees && data.attendees.length ? (
              <StackedAvatar
                handleAttendees={() => attendeesModal(data)}
                maxAvatars={5}
                round={true}
                size={25}
                avatars={data.attendees}
                SASToken={this.props.user?.SASToken}
              />
            ) : (
              <p
                className="sub-title"
                style={{
                  height: "25px",
                  fontSize: selectedColumns === 4 ? "14px" : "12px",
                }}
              >
                {eventTranslation.noRegistrations}
              </p>
            )}
            <div className="row-sec">
              <div className="row-sec-1">
                {((type === "event" && isActiveStatus) || type !== "event") &&
                  data?.status?.toLowerCase() !== "draft" && (
                    <Flex>
                      <ShareEvent
                        eventId={data.id}
                        eventTitle={data.title}
                        shareButton={({ onShareButtonClickhandler }) => (
                          <Button
                            icon={<ShareAltIcon outline />}
                            onClick={() => {
                              onShareButtonClickhandler();
                            }}
                            text
                            style={{ width: "30px", minWidth: "20px" }}
                          />
                        )}
                        {...this.props}
                      />
                    </Flex>
                  )}

                {/* {data && !data.isGoogleMeetMeeting ? (
                  <Flex style={{ marginLeft: "4px" }}>
                    {data.availability === "PRIVATE" ? (
                      <Tooltip
                        overlayStyle={{ fontSize: 10, height: 16 }}
                        placement="bottom"
                        title={eventTranslation.privateEvent}
                      >
                        <EyeSlashIcon size="large" />
                      </Tooltip>
                    ) : (
                      <Tooltip
                        overlayStyle={{ fontSize: 10, height: 16 }}
                        placement="bottom"
                        title={eventTranslation.publicEvent}
                      >
                        <EyeIcon size="large" />
                      </Tooltip>
                    )}
                  </Flex>
                ) : null} */}

                {(type === "event" || type === "bundleEvent") && (
                  <Flex style={{ marginLeft: "3px" }}>
                    <Button
                      text
                      icon={<EditIcon outline size="medium" />}
                      iconOnly
                      onClick={() => {
                        editButtonClickHandler
                          ? editButtonClickHandler(data)
                          : this.props.history.push(`/event/edit/${data.id}`);
                      }}
                      disabled={loading?.isDeleting || loading?.isLoading}
                    />
                  </Flex>
                )}
                {type === "event" && isActiveStatus && (
                  <Flex style={{ margin: "0 3px 0 -3px" }}>
                    <Button
                      text
                      icon={<TrashCanIcon outline />}
                      iconOnly
                      onClick={() => onDelete(data)}
                      disabled={loading?.isDeleting || loading?.isLoading}
                    />
                  </Flex>
                )}
              </div>
              <Flex hAlign="start">
                {type === "discover" &&
                  (!data.isRegistrationClosed ? (
                    <RegisterEventButton
                      className="row-sec-2"
                      onClick={onRegister}
                      disabled={loading?.isLoading}
                    >
                      {eventTranslation.register}
                    </RegisterEventButton>
                  ) : (
                    <RegistrationClosedText>
                      {eventTranslation.registrationClosed}
                    </RegistrationClosedText>
                  ))}
                {type === "event" &&
                  isActiveStatus &&
                  data?.status?.toLowerCase() !== "draft" ? (
                  !!data.isMSTeamMeeting ? (
                    !!data.meetingURL && (
                      <Dialog
                        confirmButton={eventTranslation.ok}
                        content={
                          <div>
                            <Text size="large">
                              {eventTranslation.startEventMessage}
                            </Text>
                          </div>
                        }
                        onConfirm={() => {
                          window.open(data.meetingURL);
                        }}
                        trigger={
                          <RegisterButton className="row-sec-2">
                            {eventTranslation.start}
                          </RegisterButton>
                        }
                        cancelButton={translation.common.cancel}
                      />
                    )
                  ) : !!data.isGoogleMeetMeeting && !!data.googleMeetURL ? (
                    <RegisterButton
                      className="row-sec-2"
                      onClick={() => {
                        window.open(data.googleMeetURL);
                      }}
                    >
                      {eventTranslation.start}
                    </RegisterButton>
                  ) : null
                ) : null}

                {type === registered || type === "favourite" ? (
                  !!data.isMSTeamMeeting ? (
                    !!data.meetingURL ? (
                      <Dialog
                        confirmButton={eventTranslation.ok}
                        content={
                          <div>
                            <Text size="large">
                              {eventTranslation.areYouJoiningFromAccount},{" "}
                              <Text weight="bold">{this.props.user.mail}</Text>{" "}
                              ?
                            </Text>
                          </div>
                        }
                        cancelButton={eventTranslation.cancel}
                        onConfirm={() => {
                          let url = data.meetingURL;
                          window.open(url);
                        }}
                        trigger={
                          <RegisterButton className="row-sec-2">
                            {eventTranslation.join}
                          </RegisterButton>
                        }
                      />
                    ) : null
                  ) : (
                    !!data.isGoogleMeetMeeting &&
                    !!data.googleMeetURL && (
                      <RegisterButton
                        className="row-sec-2"
                        onClick={() => {
                          let url = data.googleMeetURL;
                          window.open(url);
                        }}
                      >
                        {eventTranslation.join}
                      </RegisterButton>
                    )
                  )
                ) : null}
                {type === "cancel" && (
                  // <RegisterButton
                  //   disabled={loading?.isLoading}
                  //   className="row-sec-2"
                  //   onClick={onCancelRegistration}
                  // >
                  //   {eventTranslation.cancel}
                  // </RegisterButton>
                  <RegistrationCancelButton
                    disabled={loading?.isLoading}
                    className="row-sec-2"
                    onClick={onCancelRegistration}
                  >
                    {eventTranslation.cancel}
                  </RegistrationCancelButton>
                )}
              </Flex>
            </div>
          </Attendees>
        </div>
      </Section>
    );
  }
}

export default withTranslation()(Event);
