import React, { useState } from "react";
import { Calendar } from "react-date-range";
import "react-date-range/dist/styles.css";
import "react-date-range/dist/theme/default.css";
import { useBooking } from "../../../../context/BookingContext";
import moment from "moment";
import "./BlockedDatesCalender.scss";

const BlockedDatesCalender = () => {
  const { blockedDates, setBlockedDates } = useBooking();

  const handleSelect = (date) => {
    const selectedDate = moment(date);
    const isBlocked = blockedDates.some((blockedDate) =>
      moment(blockedDate).isSame(selectedDate, "day")
    );

    if (isBlocked) {
      setBlockedDates(
        blockedDates.filter(
          (blockedDate) => !moment(blockedDate).isSame(selectedDate, "day")
        )
      );
    } else {
      setBlockedDates([...blockedDates, selectedDate.format("YYYY-MM-DD")]);
    }
  };

  return (
    <div className="blocked-dates-calender">
      <Calendar
        onChange={handleSelect}
        months={1}
        classNames={{ dayInPreview: "react-calender-day-today" }}
        direction="vertical"
        showDateDisplay={false}
        minDate={moment().toDate()}
        maxDate={moment().add(2, "months").toDate()}
        color="#6264a7"
        className="react-calender"
        dayContentRenderer={(date) => {
          const isBlocked = blockedDates.some(
            (blockedDate) =>
              moment(blockedDate).isSameOrAfter(moment(), "day") &&
              moment(blockedDate).isSame(moment(date), "day")
          );

          const isToday = moment(date).isSame(moment(), "day");
          return (
            <span
              style={{
                height: "100%",
                width: "100%",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                backgroundColor: isBlocked
                  ? " #6264a7"
                  : isToday
                  ? "White"
                  : "transparent",
                color: isBlocked ? "white" : "#6264a7",
                borderRadius: "18.662px",
              }}
            >
              {moment(date).date()}
            </span>
          );
        }}
      />
    </div>
  );
};

export default BlockedDatesCalender;
