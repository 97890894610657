import * as XLSX from "xlsx";

export const exportExcel = (headers, rows, fileName) => {
  try {
    const ws = XLSX.utils.aoa_to_sheet([headers, ...rows]);
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, "Feedback");

    const fileName_ = `${fileName || "acv"}.xlsx`;
    XLSX.writeFile(wb, fileName_);
  } catch (error) {
    console.log("error from exportExcel", error.message);
  }
};


export const importExcelToJson = (file) => {
  return new Promise((resolve, reject) => {
    try {
      const reader = new FileReader();
      
      reader.onload = (event) => {
        const data = new Uint8Array(event.target.result);
        const workbook = XLSX.read(data, { type: 'array' });
        const sheetName = workbook.SheetNames[0];
        const worksheet = workbook.Sheets[sheetName];
        const json = XLSX.utils.sheet_to_json(worksheet);
        resolve(json);
      };

      reader.onerror = (error) => {
        reject(error);
      };

      reader.readAsArrayBuffer(file);
    } catch (error) {
      console.log("error from importExcelToJson", error.message);
      reject(error);
    }
  });
};
