import React, { useEffect, useReducer, memo } from "react";
import JoyRide, { ACTIONS, EVENTS, STATUS } from "react-joyride";
import { InfoIcon } from "@fluentui/react-icons-northstar";
import { Button } from "@fluentui/react-northstar";
import { withTranslation } from "react-i18next";

const TEACHER_MAIN_PAGE_NO_LESSONS_TOUR_STEPS = [
  {
    target: ".tour-no-lessons-available",
    content: "No lessons available for this date. Please select another date.",
    disableBeacon: true, // This makes the tour to start automatically without clicking
  },
];

const TEACHER_MAIN_PAGE_TOUR_STEPS = [
  {
    target: ".tour-find-class",
    content: "Here you can find class by date.",
    disableBeacon: true,
  },
  {
    target: ".tour-select-lesson",
    content:
      "This is where you can find all Lessons. Select one to create new Assignment.",
    placement: "right",
  },
  // {
  //   target: ".tour-all-assignments",
  //   content:
  //     "This is where you can find all created Assignments for this Lesson.",
  // },
  // {
  //   target: ".tour-create-assignment",
  //   content: "Click here to create new Assignment.",
  // },
];

const TEACHER_ASSIGNMENT_HOME_PAGE_TOUR_STEPS = [
  {
    target: ".tour-all-assignments",
    content:
      "This is where you can find all created Assignments for this Lesson.",
    disableBeacon: true,
  },
  {
    target: ".tour-create-assignment",
    content: "Click here to create new Assignment.",
  },
];

const TEACHER_NO_ASSIGNMENT_HOME_PAGE_TOUR_STEPS = [
  {
    target: ".tour-all-assignments",
    content: "All the Assignments for this Lesson will be displayed here.",
    disableBeacon: true,
  },
  {
    target: ".tour-create-assignment",
    content: "Click here to create new Assignment.",
  },
];

const TEACHER_NEW_ASSIGNMENT_DETAILS_PAGE_TOUR_STEPS = [
  {
    target: ".tour-create-assignment-title-instruction",
    content: "You can add Assigment Title and instruction here.",
    disableBeacon: true,
  },
  {
    target: ".tour-create-assignment-attachments",
    content:
      "This is where you can add text, audio, video, link and file Attachments.",
  },
  {
    target: ".tour-create-assignment-students-duedate-grade",
    content:
      "Here you can add Students, Due date and Grade for this Assignment.",
  },
  {
    target: ".tour-create-assignment-rubric",
    content: "Click here to create or view Assignment Rubric.",
  },
  {
    target: ".tour-create-assignment-publish-draft",
    content:
      "Click here to publish, save as draft and publish later Assignment.",
  },
];
const TEACHER_CREATED_ASSIGNMENT_DETAILS_PAGE_TOUR_STEPS = [
  {
    target: ".tour-create-assignment-title-instruction",
    content: "You can add Assigment Title and instruction here.",
    disableBeacon: true,
  },
  {
    target: ".tour-create-assignment-attachments",
    content:
      "This is where you can add text, audio, video, link and file Attachments.",
  },
  {
    target: ".tour-create-assignment-students-duedate-grade",
    content:
      "Here you can add Students, Due date and Grade for this Assignment.",
  },
  {
    target: ".tour-create-assignment-rubric",
    content: "Click here to create or view Assignment Rubric.",
  },
  {
    target: ".tour-view-submission-button",
    content: "Click here to view student submission.",
  },
];

const TEACHER_STUDENT_SUBMISSION_TOUR_STEPS = [
  {
    target: ".tour-view-assignment-student-submission",
    content:
      "This is where you can view details of student submission. Select student to view submission.",
    disableBeacon: true,
  },
];

const TEACHER_GRADING_PAGE_TOUR_STEPS = [
  {
    target: ".tour-view-student-submission",
    content: "This is where you can view Attachment of student submission.",
    disableBeacon: true,
  },
  {
    target: ".tour-rubric-grade-student-submission",
    content: "Here you can grade student submission based on Rubric.",
  },
  {
    target: ".tour-submit-grade-student-submission",
    content:
      "Here you can submit grade of student submission or send Resubmit instruction to student.",
  },
];
const TEACHER_RUBRIC_PAGE_TOUR_STEPS = [
  {
    target: ".tour-create-assignment-rubric-details",
    content:
      "Here you can create Rubric from Rubric bank, select levels of Rubric Items and create Rubric with points.",
    disableBeacon: true,
  },
  {
    target: ".tour-save-assignment-rubric",
    content: "Click here to create Rubric.",
  },
];

const STUDENT_SUBJECTS_TOUR_STEPS = [
  {
    target: ".tour-student-assignment-subject-select",
    content:
      "From here, you can find lessons by selecting from the drop-down menu.",
    disableBeacon: true,
  },
  {
    target: ".tour-student-assignment-teacher-select",
    content:
      "From here, you can find lessons by the teacher by selecting from the drop-down menu.",
  },
  {
    target: ".tour-student-assignment-global-search",
    content: "From here, you can find subjects by name.",
  },
  {
    target: ".tour-select-lesson",
    content:
      "This is where you can find all Lessons. Choose one to see all of the assignments for that lesson.",
  },
  {
    target: ".tour-subject-assignment-count",
    content: "This is where you can view assignment counts by status.",
  },
];
const STUDENT_ASSIGNMENT_LIST_TOUR_STEPS = [
  {
    target: ".tour-start-end-date-filter",
    content:
      "From here, you can filter assignments by the due date using the start and end dates.",
    disableBeacon: true,
  },
  {
    target: ".tour-student-assignment-global-search",
    content: "From here, you can find the assignment by title.",
  },
  {
    target: ".tour-all-assignments",
    content:
      "Here you can find all Assignments assigned to you for this Lesson. Click on Assignment to view Assignment Details.",
  },
];

const STUDENT_PENDING_ASSIGNMENT_LIST_TOUR_STEPS = [
  {
    target: ".tour-student-assignment-subject-select",
    content:
      "From here, you can filter assignments by subject by selecting from the drop-down menu.",
    disableBeacon: true,
  },
  {
    target: ".tour-student-assignment-teacher-select",
    content:
      "From here, you can filter assignments by the teacher by selecting from the drop-down menu.",
  },
  {
    target: ".tour-start-end-date-filter",
    content:
      "From here, you can filter assignments by the due date using the start and end dates.",
  },
  {
    target: ".tour-student-assignment-global-search",
    content: "From here, you can find the assignment by title.",
  },
  {
    target: ".tour-pending-assignments",
    content:
      "If there are any pending assignments, those assignments will be displayed here.",
  },
];

const STUDENT_SUBMISSION_PAGE_TOUR_STEPS = [
  {
    target: ".tour-view-assignment",
    content: "Here you can view Assignment details.",
    disableBeacon: true,
  },
  {
    target: ".tour-view-assignment-attachments",
    content: "Here you can view Assignment Attachments.",
  },
  {
    target: ".tour-submit-assignment-attachments",
    content: "This is where you can submit Attachments.",
  },
  {
    target: ".tour-submit-assignment",
    content: "Click here to submit Assignment after adding Attachments.",
  },
];

// Tour component
const Tour = ({
  isStudentRole,
  currentPage,
  isLessonsAvailable = false,
  isVisited,
  canCreateAssignment,
  selectedLessonAssignments = 0,
  isRubricHasPoints = false,
  t,
  i18n,
}) => {
  const translation = t("assignment").body.tour;

  useEffect(() => {
    dispatch({ type: "CHANGE_STEPS", payload: getTourSteps() });
  }, [
    currentPage,
    isLessonsAvailable,
    i18n.language,
    selectedLessonAssignments,
  ]);

  const getTourSteps = () => {
    if (isStudentRole) {
      switch (currentPage) {
        case "assignmentList":
          return STUDENT_ASSIGNMENT_LIST_TOUR_STEPS.map((item, index) => ({
            ...item,
            content: translation.student.assignmentList[index],
          }));
        case "todos":
          return STUDENT_PENDING_ASSIGNMENT_LIST_TOUR_STEPS.map(
            (item, index) => ({
              ...item,
              content: translation.student.todos[index],
            })
          );
        case "assignmentSubmissionPage":
          return STUDENT_SUBMISSION_PAGE_TOUR_STEPS.map((item, index) => ({
            ...item,
            content: translation.student.assignmentSubmissionPage[index],
          }));
        default:
          return STUDENT_SUBJECTS_TOUR_STEPS.map((item, index) => ({
            ...item,
            content: translation.student.subjects[index],
          }));
      }
    } else {
      switch (currentPage) {
        case "assignmentHome":
          if (canCreateAssignment) {
            if (selectedLessonAssignments) {
              return TEACHER_ASSIGNMENT_HOME_PAGE_TOUR_STEPS.map(
                (item, index) => ({
                  ...item,
                  content: translation.teacher.homePage[index],
                })
              );
            } else {
              return TEACHER_NO_ASSIGNMENT_HOME_PAGE_TOUR_STEPS.map(
                (item, index) => ({
                  ...item,
                  content: translation.teacher.noAssignmentHomePage[index],
                })
              );
            }
          } else {
            if (selectedLessonAssignments) {
              return TEACHER_ASSIGNMENT_HOME_PAGE_TOUR_STEPS.slice(0, 1).map(
                (item, index) => ({
                  ...item,
                  content: translation.teacher.homePage[index],
                })
              );
            } else {
              return TEACHER_NO_ASSIGNMENT_HOME_PAGE_TOUR_STEPS.slice(0, 1).map(
                (item, index) => ({
                  ...item,
                  content: translation.teacher.noAssignmentHomePage[index],
                })
              );
            }
          }
        case "newAssignmentDetails":
          return TEACHER_NEW_ASSIGNMENT_DETAILS_PAGE_TOUR_STEPS.map(
            (item, index) => ({
              ...item,
              content: translation.teacher.newAssignment[index],
            })
          );
        case "createdAssignmentDetails":
          return TEACHER_CREATED_ASSIGNMENT_DETAILS_PAGE_TOUR_STEPS.map(
            (item, index) => ({
              ...item,
              content: translation.teacher.createdAssignment[index],
            })
          );
        case "studentSubmission":
          return TEACHER_STUDENT_SUBMISSION_TOUR_STEPS.map((item, index) => ({
            ...item,
            content: translation.teacher.studentSubmission[index],
          }));
        case "grading":
          if (isRubricHasPoints) {
            return TEACHER_GRADING_PAGE_TOUR_STEPS.map((item, index) => ({
              ...item,
              content: translation.teacher.gradingPage[index],
            }));
          } else {
            return TEACHER_GRADING_PAGE_TOUR_STEPS.map((item, index) => ({
              ...item,
              content: translation.teacher.gradingPage[index],
            })).filter((item, index) => index !== 1);
          }
        case "rubric":
          return TEACHER_RUBRIC_PAGE_TOUR_STEPS.map((item, index) => ({
            ...item,
            content: translation.teacher.rubricPage[index],
          }));
        default:
          if (isLessonsAvailable) {
            return TEACHER_MAIN_PAGE_TOUR_STEPS.map((item, index) => ({
              ...item,
              content: translation.teacher.mainPage[index],
            }));
          } else {
            return TEACHER_MAIN_PAGE_NO_LESSONS_TOUR_STEPS.map(
              (item, index) => ({
                ...item,
                content: translation.teacher.noLessonsMainPage[index],
              })
            );
          }
      }
    }
  };

  const INITIAL_STATE = {
    key: new Date(), // This field makes the tour to re-render when we restart the tour
    run: false,
    continuous: true,
    loading: false,
    stepIndex: 0,
    steps: getTourSteps(),
  };

  // Reducer will manage updating the local state
  const reducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
      case "START":
        return { ...state, run: true };
      case "RESET":
        return { ...state, stepIndex: 0 };
      case "STOP":
        return { ...state, run: false };
      case "NEXT_OR_PREV":
        return { ...state, ...action.payload };
      case "RESTART":
        return {
          ...state,
          stepIndex: 0,
          run: true,
          loading: false,
          key: new Date(),
        };

      case "CHANGE_STEPS":
        return { ...state, steps: action.payload };
      default:
        return state;
    }
  };
  const [tourState, dispatch] = useReducer(reducer, INITIAL_STATE);

  // Set once tour is viewed, skipped or closed
  const setTourViewed = () => {
    // localStorage.setItem("tour", "1");
  };

  const callback = (data) => {
    const { action, index, type, status } = data;

    if (
      // If close button clicked, then close the tour
      action === ACTIONS.CLOSE ||
      // If skipped or end tour, then close the tour
      (status === STATUS.SKIPPED && tourState.run) ||
      status === STATUS.FINISHED
    ) {
      setTourViewed();
      dispatch({ type: "STOP" });
    } else if (type === EVENTS.STEP_AFTER || type === EVENTS.TARGET_NOT_FOUND) {
      // Check whether next or back button click and update the step.
      dispatch({
        type: "NEXT_OR_PREV",
        payload: { stepIndex: index + (action === ACTIONS.PREV ? -1 : 1) },
      });
    }
  };

  const startTour = () => {
    // Start the tour manually
    dispatch({ type: "RESTART" });
  };

  return (
    <>
      <Button
        icon={<InfoIcon circular outline size="large" />}
        iconOnly
        title={translation.tour}
        onClick={startTour}
      />
      <JoyRide
        {...tourState}
        callback={callback}
        styles={{
          tooltipContainer: {
            textAlign: "left",
          },
          buttonBack: {
            marginRight: 10,
          },
          buttonNext: {
            borderRadius: 4,
            color: "red",
            border: "1px solid #EB5757",
            backgroundColor: "#fff",
          },
        }}
        locale={{
          last: translation.endTour,
          next: translation.next,
          back: translation.back,
        }}
        showProgress
        disableScrolling={true}
      />
    </>
  );
};

export default withTranslation()(memo(Tour));
