import React, { useState, useEffect } from "react";
import styled from "styled-components";
import {
  Card,
  CardBody,
  CardHeader,
  Dropdown,
  Flex,
  Pill,
  Text,
  ContentIcon,
  UserFriendsIcon,
} from "@fluentui/react-northstar";
import { withTranslation } from "react-i18next";

import SelectorModal from "../Modals/SelectorModal";
import { NotificationAlert } from "../../../../components/Notification/Notification";
import Footer from "../Footer";

const TitleText = styled(Text)`
  font-weight: bold;
  font-size: 18px;
`;

const CategoryCard = styled(Card)`
  border-radius: 35px;
  background-color: #f3f2f1;
  min-height: 150px;
  box-shadow: 0px 2px 2px 1px #dcdcdc;
  :hover {
    background-color: #f3f2f1;
  }
`;

const SkillPill = styled(Pill)`
  min-width: unset;
`;

const iconList = [
  {
    category: "microsoft",
    icon: <ContentIcon />,
  },
  {
    category: "sport",
    icon: <UserFriendsIcon />,
  },
];

const SkillInformation = ({
  currentStep,
  setCurrentStep,
  totalStep,
  data,
  setData,
  skills: skillData,
  t,
}) => {
  const [selectedSkills, setSelectedSkills] = useState([]);
  const [skills, setSkills] = useState();
  const [dataBasedOnSelection, setDataBasedOnSelection] = useState([]);
  const [searchValue, setSearchValue] = useState("");

  const translation = t("feedback").body.forms.skillInformation;

  const getInterestBasedOnSelection = () => {
    let skills = [];
    if (!!selectedSkills?.length && !!skillData.length) {
      skills = skillData.filter((item) => {
        return (
          item.category &&
          selectedSkills
            .map((item) => item.category?.toLowerCase())
            .includes(item.category?.toLowerCase()) &&
          !selectedSkills.map((item) => item.id).includes(item.id)
        );
      });
    }
    if (skills.length > 8) {
      skills = Object.values(
        skills.reduce((acc, item) => {
          let formattedCategory = item.category?.toLowerCase();
          acc[formattedCategory] = acc[formattedCategory] ?? [];
          acc[formattedCategory].push(item);
          return acc;
        }, {})
      )
        .map((item) => item.slice(0, 3))
        .reduce((acc, item) => {
          acc = [...acc, ...item];
          return acc;
        }, [])
        .slice(0, 8);
    }

    setDataBasedOnSelection(skills?.length ? skills : []);
  };

  useEffect(() => {
    if (Array.isArray(data.skills)) {
      setSelectedSkills(data.skills);
    } else {
      setSelectedSkills([]);
    }
  }, [data]);

  useEffect(() => {
    setSkills(
      Object.entries(
        skillData.reduce((acc, item) => {
          if (item.category) {
            let formattedCategory = item.category.toLowerCase();
            acc[formattedCategory] = acc[formattedCategory] ?? [];
            acc[formattedCategory].push(item);
          }
          return acc;
        }, {})
      )
    );
  }, [skillData]);

  useEffect(() => {
    getInterestBasedOnSelection();
  }, [skillData, selectedSkills]);

  const nextHandler = () => {
    setCurrentStep((prev) => prev + 1);
    setData((prevData) => ({
      ...prevData,
      skills: selectedSkills,
    }));
  };
  const backHandler = () => {
    setCurrentStep((prev) => prev - 1);
    setData((prevData) => ({
      ...prevData,
      skills: selectedSkills,
    }));
  };

  const getDropdownItems = () => {
    let allSelectedSkills = selectedSkills?.map((item) => item.title);
    return allSelectedSkills?.length
      ? skillData
          ?.map((item) => item.title)
          ?.filter((item) => !allSelectedSkills?.includes(item))
      : skillData?.map((item) => item.title) ?? [];
  };

  return (
    <div className="skill-information-container">
      <div className="ms-Grid pt-3">
        <div className="ms-Grid-row margin-device-wise">
          <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg6">
            <div className="ms-Grid-row d-flex justify-content-center">
              <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg10">
                <div className="ms-Grid-row">
                  <TitleText className="pl-2">{translation.header}</TitleText>
                </div>

                <div className="ms-Grid-row pt-3">
                  <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12">
                    <Flex column>
                      <Text weight="semibold">{translation.searchSkills}</Text>
                      <Dropdown
                        className="skill-dropdown"
                        fluid
                        search
                        checkable
                        clearable
                        value={[]}
                        items={getDropdownItems()}
                        onChange={(e, data) => {
                          const { value } = data;
                          setSelectedSkills((prevData) => [
                            ...prevData,
                            skillData.find((item) => item.title === value),
                          ]);
                          setSearchValue("");
                        }}
                        placeholder={translation.placeholder}
                        noResultsMessage={translation.noMatchFound}
                        // getA11ySelectionMessage={getA11ySelectionField}
                        searchQuery={searchValue}
                        onSearchQueryChange={(e, { searchQuery }) => {
                          setSearchValue(searchQuery);
                        }}
                      />
                    </Flex>
                  </div>
                </div>

                <div className="ms-Grid-row pt-2">
                  <div className="ms-Grid-col ms-sm12">
                    <Flex space="between">
                      <Text>{translation.maxSkills}</Text>

                      <Text>{selectedSkills.length}/50</Text>
                    </Flex>
                  </div>
                </div>

                {!!selectedSkills?.length ? (
                  <div className="ms-Grid-row mt-3">
                    <div>
                      <Flex wrap>
                        {selectedSkills.map((item) => {
                          return (
                            <SkillPill
                              key={item.id}
                              actionable
                              onDismiss={() => {
                                setSelectedSkills((prevData) =>
                                  prevData.filter((data) => data.id !== item.id)
                                );
                              }}
                              style={{
                                minWidth: "initial",
                                backgroundColor: "#6264A7",
                                color: "#fff",
                              }}
                            >
                              {item.title}
                            </SkillPill>
                          );
                        })}
                      </Flex>
                    </div>
                  </div>
                ) : null}

                {!!selectedSkills?.length ? (
                  <div className="ms-Grid-row mt-3">
                    <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12">
                      <Flex column>
                        <Text weight="semibold">
                          {translation.skillBasedOnSearch}
                        </Text>
                        <Flex wrap>
                          {dataBasedOnSelection?.map((data) => {
                            return (
                              <SkillPill
                                key={data.id}
                                onClick={() => {
                                  setSelectedSkills((prevData) => {
                                    if (prevData.includes(data.id)) {
                                      return prevData.filter(
                                        (item) => item.id !== data.id
                                      );
                                    } else {
                                      if ([...prevData, data].length > 50) {
                                        NotificationAlert(
                                          translation.maxSkillValidation,
                                          "warning"
                                        );
                                        return prevData;
                                      } else {
                                        return [...prevData, data];
                                      }
                                    }
                                  });
                                }}
                                className="cur-pointer"
                              >
                                {data.title}
                              </SkillPill>
                            );
                          })}
                        </Flex>
                      </Flex>
                    </div>
                  </div>
                ) : null}
              </div>
            </div>
          </div>

          <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg6 mt-5 mt-md-0">
            <CategoryCard className="ms-depth-8" style={{ width: "100%" }}>
              <CardHeader>
                <Flex gap="gap.small">
                  <TitleText className="pl-2">
                    {translation.categories}:
                  </TitleText>
                </Flex>
              </CardHeader>
              <CardBody>
                <div className="ms-Grid-row">
                  <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12">
                    <Flex wrap>
                      {!!skills?.length &&
                        skills.map((item) => {
                          const title = item[0];
                          const skillOptions = item[1];
                          return (
                            <SelectorModal
                              key={title}
                              items={skillOptions?.length ? skillOptions : []}
                              selectedItems={
                                selectedSkills.length ? selectedSkills : []
                              }
                              header={`${title} ${translation.skills}`}
                              confirmButton={translation.addSkills}
                              // clearButton={"Clear"}
                              onSubmit={(value) => {
                                if (value?.length > 50) {
                                  NotificationAlert(
                                    translation.maxSkillValidation,
                                    "warning"
                                  );
                                  return "limit reached";
                                }
                                setSelectedSkills(value);
                              }}
                            >
                              {({ open, setOpen }) => {
                                let skill = skillOptions?.[1]?.category;
                                let icon = iconList.find(
                                  (item) => item.category === skill
                                );
                                return (
                                  <div>
                                    <Pill
                                      icon={
                                        icon ? (
                                          <ContentIcon />
                                        ) : (
                                          <UserFriendsIcon />
                                        )
                                      }
                                      onClick={() => {
                                        setOpen(true);
                                      }}
                                      className="cur-pointer"
                                    >
                                      {title}
                                    </Pill>
                                  </div>
                                );
                              }}
                            </SelectorModal>
                          );
                        })}
                    </Flex>
                  </div>
                </div>
              </CardBody>
            </CategoryCard>
          </div>
        </div>
        <div className="ms-Grid-row margin-device-wise mt-4">
          <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg6">
            <div className="d-flex justify-content-end">
              <Footer
                step={currentStep}
                totalStep={totalStep}
                backHandler={backHandler}
                nextHandler={nextHandler}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default withTranslation()(SkillInformation);
