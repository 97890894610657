import React from "react";

export const Tab = ({ activeTab, label, onClick, tabId, classes = "" }) => {
  const onTabClick = () => {
    onClick(tabId);
  };
  let className = `group-tab-list-item ${classes}`;

  if (activeTab === tabId) {
    className += " group-tab-list-active";
  }
  return (
    <li className={className} onClick={onTabClick}>
      {label}
    </li>
  );
};
