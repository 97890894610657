import React, { useState } from "react";
import { CloseIcon, Dialog, Dropdown, Input } from "@fluentui/react-northstar";
import { useTranslation } from "react-i18next";
import "../index.scss";
import axios from "axios";
import { toast } from "react-toastify";

const DeleteParentModel = (props) => {
  const {
    parentToDelete,
    setParentToDelete,
    setStudentsWithoutParents,
    studentsWithoutParents,
  } = props;
  const parentEmailToDelete = parentToDelete?.parentDetails?.email;
  const [isLoading, setIsLoading] = useState(false);

  const handleDeleteParent = async () => {
    if (!!parentEmailToDelete.trim()) {
      setIsLoading(true);

      try {
        let config = {
          method: "delete",
          url: `${process.env.REACT_APP_EP_URL}/api/parents/${parentEmailToDelete}${props.user.slug}`,
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + props.user.accessToken,
          },
        };
        const response = await axios.request(config);
        props.getAllParents();
        setParentToDelete(null);
        const deletedStudents = parentToDelete.students.map((student) => {
          return { key: student.StudentEmailID, header: student.StudentName };
        });
        let clonedStudentsWithoutParents = [
          ...studentsWithoutParents,
          ...deletedStudents,
        ];
        setStudentsWithoutParents(clonedStudentsWithoutParents);
        setIsLoading(false);
        toast.success("Parent deleted successfully!");
        props.onClose();
      } catch (error) {
        toast.error("Parent delete Failed!");
        setIsLoading(false);
        console.log(error);
      }
    } else {
      toast.error("Parent email is missing");
    }
  };

  const { t } = useTranslation();

  const deleteParentDialog = t("setting").parent.deleteParentDialog;

  return (
    <Dialog
      open={props.isOpen}
      content={<p>{deleteParentDialog.warningMessage}</p>}
      header={deleteParentDialog.title}
      className="smallDlg"
      confirmButton={
        isLoading
          ? {
              text: deleteParentDialog.confirmButton,
              disabled: true,
              loading: true,
            }
          : deleteParentDialog.confirmButton
      }
      cancelButton={
        isLoading
          ? { text: deleteParentDialog.cancelButton, disabled: true }
          : deleteParentDialog.cancelButton
      }
      onConfirm={handleDeleteParent}
      onCancel={props.onClose}
      headerAction={{
        icon: <CloseIcon />,
        title: "Close",
        onClick: props.onClose,
        disabled: isLoading,
      }}
    />
  );
};

export default DeleteParentModel;
