import { Flex } from "@fluentui/react-northstar";
import { Box } from "@mui/material";
import React from "react";
import { ExportClassData } from "../../helper/ExportData";
import { useEffect } from "react";
import { useState } from "react";

function DownloadIcon(props) {
  const [isMobileView, setIsMobileView] = useState(window.innerWidth <= 767);
  useEffect(() => {
    const handleResize = () => {
      setIsMobileView(window.innerWidth <= 767);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  return (
    <>
      {isMobileView && (
        <Box style={{ right: "6%", position: "fixed" }}>
          {props.showExport ? (
            <Flex
              gap="gap.smaller"
              className={`an-gap ${
                props.state.showRecords.length
                  ? "tour-attendence-generate-report"
                  : ""
              }`}
              // style={{ padding: "10px 0px" }}
            >
              {props.state.showRecords.length ? (
                <ExportClassData dataset={props.state.showRecords} />
              ) : null}
            </Flex>
          ) : null}
        </Box>
      )}
    </>
  );
}

export default DownloadIcon;
