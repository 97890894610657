import React, { useCallback, useEffect, useState } from "react";

import {
  Button,
  CloseIcon,
  CodeSnippetIcon,
  Dialog,
  DownloadIcon,
  Flex,
  Input,
  Pill,
  SearchIcon,
  ShareAltIcon,
  ShareGenericIcon,
  Text,
  UserFriendsIcon,
} from "@fluentui/react-northstar";
import { withTranslation } from "react-i18next";
import { toast } from "react-toastify";
import axios from "axios";

import { getQRCode } from "../../../../../utils/generateQRCode";

import "react-toastify/dist/ReactToastify.css";
import "./ShareFeedback.scss";

const linkTab = "linkTab";
const iFrameTab = "iFrameTab";

const emailLoading = "emailLoading";

const API_URL = process.env.REACT_APP_EP_URL;

const ShareFeedback = ({
  feedback,
  isCopyLinkLoading,
  setIsCopyLinkLoading,
  user,
  t,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [emailInputText, setEmailInputText] = useState("");
  const [emails, setEmails] = useState([]);
  const [isInvalidEmailText, setIsInvalidEmailText] = useState(false);
  const [activeTab, setActiveTab] = useState(linkTab);
  const [qrCodeImage, setQrCodeImage] = useState("");
  const [loading, setLoading] = useState({
    type: null,
    isLoading: false,
  });
  const [isEmailsExpanded, setIsEmailsExpanded] = useState(false);

  const translation = t("feedbackModule");

  const commonTranslation = translation.common;

  const feedbackTranslation = translation.body.allFeedback;
  const shareFeedbackTranslation = translation.body.allFeedback.shareFeedback;

  let feedbackLongUrl = feedback?.id
    ? `${process.env.REACT_APP_URI}/feedback/submit-feedback/${feedback.id}`
    : null;

  let iFrameLink = `<iframe src="${feedbackLongUrl}" width="768" height="432" allowFullScreen allow="camera; microphone; fullscreen; clipboard-read; clipboard-write" frameborder="0" />`;

  const qrCodeGenerator = () => {
    const qrValue = getQRCode(feedbackLongUrl);
    return qrValue;
  };

  useEffect(() => {
    if (isOpen) {
      try {
        const qrValue = qrCodeGenerator();
        if (!qrValue) return;
        setQrCodeImage(qrValue);
      } catch (error) {}
    }
  }, [isOpen]);

  const downloadQRImage = useCallback(() => {
    const elm = document.createElement("a");
    elm.href = qrCodeImage;
    elm.download = `${commonTranslation.feedback}-${
      feedback.title?.length ? feedback.title.slice(0, 15) : ""
    }`;
    elm.click();
  }, [qrCodeImage]);

  const onAddEmailHandler = () => {
    let emailInputList = emailInputText?.length
      ? [
          ...new Set(
            emailInputText
              .toLowerCase()
              .split(";")
              .filter((email) => email)
          ),
        ]
      : [];
    if (!emailInputList.length) {
      return;
    }
    const emailRegex = /[a-z0-9]+@[a-z]+\.[a-z]{2,3}/;
    let isValid = true;
    emailInputList.forEach((emailText) => {
      if (!emailRegex.test(emailText)) {
        isValid = false;
      }
    });

    if (emailInputText?.includes(",")) {
      isValid = false;
    }

    if (isValid) {
      setEmails((prev) => [...new Set([...prev, ...emailInputList])]);
      setEmailInputText("");
    } else {
      setIsInvalidEmailText(true);
    }
  };

  const shareFeedbackLinkHandler = async () => {
    // setIsCopyLinkLoading({ id: feedback.id, isLoading: true });

    if (feedback.id) {
      try {
        // if (navigator.share) {
        //   await navigator.share({
        //     text: translation.common.feedback,
        //     title: translation.common.feedback,
        //     url: feedbackLongUrl,
        //   });
        // } else {
        navigator.clipboard.writeText(feedbackLongUrl);
        toast.success(feedbackTranslation.linkCopied, { closeOnClick: true });
        // }
      } catch {
      } finally {
        // setIsCopyLinkLoading({ id: null, isLoading: false });
      }
    }
  };

  const copyIframeLinkHandler = async () => {
    if (feedbackLongUrl) {
      try {
        navigator.clipboard.writeText(iFrameLink);
        toast.success(shareFeedbackTranslation.iframeLinkCopiedSuccessFully);
      } catch {}
    }
  };

  const sendEmailInvitationHandler = async () => {
    try {
      setLoading({ type: emailLoading, isLoading: true });

      const payload = {
        emails,
        feedbackLink: feedbackLongUrl,
      };

      const { data } = await axios({
        method: "POST",
        url: `${API_URL}/api/feedback/send-invitation/:${feedback.id}${user.slug}`,
        data: payload,
        headers: {
          Authorization: `Bearer ${user.accessToken}`,
        },
      });
      if (data) {
        if (
          data.status?.toLowerCase() === "success" &&
          typeof data.message === "string"
        ) {
          toast.success(data.message);
          setEmails([]);
        } else if (data.status?.toLowerCase() === "error") {
          toast.error(
            typeof data.message === "string"
              ? data.message
              : feedbackTranslation.shareFeedback.invitationFailed
          );
        }
      }
    } catch (error) {
      console.log(error);
    } finally {
      setLoading({ type: null, isLoading: false });
    }
  };

  return (
    <Dialog
      open={isOpen}
      onOpen={() => {
        setIsOpen(true);
      }}
      onCancel={() => {
        setIsOpen(false);
      }}
      onConfirm={() => {
        setIsOpen(false);
      }}
      content={
        <div className="share-feedback-wrapper">
          <div className="share-feedback-tabs">
            <ol className="share-feedback-tab-list">
              <li
                className={`share-feedback-tab-list-item ${
                  activeTab === linkTab ? "active" : ""
                }`}
                onClick={() => {
                  setActiveTab(linkTab);
                }}
              >
                {shareFeedbackTranslation.share}
              </li>
              <li
                className={`share-feedback-tab-list-item ${
                  activeTab === iFrameTab ? "active" : ""
                }`}
                onClick={() => {
                  setActiveTab(iFrameTab);
                }}
              >
                {shareFeedbackTranslation.embed}
              </li>
            </ol>
            <div className="share-feedback-tab-content">
              {activeTab === linkTab ? (
                <div className="share-feedback-link">
                  <div className="share-feedback-link-container">
                    <div className="email-invite-wrapper">
                      <div className="input-wrapper">
                        <Flex column>
                          <Input
                            className="email-input"
                            fluid
                            icon={<SearchIcon />}
                            value={emailInputText}
                            onChange={(e, { value }) => {
                              setIsInvalidEmailText(false);
                              setEmailInputText(value);
                            }}
                            clearable
                            label={shareFeedbackTranslation.emailsToInvite}
                            onKeyDown={(e) => {
                              if (e.key === "Enter") {
                                onAddEmailHandler();
                              }
                            }}
                            error={isInvalidEmailText}
                          />
                          {isInvalidEmailText ? (
                            <p className="invalid-text">
                              {shareFeedbackTranslation.emailValidationError}
                            </p>
                          ) : null}
                          <p>{shareFeedbackTranslation.emailAddNote}</p>
                        </Flex>
                      </div>
                      <div className="emails-wrapper">
                        <Flex wrap>
                          {(emails.length > 5
                            ? isEmailsExpanded
                              ? emails
                              : emails.slice(0, 5)
                            : emails
                          ).map((item) => {
                            return (
                              <Pill
                                actionable
                                onDismiss={() => {
                                  setEmails((prev) =>
                                    prev.filter((data) => data !== item)
                                  );
                                }}
                                style={{
                                  minWidth: "initial",
                                  backgroundColor: "#6264A7",
                                  color: "#fff",
                                }}
                              >
                                {item}
                              </Pill>
                            );
                          })}
                        </Flex>
                        {emails.length > 5 && (
                          <Flex hAlign="end">
                            <Button
                              primary
                              onClick={() =>
                                setIsEmailsExpanded((prev) => !prev)
                              }
                            >
                              {isEmailsExpanded
                                ? shareFeedbackTranslation.showLess
                                : shareFeedbackTranslation.showAll}
                            </Button>
                          </Flex>
                        )}
                      </div>
                      <div className="action-button-wrapper">
                        <Flex
                          space="between"
                          className="feedback-share-action-buttons"
                        >
                          <Button
                            primary
                            icon={<ShareAltIcon />}
                            content={shareFeedbackTranslation.shareLink}
                            onClick={() => {
                              shareFeedbackLinkHandler(feedback.id);
                            }}
                          />
                          <Button
                            primary
                            disabled={
                              !emails.length ||
                              (loading.type === emailLoading &&
                                loading.isLoading)
                            }
                            icon={<UserFriendsIcon />}
                            content={
                              loading.type === emailLoading && loading.isLoading
                                ? commonTranslation.loading
                                : shareFeedbackTranslation.invite
                            }
                            loading={
                              loading.type === emailLoading && loading.isLoading
                            }
                            onClick={sendEmailInvitationHandler}
                          />
                        </Flex>
                      </div>
                    </div>
                    <div className="qr-code-action-wrapper">
                      <div className="qr-image-wrapper">
                        <img src={qrCodeImage} />
                      </div>
                      <div>
                        <Button
                          primary
                          onClick={downloadQRImage}
                          icon={<DownloadIcon />}
                          content={
                            <>
                              <Text>{shareFeedbackTranslation.download} </Text>
                              <Text className="download-extra-text">
                                {shareFeedbackTranslation.QRasPNG}
                              </Text>
                            </>
                          }
                        />
                      </div>
                    </div>
                  </div>
                </div>
              ) : (
                <div className="share-feedback-iframe">
                  <p className="title">
                    {shareFeedbackTranslation.embedFeedback}
                  </p>

                  <div className="iframe-wrapper">
                    <iframe
                      src={feedbackLongUrl}
                      width="768"
                      height="432"
                      allowFullScreen
                      allow="camera; microphone; fullscreen; clipboard-read; clipboard-write"
                      frameborder="0"
                    />
                  </div>

                  <div className="iframe-link-wrapper">
                    <Input
                      className="iframe-link"
                      disabled
                      fluid
                      value={iFrameLink}
                    />
                  </div>
                  <Flex
                    column
                    hAlign="center"
                    className="iframe-action-buuton-wrapper"
                  >
                    <Button
                      primary
                      icon={<CodeSnippetIcon />}
                      content={shareFeedbackTranslation.CopyEmbedCode}
                      onClick={copyIframeLinkHandler}
                    />
                  </Flex>
                </div>
              )}
            </div>
          </div>
        </div>
      }
      header={<div> {shareFeedbackTranslation.shareFeedback}</div>}
      headerAction={{
        icon: <CloseIcon />,
        title: commonTranslation.close,
        onClick: () => {
          setIsOpen(false);
        },
      }}
      trigger={
        <Button
          icon={<ShareGenericIcon outline />}
          onClick={() => {
            setEmailInputText("");
            setEmails([]);
            setIsInvalidEmailText(false);
          }}
          text
          loading={
            feedback.id === isCopyLinkLoading.id && isCopyLinkLoading.isLoading
          }
          style={{ width: "30px", minWidth: "20px" }}
        />
      }
      styles={{
        maxWidth: "700px",
      }}
    />
  );
};

export default withTranslation()(ShareFeedback);
