import React, { useState, useCallback, useEffect } from "react";
import styled from "styled-components";
import { Input, Text } from "@fluentui/react-northstar";
import { Controller, useForm } from "react-hook-form";
import { withTranslation } from "react-i18next";

import Footer from "../Footer";

const TitleText = styled(Text)`
  font-weight: bold;
  font-size: 18px;
`;

const InformationText = styled(Text)`
  font-size: 16px;
`;

const ContactInformation = ({
  currentStep,
  setCurrentStep,
  totalStep,
  data,
  setData,
  t,
}) => {
  const [initialValue, setInitialValue] = useState({
    mobileNumber: data.mobileNumber ?? "",
    emergencyMobileNumber: data.emergencyMobileNumber ?? "",
    addressLine1: data.addressLine1 ?? "",
    addressLine2: data.addressLine2 ?? "",
    city: data.city ?? "",
    state: data.state ?? "",
    country: data.country ?? "",
  });

  const form = useForm({
    defaultValues: {
      mobileNumber: "",
      emergencyMobileNumber: "",
      addressLine1: "",
      addressLine2: "",
      city: "",
      state: "",
      country: "",
    },
    values: initialValue,
    shouldUseNativeValidation: false,
  });

  const translation = t("feedback").body.forms.contactInformation;

  const {
    register,
    handleSubmit,
    formState,
    setValue,
    getValues,
    reset,
    control,
  } = form;

  const { errors } = formState;

  useEffect(() => {
    reset({
      mobileNumber: data.mobileNumber ?? "",
      emergencyMobileNumber: data.emergencyMobileNumber ?? "",
      addressLine1: data.addressLine1 ?? "",
      addressLine2: data.addressLine2 ?? "",
      city: data.city ?? "",
      state: data.state ?? "",
      country: data.country ?? "",
    });
  }, [data, reset]);

  const nextHandler = (values) => {
    setCurrentStep((prev) => prev + 1);
    setData((prevData) => ({ ...prevData, ...values }));
  };
  const backHandler = () => {
    setCurrentStep((prev) => prev - 1);
    setData((prevData) => ({ ...prevData, ...getValues() }));
  };

  return (
    <div className="ms-Grid contact-information-container">
      <div className="ms-Grid-row d-flex justify-content-center margin-device-wise">
        <div className="ms-Grid-col ms-sm12 ms-xl9">
          <form onSubmit={handleSubmit(nextHandler)}>
            <div className="ms-Grid">
              <div className="ms-Grid-row">
                <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12">
                  <TitleText>{translation.header}</TitleText>
                </div>
              </div>

              <div className="ms-Grid-row mt-2">
                <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12">
                  <InformationText weight="semibold">
                    {translation.title}
                  </InformationText>
                </div>
              </div>

              <div className="ms-Grid-row mt-3">
                <div className="ms-Grid-col ms-sm12 ms-md6 ms-lg6 mt-1">
                  <Controller
                    name="mobileNumber"
                    control={control}
                    rules={{
                      required: {
                        value: true,
                        message: translation.mobileValidation,
                      },
                    }}
                    render={({ field }) => (
                      <Input
                        {...field}
                        type="number"
                        label={translation.mobile}
                        input={{
                          styles: {
                            width: "100%",
                          },
                        }}
                        styles={{
                          width: "100%",
                        }}
                        className="mobile-number-input"
                      />
                    )}
                  />

                  <p className="error-message">
                    {errors?.mobileNumber?.message}
                  </p>
                </div>
                <div className="ms-Grid-col ms-sm12 ms-md6 ms-lg6 mt-1">
                  <Controller
                    name="emergencyMobileNumber"
                    control={control}
                    rules={{
                      required: {
                        value: true,
                        message: translation.emergencyMobileValidation,
                      },
                    }}
                    render={({ field }) => (
                      <Input
                        {...field}
                        type="number"
                        label={translation.emergencyMobile}
                        input={{ styles: { width: "100%" } }}
                        styles={{ width: "100%" }}
                        className="mobile-number-input"
                      />
                    )}
                  />
                  <p className="error-message">
                    {errors?.emergencyMobileNumber?.message}
                  </p>
                </div>
              </div>

              <div className="ms-Grid-row mt-2">
                <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12 mt-1">
                  <Controller
                    name="addressLine1"
                    control={control}
                    rules={{
                      required: {
                        value: true,
                        message: translation.addressValidation,
                      },
                    }}
                    render={({ field }) => (
                      <Input
                        {...field}
                        label={translation.addressLine1}
                        input={{ styles: { width: "100%" } }}
                        styles={{ width: "100%" }}
                      />
                    )}
                  />
                  <p className="error-message">
                    {errors?.addressLine1?.message}
                  </p>
                </div>
              </div>

              <div className="ms-Grid-row mt-2">
                <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12 mt-1">
                  <Controller
                    name="addressLine2"
                    control={control}
                    render={({ field }) => (
                      <Input
                        {...field}
                        label={translation.addressLine2}
                        input={{ styles: { width: "100%" } }}
                        styles={{ width: "100%" }}
                      />
                    )}
                  />
                </div>
              </div>

              <div className="ms-Grid-row mt-2">
                <div className="ms-Grid-col ms-sm12 ms-lg4 mt-2">
                  <Controller
                    name="city"
                    control={control}
                    rules={{
                      required: {
                        value: true,
                        message: translation.cityValidation,
                      },
                    }}
                    render={({ field }) => (
                      <Input
                        {...field}
                        label={translation.city}
                        input={{ styles: { width: "100%" } }}
                        styles={{ width: "100%" }}
                      />
                    )}
                  />
                  <p className="error-message">{errors?.city?.message}</p>
                </div>
                <div className="ms-Grid-col ms-sm12 ms-lg4 mt-2">
                  <Controller
                    name="state"
                    control={control}
                    rules={{
                      required: {
                        value: true,
                        message: translation.stateValidation,
                      },
                    }}
                    render={({ field }) => (
                      <Input
                        {...field}
                        label={translation.state}
                        input={{ styles: { width: "100%" } }}
                        styles={{ width: "100%" }}
                      />
                    )}
                  />
                  <p className="error-message">{errors?.state?.message}</p>
                </div>
                <div className="ms-Grid-col ms-sm12 ms-lg4 mt-2">
                  <Controller
                    name="country"
                    control={control}
                    rules={{
                      required: {
                        value: true,
                        message: translation.countryValidation,
                      },
                    }}
                    render={({ field }) => (
                      <Input
                        {...field}
                        label={translation.country}
                        input={{ styles: { width: "100%" } }}
                        styles={{ width: "100%" }}
                      />
                    )}
                  />
                  <p className="error-message">{errors?.country?.message}</p>
                </div>
              </div>
              <div className="ms-Grid-row d-flex justify-content-end mt-4">
                <Footer
                  step={currentStep}
                  totalStep={totalStep}
                  backHandler={backHandler}
                />
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default withTranslation()(ContactInformation);
