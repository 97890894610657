import Tabs from "./Tabs/Tabs";

import { EventCategory } from "./EventCantegory/eventCategory";
import { EventTag } from "./EventTag/eventTag";
import { useTranslation } from "react-i18next";

export const Events = ({
  user,
  isMobileView,
  addCategory,
  setAddCategory,
  addTag,
  setAddTag,
}) => {
  const { t } = useTranslation();

  return (
    <div className="events-setting-tabs-wrapper">
      <Tabs currentTab={t("setting").event[0]}>
        <div label={t("setting").event[0]}>
          <EventCategory
            user={user}
            isMobileView={isMobileView}
            addCategory={addCategory}
            setAddCategory={setAddCategory}
          />
        </div>
        <div label={t("setting").event[1]}>
          <EventTag
            user={user}
            isMobileView={isMobileView}
            addTag={addTag}
            setAddTag={setAddTag}
          />
        </div>
      </Tabs>
    </div>
  );
};
