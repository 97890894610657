import React from "react";
import { Modal, Row, Col } from "antd";
import { withTranslation } from "react-i18next";
import { Flex } from "@fluentui/react-northstar";

import SpeakerWidget from "../SpeakerWidget";

const RegistrationModal = (props) => {
  const { visible, data = [], onCancel, user, t } = props;

  const translation = t("events").body.registrationsModal;
  return (
    <>
      <Modal
        centered
        title={translation.registrations}
        open={visible}
        footer={false}
        onCancel={() => onCancel(false)}
        width={1000}
      >
        <Flex wrap gap={"gap.medium"}>
          {data?.map((d, index) => (
            <Flex key={index} styles={{ width: "150px" }}>
              {/* <SpeakerWidget data={d} /> */}
              <SpeakerWidget
                data={{
                  ...d,
                  email: d.email || d.type === "Teacher" ? d.teacherId : "",
                }}
                userToken={user?.SASToken}
              />
            </Flex>
          ))}
        </Flex>
      </Modal>
    </>
  );
};

export default withTranslation()(RegistrationModal);
