import React, { Fragment, useEffect, useState } from "react";
import { Flex } from "@fluentui/react-northstar";

import "./Controls.css";

const Controls = (props) => {
  const [isMobileView, setIsMobileView] = useState(window.innerWidth <= 767);

  useEffect(() => {
    const handleResize = () => {
      setIsMobileView(window.innerWidth <= 767);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  // console.log(props);
  return (
    <div className={isMobileView ? "mobile-chart-controls" : "chart-controls"}>
      <Flex
        gap="gap.small"
        className="Flex tour-filter"
        style={{ padding: isMobileView ? "0px 0px 8px" : "14px 14px 8px" }}
      >
        {/* {console.log(props)} */}
        {props.children.map((child, ind) => {
          if (child !== null) {
            return (
              <Fragment key={ind}>
                {/* // <div className="leftControls" key={key}> */}
                {child}
                {/* // </div> */}
              </Fragment>
            );
          }
        })}
      </Flex>
    </div>
  );
};

export default Controls;
