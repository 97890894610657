import React from "react";

import Header from "../../components/Header/Header";
import Controls from "../Charts/components/Controls/Controls";
import ReactMultiSelectCheckboxes from "react-multiselect-checkboxes";
import {
  Datepicker,
  Dropdown,
  Segment,
  Flex,
  Table,
  Loader,
  ItemLayout,
  Avatar,
  Dialog,
  Checkbox,
} from "@fluentui/react-northstar";

import axios from "axios";

import { ExportBulkData, StudentExport } from "../../helper/ExportData";

import { withTranslation } from "react-i18next";
import { classTypeOption } from "../../constant/classTypeOption";
import Background from "../../Assets/images/sunset.jpeg";

import "./History.css";
import moment from "moment";
import { toast } from "react-toastify";

import userSvg from "../../Assets/images/svg/user.svg";
import { Button, DialogActions, DialogTitle, Grid } from "@mui/material";

const url = process.env.REACT_APP_EP_URL;

let teachersNames = [];
let d = new Date();

const options = [
  { label: "Thing 1", value: 1 },
  { label: "Thing 2", value: 2 },
];

class History extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedClass: [],
      selectedTeacher: null,
      LessonDate: [
        new Date(d.getFullYear(), d.getMonth() - 3, d.getDate(), 0, 0, 0, 0),
        new Date(d.getFullYear(), d.getMonth(), d.getDate(), 0, 0, 0, 0),
      ],
      allClassNames: null,
      teacherData: null,
      dropdownOption: [],
      studentData: [],
      classNames: [],
      loader: true,
      accDlg: false,
      checkPhysical: null,
      userRole: this.props.user.role,
      isMobileView: window.innerWidth <= 767,
      open: false,
    };
  }

  handleResize = () => {
    this.setState({
      isMobileView: window.innerWidth <= 767,
    });
  };

  historyHandler() {
    if (
      this.state.userRole === "Admin" ||
      this.state.userRole === "SuperAdmin"
    ) {
      axios
        .post(
          `${url}/api/chart/get-chart-by-classname${this.props.user.slug}`,
          {
            ClassName: null,
            LessonDate: this.state.LessonDate,
          },
          {
            headers: {
              Authorization: "Bearer " + this.props.user.accessToken,
            },
          }
        )
        .then((res) => {
          const response = res.data.result;
          let classNames = response
            ? response.map((lsn) => {
                return lsn.ClassName;
              })
            : [];

          let dropdownOption = [];
          response.map((data) => {
            dropdownOption.push({
              label: data.ClassName,
              value: data.ClassName,
            });
          });
          if (
            this.state.allClassNames === null ||
            this.state.allClassNames.length === 0
          ) {
            this.setState({
              allClassNames: classNames,
              loader: false,
              dropdownOption,
            });
          }
        })
        .catch((err) => console.log(err));

      this.getTeacher();
    } else {
      axios
        .get(
          `${url}/api/teacher/get-teacher/${this.props.user.username}${this.props.user.slug}`,
          {
            headers: {
              Authorization: "Bearer " + this.props.user.accessToken,
            },
          }
        )
        .then((res) => {
          const classdata = res.data.result?.LessonData
            ? res.data.result.LessonData
            : [];
          let classNames =
            classdata && classdata.length
              ? classdata
                  .map((lsn) => {
                    return lsn.ClassName;
                  })
                  .filter((value, index, self) => self.indexOf(value) === index)
              : [];

          if (this.state.allClassNames === null) {
            this.setState({ allClassNames: classNames, loader: false });
          }
        })
        .catch((err) => console.log(err));
    }
  }
  componentDidUpdate(prevProps) {
    if (prevProps.user.role !== this.props.user.role) {
      this.setState({ userRole: this.props.user.role }, () =>
        this.historyHandler()
      );
    }
  }
  componentDidMount() {
    this.historyHandler();
    this.stateChangeHandler();
    window.addEventListener("resize", this.handleResize);
    if (document.querySelector(".css-1gpjby2")) {
      let classes =
        document.querySelector(".ui-icon svg")?.classList.value || "";
      let dropDowns = document.querySelectorAll(".css-1gpjby2");
      [].forEach.call(dropDowns, function (dropDown) {
        dropDown.innerHTML = `<svg role="presentation" focusable="false" viewBox="2 2 16 16" class="${classes}" style="width: 16px"><path class="ui-icon__outline br" d="M15.8527 7.64582C16.0484 7.84073 16.0489 8.15731 15.854 8.35292L10.389 13.8374C10.1741 14.0531 9.82477 14.0531 9.60982 13.8374L4.14484 8.35292C3.94993 8.15731 3.95049 7.84073 4.1461 7.64582C4.34171 7.4509 4.65829 7.45147 4.85321 7.64708L9.99942 12.8117L15.1456 7.64708C15.3406 7.45147 15.6571 7.4509 15.8527 7.64582Z"></path><path class="ui-icon__filled cs" d="M15.793 7.73271C16.0787 8.03263 16.0672 8.50737 15.7672 8.79306L10.5168 13.7944C10.2271 14.0703 9.77187 14.0703 9.4822 13.7944L4.23173 8.79306C3.93181 8.50737 3.92028 8.03263 4.20597 7.73271C4.49166 7.43279 4.96639 7.42125 5.26631 7.70694L9.99949 12.2155L14.7327 7.70694C15.0326 7.42125 15.5073 7.43279 15.793 7.73271Z"></path></svg>`;
      });
    }
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.handleResize);
  }

  getTeacher() {
    axios
      .post(
        `${url}/api/teacher/get-teachers${this.props.user.slug}`,
        {},
        {
          headers: {
            Authorization: "Bearer " + this.props.user.accessToken,
          },
        }
      )
      .then((res) => {
        teachersNames = res.data.result
          ? res.data.result
              .map((teacher, index) => {
                return {
                  header: teacher.TeacherName,
                  image: teacher.UrlPhoto
                    ? teacher.UrlPhoto + this.props.user.SASToken
                    : userSvg,
                  content: teacher.Facultad,
                  key: teacher.TeacherEmailID,
                };
              })
              .filter((value, index, self) => self.indexOf(value) === index)
          : [];

        this.setState({ teacherData: res.data.result });
      })
      .catch((err) => {
        toast.error(err?.response?.data?.message);
        console.log(err, err.response);
        if (err.response?.status === 401) {
          this.setState({ accDlg: true });
        }
      });
  }

  getStudentHandler() {
    axios
      .post(
        `${url}/api/student/export-student${this.props.user.slug}`,
        {
          ClassName: this.state.selectedClass.length
            ? this.state.selectedClass
            : null,
          LessonDate: this.state.LessonDate,
          StudentEmailId: null,
          TeacherEmailID: this.state.selectedTeacher
            ? this.state.selectedTeacher.key
            : null,
          ClassType: this.state.checkPhysical,
        },
        {
          headers: {
            Authorization: "Bearer " + this.props.user.accessToken,
          },
        }
      )
      .then((res) => {
        this.setState({ studentData: res.data.result, loader: false });
      })
      .catch((err) => {
        toast.error(err?.response?.data?.message);
        console.log(err, err.response);
        if (err.response?.status === 401) {
          this.setState({ accDlg: true, loader: false });
        }
      });
  }

  stateChangeHandler() {
    this.setState({ loader: true });
    // console.log("State Changed!!", this.state);
    this.getStudentHandler();
  }

  classNameHandler = (lessions) => {
    let classNames = [];
    classNames =
      lessions && lessions.length
        ? lessions
            .map((lsn) => {
              return lsn.ClassName;
            })
            .filter((value, index, self) => self.indexOf(value) === index)
        : [];
    let dropdownOption = [];
    classNames.map((ClassName) => {
      dropdownOption.push({
        label: ClassName,
        value: ClassName,
      });
    });
    this.setState({ classNames, dropdownOption });
  };

  teacherChangedHandler = (teacher) => {
    axios
      .get(`${url}/api/teacher/get-teacher/${teacher}${this.props.user.slug}`, {
        headers: {
          Authorization: "Bearer " + this.props.user.accessToken,
        },
      })
      .then((res) => {
        this.classNameHandler(res.data.result.LessonData);
      })
      .catch((err) => {
        toast.error(err?.response?.data?.message);
        console.log(err, err.response);
        if (err.response?.status === 401) {
          this.setState({ accDlg: true });
        }
      });
  };

  openFilterDialog = () => {
    this.setState({ open: true });
  };

  closeFilterDialog = () => {
    this.setState({ open: false });
  };

  render() {
    const { t } = this.props;

    Array.prototype.sum = function (prop) {
      var total = 0;
      for (var i = 0, _len = this.length; i < _len; i++) {
        total += parseInt(this[i][prop]);
      }
      return total;
    };

    let studentData = this.state.studentData;
    let data = studentData
      ? {
          "Selected Classes": this.state.selectedClass.length
            ? this.state.selectedClass.length
            : this.state.classNames.length
            ? this.state.classNames.length
            : this.state.allClassNames
            ? this.state.allClassNames.length
            : 0,
          "Total Students": studentData.length,
          Present: studentData.sum("Presente"),
          Absent: studentData.sum("Ausente"),
          Delay: studentData.sum("Retardo"),
          Justification: studentData.sum("Justificado"),
          "Average Attendance":
            (
              (studentData.sum("Presente") / studentData.sum("Total")) *
              100
            ).toFixed(2) + "%",
        }
      : null;
    const boxData = data ? Object.entries(data) : [];

    const dropDownChangeHandler = (data) => {
      let selectedClass = data.map(({ value }) => value);
      this.setState({ selectedClass }, () => {
        this.stateChangeHandler();
      });
    };

    return (
      <div className="Charts Schedule s-ctr">
        <Dialog
          open={this.state.accDlg}
          onConfirm={() => location.reload()}
          confirmButton="Refresh Now"
          content={
            <p className="refreshMsg">
              No big deal — but we need to refresh to show your content.
            </p>
          }
          header="Authorization expire"
        />
        <Header
          path="history"
          user={{ ...this.props.user }}
          logout={this.props.logout.bind()}
          accessToken={this.props.user.accessToken}
          getDb={this.props.getDb.bind()}
          switchDb={this.props.switchDb}
          openFilterDialog={this.openFilterDialog}
          closeFilterDialog={this.closeFilterDialog}
          open={this.state.open}
          selectedExtension={this.props.selectedExtension}
          setSelectedExtension={this.props.setSelectedExtension}
        />

        {this.state.isMobileView ? (
          <Controls>
            <Dialog
              open={this.state.open}
              onDismiss={this.closeFilterDialog}
              className="filter-dialog"
              styles={{ width: "75% !important" }}
              content={
                <>
                  <DialogTitle
                    style={{
                      position: "sticky",
                      zIndex: "2",
                      top: "0",
                      background: "white",
                      padding: "6px",
                      display: "flex",
                      justifyContent: "center",
                      fontWeight: "600",
                    }}
                  >
                    Filter a Value
                  </DialogTitle>
                  <div>
                    <Segment
                      content={t("attendance").controls.classType[0]}
                      className="seg1"
                      style={{ boxShadow: "none" }}
                    />

                    <Segment
                      content={
                        <Dropdown
                          items={classTypeOption}
                          checkable
                          defaultValue="All"
                          onChange={(e, { value }) => {
                            this.setState(
                              { checkPhysical: value === "All" ? null : value },
                              () => {
                                this.stateChangeHandler();
                              }
                            );
                          }}
                          className="mobile-dropdown"
                        />
                      }
                      style={{ boxShadow: "none" }}
                      className="seg2 class-type classSelector mobile-view"
                    />
                  </div>
                  {teachersNames && teachersNames.length ? (
                    <div>
                      <Segment
                        content={t("AttendanceHistory").controls.teacher[0]}
                        className="seg1"
                        style={{ boxShadow: "none" }}
                      />
                      <Segment
                        content={
                          <Dropdown
                            clearable
                            search
                            // inverted
                            items={teachersNames}
                            placeholder={
                              t("AttendanceHistory").controls.teacher[1]
                            }
                            getA11ySelectionMessage={{
                              onAdd: (item) => {
                                this.teacherChangedHandler(item.key);
                                this.setState(
                                  { selectedTeacher: item, selectedClass: [] },
                                  () => {
                                    this.stateChangeHandler();
                                  }
                                );
                              },
                            }}
                            noResultsMessage="We couldn't find any matches."
                            className="mobile-dropdown"
                          />
                        }
                        style={{ boxShadow: "none" }}
                        className="seg2 teacher-drop mobile-view"
                      />
                    </div>
                  ) : null}
                  {this.state?.dropdownOption?.length ? (
                    <div>
                      <Segment
                        content={t("AttendanceHistory").controls.class}
                        className="seg1"
                        style={{ boxShadow: "none" }}
                      />

                      <Segment
                        content={
                          <ReactMultiSelectCheckboxes
                            getDropdownButtonLabel={({
                              placeholderButtonLabel,
                              value,
                            }) => {
                              if (!value) {
                                return placeholderButtonLabel;
                              }

                              if (Array.isArray(value)) {
                                if (value.length === 0) {
                                  document
                                    .querySelector(".classSelector")
                                    ?.classList.remove("filterSelected");
                                  return placeholderButtonLabel;
                                }
                                if (value.length !== 0) {
                                  document
                                    .querySelector(".classSelector")
                                    ?.classList.add("filterSelected");
                                }

                                if (value.length === 1) {
                                  return value[0].label;
                                }

                                return "".concat(
                                  value.length,
                                  " clase seleccionada"
                                );
                              }
                            }}
                            value={
                              this.state.selectedClass.map((classname) => {
                                return { label: classname, value: classname };
                              }) || []
                            }
                            options={this.state.dropdownOption}
                            onChange={(data) => dropDownChangeHandler(data)}
                            placeholderButtonLabel={
                              t("AttendanceHistory").controls.class
                            }
                          />
                        }
                        style={{ boxShadow: "none" }}
                        className="seg2 classSelector-mobile"
                      />
                    </div>
                  ) : null}
                  <DialogActions
                    sx={{
                      position: "sticky",
                      zIndex: "1",
                      bottom: "0",
                      background: "white",
                    }}
                  >
                    <Button onClick={this.closeFilterDialog}>Cancel</Button>
                    <Button onClick={this.closeFilterDialog}>Ok</Button>
                  </DialogActions>
                </>
              }
            />
            <Grid container>
              <Grid item xs={12} style={{ display: "flex", gap: "11px" }}>
                <Segment
                  content={
                    <Datepicker
                      className="datepicker-mobileView"
                      inputPlaceholder={t("AttendanceHistory").controls.date[0]}
                      defaultSelectedDate={this.state.LessonDate[0]}
                      style={{ display: "flex" }}
                      onDateChange={(e, v) => {
                        let LessonDate = { ...this.state.LessonDate };
                        LessonDate[0] = v.value.toISOString();
                        this.setState({ LessonDate }, () => {
                          this.stateChangeHandler();
                        });
                      }}
                    />
                  }
                  className="seg2"
                />
                <Segment
                  content={
                    <Datepicker
                      className="datepicker-mobileView"
                      inputPlaceholder={t("AttendanceHistory").controls.date[1]}
                      defaultSelectedDate={this.state.LessonDate[1]}
                      style={{ display: "flex" }}
                      onDateChange={(e, v) => {
                        let LessonDate = { ...this.state.LessonDate };
                        LessonDate[1] = v.value.toISOString();
                        this.setState({ LessonDate }, () => {
                          this.stateChangeHandler();
                        });
                      }}
                    />
                  }
                  className="seg2"
                />
                {this.state.studentData && this.state.studentData.length ? (
                  <div>
                    <Segment
                      content={
                        <ExportBulkData
                          dataset={this.state.studentData}
                          dates={this.state.LessonDate}
                        />
                      }
                      className="seg2"
                    />
                  </div>
                ) : null}
              </Grid>
            </Grid>
          </Controls>
        ) : (
          <Controls>
            <div className="leftControls">
              <Segment
                content={t("AttendanceHistory").controls.date[0]}
                className="seg1"
              />
              <Segment
                content={
                  <Datepicker
                    inputPlaceholder={t("AttendanceHistory").controls.date[0]}
                    defaultSelectedDate={this.state.LessonDate[0]}
                    style={{ margin: 0, marginLeft: "31px" }}
                    onDateChange={(e, v) => {
                      let LessonDate = { ...this.state.LessonDate };
                      LessonDate[0] = v.value.toISOString();
                      this.setState({ LessonDate }, () => {
                        this.stateChangeHandler();
                      });
                    }}
                  />
                }
                className="seg2"
              />
            </div>
            <div className="leftControls">
              <Segment
                content={t("AttendanceHistory").controls.date[1]}
                className="seg1"
              />
              <Segment
                content={
                  <Datepicker
                    inputPlaceholder={t("AttendanceHistory").controls.date[1]}
                    defaultSelectedDate={this.state.LessonDate[1]}
                    style={{ margin: 0, marginLeft: "31px" }}
                    onDateChange={(e, v) => {
                      let LessonDate = { ...this.state.LessonDate };
                      LessonDate[1] = v.value.toISOString();
                      this.setState({ LessonDate }, () => {
                        this.stateChangeHandler();
                      });
                    }}
                  />
                }
                className="seg2"
              />
            </div>
            <div className="leftControls">
              <Segment
                content={t("attendance").controls.classType[0]}
                className="seg1"
              />

              <Segment
                content={
                  <Dropdown
                    items={classTypeOption}
                    checkable
                    defaultValue="All"
                    onChange={(e, { value }) => {
                      this.setState(
                        { checkPhysical: value === "All" ? null : value },
                        () => {
                          this.stateChangeHandler();
                        }
                      );
                    }}
                  />
                }
                className="seg2 class-type classSelector"
              />
              {/* <Segment
              content={
                <Dropdown
                  items={classTypeOption}
                  placeholder={
                    t("chart").screens.subject.controls.dropdown
                      .class_type_placeholder
                  }
                  checkable
                  defaultValue="All"
                  // checked={props?.checkPhysical === "Physical" ? true : false}
                  onChange={(e, { value }) => {
                    this.setState(
                      { checkPhysical: value === "All" ? null : value },
                      () => {
                        this.stateChangeHandler();
                      }
                    );
                  }}
                />
              }
              className="seg2"
            /> */}
            </div>
            {teachersNames && teachersNames.length ? (
              <div className="leftControls">
                <Segment
                  content={t("AttendanceHistory").controls.teacher[0]}
                  className="seg1"
                />
                <Segment
                  content={
                    <Dropdown
                      clearable
                      search
                      // inverted
                      items={teachersNames}
                      placeholder={t("AttendanceHistory").controls.teacher[1]}
                      getA11ySelectionMessage={{
                        onAdd: (item) => {
                          this.teacherChangedHandler(item.key);
                          this.setState(
                            { selectedTeacher: item, selectedClass: [] },
                            () => {
                              this.stateChangeHandler();
                            }
                          );
                        },
                      }}
                      noResultsMessage="We couldn't find any matches."
                    />
                  }
                  className="seg2 teacher-drop"
                />
              </div>
            ) : null}
            {this.state?.dropdownOption?.length ? (
              <div className="leftControls">
                <Segment
                  content={t("AttendanceHistory").controls.class}
                  className="seg1"
                />

                <Segment
                  content={
                    <ReactMultiSelectCheckboxes
                      getDropdownButtonLabel={({
                        placeholderButtonLabel,
                        value,
                      }) => {
                        if (!value) {
                          return placeholderButtonLabel;
                        }

                        if (Array.isArray(value)) {
                          if (value.length === 0) {
                            document
                              .querySelector(".classSelector")
                              ?.classList.remove("filterSelected");
                            return placeholderButtonLabel;
                          }
                          if (value.length !== 0) {
                            document
                              .querySelector(".classSelector")
                              ?.classList.add("filterSelected");
                          }

                          if (value.length === 1) {
                            return value[0].label;
                          }

                          return "".concat(value.length, " clase seleccionada");
                        }
                      }}
                      value={
                        this.state.selectedClass.map((classname) => {
                          return { label: classname, value: classname };
                        }) || []
                      }
                      options={this.state.dropdownOption}
                      onChange={(data) => dropDownChangeHandler(data)}
                      placeholderButtonLabel={
                        t("AttendanceHistory").controls.class
                      }
                    />
                  }
                  className="seg2 classSelector"
                />
              </div>
            ) : null}
            {this.state.studentData && this.state.studentData.length ? (
              <div className="leftControls">
                <Segment
                  content="Export"
                  className="seg1"
                  style={{ padding: "0" }}
                />
                <Segment
                  content={
                    <ExportBulkData
                      dataset={this.state.studentData}
                      dates={this.state.LessonDate}
                    />
                  }
                  className="seg2"
                />
              </div>
            ) : null}
          </Controls>
        )}
        <div
          className="Analytics darkMode"
          style={{
            background: `url(${Background}) center center no-repeat`,
            backgroundSize: "cover",
            border: "none",
            width: "100%",
          }}
        >
          <Segment
            content={
              <Flex gap="gap.small" className="Boxes" wrap>
                {boxData
                  ? boxData.map((data, key) => {
                      return (
                        <Segment
                          key={key}
                          content={
                            <>
                              <Segment
                                content={t("AttendanceHistory").body.boxes[key]}
                                className="ui-seg1"
                              />
                              <Segment
                                content={
                                  <h1
                                    className="seg-header Fcabin"
                                    style={{
                                      fontSize:
                                        this.state.isMobileView && "1.5em",
                                    }}
                                  >
                                    {data[1] === "100.00%"
                                      ? "100%"
                                      : data[1] === "NaN%"
                                      ? "0%"
                                      : data[1]}
                                  </h1>
                                }
                              />
                            </>
                          }
                          className={
                            this.state.isMobileView
                              ? "mobile-seg-Box"
                              : "seg-Box"
                          }
                          title={t("AttendanceHistory").body.boxes[key]}
                          // styles={{
                          //   width: this.state.isMobileView && "99px !important",
                          // }}
                        />
                      );
                    })
                  : null}
              </Flex>
            }
            className="BoxChart darkMode"
          />
          <Segment
            content={
              <>
                {this.state.studentData && this.state.studentData.length ? (
                  <>
                    <Table
                      header={t("AttendanceHistory").body.table}
                      aria-label="Static table with no rows"
                      className="gridHeader sm-hide"
                    />
                    <Table
                      header={[
                        "Students",
                        "P",
                        "A",
                        "L",
                        "J",
                        "Avg",
                        t("AttendanceHistory").body.table[6],
                      ]}
                      aria-label="Static table with no rows"
                      className="gridHeader-small"
                    />
                    <Table
                      style={{
                        overflowY: "scroll",
                        position: "relative",
                        height: "331px",
                      }}
                      rows={
                        this.state.studentData.length
                          ? this.state.studentData.map((student, key) => {
                              return {
                                key: student.StudentEmailId + "key" + key,
                                items: [
                                  {
                                    content: (
                                      <ItemLayout
                                        media={
                                          <Avatar
                                            image={
                                              student.StudentPhoto &&
                                              student.StudentPhoto.Foto
                                                ? student.StudentPhoto.Foto +
                                                  this.props.user.SASToken
                                                : null
                                            }
                                            name={student.StudentName}
                                          />
                                        }
                                        header={student.StudentName}
                                        style={{ paddingLeft: "0px" }}
                                        className="stud-name"
                                      />
                                    ),
                                    key: "StudentName" + key,
                                  },
                                  {
                                    content: student.Presente,
                                    key: "Presente" + key,
                                  },
                                  {
                                    content: student.Ausente,
                                    key: "Ausente" + key,
                                  },
                                  {
                                    content: student.Retardo,
                                    key: "Retardo" + key,
                                  },
                                  {
                                    content: student.Justificado,
                                    key: "Justificado" + key,
                                  },
                                  {
                                    content:
                                      (
                                        (student.Presente / student.Total) *
                                        100
                                      ).toFixed(2) + "%",
                                    key: "Average" + key,
                                  },
                                  {
                                    content: (
                                      <Flex gap="gap.large">
                                        <Flex.Item size="size.quarter">
                                          <StudentExport
                                            dataset={{
                                              data: {
                                                ClassName: this.state
                                                  .selectedClass.length
                                                  ? this.state.selectedClass
                                                  : null,
                                                LessonDate:
                                                  this.state.LessonDate,
                                                StudentEmailId:
                                                  student.StudentEmailId,
                                                TeacherEmailID: this.state
                                                  .selectedTeacher
                                                  ? this.state.selectedTeacher
                                                      .key
                                                  : null,
                                              },
                                              head: {
                                                headers: {
                                                  Authorization:
                                                    "Bearer" +
                                                    " " +
                                                    this.props.user.accessToken,
                                                },
                                              },
                                              slug: this.props.user.slug,
                                            }}
                                          />
                                        </Flex.Item>
                                      </Flex>
                                    ),
                                    key: `Export-${key}`,
                                  },
                                ],
                              };
                            })
                          : []
                      }
                      aria-label="Static headless table"
                      className="gridRows"
                    />
                  </>
                ) : this.state.loader ? (
                  <Loader />
                ) : (
                  <>
                    <h3 className="text-white">No Data Found!</h3>
                  </>
                )}
              </>
            }
            className="scheduleTable darkMode"
          />
        </div>
      </div>
    );
  }
}

export default withTranslation()(History);
