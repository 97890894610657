import React from "react";
import "./MeetingDuration.scss";
import { Button, Flex, Input, Text } from "@fluentui/react-northstar";
import { useBooking } from "../../../../../../context/BookingContext";
import { useTranslation } from "react-i18next";

const MeetingDuration = (props) => {
  const { t } = useTranslation();

  const translation = t("booking").setting.meetingDuration;
  const {
    durationOfSlot,
    setDurationOfSlot,
    isScheduling,
    isShowingScheduledSlots,
  } = useBooking();
  const { handleUpdateDuration } = props;
  return (
    <Flex column className="meeting-duration-wrapper" gap="gap.small">
      <Text weight="regular" size="large" content={translation.header} />
      <Text content={translation.message} />
      <Input
        className="meeting-duration-input"
        type="number"
        value={durationOfSlot}
        disabled={isScheduling || isShowingScheduledSlots}
        onChange={(e) => {
          setDurationOfSlot(e.target.value);
        }}
      />
      <Flex hAlign="end">
        <Button
          primary
          className="update-duration-button"
          onClick={() => handleUpdateDuration(durationOfSlot)}
          disabled={isScheduling || isShowingScheduledSlots}
        >
          {translation.updateDuration}
        </Button>
      </Flex>
    </Flex>
  );
};

export default MeetingDuration;
