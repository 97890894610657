import React, { useState, useEffect } from "react";
import styled from "styled-components";
import {
  Card,
  CardBody,
  CardHeader,
  Dropdown,
  Flex,
  Pill,
  Text,
  ContentIcon,
  UserFriendsIcon,
} from "@fluentui/react-northstar";
import { withTranslation } from "react-i18next";

import SelectorModal from "../Modals/SelectorModal";
import { NotificationAlert } from "../../../../components/Notification/Notification";
import Footer from "../Footer";

const TitleText = styled(Text)`
  font-weight: bold;
  font-size: 18px;
`;

const CategoryCard = styled(Card)`
  border-radius: 35px;
  background-color: #f3f2f1;
  min-height: 150px;
  box-shadow: 0px 2px 2px 1px #dcdcdc;
  :hover {
    background-color: #f3f2f1;
  }
`;

const InterestPill = styled(Pill)`
  min-width: unset;
`;

const iconList = [
  {
    category: "microsoft",
    icon: <ContentIcon />,
  },
  {
    category: "sport",
    icon: <UserFriendsIcon />,
  },
];

const SpecialityInformation = ({
  currentStep,
  setCurrentStep,
  totalStep,
  data,
  setData,
  setIsEditProfile,
  interests: interestData,
  skills,
  t,
}) => {
  const [selectedSpecialities, setSelectedSpecialities] = useState([]);
  const [interests, setInterests] = useState();
  const [dataBasedOnSelection, setDataBasedOnSelection] = useState([]);
  const [searchValue, setSearchValue] = useState("");

  const translation = t("feedback").body.forms.specialityInformation;

  const getInterestBasedOnSelection = () => {
    let interests = [];
    if (!!selectedSpecialities?.length && !!interestData.length) {
      interests = interestData.filter((item) => {
        return (
          item.category &&
          selectedSpecialities
            .map((item) => item.category?.toLowerCase())
            .includes(item.category?.toLowerCase()) &&
          !selectedSpecialities.map((item) => item.id).includes(item.id)
        );
      });
    }
    if (interests.length > 8) {
      interests = Object.values(
        interests.reduce((acc, item) => {
          let formattedCategory = item.category?.toLowerCase();
          acc[formattedCategory] = acc[formattedCategory] ?? [];
          acc[formattedCategory].push(item);
          return acc;
        }, {})
      )
        .map((item) => item.slice(0, 3))
        .reduce((acc, item) => {
          acc = [...acc, ...item];
          return acc;
        }, [])
        .slice(0, 8);
    }

    setDataBasedOnSelection(interests?.length ? interests : []);
  };

  useEffect(() => {
    if (Array.isArray(data.specialities)) {
      const specialities = data.specialities.map((item) => item.id);
      let filteredInterests = [...interestData, ...skills].filter((interest) =>
        specialities.includes(interest.id)
      );
      setSelectedSpecialities(filteredInterests);
    } else {
      setSelectedSpecialities([]);
    }
  }, [data]);

  useEffect(() => {
    setInterests([
      ["skills", data.skills],
      ["interests", data.interests],
    ]);
  }, []);

  useEffect(() => {
    getInterestBasedOnSelection();
  }, [interestData, selectedSpecialities]);

  const nextHandler = () => {
    setCurrentStep((prev) => prev + 1);
    setData((prevData) => ({
      ...prevData,
      specialities: selectedSpecialities,
    }));
  };
  const backHandler = () => {
    setCurrentStep((prev) => prev - 1);
    setData((prevData) => ({
      ...prevData,
      specialities: selectedSpecialities,
    }));
  };

  const getDropdownItems = () => {
    let specialities = [...data.interests, ...data.skills];
    let allSpecialities = selectedSpecialities?.map((item) => item.title) ?? [];

    return (
      (allSpecialities.length
        ? specialities
            ?.map((item) => item.title)
            ?.filter((item) => !allSpecialities?.includes(item))
        : specialities?.map((item) => item.title)) ?? []
    );
  };

  return (
    <div className="skill-information-container">
      <div className="ms-Grid pt-3">
        <div className="ms-Grid-row margin-device-wise">
          <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg6">
            <div className="ms-Grid-row d-flex justify-content-center">
              <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg10">
                <div className="ms-Grid-row">
                  <TitleText className="pl-2">{translation.header}</TitleText>
                </div>

                <div className="ms-Grid-row pt-3">
                  <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12">
                    <Flex column>
                      <Text weight="semibold">
                        {translation.searchSpeciality}
                      </Text>
                      <Dropdown
                        className="skill-dropdown"
                        fluid
                        search
                        checkable
                        clearable
                        value={[]}
                        items={getDropdownItems()}
                        onChange={(e, { value }) => {
                          const specialities = [...data.interests, ...data.skills]
                          if (selectedSpecialities.length > 5) {
                            NotificationAlert(
                              translation.maxSpecialityValidation,
                              "warning"
                            );
                          } else {
                            setSelectedSpecialities((prevData) => [
                              ...prevData,
                              specialities.find(
                                (item) => item.title === value
                              ),
                            ]);
                          }
                          setSearchValue("");
                        }}
                        placeholder={translation.placeholder}
                        noResultsMessage={translation.noMatchFound}
                        //   getA11ySelectionMessage={getA11ySelectionField}
                        searchQuery={searchValue}
                        onSearchQueryChange={(e, { searchQuery }) => {
                          setSearchValue(searchQuery);
                        }}
                      />
                    </Flex>
                  </div>
                </div>

                <div className="ms-Grid-row pt-2">
                  <div className="ms-Grid-col ms-sm12">
                    <Flex space="between">
                      <Text>{translation.maxSpeciality}</Text>

                      <Text>{selectedSpecialities.length}/5</Text>
                    </Flex>
                  </div>
                </div>

                {!!selectedSpecialities?.length ? (
                  <div className="ms-Grid-row mt-3">
                    <div>
                      <Flex wrap>
                        {selectedSpecialities.map((item) => {
                          return (
                            <InterestPill
                              key={item.id}
                              actionable
                              onDismiss={() => {
                                const filteredSpecialities =
                                  selectedSpecialities.filter(
                                    (speciality) => speciality.id !== item.id
                                  );
                                setSelectedSpecialities(filteredSpecialities);
                              }}
                              style={{
                                minWidth: "initial",
                                backgroundColor: "#6264A7",
                                color: "#fff",
                              }}
                            >
                              {item.title}
                            </InterestPill>
                          );
                        })}
                      </Flex>
                    </div>
                  </div>
                ) : null}

                {!!selectedSpecialities?.length ? (
                  <div className="ms-Grid-row mt-3">
                    <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12">
                      <Flex column>
                        <Text weight="semibold">
                          {translation.specialityBasedOnSearch}
                        </Text>
                        <Flex wrap>
                          {dataBasedOnSelection?.map((data) => {
                            return (
                              <InterestPill
                                key={data.id}
                                onClick={() => {
                                  setSelectedSpecialities((prevData) => {
                                    if (prevData.includes(data.id)) {
                                      return prevData.filter(
                                        (item) => item.id !== data.id
                                      );
                                    } else {
                                      if ([...prevData, data].length > 5) {
                                        NotificationAlert(
                                          translation.maxSpecialityValidation,
                                          "warning"
                                        );
                                        return prevData;
                                      } else {
                                        return [...prevData, data];
                                      }
                                    }
                                  });
                                }}
                                className="cur-pointer"
                              >
                                {data.title}
                              </InterestPill>
                            );
                          })}
                        </Flex>
                      </Flex>
                    </div>
                  </div>
                ) : null}
              </div>
            </div>
          </div>

          <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg6  mt-5 mt-md-0">
            <CategoryCard className="ms-depth-8" style={{ width: "100%" }}>
              <CardHeader>
                <Flex gap="gap.small">
                  <TitleText className="pl-2">
                    {translation.categories}:
                  </TitleText>
                </Flex>
              </CardHeader>
              <CardBody>
                <div className="ms-Grid-row">
                  <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12">
                    <Flex wrap>
                      {!!interests?.length &&
                        interests.map((item) => {
                          const title = item[0];
                          const skillOptions = item[1];
                          return (
                            <SelectorModal
                              key={title}
                              items={skillOptions?.length ? skillOptions : []}
                              selectedItems={
                                selectedSpecialities.length
                                  ? selectedSpecialities
                                  : []
                              }
                              header={`${title} ${translation.speciality}`}
                              confirmButton={translation.addSpeciality}
                              // clearButton={"Clear"}
                              onSubmit={(value) => {
                                if (value?.length > 5) {
                                  NotificationAlert(
                                    translation.maxSpecialityValidation,
                                    "warning"
                                  );
                                  return "limit reached";
                                }
                                setSelectedSpecialities(value);
                              }}
                            >
                              {({ open, setOpen }) => {
                                let skill = skillOptions?.[1]?.category;
                                let icon = iconList.find(
                                  (item) => item.category === skill
                                );
                                return (
                                  <div>
                                    <Pill
                                      icon={
                                        icon ? (
                                          <ContentIcon />
                                        ) : (
                                          <UserFriendsIcon />
                                        )
                                      }
                                      onClick={() => {
                                        setOpen(true);
                                      }}
                                      className="cur-pointer"
                                    >
                                      {title}
                                    </Pill>
                                  </div>
                                );
                              }}
                            </SelectorModal>
                          );
                        })}
                    </Flex>
                  </div>
                </div>
              </CardBody>
            </CategoryCard>
          </div>
        </div>

        <div className="ms-Grid-row margin-device-wise mt-4">
          <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg6">
            <div className="d-flex justify-content-end">
              <Footer
                step={currentStep}
                totalStep={totalStep}
                backHandler={backHandler}
                nextHandler={nextHandler}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default withTranslation()(SpecialityInformation);
