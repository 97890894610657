import { useEffect, useState } from "react";

import {
  AddIcon,
  Button,
  CardBody,
  CardColumn,
  CardFooter,
  CardHeader,
  Card,
  EditIcon,
  Flex,
  PollIcon,
  Loader,
  Text,
} from "@fluentui/react-northstar";
import { initializeIcons, IconButton } from "@fluentui/react";
import { DropdownMenuItemType } from "@fluentui/react/lib/Dropdown";
import moment from "moment";
import axios from "axios";
import { useHistory } from "react-router-dom";
import { withTranslation } from "react-i18next";
import styled from "styled-components";

import { markingType as mark } from "../../constant";
import { useAssignment } from "../../../../context/AssignmentContext";
import { NotificationAlert } from "../../../../components/Notification/Notification";
import CreateNewAssignment from "../CreateNewAssignment/CreateNewAssignment";
import AssignmentTypeFilterButton from "../AssignmentButton/AssignmentTypeFilterButton";
import CreateRubricPage from "../Rubric/CreateRubricPage";
import NoAssignmentFound from "../../../../Assets/images/svg/NoAssignmentFound.svg";
import CustomProgressIndicator from "../../../../components/Loader/CustomProgressIndicator";

import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import "./AssignmentHome.css";
import "react-toastify/dist/ReactToastify.css";
import { Subscription } from "../../../../components/Subscription/Subscription";

initializeIcons();

const url = process.env.REACT_APP_EP_URL;

const SubjectName = styled(Text)`
  @media screen and (max-width: 800px) {
    font-size: 20px;
  }
  @media screen and (max-width: 500px) {
    font-size: 16px;
  }
`;
const ButtonTitle = styled(Text)`
  @media screen and (max-width: 500px) {
    font-size: 16px;
  }
`;

const StatusButtonWrapper = styled(Flex)`
  @media screen and (max-width: 800px) {
    justify-content: center;
    row-gap: 10px;
  }
`;

const AssignmentHome = (props) => {
  const history = useHistory();
  const { selectedLesson, user, showRecords, t, isSmall, isCompactMode } =
    props;

  const translation = t("assignment").body;
  const assignmentHomeTranslation = t("assignment").body.assignmentHome;

  const [assignmentList, setAssignmentList] = useState([]);

  const [filteredAssignment, setFilteredAssignment] = useState([]);
  const [filterStatus, setFilterStatus] = useState({
    isDraft: false,
    isPublished: false,
    isPublishLater: false,
  });

  const [currentExCard, setCurrentExCard] = useState(null);

  const [refreshAssignmentList, setRefreshAssignmentList] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isFormateAssignmentDataLoading, setIsFormateAssignmentDataLoading] =
    useState(false);
  const [isCreatingNew, setIsCreatingNew] = useState(false);

  const [isMobileView, setIsMobileView] = useState(window.innerWidth <= 767);
  const [isTabletView, setIsTabletView] = useState(window.innerWidth <= 991);

  const {
    setCurrentAssignmentData,
    newAssignmentInitialValues,
    setCurrentLessionStudentList,
    setOtherData,
    globalSearchText,
    setCurrentPage,
    setSelectedLesson,
    setSelectedLessonAssignments,
  } = useAssignment();

  let draftCount = 0;
  let publishedCount = 0;
  let publishLaterCount = 0;

  useEffect(() => {
    const handleResize = () => {
      setIsMobileView(window.innerWidth <= 767);
      setIsTabletView(window.innerWidth <= 991);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    setCurrentPage("assignmentHome");

    return () => {
      setCurrentPage("");
    };
  }, []);

  useEffect(() => {
    setOtherData((prevData) => ({ ...prevData, isCompactMode, isSmall }));
  }, [isCompactMode, isSmall]);

  useEffect(() => {
    if (showRecords?.length) {
      let list = showRecords.map((student) => ({
        key: student.StudentEmailId,
        email: student.StudentEmailId,
        text: student.StudentName,
        name: student.StudentName,
        url: student.StudentPhoto?.Foto,
      }));

      list.unshift(
        { key: 0, text: "All Student" },
        { itemType: DropdownMenuItemType.Divider }
      );
      setCurrentLessionStudentList(list);
    }
  }, [showRecords]);

  useEffect(() => {
    let assignmentStatusFilter = Object.entries(filterStatus);

    const getFilteredAssignmentByStatus = (value) => {
      const getFilterStatus = () => {
        switch (value) {
          case "isDraft":
            return "Draft";
          case "isPublished":
            return "Published";
          case "isPublishLater":
            return "Scheduled";
        }
      };
      const filterStatus = getFilterStatus(value);
      if (filterStatus) {
        let data = assignmentList;

        let filteredData = data?.filter((item) => item.status === filterStatus);
        return filteredData;
      } else {
        return assignmentList;
      }
    };
    let selectedStatus = assignmentStatusFilter.find(([key, value]) => value);
    if (globalSearchText?.length) {
      let data = selectedStatus ? filteredAssignment : assignmentList;

      if (selectedStatus) {
        data = getFilteredAssignmentByStatus(selectedStatus[0]);
      }

      setFilteredAssignment((prevData) =>
        data?.filter((item) => {
          return item.assignmentTitle
            ?.toLowerCase()
            ?.includes(globalSearchText.toLowerCase());
        })
      );
    } else if (selectedStatus) {
      let data = getFilteredAssignmentByStatus(selectedStatus[0]);
      setFilteredAssignment(data);
    } else {
      setFilteredAssignment(assignmentList);
    }
  }, [globalSearchText, filterStatus]);

  useEffect(() => {
    setCurrentPage("assignmentHome");

    setSelectedLesson(selectedLesson);

    const fetchAssignmentList = async () => {
      setIsLoading(true);
      try {
        let response = await axios({
          method: "get",
          url: `${url}/api/assignment/get-assingments${user.slug}&lessonId=${selectedLesson.Title}`,
          headers: {
            Authorization: "Bearer " + user.accessToken,
          },
        });

        if (response.data.results?.length) {
          let data = response.data.results.map((assignment) => {
            let teacherAttachments =
              assignment.Attachments?.filter(
                (item) => item.role === "teacher"
              ) || [];
            return {
              id: assignment.id,
              assignmentTitle: assignment.title,
              instructionStyle: {
                instructionHtmlString: assignment.instruction,
              },

              attachments: [
                {
                  type: "text",
                  info: teacherAttachments?.find((item) => item.type == "text")
                    ?.url,
                },
                {
                  type: "voice",
                  info:
                    teacherAttachments?.find((item) => item.type == "voice")
                      ?.url || "",
                },
                {
                  type: "video",
                  info:
                    teacherAttachments?.find((item) => item.type == "video")
                      ?.url || "",
                },
                {
                  type: "avatarVideo",
                  info:
                    teacherAttachments?.find(
                      (item) => item.type == "avatarVideo"
                    )?.url || "",
                },
                {
                  type: "link",
                  info: teacherAttachments?.find((item) => item.type == "link")
                    ?.url,
                },
                {
                  type: "file",
                  info: teacherAttachments
                    ?.find((item) => item.type == "file")
                    ?.url?.split(","),
                },
              ],

              assignmentFor:
                assignment.AssingmentStudents?.map(
                  (student) => student.studentId
                ) || [],
              dueDate:
                assignment.dueDate && moment(assignment.dueDate).isValid()
                  ? utcToLocalDateConvert(assignment.dueDate)
                  : null,
              publishedDate:
                assignment.pusblishedDate &&
                moment(assignment.pusblishedDate).isValid()
                  ? utcToLocalDateConvert(assignment.pusblishedDate)
                  : null,

              scheduledOn:
                assignment.scheduledOn &&
                moment(assignment.scheduledOn).isValid()
                  ? utcToLocalDateConvert(assignment.scheduledOn)
                  : null,

              markingType: Number(assignment.markingType) || 0,
              status: assignment.creationStatus,
              rubricBankId: assignment.rubricBankId,
            };
          });
          setAssignmentList([...data]);
          setFilteredAssignment([...data]);
          setSelectedLessonAssignments([...data]);
        } else {
          setAssignmentList([]);
          setFilteredAssignment([]);
          setSelectedLessonAssignments([]);
        }
        if (response.data?.status == "success") {
          setCurrentExCard(null);
          // NotificationAlert(response.data.message, "success");
        }
      } catch (error) {
        console.log("error", error);
        setAssignmentList([]);
        setFilteredAssignment([]);
        setSelectedLessonAssignments([]);
      } finally {
        setIsLoading(false);
      }
    };
    fetchAssignmentList();
  }, [selectedLesson, user.slug, user.accessToken, refreshAssignmentList]);

  const statusCount = () => {
    assignmentList && assignmentList.length > 0
      ? assignmentList.map((a) => {
          if (a.status == "Draft") {
            draftCount++;
          } else if (a.status == "Published") {
            publishedCount++;
          } else if (a.status == "Scheduled") {
            publishLaterCount++;
          }
        })
      : null;
  };
  statusCount();

  const getTextContentFromUrl = (url) => {
    if (url) {
      try {
        return fetch(url)
          .then((response) => response.text())
          .then((data) => data);
      } catch (error) {
        return "";
      }
    } else {
      return "";
    }
  };

  const getLinkContentFromUrl = (url) => {
    if (url) {
      try {
        return fetch(url)
          .then((response) => response.text())
          .then((data) => data.split(","));
      } catch (error) {
        return "";
      }
    } else {
      return "";
    }
  };

  const fetchRubricData = async (id) => {
    try {
      let response = await axios.get(
        `${url}/api/rubric/get-rubric-by-rubricId${user.slug}&rubricId=${id}`,
        {
          headers: {
            Authorization: "Bearer " + props.user.accessToken,
          },
        }
      );
      if (response.data.status == "success") {
        let rubricData = response.data.results;
        return Object.keys(rubricData).length
          ? {
              ...rubricData,
              rubricItems: rubricData.rubricItem?.map((item) => ({
                ...item,
                havePoints: !!item.havePoints,
                id: item.rubricItemId,
                levels: item.rubricCriteria?.map((criterialData) => ({
                  ...criterialData,
                  id: criterialData.criteriaId,
                  levelTitle: criterialData.criteriaTitle,
                })),
                title: item.rubricItemTitle,
              })),
            }
          : null;
      }
    } catch (error) {
      console.log("error", error);
    }
  };

  const formatCurrentAssignmentData = async (assign) => {
    try {
      setIsFormateAssignmentDataLoading(true);

      const textInfo = await getTextContentFromUrl(
        assign.attachments?.find((item) => item.type == "text")?.info
      );

      const linkInfo = await getLinkContentFromUrl(
        assign.attachments?.find((item) => item.type == "link")?.info
      );
      console.log("linkInfo", linkInfo);
      setCurrentAssignmentData({
        ...assign,
        attachments: [
          {
            type: "text",
            info: textInfo,
          },
          {
            type: "voice",
            info:
              assign.attachments?.find((item) => item.type == "voice")?.info ||
              "",
          },
          {
            type: "video",
            info:
              assign.attachments?.find((item) => item.type == "video")?.info ||
              "",
          },
          {
            type: "avatarVideo",
            info:
              assign.attachments?.find((item) => item.type == "avatarVideo")
                ?.info || "",
          },
          {
            type: "link",
            info: linkInfo,
          },
          {
            type: "file",
            info: assign.attachments?.find((item) => item.type == "file")?.info
              ? [
                  ...assign.attachments?.find((item) => item.type == "file")
                    ?.info,
                ]
              : [],
          },
        ],
        rubric: assign.rubricBankId
          ? (await fetchRubricData(assign.rubricBankId)) || null
          : null,
      });
      setCurrentExCard({ ...assign });
    } catch (error) {
      console.log(error);
    } finally {
      setIsFormateAssignmentDataLoading(false);
    }
  };

  const utcToLocalDateConvert = (utcDate) => {
    let time = moment().utc().format("HH:mm");

    // let date = moment.utc(`${utcDate} ${time}`).local().format("YYYY-MM-DD");
    let date = moment.utc(utcDate).local();
    return date;
  };

  const getSubmissionStatus = (submissionStatus) => {
    const statusTranslation = translation.assignmentStatus;
    switch (submissionStatus) {
      case "Scheduled":
        return statusTranslation.scheduled;
      case "Draft":
        return statusTranslation.draft;
      case "Published":
        return statusTranslation.published;
      default:
        return submissionStatus;
    }
  };

  const myCard = (assign) => {
    const dueDate = assign.dueDate;
    assign.dueDate;

    return (
      <Card
        className="mt-3"
        style={{ borderRadius: "10px", cursor: "pointer", maxWidth: "320px" }}
        styles={{
          ":hover": { backgroundColor: "white" },
          backgroundColor: "white",
        }}
        elevated
        onClick={() => {
          formatCurrentAssignmentData(assign ? { ...assign } : null);
        }}
      >
        <CardColumn>
          <CardHeader>
            <Text
              content={assign.assignmentTitle}
              size="large"
              weight="bold"
              style={{ marginTop: "6px", minHeight: "47.95px" }}
            />
          </CardHeader>
          <CardBody>
            <div>
              <Flex column>
                <Flex>
                  <Text
                    className="primary"
                    content={
                      assign.status === "Scheduled"
                        ? `${assignmentHomeTranslation.scheduledOn}:`
                        : `${assignmentHomeTranslation.published}:`
                    }
                    size="small"
                    style={{ marginTop: "6px" }}
                  />
                  <Text
                    content={` ${
                      assign.status === "Scheduled"
                        ? assign.scheduledOn &&
                          moment(assign.scheduledOn).isValid()
                          ? moment(assign.scheduledOn).format("yyyy-MM-DD")
                          : ""
                        : assign.status === "Published"
                        ? assign.publishedDate &&
                          moment(assign.publishedDate).isValid()
                          ? moment(assign.publishedDate).format(
                              "yyyy-MM-DD | HH:mm"
                            )
                          : ""
                        : assignmentHomeTranslation.none
                    }`}
                    size="small"
                    style={{ margin: "6px 0 0 6px" }}
                  />
                </Flex>

                <Flex>
                  <Text
                    className="primary"
                    content={`${assignmentHomeTranslation.due}:`}
                    size="small"
                    style={{ marginTop: "6px" }}
                  />
                  <Text
                    content={`  ${
                      // assign.status === "Draft"
                      //   ? assignmentHomeTranslation.none
                      assign.dueDate && moment(assign.dueDate).isValid()
                        ? moment(assign.dueDate).format("yyyy-MM-DD | HH:mm")
                        : assignmentHomeTranslation.none
                    }`}
                    size="small"
                    style={{ margin: "6px 0 0 6px" }}
                  />
                </Flex>
              </Flex>
            </div>
          </CardBody>
          <CardFooter>
            <Flex space="between">
              <Text
                className={`${
                  assign.status === "Published"
                    ? "published"
                    : `${
                        assign.status === "Publish Later" ||
                        assign.status === "Scheduled"
                          ? "publish-later"
                          : "draft"
                      }`
                }`}
                content={getSubmissionStatus(assign.status)}
                size="medium"
                style={{ marginTop: "6px" }}
              />
              {assign.status === "Published" ? (
                <div className="primary">
                  <PollIcon
                    bordered
                    size="small"
                    style={{ marginTop: "6px" }}
                  />
                </div>
              ) : (
                <Flex hAlign="end" gap="gap.smaller">
                  <EditIcon
                    size="small"
                    style={{ marginTop: "10px" }}
                    className="primary"
                  />
                  <Text style={{ marginTop: "6px" }} className="primary">
                    {assignmentHomeTranslation.edit}
                  </Text>
                </Flex>
              )}
            </Flex>
          </CardFooter>
        </CardColumn>
      </Card>
    );
  };

  return (
    <>
      <div className="assignment-home-section">
        {isLoading ? (
          <div>
            <Loader size="medium" style={{ paddingTop: "50px" }} />
          </div>
        ) : (
          <div>
            {props.state.isCreatingRubric ? (
              <CreateRubricPage
                {...props}
                isMobileView={isMobileView}
                isTabletView={isTabletView}
                state={props.state}
                setState={props.setState}
              />
            ) : isCreatingNew || currentExCard ? (
              <CreateNewAssignment
                data={currentExCard}
                setCurrentExCard={setCurrentExCard}
                showRecords={showRecords}
                {...props}
                setRefreshAssignmentList={setRefreshAssignmentList}
                refreshAssignmentList={refreshAssignmentList}
                isMobileView={isMobileView}
                isTabletView={isTabletView}
                setIsCreatingNew={setIsCreatingNew}
                setState={props.setState}
              />
            ) : (
              <>
                <CustomProgressIndicator
                  isLoading={isFormateAssignmentDataLoading}
                  style={{
                    progressTrack: {
                      backgroundColor: "#ffffff",
                    },
                  }}
                />
                <div className="tour-all-assignments all-subject-assignment mt-4">
                  <>
                    <Flex column>
                      {isMobileView || isTabletView ? (
                        <Flex space="between" wrap>
                          <Flex gap="gap.smaller" vAlign="center">
                            <IconButton
                              size={14}
                              iconProps={{ iconName: "ChromeBack" }}
                              color="#6264a7"
                              title={translation.back}
                              ariaLabel={translation.back}
                              onClick={() => {
                                if (
                                  document.querySelector(".Attendance.h-cls")
                                ) {
                                  document
                                    .querySelector(".Attendance")
                                    .classList.remove("h-cls");
                                }
                                props.history.push({
                                  pathname: "/assignments",
                                });
                                props.setSelectedLesson(null);
                              }}
                            />

                            <SubjectName weight="bold" size="larger">
                              {props.selectedLesson.ClassName
                                ? props.selectedLesson.ClassName.split(" - ")[0]
                                : ""}
                            </SubjectName>
                          </Flex>
                          {moment(moment().format("YYYY-MM-DD"))
                            // .utc()
                            .isSameOrBefore(selectedLesson.LessonDate) ? (
                            <Button
                              className="button-primary tour-create-assignment"
                              icon={<AddIcon />}
                              iconPosition="before"
                              content={
                                t("assignment").body.assignmentHome
                                  .newAssignment
                              }
                              onClick={() => {
                                // props.history.push({
                                //   pathname: "/assignments/create",
                                // });
                                setIsCreatingNew(true);
                                setCurrentAssignmentData((prevData) => ({
                                  ...newAssignmentInitialValues,
                                }));
                              }}
                              title={
                                <ButtonTitle>
                                  {
                                    t("assignment").body.assignmentHome
                                      .newAssignment
                                  }
                                </ButtonTitle>
                              }
                            />
                          ) : null}
                        </Flex>
                      ) : (
                        <Flex space="between" wrap>
                          <Flex gap="gap.small" vAlign="center">
                            <IconButton
                              iconProps={{ iconName: "ChromeBack" }}
                              title={translation.back}
                              ariaLabel={translation.back}
                              color="#6264a7"
                              onClick={() => {
                                if (
                                  document.querySelector(".Attendance.h-cls")
                                ) {
                                  document
                                    .querySelector(".Attendance")
                                    .classList.remove("h-cls");
                                }
                                props.history.push({
                                  pathname: "/assignments",
                                });
                                props.setSelectedLesson(null);
                              }}
                            />

                            <SubjectName weight="bold" size="larger">
                              {props.selectedLesson.ClassName
                                ? props.selectedLesson.ClassName.split(" - ")[0]
                                : ""}
                            </SubjectName>
                          </Flex>
                          {moment(moment().format("YYYY-MM-DD"))
                            // .utc()
                            .isSameOrBefore(selectedLesson.LessonDate) ? (
                            <Button
                              className="button-primary tour-create-assignment"
                              icon={<AddIcon />}
                              iconPosition="before"
                              content={
                                t("assignment").body.assignmentHome
                                  .newAssignment
                              }
                              onClick={() => {
                                // props.history.push({
                                //   pathname: "/assignments/create",
                                // });
                                setIsCreatingNew(true);
                                setCurrentAssignmentData((prevData) => ({
                                  ...newAssignmentInitialValues,
                                }));
                              }}
                              title={
                                <ButtonTitle>
                                  {
                                    t("assignment").body.assignmentHome
                                      .newAssignment
                                  }
                                </ButtonTitle>
                              }
                            />
                          ) : null}
                        </Flex>
                      )}

                      <StatusButtonWrapper
                        gap={isMobileView ? "gap.small" : "gap.medium"}
                        className={
                          isMobileView || isTabletView ? "m-1 mt-3" : "m-1 mt-3"
                        }
                        wrap
                      >
                        <AssignmentTypeFilterButton
                          primary={filterStatus.isDraft}
                          content={`${
                            t("assignment").body.assignmentType.draft
                          } (${draftCount})`}
                          onClick={() => {
                            // if (filterStatus.isDraft == false) {
                            setFilterStatus({
                              ...filterStatus,
                              isDraft: !filterStatus.isDraft,
                              isPublished: false,
                              isPublishLater: false,
                            });
                          }}
                          isMobileView={isMobileView}
                        />
                        <AssignmentTypeFilterButton
                          primary={filterStatus.isPublished}
                          content={`${
                            t("assignment").body.assignmentType.published
                          } (${publishedCount})`}
                          onClick={() => {
                            // if (filterStatus.isPublished == false) {
                            setFilterStatus({
                              ...filterStatus,
                              isDraft: false,
                              isPublished: !filterStatus.isPublished,
                              isPublishLater: false,
                            });
                          }}
                          isMobileView={isMobileView}
                        />
                        <AssignmentTypeFilterButton
                          primary={filterStatus.isPublishLater}
                          content={`${
                            t("assignment").body.assignmentType.publishLater
                          } (${publishLaterCount})`}
                          onClick={() => {
                            // if (filterStatus.isPublishLater == false) {
                            setFilterStatus({
                              ...filterStatus,
                              isDraft: false,
                              isPublished: false,
                              isPublishLater: !filterStatus.isPublishLater,
                            });
                          }}
                          isMobileView={isMobileView}
                        />
                      </StatusButtonWrapper>

                      {filteredAssignment && filteredAssignment.length > 0 ? (
                        <div>
                          <Flex wrap gap="gap.medium">
                            {filteredAssignment.map((assign) => {
                              return (
                                <Flex.Item key={assign.id}>
                                  {myCard(assign)}
                                </Flex.Item>
                              );
                            })}
                          </Flex>
                        </div>
                      ) : (
                        <>
                          <figure
                            className="w-100 mt-5 mb-2"
                            style={{
                              display: "flex",
                              height: "100%",
                              margin: "auto",
                              justifyContent: "center",
                            }}
                          >
                            <img
                              alt="ACV - Tangible IT"
                              src={NoAssignmentFound}
                              style={{
                                maxWidth: "50%",
                              }}
                              draggable="false"
                            />
                          </figure>
                          <Text
                            size="larger"
                            weight="semibold"
                            content={
                              t("assignment").body.assignmentHome.noAssignment
                            }
                            align="center"
                          />
                        </>
                      )}
                    </Flex>
                  </>
                </div>
              </>
            )}
          </div>
        )}
      </div>
    </>
  );
};

export default withTranslation()(AssignmentHome);
