import axios from "axios";
import { useEffect, useState } from "react";
import { toast } from "react-toastify";

const url = process.env.REACT_APP_EP_URL;

const useImportHomeStep = ({ user }) => {
  const [categories, setCategories] = useState([]);
  const [tags, setTags] = useState([]);

  const getCategories = async () => {
    try {
      const {
        data: { result },
      } = await axios.get(`${url}/api/category/all${user.slug}`);
      setCategories(Array.isArray(result) ? result : []);
    } catch (error) {
      toast.error(error?.response?.data?.message);
    }
  };

  const getTags = async () => {
    try {
      const {
        data: { result },
      } = await axios.get(`${url}/api/tag/all${user.slug}`);
      setTags(Array.isArray(result) ? result : []);
    } catch (error) {
      toast.error(error?.response?.data?.message);
    }
  };

  useEffect(() => {
    getCategories();
    getTags();
  }, []);

  return { categories, tags };
};

export default useImportHomeStep;
