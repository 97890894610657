import React from "react";

import Chart from "react-apexcharts";

class ApexChart extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      series: [],
      options: {
        chart: {
          id: "pie-chart",
          width: 380,
          type: "pie",
        },
        legend: {
          show: false,
        },
        labels: [],
        stroke: {
          show: false,
          width: 2,
          colors: ["transparent"],
        },
        responsive: [
          {
            breakpoint: 1060,
            options: {
              chart: {
                width: 280,
              },
            },
          },
          {
            breakpoint: 680,
            options: {
              chart: {
                width: 250,
              },
            },
          },
        ],
      },
    };
  }

  updateStates() {
    // console.log("Pie - Data | ",this.props.data)
    let data = this.props.data ? this.props.data : null;
    let series = [];
    let options = { ...this.state.options };
    let lables = [];

    data = data ? Object.entries(data) : [];

    data
      ? data.map((report) => {
          // console.log(report);
          lables.push(report[0]);
          series.push(report[1]);
        })
      : null;

    // console.log(series,lables)
    this.setState({ series });

    options.labels = lables;
    this.setState({ options });
  }

  componentDidMount() {
    this.updateStates();
  }

  componentWillReceiveProps() {
    this.updateStates();
  }

  render() {
    return (
      <Chart
        options={this?.state?.options ? this?.state?.options : {}}
        series={this?.state?.series ? this?.state?.series : []}
        type="pie"
        width="290"
        height="210"
      />
    );
  }
}

export default ApexChart;
