import { Calendar as AntCalendar } from "antd";
import React from "react";
import "./Calender.scss";
import { useBooking } from "../../../../context/BookingContext";

const Calendar = () => {
  const { date, dateFullCellRender, handleSelectDate, validRange } =
    useBooking();

  return (
    <div style={{ paddingTop: "15px" }}>
      <div className="calendar-wrapper">
        <div className="calender">
          <AntCalendar
            fullscreen={false}
            onSelect={handleSelectDate}
            headerRender={({ value }) => {
              return (
                <div
                  style={{
                    padding: "8px 10px",
                    fontSize: "18px",
                    fontWeight: 500,
                    textAlign: "center",
                  }}
                >
                  {value.format("MMM YYYY")}
                </div>
              );
            }}
            dateFullCellRender={dateFullCellRender}
            value={date}
            validRange={validRange}
            onChange={(value) => {
              console.log("value", value);
            }}
            onPanelChange={() => {}}
          />
        </div>
      </div>
    </div>
  );
};

export default Calendar;
