import React, { Component } from "react";

import Chart from "../Container/Charts";

import { Button } from "@fluentui/react-northstar";

import axios from "axios";

import { withTranslation } from "react-i18next";
import { reportTypes } from "./constant";

const url = process.env.REACT_APP_EP_URL;

let d = new Date();
class SpecialReport extends Component {
  constructor(props) {
    super(props);
    this.state = {
      slug: this?.props?.user?.slug,
      selectedTeacher: [],
      selectedColony: [],
      selectedInternetSpeed: [],
      LessonDate: [
        new Date(d.getFullYear(), d.getMonth() - 3, d.getDate(), 0, 0, 0, 0),
        new Date(d.getFullYear(), d.getMonth(), d.getDate(), 23, 59, 0, 0),
      ],
      allTeachersNames: null,
      allClassNames: null,
      colonyNames: null,
      colonyNames: null,
      allInternetSpeed: null,
      isEmployee: null,
      isPassing: null,
      filterBy: "Presente",
      filerts: {
        CF_1: null,
        CF_2: null,
        CF_3: null,
        CF_4: null,
        CF_5: null,
      },
      checkPhysical: null,
      loading: false,
    };
  }

  stateChangeHandler(slug) {
    this.setState({ loading: true });
    axios
      .post(
        `${url}/api/chart/get-chart-by-AttendanceTakenBy${slug}`,
        {
          TeacherName: this.state.selectedTeacher.length
            ? this.state.selectedTeacher
            : null,
          LessonDate: this.state.LessonDate,
          // colonia: this.state.selectedColony.length
          //   ? this.state.selectedColony
          //   : null,
          // internetSpeed: this.state.selectedInternetSpeed.length
          //   ? this.state.selectedInternetSpeed
          //   : null,
          // isEmployee: this.state.isEmployee,
          // isPassing: this.state.isPassing,
          ...this.state.filerts,
          ClassType: this.state.checkPhysical,
        },
        {
          headers: {
            Authorization: "Bearer " + this.props.user.accessToken,
          },
        }
      )
      .then((res) => {
        const response = res.data.result;
        const StartTimeData = res.data.startTimeData;
        const classData = res.data.classData;

        const internetSpeed = res.data.internetSpeed
          ? res.data.internetSpeed
          : null;
        this.setState({ internetSpeed, customFields: res.data.sendData });

        let classNames = response
          ? response.map((lsn) => {
              return lsn.ClassName;
            })
          : [];

        let teachersNames = res.data.result.map((teacher, index) => {
          return {
            header: teacher.TeacherData.ATBName,
            // content:teacher.Facultad,
            key: teacher.TeacherData.AttendanceTakenBy,
          };
        });

        this.setState({
          attendanceTakenBy: response.sort(
            (a, b) =>
              parseInt(b[this.state.filterBy]) -
              parseInt(a[this.state.filterBy])
          ),
          StartTimeData,
          classData,
          teachersNames,
        });

        if (this.state.allClassNames === null) {
          this.setState({ allTeachersNames: teachersNames });
        }

        if (this.state.colonyNames === null) {
          let colonyNames = res.data.coloniaData
            ? res.data.coloniaData.map((colony) => colony.StudentData.Colonia)
            : [];
          this.setState({ colonyNames });
        }
      })
      .catch((err) => console.log(err))
      .finally(() => {
        this.setState({ loading: false });
      });
  }

  componentDidMount() {
    axios
      .post(
        `${url}/api/chart/get-chart-by-classname${this.props.user.slug}`,
        {
          ClassName: null,
          LessonDate: this.state.LessonDate,
        },
        {
          headers: {
            Authorization: "Bearer " + this.props.user.accessToken,
          },
        }
      )
      .then((res) => {
        const response = res.data.result;
        let classNames = response
          ? response.map((lsn) => {
              return lsn.ClassName;
            })
          : [];

        if (this.state.allClassNames === null) {
          this.setState({ allClassNames: classNames });
        }
      })
      .catch((err) => console.log(err))
      .finally(() => {
        this.setState({
          loading: { ...this.state.loading, chart: false },
        });
      });
    this.stateChangeHandler(this.state.slug);
  }

  componentWillReceiveProps(nextProps) {
    this.stateChangeHandler(nextProps?.user?.slug);
  }

  filterByHandler(option) {
    // console.log(option);
    this.setState({ filterBy: option }, () => {
      this.setState({
        attendanceTakenBy: this.state.attendanceTakenBy.sort(
          (a, b) =>
            parseInt(b[this.state.filterBy]) - parseInt(a[this.state.filterBy])
        ),
      });
    });
  }

  render() {
    const { t } = this.props;

    Array.prototype.sum = function (prop) {
      var total = 0;
      for (var i = 0, _len = this.length; i < _len; i++) {
        total += parseInt(this[i][prop]);
      }
      return total;
    };

    let pie1data = {};
    if (this.state.attendanceTakenBy) {
      // Step 1: Sort the array by the specified property in descending order
      const sortedAttendanceTakenByTeacher = this.state.attendanceTakenBy.sort(
        (a, b) =>
          parseInt(b[this.state.filterBy]) - parseInt(a[this.state.filterBy])
      );

      // Step 2: Take the first 10 records from the sorted array
      const top10AttendanceTakenByTeacher =
        sortedAttendanceTakenByTeacher.slice(0, 10);

      // Step 3: Add these records to the pie1data object
      top10AttendanceTakenByTeacher.forEach((attendance) => {
        if (attendance?.TeacherData?.ATBName) {
          pie1data[attendance.TeacherData.ATBName.trim()] = parseInt(
            attendance[this.state.filterBy]
          );
        }
      });
    }

    let pie2data = {};
    if (this.state.StartTimeData) {
      // Step 1: Sort the array by "Total" in descending order
      const sortedData = this.state.StartTimeData.sort(
        (a, b) => b.Total - a.Total
      );

      // Step 2: Take the first 10 records from the sorted array
      const top10Data = sortedData.slice(0, 10);

      // Step 3: Add these records to the pie2data object
      top10Data.forEach((time) => {
        if (time.StartTime) {
          pie2data[time.StartTime.trim()] = time.Total;
        }
      });
    }

    return (
      // this.state.attendanceTakenBy?(
      <Chart
        mainPath={this.props.mainPath}
        showControls={true}
        isLoading={this.state.loading}
        report={reportTypes.attendanceTeacher}
        user={this.props.user}
        logout={() => this.props.logout()}
        switchDb={this.props.switchDb}
        getDb={() => this.props.getDb()}
        path={this.props.match.path}
        header={{
          report: t("chart").screens.header.reports[3],
          lable: t("chart").screens.header.lable,
        }}
        startDate={this.state.LessonDate[0]}
        endDate={this.state.LessonDate[1]}
        startChangeHandler={(selectedDate) => {
          // console.log(selectedDate)
          let LessonDate = [...this.state.LessonDate];
          LessonDate[0] = selectedDate;
          this.setState({ LessonDate }, () => {
            this.stateChangeHandler(this.state.slug);
          });
        }}
        endChangeHandler={(selectedDate) => {
          let LessonDate = [...this.state.LessonDate];
          LessonDate[1] = selectedDate;
          this.setState({ LessonDate }, () => {
            this.stateChangeHandler(this.state.slug);
          });
        }}
        dropPlaceHolder={t("chart").dropPlaceHolder}
        allClassNames={this.state.allClassNames || []}
        showClassType={true}
        checkPhysical={this.state.checkPhysical}
        setCheckPhysical={(classType) => {
          this.setState({ checkPhysical: classType }, () => {
            this.stateChangeHandler(this.state.slug);
          });
        }}
        stateChangeHandler={(selectedTeacher) => {
          this.setState({ selectedTeacher }, () => {
            this.stateChangeHandler(this.state.slug);
          });
        }}
        classAdd={(item) => {
          let selectedTeacher = [...this.state.selectedTeacher];
          selectedTeacher.push(item.key);
          this.setState({ selectedTeacher }, () => {
            this.stateChangeHandler(this.state.slug);
          });
        }}
        classRemove={(item) => {
          let selectedTeacher = [...this.state.selectedTeacher];
          selectedTeacher.splice(selectedTeacher.indexOf(item.key), 1);
          this.setState({ selectedTeacher }, () => {
            this.stateChangeHandler(this.state.slug);
          });
        }}
        customFields={this.state.customFields}
        getFilters={this.state.filerts}
        setFilters={(filerts) => {
          this.setState({ filerts }, () => {
            this.stateChangeHandler(this.state.slug);
          });
        }}
        colonyNames={this.state.colonyNames}
        colonyAdd={(item) => {
          let selectedColony = [...this.state.selectedColony];
          selectedColony.push(item);
          this.setState({ selectedColony }, () => {
            this.stateChangeHandler(this.state.slug);
          });
        }}
        colonyRemove={(item) => {
          let selectedColony = [...this.state.selectedColony];
          selectedColony.splice(selectedColony.indexOf(item), 1);
          this.setState({ selectedColony }, () => {
            this.stateChangeHandler(this.state.slug);
          });
        }}
        allInternetSpeed={this.state.allInternetSpeed}
        internetSpeedAdd={(item) => {
          let selectedInternetSpeed = [...this.state.selectedInternetSpeed];
          selectedInternetSpeed.push(item);
          this.setState({ selectedInternetSpeed }, () => {
            this.stateChangeHandler(this.state.slug);
          });
        }}
        internetSpeedRemove={(item) => {
          let selectedInternetSpeed = [...this.state.selectedInternetSpeed];
          selectedInternetSpeed.splice(selectedInternetSpeed.indexOf(item), 1);
          this.setState({ selectedInternetSpeed }, () => {
            this.stateChangeHandler(this.state.slug);
          });
        }}
        showLbl={[
          this.state.selectedTeacher.length ? false : true,
          this.state.selectedColony.length ? false : true,
          this.state.selectedInternetSpeed.length ? false : true,
        ]}
        setIsEmployee={(item) => {
          this.setState({ isEmployee: item === "All" ? null : item }, () => {
            this.stateChangeHandler(this.state.slug);
          });
        }}
        setIsPassing={(item) => {
          this.setState({ isPassing: item === "All" ? null : item }, () => {
            this.stateChangeHandler(this.state.slug);
          });
        }}
        boxData={
          this.state.attendanceTakenBy && this.state.classData
            ? {
                [t("chart").screens.teacher.boxs[0]]:
                  this.state.attendanceTakenBy.length,
                [t("chart").screens.teacher.boxs[1]]:
                  this.state.allClassNames.length,
                [t("chart").screens.teacher.boxs[2]]:
                  this.state.attendanceTakenBy.sum("Presente"),
                [t("chart").screens.teacher.boxs[3]]:
                  this.state.attendanceTakenBy.sum("Ausente"),
                [t("chart").screens.teacher.boxs[4]]:
                  this.state.attendanceTakenBy.sum("Retardo"),
                [t("chart").screens.teacher.boxs[5]]:
                  this.state.attendanceTakenBy.sum("Justificado"),
              }
            : null
        }
        pieData={{
          pie1: {
            lable: t("chart").screens.teacher.pie.pie1.lbl,
            data: pie1data,
          },
          pie2: {
            lable: t("chart").screens.teacher.pie.pie2.lbl,
            data: pie2data,
          },
        }}
        columnData={{
          radius: 10,
          title: t("chart").screens.teacher.column.title,
          lableY: t("chart").screens.teacher.column.lableY,
          lableX: t("chart").screens.teacher.column.lableX,
          series: this.state.attendanceTakenBy
            ? [
                {
                  name: [t("chart").screens.subject.column.series[0]],
                  data: [...this.state.attendanceTakenBy]
                    .slice(0, 7)
                    .map((stud) => {
                      return parseInt(stud.Presente);
                    }),
                },
                {
                  name: [t("chart").screens.subject.column.series[1]],
                  data: this.state.attendanceTakenBy.slice(0, 7).map((stud) => {
                    return stud.Ausente;
                  }),
                },
                {
                  name: [t("chart").screens.subject.column.series[2]],
                  data: this.state.attendanceTakenBy.slice(0, 7).map((stud) => {
                    return stud.Retardo;
                  }),
                },
                {
                  name: [t("chart").screens.subject.column.series[3]],
                  data: this.state.attendanceTakenBy.slice(0, 7).map((stud) => {
                    return stud.Justificado;
                  }),
                },
              ]
            : [],

          categories: this.state.attendanceTakenBy
            ? [...this.state.attendanceTakenBy].slice(0, 7).map((colony) => {
                return colony.TeacherData.ATBName;
              })
            : [],
        }}
        // 6264A7
        tableData={{
          header: {
            key: "Headers",
            items: t("chart").screens.teacher.table.header.map(
              (header, key) => {
                return {
                  content:
                    key && key === 1 ? (
                      <Button
                        text
                        style={{
                          padding: 0,
                          margin: 0,
                          minWidth: "30px",
                          color:
                            this.state.filterBy === "Presente"
                              ? "#6264A7"
                              : "black",
                        }}
                        content={header}
                        onClick={() => this.filterByHandler("Presente")}
                      />
                    ) : key === 2 ? (
                      <Button
                        text
                        style={{
                          padding: 0,
                          margin: 0,
                          minWidth: "30px",
                          color:
                            this.state.filterBy === "Ausente"
                              ? "#6264A7"
                              : "black",
                        }}
                        content={header}
                        onClick={() => this.filterByHandler("Ausente")}
                      />
                    ) : key === 3 ? (
                      <Button
                        text
                        style={{
                          padding: 0,
                          margin: 0,
                          minWidth: "30px",
                          color:
                            this.state.filterBy === "Retardo"
                              ? "#6264A7"
                              : "black",
                        }}
                        content={header}
                        onClick={() => this.filterByHandler("Retardo")}
                      />
                    ) : key === 4 ? (
                      <Button
                        text
                        style={{
                          padding: 0,
                          margin: 0,
                          minWidth: "30px",
                          color:
                            this.state.filterBy === "Justificado"
                              ? "#6264A7"
                              : "black",
                        }}
                        content={header}
                        onClick={() => this.filterByHandler("Justificado")}
                      />
                    ) : key === 5 ? (
                      <Button
                        text
                        style={{
                          padding: 0,
                          margin: 0,
                          minWidth: "30px",
                          color:
                            this.state.filterBy === "Total"
                              ? "#6264A7"
                              : "black",
                        }}
                        content={header}
                        onClick={() => this.filterByHandler("Total")}
                      />
                    ) : (
                      header
                    ),
                  key: "Header-" + header,
                };
              }
            ),
          },
          rows: this.state.attendanceTakenBy
            ? this.state.attendanceTakenBy.map((colony, key) => {
                return {
                  key: colony.TeacherData.AttendanceTakenBy + "key" + key,
                  items: [
                    {
                      content: colony.TeacherData.ATBName,
                      key:
                        colony.TeacherData.AttendanceTakenBy +
                        "AttendanceTakenBy" +
                        key,
                    },
                    {
                      content: colony.Presente,
                      key:
                        colony.TeacherData.AttendanceTakenBy + "Presente" + key,
                    },
                    {
                      content: colony.Ausente,
                      key:
                        colony.TeacherData.AttendanceTakenBy + "Ausente" + key,
                    },
                    {
                      content: colony.Retardo,
                      key:
                        colony.TeacherData.AttendanceTakenBy + "Retardo" + key,
                    },
                    {
                      content: colony.Justificado,
                      key:
                        colony.TeacherData.AttendanceTakenBy +
                        "Justificado" +
                        key,
                    },
                    {
                      content: colony.Total,
                      key: colony.TeacherData.AttendanceTakenBy + "Total" + key,
                    },
                  ],
                };
              })
            : [],
        }}
        selectedExtension={this.props.selectedExtension}
        setSelectedExtension={this.props.setSelectedExtension}
      />
      // )
      // :<Loader size="largest" style={{paddingTop:"45vh"}}/>
    );
  }
}

export default withTranslation()(SpecialReport);
