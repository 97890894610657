import React, { useEffect } from "react";
import useImportWithAI from "./useImportWithAI";

import EventMagicBar from "../../../Components/MagicBar";

const ImportWithAI = (props) => {
  const { isOpen, user, onCloseHandler, setUser } = props;
  const { handleAIGeneratedData, isSavingEvents } = useImportWithAI({
    user,
    onCloseHandler,
  });


  return (
    <div>
      <EventMagicBar
        isOpen={isOpen}
        user={user}
        setUser={setUser}
        responseHandler={(...rest) => {
          handleAIGeneratedData(...rest);
        }}
        onCloseHandler={() => {
          onCloseHandler();
        }}
        type={"BULK"}
        isSavingData={isSavingEvents}
      />
    </div>
  );
};

export default ImportWithAI;
