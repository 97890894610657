import React, { useEffect, useState } from "react";
import "./drawer.css";
import {
  Box,
  Button,
  ClickAwayListener,
  Popover,
  Tooltip,
  Typography,
} from "@mui/material";
import { makeStyles } from "@mui/styles";

function FilterDrawer(props) {
  const {
    selectArray,
    isOpen,
    removeVal,
    setFilterDataWithKeys,
    filterDataWithKeys,
    filterData,
    setFilterData,
    setFilterApply,
    fetchFilteredData,
    removeFilters,
  } = props;

  const useStyles = makeStyles((theme) => ({
    arrow: {
      "&:before": {
        border: "1px solid #E6E8ED",
      },
    },
    tooltip: {
      minWidth: 266,
      backgroundColor: "white",
      color: "black",
      padding: "10px",
      fontSize: "16px",
      borderRadius: 12,
      boxShadow:
        "rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px",
    },
  }));

  const [openDropdownIndex, setOpenDropdownIndex] = useState(-1);
  const [searchText, setSearchText] = useState("");
  const [anchorEl, setAnchorEl] = useState(null);

  const handleDropdownToggle = (index) => {
    setOpenDropdownIndex(index === openDropdownIndex ? -1 : index);
  };

  useEffect(() => {
    if (isOpen === false) {
      setFilterData([]);
    }
  }, [isOpen]);

  useEffect(() => {
    const updatedCheckedCategories = filterData.filter(
      (cat) => cat !== removeVal
    );
    setFilterData(updatedCheckedCategories);
  }, [removeVal]);

  const updateFilterDataWithKeys = (checked, val, title, id) => {
    if (filterDataWithKeys) {
      const clonedFilterDataWithKeys = JSON.parse(
        JSON.stringify(filterDataWithKeys)
      );
      if (checked) {
        if (id !== "age") {
          clonedFilterDataWithKeys[id.toLowerCase()]?.push(val);
        } else {
          if (val === "7 Days") {
            clonedFilterDataWithKeys.age = "7";
          } else if (val === "1 Months") {
            clonedFilterDataWithKeys.age = "30";
          } else if (val === "3 Months") {
            clonedFilterDataWithKeys.age = "90";
          } else if (val === "6 Months") {
            clonedFilterDataWithKeys.age = "180";
          }
        }
      } else {
        if (id !== "age") {
          clonedFilterDataWithKeys[id.toLowerCase()] = clonedFilterDataWithKeys[
            id.toLowerCase()
          ].filter((value) => value !== val);
        } else {
          clonedFilterDataWithKeys[id.toLowerCase()] = "";
        }
      }
      setFilterDataWithKeys(clonedFilterDataWithKeys);
    }
  };

  const toggle = (checked, val, title, id) => {
    updateFilterDataWithKeys(checked, val, title, id);
    if (id === "age") {
      if (checked) {
        const ageIndex = selectArray.findIndex((data) => data.id === id);
        const agesData = selectArray[ageIndex];
        const updatedFilterData = filterData.filter(
          (data) => !agesData.label.includes(data)
        );
        updatedFilterData.push(val);
        setFilterData(updatedFilterData);
      } else {
        const ageIndex = selectArray.findIndex((data) => data.id === id);
        const agesData = selectArray[ageIndex];
        const updatedFilterData = filterData.filter(
          (data) => !agesData.label.includes(data)
        );
        setFilterData(updatedFilterData);
      }
    } else {
      if (!checked) {
        const updatedCheckedCategories = filterData.filter(
          (cat) => cat !== val
        );
        setFilterData(updatedCheckedCategories);
      } else {
        const updatedCheckedCategories = [...filterData];
        updatedCheckedCategories.push(val);
        setFilterData(updatedCheckedCategories);
      }
    }
  };

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
    handleDropdownToggle(-1);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;
  let classes = useStyles();

  return (
    <div>
      <span
        className="filter"
        data-bs-toggle="offcanvas"
        data-bs-target="#offcanvasRight"
        aria-controls="offcanvasRight"
        aria-describedby={id}
        onClick={handleClick}
      >
        <div style={{ display: "flex" }}>
          <svg
            aria-hidden="true"
            focusable="false"
            role="img"
            viewBox="0 0 20 20"
            class=""
            style={{ height: "20px", width: "auto" }}
          >
            <path
              d="M17.217 0.0115967H0.78399C0.237115 0.0115967 -0.104403 0.607579 0.17015 1.08303L5.36211 9.90892V15.2795C5.36211 15.6745 5.67908 15.9937 6.07194 15.9937H11.9291C12.3219 15.9937 12.6389 15.6745 12.6389 15.2795V9.90892L17.8331 1.08303C18.1054 0.607579 17.7639 0.0115967 17.217 0.0115967ZM11.0407 14.3866H6.96033V10.9045H11.0429V14.3866H11.0407ZM11.255 9.10535L11.0429 9.47588H6.9581L6.74604 9.10535L2.3197 1.61874H15.6813L11.255 9.10535Z"
              fill="var(--icon-fill-color)"
            ></path>
          </svg>
          <span className="">Filter</span>
        </div>
      </span>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        // sx={{ borderRadius: "5px" }}

        PaperProps={{
          style: {
            marginTop: "15px",
            minWidth: "200px",
            borderRadius: 12,
            boxShadow:
              "rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px",
          },
        }}
      >
        {selectArray.map((data, index) => {
          const isOpenDropDown = index === openDropdownIndex;

          const filteredOptions = data.label.filter((o) => {
            return o.toLowerCase().includes(searchText.toLowerCase());
          });

          const options =
            searchText || filteredOptions?.length > 0
              ? filteredOptions
              : data.label;

          return (
            <Box>
              <Tooltip
                title={
                  <div
                    className={`overflow-auto collapse ${
                      isOpenDropDown ? "show" : ""
                    }`}
                    style={{ maxHeight: "493px" }}
                    id={`${data.title}`}
                  >
                    {data.search && (
                      <div className="mx-3 mt-3">
                        <input
                          type="search"
                          id="search"
                          name="search"
                          placeholder={`Search ${data.title}`}
                          className="search-box w-100 "
                          value={searchText}
                          onChange={(e) => setSearchText(e.target.value)}
                        />
                      </div>
                    )}
                    {options?.length > 0 ? (
                      options.map((label, index) => {
                        return (
                          <div
                            key={index}
                            style={{
                              display: "flex",
                              gap: "10px",
                              marginBottom: "5px",
                              paddingLeft: "6px",
                            }}
                          >
                            <input
                              type="checkbox"
                              id={`${label}`}
                              value={`${label}`}
                              checked={filterData.includes(label)}
                              onChange={(e) => {
                                toggle(
                                  e.target.checked,
                                  label,
                                  data.title,
                                  data.id
                                );
                              }}
                            />
                            <label htmlFor={`${label}`} className="ms-3">
                              {label}
                            </label>
                            <br />
                          </div>
                        );
                      })
                    ) : (
                      <div className="px-5 pt-3 pb-0">
                        <p>Not found</p>
                      </div>
                    )}
                  </div>
                }
                placement="left"
                onClose={() => {
                  handleClose();
                  setFilterApply(false);
                  setFilterData([]);
                  setFilterDataWithKeys({});
                  handleDropdownToggle(-1);
                }}
                open={openDropdownIndex === index}
                arrow
                disableFocusListener
                disableHoverListener
                disableTouchListener
                classes={{ arrow: classes.arrow, tooltip: classes.tooltip }}
              >
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    fontFamily: "Karla",
                    width: "96%",
                    margin: "4px",
                    borderRadius: "12px",
                    "&:hover": {
                      cursor: "pointer",
                      background: "#eeeeee",
                    },
                  }}
                >
                  <Typography
                    onClick={() => handleDropdownToggle(index)}
                    sx={{
                      fontFamily: "Karla",
                      padding: "3px 16px 3px 16px",
                      width: "96%",
                      margin: "4px",
                      borderRadius: "12px",
                      "&:hover": {
                        cursor: "pointer",
                        background: "#eeeeee",
                      },
                    }}
                  >
                    {data.title}
                  </Typography>
                  <label htmlFor="">
                    {data.selected === 0 ? "" : data.selected}
                  </label>
                  <svg
                    width="20"
                    height="20"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                    style={{ marginRight: "5%" }}
                  >
                    <path
                      d="M8.90961 20.67C8.71961 20.67 8.52961 20.6 8.37961 20.45C8.08961 20.16 8.08961 19.68 8.37961 19.39L14.8996 12.87C15.3796 12.39 15.3796 11.61 14.8996 11.13L8.37961 4.61002C8.08961 4.32002 8.08961 3.84002 8.37961 3.55002C8.66961 3.26002 9.14961 3.26002 9.43961 3.55002L15.9596 10.07C16.4696 10.58 16.7596 11.27 16.7596 12C16.7596 12.73 16.4796 13.42 15.9596 13.93L9.43961 20.45C9.28961 20.59 9.09961 20.67 8.90961 20.67Z"
                      fill="#292D32"
                    />
                  </svg>
                </Box>
              </Tooltip>
            </Box>
          );
        })}
        <div
          style={{
            padding: "10px",
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <Button
            style={{
              background: "#6264a7",
              color: "white",
              textTransform: "none",
              padding: "0px 28px",
              fontSize: "14px",
              height: "30px",
              fontFamily: "Karla",
            }}
            onClick={() => {
              setFilterApply(true);
              fetchFilteredData(filterDataWithKeys);
              handleClose();
            }}
          >
            Apply
          </Button>
          <Button
            style={{
              textTransform: "none",
              padding: "0px 28px",
              fontSize: "14px",
              height: "30px",
              fontFamily: "Karla",
            }}
            onClick={() => {
              handleClose();
              setFilterDataWithKeys({
                ...filterDataWithKeys,
                age: "",
                author: [],
                tags: [],
              });
              setFilterApply(false);
              setFilterData([]);

              handleDropdownToggle(-1);
              removeFilters();
            }}
          >
            Clear
          </Button>
        </div>
      </Popover>
    </div>
  );
}

export default FilterDrawer;
