import { Avatar, Flex, Text } from "@fluentui/react-northstar";
import React from "react";
import moment from "moment";
import "./ReviewCard.scss";

const ReviewCard = ({ review }) => {
  const userImage = review.userData.image
    ? review.userData.image +
      process.env.REACT_APP_AZURE_SAS_TOKEN.replace(/"/g, "")
    : "";
  return (
    <Flex column gap="gap.small" className="review-card">
      <Text
        content={moment(review.createdAt).format("MMMM D, YYYY")}
        size="medium"
        weight="bold"
        color="brand"
      />
      <Text content={review.comment} />
      <Flex gap="gap.medium">
        <Avatar image={userImage} name={review.userData.name} size="large" />

        <Flex column>
          <Text
            content={review.userData.name}
            size="large"
            weight="bold"
            color="brand"
          />
          <Text
            content={`${review.userData.role} ${
              review.userData?.fieldOfStudy
                ? `, ${review.userData?.fieldOfStudy}`
                : ""
            }`}
            size="medium"
            weight="semibold"
          />
        </Flex>
      </Flex>
    </Flex>
  );
};

export default ReviewCard;
