import React, { useCallback, useEffect, useState } from "react";
import { DatePicker } from "@fluentui/react";
import { Input, RadioGroup, Text } from "@fluentui/react-northstar";
import styled from "styled-components";
import moment from "moment";
import { Controller, useForm } from "react-hook-form";
import { withTranslation } from "react-i18next";

import Footer from "../Footer";

const TitleText = styled(Text)`
  font-weight: bold;
  font-size: 18px;
`;

const InformationText = styled(Text)`
  font-size: 16px;
`;

const PersonalInformation = ({
  currentStep,
  setCurrentStep,
  totalStep,
  data,
  setData,
  setIsEditProfile,
  user,
  t,
}) => {
  const [initialValue, setInitialValue] = useState({
    // firstName: "",
    // lastName: "",
    name: "",
    dateOfBirth: "",
    bloodGroup: "",
    gender: "",
    instituteName: "",
    linkedinProfile: "",
    instagramProfile: "",
    facebookProfile: "",
    twitterProfile: "",
  });

  const form = useForm({
    defaultValues: {
      // firstName: "",
      // lastName: "",
      name: "",
      dateOfBirth: "",
      bloodGroup: "",
      gender: "",
      instituteName: "",
      linkedinProfile: "",
      instagramProfile: "",
      facebookProfile: "",
      twitterProfile: "",
    },
    values: initialValue,
    shouldUseNativeValidation: false,
  });

  const translation = t("feedback").body.forms.personalInformation;

  const { handleSubmit, formState, reset, control } = form;

  const { errors } = formState;

  useEffect(() => {
    reset({
      // firstName: data.firstName ?? "",
      // lastName: data.lastName ?? "",
      name: data?.firstName ?? "",
      dateOfBirth: data.dateOfBirth ?? "",
      bloodGroup: data.bloodGroup ?? "",
      gender: data.gender ?? "Male",
      instituteName: data.instituteName || user.schoolInfo.School,
      linkedinProfile: data.linkedinProfile ?? "",
      instagramProfile: data.instagramProfile ?? "",
      facebookProfile: data.facebookProfile ?? "",
      twitterProfile: data.twitterProfile ?? "",
    });
  }, [data, reset]);

  const nextHandler = (values) => {
    setCurrentStep((prev) => prev + 1);
    setData((prevData) => ({ ...prevData, ...values }));
  };
  const backHandler = () => {
    setCurrentStep((prev) => 1);
    setIsEditProfile(false);
  };

  return (
    <div className="personal-information-container">
      <div className="ms-Grid">
        <div className="ms-Grid-row d-flex justify-content-center margin-device-wise">
          <div className="ms-Grid-col ms-sm12 ms-xl9">
            <form onSubmit={handleSubmit(nextHandler)}>
              <div className="ms-Grid">
                <div className="ms-Grid-row">
                  <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12">
                    <TitleText>{translation.header}</TitleText>
                  </div>
                </div>
                <div className="ms-Grid-row mt-2">
                  <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12">
                    <InformationText>{translation.note}</InformationText>
                  </div>
                </div>
                <div className="ms-Grid-row mt-3">
                  <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12">
                    <InformationText weight="semibold">
                      {translation.title}
                    </InformationText>
                  </div>
                </div>

                {/* <div className="ms-Grid-row mt-3">
                  <div className="ms-Grid-col ms-sm12 ms-md6 ms-lg6 mt-1">
                    <Controller
                      name="firstName"
                      control={control}
                      rules={{
                        required: {
                          value: true,
                          message: translation.firstNameValidation,
                        },
                      }}
                      render={({ field }) => (
                        <Input
                          {...field}
                          label={translation.firstName}
                          input={{ styles: { width: "100%" } }}
                          styles={{ width: "100%" }}
                          error={errors?.firstName?.message}
                        />
                      )}
                    />

                    <p className="error-message">
                      {errors?.firstName?.message}
                    </p>
                  </div>
                  <div className="ms-Grid-col ms-sm12 ms-md6 ms-lg6 mt-1">
                    <Controller
                      name="lastName"
                      control={control}
                      rules={{
                        required: {
                          value: true,
                          message: translation.lastNameValidation,
                        },
                      }}
                      render={({ field }) => (
                        <Input
                          {...field}
                          label={translation.lastName}
                          input={{ styles: { width: "100%" } }}
                          styles={{ width: "100%" }}
                          error={errors?.lastName?.message}
                        />
                      )}
                    />

                    <p className="error-message">{errors?.lastName?.message}</p>
                  </div>
                </div> */}
                <div className="ms-Grid-row mt-3">
                  <div className="ms-Grid-col ms-sm12 ms-md6 ms-lg6 mt-1">
                    <Controller
                      name="name"
                      control={control}
                      rules={{
                        required: {
                          value: true,
                          message: "Please enter Name",
                        },
                      }}
                      render={({ field }) => (
                        <Input
                          {...field}
                          label={"Name"}
                          input={{ styles: { width: "100%" } }}
                          styles={{ width: "100%" }}
                          error={errors?.name?.message}
                        />
                      )}
                    />

                    <p className="error-message">{errors?.name?.message}</p>
                  </div>
                </div>

                <div className="ms-Grid-row">
                  <div className="ms-Grid-col ms-sm12 ms-md6 ms-lg6 mt-1">
                    <Controller
                      name="dateOfBirth"
                      control={control}
                      rules={{
                        required: {
                          value: true,
                          message: translation.dobValidation,
                        },
                      }}
                      render={({ field }) => (
                        <DatePicker
                          {...field}
                          className="date-of-birth-datepicker"
                          value={
                            moment(field.value).isValid()
                              ? new Date(field.value)
                              : null
                          }
                          onSelectDate={(date) => {
                            if (date && moment(date).isValid()) {
                              field.onChange(moment(date).format("MM/DD/YYYY"));
                            } else {
                              field.onChange("");
                            }
                          }}
                          maxDate={new Date()}
                          borderless
                          label={<Text>{translation.dob}</Text>}
                          ariaLabel={translation.dobAriaLabel}
                          // strings={defaultDatePickerStrings}
                          textField={{
                            style: { backgroundColor: "#f3f2f1" },
                          }}
                        />
                      )}
                    />

                    <p className="error-message">
                      {errors?.dateOfBirth?.message}
                    </p>
                  </div>
                  <div className="ms-Grid-col ms-sm12 ms-md6 ms-lg6 mt-1">
                    <Controller
                      name="bloodGroup"
                      control={control}
                      rules={{
                        required: {
                          value: true,
                          message: translation.bloodGroupValidation,
                        },
                      }}
                      render={({ field }) => (
                        <Input
                          {...field}
                          label={translation.bloodGroup}
                          input={{ styles: { width: "100%" } }}
                          styles={{ width: "100%" }}
                          error={errors?.bloodGroup?.message}
                        />
                      )}
                    />
                    <p className="error-message">
                      {errors?.bloodGroup?.message}
                    </p>
                  </div>
                </div>

                <div className="ms-Grid-row">
                  <div className="ms-Grid-col ms-sm12 ms-md6 ms-lg6 mt-1">
                    <div>
                      <Text> {translation.gender}</Text>

                      <Controller
                        name="gender"
                        control={control}
                        rules={{
                          required: {
                            value: true,
                            message: translation.genderValidation,
                          },
                        }}
                        render={({ field }) => {
                          return (
                            <RadioGroup
                              name={field.name}
                              checkedValue={field.value}
                              items={[
                                {
                                  name: "gender",
                                  key: "Male",
                                  label: translation.male,
                                  value: "Male",
                                },
                                {
                                  name: "gender",
                                  key: "Female",
                                  label: translation.female,
                                  value: "Female",
                                },
                              ]}
                              onCheckedValueChange={(e, { value }) => {
                                field.onChange(value);
                              }}
                            />
                          );
                        }}
                      />
                      <p className="error-message">{errors?.gender?.message}</p>
                    </div>
                  </div>
                </div>
                <div className="ms-Grid-row">
                  <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12 mt-1">
                    <Controller
                      name="instituteName"
                      control={control}
                      rules={{
                        required: {
                          value: true,
                          message: translation.instituteValidation,
                        },
                      }}
                      render={({ field }) => (
                        <Input
                          {...field}
                          label={translation.institute}
                          input={{ styles: { width: "100%" } }}
                          styles={{ width: "100%" }}
                        />
                      )}
                    />
                    <p className="error-message">
                      {errors?.instituteName?.message}
                    </p>
                  </div>
                </div>
              </div>

              {user?.role?.toLowerCase() === "student" ||
              user?.role?.toLowerCase() === "teacher" ? (
                <div className="ms-Grid-row">
                  <div className="ms-Grid-col ms-sm12">
                    <div className="ms-Grid-row mt-3">
                      <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12">
                        <InformationText weight="semibold">
                          {translation.socialProfile}
                        </InformationText>
                      </div>
                    </div>
                    <div className="ms-Grid-row mt-2">
                      <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12">
                        <InformationText>
                          {translation.socialProfileNote}
                        </InformationText>
                      </div>
                    </div>

                    <div className="ms-Grid-row mt-3">
                      <div className="ms-Grid-col ms-sm12 ms-md6 ms-lg6 mt-1">
                        <Controller
                          name="linkedinProfile"
                          control={control}
                          render={({ field }) => (
                            <Input
                              {...field}
                              label={translation.linkedIn}
                              input={{ styles: { width: "100%" } }}
                              styles={{ width: "100%" }}
                            />
                          )}
                        />
                      </div>
                      <div className="ms-Grid-col ms-sm12 ms-md6 ms-lg6 mt-1">
                        <Controller
                          name="instagramProfile"
                          control={control}
                          render={({ field }) => (
                            <Input
                              {...field}
                              label={translation.instagram}
                              input={{ styles: { width: "100%" } }}
                              styles={{ width: "100%" }}
                            />
                          )}
                        />
                      </div>
                    </div>

                    <div className="ms-Grid-row">
                      <div className="ms-Grid-col ms-sm12 ms-md6 ms-lg6 mt-1">
                        <Controller
                          name="facebookProfile"
                          control={control}
                          render={({ field }) => (
                            <Input
                              {...field}
                              label={translation.facebook}
                              input={{ styles: { width: "100%" } }}
                              styles={{ width: "100%" }}
                            />
                          )}
                        />
                      </div>

                      <div className="ms-Grid-col ms-sm12 ms-md6 ms-lg6 mt-1">
                        <Controller
                          name="twitterProfile"
                          control={control}
                          render={({ field }) => (
                            <Input
                              {...field}
                              label={translation.twitter}
                              input={{ styles: { width: "100%" } }}
                              styles={{ width: "100%" }}
                            />
                          )}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              ) : null}

              <div className="ms-Grid-row d-flex justify-content-end mt-4">
                <Footer
                  step={currentStep}
                  totalStep={totalStep}
                  backHandler={backHandler}
                />
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default withTranslation()(PersonalInformation);
