import React, { useState } from "react";
import { DateRange } from "react-date-range";
import "react-date-range/dist/styles.css";
import "react-date-range/dist/theme/default.css";
import { useBooking } from "../../../../context/BookingContext";
import moment from "moment";
import "./ScheduleDatesCalender.scss";
import { Flex } from "@fluentui/react-northstar";

const ScheduleDatesCalender = () => {
  const {
    selectedDates,
    setSelectedDates,
    availabilityTimes,
    setAvailabilityTimes,
    getScheduledSlots,
    dateRange,
    setDateRange,
    user,
    isLoadingSlots,
  } = useBooking();

  const handleSelect = (ranges) => {
    if (isLoadingSlots) return;

    let clonedAvailabilityTimes = [...availabilityTimes];

    setDateRange([ranges.selection]);

    const start = moment(ranges.selection.startDate);
    const end = moment(ranges.selection.endDate);
    const range = [];

    for (let m = moment(start); m.diff(end, "days") <= 0; m.add(1, "days")) {
      range.push(m.format("YYYY-MM-DD"));
    }

    clonedAvailabilityTimes.forEach((availabilityTime) => {
      availabilityTime["isDisabled"] = !range.some(
        (date) =>
          moment(date).format("dddd").toLowerCase() ===
          availabilityTime.day.toLowerCase()
      );
    });
    setSelectedDates(range);
    setAvailabilityTimes(clonedAvailabilityTimes);
    getScheduledSlots(
      start.format("YYYY-MM-DD"),
      end.format("YYYY-MM-DD"),
      range,
      user.mail
    );
  };

  return (
    <Flex className="schedule-dates-calender">
      <DateRange
        onChange={handleSelect}
        moveRangeOnFirstSelection={false}
        ranges={dateRange}
        months={1}
        direction="vertical"
        showDateDisplay={false}
        minDate={
          isLoadingSlots
            ? moment().add(2, "months").toDate()
            : moment().toDate()
        }
        maxDate={moment().add(12, "months").toDate()}
        color="#6264a7"
        rangeColors={["#6264a7"]}
        className="react-calender"
      />
    </Flex>
  );
};

export default ScheduleDatesCalender;
