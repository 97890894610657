import axios from "axios";
import moment from "moment";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import { NotificationAlert } from "../../../../components/Notification/Notification";
import userSvg from "../../../../Assets/images/svg/user.svg";

const url = process.env.REACT_APP_EP_URL;

const useEditBundleEvent = ({
  editEventData,
  user,
  onSuccessFulEditHandler,
  onSuccessFulDeleteHandler,
  tags,
  categories,
}) => {
  const [isLoading, setIsLoading] = useState(false);

  const [formattedEvent, setFormattedEvent] = useState(null);
  const [teacherData, setTeacherData] = useState([]);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);

  const { t } = useTranslation();

  const commonTranslation = t("events").common;

  useEffect(() => {
    getTeachers();
  }, []);

  useEffect(() => {
    if (editEventData) {
      getEvent({ id: editEventData.id });
    }
  }, [editEventData]);

  const getTextContentFromUrl = (url) => {
    if (url) {
      try {
        return fetch(url)
          .then((response) => response.text())
          .then((data) => data);
      } catch (error) {
        return "";
      }
    } else {
      return "";
    }
  };

  const getLinkContentFromUrl = (url) => {
    if (url) {
      try {
        return fetch(url)
          .then((response) => response.text())
          .then((data) => data.split(","));
      } catch (error) {
        return "";
      }
    } else {
      return "";
    }
  };

  const formatFileLinks = (url) => {
    if (url) {
      try {
        const formattedUrls = url.split(",");

        return Array.isArray(formattedUrls) ? formattedUrls : [];
      } catch (error) {
        return "";
      }
    } else {
      return "";
    }
  };

  const formatEventData = async (eventData) => {
    try {
      const eventAttachments = eventData?.EventAttachments;

      if (Array.isArray(eventAttachments) && eventAttachments.length) {
        const textInfo = await getTextContentFromUrl(
          eventAttachments?.find((item) => item.type == "text")?.info
        );
        const linkInfo = await getLinkContentFromUrl(
          eventAttachments?.find((item) => item.type == "link")?.info
        );

        const fileInfo = formatFileLinks(
          eventAttachments?.find((item) => item.type == "file")?.info
        );

        const getContentByType = (type) =>
          eventAttachments?.find((item) => item.type == type);

        const attachments = [
          // {
          //   ...(getContentByType("text") ?? {}),
          //   type: "text",
          //   info: textInfo,
          // },
          // {
          //   ...(getContentByType("voice") ?? {}),
          //   type: "voice",
          //   info: getContentByType("voice")?.info || "",
          // },
          // {
          //   ...(getContentByType("video") ?? {}),
          //   type: "video",
          //   info: getContentByType("voice")?.info || "",
          // },
          {
            ...(getContentByType("link") ?? {}),
            type: "link",
            info: linkInfo,
          },
          {
            ...(getContentByType("file") ?? {}),
            type: "file",
            info: Array.isArray(fileInfo) && fileInfo.length ? fileInfo : [],
          },
        ];

        eventData.attachments = attachments;
      } else {
      }
    } catch (error) {
      console.log(error);
    }
    return eventData;
  };

  const getEvent = async ({ id }) => {
    setIsLoading(true);
    const teacherId = user.mail;
    await axios
      .get(`${url}/api/events/${teacherId}${user.slug}&id=${id}`)
      .then(({ data: { result } }) => formatEventData(result))
      .then((result) => {
        result.speakers =
          (!!result.speakers && JSON.parse(result.speakers)) || [];
        result.files = (!!result.files && JSON.parse(result.files)) || [];
        result.startDate =
          result.startDate && moment(result.startDate).isValid()
            ? moment
              .utc(result.startDate)
              .local()
              .format("YYYY-MM-DDTHH:mm:ssZ")
            : null;
        result.endDate =
          result.endDate && moment(result.endDate).isValid()
            ? moment.utc(result.endDate).local().format("YYYY-MM-DDTHH:mm:ssZ")
            : null;
        if (teacherData.length) {
          result = formateSpeakersDataInEvent({ event: formattedEvent, teacherData })
        }
        setFormattedEvent(result);
      })
      .catch((err) => {
        toast.error(err?.response?.data?.message);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const getTeachers = () => {
    axios
      .post(
        `${url}/api/teacher/get-teachers${user.slug}`,
        {},
        {
          headers: {
            Authorization: user.tokenType + " " + user.accessToken,
          },
        }
      )
      .then(({ data }) => {
        if (!Array.isArray(data?.result)) {
          console.error("data.result is not an array or is undefined");
          return;
        }

        const teachers = data.result.map((teacher) => {
          return {
            header: teacher.TeacherName,
            image: teacher.UrlPhoto ? teacher.UrlPhoto + user.SASToken : userSvg,
            content: teacher.Facultad,
            key: teacher.TeacherEmailID,
          };
        });

        const uniqueTeachers = teachers.filter(
          (value, index, self) =>
            index === self.findIndex((t) => t.key === value.key)
        );

        setTeacherData(uniqueTeachers);
        if (formattedEvent) {
          const event = formateSpeakersDataInEvent({ event: formattedEvent, teacherData: uniqueTeachers })
          setFormattedEvent(event)
        }
      })
      .catch((err) => {
        console.error("Error fetching teachers", err);
        toast.error(err?.response?.data?.message);
      });
  };


  const formateSpeakersDataInEvent = ({ event, teacherData }) => {
    if (!!event && !!event.speakers) {
      let selectedSpeakers = event.speakers;
      let speakers = [];
      if (
        selectedSpeakers &&
        Array.isArray(selectedSpeakers) &&
        selectedSpeakers.length
      ) {
        speakers = selectedSpeakers.map((x) => {
          return teacherData.find((y) => x == y.key);
        });
      }
      event.speakers = speakers;
      return event;
    }
  };

  const saveAttachments = async ({
    eventId,
    uploadEventAttachmentsToAzure,
  }) => {
    try {
      const attachments = await uploadEventAttachmentsToAzure(eventId);

      let headers = {
        Authorization: "Bearer " + user.accessToken,
      };

      return await axios({
        url: `${url}/api/event-attachments/update-event-attachments/${eventId}${user.slug}`,
        data: attachments,
        method: "POST",
        headers,
      });
    } catch (error) {
      NotificationAlert(
        error?.response?.data?.message ||
        error?.message ||
        commonTranslation.somethingWentWrong,
        "error"
      );
      return;
    }
  };

  const onSubmit = async (event, uploadEventAttachmentsToAzure) => {
    setIsSubmitting(true);

    const isDraftEvent = event.status === "draft";

    if (!!event.speakers) {
      event.speakers = JSON.stringify(event.speakers);
    }
    const eventData = new FormData();

    Object.keys(event).forEach((e) => {
      if (e !== "attachments") {
        if (e === "categories") {
          if (Array.isArray(event[e])) {
            for (let category of event[e]) {
              eventData.append("categories", category);
            }
          } else if (typeof event[e] === "string") {
            eventData.append("categories", event[e]);
          }
        } else if (e === "tags") {
          if (Array.isArray(event[e])) {
            for (let tag of event[e]) {
              eventData.append("tags", tag);
            }
          } else if (typeof event[e] === "string") {
            eventData.append("tags", event[e]);
          }
        } else if (e === "isMSTeamMeeting") {
          eventData.append(e, !!event["isMSTeamMeeting"]);
        } else if (e === "isGoogleMeetMeeting") {
          eventData.append(e, !!event["isGoogleMeetMeeting"]);
        } else if (["eventType", "meetingURL", "meetingId"].includes(e)) {
          let isMsMeeting = !!event.isMSTeamMeeting;
          if (!isMsMeeting) {
            eventData.append(e, "");
          } else {
            eventData.append(e, event[e] || "");
          }
        } else {
          eventData.append(e, event[e] || "");
        }
      } else {
        for (let file of event[e]) {
          if (file.url?.includes("blob.core.windows.net")) {
            eventData.append("attachments", file.url || file.originFileObj);
          } else {
            eventData.append("attachments", file.originFileObj);
          }
        }
      }
    });

    const headers = {
      Authorization: user.tokenType + " " + user.accessToken,
    };

    if (
      !!event.isGoogleMeetMeeting ||
      event.googleMeetId ||
      event.googleMeetURL
    ) {
      try {
        let tokenData = null;
        let googleMail = null;
        let googleToken = localStorage.getItem("googleToken");
        let googleMeetToken = localStorage.getItem("GoogleMeetToken");
        let token = null;

        if (googleMeetToken && JSON.parse(googleMeetToken)?.accessToken) {
          tokenData = JSON.parse(googleMeetToken);
        } else if (googleToken && JSON.parse(googleToken)?.accessToken) {
          tokenData = JSON.parse(googleToken);
        }
        if (tokenData?.accessToken && tokenData.profileObj?.email) {
          token = tokenData.accessToken;
          googleMail = tokenData.profileObj.email;

          headers.AuthorizationForMeet = "Bearer " + token;
          eventData.append("googleMail", googleMail);
        } else {
          if (!isDraftEvent) {
            eventData.set("isGoogleMeetMeeting", false);
          }
        }
      } catch (error) {}
    }

    try {
      let tokenData = null;
      let MSTeamsToken = localStorage.getItem("MSTeamsToken");
      let token = null;
      let microsoftMail = null;
      if (MSTeamsToken && JSON.parse(MSTeamsToken)?.accessToken) {
        tokenData = JSON.parse(MSTeamsToken);
      }
      if (tokenData && tokenData.account?.idTokenClaims?.email) {
        token = tokenData.accessToken;
        microsoftMail = tokenData.account.idTokenClaims.email;

        headers.AuthorizationForMSTeams = "Bearer " + token;
        eventData.append("microsoftMail", microsoftMail);
      }
    } catch (error) {}

    try {
      const attachmentResponseData = await saveAttachments({
        eventId: event.id,
        uploadEventAttachmentsToAzure,
      });

      const { data } = await axios.put(
        `${url}/api/events/${event.id}${user.slug}`,
        eventData,
        {
          headers,
        }
      );

      if (data?.status?.toLowerCase() === "success") {
        onSuccessFulEditHandler();
      }
    } catch (err) {
      toast.error(err?.response?.data?.message || err?.message);
      console.log(err.response);

      console.log(err);
    } finally {
      setIsSubmitting(true);
    }
  };

  const deleteEvent = (event) => {
    setIsDeleting(true);
    axios
      .delete(`${url}/api/events/${event.id}${user.slug}`)
      .then(({ data: { result } }) => {
        onSuccessFulDeleteHandler();
      })
      .catch((err) => {
        toast.error(err?.response?.data?.message);
      })
      .finally(() => {
        setIsDeleting(false);
      });
  };

  return {
    teacherData,
    deleteEvent,
    onSubmit,
    formattedEvent,
    isLoading,
    isDeleting,
    isSubmitting,
  };
};

export default useEditBundleEvent;
