import React, { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import { Button, Flex, Text, Loader } from "@fluentui/react-northstar";

import { BookmarkIcon } from "@fluentui/react-icons-northstar";
import { TextField } from "@fluentui/react/lib/TextField";
import axios from "axios";
import { IconButton } from "@fluentui/react";
import styled from "styled-components";
import { withTranslation } from "react-i18next";

import FeedbackResponseCard from "../shared/FeedbackResponseCard/FeedbackResponseCard";
import { ResponsiveImage } from "../../../../components/blocks/Images";
import { NotificationAlert } from "../../../../components/Notification/Notification";

import noFeedbackImage from "../../../../Assets/images/conversation.png";
import CustomProgressIndicator from "../../../../components/Loader/CustomProgressIndicator";
import Response from "./Response";

import "./ViewResponse.scss";

const QuestionTitleWrapper = styled.div`
  margin: 15px 0 5px 0;
`;

const url = process.env.REACT_APP_EP_URL;

const all = "all";
const any = "ANY";
const text = "TEXT";
const audio = "AUDIO";
const video = "VIDEO";
const poll = "POLL";

const ViewResponse = (props) => {
  const { user, isCompactMode, t } = props;

  const { feedbackId } = useParams();

  const [studentSearchText, setStudentSearchText] = useState("");
  const [feedback, setFeedback] = useState({});
  const [isBookmarkedResponseSelected, setIsBookmarkedResponseSelected] =
    useState(false);

  const [isLoading, setIsLoading] = useState(false);
  const [isUpdating, setIsUpdating] = useState({
    stopResponse: false,
    bookmark: false,
  });
  const [currentQuestion, setCurrentQuestion] = useState(null);
  const [allQuestions, setAllQuestions] = useState([]);
  const [allQuestionResponses, setAllQuestionResponses] = useState([]);

  const history = useHistory();

  const translation = t("feedbackModule");

  const commonTranslation = translation.common;
  const feedbackTranslation = translation.body.viewResponse;

  const getFeedbackResponse = async () => {
    try {
      setIsLoading(true);
      const { data } = await axios({
        url: `${url}/api/feedback/${feedbackId}${user.slug}`,
        headers: {
          Authorization: `Bearer ${user.accessToken}`,
        },
      });
      if (data?.result?.length) {
        const feedback = data.result[0];

        const {
          feedbackRequestId,
          feedbackType,
          title,
          userID,
          requestedDate,
          endDate,
          isStopAcceptingResponse,
          everyoneInOrganization,
          status,
          videoQuestionUrl,
          groupId,
          classId,
          pollOptions,
          feedbackRequestUser,
          FeedbackSubmissions,
          description,
          isAutoLogoutAfterSubmission,
          FeedbackQuestions,
          totalSubmissions,
          isMixMode,
        } = feedback;

        let formattedFeedback = {
          id: feedbackRequestId,
          title,
          dateCreated: requestedDate,
          feedbackEndsOn: endDate,
          feedbackType: feedbackType,
          status,
          pollOptions:
            Array.isArray(pollOptions) && pollOptions.length ? pollOptions : [],

          response: FeedbackSubmissions?.length
            ? FeedbackSubmissions.map(({ users, ...rest }) => ({
                ...rest,
                name: users.firstName + (users.lastName ? users.lastName : ""),
                studentEmailId: users.email,
                imageUrl: users.photo,
              }))
            : [],
          responseReceived: FeedbackSubmissions?.length
            ? FeedbackSubmissions.length
            : 0,
          responseRemaining:
            totalSubmissions >= 0
              ? totalSubmissions - (FeedbackSubmissions?.length ?? 0)
              : 0,
          totalResponse: Array.isArray(FeedbackSubmissions)
            ? FeedbackSubmissions.length
            : 0,
          sharedWith: FeedbackSubmissions,
          videoUrl: videoQuestionUrl,
          stopAcceptingResponse: isStopAcceptingResponse,
          createdBy: feedbackRequestUser.email ?? "",
          description,
          isMixMode,
          totalSubmissions,
        };
        setFeedback(formattedFeedback);
        setAllQuestionResponses(formattedFeedback.response);
        setAllQuestions(FeedbackQuestions?.length ? FeedbackQuestions : []);

        if (FeedbackQuestions?.length) {
          setCurrentQuestion({ questionNumber: 0, ...FeedbackQuestions[0] });
        }
      }
    } catch (error) {
      console.log(error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (feedbackId) getFeedbackResponse();
  }, [feedbackId]);

  const bookmarkResponseHandler = async (item) => {
    setIsUpdating((prev) => ({ ...prev, bookmark: true }));
    const payload = {
      bookmark: !item.isBookmarked,
    };
    try {
      const { data } = await axios({
        url: `${url}/api/feedback/submission/bookmark/${item.feedbackSubmissionId}${user.slug}`,
        method: "PUT",
        data: payload,
        headers: {
          Authorization: `Bearer ${user.accessToken}`,
        },
      });
      if (
        data?.result?.message === "Bookmark updated" &&
        data?.status === "Success"
      ) {
        NotificationAlert(data.result.message, "success");

        getFeedbackResponse();
      }
    } catch (error) {
    } finally {
      setIsUpdating((prev) => ({ ...prev, bookmark: false }));
    }
  };

  const stopRecievingResponseHandler = async () => {
    setIsUpdating((prev) => ({ ...prev, stopResponse: true }));
    try {
      const { data } = await axios({
        url: `${url}/api/feedback/stop-accepting-response/${feedback.id}${user.slug}`,
        method: "PUT",
        data: {
          isStopAcceptingResponse: !feedback.stopAcceptingResponse,
        },
        headers: {
          Authorization: `Bearer ${user.accessToken}`,
        },
      });
      if (data?.status?.toLowerCase() === "success") {
        NotificationAlert(
          data.result.message === "Response accepting status is updated"
            ? feedbackTranslation.feedbackStatusUpdated
            : data.result.message,
          "success"
        );
        getFeedbackResponse();
      }
    } catch (error) {
    } finally {
      setIsUpdating((prev) => ({ ...prev, stopResponse: false }));
    }
  };

  const nextQuestionHandler = () => {
    setCurrentQuestion((prev) => ({
      questionNumber: prev.questionNumber + 1,
      ...allQuestions[prev.questionNumber + 1],
    }));
  };
  const previousQuestionHandler = () => {
    setCurrentQuestion((prev) => ({
      questionNumber: prev.questionNumber - 1,
      ...allQuestions[prev.questionNumber - 1],
    }));
  };

  return (
    <>
      <CustomProgressIndicator
        isLoading={
          Object.entries(feedback).length &&
          (isLoading || Object.values(isUpdating).some((loading) => loading))
        }
        style={{
          progressTrack: {
            backgroundColor: "#ffffff",
          },
        }}
      />
      <div className="view-response-container">
        <div className="view-response">
          <div className="ms-Grid">
            <div className="back-button-wrapper">
              <Flex vAlign="center">
                <IconButton
                  iconProps={{ iconName: "ChromeBack" }}
                  title={commonTranslation.back}
                  ariaLabel={commonTranslation.back}
                  onClick={() => {
                    history.push("/feedback/all-feedback");
                  }}
                />
                <Text
                  size="large"
                  weight="semibold"
                  style={{ marginRight: "10px" }}
                >
                  {feedbackTranslation.allFeedbacks}
                </Text>
              </Flex>
            </div>
            <div className="ms-Grid-row view-response-student-section">
              {!Object.entries(feedback).length && isLoading ? (
                <Loader style={{ paddingTop: "20px" }} />
              ) : Object.entries(feedback).length ? (
                <div>
                  <div
                    className={`ms-Grid-col ms-sm12 ms-md12 ms-lg6 ms-xl4 ms-xxl3`}
                  >
                    <FeedbackResponseCard
                      isDataLoading={isLoading}
                      title={feedback.title}
                      videoUrl={feedback.videoUrl}
                      status={feedback.status}
                      endsOn={feedback.feedbackEndsOn}
                      isResponseRecieved={true}
                      responseRecieved={feedback.responseReceived}
                      isResponseRemaining={true}
                      responseRemaining={feedback.responseRemaining}
                      showStopRecievingResponse={true}
                      stopAcceptingResponse={feedback.stopAcceptingResponse}
                      stopRecievingResponseHandler={
                        stopRecievingResponseHandler
                      }
                      stopRecievingResponseLoading={isUpdating.stopResponse}
                      description={feedback.description}
                      // showExcelExport={
                      //   feedback.feedbackType === poll ||
                      //   feedback.feedbackType === text
                      // }
                      feedbackData={feedback}
                      isShowQuestionNumber={
                        !!currentQuestion &&
                        allQuestionResponses?.length > 0 &&
                        allQuestions?.length > 1
                      }
                      currentQuestion={currentQuestion?.questionNumber + 1}
                      totalQuestion={allQuestions.length}
                    />
                  </div>
                  <div
                    className={`ms-Grid-col ms-sm12 ms-md12 ms-lg6 ms-xl8 ms-xxl9 student-response-container`}
                  >
                    <div>
                      {!!currentQuestion &&
                        !!allQuestionResponses?.length &&
                        currentQuestion.questionType !== poll && (
                          <div className="student-response-header">
                            <Flex
                              fill
                              space="between"
                              wrap
                              style={{ rowGap: "10px" }}
                            >
                              <Flex.Item grow>
                                <TextField
                                  value={studentSearchText}
                                  onChange={(e, value) => {
                                    setStudentSearchText(value);
                                  }}
                                  style={{
                                    background: "#F5F5F5",
                                    borderRadius: "5px",
                                  }}
                                  borderless
                                  placeholder="Search"
                                  styles={{
                                    root: { maxWidth: "70%", minWidth: "50%" },
                                  }}
                                />
                              </Flex.Item>
                              <Flex
                                gap="gap.medium"
                                wrap
                                style={{ rowGap: "10px" }}
                              >
                                <Button
                                  content={feedbackTranslation.all}
                                  primary
                                  style={{ minWidth: "50px" }}
                                  onClick={() => {
                                    setIsBookmarkedResponseSelected(false);
                                  }}
                                />

                                <Button
                                  icon={<BookmarkIcon />}
                                  content={feedbackTranslation.bookmarked}
                                  iconPosition="before"
                                  primary
                                  style={{ backgroundColor: "#FAB500" }}
                                  onClick={() => {
                                    setIsBookmarkedResponseSelected(true);
                                  }}
                                />
                              </Flex>
                            </Flex>
                          </div>
                        )}
                      {!!currentQuestion &&
                        !!allQuestionResponses?.length &&
                        ((feedback?.feedbackType === any &&
                          feedback?.isMixMode) ||
                          [text, poll].includes(feedback?.feedbackType)) && (
                          <QuestionTitleWrapper>
                            <Text weight="semibold" size="large">
                              {currentQuestion.questionTitle ?? ""}
                            </Text>
                          </QuestionTitleWrapper>
                        )}

                      {allQuestions.length > 1 &&
                        !!allQuestionResponses?.length > 5 &&
                        !!currentQuestion && (
                          <Flex
                            hAlign="end"
                            wrap
                            className="question-navigation-btn-wrapper"
                          >
                            <Button
                              content={commonTranslation.previous}
                              primary
                              onClick={previousQuestionHandler}
                              disabled={!currentQuestion?.questionNumber}
                            />
                            <Button
                              content={commonTranslation.next}
                              primary
                              onClick={nextQuestionHandler}
                              disabled={
                                currentQuestion?.questionNumber ===
                                allQuestions.length - 1
                              }
                            />
                          </Flex>
                        )}

                      {!!currentQuestion && (
                        <Response
                          {...{
                            bookmarkResponseHandler,
                            allQuestionResponses,
                            allQuestions,
                            feedback,
                            studentSearchText,
                            setStudentSearchText,
                            currentQuestion,
                            isBookmarkedResponseSelected,
                            setIsBookmarkedResponseSelected,
                            isUpdating,
                            isLoading,
                            ...props,
                          }}
                          key={currentQuestion.feedbackQuestionId}
                        />
                      )}

                      {allQuestions.length > 1 &&
                        !!allQuestionResponses?.length &&
                        !!currentQuestion && (
                          <Flex
                            hAlign="end"
                            wrap
                            className="question-navigation-btn-wrapper"
                          >
                            <Button
                              content={commonTranslation.previous}
                              primary
                              onClick={previousQuestionHandler}
                              disabled={!currentQuestion?.questionNumber}
                            />
                            <Button
                              content={commonTranslation.next}
                              primary
                              onClick={nextQuestionHandler}
                              disabled={
                                currentQuestion?.questionNumber ===
                                allQuestions.length - 1
                              }
                            />
                          </Flex>
                        )}
                    </div>
                  </div>
                </div>
              ) : !isLoading ? (
                <Flex hAlign="center" column fill style={{ marginTop: "40px" }}>
                  <ResponsiveImage
                    src={noFeedbackImage}
                    style={{ width: "252px" }}
                  />
                  <Text size="large">{feedbackTranslation.noFeedbackData}</Text>
                </Flex>
              ) : (
                <div />
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default withTranslation()(ViewResponse);
