import React, { useEffect } from "react";
import ReactPaginate from "react-paginate";
import {
  Flex,
  Segment,
  Image,
  Text,
  Loader,
  Dialog,
  Input,
  Dropdown,
  Divider,
  Tooltip,
  Button,
  // Status
} from "@fluentui/react-northstar";

import {
  FlagIcon,
  PollIcon,
  AcceptIcon,
  CloseIcon,
  ShiftActivityIcon,
  SurveyIcon,
  SearchIcon,
  RetryIcon,
  CallVideoIcon,
  VolumeIcon,
  ItalicIcon,
  BookmarkIcon,
  PresenterIcon,
  ChevronEndIcon,
  ChevronStartIcon,
} from "@fluentui/react-icons-northstar";
import axios from "axios";
import { toast } from "react-toastify";
import { Grid } from "@mui/material";
import { useTranslation } from "react-i18next";
import InfiniteScroll from "react-infinite-scroller";

import TabMobileViewMenu from "./TabMobileViewMenu";
import StudentCard from "./StudentCard";
import ProgressChart from "../../../../components/studentProgress/ProgressChart";
import userSvg from "../../../../Assets/images/svg/user.svg";

import "./StudentData.css";

const url = process.env.REACT_APP_EP_URL;

const inputItems = [20, 50, 100];

const chartTypes = {
  attendance: "attendance",
  assignment: "assignment",
  event: "event",
  feedback: "feedback",
};

const StudentData = (props) => {
  const { t } = useTranslation();
  const [state, setState] = React.useState({
    students: [],
    pageCount: 0,
  });
  const [load, setLoad] = React.useState(true);
  const [accDlg, setAccDlg] = React.useState(false);
  const [loadedInfo, setLoadedInfo] = React.useState(null);
  const [customFieldInfo, setCustomFieldInfo] = React.useState(null);
  const [page, setPage] = React.useState(1);
  const [limit, setLimit] = React.useState(21);
  const [isScrollEnabled, setIsScrollEnabled] = React.useState(true);
  const [selectedChart, setSelectedChart] = React.useState(
    chartTypes.attendance
  );
  const [loader, setLoader] = React.useState(false);

  useEffect(() => {
    getAllStudent();
  }, []);

  const getAllStudent = (page = 1, limit = 21) => {
    axios
      .get(
        `${url}/api/admin/get-students-info${props.user.slug}&page=${page}&limit=${limit}`,
        {
          headers: {
            Authorization: "Bearer " + props.user.accessToken,
          },
        }
      )
      .then((res) => {
        setPage(page);
        const response = res.data.result.rows;
        const pageCount = Math.ceil(res.data.result.count / limit);
        setIsScrollEnabled(page < pageCount);

        setState({
          // students: [...(state?.students || []), ...response
          students: [...response],
          pageCount,
        });

        setLoad(false);
      })
      .catch((err) => {
        toast.error(err?.response?.data?.message);
        console.log(err);
        if (err.response?.status === 401) {
          setAccDlg(true);
        }
      });
  };

  const getInfo = (email) => {
    setLoader(true);
    setLoadedInfo(null);
    setCustomFieldInfo(null);
    setSelectedChart(chartTypes.attendance);
    axios
      .put(
        `${url}/api/admin/get-student-info/${email}${props.user.slug}`,
        null,
        {
          headers: {
            Authorization: "Bearer " + props.user.accessToken,
          },
        }
      )
      .then((res) => {
        const response = res.data.result;
        const { assignment, feedback, event, info, chart } = res.data;
        setLoadedInfo({
          ...response,
          Ausente: response?.Ausente ?? 0,
          Justificado: response?.Justificado ?? 0,
          Presente: response?.Presente ?? 0,
          Retardo: response?.Retardo ?? 0,
          StudentEmailId: response?.StudentEmailId ?? "",
          StudentName: response?.StudentName ?? "",
          Total: response?.Total ?? 0,

          assignment: {
            total: assignment?.total ?? 0,
            reSubmit: assignment?.Resubmit ?? 0,
            completed: assignment?.Completed ?? 0,
            pending: assignment?.Pending ?? 0,
            gradesPending: assignment?.["Grades Pending"] ?? 0,
          },
          event: {
            registered: event?.registered ?? 0,
            bookmarked: event?.bookmarked ?? 0,
            organized: event?.organized ?? 0,
            total: event?.total ?? 0,
          },
          feedback: {
            total: feedback?.total ?? 0,
            poll: feedback?.poll ?? 0,
            text: feedback?.text ?? 0,
            audio: feedback?.audio ?? 0,
            video: feedback?.video ?? 0,
            mixed: feedback?.mixed ?? 0,
            any: feedback?.any ?? 0,
          },
          info: {
            ...info,
            subjects:
              info.subjects && Array.isArray(info.subjects)
                ? info.subjects
                : [],
            classNumber: info.classNumber ?? "",
            attendanceAverage: info.attendanceAverage ?? 0,
            assignmentAverage: info.assignmentAverage ?? 0,
          },
          chart,
        });
        setCustomFieldInfo(res.data.custom_field);
        setLoader(false);
      })
      .catch((err) => {
        setLoader(false);
        toast.error(err?.response?.data?.message);
        console.log(err);

        if (err.response?.status === 401) {
          setAccDlg(true);
        }
      });
  };

  const onSearchStudentHandler = async (e) => {
    const value = e.target.value || "";
    axios
      .get(
        `${url}/api/admin/get-students-info${props.user.slug}&page=${page}&limit=${limit}&s=${value}`,
        {
          headers: {
            Authorization: "Bearer " + props.user.accessToken,
          },
        }
      )
      .then((res) => {
        const { rows, count } = res.data.result;
        const pageCount = Math.ceil(count / limit);
        setState({
          students: rows,
          pageCount,
        });
      })
      .catch((err) => {
        toast.error(err?.response?.data?.message);
        console.log(err);
        if (err.response?.status === 401) {
          setAccDlg(true);
        }
      });
  };

  const handlePageClick = (data) => {
    setPage(data.selected + 1);
    const page = data.selected + 1;
    getAllStudent(page);
  };

  const chartSelectHandler = (type) => {
    setSelectedChart(type);
  };

  const scrollButtonHandler = (isScrollToRight) => {
    document.getElementById("scroll-statictics-container").scrollBy({
      left: isScrollToRight ? 150 : -150,
      top: 0,
      behavior: "smooth",
    });
  };

  return load ? (
    <Loader size="medium" style={{ paddingTop: "50px" }} />
  ) : (
    <div className="StudentData">
      <TabMobileViewMenu
        isTabletOrMobileView={props.isTabletOrMobileView}
        onSearchStudentHandler={onSearchStudentHandler}
      />
      <Dialog
        open={accDlg}
        onConfirm={() => location.reload()}
        confirmButton="Refresh Now"
        content={
          <p className="refreshMsg">{`${t("setting").student.info[7]}`}</p>
        }
        header="Authorization expire"
      />
      {/* <div className="filter-wrapper"> */}
      {/* {state.students.length > 0 ? ( */}
      <>
        {!props.isTabletOrMobileView && (
          <>
            <Flex space="around" vAlign="center" hAlign="center" wrap>
              <Input
                icon={<SearchIcon />}
                clearable
                placeholder={t("setting").init[2]}
                onChange={onSearchStudentHandler}
              />
              <ReactPaginate
                previousLabel={t("setting").init[5]}
                nextLabel={t("setting").init[4]}
                breakLabel={"..."}
                breakClassName={"break-me"}
                pageCount={state.pageCount}
                marginPagesDisplayed={2}
                pageRangeDisplayed={5}
                onPageChange={handlePageClick}
                containerClassName={"pagination"}
                activeClassName={"active"}
              />
              <Dropdown
                items={inputItems}
                placeholder={t("setting").init[6]}
                checkable
                getA11ySelectionMessage={{
                  onAdd: (item) => {
                    setLimit(item);
                    getAllStudent(page, item);
                  },
                }}
                className="SmallSelector"
              />
            </Flex>
            <Divider />
          </>
        )}
      </>
      {/* ) : (
        <p className="btsp text-center" style={{ margin: "50px auto" }}>
          Students are not available!
        </p>
      )} */}
      {/* </div> */}

      <Flex
        className="s-studentData "
        gap="gap.small"
        vAlign="center"
        hAlign="center"
        wrap
        style={{
          overflowY: "auto",
          position: "relative",
          maxHeight: props.isTabletOrMobileView
            ? "calc(100vh - 150px)"
            : // : "calc(100vh - 320px)",
              "calc(100vh - 290px)",
        }}
      >
        {props.isTabletOrMobileView ? (
          <>
            <InfiniteScroll
              pageStart={1}
              loadMore={() => {
                getAllStudent(page + 1);
              }}
              hasMore={isScrollEnabled}
              loader={<Loader key={0} size="medium" />}
              useWindow={false}
              style={{ width: "100%" }}
            >
              <Grid
                container
                spacing={1}
                style={{ justifyContent: "center", marginTop: "0px" }}
              >
                {state.students.length > 0 ? (
                  state.students?.map((student, key) => {
                    return (
                      <StudentCard
                        studentKey={key}
                        student={student}
                        loadedInfo={loadedInfo}
                        userSvg={userSvg}
                        props={props}
                        t={t}
                        getInfo={getInfo}
                        customFieldInfo={customFieldInfo}
                        isMobileView={props.isMobileView}
                        isTabletView={props.isTabletView}
                        user={props.user}
                      />
                    );
                  })
                ) : (
                  <p
                    className="btsp text-center"
                    style={{ margin: "50px auto" }}
                  >
                    {`${t("setting").student.info[8]}`}
                  </p>
                )}
              </Grid>
            </InfiniteScroll>
            {!isScrollEnabled && (
              <p className="btsp text-center" style={{ margin: "50px auto" }}>
                {`${t("setting").student.info[9]}`}
              </p>
            )}
          </>
        ) : state.students.length > 0 ? (
          state.students?.map((student, key) => {
            const uniqueClassNumbers = [
              ...new Set(loadedInfo?.info?.classNumber || []),
            ];
            return (
              <>
                <Dialog
                  key={student.StudentEmailID + key}
                  confirmButton={t("setting").init[3]}
                  className="student-progress-dialog"
                  content={
                    <Flex gap="gap.medium" className="student-data-wrapper">
                      <Flex
                        column
                        className="student-image-info-wrapper"
                        gap="gap.medium"
                      >
                        <Image
                          src={
                            student.studPhoto.Foto
                              ? props.user.slug === "?slug=acvpreprod" ||
                                props.user.slug === "?slug=acvapp"
                                ? student.studPhoto.Foto
                                : student.studPhoto.Foto + props.user.SASToken
                              : userSvg
                          }
                          className="m-img"
                        />
                        {loadedInfo ? (
                          <Flex
                            column
                            className="student-info-wrapper"
                            gap="gap.smaller"
                          >
                            <Flex gap="gap.small" wrap>
                              <Text
                                content={`${t("setting").student.info[0]}:`}
                                className="Karla fw6 tcap"
                              />
                              <Tooltip
                                content={student.StudentName}
                                trigger={
                                  <Text
                                    content={student.StudentName}
                                    className="Karla tcap field-value"
                                  />
                                }
                              />
                            </Flex>
                            <Flex gap="gap.small" wrap>
                              <Text
                                content={`${t("setting").student.info[1]}:`}
                                className="Karla fw6 tcap"
                              />
                              <Tooltip
                                content={student.StudentEmailID}
                                trigger={
                                  <Text
                                    content={student.StudentEmailID}
                                    className="Karla tlow field-value"
                                  />
                                }
                              />
                            </Flex>
                            <Flex gap="gap.small" wrap>
                              <Text
                                content={`${t("setting").student.info[2]}:`}
                                className="Karla fw6 tcap"
                              />
                              <Text
                                content={uniqueClassNumbers?.join(", ") ?? ""}
                                className="Karla tcap"
                              />
                            </Flex>
                            <Flex gap="gap.small" wrap>
                              <Text
                                content={`${t("setting").student.info[3]}:`}
                                className="Karla fw6 tcap"
                              />
                              <Text
                                content={
                                  loadedInfo?.info?.attendanceAverage ?? ""
                                }
                                className="Karla tcap"
                              />
                            </Flex>
                            <Flex gap="gap.small" wrap>
                              <Text
                                content={`${t("setting").student.info[4]}:`}
                                className="Karla fw6 tcap"
                              />
                              <Text
                                content={
                                  loadedInfo?.info?.assignmentAverage ?? ""
                                }
                                className="Karla tcap"
                              />
                            </Flex>
                            <Flex gap="gap.small" wrap>
                              <Text
                                content={`${t("setting").student.info[5]}:`}
                                className="Karla fw6 tcap"
                              />
                              <div className="Karla tcap">
                                {Array.isArray(loadedInfo?.info?.subjects) ? (
                                  <Flex column className="subjects-wrapper">
                                    {loadedInfo.info.subjects.map((item) => (
                                      <span key={item}>{item}</span>
                                    ))}
                                  </Flex>
                                ) : (
                                  <Text
                                    content={`${t("setting").student.info[6]}`}
                                    className="Karla tcap"
                                  />
                                )}
                              </div>
                            </Flex>
                          </Flex>
                        ) : (
                          ""
                        )}

                        {loadedInfo?.StudentData ? (
                          <Flex gap="gap.large" wrap>
                            <Flex column>
                              <Divider
                                content={t("setting").student.attendance[1]}
                              />
                              {customFieldInfo?.map((cf) => {
                                return (
                                  <Text
                                    content={
                                      <>
                                        <FlagIcon outline className="pb2 pr2" />
                                        {/* <Status state={cf.IsEnable?"info":"unknown"} className="pb2 pr2"/> */}
                                        <Text
                                          content={" " + cf.Name}
                                          color="brand"
                                          className="fw6"
                                          disabled={!cf.IsEnable}
                                        />{" "}
                                        -{" "}
                                        {
                                          loadedInfo.StudentData[
                                            "CF_" + cf.CustomFieldID
                                          ]
                                        }
                                      </>
                                    }
                                    className="Karla m1 tcap"
                                    key={cf.CustomFieldID}
                                    disabled={!cf.IsEnable}
                                  />
                                );
                              })}
                            </Flex>
                          </Flex>
                        ) : null}
                      </Flex>
                      <Flex column className="info-chart-wrapper">
                        {loadedInfo ? (
                          <>
                            <Flex vAlign="center">
                              <div className="scroll-button-wrapper">
                                <Button
                                  className="scroll-button"
                                  onClick={() => {
                                    scrollButtonHandler(false);
                                  }}
                                >
                                  <ChevronStartIcon size="large" />
                                </Button>
                              </div>
                              <div
                                className="statictics-container"
                                id="scroll-statictics-container"
                              >
                                <Flex className="statictics-wrapper">
                                  <Flex column>
                                    <Divider
                                      content={
                                        t("setting")?.student?.attendance[0]
                                      }
                                      onClick={() =>
                                        chartSelectHandler(
                                          chartTypes.attendance
                                        )
                                      }
                                      className={`selectable-option ${
                                        selectedChart === chartTypes.attendance
                                          ? "active-chart"
                                          : ""
                                      }`}
                                    />
                                    <Text
                                      content={
                                        <>
                                          <PollIcon
                                            outline
                                            className="pb2 pr2"
                                          />
                                          <Text
                                            content={
                                              t("setting").student.attendance[2]
                                            }
                                            color="brand"
                                            className="fw6"
                                          />{" "}
                                          - {loadedInfo.Total}
                                        </>
                                      }
                                      className="Karla m1 tcap"
                                    />
                                    <Text
                                      content={
                                        <>
                                          <AcceptIcon
                                            outline
                                            className="pb2 pr2"
                                          />
                                          <Text
                                            content={
                                              t("setting").student.attendance[3]
                                            }
                                            color="brand"
                                            className="fw6"
                                          />{" "}
                                          - {parseInt(loadedInfo.Presente)}
                                        </>
                                      }
                                      className="Karla m1 tcap"
                                    />
                                    <Text
                                      content={
                                        <>
                                          <CloseIcon
                                            outline
                                            className="pb2 pr2"
                                          />
                                          <Text
                                            content={
                                              t("setting").student.attendance[4]
                                            }
                                            color="brand"
                                            className="fw6"
                                          />{" "}
                                          - {parseInt(loadedInfo.Ausente)}
                                        </>
                                      }
                                      className="Karla m1 tcap"
                                    />
                                    <Text
                                      content={
                                        <>
                                          <ShiftActivityIcon
                                            outline
                                            className="pb2 pr2"
                                          />
                                          <Text
                                            content={
                                              t("setting").student.attendance[5]
                                            }
                                            color="brand"
                                            className="fw6"
                                          />{" "}
                                          - {parseInt(loadedInfo.Retardo)}
                                        </>
                                      }
                                      className="Karla m1 tcap"
                                    />
                                    <Text
                                      content={
                                        <>
                                          <SurveyIcon
                                            outline
                                            className="pb2 pr2"
                                          />
                                          <Text
                                            content={
                                              t("setting").student.attendance[6]
                                            }
                                            color="brand"
                                            className="fw6"
                                          />{" "}
                                          - {parseInt(loadedInfo.Justificado)}
                                        </>
                                      }
                                      className="Karla m1 tcap"
                                    />
                                  </Flex>

                                  <Flex column>
                                    <Divider
                                      content={
                                        t("setting").student.assignment[0]
                                      }
                                      onClick={() =>
                                        chartSelectHandler(
                                          chartTypes.assignment
                                        )
                                      }
                                      className={`selectable-option ${
                                        selectedChart === chartTypes.assignment
                                          ? "active-chart"
                                          : ""
                                      }`}
                                    />
                                    <Text
                                      content={
                                        <>
                                          <PollIcon
                                            outline
                                            className="pb2 pr2"
                                          />
                                          <Text
                                            content={
                                              t("setting").student.assignment[1]
                                            }
                                            color="brand"
                                            className="fw6"
                                          />{" "}
                                          - {loadedInfo.assignment.total}
                                        </>
                                      }
                                      className="Karla m1 tcap"
                                    />
                                    <Text
                                      content={
                                        <>
                                          <AcceptIcon
                                            outline
                                            className="pb2 pr2"
                                          />
                                          <Text
                                            content={
                                              t("setting").student.assignment[2]
                                            }
                                            color="brand"
                                            className="fw6"
                                          />{" "}
                                          -{" "}
                                          {parseInt(
                                            loadedInfo.assignment.completed
                                          )}
                                        </>
                                      }
                                      className="Karla m1 tcap"
                                    />
                                    <Text
                                      content={
                                        <>
                                          <RetryIcon
                                            outline
                                            className="pb2 pr2"
                                          />
                                          <Text
                                            content={
                                              t("setting").student.assignment[3]
                                            }
                                            color="brand"
                                            className="fw6"
                                          />{" "}
                                          -{" "}
                                          {parseInt(
                                            loadedInfo.assignment.reSubmit
                                          )}
                                        </>
                                      }
                                      className="Karla m1 tcap"
                                    />
                                    <Text
                                      content={
                                        <>
                                          <ShiftActivityIcon
                                            outline
                                            className="pb2 pr2"
                                          />
                                          <Text
                                            content={
                                              t("setting").student.assignment[4]
                                            }
                                            color="brand"
                                            className="fw6"
                                          />{" "}
                                          -{" "}
                                          {parseInt(
                                            loadedInfo.assignment.pending
                                          )}
                                        </>
                                      }
                                      className="Karla m1 tcap"
                                    />
                                    <Text
                                      content={
                                        <>
                                          <SurveyIcon
                                            outline
                                            className="pb2 pr2"
                                          />
                                          <Text
                                            content={
                                              t("setting").student.assignment[5]
                                            }
                                            color="brand"
                                            className="fw6"
                                          />{" "}
                                          -{" "}
                                          {parseInt(
                                            loadedInfo.assignment.gradesPending
                                          )}
                                        </>
                                      }
                                      className="Karla m1 tcap"
                                    />
                                  </Flex>
                                  <Flex column>
                                    <Divider
                                      content={t("setting").student.event[0]}
                                      onClick={() =>
                                        chartSelectHandler(chartTypes.event)
                                      }
                                      className={`selectable-option ${
                                        selectedChart === chartTypes.event
                                          ? "active-chart"
                                          : ""
                                      }`}
                                    />
                                    <Text
                                      content={
                                        <>
                                          <AcceptIcon
                                            outline
                                            className="pb2 pr2"
                                          />
                                          <Text
                                            content={
                                              t("setting").student.event[2]
                                            }
                                            color="brand"
                                            className="fw6"
                                          />{" "}
                                          -{" "}
                                          {parseInt(
                                            loadedInfo.event.registered
                                          )}
                                        </>
                                      }
                                      className="Karla m1 tcap"
                                    />
                                    <Text
                                      content={
                                        <>
                                          <BookmarkIcon
                                            outline
                                            className="pb2 pr2"
                                          />
                                          <Text
                                            content={
                                              t("setting").student.event[3]
                                            }
                                            color="brand"
                                            className="fw6"
                                          />{" "}
                                          -{" "}
                                          {parseInt(
                                            loadedInfo.event.bookmarked
                                          )}
                                        </>
                                      }
                                      className="Karla m1 tcap"
                                    />
                                    <Text
                                      content={
                                        <>
                                          <PresenterIcon
                                            outline
                                            className="pb2 pr2"
                                          />
                                          <Text
                                            content={
                                              t("setting").student.event[4]
                                            }
                                            color="brand"
                                            className="fw6"
                                          />{" "}
                                          -{" "}
                                          {parseInt(loadedInfo.event.organized)}
                                        </>
                                      }
                                      className="Karla m1 tcap"
                                    />
                                  </Flex>

                                  <Flex column>
                                    <Divider
                                      content={t("setting").student.feedback[0]}
                                      onClick={() =>
                                        chartSelectHandler(chartTypes.feedback)
                                      }
                                      className={`selectable-option ${
                                        selectedChart === chartTypes.feedback
                                          ? "active-chart"
                                          : ""
                                      }`}
                                    />
                                    <Text
                                      content={
                                        <>
                                          <PollIcon
                                            outline
                                            className="pb2 pr2"
                                          />
                                          <Text
                                            content={
                                              t("setting").student.feedback[1]
                                            }
                                            color="brand"
                                            className="fw6"
                                          />{" "}
                                          - {loadedInfo.feedback.total}
                                        </>
                                      }
                                      className="Karla m1 tcap"
                                    />
                                    <Text
                                      content={
                                        <>
                                          <PollIcon
                                            outline
                                            className="pb2 pr2"
                                          />
                                          <Text
                                            content={
                                              t("setting").student.feedback[2]
                                            }
                                            color="brand"
                                            className="fw6"
                                          />{" "}
                                          - {parseInt(loadedInfo.feedback.poll)}
                                        </>
                                      }
                                      className="Karla m1 tcap"
                                    />
                                    <Text
                                      content={
                                        <>
                                          <ItalicIcon
                                            outline
                                            className="pb2 pr2"
                                          />
                                          <Text
                                            content={
                                              t("setting").student.feedback[3]
                                            }
                                            color="brand"
                                            className="fw6"
                                          />{" "}
                                          - {parseInt(loadedInfo.feedback.text)}
                                        </>
                                      }
                                      className="Karla m1 tcap"
                                    />
                                    <Text
                                      content={
                                        <>
                                          <VolumeIcon
                                            outline
                                            className="pb2 pr2"
                                          />
                                          <Text
                                            content={
                                              t("setting").student.feedback[4]
                                            }
                                            color="brand"
                                            className="fw6"
                                          />{" "}
                                          -{" "}
                                          {parseInt(loadedInfo.feedback.audio)}
                                        </>
                                      }
                                      className="Karla m1 tcap"
                                    />
                                    <Text
                                      content={
                                        <>
                                          <CallVideoIcon
                                            outline
                                            className="pb2 pr2"
                                          />
                                          <Text
                                            content={
                                              t("setting").student.feedback[5]
                                            }
                                            color="brand"
                                            className="fw6"
                                          />{" "}
                                          -{" "}
                                          {parseInt(loadedInfo.feedback.video)}
                                        </>
                                      }
                                      className="Karla m1 tcap"
                                    />
                                  </Flex>
                                </Flex>
                              </div>
                              <div className="scroll-button-wrapper">
                                <Button
                                  className="scroll-button"
                                  onClick={() => {
                                    scrollButtonHandler(true);
                                  }}
                                >
                                  <ChevronEndIcon size="large" />
                                </Button>
                              </div>
                            </Flex>

                            <ProgressChart
                              selectedChart={selectedChart}
                              data={loadedInfo.chart}
                              chartTypes={chartTypes}
                            />
                          </>
                        ) : (
                          loader && (
                            <Flex fill hAlign="center" vAlign="center" column>
                              <Loader size="small" />
                            </Flex>
                          )
                        )}
                      </Flex>
                    </Flex>
                  }
                  // header={student.StudentName}
                  trigger={
                    <Segment
                      key={student.StudentEmailID + "-trigger"}
                      content={
                        <Flex style={{ flexDirection: "column" }}>
                          <Image
                            src={
                              student.studPhoto.Foto
                                ? props.user.slug === "?slug=acvpreprod" ||
                                  props.user.slug === "?slug=acvapp"
                                  ? student.studPhoto.Foto
                                  : student.studPhoto.Foto + props.user.SASToken
                                : userSvg
                            }
                            className="m-img"
                          />
                          <Text
                            content={student.StudentName}
                            className="Karla fw6 m1 tcap"
                          />
                        </Flex>
                      }
                      style={{ width: "228px", height: "260px" }}
                      className="seg"
                      onClick={() => getInfo(student.StudentEmailID)}
                      title={student.StudentEmailID}
                    />
                  }
                />
                {/* )} */}
              </>
            );
          })
        ) : (
          <p className="btsp text-center" style={{ margin: "50px auto" }}>
            {`${t("setting").student.info[8]}`}
          </p>
        )}
      </Flex>
    </div>
  );
};

export default StudentData;
