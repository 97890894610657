import {
  Button,
  EditIcon,
  Flex,
  Loader,
  Tooltip,
  TrashCanIcon,
} from "@fluentui/react-northstar";
import React, { useState } from "react";
import styled from "styled-components";
import UpdateParentModel from "../UpdateParentModel";
import DeleteParentModel from "../DeleteParentModel";
import { useTranslation } from "react-i18next";
import "./ParentTable.scss";

const Card = styled(Flex)`
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
  padding: 20px;
  border-radius: 8px;
  flex-wrap: wrap;
  align-items: center;
`;

const ParentsTable = (props) => {
  const [parentDetails, setParentDetails] = useState({
    name: "",
    email: "",
    isActive: true,
    number: NaN,
    expirationDate: "",
  });
  const [parentToDelete, setParentToDelete] = useState(null);
  const [selectedStudents, setSelectedStudents] = useState([]);
  const [isOpenUpdateParent, setIsOpenUpdateParent] = useState(false);
  const [isOpenDeleteParent, setIsOpenDeleteParent] = useState(false);

  const { t } = useTranslation();
  const parentListHeaders = t("setting").parent.parentListHeaders;

  return (
    <>
      <UpdateParentModel
        parentDetails={parentDetails}
        setParentDetails={setParentDetails}
        selectedStudents={selectedStudents}
        setSelectedStudents={setSelectedStudents}
        isOpen={isOpenUpdateParent}
        studentsWithoutParents={props.studentsWithoutParents}
        setStudentsWithoutParents={props.setStudentsWithoutParents}
        getAllParents={props.getAllParents}
        user={props.user}
        onClose={() => {
          setIsOpenUpdateParent(false);
        }}
      />
      <DeleteParentModel
        isOpen={isOpenDeleteParent}
        getAllParents={props.getAllParents}
        parentToDelete={parentToDelete}
        setParentToDelete={setParentToDelete}
        studentsWithoutParents={props.studentsWithoutParents}
        setStudentsWithoutParents={props.setStudentsWithoutParents}
        user={props.user}
        onClose={() => {
          setIsOpenDeleteParent(false);
        }}
      />
      <Flex column>
        <div style={{ padding: "0 20px 20px" }}>
          <Card className="ms-Grid-row my-3">
            <div className="ms-Grid-col ms-sm12 ms-lg2">
              {parentListHeaders.parentName}
            </div>
            <div className="ms-Grid-col ms-sm12 ms-lg2">
              {parentListHeaders.parentEmail}
            </div>
            <div className="ms-Grid-col ms-sm12 ms-lg2 ">
              {parentListHeaders.parentNumber}
            </div>
            <div className="ms-Grid-col ms-sm12 ms-lg2 ">
              {parentListHeaders.status}
            </div>
            <div className="ms-Grid-col ms-sm12 ms-lg2 ">
              {parentListHeaders.studentsNames}
            </div>
            <div className="ms-Grid-col ms-sm12 ms-lg2 text-right pr-3">
              {parentListHeaders.actions}
            </div>
          </Card>
        </div>
        <div
          style={{ padding: "0 20px 20px" }}
          className="parents-lists-wrapper"
        >
          {props.isLoading ? (
            <Flex
              hAlign="center"
              vAlign="center"
              style={{ height: "150px", width: "100%" }}
            >
              <Loader />
            </Flex>
          ) : props.data.length ? (
            props.data.map((parent, i) => {
              const students = parent.students.length
                ? parent.students
                    .map((student) => student.StudentName)
                    .join(", ")
                : "-";
              return (
                <Card className="ms-Grid-row my-3">
                  <div className="ms-Grid-col ms-sm12 ms-lg2">
                    <Tooltip
                      content={parent.parentDetails.name}
                      trigger={
                        <p className="parent-info-text">
                          {parent.parentDetails.name}
                        </p>
                      }
                    />
                  </div>
                  <div className="ms-Grid-col ms-sm12 ms-lg2">
                    <Tooltip
                      content={parent.parentDetails.email}
                      trigger={
                        <p className="parent-info-text">
                          {parent.parentDetails.email}
                        </p>
                      }
                    />
                  </div>
                  <div className="ms-Grid-col ms-sm12 ms-lg2 ">
                    {parent.parentDetails.number}
                  </div>
                  <div className="ms-Grid-col ms-sm12 ms-lg2 ">
                    <Flex
                      style={{
                        width: "70px",
                        height: "25px",
                        padding: "3px 0",
                        textAlign: "center",
                        borderRadius: " 13px",
                        color: "white",
                        alignSelf: "center",
                        backgroundColor: parent.parentDetails.isActive
                          ? "#1dc344"
                          : "#ff5000",
                      }}
                      hAlign="center"
                      vAlign="center"
                    >
                      {parent.parentDetails.isActive ? "Active" : "Inactive"}
                    </Flex>
                  </div>
                  <div className="ms-Grid-col ms-sm12 ms-lg2 ">{students}</div>
                  <div className="ms-Grid-col ms-sm12 ms-lg2 text-right pr-3">
                    <Flex gap="gap.smaller">
                      <Button
                        iconOnly
                        icon={<EditIcon />}
                        onClick={() => {
                          const parentDetails = {
                            name: parent.parentDetails.name,
                            email: parent.parentDetails.email,
                            isActive: parent.parentDetails.isActive,
                            number: parent.parentDetails.number,
                            expirationDate: parent.parentDetails.expirationDate,
                          };
                          const selectedStudents = parent?.students?.length
                            ? parent.students.map((student) => {
                                return {
                                  key: student.StudentEmailID,
                                  header: student.StudentName,
                                };
                              })
                            : [];
                          setSelectedStudents(selectedStudents);
                          setParentDetails(parentDetails);
                          setIsOpenUpdateParent(true);
                        }}
                      />
                      <Button
                        iconOnly
                        icon={<TrashCanIcon />}
                        onClick={() => {
                          console.log("parent", parent);
                          setParentToDelete(parent);
                          setIsOpenDeleteParent(true);
                        }}
                      />
                    </Flex>
                  </div>
                </Card>
              );
            })
          ) : (
            <Flex
              hAlign="center"
              vAlign="center"
              style={{ height: "150px", width: "100%" }}
            >
              No Results
            </Flex>
          )}
        </div>
      </Flex>
    </>
  );
};

export default ParentsTable;
