import React, { useContext, useState } from "react";

const Assignment = React.createContext();

export const useAssignment = () => {
  return useContext(Assignment);
};

const newAssignmentInitialValues = {
  id: null,
  assignmentTitle: "",
  instruction: "",
  instructionStyle: {},
  attachments: [
    {
      type: "text",
      info: "",
    },
    {
      type: "voice",
      info: "",
    },
    {
      type: "video",
      info: "",
    },
    {
      type: "link",
      info: [],
    },
    {
      type: "file",
      info: [],
    },
  ],
  assignmentFor: [],
  dueDate: null,
  publishedDate: null,
  scheduledOn: null,
  markingType: 0,
  status: "",
  rubric: {},
  isNew: true,
};

export const AssignmentContext = ({ children }) => {
  const [currentAssignmentData, setCurrentAssignmentData] = useState({
    id: null,
    assignmentTitle: "",
    instruction: "",
    instructionStyle: {},
    attachments: [
      {
        type: "text",
        info: "",
      },
      {
        type: "voice",
        info: "",
      },
      {
        type: "video",
        info: "",
      },
      {
        type: "avatarVideo",
        info: "",
      },
      {
        type: "link",
        info: [],
      },
      {
        type: "file",
        info: [],
      },
    ],
    assignmentFor: [],
    dueDate: null,
    publishedDate: null,
    scheduledOn: null,
    markingType: "",
    status: "",
    rubric: {},
  });

  const [selectedGrades, setSelectedGrades] = useState([]);

  const [currentLessionStudentList, setCurrentLessionStudentList] = useState(
    []
  );
  const [otherData, setOtherData] = useState({});
  const [isSubmissionsPage, setIsSubmissionsPage] = useState(false);
  const [isGradingPage, setIsGradingPage] = useState(false);
  const [globalSearchText, setGlobalSearchText] = useState("");
  const [currentPage, setCurrentPage] = useState("");
  const [selectedLesson, setSelectedLesson] = useState(null);
  const [selectedLessonAssignments, setSelectedLessonAssignments] = useState(
    []
  );
  const [isRubricHasPoints, setIsRubricHasPoints] = useState(false);
  return (
    <Assignment.Provider
      value={{
        currentAssignmentData,
        setCurrentAssignmentData,
        selectedGrades,
        setSelectedGrades,
        newAssignmentInitialValues,
        currentLessionStudentList,
        setCurrentLessionStudentList,
        setOtherData,
        otherData,
        isSubmissionsPage,
        setIsSubmissionsPage,
        isGradingPage,
        setIsGradingPage,
        globalSearchText,
        setGlobalSearchText,
        currentPage,
        setCurrentPage,
        selectedLesson,
        setSelectedLesson,
        selectedLessonAssignments,
        setSelectedLessonAssignments,
        setIsRubricHasPoints,
        isRubricHasPoints,
      }}
    >
      {children}
    </Assignment.Provider>
  );
};
