import React, { useState } from "react";
import {
  Button,
  Portal,
  CloseIcon,
  Checkbox,
  Pill,
} from "@fluentui/react-northstar";
import { Collapse } from "antd";
import { withTranslation } from "react-i18next";
import { Panel } from "@fluentui/react";
import { DesktopDatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { TextField } from "@mui/material";
import { TextField as FluentUITextField } from "@fluentui/react";

import { Flex } from "@fluentui/react-northstar";
import { useEvent } from "../../context/EventContext";

const EventFilterModal = (props) => {
  const {
    open,
    isAudio,
    isVideoConf,
    isInplace,
    categories,
    tags,
    onHandlePortal,
    onHandleSelection,
    onResetFilter,
    onApplyFilter,
    userFilters,
    selectedUserFilter,
    t,
  } = props;

  const [categorySearch, setCategorySearch] = useState("");
  const [tagSearch, setTagSearch] = useState("");

  const filterModalProps = useEvent();
  const { startDate, endDate, changeStartDate, changeEndDate } =
    filterModalProps;

  const translation = t("events");
  const filterTranslation = translation.body.userFilter;
  const controlsTranslation = translation.controls;

  const dismissPanel = () => {
    onHandlePortal(false);
  };

  return (
    <Panel
      isOpen={open}
      onDismiss={(e) => {
        if (e?.currentTarget?.tagName?.toLowerCase() === "button") {
          dismissPanel();
        }
      }}
      closeButtonAriaLabel="Close"
    >
      <div>
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <p>{filterTranslation.filters}</p>
        </div>
        <Collapse>
          <Collapse.Panel header={filterTranslation.category} key="1">
            <Flex column gap="gap.small">
              <FluentUITextField
                value={categorySearch}
                onChange={(e, value) => {
                  setCategorySearch(value);
                }}
                placeholder={filterTranslation.searchCategory}
                style={{ background: "#F5F5F5", borderRadius: "5px" }}
                borderless={true}
              />

              <Flex wrap>
                {(categorySearch?.length
                  ? categories.filter((c) =>
                      c.title
                        ?.toLowerCase()
                        ?.includes(categorySearch?.toLowerCase())
                    )
                  : categories
                ).map((c) => (
                  <Pill
                    key={c.id}
                    selected={c.checked}
                    selectable
                    onClick={(e) => {
                      e.checked = !c.checked;
                      onHandleSelection(e, c.id, "categories");
                    }}
                    style={{
                      minWidth: "initial",
                      backgroundColor: c.checked && "#6264A7",
                      color: c.checked ? "#fff" : "#000",
                    }}
                    size="small"
                  >
                    {c.title}
                  </Pill>
                ))}
              </Flex>
            </Flex>
          </Collapse.Panel>
          <Collapse.Panel header={filterTranslation.type} key="2">
            <Flex wrap>
              <Checkbox
                label={filterTranslation.audio}
                onChange={(e, val) => onHandleSelection(val, "", "audio")}
                checked={isAudio}
              />
              <Checkbox
                label={filterTranslation.videoConference}
                onChange={(e, val) => onHandleSelection(val, "", "videoConf")}
                checked={isVideoConf}
              />
              <Checkbox
                label={filterTranslation.inPlace}
                onChange={(e, val) => onHandleSelection(val, "", "inPlace")}
                checked={isInplace}
              />
            </Flex>
          </Collapse.Panel>

          <Collapse.Panel header={filterTranslation.tags} key="3">
            <Flex column gap="gap.small">
              <FluentUITextField
                value={tagSearch}
                onChange={(e, value) => {
                  setTagSearch(value);
                }}
                placeholder={filterTranslation.searchTag}
                style={{ background: "#F5F5F5", borderRadius: "5px" }}
                borderless={true}
              />

              <Flex wrap>
                {(tagSearch?.length
                  ? tags.filter((c) =>
                      c.title?.toLowerCase()?.includes(tagSearch?.toLowerCase())
                    )
                  : tags
                ).map((c) => (
                  <Pill
                    key={c.id}
                    selected={c.checked}
                    selectable
                    onClick={(e) => {
                      e.checked = !c.checked;
                      onHandleSelection(e, c.id, "tags");
                    }}
                    style={{
                      minWidth: "initial",
                      backgroundColor: c.checked && "#6264A7",
                      color: c.checked ? "#fff" : "#000",
                    }}
                    size="small"
                  >
                    {c.title}
                  </Pill>
                ))}
              </Flex>
            </Flex>
          </Collapse.Panel>
          <Collapse.Panel header={filterTranslation.specificDates} key="4">
            <Flex column gap="gap.medium">
              <Flex column>
                <span style={{ marginTop: "5px" }}>
                  {controlsTranslation.from}
                </span>

                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DesktopDatePicker
                    className="date-picker-attendance"
                    inputFormat="MMMM D, YYYY"
                    value={startDate}
                    onChange={(value) => {
                      let date = new Date(value);
                      changeStartDate(date);
                    }}
                    slotProps={{
                      field: { clearable: true },
                    }}
                    renderInput={(params) => {
                      params.inputProps.placeholder =
                        filterTranslation.selectStartDate;
                      return (
                        <TextField
                          {...params}
                          sx={{
                            "& fieldset": { border: "none" },
                          }}
                        />
                      );
                    }}
                    PopperProps={{
                      sx: {
                        zIndex: 9999999,
                      },
                    }}
                  />
                </LocalizationProvider>
              </Flex>

              <Flex column>
                <span
                  style={{
                    marginTop: "5px",
                    marginLeft: !endDate ? "0px" : "32px",
                  }}
                >
                  {controlsTranslation.to}
                </span>

                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DesktopDatePicker
                    className="date-picker-attendance"
                    inputFormat="MMMM D, YYYY"
                    value={endDate}
                    onChange={(value) => {
                      let date = new Date(value);
                      changeEndDate(date);
                    }}
                    renderInput={(params) => {
                      params.inputProps.placeholder =
                        filterTranslation.selectEndDate;
                      return (
                        <TextField
                          {...params}
                          sx={{
                            "& fieldset": { border: "none" },
                          }}
                        />
                      );
                    }}
                    PopperProps={{
                      sx: {
                        zIndex: 9999999,
                      },
                    }}
                  />
                </LocalizationProvider>
              </Flex>
            </Flex>
          </Collapse.Panel>
          {userFilters?.length ? (
            <Collapse.Panel header={filterTranslation.savedFilter} key="5">
              <div style={{ display: "flex", flexDirection: "column" }}>
                {userFilters.map((item) => (
                  <Checkbox
                    key={item.id}
                    checked={item.id === selectedUserFilter}
                    label={item.name}
                    onChange={(e, val) => {
                      onHandleSelection(val, item.id, "userFilter");
                    }}
                  />
                ))}
              </div>
            </Collapse.Panel>
          ) : null}
        </Collapse>
        <div
          style={{
            border: 0,
            display: "flex",
            justifyContent: "space-between",
            marginTop: 40,
          }}
        >
          <Button
            content={filterTranslation.clearAll}
            text
            onClick={() => onResetFilter()}
          />
          <Button
            content={filterTranslation.apply}
            onClick={() => onApplyFilter()}
          />
        </div>
      </div>
    </Panel>
  );
};

export default withTranslation()(EventFilterModal);
