import { CloseIcon } from "@fluentui/react-icons-northstar";
import React from "react";

import { Dialog } from "@fluentui/react-northstar";

const ConfirmDialog = (props) => {
  return (
    <div>
      <Dialog
        open={props.isConfirmDelete}
        onOpen={() => props.setisConfirmDelete(true)}
        onCancel={() => props.onCancel(false)}
        onConfirm={() => props.onConfirm(true)}
        confirmButton={props.confirmButton}
        cancelButton={props.cancelButton}
        content={props.content}
        header={props.header}
        headerAction={{
          icon: <CloseIcon />,
          title: "Close",
          onClick: () => {
            props.setisConfirmDelete(false);
          },
        }}
        trigger={props.button}
      />
    </div>
  );
};

export default ConfirmDialog;
