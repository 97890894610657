import { useEffect, useState } from "react";
import {
  CloseIcon,
  Dialog,
  Flex,
  Input,
  Pill,
  SearchIcon,
} from "@fluentui/react-northstar";

const SelectorModal = ({
  children,
  items,
  selectedItems,
  header,
  confirmButton,
  clearButton,
  onSubmit,
  isLoading,
}) => {
  const [open, setOpen] = useState(false);
  const [search, setSearch] = useState("");
  const [changedItems, setChangedItems] = useState([]);

  useEffect(() => {
    setChangedItems(selectedItems ?? []);
  }, [items, selectedItems, open]);

  const onChangeHandler = ({ mode, value }) => {
    if (mode === "change") {
      const items = selectedItems.filter((item) => item !== value);
      onSubmit(items);
    } else {
      setChangedItems((prevData) =>
        prevData.includes(value)
          ? prevData.filter((item) => item !== value)
          : [...prevData, value]
      );
    }
  };

  const clearButtonHandler = () => {
    setChangedItems([]);
  };

  const applyButtonHandler = () => {
    onSubmit(changedItems);
    setOpen(false);
    setChangedItems([]);
  };

  const filteredItems = !!search.trim()
    ? items.filter((item) =>
        item.title.toLowerCase().includes(search.toLowerCase())
      )
    : items;

  return (
    <div style={{ marginBottom: "10px" }}>
      {children({ open, setOpen })}
      <Dialog
        cancelButton={clearButton}
        confirmButton={confirmButton}
        open={open}
        onCancel={clearButtonHandler}
        onConfirm={applyButtonHandler}
        closeOnOutsideClick={false}
        content={
          <div>
            <div>
              <Input
                style={{ margin: "10px 0" }}
                icon={<SearchIcon />}
                onChange={(e, { value }) => {
                  setSearch(value);
                }}
                value={search}
                clearable
                placeholder="Search..."
              />
            </div>
            {filteredItems.length ? (
              <Flex wrap>
                {filteredItems.map((item) => {
                  let isSelected = changedItems.includes(item.id);
                  return (
                    <Pill
                      key={item.id}
                      selectable
                      selected={isSelected}
                      onClick={() =>
                        onChangeHandler({ mode: "update", value: item.id })
                      }
                      style={{
                        minWidth: "initial",
                        backgroundColor: isSelected && "#6264A7",
                        color: isSelected ? "#fff" : "#000",
                      }}
                    >
                      {item.title}
                    </Pill>
                  );
                })}
              </Flex>
            ) : (
              <Flex vAlign="center" hAlign="center">
                No Results
              </Flex>
            )}
          </div>
        }
        header={header}
        headerAction={{
          icon: <CloseIcon />,
          title: "Close",
          onClick: () => {
            setOpen(false);
            onSubmit(selectedItems);
            setChangedItems([]);
          },
        }}
      />
      <div>
        <Flex wrap>
          {items
            .filter((item) => selectedItems.includes(item.id))
            .map((item) => {
              return (
                <Pill
                  disabled={isLoading}
                  actionable
                  onDismiss={() =>
                    onChangeHandler({ mode: "change", value: item.id })
                  }
                  style={{
                    minWidth: "initial",
                    backgroundColor: "#6264A7",
                    color: "#fff",
                  }}
                >
                  {item.title}
                </Pill>
              );
            })}
        </Flex>
      </div>
    </div>
  );
};

export default SelectorModal;
