import React, { useState } from "react";
import { Tab } from "./Tab";

export const Tabs = ({ children, RBAC_OPTIONS }) => {
  const updatedChildren = children
    ? children.length
      ? children
      : [children]
    : [];

  const [activeTab, setActiveTab] = useState(RBAC_OPTIONS.dashboard);

  const onClickTabItem = (tab) => {
    setActiveTab(tab);
  };

  return (
    <div className="tabs setting-rbac-container">
      <ol className="tab-list">
        {updatedChildren.map((child) => {
          const { option, label } = child.props;

          return (
            <Tab
              activeTab={activeTab}
              key={option}
              option={option}
              label={label}
              onClick={onClickTabItem}
            />
          );
        })}
      </ol>
      <div className="tab-content">
        {updatedChildren.map((child) => {
          if (child.props.option !== activeTab) return undefined;
          return child.props.children;
        })}
      </div>
    </div>
  );
};

export default Tabs;
