import React from "react";

export const Tab = ({ activeTab, option, label, onClick }) => {
  const onTabClick = () => {
    onClick(option);
  };
  let className = "tab-list-item";
  if (activeTab === option) {
    className += " tab-list-active";
  }
  return (
    <li className={className} onClick={onTabClick}>
      {label?.length ? label[0].toUpperCase() + label.slice(1) : ""}
    </li>
  );
};
