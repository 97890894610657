import React, { useEffect, useState } from "react";
import { Tab } from "./Tab";

export const Tabs = ({ children, currentTab }) => {
  const [activeTab, setActiveTab] = useState(currentTab);
  const [isTabletOrMobileView, setIsTabletOrMobileView] = useState(
    window.innerWidth <= 991
  );

  useEffect(() => {
    const handleResize = () => {
      setIsTabletOrMobileView(window.innerWidth <= 991);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const onClickTabItem = (tab) => {
    setActiveTab(tab);
  };

  return (
    <div
      className="tabs"
      // style={{ margin: isTabletOrMobileView ? "0px" : "40px" }}
    >
      <ol className="tab-list">
        {children.map((child) => {
          const { label, className = "" } = child.props;

          return (
            <Tab
              activeTab={activeTab}
              key={label}
              label={label}
              onClick={onClickTabItem}
              classes={className}
            />
          );
        })}
      </ol>
      <div className="tab-content">
        {children.map((child) => {
          if (child.props.label !== activeTab) return undefined;
          return child.props.children;
        })}
      </div>
    </div>
  );
};

export default Tabs;
