import React, { useContext, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import moment from "moment";

const Student = React.createContext();

export const useStudentSubmission = () => {
  return useContext(Student);
};

// const startDate = new Date(moment().startOf("isoweek"));
const startDate = new Date(moment().subtract(30, "days"));
// const endDate = new Date(moment().subtract(30, "days"));
// const endDate = new Date(moment().endOf("isoweek"));
const endDate = new Date(moment(new Date()).format("MM/DD/YYYY"));

const StudentSubmissionContext = ({ children }) => {
  const { location } = useHistory();
  const [studentAttachmentsForAssignment, setStudentAttachmentsForAssignment] =
    useState([
      {
        id: 1,
        type: "text",
        info: "",
      },
      {
        id: 2,
        type: "voice",
        info: "",
      },
      {
        id: 3,
        type: "video",
        info: "",
      },
      {
        id: 4,
        type: "link",
        info: [],
      },
      {
        id: 5,
        type: "file",
        info: [],
      },
    ]);
  const [currentAssignmentCode, setCurrentAssignmentCode] = useState(null);
  const [currentAssignmentData, setCurrentAssignmentData] = useState({});
  const [currentSubjectData, setCurrentSubjectData] = useState({});
  const [currentPage, setCurrentPage] = useState("");
  const [subjectListForDropdown, setSubjectListForDropdown] = useState([]);
  const [teacherList, setTeacherList] = useState([]);
  const [selectedSubject, setSelectedSubject] = useState(null);
  const [selectedTeacher, setSelectedTeacher] = useState(null);
  const [globalSearch, setGlobalSearch] = useState(null);
  const [dateFilter, setDateFilter] = useState({
    start: startDate,
    end: endDate,
  });
  const [isFilteredByCreationDate, setIsFilteredByCreationDate] =
    useState(false);

  const defaultDateHandler = () => {
    setDateFilter({
      start: null,
      end: null,
    });
  };

  return (
    <Student.Provider
      value={{
        studentAttachmentsForAssignment,
        setStudentAttachmentsForAssignment,
        setCurrentAssignmentCode,
        currentAssignmentData,
        setCurrentAssignmentData,
        currentSubjectData,
        setCurrentSubjectData,
        currentPage,
        setCurrentPage,
        subjectListForDropdown,
        setSubjectListForDropdown,
        teacherList,
        setTeacherList,
        selectedSubject,
        setSelectedSubject,
        selectedTeacher,
        setSelectedTeacher,
        globalSearch,
        setGlobalSearch,
        dateFilter,
        setDateFilter,
        defaultDateHandler,
        isFilteredByCreationDate,
        setIsFilteredByCreationDate,
      }}
    >
      {children}
    </Student.Provider>
  );
};

export default StudentSubmissionContext;
