import React, { useState } from "react";
import axios from "axios";
import {
  Button,
  Checkbox,
  CloseIcon,
  Dialog,
  Flex,
  Input,
  Text,
} from "@fluentui/react-northstar";
import { withTranslation } from "react-i18next";
import { toast } from "react-toastify";

const END_POINT = process.env.REACT_APP_EP_URL;

const AddRBAC = ({ option: rbacOption, user, setRefreshData, t }) => {
  const [isOpen, setIsOpen] = useState(false);
  // const [isValidate, setIsValidate] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [newRBACData, setNewRBACData] = useState({
    section: "",
    area: "",
    option: "",
    description: "",
    title: "",
    admin: true,
    teacher: true,
    student: true,
  });

  const translation = t("setting").RBAC;
  const commonTranslation = translation.common;
  const dashboardTranslation = translation.dashboard;

  const saveRBACDataHandler = async () => {
    try {
      setIsLoading(true);
      const slug = user.slug;
      const token = user.accessToken;
      const payload = {
        ...newRBACData,
        section: rbacOption,
        area: newRBACData.area?.length ? newRBACData.area.toLowerCase() : "",
        option: newRBACData.option?.length
          ? newRBACData.option.toLowerCase().split(" ").join("-")
          : "",
      };
      const response = await axios({
        method: "POST",
        url: `${END_POINT}/api/rbac/add-rbac-data${slug}`,
        data: payload,
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      if (response.data?.status === "success") {
        setRefreshData((prev) => !prev);
        toast.success(response.data.message);
      }
    } catch (error) {
      console.log(error);
    } finally {
      setIsLoading(false);
    }
  };

  const onInputChangeHandler = ({ field, value }) => {
    setNewRBACData((prevData) => ({ ...prevData, [field]: value }));
  };

  const onAddClickHandler = () => {
    setIsOpen(true);
    setNewRBACData({
      section: rbacOption?.length
        ? rbacOption[0]?.toUpperCase() + rbacOption.slice(1)
        : "",
      area: "",
      option: "",
      description: "",
      title: "",
      admin: true,
      teacher: true,
      student: true,
    });
  };

  return (
    <>
      <Button
        style={{ borderRadius: "5px" }}
        content={dashboardTranslation.addReport}
        primary
        onClick={onAddClickHandler}
      />
      {isOpen && (
        <Dialog
          open={isOpen}
          onCancel={() => setIsOpen(false)}
          onConfirm={saveRBACDataHandler}
          confirmButton={{
            primary: !isLoading,
            disabled: isLoading,
            loading: isLoading,
            content: isLoading
              ? commonTranslation.saving
              : commonTranslation.save,
          }}
          cancelButton={commonTranslation.cancel}
          header={dashboardTranslation.addRBAC}
          content={
            <Flex gap="gap.small" column>
              <Input
                fluid
                label={dashboardTranslation.section}
                placeholder={dashboardTranslation.section}
                disabled
                value={newRBACData.section}
                onChange={(_, { value }) =>
                  onInputChangeHandler({ field: "section", value })
                }
                // status={isValidate ? (!event.title?.length ? "error" : "") : ""}
              />
              <Input
                fluid
                label={dashboardTranslation.area}
                placeholder={dashboardTranslation.area}
                value={newRBACData.area}
                onChange={(_, { value }) =>
                  onInputChangeHandler({ field: "area", value })
                }
                // status={isValidate ? (!event.title?.length ? "error" : "") : ""}
              />
              <Input
                fluid
                label={dashboardTranslation.option}
                placeholder={dashboardTranslation.option}
                value={newRBACData.option}
                onChange={(_, { value }) =>
                  onInputChangeHandler({ field: "option", value })
                }
                // status={isValidate ? (!event.title?.length ? "error" : "") : ""}
              />
              <Input
                fluid
                label={dashboardTranslation.description}
                placeholder={dashboardTranslation.description}
                value={newRBACData.description}
                onChange={(_, { value }) =>
                  onInputChangeHandler({ field: "description", value })
                }
                // status={isValidate ? (!event.title?.length ? "error" : "") : ""}
              />
              <Input
                fluid
                label={dashboardTranslation.title}
                placeholder={dashboardTranslation.title}
                value={newRBACData.title}
                onChange={(_, { value }) =>
                  onInputChangeHandler({ field: "title", value })
                }
                // status={isValidate ? (!event.title?.length ? "error" : "") : ""}
              />
              <div>
                <Text>{dashboardTranslation.access}</Text>
              </div>
              <Flex wrap gap="gap.large">
                <Checkbox
                  toggle
                  checked={newRBACData.admin}
                  onChange={(_, { checked }) =>
                    onInputChangeHandler({
                      field: "admin",
                      value: checked,
                    })
                  }
                  disabled={false}
                  label={commonTranslation.admin}
                />
                <Checkbox
                  toggle
                  checked={newRBACData.teacher}
                  onChange={(_, { checked }) =>
                    onInputChangeHandler({
                      field: "teacher",
                      value: checked,
                    })
                  }
                  disabled={false}
                  label={commonTranslation.teacher}
                />
                <Checkbox
                  toggle
                  checked={newRBACData.student}
                  onChange={(_, { checked }) =>
                    onInputChangeHandler({
                      field: "student",
                      value: checked,
                    })
                  }
                  disabled={false}
                  label={commonTranslation.student}
                />
              </Flex>
            </Flex>
          }
          headerAction={{
            icon: <CloseIcon />,
            title: commonTranslation.close,
            onClick: () => {
              setIsOpen(false);
            },
          }}
          styles={{
            maxWidth: "700px",
          }}
        />
      )}
    </>
  );
};

export default withTranslation()(AddRBAC);
