import { TextArea } from "@fluentui/react-northstar";
import { withTranslation } from "react-i18next";

const TextAttachment = ({ val, setVal, isEditable = false, t }) => {
  const translation = t("assignment").body;
  const attachmentTranslation = translation.attachmentOption;

  return (
    <>
      <div>
        <TextArea
          fluid
          rows={12}
          value={val}
          readOnly={!isEditable}
          placeholder={attachmentTranslation.text.placeholder}
          onChange={(e) => {
            setVal(e.target.value);
          }}
          style={{ color: "black" }}
        />
      </div>
    </>
  );
};

export default withTranslation()(TextAttachment);
