export const gradingStatusDropDown = [
  { key: 1, text: "Mark as Completed" },
  { key: 2, text: "Re-submit" },
  { key: 3, text: "Update Gradings" },
];
export const assignmentCreationStatus = [
  {
    key: "Published",
    content: "Publish now",
  },
  {
    key: "Draft",
    content: "Save draft",
  },
  {
    key: "Scheduled",
    content: "Publish later",
  },
];

export const markingType = [
  {
    key: 1,
    text: "Ungraded",
  },
  {
    key: 25,
    text: "25",
  },
  {
    key: 50,
    text: "50",
  },
  {
    key: 75,
    text: "75",
  },
  {
    key: 100,
    text: "100",
  },
];
