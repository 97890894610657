import React from "react";
import styled from "styled-components";
import Avatar from "react-avatar";
import { Tooltip } from "@fluentui/react-northstar";

const MainBox = styled.div`
  background: #ffffff;
  box-shadow: 0px 0.3px 0.9px rgba(0, 0, 0, 0.07),
    0px 1.6px 3.6px rgba(0, 0, 0, 0.11);
  width: max-content;
  padding: 16px 20px;
  margin-top: 12px;
`;

const Title = styled.p`
  font-family: "Karla";
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
  text-align: center;
  text-transform: capitalize;
  color: #000000;
  margin: 0px;
  margin-top: 12px;
  width: 100%;
  word-break: break-word;
`;

const SubTitle = styled.p`
  font-family: "Karla";
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 16px;
  text-align: center;
  text-transform: capitalize;
  color: #616161;
  margin: 0px;
  margin-top: 5px;
`;

export function SpeakerWidget(props) {
  const { data = {}, userToken } = props;
  const style = {
    display: "flex",
    alignItems: "center",
    flexDirection: "Column",
    // margin: 'auto',
    width: "100%",
    fontSize: 16,
    border: "1px solid white",
    justifyContent: "center",
    ...props.style,
  };
  return (
    <MainBox style={style}>
      <Avatar
        name={data.name || (data.email && data.email.split("@")?.[0])}
        round={true}
        size={50}
        key={"idx"}
        src={
          data.UrlPhoto
            ? data.UrlPhoto
            : data.url && userToken
            ? data.url + userToken
            : null
        }
      />
      <Tooltip
        trigger={
          <Title
            style={{
              minHeight: "18px",
              width: "100%",
              whiteSpace: "nowrap",
              overflow: "hidden",
              textOverflow: "ellipsis",
            }}
          >
            {data.name || data.email?.split("@")?.[0]}
          </Title>
        }
        content={data.name || data.email?.split("@")?.[0]}
      />

      <SubTitle>{data.type}</SubTitle>
    </MainBox>
  );
}

export default SpeakerWidget;
