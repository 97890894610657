import {
  ArrowLeftIcon,
  ArrowRightIcon,
  Button,
  Checkbox,
  CloseIcon,
  Datepicker,
  Dialog,
  EditIcon,
  ExcelIcon,
  FilesUploadIcon,
  Flex,
  SaveIcon,
  Segment,
} from "@fluentui/react-northstar";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import parse from "html-react-parser";
import { exportExcel, importExcelToJson } from "../../../../../utils/excel";
import axios from "axios";
import ImportSteps from "./ImportSteps/ImportSteps";
import * as Icon from "react-feather";
import "./index.scss";

const UploadParentsModel = (props) => {
  const [step, setStep] = useState(1);
  const [selectedFile, setSelectedFile] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [parentsWithStudents, setParentsWithStudents] = useState([]);
  const [inCompletedRows, setInCompletedRows] = useState(0);
  const [shouldShowErrors, setShouldShowErrors] = useState(false);
  const [rows, setRows] = useState([]);
  const [response, setResponse] = useState(null);

  const { t } = useTranslation();

  const translation = t("events");
  const commonTranslation = translation.common;

  const downloadSampleFile = () => {
    const headers = [
      "Student_Mail",
      "Parent_Mail",
      "Parent_Name",
      "Parent_Number",
      "Parent_Expiration_Date",
      "Provider",
    ];
    const sampleData = [
      [],
      [
        "student@tangibleitdemo.com",
        "joansmith@tangibleitdemo.com",
        "Joan Smith",
        "9865452535",
        "02/05/2025",
        "microsoft",
      ],
      [
        "student1@tangibleitdemo.com",
        "joansmith@tangibleitdemo.com",
        "Joan Smith",
        "9865452535",
        "05/03/2025",
        "microsoft",
      ],
      [
        "student2@tangibleitdemo.com",
        "jakes@gmail.com",
        "Jakes",
        "7545213685",
        "08/04/2025",
        "google",
      ],
    ];
    exportExcel(headers, sampleData, "Parent Data Template");
  };

  const downloadNotFoundStudentsExcel = () => {
    const notFoundedStudents = response?.data?.notFoundedStudents;
    if (notFoundedStudents.length) {
      const data = rows.filter((parent) =>
        notFoundedStudents.includes(parent.Student_Mail)
      );
      if (data.length) {
        const headers = Object.keys(data[0]);
        const notFoundedStudentsRows = data.map((parent) => {
          const data = headers.map((header) => parent[header]);
          return data;
        });
        exportExcel(headers, notFoundedStudentsRows, "Students_Not_Found");
      }
    }
  };

  const downloadStudentsWithParentsExcel = () => {
    const studentsWithParents = response?.data?.studentsWithParents;
    if (studentsWithParents.length) {
      const data = rows.filter((parent) =>
        studentsWithParents.includes(parent.Student_Mail)
      );
      if (data.length) {
        const headers = Object.keys(data[0]);
        const studentsWithParentsRows = data.map((parent) => {
          const data = headers.map((header) => parent[header]);
          return data;
        });
        exportExcel(
          headers,
          studentsWithParentsRows,
          "Students_already_have_parents"
        );
      }
    }
  };

  const onFileSelectHandler = async (file) => {
    if (file) {
      setSelectedFile(file);
      const parents = await importExcelToJson(file);
      if (parents.length) {
        setRows(parents);
        let parentsWithStudents = [];
        let inCompletedRows = 0;
        parents.map((parent) => {
          if (
            !!parent.Student_Mail.trim() &&
            !!parent.Parent_Mail.trim() &&
            !!parent.Parent_Name.trim() &&
            !!parent.Parent_Number.trim() &&
            !!parent.Parent_Expiration_Date.trim() &&
            !!parent.Provider.trim()
          ) {
            const findedIndex = parentsWithStudents.findIndex(
              (data) => data.parentData.parentEmail === parent.Parent_Mail
            );

            if (findedIndex !== -1) {
              parentsWithStudents[findedIndex].students = [
                ...parentsWithStudents[findedIndex].students,
                parent.Student_Mail,
              ];
            } else {
              const parent_ = {
                parentData: {
                  parentEmail: parent.Parent_Mail,
                  parentName: parent.Parent_Name,
                  number: parent.Parent_Number,
                  expirationDate: parent.Parent_Expiration_Date,
                  provider: parent.Provider,
                },
                students: [parent.Student_Mail],
              };
              parentsWithStudents.push(parent_);
            }
          } else {
            inCompletedRows = inCompletedRows + 1;
          }
        });
        setInCompletedRows(inCompletedRows);
        setParentsWithStudents(parentsWithStudents);
      }
    }
  };

  const handleAddParents = async () => {
    if (parentsWithStudents.length && inCompletedRows === 0) {
      setIsLoading(true);

      try {
        let config = {
          method: "post",
          url: `${process.env.REACT_APP_EP_URL}/api/parents/add-multiple${props.user.slug}`,
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + props.user.accessToken,
          },
          data: parentsWithStudents,
        };

        const response = await axios.request(config);
        setResponse(response.data);
        setIsLoading(false);
        setStep(3);
        props.getAllParents();
      } catch (error) {
        setIsLoading(false);
        console.log(error);
      }
    } else {
      setShowError(true);
    }
  };

  const handleReset = () => {
    setStep(1);
    setSelectedFile(null);
    setParentsWithStudents([]);
    setInCompletedRows(0);
    setShouldShowErrors(false);
    setRows([]);
    setResponse(null);
  };

  return (
    <>
      <Dialog
        open={props.isOpen}
        content={
          <>
            <div>
              <ImportSteps
                step={step}
                onFileSelectHandler={onFileSelectHandler}
                selectedFile={selectedFile}
                downloadSampleFile={downloadSampleFile}
                downloadNotFoundStudentsExcel={downloadNotFoundStudentsExcel}
                downloadStudentsWithParentsExcel={
                  downloadStudentsWithParentsExcel
                }
                rows={rows}
                shouldShowErrors={shouldShowErrors}
                response={response}
                inCompletedRows={inCompletedRows}
                {...props}
              />

              <Flex
                hAlign="center"
                gap="gap.small"
                className="action-btn-wrapper"
              >
                <Button
                  icon={<Icon.Home size="20" />}
                  onClick={() => {
                    props.onClose();
                    handleReset();
                  }}
                  disabled={isLoading}
                />
                {step === 2 && (
                  <Button
                    icon={<ArrowLeftIcon />}
                    content={commonTranslation.back}
                    onClick={() => setStep(1)}
                    disabled={isLoading}
                  />
                )}
                {step === 1 ? (
                  <Button
                    icon={<ArrowRightIcon />}
                    content={commonTranslation.next}
                    disabled={isLoading || !selectedFile}
                    onClick={() => {
                      setStep(2);
                    }}
                  />
                ) : (
                  step === 2 && (
                    <Button
                      icon={<SaveIcon size="large" outline />}
                      content={commonTranslation.save}
                      onClick={handleAddParents}
                      loading={isLoading}
                      disabled={isLoading || !rows.length}
                    />
                  )
                )}
              </Flex>
            </div>
          </>
        }
        className="excel-import-dialog"
        headerAction={{
          icon: <CloseIcon />,
          title: "Close",
          onClick: () => {
            handleReset();
            props.onClose();
          },
          disabled: isLoading,
        }}
      />
    </>
  );
};

export default UploadParentsModel;
