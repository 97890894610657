import axios from "axios";
import { useState } from "react";
import { useHistory } from "react-router-dom";
import { toast } from "react-toastify";

const URL = process.env.REACT_APP_EP_URL;

const useImportWithAI = ({ user, onCloseHandler }) => {
  const history = useHistory();

  const [isSavingEvents, setIsSavingEvents] = useState(false);

  const handleAIGeneratedData = async (eventData, { title }) => {
    let formattedData = Array.isArray(eventData) ? eventData : [];

    formattedData = formattedData.map((item) => ({
      ...item,
      description: JSON.stringify(item.description),
    }));

    try {
      setIsSavingEvents(true);
      const { data } = await axios.post(
        `${URL}/api/bulk-events/create-events-from-ai${user.slug}`,
        { events: formattedData, title },
        {
          headers: {
            Authorization: `Bearer ${user.accessToken}`,
          },
        }
      );
      if (data?.status?.toLowerCase() === "success") {
        toast.success(data?.message);
        onCloseHandler();
        history.push("/events/bundles", {
          // Random new state to rerender Bundles page
          date: new Date(),
        });
      } else if (data?.status?.toLowerCase() === "warning") {
        toast.warning(data?.message);
      }
    } catch (error) {
      toast.error(error?.response?.data?.message);
    } finally {
      setIsSavingEvents(false);
    }
  };
  return { handleAIGeneratedData, isSavingEvents };
};

export default useImportWithAI;
