import Tabs from "./Tabs/Tabs";

import { useTranslation } from "react-i18next";
import DashbaordRBACSetting from "./Dashboard";

import "./RBAC.scss";
import EventsRBACSetting from "./Events";

const RBAC_OPTIONS = {
  dashboard: "dashboard",
  events: "Events",
};

const RBACSetting = ({ user, setEventPermissions }) => {
  const { t } = useTranslation();

  const translation = t("setting").RBAC;
  const RBACOptionsTranslation = translation.RBACOptions;

  return (
    <Tabs RBAC_OPTIONS={RBAC_OPTIONS}>
      <div
        option={RBAC_OPTIONS.dashboard}
        label={RBACOptionsTranslation.dashboard}
      >
        <DashbaordRBACSetting option={RBAC_OPTIONS.dashboard} user={user} />
      </div>
      <div option={RBAC_OPTIONS.events} label={RBACOptionsTranslation.events}>
        <EventsRBACSetting
          option={RBAC_OPTIONS.events}
          user={user}
          setEventPermissions={setEventPermissions}
        />
      </div>
    </Tabs>
  );
};
export default RBACSetting;
