import { DefaultButton } from "@fluentui/react";
import React from "react";
import { withTranslation } from "react-i18next";
import { NotificationAlert } from "../../../../components/Notification/Notification";

const CreateRubricFooter = ({
  rubricData,
  havePoints,
  props,
  setCurrentAssignmentData,
  currentAssignmentData,
  setIsValidateFields,
  rubricTitle,
  t,
  
}) => {
  const translation = t("assignment").body;
  const rubricTranslation = translation.rubricPage;

  const nextButtonHandler = () => {
    setIsValidateFields(true);
    if (!!rubricData.length && !rubricTitle.replace(/\s/g, "")?.length) {
      return;
    }
    if (havePoints || +currentAssignmentData.markingType > 0) {
      let points = 0;
      rubricData.forEach((rubricItem) => {
        points = points + (Number(rubricItem.levels[0]?.points) || 0);
      });

      if (!(points == +currentAssignmentData.markingType)) {
        NotificationAlert(
          `${rubricTranslation.rubricError} (${currentAssignmentData.markingType})`,
          "error"
        );
      } else {
        setCurrentAssignmentData((prevData) => ({
          ...prevData,
          isChanged: true,
          rubric: {
            ...prevData.rubric,
            rubricTitle,
            rubricItems: rubricData.map((item) => {
              if (!havePoints) {
                item.havePoints = false;
                item.levels?.forEach((childItem) => {
                  delete childItem.points;
                });
                return item;
              } else {
                return item;
              }
            }),
            isVisited: true,
          },
        }));
        // NotificationAlert(rubricTranslation.rubricSaved, "success");
        // props.history.push("/assignments/create");
        props.setState({ isCreatingRubric: false });
      }
    } else {
      setCurrentAssignmentData((prevData) => ({
        ...prevData,
        isChanged: true,
        rubric: {
          ...prevData.rubric,
          rubricTitle,
          rubricItems: rubricData.map((item) => {
            if (!havePoints) {
              item.havePoints = false;
              item.levels?.forEach((childItem) => {
                delete childItem.points;
              });
              return item;
            } else {
              return item;
            }
          }),
          isVisited: true,
        },
      }));
      // NotificationAlert(rubricTranslation.rubricSaved, "success");
      // props.history.push("/assignments/create");
      props.setState({ isCreatingRubric: false });
    }
  };

  return (
    <div className="create-rubric-footer">
      <div className="footer-section">
        <div className="ms-fontSize-16">
          {currentAssignmentData?.rubric?.rubricCode
            ? `${rubricTranslation.CODE} ${currentAssignmentData?.rubric?.rubricCode}`
            : ""}
        </div>
        <div className="button-group">
          <DefaultButton
            text={translation.cancel}
            className="ms-depth-8"
            allowDisabledFocus
            style={{
              borderRadius: "3px",
            }}
            onClick={() => {
              // props.history.push("/assignments/create");
              props.setState({ isCreatingRubric: false });
            }}
          />
          <DefaultButton
            text={translation.next}
            className="ms-depth-8 tour-save-assignment-rubric"
            allowDisabledFocus
            style={{
              borderRadius: "3px",
              backgroundColor: "#6264A7",
              color: "white",
            }}
            onClick={nextButtonHandler}
          />
        </div>
      </div>
    </div>
  );
};

export default withTranslation()(CreateRubricFooter);
