import { useEffect, useState } from "react";
import { useEvent } from "../../../../context/EventContext";

import axios from "axios";
import { useHistory } from "react-router-dom";
import { useParams } from "react-router-dom/cjs/react-router-dom";
import { toast } from "react-toastify";
import { EventDateFilterTypes } from "../../../../components/widgets/EventFilters";

const URL = process.env.REACT_APP_EP_URL;

const useBundleEvents = ({ user }) => {
  const history = useHistory();

  const { bundleId } = useParams();

  const filterModalProps = useEvent();
  const {
    appliedFilter,
    globalSearch,
    isAudio,
    isVideoConf,
    isInplace,
    handlePortal,
    isFilterApplied,
    selectedColumns,
    startDate,
    endDate,
    selectedDateFilter,
    setSelectedDateFilter,
  } = filterModalProps;

  const [isMobileView, setIsMobileView] = useState(window.innerWidth <= 767);
  const [isTabletView, setIsTabletView] = useState(
    window.innerWidth > 767 && window.innerWidth <= 991
  );
  const [isRegModalVisible, setIsRegModalVisible] = useState(false);

  const [selectedEvent, setSelectedEvent] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isUpdating, setIsUpdating] = useState(false);
  const [deleteConfirmation, setDeleteConfirmation] = useState({
    isOpen: false,
    data: null,
    isDeleting: false,
  });
  const [eventList, setEventList] = useState([]);
  const [tags, setTags] = useState([]);
  const [categories, setCategories] = useState([]);
  const [editEvent, setEditEvent] = useState(null);

  useEffect(() => {
    const handleResize = () => {
      setIsMobileView(window.innerWidth <= 767);
      setIsTabletView(window.innerWidth <= 991);
      handleScrollButtonVisibility();
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const getCategories = async () => {
    try {
      const {
        data: { result },
      } = await axios.get(`${URL}/api/category/all${user.slug}`);
      setCategories(Array.isArray(result) ? result : []);
    } catch (error) {
      toast.error(error?.response?.data?.message);
    }
  };

  const getTags = async () => {
    try {
      const {
        data: { result },
      } = await axios.get(`${URL}/api/tag/all${user.slug}`);
      setTags(Array.isArray(result) ? result : []);
    } catch (error) {
      toast.error(error?.response?.data?.message);
    }
  };

  useEffect(() => {
    getTags();
    getCategories();
    setSelectedDateFilter(EventDateFilterTypes.all);
  }, []);

  const fetchBundleEvents = async () => {
    setIsLoading(true);

    try {
      const teacherId = user.mail;
      const headers = {
        Authorization: `Bearer ${user.accessToken}`,
      };
      let params = {};
      if (!!startDate && moment(startDate).isValid()) {
        params.start = moment(startDate).format("MM-DD-YYYY");
      }
      if (!!endDate && moment(endDate).isValid()) {
        params.end = moment(endDate).format("MM-DD-YYYY");
      }

      if (bundleId) {
        params.bundleId = bundleId;
      }

      if (isAudio) {
        params.isAudio = isAudio;
      }
      if (isVideoConf) {
        params.isVideoConf = isVideoConf;
      }
      if (isInplace) {
        params.isInplace = isInplace;
      }

      if (globalSearch) {
        params.title = globalSearch;
      }
      const selectedTags = filterModalProps.tags
        .filter((t) => !!t.checked)
        .map((t) => t.id);
      const selectedCategories = filterModalProps.categories
        .filter((c) => !!c.checked)
        .map((c) => c.id);
      if (selectedTags.length) {
        params.tags = JSON.stringify(selectedTags);
      }
      if (selectedCategories.length) {
        params.categories = JSON.stringify(selectedCategories);
      }

      if (selectedDateFilter) {
        params.dateFilter = selectedDateFilter;
        params.isFutureDateFilter = status === "active" ? true : false;
      }

      const { data } = await axios.get(
        `${URL}/api/events/${teacherId}/all${user.slug}`,
        {
          headers,
          params,
        }
      );
      setEventList(Array.isArray(data?.result) ? data.result : []);
    } catch (error) {
      toast.error(err?.response?.data?.message);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchBundleEvents();
  }, [appliedFilter, globalSearch, selectedDateFilter, bundleId]);

  const handleScrollButtonVisibility = () => {
    let containerElement = document.getElementById("scroll-events-container");
    let buttonElements = document.querySelectorAll(".scroll-button-wrapper");

    const isElementHasScrollbar =
      !!containerElement &&
      containerElement.scrollWidth > containerElement.clientWidth;

    buttonElements.forEach((buttonElement) => {
      if (isElementHasScrollbar) {
        buttonElement.classList.remove("hidden");
      } else {
        buttonElement.classList.add("hidden");
      }
    });
  };

  useEffect(() => {
    handleScrollButtonVisibility();
  }, [eventList]);

  const scrollButtonHandler = (isScrollToRight) => {
    document.getElementById("scroll-events-container").scrollBy({
      left: isScrollToRight ? 150 : -150,
      top: 0,
      behavior: "smooth",
    });
  };

  const editButtonClickHandler = (eventData) => {
    setEditEvent(eventData);
  };

  const onSuccessFulEditHandler = () => {
    setEditEvent(null);
    fetchBundleEvents();
  };

  const onSuccessFulDeleteHandler = () => {
    setEditEvent(null);
    fetchBundleEvents();
  };

  const onEditCancelHandler = () => {
    setEditEvent(null);
  };

  const updateFavourite = async (data) => {
    setIsUpdating(true);
    const { id, isFavourite } = data;
    try {
      await axios.post(
        `${URL}/api/event-favourite${user.slug}`,
        {
          eventId: id,
          teacherId: user.mail,
          isFavourite,
        },
        {
          headers: {
            Authorization: `${user.tokenType} ${user.accessToken}`,
          },
        }
      );
      fetchBundleEvents();
    } catch (error) {
      toast.error(err?.response?.data?.message);
    } finally {
      setIsUpdating(false);
    }
  };

  const deleteEvent = async (event) => {
    setDeleteConfirmation((prev) => ({ ...prev, isDeleting: true }));

    try {
      await axios.delete(`${URL}/api/events/${event.id}${user.slug}`);
      fetchBundleEvents();
    } catch (error) {
      toast.error(err?.response?.data?.message);
    } finally {
      setDeleteConfirmation((prev) => ({ ...prev, isDeleting: false }));
    }
  };

  return {
    isTabletView,
    isMobileView,
    appliedFilter,
    globalSearch,
    isAudio,
    isVideoConf,
    isInplace,
    handlePortal,
    isFilterApplied,
    selectedColumns,
    startDate,
    endDate,
    selectedDateFilter,
    setSelectedDateFilter,
    filterModalProps,

    isRegModalVisible,
    setIsRegModalVisible,

    selectedEvent,
    setSelectedEvent,

    isLoading,
    isUpdating,
    deleteConfirmation,
    setDeleteConfirmation,

    history,
    eventList,
    tags,
    categories,

    scrollButtonHandler,

    editEvent,
    editButtonClickHandler,
    onSuccessFulEditHandler,
    onSuccessFulDeleteHandler,
    onEditCancelHandler,
    updateFavourite,
    deleteEvent,
  };
};

export default useBundleEvents;
