export const reportTypes = {
  attendanceStudent: "attendanceStudent",
  attendanceSubject: "attendanceSubject",
  attendanceTeacher: "attendanceTeacher",
  attendanceSpecial: "attendanceSpecial",
  assignmentStudent: "assignmentStudent",
  assignmentSubject: "assignmentSubject",
  assignmentTeacher: "assignmentTeacher",
  eventCategory: "eventCategory",
  eventTag: "eventTag",
  feedback: "feedback",
};
