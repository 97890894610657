import React, { useState } from "react";

import {
  Button,
  Segment,
  Flex,
  Table,
  SaveIcon,
  Text,
} from "@fluentui/react-northstar";

import {
  ExcelIcon,
  FilesUploadIcon,
  ArrowLeftIcon,
  ArrowRightIcon,
} from "@fluentui/react-northstar";

import * as Icon from "react-feather";
import * as XLSX from "xlsx";
import { toast } from "react-toastify";
import axios from "axios";

import positiveVote from "../../../../../Assets/images/positive-vote.png";
import { ProfileTemplate } from "../../../../Startup/Helper/ExportTemplate";

import "./BulkUpload.scss";

const END_POINT = process.env.REACT_APP_EP_URL;

const BulkUploadProfileData = (props) => {
  const { setIsBulkUploadScreen, type, user, refreshData, header } = props;
  const [step, setStep] = useState(1);
  const [fileData, setFileData] = useState([]);
  const [selectedFile, setSelectedFile] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const onFileAddHandler = (e) => {
    if (e.target.files?.length && e.target.files[0]) {
      let file = e.target.files[0];
      try {
        const reader = new FileReader();
        reader.readAsBinaryString(file);
        reader.onload = (event) => {
          const data = event.target.result;
          const workbook = XLSX.read(data, { type: "binary" });
          const sheetName = workbook.SheetNames[0];
          const sheet = workbook.Sheets[sheetName];

          const parsedData = XLSX.utils.sheet_to_json(sheet);
          if (parsedData?.length) {
            setFileData(parsedData);
            setSelectedFile(file);
          } else {
            setFileData([]);
            setSelectedFile(null);
            toast.error(
              "Please Upload file with data as shown in Template file"
            );
          }
        };
      } catch (error) {
        console.log(error);
        setFileData([]);
        setSelectedFile(null);
      }
    } else {
      setFileData([]);
      setSelectedFile(null);
    }
  };

  const saveDataHandler = async () => {
    try {
      const payload = fileData.map(({ Title, Category }) => ({
        title: Title ?? "",
        category: Category ?? "",
      }));
      setIsLoading(true);
      const { data } = await axios.post(
        `${END_POINT}/api/profile/${
          type === "skill" ? "add-skill" : "add-interest"
        }${user.slug}`,
        payload,
        {
          headers: {
            Authorization: `Bearer ${user.accessToken}`,
          },
        }
      );

      if (data?.status?.toLowerCase() === "success") {
        toast.success("Data Saved successfully");
        setStep(3);
      } else {
        toast.warning(
          typeof data?.message === "string"
            ? data.message
            : "Something went wrong while saving Data"
        );
      }
    } catch (error) {
      toast.error(error?.response?.data?.message);
    } finally {
      setIsLoading(false);
    }
  };

  const homeBtnHandler = () => {
    if (step === 3) {
      refreshData();
    }
    setIsBulkUploadScreen(false);
  };

  return (
    <Segment
      content={
        <Flex
          gap="gap.small"
          hAlign="center"
          vAlign="center"
          padding="padding.medium"
          wrap
          style={{ flexDirection: "column", marginTop: "-30px" }}
        >
          <Segment
            content={
              <>
                <Flex
                  gap="gap.small"
                  hAlign="center"
                  vAlign="center"
                  padding="padding.medium"
                  wrap
                >
                  {step === 1 ? (
                    <Flex.Item>
                      <Segment
                        content={
                          <>
                            <Segment
                              content={<ExcelIcon size={"largest"} />}
                              className="bg-trans"
                            />
                            <Flex column>
                              <h1>{header}</h1>
                              <Flex hAlign="start">
                                <ul className="text-left">
                                  <li>
                                    Download the template and fill in all the
                                    details as shown in the headings.
                                  </li>
                                  <li>
                                    Do not add special characters in Title or
                                    Category.
                                  </li>
                                  <li>Do not add duplicate value in Title.</li>
                                  <li>Do not change Header.</li>
                                </ul>
                              </Flex>
                            </Flex>

                            <ProfileTemplate />

                            <input
                              type="file"
                              id="file-lsn"
                              name="file1"
                              onChange={onFileAddHandler}
                              style={{ display: "none" }}
                              accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                            />
                            <Button
                              icon={<FilesUploadIcon />}
                              content={
                                selectedFile
                                  ? selectedFile.name
                                  : "Select a file"
                              }
                              onClick={() =>
                                document.getElementById("file-lsn").click()
                              }
                              title={
                                selectedFile
                                  ? selectedFile.name
                                  : "Select a file"
                              }
                              style={{
                                maxWidth: "209px",
                              }}
                            />
                          </>
                        }
                        className="seg-card Karla bg-trans2"
                      />
                    </Flex.Item>
                  ) : null}
                  {step === 2 ? (
                    <Flex.Item>
                      <Segment
                        content={
                          <>
                            <Segment
                              content={<ExcelIcon size={"largest"} />}
                              className="bg-trans"
                            />
                            <Flex column>
                              <h1>{header}</h1>
                              <Flex column>
                                {fileData.length ? (
                                  <Flex column>
                                    <Table
                                      header={{
                                        items: ["Title", "Category"],
                                        styles: { fontWeight: "bold" },
                                      }}
                                      rows={fileData.map((item, index) => ({
                                        key: index,
                                        items: [
                                          item.Title ?? "",
                                          item.Category,
                                        ],
                                      }))}
                                      aria-label="Profile table"
                                      style={{ backgroundColor: "#efefef" }}
                                      styles={{ backgroundColor: "#efefef" }}
                                    />
                                    {fileData.some(({ Title }) => !Title) && (
                                      <Flex>
                                        <Text className="mt-2 pl-1 text-danger">
                                          * Title is required. Please add Title
                                          in File.
                                        </Text>
                                      </Flex>
                                    )}
                                  </Flex>
                                ) : (
                                  <div className="mt-5">
                                    <Text
                                      align="center"
                                      size="large"
                                      weight="semibold"
                                    >
                                      File Doesn't have any Data
                                    </Text>
                                  </div>
                                )}
                              </Flex>
                            </Flex>
                          </>
                        }
                        className="seg-card Karla bg-trans2"
                      />
                    </Flex.Item>
                  ) : null}

                  {step === 3 ? (
                    <Flex.Item>
                      <Segment
                        content={
                          <>
                            <img
                              src={positiveVote}
                              alt="ACV - Asistente de Aula Virtual | Congretulations"
                              style={{
                                padding: "10px",
                                margin: "20px",
                                width: "200px",
                                height: "200px",
                              }}
                            />

                            <h1 style={{ color: "#101010" }}>
                              {/* {t("startUp").controls.action[7]} */}
                            </h1>
                          </>
                        }
                      />
                    </Flex.Item>
                  ) : null}
                </Flex>

                <Button
                  icon={<Icon.Home size="20" />}
                  onClick={() => {
                    homeBtnHandler();
                  }}
                  disabled={isLoading}
                />

                {step === 2 && (
                  <Button
                    icon={<ArrowLeftIcon />}
                    // content={t("startUp").controls.action[4]}
                    onClick={() => setStep((prev) => prev - 1)}
                    disabled={isLoading}
                  />
                )}
                {step === 1 ? (
                  <Button
                    icon={<ArrowRightIcon />}
                    // content={t("startUp").controls.action[5]}
                    disabled={!fileData?.length}
                    onClick={() => {
                      setStep((prev) => 2);
                    }}
                  />
                ) : (
                  step === 2 && (
                    <Button
                      icon={<SaveIcon size="large" outline />}
                      content={"Save"}
                      onClick={() => {
                        saveDataHandler();
                      }}
                      loading={isLoading}
                      disabled={
                        isLoading || fileData.some(({ Title }) => !Title)
                      }
                    />
                  )
                )}
              </>
            }
            className="bg-gr "
            style={{
              border: "none",
              width: "100%",
            }}
          />
        </Flex>
      }
      style={{ textAlign: "center", boxShadow: "none" }}
      className="bulk-upload-data-container UploadData"
    />
  );
};

export default BulkUploadProfileData;
