import React, { useEffect, useRef, useState } from "react";
import "../ReCaptcha/style.css";
import { Button, Input, RetryIcon } from "@fluentui/react-northstar";

export const ReCaptcha = (props) => {
  useEffect(() => {
    if (!props.canvasRef.current) return;
    const canvas = props.canvasRef.current;
    if (!canvas) return;
    const ctx = canvas.getContext("2d");
    props.initializeCaptcha(ctx);
  }, []);

  const handleUserInputChange = (e) => {
    props.setCheckInputCaptchaBlankOrNot(false);
    props.setUserInput(e.target.value);
  };

  return (
    <div className="container_recaptcha">
      <div className="wrapper_recaptcha">
        <canvas ref={props.canvasRef} width="200" height="70"></canvas>
        <Button
          icon={<RetryIcon />}
          iconOnly
          title="Refresh captcha"
          id="reload-button"
          onClick={() =>
            props.initializeCaptcha(props.canvasRef.current.getContext("2d"))
          }
        />
      </div>
      <div className="input-recaptcha-div">
        <Input
          type="text"
          className="input-recaptcha-input"
          placeholder="Enter the captcha"
          value={props.userInput}
          onChange={handleUserInputChange}
        />
      </div>
      <span className="invalid-captcha">
        {props.checkInputCaptchaBlankOrNot
          ? "Please enter correct captcha"
          : ""}
      </span>
    </div>
  );
};

export default ReCaptcha;
