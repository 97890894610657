import { Button, Tooltip } from "@fluentui/react-northstar";
import React, { useState } from "react";
import { withTranslation } from "react-i18next";
import ChatGPTIcon from "../../../../Assets/images/svg/chatGpt.svg";
import EventMagicBar from "../../../../pages/Events/Components/MagicBar";

const EventMagicBarWrapper = ({
  user,
  handleAIGeneratedData,
  t,
  setUser,
  disabled,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const translation = t("events");
  const eventTranslation = translation.body.eventForm;
  const subscription = JSON.parse(localStorage.getItem("subscription"));

  return (
    <>
      {subscription.EventsFromAI && (
        <Button
          primary
          onClick={() => {
            setIsOpen(true);
          }}
          text
          className="chatgpt-icon-button"
          disabled={disabled}
        >
          <Tooltip
            trigger={<img src={ChatGPTIcon} className="chatgpt-icon" />}
            content={eventTranslation.magicBar.generateEventWithAI}
          />
        </Button>
      )}
      <EventMagicBar
        user={user}
        setUser={setUser}
        isOpen={isOpen}
        responseHandler={(data) => {
          handleAIGeneratedData(data);
        }}
        onCloseHandler={() => {
          setIsOpen(false);
        }}
        type={"SINGLE"}
      />
    </>
  );
};

export default withTranslation()(EventMagicBarWrapper);
