export const newRubricData = {
  rubricBankId: 1,
  rubricTitle: "",
  rubricCode: "",
  rubricItems: [
    {
      description: "",
      element: "parent",
      id: 1,
      title: "",
      levels: [
        {
          description: "",
          element: "child",
          id: 1,
          levelTitle: "",
        },
        {
          description: "",
          element: "child",
          id: 2,
          levelTitle: "",
        },
        {
          description: "",
          element: "child",
          id: 3,
          levelTitle: "",
        },
        {
          description: "",
          element: "child",
          id: 4,
          levelTitle: "",
        },
      ],
    },
  ],
};
