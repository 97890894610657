import React, { useEffect, useReducer, memo } from "react";
import JoyRide, { ACTIONS, EVENTS, STATUS } from "react-joyride";
import { InfoIcon } from "@fluentui/react-icons-northstar";
import { Button } from "@fluentui/react-northstar";
import { withTranslation } from "react-i18next";
import styled from "styled-components";

const TourButton = styled(Button)`
  position: ${(props) => (props.isMobileView ? "static" : "absolute")};
  top: 38px;
  right: 10px;
`;

const SUBJECT_REPORT_TOUR_STEPS = [
  {
    target: ".tour-start-date-filter",
    content: "From here you can filter an analysis data by start date.",
    disableBeacon: true,
  },
  {
    target: ".tour-end-date-filter",
    content: "From here you can filter an analysis data by end date.",
  },
  {
    target: ".tour-select-class-type",
    content: "Here you can filter data by class type.",
  },
  {
    target: ".tour-filter-by-class",
    content: "This is where you can filter data by class.",
  },
  {
    target: ".tour-boxes",
    content:
      "This is where you can get summary of subject and student of this subject.",
  },
  {
    target: ".tour-first-pie-chart",
    content: "Here you can get analysis for Attendance by Faculty.",
  },
  {
    target: ".tour-second-pie-chart",
    content: "Here you can get analysis for Attendance Records.",
  },
  {
    target: ".tour-bar-chart",
    content:
      "Here you can get analysis in a bar graph representing student attendance per class. Hover over the bar graph to see number of students are present, absent or late for that class.",
  },
  {
    target: ".tour-table-data",
    content:
      "Here you can get an analysis in a table representing student attendance per class. You can sort the data based on Absent, Late, Present, and total by clicking on the option.",
  },
];

// {
//   target: ".tour-start-date-filter",
//   content: "From here you can filter an analysis data by start date.",
//   disableBeacon: true,
// },
// {
//   target: ".tour-end-date-filter",
//   content: "From here you can filter an analysis data by end date.",
//   disableBeacon: true,
// },
// {
//   target: ".tour-select-class-type",
//   content: "Here you can filter data by class type.",
// },
// {
//   target: ".tour-filter-by-class",
//   content: "This is where you can filter data by class.",
// },
// {
//   target: ".tour-filter-by-parameter",
//   content:
//     "This is where you can filter data by parameter like Ciudad, Scholarship, Employee, InternetSpeed and Passing.",
// },

const STUDENT_REPORT_TOUR_STEPS = [
  {
    target: ".tour-filter",
    content:
      "From here you can filter an analysis data by start and end date, class type, class, Ciudad, scholarship, employee, InternetSpeed and passing.",
    disableBeacon: true,
  },

  {
    target: ".tour-boxes",
    content:
      "This is where you can get analytics of students, like the number of students present, Absent, Delay and provided Justification.",
  },
  {
    target: ".tour-first-pie-chart",
    content:
      "Here you can get analysis of student based on a Colony Attendance.",
  },
  {
    target: ".tour-second-pie-chart",
    content: "Here you can get analysis of student based on class scheduling.",
  },
  {
    target: ".tour-bar-chart",
    content:
      "Here you can get analysis in a bar graph representing student attendance. Hover over the bar graph to see number of days student is present, absent or late.",
  },
  {
    target: ".tour-table-data",
    content:
      "Here you can get an analysis in a table representing student attendance. You can sort the data based on Absent, Late, Present, and total by clicking on the option.",
  },
];

const TEACHERS_REPORT_TOUR_STEPS = [
  {
    target: ".tour-filter",
    content:
      "From here you can filter an analysis data by start and end date, class type, class, Ciudad, scholarship, employee, InternetSpeed and passing.",
    disableBeacon: true,
  },
  {
    target: ".tour-boxes",
    content:
      "Here you can get analytics of teachers like Schedule sessions, Schedule passes, Passes taken, and Skipped passes.",
  },
  {
    target: ".tour-first-pie-chart",
    content: "Here you can get analysis of teacher based on subject data.",
  },
  {
    target: ".tour-second-pie-chart",
    content:
      "Here you can get analysis of class data based on the class schedule.",
  },
  {
    target: ".tour-bar-chart",
    content:
      "Here you can get analysis in a bar graph representing how many students are present, absent, and late in that particular teacher's class.",
  },
  {
    target: ".tour-table-data",
    content:
      "Here you can get an analysis in a table representing attendance in that particular teacher's class. You can sort the data by clicking on the option.",
  },
];

const SPECIAL_REPORT_TOUR_STEPS = [
  {
    target: ".tour-filter",
    content:
      "From here you can filter an analysis data by start and end date, class type, class, Ciudad, scholarship, employee, InternetSpeed and passing.",
    disableBeacon: true,
  },

  {
    target: ".tour-boxes",
    content:
      "This is where you can get analytics of faculties, subjects, students.",
  },
  {
    target: ".tour-first-pie-chart",
    content: "Here you can get analysis of students per colony.",
  },
  {
    target: ".tour-second-pie-chart",
    content: "Here you can get analysis by connection speed.",
  },
  {
    target: ".tour-bar-chart",
    content:
      "Here you can get analysis in a bar graph representing top attendance by neighborhood.",
  },
  {
    target: ".tour-table-data",
    content:
      "Here you can get an analysis in a table representing top attendance by neighborhood. You can sort the data by clicking on the option.",
  },
];

const ASSIGNMENT_SUBJECT_REPORT_TOUR_STEPS = [
  {
    target: ".tour-start-date-filter",
    content: "From here you can filter the analysis data by start date.",
    disableBeacon: true,
  },
  {
    target: ".tour-end-date-filter",
    content: "From here you can filter the analysis data by end date.",
  },
  {
    target: ".tour-filter-by-class",
    content: "This is where you can filter data by class.",
  },
  {
    target: ".tour-boxes",
    content: "This is where you can get a summary of all assignments.",
  },
  {
    target: ".tour-first-pie-chart",
    content: "Here you can get an analysis of assignments by subject.",
  },
  {
    target: ".tour-second-pie-chart",
    content: "Here you can get an analysis of assignments by status.",
  },
  {
    target: ".tour-bar-chart",
    content:
      "Here you can get analysis in a bar graph representing assignments per class. Hover over the bar graph to see the number of assignments published, drafted, and scheduled for that class.",
  },
  {
    target: ".tour-table-data",
    content:
      "Here you can get an analysis in a table representing assignments per class. You can sort the data based on published, draft, scheduled, and total by clicking on the option.",
  },
];

const ASSIGNMENT_STUDENT_REPORT_TOUR_STEPS = [
  {
    target: ".tour-filter",
    content:
      "From here, you can filter the analysis data by start and end due date, class, city, scholarship, employee, Internet speed, and passing data.",
    disableBeacon: true,
  },

  {
    target: ".tour-boxes",
    content:
      "This is where you can get analytics of students' assignment submissions, like the number of completed, pending, submitted, resubmit assignments.",
  },
  {
    target: ".tour-first-pie-chart",
    content: "Here you can get an analysis of the students' submissions.",
  },
  {
    target: ".tour-second-pie-chart",
    content:
      "Here you can get an analysis of students' assignment submissions by status.",
  },
  {
    target: ".tour-bar-chart",
    content:
      "Here you can get analysis in a bar graph representing students' submission. Hover over the bar graph to see the number of completed, pending, submitted, and resubmit assignments of students.",
  },
  {
    target: ".tour-table-data",
    content:
      "Here you can get an analysis in a table representing students' submission. You can sort the data based on completed, pending, submitted, resubmit and total assignments by clicking on the option.",
  },
];

const ASSIGNMENT_TEACHER_REPORT_TOUR_STEPS = [
  {
    target: ".tour-start-date-filter",
    content: "From here you can filter the analysis data by starting due date.",
    disableBeacon: true,
  },
  {
    target: ".tour-end-date-filter",
    content: "From here you can filter the analysis data by ending due date.",
  },
  {
    target: ".tour-filter-by-class",
    content: "This is where you can filter data by class.",
  },
  {
    target: ".tour-boxes",
    content:
      "This is where you can get a summary of assignments by the teacher like published, drafted and scheduled assignments and created rubrics.",
  },
  {
    target: ".tour-first-pie-chart",
    content: "Here you can get an analysis of assignments by teacher.",
  },
  {
    target: ".tour-second-pie-chart",
    content: "Here you can get an analysis of assignments by status.",
  },
  {
    target: ".tour-bar-chart",
    content:
      "Here you can get analysis in a bar graph representing assignments created by teachers. Hover over the bar graph to see the number of assignments published, drafted, and scheduled by the teacher.",
  },
  {
    target: ".tour-table-data",
    content:
      "Here you can get an analysis in a table representing assignments created by teachers. You can sort the data based on published, drafted, scheduled, and total by clicking on the option.",
  },
];

const EVENT_CATEGORY_REPORT_TOUR_STEPS = [
  {
    target: ".tour-start-date-filter",
    content: "From here you can filter the analysis data by event start date.",
    disableBeacon: true,
  },
  {
    target: ".tour-end-date-filter",
    content: "From here you can filter the analysis data by event end date.",
  },
  {
    target: ".tour-filter-by-class",
    content: "This is where you can filter data by category.",
  },
  {
    target: ".tour-boxes",
    content: "This is where you can get a summary of events.",
  },
  {
    target: ".tour-first-pie-chart",
    content: "Here you can get an analysis of events by category.",
  },
  {
    target: ".tour-second-pie-chart",
    content: "Here you can get an analysis of events by type.",
  },
  {
    target: ".tour-bar-chart",
    content:
      "Here you can get analysis in a bar graph representing events by category. Hover over the bar graph to see the number of Google Meet, Microsoft Teams and other events by category.",
  },
  {
    target: ".tour-table-data",
    content:
      "Here you can get an analysis in a table representing events by category. You can sort the data based on Google Meet, Microsoft Teams and other events by clicking on the option.",
  },
];

const EVENT_TAG_REPORT_TOUR_STEPS = [
  {
    target: ".tour-start-date-filter",
    content: "From here you can filter the analysis data by event start date.",
    disableBeacon: true,
  },
  {
    target: ".tour-end-date-filter",
    content: "From here you can filter the analysis data by event end date.",
  },
  {
    target: ".tour-filter-by-class",
    content: "This is where you can filter data by tag.",
  },
  {
    target: ".tour-boxes",
    content: "This is where you can get a summary of events.",
  },
  {
    target: ".tour-first-pie-chart",
    content: "Here you can get an analysis of events by tag.",
  },
  {
    target: ".tour-second-pie-chart",
    content: "Here you can get an analysis of events by type.",
  },
  {
    target: ".tour-bar-chart",
    content:
      "Here you can get analysis in a bar graph representing events by tag. Hover over the bar graph to see the number of Google Meet, Microsoft Teams and other events by tag.",
  },
  {
    target: ".tour-table-data",
    content:
      "Here you can get an analysis in a table representing events by tag. You can sort the data based on Google Meet, Microsoft Teams and other events by clicking on the option.",
  },
];

const FEEDBACK_REPORT_TOUR_STEPS = [
  {
    target: ".tour-start-date-filter",
    content:
      "From here you can filter the feedback analysis data by starting of end date.",
    disableBeacon: true,
  },
  {
    target: ".tour-end-date-filter",
    content: "From here you can filter the analysis data by feedback end date.",
  },
  {
    target: ".tour-boxes",
    content: "This is where you can get a summary of feedback data.",
  },
  {
    target: ".tour-first-pie-chart",
    content: "Here you can get an analysis of feedback by type.",
  },
  {
    target: ".tour-second-pie-chart",
    content: "Here you can get an analysis of feedback by status.",
  },
  {
    target: ".tour-bar-chart",
    content:
      "Here you can get analysis in a bar graph representing feedback data by type. Hover over the bar graph to see the number of in-progress and completed feedback by type.",
  },
  {
    target: ".tour-table-data",
    content:
      "Here you can get an analysis in a table representing feedback data by type. You can sort the data based on in-progress and completed feedback by clicking on the option.",
  },
];

// Tour component
const Tour = ({ path, t, i18n, isMobileView }) => {
  const getPage = (path) => {
    switch (path) {
      case "/dashboard/attendance-report-by-student":
        return "attendanceStudent";
      case "/dashboard/attendance-report-by-subject":
        return "attendanceSubject";
      case "/dashboard/attendance-report-by-teacher":
        return "attendanceTeacher";
      case "/dashboard/attendance-special-report":
        return "attendanceSpecial";
      case "/dashboard/assignment-report-by-subject":
        return "assignmentSubject";
      case "/dashboard/assignment-report-by-student":
        return "assignmentStudent";
      case "/dashboard/assignment-report-by-teacher":
        return "assignmentTeacher";
      case "/dashboard/event-report-by-category":
        return "eventCategory";
      case "/dashboard/event-report-by-tag":
        return "eventTag";
      case "/dashboard/feedback-report":
        return "feedbackReport";
      default:
        return "";
    }
  };

  const translation = t("chart").tour;

  useEffect(() => {
    dispatch({ type: "CHANGE_STEPS", payload: getTourSteps() });
  }, [i18n.language, path]);

  const getTourSteps = () => {
    const page = getPage(path);
    switch (page) {
      case "attendanceSubject":
        return SUBJECT_REPORT_TOUR_STEPS.map((item, index) => ({
          ...item,
          content: translation.subjectReport[index],
        }));
      case "attendanceStudent":
        return STUDENT_REPORT_TOUR_STEPS.map((item, index) => ({
          ...item,
          content: translation.studentReport[index],
        }));
      case "attendanceTeacher":
        return TEACHERS_REPORT_TOUR_STEPS.map((item, index) => ({
          ...item,
          content: translation.teacherReport[index],
        }));
      case "attendanceSpecial":
        return SPECIAL_REPORT_TOUR_STEPS.map((item, index) => ({
          ...item,
          content: translation.speacialReport[index],
        }));
      case "assignmentSubject":
        return ASSIGNMENT_SUBJECT_REPORT_TOUR_STEPS.map((item, index) => ({
          ...item,
          content: translation.assignmentSubjectReport[index],
        }));
      case "assignmentStudent":
        return ASSIGNMENT_STUDENT_REPORT_TOUR_STEPS.map((item, index) => ({
          ...item,
          content: translation.assignmentStudentReport[index],
        }));
      case "assignmentTeacher":
        return ASSIGNMENT_TEACHER_REPORT_TOUR_STEPS.map((item, index) => ({
          ...item,
          content: translation.assignmentTeacherReport[index],
        }));
      case "eventCategory":
        return EVENT_CATEGORY_REPORT_TOUR_STEPS.map((item, index) => ({
          ...item,
          content: translation.eventCategoryReport[index],
        }));
      case "eventTag":
        return EVENT_TAG_REPORT_TOUR_STEPS.map((item, index) => ({
          ...item,
          content: translation.eventTagReport[index],
        }));
      case "feedbackReport":
        return FEEDBACK_REPORT_TOUR_STEPS.map((item, index) => ({
          ...item,
          content: translation.feedbackReport[index],
        }));
      // default:
      //   return SUBJECT_REPORT_TOUR_STEPS.map((item, index) => ({
      //     ...item,
      //     content: translation.subjectReport[index],
      //   }));
    }
  };

  const INITIAL_STATE = {
    key: new Date(), // This field makes the tour to re-render when we restart the tour
    run: false,
    continuous: true,
    loading: false,
    stepIndex: 0,
    steps: getTourSteps(),
  };

  // Reducer will manage updating the local state
  const reducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
      case "START":
        return { ...state, run: true };
      case "RESET":
        return { ...state, stepIndex: 0 };
      case "STOP":
        return { ...state, run: false };
      case "NEXT_OR_PREV":
        return { ...state, ...action.payload };
      case "RESTART":
        return {
          ...state,
          stepIndex: 0,
          run: true,
          loading: false,
          key: new Date(),
        };

      case "CHANGE_STEPS":
        return { ...state, steps: action.payload };
      default:
        return state;
    }
  };
  const [tourState, dispatch] = useReducer(reducer, INITIAL_STATE);

  // Set once tour is viewed, skipped or closed
  const setTourViewed = () => {
    // localStorage.setItem("tour", "1");
  };

  const callback = (data) => {
    const { action, index, type, status } = data;

    if (
      // If close button clicked, then close the tour
      action === ACTIONS.CLOSE ||
      // If skipped or end tour, then close the tour
      (status === STATUS.SKIPPED && tourState.run) ||
      status === STATUS.FINISHED
    ) {
      setTourViewed();
      dispatch({ type: "STOP" });
    } else if (type === EVENTS.STEP_AFTER || type === EVENTS.TARGET_NOT_FOUND) {
      // Check whether next or back button click and update the step.
      dispatch({
        type: "NEXT_OR_PREV",
        payload: { stepIndex: index + (action === ACTIONS.PREV ? -1 : 1) },
      });
    }
  };

  const startTour = () => {
    // Start the tour manually
    dispatch({ type: "RESTART" });
  };

  return (
    <>
      <TourButton
        icon={<InfoIcon circular outline size="large" />}
        iconOnly
        title={translation.tour}
        onClick={startTour}
        isMobileView={isMobileView}
      />

      <JoyRide
        {...tourState}
        callback={callback}
        styles={{
          tooltipContainer: {
            textAlign: "left",
          },
          buttonBack: {
            marginRight: 10,
          },
          buttonNext: {
            borderRadius: 4,
            color: "red",
            border: "1px solid #EB5757",
            backgroundColor: "#fff",
          },
        }}
        locale={{
          last: translation.endTour,
          next: translation.next,
          back: translation.back,
        }}
        showProgress
        disableScrolling={true}
      />
    </>
  );
};

export default withTranslation()(memo(Tour));
