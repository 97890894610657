import React, { useState, useEffect, useCallback } from "react";

import {
  ArrowLeftIcon,
  AttendeeIcon,
  Avatar,
  Button,
  CalendarIcon,
  Checkbox,
  Flex,
  Input,
  ItemLayout,
  Label,
  Loader,
  SearchIcon,
  Table,
  Text,
} from "@fluentui/react-northstar";

import axios from "axios";
import moment from "moment";
import { useHistory } from "react-router-dom";
import { withTranslation } from "react-i18next";

import { useAssignment } from "../../../../context/AssignmentContext";
import AssignmentHomeSvg from "../../../../Assets/images/svg/AssignmentHome.svg";

const url = process.env.REACT_APP_EP_URL;

const StudentSubmissions = (props) => {
  const { user, t, setSelectedSubmission } = props;

  const translation = t("assignment").body;
  const submissionTranslation = translation.studentSubmission;

  const history = useHistory();

  const {
    currentAssignmentData,
    setIsSubmissionsPage,
    setIsGradingPage,
    setCurrentPage,
    globalSearchText,
  } = useAssignment();

  const [selectedStudentSubmissions, setSelectedStudentSubmissions] = useState(
    []
  );
  const [studentForCurrentAssignment, setStudentForCurrentAssignment] =
    useState([]);
  const [
    filteredStudentForCurrentAssignment,
    setFilteredStudentForCurrentAssignment,
  ] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [searchStudents, setSearchStudents] = useState("");

  useEffect(() => {
    const getStudents = async () => {
      setIsLoading(true);
      try {
        let response = await axios({
          method: "get",
          url: `${url}/api/assignment/get-assignment-students${user.slug}&id=${currentAssignmentData.id}`,
          headers: {
            Authorization: "Bearer " + user.accessToken,
          },
        });
        if (response.data?.results?.length) {
          const studentForCurrentAssignment = response.data?.results.map(
            (item) => ({
              ...item,
              studentName: item.StudentDatum?.StudentName,
              StudentEmailId: item.studentId,
              imgUrl: item.StudentDatum?.studPhoto?.Foto || "",
              submissionDate: item.submissionDate,
              //remaining to add
              attachments: item.attachments,
              submissionStatus: item.submissionStatus,
            })
          );
          setStudentForCurrentAssignment(studentForCurrentAssignment);
          setFilteredStudentForCurrentAssignment(studentForCurrentAssignment);
        }
      } catch (error) {
        console.log("error", error);
      } finally {
        setIsLoading(false);
      }
    };
    getStudents();
    setCurrentPage("studentSubmission");

    return () => {
      setStudentForCurrentAssignment([]);
      setFilteredStudentForCurrentAssignment([]);
    };
  }, []);

  const onSubmissionSelect = (StudentEmailId) => {
    studentForCurrentAssignment.forEach((student) => {
      if (student.StudentEmailId == StudentEmailId) {
        setSelectedSubmission(student);
        //   setIsUpdateGrade(false);
      }
    });
  };

  const dateComparison = (subdate, dueDate) => {
    const d1 = new Date(subdate);
    const d2 = new Date(dueDate);
    const d3 = new Date();
    if (d1 > d2 || (d1 === null && d2 < d3)) {
      return "Late";
    } else {
      return "";
    }
  };

  const getSubmissionStatus = (submissionStatus) => {
    const statusTranslation = translation.studentSubmissionStatus;
    switch (submissionStatus) {
      case "Pending":
        return statusTranslation.pending;
      case "Grades Pending":
        return statusTranslation.gradesPending;
      case "Resubmit":
        return statusTranslation.resubmit;
      case "Completed":
        return statusTranslation.completed;
      default:
        return submissionStatus;
    }
  };

  const handleSearchStudentsByName = (searchText) => {
    const filteredStudentForCurrentAssignment =
      studentForCurrentAssignment.filter((student) =>
        student.studentName.includes(searchText)
      );
    setFilteredStudentForCurrentAssignment(filteredStudentForCurrentAssignment);
  };

  useEffect(() => {
    if (studentForCurrentAssignment.length) {
      if (globalSearchText !== undefined && !!globalSearchText.trim()) {
        handleSearchStudentsByName(globalSearchText);
      } else {
        setFilteredStudentForCurrentAssignment(studentForCurrentAssignment);
      }
    }
  }, [globalSearchText, studentForCurrentAssignment]);

  useEffect(() => {
    if (studentForCurrentAssignment.length) {
      if (searchStudents !== undefined && !!searchStudents.trim()) {
        handleSearchStudentsByName(searchStudents);
      } else {
        setFilteredStudentForCurrentAssignment(studentForCurrentAssignment);
      }
    }
  }, [searchStudents, studentForCurrentAssignment]);

  const handleChange = (value) => {
    setSearchStudents(value);
  };

  const debounce = (func) => {
    let timer;
    return function (...args) {
      const context = this;
      if (timer) clearTimeout(timer);
      timer = setTimeout(() => {
        timer = null;
        func.apply(context, args);
      }, 500);
    };
  };

  const optimizedFn = useCallback(debounce(handleChange), []);

  const header = {
    key: "header",
    items: [
      {
        content: (
          <Flex className="multi-slc">
            <Checkbox
              checked={
                selectedStudentSubmissions.length ==
                filteredStudentForCurrentAssignment.length
              }
              onChange={(e, value) => {
                if (value.checked) {
                  setSelectedStudentSubmissions(
                    filteredStudentForCurrentAssignment.map(
                      (student) => student.StudentEmailId
                    ) ?? []
                  );
                } else {
                  setSelectedStudentSubmissions([]);
                }
              }}
              styles={{
                // marginTop: this.state.checkedRecords.length ? "3px" : "0px",
                marginTop: "0px",
              }}
            />

            <Label
              style={{ color: "#252423" }}
              iconPosition="start"
              icon={
                <AttendeeIcon outline size="16" style={{ width: "16px" }} />
              }
              content={
                <Text
                  content={submissionTranslation.header[0]}
                  size="medium"
                  weight="semibold"
                  style={{ marginLeft: "4px" }}
                  className="stud-name-lable"
                />
              }
              color="white"
            />
          </Flex>
        ),
        key: "StudentsName",
      },
      {
        content: (
          <Flex>
            <Label
              style={{ color: "#252423" }}
              iconPosition="start"
              icon={<CalendarIcon outline />}
              content={
                <Text
                  // content={t("attendance").body.headers.attendance}
                  content={submissionTranslation.header[1]}
                  size="medium"
                  weight="semibold"
                  style={{ marginLeft: "4px" }}
                />
              }
              color="white"
              styles={{ marginTop: "2px" }}
            />
          </Flex>
        ),
        key: "Submission date",
      },
      {
        content: (
          <Label
            style={{ color: "#252423" }}
            iconPosition="start"
            icon={<CalendarIcon outline />}
            content={
              <Text
                // content={t("attendance").body.headers.notes}
                content={submissionTranslation.header[2]}
                size="medium"
                weight="semibold"
                style={{ marginLeft: "4px" }}
              />
            }
            color="white"
            className="action-notes"
            styles={{ marginTop: "2px" }}
          />
        ),
        key: "Due date",
      },

      {
        content: (
          <Label
            style={{ color: "#252423" }}
            content={
              <Text
                // content={t("attendance").body.headers.notes}
                content={submissionTranslation.header[3]}
                size="medium"
                weight="semibold"
                style={{ marginLeft: "4px" }}
              />
            }
            color="white"
            className="action-notes"
            styles={{ marginTop: "2px" }}
          />
        ),
        key: "Attachments",
      },
      {
        content: (
          <Label
            style={{ color: "#252423" }}
            content={
              <Text
                // content={t("attendance").body.headers.notes}
                content={submissionTranslation.header[4]}
                size="medium"
                weight="semibold"
                style={{ marginLeft: "4px" }}
              />
            }
            color="white"
            className="action-notes"
            styles={{ marginTop: "2px" }}
          />
        ),
        key: "Status",
      },
    ],
  };

  const rows = filteredStudentForCurrentAssignment?.length
    ? filteredStudentForCurrentAssignment.map((student) => {
        // const submissionDate = student.submissionDate.split(" ");
        // if (this.props.user.username !== student.StudentEmailId) {
        //  const a = dateComparison(student.submissionDate,props.data.dueDate)
        return {
          key: `${student.studentName}`,
          // key: `${student.StudentEmailId}  ${student.LessonId}`,
          // className: student.ClassType === "Virtual" ? "bgVirtual" : "",
          onClick: () => {
            onSubmissionSelect(student.StudentEmailId);
            setIsGradingPage(true);
            setIsSubmissionsPage(false);
          },
          items: [
            {
              content: (
                <Flex
                  hAlign="center"
                  vAlign="center"
                  style={{ justifyContent: "flex-start" }}
                >
                  <Checkbox
                    checked={selectedStudentSubmissions?.includes(
                      student.StudentEmailId
                    )}
                    onChange={(e, value) => {
                      if (value.checked) {
                        setSelectedStudentSubmissions([
                          ...selectedStudentSubmissions,
                          student.StudentEmailId,
                        ]);
                      } else {
                        setSelectedStudentSubmissions(
                          selectedStudentSubmissions.filter(
                            (StudentEmailId) =>
                              StudentEmailId != student.StudentEmailId
                          )
                        );
                      }
                    }}
                  />
                  <ItemLayout
                    media={
                      <Avatar
                        image={
                          student.imgUrl
                            ? student.imgUrl + user?.SASToken
                            : null
                        }
                        name={student.studentName}
                      />
                    }
                    header={`${student.studentName}`}
                    headerMedia={
                      <div className="late-label d-flex justify-content-center">
                        {dateComparison(
                          student.submissionDate,
                          currentAssignmentData.dueDate
                        )
                          ? translation.late
                          : ""}
                        {/* {student.isLate == "Y" ? "Late" : ""} */}
                      </div>
                    }
                    // headerMediaCSS={style={...{marginRight: spacing + 'em'}}}

                    style={{ paddingLeft: "0px", cursor: "pointer" }}
                    className="stud-name"
                    onClick={() => {
                      // alert(`opk ${student.id}`)
                      onSubmissionSelect(student.StudentEmailId);
                      setIsGradingPage(true);
                      setIsSubmissionsPage(false);
                    }}
                  />
                </Flex>
              ),
              key: "1-1",
            },
            {
              content: (
                <Flex
                  gap="gap.large"
                  hAlign="center"
                  vAlign="center"
                  style={{ height: "50px" }}
                >
                  <Text>
                    {moment(student.submissionDate).isValid()
                      ? moment(student.submissionDate).format(
                          "MMM DD,yyyy | HH:mm"
                        )
                      : translation.NA}
                  </Text>
                </Flex>
              ),
              key: "1-2",
            },
            {
              content: (
                <Flex
                  gap="gap.large"
                  hAlign="center"
                  vAlign="center"
                  style={{ height: "50px" }}
                >
                  <Text>
                    {moment(currentAssignmentData?.dueDate).isValid()
                      ? moment(currentAssignmentData?.dueDate).format(
                          "MMM DD,yyyy | HH:mm"
                        )
                      : null}
                  </Text>
                </Flex>
              ),
              key: "1-3",
            },
            {
              content: (
                <Flex
                  gap="gap.large"
                  hAlign="center"
                  vAlign="center"
                  style={{ height: "50px" }}
                >
                  <Text>{student.attachmentCount || 0}</Text>
                  {/* <Text>{student.attachments?.length || 3}</Text> */}
                </Flex>
              ),
              key: "1-4",
            },
            {
              content: (
                <Flex
                  gap="gap.large"
                  hAlign="center"
                  vAlign="center"
                  style={{ height: "50px" }}
                >
                  <Text
                    className={
                      student.submissionStatus === "Grades Pending"
                        ? "text-warning"
                        : student.submissionStatus === "Pending"
                        ? "text-danger"
                        : student.submissionStatus === "Completed"
                        ? "published"
                        : "resubmit"
                    }
                  >
                    {getSubmissionStatus(student.submissionStatus)}
                  </Text>
                </Flex>
              ),
              key: "1-5",
            },
          ],
          // onClick: () => {
          //   // alert(`opk ${student.id}`)
          //   onSubmissionSelect(student.id);
          //   setIsGradingPage(true);
          // },
        };

        // }
        // else {
        //   return null;
        // }
      })
    : [];
  return (
    <>
      <div className="tour-view-assignment-student-submission mt-4">
        <Flex space="between" wrap style={{ rowGap: "10px" }}>
          <Flex gap="gap.small" vAlign="center">
            <ArrowLeftIcon
              title={translation.back}
              className="ml-3"
              onClick={() => {
                setIsSubmissionsPage(false);
                // history.push({
                //   pathname: "/assignments",
                // });
              }}
              style={{ cursor: "pointer" }}
            />
            <Text weight="bold" size="large">
              {translation.back}
            </Text>
          </Flex>

          <Flex gap="gap.smaller" wrap>
            <Input
              icon={<SearchIcon />}
              clearable
              placeholder={"Search students"}
              onChange={(e) => optimizedFn(e.target.value)}
              className="mr-3 mb-2"
            />
            <Button
              className="btn-bg-primary text-white ml-2"
              content={submissionTranslation.submissions}
              onClick={() => {
                setIsSubmissionsPage(true);
              }}
            />
            <Button
              className="ms-1"
              content={submissionTranslation.assignmentDetail}
              onClick={() => {
                setIsSubmissionsPage(false);
              }}
            />
          </Flex>
        </Flex>
        {filteredStudentForCurrentAssignment?.length ? (
          <div className="student-assignments-table">
            <Table header={header} rows={rows} aria-label="Student Data" />
          </div>
        ) : isLoading ? (
          <Loader size="medium" style={{ paddingTop: "50px" }} />
        ) : (
          <>
            <figure
              className="w-100"
              style={{
                display: "flex",
                height: "100%",
                margin: "auto",
                justifyContent: "center",
              }}
            >
              <img
                alt="ACV - Tangible IT"
                src={AssignmentHomeSvg}
                style={{
                  maxWidth: "50%",
                }}
                draggable="false"
              />
            </figure>
            <Text size="large" align="center" style={{ marginTop: "15px" }}>
              {submissionTranslation.noSubmission}
            </Text>
          </>
        )}
      </div>
    </>
  );
};

export default withTranslation()(StudentSubmissions);
