import { CloseIcon, Dialog } from "@fluentui/react-northstar";
import React from "react";

function ConfirmationDialog({ openIsModal, setIsOpenModal, history }) {
  return (
    <Dialog
      open={openIsModal}
      onConfirm={() => {
        history.push("/start-up/info");
        setIsOpenModal(false);
      }}
      confirmButton="Okay"
      content={
        "Do you really want change this page? You will lose all the changes that are not saved."
      }
      header="Return to Start Up page"
      headerAction={{
        icon: <CloseIcon />,
        title: "Close",
        onClick: () => setIsOpenModal(false),
      }}
      styles={{ width: "fit-content !important" }}
    />
  );
}

export default ConfirmationDialog;
