import React, { useEffect, useState } from "react";

import {
  CallVideoIcon,
  CloseIcon,
  FilesTextColoredIcon,
  Flex,
  LinkIcon,
  MicIcon,
  OcrOnIcon,
  PauseIcon,
  PlayIcon,
  Text,
  TextArea,
  Button,
  Card,
  CardFooter,
  ArchiveIcon,
  Attachment,
  ToDoListIcon,
  ShareGenericIcon,
  SpeakerPersonIcon,
} from "@fluentui/react-northstar";
import { useAudioRecorder } from "@sarafhbk/react-audio-recorder";
import { TextField } from "@fluentui/react/lib/TextField";
import { FileUploader } from "react-drag-drop-files";
import { withTranslation } from "react-i18next";

import DialogVoice from "../Dialog/DialogVoice";
import ConfirmDialog from "../ConfirmDialog/ConfirmDialog";
import { NotificationAlert } from "../../../../components/Notification/Notification";
import { useAssignment } from "../../../../context/AssignmentContext";
import VideoRecorder from "react-video-recorder";

import "./AttachmentOption.css";
import "react-toastify/dist/ReactToastify.css";
import AvatarVideosModel from "../AvatarVideosModel/AvatarVideosModel";

export const getUniqueId = () => {
  return Math.floor(Math.random() * Math.floor(Math.random() * Date.now()));
};

function extractFileName(url) {
  // Parse the URL
  let parsedUrl = new URL(url);
  // Get the pathname (path component of the URL)
  let pathname = parsedUrl.pathname;
  // Split the pathname by '/' and get the last part which is the file name
  let parts = pathname.split("/");
  let fileName = parts[parts.length - 1];
  // Decode the URI encoding in the file name
  fileName = decodeURIComponent(fileName);
  return fileName;
}

const ReturnTextContent = ({
  val,
  setVal,
  isGradingPage,
  attachmentTranslation,
}) => {
  return (
    <>
      <div>
        <TextArea
          fluid
          rows={12}
          value={val}
          readOnly={isGradingPage}
          placeholder={attachmentTranslation.text.placeholder}
          onChange={(e) => {
            setVal(e.target.value);
          }}
          style={{ color: "black" }}
        />
      </div>
    </>
  );
};

const LinkTextField = ({
  val,
  setVal,
  attachmentTranslation,
  isLinkValidToSave,
}) => {
  return (
    <TextField
      style={{
        background: "#F5F5F5",
        width: "100%",
        borderRadius: "5px",
        minWidth: "176px",
        maxWidth: "250px",
        height: "30px",
      }}
      styles={{
        root: {
          maxWidth: "250px",
          width: "100%",
        },
      }}
      value={val}
      onChange={(e) => {
        setVal(e.target.value);
      }}
      borderless
      placeholder={attachmentTranslation.link.placeholder}
      errorMessage={
        !isLinkValidToSave ? attachmentTranslation.link.validation : ""
      }
    />
  );
};

const ReturnLinkContent = ({
  val,
  setVal,
  linkVal,
  handleAdd,
  attachmentTranslation,
  setIsLinkValidToSave,
  isLinkValidToSave,
}) => {
  const onAddClickHandler = () => {
    if (!val?.trim()?.length && setIsLinkValidToSave) {
      return setIsLinkValidToSave(false);
    } else {
      handleAdd();
    }
  };

  return (
    <div>
      <Text content={attachmentTranslation.link.header} />
      <Flex wrap space="between" style={{ marginTop: "10px", rowGap: "15px" }}>
        <LinkTextField
          val={val}
          setVal={(val) => {
            setIsLinkValidToSave(true);
            setVal(val);
          }}
          attachmentTranslation={attachmentTranslation}
          isLinkValidToSave={isLinkValidToSave}
        />

        <Button
          content={attachmentTranslation.add}
          style={{ width: "30px" }}
          onClick={onAddClickHandler}
        />
      </Flex>
    </div>
  );
};

const AttachmentOption = (props) => {
  const {
    isGradingPage,
    attachmentList,
    setAttachmentList,
    selectedStudentAttachments,
    t,
    settings,
    selectedExtension,
  } = props;
  const { currentAssignmentData, setCurrentAssignmentData } = useAssignment();
  const subscription = JSON.parse(localStorage.getItem("subscription"));

  const [linkText, setLinkText] = useState("");
  const [links, setLinks] = useState(
    currentAssignmentData?.attachments?.find((item) => item.type === "link")
      ?.info || []
  );
  const [linkState, setLinkState] = useState({
    open: false,
    isSave: !!currentAssignmentData?.attachments?.find(
      (item) => item.type === "link"
    )?.info?.length,
  });

  const [text, setText] = useState(
    currentAssignmentData?.attachments?.find((item) => item.type === "text")
      ?.info || ""
  );
  const [textState, setTextState] = useState(() =>
    currentAssignmentData?.attachments?.find((item) => item.type === "text")
      ?.info
      ? {
          open: false,
          isSave: true,
          isChanged: false,
        }
      : {
          open: false,
          isSave: false,
          isChanged: false,
        }
  );
  const [file, setFile] = useState(
    currentAssignmentData?.attachments?.find((item) => item.type === "file")
      ?.info || []
  );
  const [fileState, setFileState] = useState({
    open: false,
    isSave: false,
  });

  const [voiceState, setVoiceState] = useState(() => {
    let voiceInfoFromCurrentAssignmentData =
      currentAssignmentData?.attachments?.find(
        (item) => item.type === "voice"
      )?.info;
    return voiceInfoFromCurrentAssignmentData
      ? {
          open: false,
          isStart: false,
          isSaved: true,
          isPause: false,
          isDone: true,
          audioUrl: voiceInfoFromCurrentAssignmentData,
        }
      : {
          open: false,
          isStart: false,
          isSaved: false,
          isPause: false,
          isDone: false,
          audioUrl: "",
        };
  });

  const [videoState, setVideoState] = useState(() => {
    let videoInfoFromCurrentAssignmentData =
      currentAssignmentData?.attachments?.find(
        (item) => item.type === "video"
      )?.info;
    return videoInfoFromCurrentAssignmentData
      ? {
          open: false,
          isStart: false,
          isSaved: true,
          isDone: true,
          videoUrl: videoInfoFromCurrentAssignmentData,
        }
      : {
          open: false,
          isStart: false,
          isSaved: false,
          isDone: false,
          videoUrl: "",
        };
  });

  const [isConfirmDelete, setisConfirmDelete] = useState(false);

  const [confirmTextDelete, setConfirmTextDelete] = useState(false);
  const [confirmVideoDelete, setConfirmVideoDelete] = useState(false);
  const [isLinkValidToSave, setIsLinkValidToSave] = useState(true);
  const [isOpenAvatarVideosModel, setIsOpenAvatarVideosModel] = useState(false);

  const {
    audioResult,
    timer,
    startRecording,
    stopRecording,
    pauseRecording,
    resumeRecording,
  } = useAudioRecorder();

  const translation = t("assignment").body;
  const attachmentTranslation = translation.attachmentOption;

  // const {
  //   status,
  //   startRecording: startVideoRecording,
  //   stopRecording: stopVideoRecording,
  //   mediaBlobUrl,
  // } = useReactMediaRecorder({
  //   video: true,
  //   facingMode: { exact: "environment" },
  //   BlobPropertyBag: {
  //     type: "video/mp4",
  //   },
  // });

  useEffect(() => {
    if (!isGradingPage) {
      setLinks(
        currentAssignmentData?.attachments?.find((item) => item.type === "link")
          ?.info || []
      );

      setLinkState({
        open: false,
        isSave: !!currentAssignmentData?.attachments?.find(
          (item) => item.type === "link"
        )?.info?.length,
      });

      setText(
        currentAssignmentData?.attachments?.find((item) => item.type === "text")
          ?.info || ""
      );
      setTextState(() =>
        currentAssignmentData?.attachments?.find((item) => item.type === "text")
          ?.info
          ? {
              open: false,
              isSave: true,
              isChanged: false,
            }
          : {
              open: false,
              isSave: false,
              isChanged: false,
            }
      );
      setFile(
        currentAssignmentData?.attachments?.find((item) => item.type === "file")
          ?.info || []
      );
      setFileState({
        open: false,
        isSave: false,
      });

      setVoiceState(() => {
        let voiceInfoFromCurrentAssignmentData =
          currentAssignmentData?.attachments?.find(
            (item) => item.type === "voice"
          )?.info;
        return voiceInfoFromCurrentAssignmentData
          ? {
              open: false,
              isStart: false,
              isSaved: true,
              isPause: false,
              isDone: true,
              audioUrl: voiceInfoFromCurrentAssignmentData,
            }
          : {
              open: false,
              isStart: false,
              isSaved: false,
              isPause: false,
              isDone: false,
              audioUrl: "",
            };
      });

      setVideoState(() => {
        let videoInfoFromCurrentAssignmentData =
          currentAssignmentData?.attachments?.find(
            (item) => item.type === "video"
          )?.info;
        return videoInfoFromCurrentAssignmentData
          ? {
              open: false,
              isStart: false,
              isSaved: true,
              isDone: true,
              videoUrl: videoInfoFromCurrentAssignmentData,
            }
          : {
              open: false,
              isStart: false,
              isSaved: false,
              isDone: false,
              videoUrl: "",
            };
      });
    } else {
      setLinks(
        selectedStudentAttachments?.find((item) => item.type === "link")
          ?.info || []
      );

      setLinkState({
        open: false,
        isSave: !!selectedStudentAttachments?.find(
          (item) => item.type === "link"
        )?.info?.length,
      });

      setText(
        selectedStudentAttachments?.find((item) => item.type === "text")
          ?.info || ""
      );
      setTextState({
        open: false,
      });
      setFile(
        selectedStudentAttachments?.find((item) => item.type === "file")
          ?.info || []
      );
      setFileState({
        open: false,
        isSave: false,
      });

      setVoiceState(() => {
        let voiceInfoFromCurrentAssignmentData =
          selectedStudentAttachments?.find(
            (item) => item.type === "voice"
          )?.info;
        return voiceInfoFromCurrentAssignmentData
          ? {
              open: false,
              audioUrl: voiceInfoFromCurrentAssignmentData,
            }
          : {
              open: false,
              audioUrl: "",
            };
      });

      setVideoState(() => {
        let videoInfoFromCurrentAssignmentData =
          selectedStudentAttachments?.find(
            (item) => item.type === "video"
          )?.info;
        return videoInfoFromCurrentAssignmentData
          ? {
              open: false,
              videoUrl: videoInfoFromCurrentAssignmentData,
            }
          : {
              open: false,
              videoUrl: "",
            };
      });
    }
  }, []);

  useEffect(() => {
    if (audioResult) {
      setVoiceState((prev) => ({
        ...prev,
        audioUrl: audioResult,
      }));
    }
  }, [audioResult]);
  useEffect(() => {
    if (
      currentAssignmentData?.attachments?.find((item) => item.type === "text")
        ?.info != text
    ) {
      setTextState((prevState) => ({ ...prevState, isChanged: true }));
    }
  }, [text]);

  const handleChange = (newFile) => {
    Array.from(newFile)?.forEach((item) => {
      if (typeof item === "object" && item?.size / 1048576 > 25) {
        NotificationAlert(attachmentTranslation.file.sizeValidation, "warning");
      } else {
        setFile((prevData) => [
          ...prevData,
          {
            id: getUniqueId(),
            file: item,
          },
        ]);
      }
    });
  };

  const ReturnAttachmentContent = () => {
    return (
      <>
        {!isGradingPage && <Text content={attachmentTranslation.file.header} />}

        {!isGradingPage && (
          <FileUploader
            className="file-browse-section-outer"
            multiple
            handleChange={handleChange}
            types={[
              "docx",
              "pptx",
              "xlsx",
              "pdf",
              "txt",
              "zip",
              "c",
              "py",
              "js",
            ]}
            //   value={file}
            children={
              <div className="file-browse-section-outer mt-2">
                <div className="file-browse-section-inner">
                  <Text weight="bold">
                    <span className="file-browse-section-file">
                      {attachmentTranslation.file.browse}
                      {"  "}
                    </span>
                    {attachmentTranslation.file.or} {"  "}
                    {attachmentTranslation.file.dragAndDropHere}
                  </Text>
                </div>
              </div>
            }
            name="file"
            fluid
          />
        )}

        {!isGradingPage ? (
          <div className="attachment-file-outer">
            <Flex gap="gap.small" column>
              {file && file.length > 0
                ? file.map((f, index) => {
                    return (
                      <Attachment
                        key={index}
                        className="attachment-file-inner"
                        header={
                          f?.file?.name ||
                          `${extractFileName(f)}` ||
                          `${attachmentTranslation.file.document} ${index + 1}`
                        }
                        actionable
                        action={{
                          icon: (
                            <ArchiveIcon
                              style={{ color: "black" }}
                              onClick={(e) => {
                                e.stopPropagation();
                                const data = file.filter(
                                  (k, ind) => ind != index
                                );
                                setFile(data);
                              }}
                            />
                          ),

                          title: translation.close,
                        }}
                        onClick={() => {
                          if (
                            typeof f === "string" &&
                            f?.includes(
                              "https://acvstorageprod.blob.core.windows.net"
                            )
                          ) {
                            window.open(f);
                          } else {
                            try {
                              let fileUrl = URL.createObjectURL(f?.file);
                              window.open(fileUrl);
                            } catch (error) {}
                          }
                        }}
                      />
                    );
                  })
                : ""}
            </Flex>
          </div>
        ) : (
          <div className="attachment-file-outer">
            <Flex gap="gap.small" column>
              {file && file.length > 0
                ? file.map((f, index) => {
                    return (
                      <Attachment
                        key={index}
                        className="attachment-file-inner"
                        header={
                          `${extractFileName(f)}` ||
                          attachmentTranslation.file.file
                        }
                        actionable
                        action={{
                          icon: (
                            <ShareGenericIcon
                              style={{ color: "black" }}
                              onClick={() => {
                                window.open(f);
                              }}
                            />
                          ),

                          title: attachmentTranslation.file.openFile,
                        }}
                      />
                    );
                  })
                : ""}
            </Flex>
          </div>
        )}
      </>
    );
  };

  const handleRecordConfirm = async () => {
    if (!isGradingPage) {
      if (voiceState.isDone) {
        setisConfirmDelete(true);
        // setCurrentAssignmentData((prevData) => ({
        //   ...prevData,
        //   isChanged: true,
        //   attachments: [
        //     ...prevData.attachments?.map((item) =>
        //       item.type === "voice" ? { ...item, info: "" } : item
        //     ),
        //   ],
        // }));
      } else if (voiceState.isSaved) {
        setVoiceState({
          ...voiceState,
          isDone: true,
          open: false,
          audioUrl: audioResult,
        });
        NotificationAlert(attachmentTranslation.voice.added, "success");
        setCurrentAssignmentData((prevData) => ({
          ...prevData,
          isChanged: true,
          attachments: [
            ...prevData.attachments?.map((item) =>
              item.type === "voice" ? { ...item, info: audioResult } : item
            ),
          ],
        }));
      } else {
        stopRecording();
        if (timer && timer / 60 > 5) {
          setVoiceState({
            ...voiceState,
            isDone: false,
            open: false,
            isSaved: false,
            isPause: false,
            isStart: false,
            audioUrl: "",
          });
          NotificationAlert(
            attachmentTranslation.voice.sizeValidation,
            "warning"
          );
        } else {
          setVoiceState({
            ...voiceState,
            isSaved: true,
            isStart: false,
            isPause: false,
            timer,
          });
        }
      }
    }
  };

  const handleVideoRecordConfirm = () => {
    if (!isGradingPage) {
      if (videoState.isDone) {
        setConfirmVideoDelete(true);
      } else if (!videoState.isSaved) {
        setVideoState({
          ...videoState,
          isSaved: true,
          isClosedWithoutSaved: false,
        });
        // NotificationAlert("Video note added", "success");
        // stopVideoRecording();
      } else if (videoState.videoUrl) {
        setVideoState({
          ...videoState,
          isDone: true,
          open: false,
          isClosedWithoutSaved: false,
          isVideoRecorded: false,
        });
        NotificationAlert(attachmentTranslation.video.added, "success");
        setCurrentAssignmentData((prevData) => ({
          ...prevData,
          isChanged: true,
          attachments: [
            ...prevData.attachments?.map((item) =>
              item.type === "video"
                ? { ...item, info: videoState.videoUrl }
                : item
            ),
          ],
        }));
      } else {
        setVideoState({
          ...videoState,
          isDone: false,
          isSaved: false,
          open: false,
        });
      }
      //  else {
      //   setVideoState({
      //     ...videoState,
      //     isSaved: true,
      //     isStart: false,
      //   });
      // }
    }
  };

  const handleDeleteText = (val) => {
    setText("");
    setCurrentAssignmentData((prevData) => ({
      ...prevData,
      isChanged: true,
      attachments: [
        ...prevData.attachments?.map((item) =>
          item.type === "text" ? { ...item, info: "" } : item
        ),
      ],
    }));
    setTextState({ ...textState, isSave: false, open: false });
    setConfirmTextDelete(false);
    NotificationAlert(attachmentTranslation.text.deleted, "success");
  };

  const handleTextConfirm = (val) => {
    if (
      textState.isSave &&
      !textState.isChanged &&
      currentAssignmentData?.attachments?.find((item) => item.type === "text")
        ?.info
    ) {
      setConfirmTextDelete(true);
    } else {
      setCurrentAssignmentData((prevData) => ({
        ...prevData,
        isChanged: true,
        attachments: [
          ...prevData.attachments?.map((item) =>
            item.type === "text" ? { ...item, info: text } : item
          ),
        ],
      }));
      setTextState({ ...textState, isSave: true, open: false });
      NotificationAlert(attachmentTranslation.text.added, "success");
    }
  };

  const linkCards = (link) => {
    return (
      <Card elevated style={{ maxWidth: "200px" }}>
        <a
          href={link?.includes("http") ? link : `http://${link}`}
          target={"_blank"}
        >
          <Text style={{ wordBreak: "break-word" }}> {link}</Text>
        </a>
        {!isGradingPage && (
          <CardFooter style={{ marginTop: "10px" }}>
            <Flex hAlign="end">
              <ArchiveIcon
                onClick={() => {
                  const data = links.filter((a) => a != link);
                  setLinks(data);
                }}
              />
            </Flex>
          </CardFooter>
        )}
      </Card>
    );
  };

  const ReturnVoiceContent = () => {
    return (
      <>
        {isGradingPage ? (
          <div className="justify-center">
            <audio controls src={voiceState.audioUrl} />
          </div>
        ) : (
          <div className="justify-center">
            {(voiceState.isSaved || voiceState.isDone) &&
            voiceState.audioUrl ? (
              <audio
                controls
                src={voiceState.audioUrl ? voiceState.audioUrl : ""}
              />
            ) : voiceState.isPause ? (
              <>
                <Flex column gap="gap.smaller">
                  <Text content={timer} className="justify-center" />
                  <div className="justify-center">
                    <PlayIcon
                      className="play-bg-color icon-padding"
                      circular
                      size="large"
                      onClick={() => {
                        setVoiceState({
                          ...voiceState,
                          isStart: true,
                          isPause: false,
                        });
                        resumeRecording();
                      }}
                    />
                  </div>
                  <Text
                    content={attachmentTranslation.voice.resume}
                    className="justify-center"
                  />
                </Flex>
              </>
            ) : voiceState.isStart ? (
              <>
                <Flex column gap="gap.smaller">
                  <Text content={timer} className="justify-center" />
                  <div className="justify-center">
                    <PauseIcon
                      className="pause-bg-color icon-padding"
                      circular
                      size="large"
                      onClick={() => {
                        setVoiceState({
                          ...voiceState,
                          isStart: false,
                          isPause: true,
                        });
                        pauseRecording();
                      }}
                    />
                  </div>
                  <Text
                    content={attachmentTranslation.voice.pause}
                    className="justify-center"
                  />
                </Flex>
              </>
            ) : (
              <>
                <Flex column gap="gap.smaller">
                  <Text content={timer} className="justify-center" />
                  <div className="justify-center">
                    <MicIcon
                      className="mic-bg-color icon-padding"
                      circular
                      size="large"
                      onClick={() => {
                        setVoiceState({ ...voiceState, isStart: true });
                        startRecording();
                      }}
                    />
                  </div>
                  <Text
                    content={attachmentTranslation.voice.start}
                    className="justify-center"
                  />
                </Flex>
              </>
            )}
          </div>
        )}
      </>
    );
  };

  const getConfirmFlag = (val) => {
    setisConfirmDelete(false);

    if (!val) {
      setVoiceState({
        ...voiceState,
        open: false,
      });

      return;
    }

    setVoiceState({
      ...voiceState,
      isStart: false,
      isPause: false,
      isSaved: false,
      isDone: false,
      open: false,
    });
    NotificationAlert(attachmentTranslation.voice.deleted, "success");
    setCurrentAssignmentData((prevData) => ({
      ...prevData,
      isChanged: true,
      attachments: [
        ...prevData.attachments?.map((item) =>
          item.type === "voice" ? { ...item, info: "" } : item
        ),
      ],
    }));
  };
  const getConfirmFlagForVideo = (val) => {
    setConfirmVideoDelete(false);

    if (!val) {
      setVideoState({
        ...videoState,
        open: false,
      });

      // return;
    } else {
      setVideoState({
        ...videoState,
        isStart: false,
        isSaved: false,
        isDone: false,
        open: false,
        videoUrl: "",
        isClosedWithoutSaved: false,
        isVideoRecorded: false,
      });
      NotificationAlert(attachmentTranslation.video.deleted, "success");
      setCurrentAssignmentData((prevData) => ({
        ...prevData,
        isChanged: true,
        attachments: [
          ...prevData.attachments?.map((item) =>
            item.type === "video" ? { ...item, info: "" } : item
          ),
        ],
      }));
    }
  };

  const ReturnVideoContent = () => {
    return (
      <>
        {isGradingPage ? (
          <div className="justify-center">
            <video
              controls
              src={videoState.videoUrl}
              style={{ width: "100%", maxWidth: "500px" }}
            />
          </div>
        ) : (
          <div>
            {(videoState.isVideoRecorded ||
              videoState.isDone ||
              videoState.isSaved) &&
            videoState.videoUrl ? (
              <div className="justify-center">
                <video
                  controls
                  src={videoState.videoUrl}
                  style={{ width: "100%", maxWidth: "500px" }}
                />
              </div>
            ) : (
              // videoState.videoUrl ? (
              //   <div className="justify-center">
              //     <video controls src={videoState.videoUrl} />
              //   </div>
              <div>
                <Flex>
                  <VideoRecorder
                    onRecordingComplete={(videoBlob) => {
                      if ({ ...videoState }.isClosedWithoutSaved) {
                        setVideoState((prevState) => ({
                          ...prevState,
                          isVideoRecorded: true,
                          isDone: false,
                          isSaved: false,
                          videoUrl: "",
                          isClosedWithoutSaved: false,
                        }));
                      } else {
                        setVideoState((prevState) => ({
                          ...prevState,
                          videoUrl: videoBlob
                            ? URL.createObjectURL(videoBlob)
                            : "",
                          isVideoRecorded: true,
                        }));
                      }
                      // }
                    }}
                    // onStartRecording={() => {
                    //   setVideoState((prevState) => ({
                    //     ...prevState,
                    //     isDone: false,
                    //     isSaved: false,
                    //     isClosedWithoutSaved: false,
                    //   }));
                    // }}
                    // onStopRecording={(videoBlob) => {
                    //   console.log("video stopped", videoBlob);
                    //   setVideoState((prevState) => ({
                    //     ...prevState,
                    //     isDone: true,
                    //     videoUrl: videoBlob
                    //       ? URL.createObjectURL(videoBlob)
                    //       : "",
                    //     isVideoRecorded: true,
                    //   }));
                    // }}
                    isFlipped={false}
                    className="videonote-box"
                    renderDisconnectedView={() => <div></div>}
                    replayVideoAutoplayAndLoopOff
                    timeLimit={300000}
                  />
                </Flex>
              </div>
            )}
          </div>
        )}
      </>
    );
  };

  return (
    <div className="attachment-option">
      <AvatarVideosModel
        open={isOpenAvatarVideosModel && settings?.generateAvatar}
        onClose={() => {
          setIsOpenAvatarVideosModel(false);
        }}
        onSelectVideo={() => {}}
        slug={props?.user?.slug || ""}
        mail={props?.user?.mail || ""}
        setSelectedAvatarVideo={props.setSelectedAvatarVideo}
        selectedAvatarVideo={props.selectedAvatarVideo}
        setState={props.setState}
        state={props.state}
      />
      <ConfirmDialog
        isConfirmDelete={isConfirmDelete}
        setisConfirmDelete={setisConfirmDelete}
        onConfirm={(val) => getConfirmFlag(val)}
        onCancel={(val) => getConfirmFlag(val)}
        header={attachmentTranslation.deleteConfirmation}
        confirmButton={translation.yes}
        cancelButton={translation.no}
      />

      <ConfirmDialog
        isConfirmDelete={confirmTextDelete}
        setisConfirmDelete={setConfirmTextDelete}
        onConfirm={(val) => {
          handleDeleteText(val);
        }}
        onCancel={(val) => setConfirmTextDelete(val)}
        header={attachmentTranslation.deleteConfirmation}
        confirmButton={translation.yes}
        cancelButton={translation.no}
      />
      <ConfirmDialog
        isConfirmDelete={confirmVideoDelete}
        setisConfirmDelete={setConfirmVideoDelete}
        onConfirm={(val) => getConfirmFlagForVideo(val)}
        onCancel={(val) => getConfirmFlagForVideo(val)}
        header={attachmentTranslation.deleteConfirmation}
        confirmButton={translation.yes}
        cancelButton={translation.no}
      />

      <div className="mt-2 mb-1">
        <Flex>
          {isGradingPage ? (
            <Flex wrap gap="gap.small" style={{ rowGap: "5px" }}>
              <DialogVoice
                button={
                  <Button
                    className={`attachment-btn d-flex justify-content-center align-items-center ${
                      text ? " btn-bg-primary" : ""
                    }`}
                    icon={<OcrOnIcon size="large" />}
                    title={attachmentTranslation.text.textNote}
                    iconOnly
                    style={{
                      color: text ? "white" : "black",
                    }}
                  />
                }
                open={textState.open}
                onOpen={() => setTextState({ ...textState, open: !!text })}
                content={
                  <ReturnTextContent
                    val={text}
                    setVal={setText}
                    isGradingPage={isGradingPage}
                    attachmentTranslation={attachmentTranslation}
                  />
                }
                headerAction={{
                  icon: <CloseIcon />,
                  title: translation.close,
                  onClick: () => {
                    setTextState({ ...textState, open: false });
                  },
                }}
                header={attachmentTranslation.text.textNote}
              />

              <DialogVoice
                button={
                  <Button
                    className={`attachment-btn d-flex justify-content-center align-items-center ${
                      voiceState.audioUrl ? " btn-bg-primary" : ""
                    }`}
                    icon={<MicIcon size="large" />}
                    title={attachmentTranslation.voice.voiceNote}
                    iconOnly
                    style={{
                      color: voiceState.audioUrl ? "white" : "black",
                    }}
                  />
                }
                open={voiceState.open}
                onOpen={() =>
                  setVoiceState({
                    ...voiceState,
                    open: !!voiceState.audioUrl,
                  })
                }
                onCancel={() => setVoiceState({ ...voiceState, open: false })}
                header={attachmentTranslation.voice.voiceNote}
                content={<ReturnVoiceContent />}
                headerAction={{
                  icon: <CloseIcon />,
                  title: translation.close,
                  onClick: () => {
                    setVoiceState({
                      ...voiceState,
                      open: false,
                    });
                  },
                }}
              />

              <DialogVoice
                button={
                  <Button
                    className={`attachment-btn d-flex justify-content-center align-items-center
                            ${videoState.videoUrl ? " btn-bg-primary" : ""}`}
                    icon={<CallVideoIcon size="large" />}
                    title={attachmentTranslation.video.videoNote}
                    iconOnly
                    style={{
                      color: videoState.videoUrl ? "white" : "black",
                    }}
                  />
                }
                open={videoState.open}
                onOpen={() =>
                  setVideoState({
                    ...videoState,
                    open: !!videoState.videoUrl,
                  })
                }
                onCancel={() => setVideoState({ ...videoState, open: false })}
                // onConfirm={() => {
                //   setVideoState({ ...videoState, isSaved: true, open:false})
                // }}
                header={attachmentTranslation.video.videoNote}
                content={<ReturnVideoContent />}
                headerAction={{
                  icon: <CloseIcon />,
                  title: translation.close,
                  onClick: () => {
                    // stopVideoRecording();
                    setVideoState({
                      ...videoState,
                      open: false,
                      // isStart: false,
                      // isStop: false,
                      // isDone: false,
                    });
                  },
                }}
              />

              {selectedExtension?.generateAvatar && (
                <Button
                  className={`attachment-btn d-flex justify-content-center align-items-center ${
                    props.selectedAvatarVideo ? " btn-bg-primary" : ""
                  }`}
                  icon={<SpeakerPersonIcon size="large" />}
                  title={attachmentTranslation.voice.voiceNote}
                  iconOnly
                  style={{
                    color: props.selectedAvatarVideo ? "white" : "black",
                  }}
                  onClick={() => {
                    setIsOpenAvatarVideosModel(true);
                  }}
                />
              )}

              <DialogVoice
                button={
                  <Button
                    className={`attachment-btn d-flex justify-content-center align-items-center ${
                      links.length > 0 ? " btn-bg-primary" : ""
                    }`}
                    icon={<LinkIcon size="large" />}
                    iconOnly
                    title={attachmentTranslation.link.links}
                    style={{
                      color: links.length ? "white" : "black",
                    }}
                  />
                }
                open={linkState.open}
                onOpen={() => {
                  setLinkState({
                    ...linkState,
                    open: !!links.length,
                  });
                }}
                // onConfirm={() => {
                //   setLinkState({
                //     ...linkState,
                //     open: false,
                //     isSave: true,
                //   });
                //   if (links.length && links.length > 0) {
                //     NotificationAlert("Link added", "success");
                //   }
                // }}
                // confirmButton="Save"
                content={
                  <>
                    {/* <ReturnLinkContent
                                val={linkText}
                                setVal={(val) => setLinkText(val)}
                                linkVal={links}
                                handleAdd={() => {
                                  setLinks([...links, linkText]);
                                }}
                              /> */}

                    <Flex wrap>
                      {links && links.length > 0
                        ? links.map((link, index) => {
                            return (
                              <Flex key={index} style={{ margin: "10px" }}>
                                {linkCards(link)}
                              </Flex>
                            );
                          })
                        : ""}
                    </Flex>
                  </>
                }
                headerAction={{
                  icon: <CloseIcon />,
                  title: translation.close,
                  onClick: () => {
                    setLinkState({ ...linkState, open: false });
                  },
                }}
                header={attachmentTranslation.link.links}
              />

              <DialogVoice
                button={
                  <Button
                    className={`attachment-btn d-flex justify-content-center align-items-center ${
                      file.length > 0 ? " btn-bg-primary" : ""
                    }`}
                    iconOnly
                    icon={<FilesTextColoredIcon size="large" />}
                    title={attachmentTranslation.file.Documents}
                    style={{
                      color: file.length ? "white" : "black",
                    }}
                  />
                }
                open={fileState.open}
                onOpen={() => {
                  setFileState({
                    ...fileState,
                    open: !!file.length,
                  });
                }}
                content={
                  <>
                    <ReturnAttachmentContent />
                  </>
                }
                headerAction={{
                  icon: <CloseIcon />,
                  title: translation.close,
                  onClick: () => {
                    setFileState({
                      ...fileState,
                      open: false,
                    });
                  },
                }}
                header={attachmentTranslation.file.Documents}
              />
            </Flex>
          ) : (
            <Flex wrap gap="gap.small" style={{ rowGap: "5px" }}>
              <DialogVoice
                button={
                  <Button
                    className={`attachment-btn d-flex justify-content-center align-items-center ${
                      currentAssignmentData?.attachments?.find(
                        (item) => item.type === "text"
                      )?.info
                        ? " btn-bg-primary"
                        : ""
                    }`}
                    icon={<OcrOnIcon size="large" />}
                    title={attachmentTranslation.text.addText}
                    iconOnly
                    style={{
                      color: currentAssignmentData?.attachments?.find(
                        (item) => item.type === "text"
                      )?.info
                        ? "white"
                        : "black",
                    }}
                  />
                }
                open={textState.open}
                onOpen={() => setTextState({ ...textState, open: true })}
                onConfirm={() => {
                  handleTextConfirm();
                  // setConfirmTextDelete(true)
                }}
                confirmButton={
                  textState.isSave &&
                  !textState.isChanged &&
                  currentAssignmentData?.attachments?.find(
                    (item) => item.type === "text"
                  )?.info
                    ? attachmentTranslation.deleteNote
                    : translation.save
                }
                content={
                  <ReturnTextContent
                    val={text}
                    setVal={(val) => setText(val)}
                    attachmentTranslation={attachmentTranslation}
                  />
                }
                headerAction={{
                  icon: <CloseIcon />,
                  title: translation.close,
                  onClick: () => {
                    let textData = currentAssignmentData?.attachments?.find(
                      (item) => item.type === "text"
                    )?.info;
                    if (textData) {
                      setText(textData);
                    } else {
                      setText("");
                    }

                    setTextState({ ...textState, open: false });
                  },
                }}
                header={attachmentTranslation.text.addTextNote}
              />

              <DialogVoice
                button={
                  <Button
                    className={` attachment-btn d-flex justify-content-center align-items-center ${
                      voiceState.isDone ? " btn-bg-primary" : ""
                    }`}
                    icon={<MicIcon size="large" />}
                    title={attachmentTranslation.voice.addVoice}
                    iconOnly
                    style={{
                      color: voiceState.isDone ? "white" : "black",
                    }}
                  />
                }
                open={voiceState.open}
                onOpen={() => setVoiceState({ ...voiceState, open: true })}
                onCancel={() => setVoiceState({ ...voiceState, open: false })}
                onConfirm={() => {
                  handleRecordConfirm();
                }}
                confirmButton={
                  isGradingPage
                    ? ""
                    : voiceState.isDone
                    ? attachmentTranslation.deleteNote
                    : voiceState.isSaved
                    ? translation.done
                    : translation.save
                }
                header={
                  <Flex column>
                    <Text align="start">
                      {attachmentTranslation.voice.addVoiceNote}
                    </Text>
                    <Text weight="light" size="small">
                      {attachmentTranslation.voice.sizeValidation}
                    </Text>
                  </Flex>
                }
                content={<ReturnVoiceContent />}
                headerAction={{
                  icon: <CloseIcon />,
                  title: translation.close,
                  onClick: () => {
                    stopRecording();
                    setVoiceState({
                      ...voiceState,
                      open: false,
                    });
                  },
                }}
              />
              <DialogVoice
                button={
                  <Button
                    className={`attachment-btn d-flex justify-content-center align-items-center 
                      ${
                        (videoState.isDone || videoState.isSaved) &&
                        videoState.videoUrl
                          ? "btn-bg-primary"
                          : ""
                      }
                    `}
                    icon={<CallVideoIcon size="large" />}
                    title={attachmentTranslation.video.addVideo}
                    iconOnly
                    style={{
                      color:
                        (videoState.isDone || videoState.isSaved) &&
                        videoState.videoUrl
                          ? "white"
                          : "black",
                    }}
                  />
                }
                open={videoState.open}
                onOpen={() => setVideoState({ ...videoState, open: true })}
                onCancel={() => {
                  if (videoState.isVideoRecorded) {
                    setVideoState({
                      ...videoState,
                      videoUrl: null,
                      isVideoRecorded: false,
                    });
                  }
                }}
                cancelButton={"Retake video"}
                onConfirm={() => {
                  handleVideoRecordConfirm();
                  // setCurrentAssignmentData((prevData) => ({
                  //   ...prevData,
                  //   isChanged: true,
                  //   attachments: [
                  //     ...prevData.attachments?.map((item) =>
                  //       item.type === "video"
                  //         ? { ...item, info: videoState.videoUrl }
                  //         : item
                  //     ),
                  //   ],
                  // }));

                  // if (!videoState.isDone) {
                  //   setVideoState({
                  //     ...videoState,
                  //     open: false,
                  //     isStart: false,
                  //     isDone: true,
                  //     isStop: false,
                  //   });
                  // } else {
                  //   setVideoState({
                  //     ...videoState,
                  //     open: false,
                  //     isStart: false,
                  //     isDone: false,
                  //     isStop: false,
                  //   });
                  // }
                }}
                confirmButton={
                  videoState.isDone
                    ? attachmentTranslation.deleteNote
                    : videoState.isSaved
                    ? translation.done
                    : translation.save
                }
                header={
                  <Flex column>
                    <Text align="start">
                      {attachmentTranslation.video.recordVideo}
                    </Text>
                    <Text weight="light" size="small">
                      {attachmentTranslation.video.lengthValidation}
                    </Text>
                  </Flex>
                }
                content={<ReturnVideoContent />}
                headerAction={{
                  icon: <CloseIcon />,
                  title: translation.close,
                  onClick: () => {
                    // stopVideoRecording();
                    setVideoState({
                      ...videoState,
                      open: false,
                      // isStart: false,
                      // isStop: false,
                      isClosedWithoutSaved: true,
                    });
                  },
                }}
              />
              {subscription.GAN && selectedExtension?.generateAvatar && (
                <Button
                  className={`attachment-btn d-flex justify-content-center align-items-center ${
                    props.selectedAvatarVideo ? " btn-bg-primary" : ""
                  }`}
                  icon={<SpeakerPersonIcon size="large" />}
                  title={"Avatar Video"}
                  iconOnly
                  style={{
                    color: props.selectedAvatarVideo ? "white" : "black",
                  }}
                  onClick={() => {
                    setIsOpenAvatarVideosModel(true);
                  }}
                />
              )}
              <DialogVoice
                button={
                  <Button
                    className={`attachment-btn d-flex justify-content-center align-items-center ${
                      currentAssignmentData?.attachments?.find(
                        (item) => item.type === "link"
                      )?.info?.length > 0
                        ? " btn-bg-primary"
                        : ""
                    }`}
                    icon={<LinkIcon size="large" />}
                    iconOnly
                    title={attachmentTranslation.link.addLink}
                    style={{
                      color: currentAssignmentData?.attachments?.find(
                        (item) => item.type === "link"
                      )?.info?.length
                        ? "white"
                        : "black",
                    }}
                  />
                }
                open={linkState.open}
                onOpen={() => {
                  setLinkState({ ...linkState, open: true });
                }}
                onConfirm={() => {
                  setCurrentAssignmentData((prevData) => ({
                    ...prevData,
                    isChanged: true,
                    attachments: [
                      ...prevData.attachments?.map((item) =>
                        item.type === "link" ? { ...item, info: links } : item
                      ),
                    ],
                  }));
                  setLinkState({
                    ...linkState,
                    open: false,
                    isSave: true,
                  });
                  if (links.length && links.length > 0) {
                    NotificationAlert(
                      attachmentTranslation.link.linkAdded,
                      "success"
                    );
                  }
                }}
                confirmButton={translation.save}
                content={
                  <>
                    <ReturnLinkContent
                      val={linkText}
                      setVal={(val) => setLinkText(val)}
                      linkVal={links}
                      handleAdd={() => {
                        setLinkText("");
                        setLinks([...links, linkText]);
                      }}
                      attachmentTranslation={attachmentTranslation}
                      isLinkValidToSave={isLinkValidToSave}
                      setIsLinkValidToSave={setIsLinkValidToSave}
                    />
                    {/* <div className="ms-Grid">
                                <div className="ms-Grid-row">
                                  {links && links.length > 0
                                    ? links.map((link, index) => {
                                        return (
                                          <div
                                            className="ms-Grid-col ms-sm12 ms-md6 ms-lg4 mt-2"
                                            key={index}
                                          >
                                            {linkCards(link)}
                                          </div>
                                        );
                                      })
                                    : ""}
                                </div>
                              </div> */}
                    <div style={{ marginTop: "10px" }}>
                      <Flex wrap>
                        {links && links.length > 0
                          ? links.map((link, index) => {
                              return (
                                <Flex key={index} style={{ margin: "10px" }}>
                                  {linkCards(link)}
                                </Flex>
                              );
                            })
                          : ""}
                      </Flex>
                    </div>
                  </>
                }
                headerAction={{
                  icon: <CloseIcon />,
                  title: translation.close,
                  onClick: () => {
                    let linkData = currentAssignmentData?.attachments?.find(
                      (item) => item.type === "link"
                    )?.info;
                    if (linkData?.length) {
                      setLinks(linkData);
                    } else {
                      setLinks([]);
                    }
                    setLinkState({ ...linkState, open: false });
                  },
                }}
                header={attachmentTranslation.link.addLink}
              />

              <DialogVoice
                button={
                  <Button
                    className={`attachment-btn d-flex justify-content-center align-items-center  ${
                      currentAssignmentData?.attachments?.find(
                        (item) => item.type === "file"
                      )?.info?.length > 0
                        ? "btn-bg-primary"
                        : ""
                    }`}
                    iconOnly
                    icon={<FilesTextColoredIcon size="large" />}
                    title={attachmentTranslation.file.addDocument}
                    style={{
                      color: currentAssignmentData?.attachments?.find(
                        (item) => item.type === "file"
                      )?.info?.length
                        ? "white"
                        : "black",
                    }}
                  />
                }
                open={fileState.open}
                onOpen={() => {
                  setFileState({
                    ...fileState,
                    open: true,
                  });
                }}
                onConfirm={() => {
                  setCurrentAssignmentData((prevData) => ({
                    ...prevData,
                    isChanged: true,
                    attachments: [
                      ...prevData.attachments?.map((item) =>
                        item.type === "file" ? { ...item, info: file } : item
                      ),
                    ],
                  }));
                  setFileState({
                    ...fileState,
                    open: false,
                    isSave: true,
                  });
                  if (file && file.length > 0) {
                    NotificationAlert(
                      attachmentTranslation.file.documentsAdded,
                      "success"
                    );
                  }
                }}
                content={
                  <>
                    <ReturnAttachmentContent />
                  </>
                }
                confirmButton={translation.save}
                headerAction={{
                  icon: <CloseIcon />,
                  title: translation.close,
                  onClick: () => {
                    let fileData = currentAssignmentData?.attachments?.find(
                      (item) => item.type === "file"
                    )?.info;
                    if (fileData?.length) {
                      setFile(fileData);
                    } else {
                      setFile([]);
                    }
                    setFileState({ ...linkState, open: false });

                    setFileState({
                      ...fileState,
                      open: false,
                    });
                  },
                }}
                header={attachmentTranslation.file.attachDocument}
              />
            </Flex>
          )}
        </Flex>
      </div>
    </div>
  );
};

export default withTranslation()(AttachmentOption);
