import React from "react";
import Gptcredits from "./Gptcredits";
import Tabs from "../Events/Tabs/Tabs";
import History from "./History";

const Credits = ({
  user,
  isTabletOrMobileView,
  isMobileView,
  isTabletView,
  creditHistory,
  setCreditHistory,
}) => {
  return (
    <Tabs currentTab="GPT Credit Request">
      {user.actualRole === "SuperAdmin" && (
        <div label="GPT Credit Request">
          <Gptcredits
            user={user}
            isTabletOrMobileView={isTabletOrMobileView}
            isMobileView={isMobileView}
            isTabletView={isTabletView}
            creditHistory={creditHistory}
            setCreditHistory={setCreditHistory}
          />
        </div>
      )}
      {user.actualRole === "SuperAdmin" && (
        <div label="History">
          <History
            user={user}
            isTabletOrMobileView={isTabletOrMobileView}
            isMobileView={isMobileView}
            isTabletView={isTabletView}
            creditHistory={creditHistory}
          />
        </div>
      )}
    </Tabs>
  );
};

export default Credits;
