import React, { useState } from "react";
import { CloseIcon, Dialog, Dropdown, Input } from "@fluentui/react-northstar";
import { useTranslation } from "react-i18next";
import axios from "axios";
import { toast } from "react-toastify";

const DeleteTutorModel = (props) => {
  const {
    tutorToDelete,
    setTutorToDelete,
    students,
    setStudents,
    teachers,
    setTeachers,
    tutors,
    setTutors,
  } = props;
  const [isLoading, setIsLoading] = useState(false);

  const handleDeleteTutor = async () => {
    const { email = "", role = "", name = "" } = tutorToDelete;

    if (email && role) {
      const payload = {
        mail: email,
        role,
        fields: {
          isTutor: false,
        },
      };
      setIsLoading(true);
      try {
        let config = {
          method: "put",
          url: `${process.env.REACT_APP_EP_URL}/api/tutor/${props.user.slug}`,
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + props.user.accessToken,
          },
          data: JSON.stringify(payload),
        };
        const response = await axios.request(config);
        setIsLoading(false);
        if (response.data.success) {
          let clonedTutors = [...tutors];
          clonedTutors = clonedTutors.filter((tutor) => tutor.email !== email);
          setTutors(clonedTutors);
          if (role === "Student") {
            let clonedStudents = [
              {
                key: email,
                header: name,
              },
              ...students,
            ];
            setStudents(clonedStudents);
          } else {
            let clonedTeachers = [
              {
                key: email,
                header: name,
              },
              ...teachers,
              ,
            ];
            setTeachers(clonedTeachers);
          }
          toast.success("Tutor deleted successfully!");
        } else {
          toast.error("Tutor delete Failed!");
        }
        setTutorToDelete(null);
        props.onClose();
      } catch (error) {
        setIsLoading(false);
        console.log(error);
      }
    } else {
      toast.error("fields are missing");
    }
  };

  const { t } = useTranslation();

  const deleteParentDialog = t("setting").parent.deleteParentDialog;
  const commonTranslation = t("booking").common;
  const deleteTutorModelDialog = t("booking").deleteTutorDialog;

  return (
    <Dialog
      open={props.isOpen}
      content={
        <div>
          <p>{deleteTutorModelDialog.message}</p>
          {tutorToDelete?.isBookedSlots && (
            <p style={{ color: "red" }}>{deleteTutorModelDialog.warning}</p>
          )}
        </div>
      }
      header={deleteTutorModelDialog.header}
      className="smallDlg"
      confirmButton={
        isLoading
          ? {
              text: deleteParentDialog.confirmButton,
              disabled: true,
              loading: true,
            }
          : deleteParentDialog.confirmButton
      }
      cancelButton={
        isLoading
          ? { text: deleteParentDialog.cancelButton, disabled: true }
          : deleteParentDialog.cancelButton
      }
      onConfirm={handleDeleteTutor}
      onCancel={props.onClose}
      headerAction={{
        icon: <CloseIcon />,
        title: commonTranslation.close,
        onClick: props.onClose,
        disabled: isLoading,
      }}
    />
  );
};

export default DeleteTutorModel;
