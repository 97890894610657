import React from "react";
import { useExternalTutors } from "./context/ExternalTutorsContext";
import TutorsTable from "./components/TutorsTable/TutorsTable";
import "./ExternalTutors.scss";
import { Button, Flex, ParticipantAddIcon } from "@fluentui/react-northstar";
import AddExternalTutorModel from "./components/AddExternalTutorModel/AddExternalTutorModel";
import ConfirmModel from "./components/ConfirmModel/ConfirmModel";
import { useTranslation } from "react-i18next";

const ExternalTutors = () => {
  const { isLoading, setIsOpenAddExternalTutorsModel } = useExternalTutors();
  const { t } = useTranslation();

  const translation = t("booking").externalTutors;
  return (
    <>
      <Flex column className="external-tutors" gap="gap.medium">
        <h1 className="Heading-settings">{translation.heading}</h1>
        <Flex hAlign="end" className="add-tutors-button">
          <Button
            content={translation.addExternalTutor}
            primary
            icon={<ParticipantAddIcon />}
            disabled={isLoading}
            onClick={() => setIsOpenAddExternalTutorsModel(true)}
          />
        </Flex>
        <TutorsTable />
      </Flex>
      <AddExternalTutorModel />
      <ConfirmModel />
    </>
  );
};

export default ExternalTutors;
