import React, { useState } from "react";
import { DefaultButton, Stack, TextField } from "@fluentui/react";
import { withTranslation } from "react-i18next";
import { CloseIcon, Dialog, Flex } from "@fluentui/react-northstar";

import "./CreateRubric.css";
import { Grid } from "@mui/material";

const CreateRubricSection = ({
  rubricData,
  setRubricData,
  havePoints,
  selectedLevels,
  setCurrentAssignmentData,
  rubricTitle,
  setRubricTitle,
  isValidateFields,
  t,
  props,
}) => {
  const [deleteRubricItem, setDeleteRubricItem] = useState({
    id: null,
    isOpen: false,
  });

  const translation = t("assignment").body;
  const rubricTranslation = translation.rubricPage;

  const getLargestId = (data) => {
    let largestId = 1;
    data.forEach((item) => {
      if (item.id > largestId) {
        largestId = item.id;
      }
    });
    return largestId;
  };

  const onValueChange = ({ parentId, id, element, field, value }) => {
    setRubricData((prevRubricData) =>
      prevRubricData.map((item) => {
        if (element?.toLowerCase() === "parent") {
          if (item.id === id)
            return {
              ...item,
              [field]: value,
              havePoints: !!havePoints,
              isChanged: true,
            };
          else return { ...item, havePoints: !!havePoints };
        } else if (
          element?.toLowerCase() === "child" &&
          item.levels?.length &&
          parentId === item.id
        ) {
          return {
            ...item,
            levels: item.levels.map((data) => {
              if (data.id === id) {
                return { ...data, [field]: value, isChanged: true };
              } else {
                return data;
              }
            }),
          };
        } else return item;
      })
    );
  };

  const duplicateBtnHandler = ({ id, index }) => {
    rubricData.splice(index + 1, 0, {
      ...rubricData[index],
      id: getLargestId(rubricData) + 1,
    });
    setRubricData([...rubricData]);
  };

  const deleteBtnHandler = ({ id }) => {
    setRubricData((prevRubricData) =>
      prevRubricData.filter((item) => item.id !== id)
    );
    setCurrentAssignmentData((prevData) => ({
      ...prevData,
      isChanged: true,
      rubric: {
        ...prevData.rubric,
        isRubricItemDeleted: true,
      },
    }));
  };

  const addMoreCriteriaBtnHandler = () => {
    let levels = [];
    for (let i = 0; i < selectedLevels; i++) {
      levels.push({
        id: i + 1,
        levelTitle: "",
        description: "",
        element: "child",
        points: 0,
      });
    }
    setRubricData((prevRubricData) => [
      ...prevRubricData,
      {
        id: getLargestId(rubricData) + 1,
        title: "",
        description: "",
        element: "parent",
        levels: levels,
        havePoints: !!havePoints,
      },
    ]);
  };

  const clearAllBtnHandler = ({ parentId, id }) => {
    setRubricData((prevRubricData) => {
      return [
        ...prevRubricData.map((item) => {
          if (item.id === parentId) {
            return {
              ...item,
              levels: [
                ...item.levels?.map((data) => {
                  if (data.id === id) {
                    return {
                      ...data,
                      isChanged: true,
                      levelTitle: "",
                      description: "",
                      points: 0,
                    };
                  } else {
                    return data;
                  }
                }),
              ],
            };
          } else {
            return item;
          }
        }),
      ];
    });
  };

  const getLevelPlaceholder = (index) => {
    switch (index) {
      case 0:
        return rubricTranslation.excellent;
      case 1:
        return rubricTranslation.aboveAverage;
      case 2:
        return rubricTranslation.belowAverage;
      case 3:
        return rubricTranslation.needImprovements;
      default:
        return rubricTranslation.levelName;
    }
  };

  return (
    <>
      <Dialog
        open={deleteRubricItem.isOpen}
        onConfirm={() => {
          deleteBtnHandler({ id: deleteRubricItem.id });
          setDeleteRubricItem({ id: null, isOpen: false });
        }}
        confirmButton={rubricTranslation.deleteConfirm}
        header={rubricTranslation.deleteConfirmation}
        cancelButton={rubricTranslation.deleteCancel}
        onCancel={() => {
          setDeleteRubricItem({ id: null, isOpen: false });
        }}
        headerAction={{
          icon: <CloseIcon />,
          title: translation.close,
          onClick: () => {
            setDeleteRubricItem({ id: null, isOpen: false });
          },
        }}
      />

      <div
        className="create-rubric-section ms-Grid"
        dir="ltr"
        style={{
          padding: props.isMobileView ? "0 15px 15px 20px" : "15px",
        }}
      >
        <div>
          <Flex
            style={{
              marginTop: props.isMobileView ? "10px" : "0",
              marginBottom: props.isMobileView ? "10px" : "0",
              marginLeft:
                props.isTabletView && !props.isMobileView ? "36px" : "0px",
            }}
          >
            <TextField
              value={rubricTitle}
              onChange={(e, value) => {
                setRubricTitle(value);
              }}
              style={{
                background: "#F5F5F5",
                borderRadius: "5px",
              }}
              styles={{
                root: {
                  width: "100%",
                  maxWidth: !props.isMobileView && "300px",
                },
              }}
              placeholder={rubricTranslation.rubricBankTitleHeader}
              borderless={true}
              label={rubricTranslation.rubricBankTitleHeader}
              required
              errorMessage={
                isValidateFields
                  ? !rubricTitle.replace(/\s/g, "")?.length && rubricData.length
                    ? rubricTranslation.rubricBankTitleValidation
                    : ""
                  : ""
              }
            />
          </Flex>
        </div>
        <div className="create-rubric-section-container">
          {rubricData?.map((data, index) => {
            return (
              <div key={data.id}>
                <Stack
                  horizontal
                  tokens={{ childrenGap: 15 }}
                  style={{ flexWrap: "wrap" }}
                >
                  <Stack
                    style={{ width: props.isTabletView ? "100%" : "auto" }}
                  >
                    <div
                      className="ms-depth-4 main-rubric"
                      style={{
                        margin: props.isMobileView && "2px",
                        maxWidth:
                          props.isTabletView && !props.isMobileView
                            ? "90%"
                            : props.isMobileView
                            ? "100%"
                            : "350px",
                        marginLeft:
                          props.isTabletView && !props.isMobileView
                            ? "36px"
                            : props.isMobileView
                            ? "2px"
                            : "0px",
                      }}
                    >
                      <div>
                        <TextField
                          value={data.title}
                          name="title"
                          className="create-rubric-input-text"
                          style={{
                            backgroundColor: "#f3f2f1",
                          }}
                          placeholder={rubricTranslation.criteriaName}
                          onChange={(e, v) =>
                            onValueChange({
                              id: data.id,
                              element: data.element,
                              field: "title",
                              value: v,
                            })
                          }
                        />
                      </div>
                      <div
                        style={{
                          marginTop: 15,
                        }}
                      >
                        <TextField
                          grow
                          value={data.description}
                          name="description"
                          multiline
                          className="create-rubric-input-text"
                          style={{
                            backgroundColor: "#f3f2f1",
                          }}
                          placeholder={rubricTranslation.description}
                          resizable={false}
                          onChange={(e, v) =>
                            onValueChange({
                              id: data.id,
                              element: data.element,
                              field: "description",
                              value: v,
                            })
                          }
                        />
                      </div>
                      <div
                        style={{
                          marginTop: 15,
                        }}
                      >
                        <div style={{ display: "flex", justifyContent: "end" }}>
                          <DefaultButton
                            text={rubricTranslation.duplicate}
                            allowDisabledFocus
                            style={{
                              border: "none",
                              color: "blue",
                            }}
                            onClick={() =>
                              duplicateBtnHandler({ id: data.id, index })
                            }
                          />

                          <DefaultButton
                            text={translation.delete}
                            allowDisabledFocus
                            style={{ border: "none", color: "red" }}
                            onClick={() => {
                              setDeleteRubricItem({
                                id: data.id,
                                isOpen: true,
                              });
                            }}
                          />
                        </div>
                      </div>
                    </div>
                  </Stack>

                  {props.isTabletView && !props.isMobileView ? (
                    <Grid
                      container
                      spacing={2}
                      style={{ justifyContent: "center" }}
                    >
                      {data?.levels?.map((childData, index) => {
                        return (
                          <Grid
                            xs={6}
                            style={{
                              padding: "20px",
                              boxShadow:
                                "0 1.6px 3.6px 0 rgba(0,0,0,.132), 0 0.3px 0.9px 0 rgba(0,0,0,.108)",
                              marginTop: "30px",
                              marginBottom: "30px",
                              borderRadius: "5px",
                              marginRight: "15px",
                              maxWidth: "45%",
                            }}
                          >
                            <div>
                              <TextField
                                value={childData.levelTitle}
                                className="create-rubric-input-text"
                                style={{
                                  backgroundColor: "#f3f2f1",
                                }}
                                placeholder={getLevelPlaceholder(index)}
                                onChange={(e, v) =>
                                  onValueChange({
                                    parentId: data.id,
                                    id: childData.id,
                                    element: childData.element,
                                    field: "levelTitle",
                                    value: v,
                                  })
                                }
                              />
                            </div>
                            <div
                              style={{
                                marginTop: 15,
                              }}
                            >
                              {havePoints && (
                                <TextField
                                  value={childData.points || 0}
                                  className="create-rubric-input-text"
                                  style={{
                                    backgroundColor: "#f3f2f1",
                                  }}
                                  placeholder={rubricTranslation.points}
                                  onChange={(e, v) =>
                                    onValueChange({
                                      parentId: data.id,
                                      id: childData.id,
                                      element: childData.element,
                                      field: "points",
                                      value: Number(v) || 0,
                                    })
                                  }
                                />
                              )}
                            </div>
                            <div
                              style={{
                                marginTop: 15,
                              }}
                            >
                              <TextField
                                grow
                                value={childData.description}
                                multiline
                                className="create-rubric-input-text"
                                resizable={false}
                                style={{
                                  backgroundColor: "#f3f2f1",
                                }}
                                placeholder={rubricTranslation.description}
                                onChange={(e, v) =>
                                  onValueChange({
                                    parentId: data.id,
                                    id: childData.id,
                                    element: childData.element,
                                    field: "description",
                                    value: v,
                                  })
                                }
                              />
                            </div>
                            <div
                              style={{
                                marginTop: 15,
                              }}
                            >
                              <div
                                style={{
                                  display: "flex",
                                  justifyContent: "end",
                                }}
                              >
                                <DefaultButton
                                  text={rubricTranslation.clearAll}
                                  allowDisabledFocus
                                  style={{ border: "none", color: "red" }}
                                  onClick={() =>
                                    clearAllBtnHandler({
                                      parentId: data.id,
                                      id: childData.id,
                                    })
                                  }
                                />
                              </div>
                            </div>
                          </Grid>
                        );
                      })}
                    </Grid>
                  ) : (
                    <Stack
                      horizontal
                      style={{
                        flexWrap: "wrap",
                        marginLeft: props.isMobileView ? "0px" : "15px",
                      }}
                    >
                      {data?.levels?.map((childData, index) => {
                        return (
                          <div
                            key={`${childData.id}${index}`}
                            className="ms-depth-4 child-rubric"
                            style={{
                              width: props.isMobileView ? "100%" : "250px",
                              padding: props.isMobileView ? "20px" : "20px",
                              margin: props.isMobileView && "2px",
                              marginTop: props.isMobileView && "15px",
                            }}
                          >
                            <div>
                              <TextField
                                value={childData.levelTitle}
                                className="create-rubric-input-text"
                                style={{
                                  backgroundColor: "#f3f2f1",
                                }}
                                placeholder={getLevelPlaceholder(index)}
                                onChange={(e, v) =>
                                  onValueChange({
                                    parentId: data.id,
                                    id: childData.id,
                                    element: childData.element,
                                    field: "levelTitle",
                                    value: v,
                                  })
                                }
                              />
                            </div>
                            <div
                              style={{
                                marginTop: 15,
                              }}
                            >
                              {havePoints && (
                                <TextField
                                  value={childData.points || 0}
                                  className="create-rubric-input-text"
                                  style={{
                                    backgroundColor: "#f3f2f1",
                                  }}
                                  placeholder={rubricTranslation.points}
                                  onChange={(e, v) =>
                                    onValueChange({
                                      parentId: data.id,
                                      id: childData.id,
                                      element: childData.element,
                                      field: "points",
                                      value: Number(v) || 0,
                                    })
                                  }
                                />
                              )}
                            </div>
                            <div
                              style={{
                                marginTop: 15,
                              }}
                            >
                              <TextField
                                grow
                                value={childData.description}
                                multiline
                                className="create-rubric-input-text"
                                resizable={false}
                                style={{
                                  backgroundColor: "#f3f2f1",
                                }}
                                placeholder={rubricTranslation.description}
                                onChange={(e, v) =>
                                  onValueChange({
                                    parentId: data.id,
                                    id: childData.id,
                                    element: childData.element,
                                    field: "description",
                                    value: v,
                                  })
                                }
                              />
                            </div>
                            <div
                              style={{
                                marginTop: 15,
                              }}
                            >
                              <div
                                style={{
                                  display: "flex",
                                  justifyContent: "end",
                                }}
                              >
                                <DefaultButton
                                  text={rubricTranslation.clearAll}
                                  allowDisabledFocus
                                  style={{ border: "none", color: "red" }}
                                  onClick={() =>
                                    clearAllBtnHandler({
                                      parentId: data.id,
                                      id: childData.id,
                                    })
                                  }
                                />
                              </div>
                            </div>
                          </div>
                        );
                      })}
                    </Stack>
                  )}
                </Stack>
              </div>
            );
          })}
          <div className="ms-Grid-row add-more-criteria-section">
            <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12">
              <DefaultButton
                text={rubricTranslation.addMoreCriteria}
                className="ms-depth-8"
                allowDisabledFocus
                style={{
                  borderRadius: "5px",
                  marginLeft:
                    props.isTabletView && !props.isMobileView ? "36px" : "0px",
                }}
                onClick={addMoreCriteriaBtnHandler}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default withTranslation()(CreateRubricSection);
