import React, { useEffect, useMemo, useState } from "react";
import styled from "styled-components";
import axios from "axios";
import moment from "moment";
import {
  CallVideoIcon,
  CheckmarkCircleIcon,
  CloseIcon,
  Dialog,
  EditIcon,
  EyeIcon,
  EyeSlashIcon,
  Flex,
  Loader,
  LocationIcon,
  MicIcon,
  TagIcon,
  Text,
  UserFriendsIcon,
} from "@fluentui/react-northstar";
import { Button, Row, Col } from "antd";
import { Button as FluentUIButton } from "@fluentui/react-northstar";
import { toast } from "react-toastify";
import { withTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";

import Header from "../../components/Header/Header";
import { ResponsiveImage } from "../../components/blocks/Images";
import SpeakerWidget from "../../components/widgets/SpeakerWidget";
import { NotificationAlert } from "../../components/Notification/Notification";
import EventModal from "../../components/widgets/EventModal";
import CustomProgressIndicator from "../../components/Loader/CustomProgressIndicator";
import ShareEvent from "./Components/ShareEvent";
import AttachmentOption from "./Components/AttachmentOption/AttachmentOption";

import "./event.css";
import { IconButton } from "@fluentui/react";
import Swiper from "./Components/Swiper/Swiper";

const url = process.env.REACT_APP_EP_URL;

const EventHead = styled.div`
  display: flex;
  flex-direction: column;
  align-items: left;
  background: linear-gradient(
      94.13deg,
      rgba(58, 0, 174, 0.8) 29.7%,
      rgba(94, 23, 235, 0.21) 105.54%
    ),
    url(.jpg);
  background: ${(props) =>
    `url(${
      (!!props.coverImage && props.coverImage) ||
      "linear-gradient(94.13deg, rgba(58, 0, 174, 0.8) 29.7%, rgba(94, 23, 235, 0.21) 105.54%), url(.jpg)"
    })`};
  background-repeat: no-repeat;
  background-size: 100% !important;
  position: relative;
`;

const HeadTitle = styled.div`
  padding-top: 80px;
  padding-bottom: 64px;
  padding-left: 32px;
  padding-right: 32px;
  font-style: normal;
  font-weight: 700;
  font-size: 22px;
  line-height: 40px;
  color: #ffffff;
  text-align: left;
`;

const HeadBottom = styled.div`
  display: flex;
  align-items: center;
  background: rgba(0, 0, 0, 0.6);
  width: 100%;
  height: auto;
  padding: 10px 32px;
  p {
    font-family: "Karla";
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
    color: #fff;
    margin: 0;
    margin-left: 10px;
  }
  img {
    width: 16px;
    height: 16px;
  }
`;

const CategoryButton = styled(Button)`
  max-width: 250px;
  span {
    width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
  @media screen and (max-width: 280px) {
    max-width: 150px;
  }
`;
const EventTagText = styled(Text)`
  max-width: 250px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  display: block;
  @media screen and (max-width: 280px) {
    max-width: 150px;
  }
`;

const DetailTitle = styled.div`
  font-style: normal;
  font-weight: 600;
  font-size: 22px;
  line-height: 32px;
  color: #242424;
`;

const DetailDesc = styled.div`
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  color: #424242;
  margin-top: 8px;
  max-width: 750px;
  min-height: 70px;
  max-height: calc(100vh - 726px);
  overflow: auto;
  @media screen and (max-width: 813px) {
    max-height: calc(100vh - 736px);
  }
`;

const CustomButton = styled(Button)`
  width: 164px;
  height: 72px;
  background: #494b83;
  box-shadow: 0px 0.3px 0.9px rgba(0, 0, 0, 0.07),
    0px 1.6px 3.6px rgba(0, 0, 0, 0.11);
  border-radius: 4px;
  text-align: center;
  margin: auto;
  p {
    margin: 0px;
  }
  &:hover {
    color: black;
  }
`;
const CustomFluentUIButton = styled(FluentUIButton)`
  /* max-width: 164px;
  width: 100%; */
  width: 150px;
  height: 72px;
  text-align: center;
  // margin: auto;
  p {
    margin: 0px;
  }
  &:hover {
    color: black;
  }
  @media screen and (max-width: 230px) {
    width: 130px;
  }
`;

const CancelRegistrationButton = styled(FluentUIButton)`
  margin-bottom: 10px;
  padding: 0;
`;

const RegistrationClosingSection = styled(Flex)`
  font-size: 18px;
  align-items: center;
  column-gap: 5px;
  span {
    font-weight: 600;
    margin-left: 3px;
  }
  @media screen and (max-width: 425px) {
    font-size: 16px;
  }
`;

const RegistrationInfoWrapper = styled(Flex)`
  row-gap: 10px;
  margin: 10px 0;
  .registration-date-statictics {
    row-gap: 10px;
  }
`;

const RegistrationStatusText = styled.p`
  font-size: 18px;
  font-weight: 600;
  margin-bottom: 0;
`;

const RegisteredNumberText = styled(Flex)`
  font-size: 18px;
  align-items: center;
  column-gap: 5px;
  span {
    font-weight: 600;
    margin-left: 3px;
  }
  @media screen and (max-width: 425px) {
    font-size: 16px;
  }
`;

const EventDetails = (props) => {
  const [accDlg, setAccDlg] = useState(false);
  const [eventData, setEventData] = useState({});
  const [tags, setTags] = useState([]);
  const [categories, setCategories] = useState([]);
  const [isSpeakers, setIsSpeakers] = useState(true);
  const [currentCategories, setCurrentCategories] = useState([]);
  const [eventTags, setEventTags] = useState([]);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const { search } = useLocation();

  const { isSiteEmbeded, t } = props;

  const translation = t("events");
  const eventsTranslation = translation.body.eventDetail;
  const commonTranslation = translation.common;

  const getTextContentFromUrl = (url) => {
    if (url) {
      try {
        return fetch(url)
          .then((response) => response.text())
          .then((data) => data);
      } catch (error) {
        return "";
      }
    } else {
      return "";
    }
  };

  const getLinkContentFromUrl = (url) => {
    if (url) {
      try {
        return fetch(url)
          .then((response) => response.text())
          .then((data) => data.split(","));
      } catch (error) {
        return "";
      }
    } else {
      return "";
    }
  };

  const formatFileLinks = (url) => {
    if (url) {
      try {
        const formattedUrls = url.split(",");

        return Array.isArray(formattedUrls) ? formattedUrls : [];
      } catch (error) {
        return "";
      }
    } else {
      return "";
    }
  };

  const formatEventData = async (eventData) => {
    try {
      const eventAttachments = eventData?.EventAttachments;

      if (Array.isArray(eventAttachments) && eventAttachments.length) {
        const textInfo = await getTextContentFromUrl(
          eventAttachments?.find((item) => item.type == "text")?.info
        );
        const linkInfo = await getLinkContentFromUrl(
          eventAttachments?.find((item) => item.type == "link")?.info
        );

        const fileInfo = formatFileLinks(
          eventAttachments?.find((item) => item.type == "file")?.info
        );

        const getContentByType = (type) =>
          eventAttachments?.find((item) => item.type == type);

        const attachments = [
          {
            ...(getContentByType("text") ?? {}),
            type: "text",
            info: textInfo,
          },
          {
            ...(getContentByType("voice") ?? {}),
            type: "voice",
            info: getContentByType("voice")?.info || "",
          },
          {
            ...(getContentByType("video") ?? {}),
            type: "video",
            info: getContentByType("voice")?.info || "",
          },
          {
            ...(getContentByType("link") ?? {}),
            type: "link",
            info: linkInfo,
          },
          {
            ...(getContentByType("file") ?? {}),
            type: "file",
            info: Array.isArray(fileInfo) && fileInfo.length ? fileInfo : [],
          },
        ];

        eventData.attachments = attachments;
      } else {
      }
    } catch (error) {
      console.log(error);
    }
    return eventData;
  };

  const getEvent = () => {
    setIsLoading(true);
    const teacherId = props.user.mail;
    axios
      .get(
        `${url}/api/events/${teacherId}${props.user.slug}&id=${props.match.params.id}`
      )
      .then(({ data: { result } }) => formatEventData(result))
      .then((data) => {
        setEventData(data);
      })
      .catch((err) => {
        toast.error(err?.response?.data?.message);
        console.log(err);
        setEventData({});
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const registerEvent = (data, status) => {
    setIsSubmitting(true);
    const params = {
      eventId: data.id,
      teacherId: props.user.mail,
      status: status,
    };
    axios
      .post(`${url}/api/registrations${props.user.slug}`, params, {
        headers: {
          Authorization: `Bearer ${props.user.accessToken}`,
        },
      })
      .catch((err) => {
        toast.error(err?.response?.data?.message);
        console.log(err);
      })
      .finally(() => {
        getEvent();
        setIsSubmitting(false);
      });
  };

  const getCategories = () => {
    axios
      .get(`${url}/api/category/all${props.user.slug}`)
      .then(({ data: { result } }) => {
        setCategories(result);
      })
      .catch((err) => {
        toast.error(err?.response?.data?.message);
        console.log(err.response);
      });
  };

  const getTags = () => {
    axios
      .get(`${url}/api/tag/all${props.user.slug}`)
      .then(({ data: { result } }) => {
        setTags(result);
      })
      .catch((err) => {
        toast.error(err?.response?.data?.message);
        console.log(err.response);
      });
  };

  useEffect(() => {
    getTags();
    getCategories();
    getEvent();
  }, [props]);

  const isEventLive = useMemo(() => {
    return (
      moment(eventData.startDate).isValid &&
      moment(eventData.endDate).isValid &&
      moment().isSameOrAfter(
        moment(moment.utc(eventData.startDate).local().format())
      ) &&
      moment().isSameOrBefore(
        moment(moment.utc(eventData.endDate).local().format())
      )
    );
  }, [eventData]);

  useEffect(() => {
    if (eventData.categories) {
      let category = [];
      if (typeof eventData.categories === "string") {
        category = [eventData.categories];
      } else if (Array.isArray(eventData.categories)) {
        category = eventData.categories;
      }
      setCurrentCategories(
        category.map((c) => {
          const [temp] = categories.filter((s) => s.id === c);
          return temp;
        })
      );
    } else {
      setCurrentCategories([]);
    }
  }, [eventData, categories]);

  useEffect(() => {
    if (eventData.tags) {
      let tempTags = [];
      if (typeof eventData.tags === "string") {
        tempTags = [eventData.tags];
      } else if (Array.isArray(eventData.tags)) {
        tempTags = eventData.tags;
      }
      setEventTags(
        tempTags.map((c) => {
          const [temp] = tags.filter((s) => s.id === c);
          return temp;
        })
      );
    } else {
      setEventTags([]);
    }
  }, [eventData, tags]);

  const formattedMeetingURL = useMemo(() => {
    return !!eventData.isMSTeamMeeting
      ? eventData.meetingURL
      : !!eventData.isGoogleMeetMeeting
      ? eventData.googleMeetURL
      : null;
  }, [eventData]);

  const coverImage = !!eventData.coverImage
    ? `${eventData.coverImage}${props.user?.SASToken}`
    : "/Images/Events/unsplash_3l3RwQdHRHg.png";

  const shareEventHandler = async () => {
    const eventId = eventData?.id;
    const eventUrl = `${process.env.REACT_APP_URI}/event/details/${eventId}`;
    if (eventId) {
      if (navigator.share) {
        try {
          await navigator.share({
            text: translation.common.event,
            title: translation.common.event,
            url: eventUrl,
          });
        } catch {
          // navigator.clipboard.writeText(eventUrl);
          // NotificationAlert("Event link copied successfully", "success");
        }
      } else {
        navigator.clipboard.writeText(eventUrl);
        NotificationAlert(eventsTranslation.eventCopied, "success");
      }
    }
  };

  const backButtonHandler = () => {
    const query = new URLSearchParams(search);
    let page = query.get("events");
    const bundleId = query.get("bundleId") || "";
    if (page) {
      switch (page) {
        case "cancel":
        case "discover":
          return props.history.push(`/events/discover`);
        case "favourite":
          return props.history.push(`/events/favourites`);
        case "event":
          return props.history.push(`/events/my-events`);
        case "registered":
          return props.history.push(`/events/registrations`);
        case "attended":
          return props.history.push(`/events/registrations?events=attended`);
        case "bundleEvent":
          return props.history.push(`/events/bundles/${bundleId}`);
        default:
          return props.history.push(`/events/registrations`);
      }
    } else {
      props.history.push(`/events/registrations`);
    }
  };

  return (
    <div className="event-details-wrapper">
      <Dialog
        open={accDlg}
        onConfirm={() => location.reload()}
        confirmButton={translation.common.authExpire.button}
        content={
          <p className="refreshMsg">{translation.common.authExpire.message}</p>
        }
        header={translation.common.authExpire.header}
      />
      {!isSiteEmbeded && (
        <Header
          path="events"
          user={{ ...props.user }}
          logout={props.logout.bind()}
          accessToken={props.user.accessToken}
          getDb={props.getDb.bind()}
          switchDb={props.switchDb}
          selectedExtension={props.selectedExtension}
          setSelectedExtension={props.setSelectedExtension}
        />
      )}

      <CustomProgressIndicator
        isLoading={isLoading || isSubmitting}
        style={{
          progressTrack: {
            backgroundColor: "#ffffff",
          },
        }}
      />
      <div className="wrapper">
        {!isSiteEmbeded && (
          <Flex vAlign="center" style={{ marginBottom: 15 }}>
            <IconButton
              iconProps={{
                iconName: "ChromeBack",
              }}
              style={{ color: "rgb(98 100 167)" }}
              title={commonTranslation.back}
              ariaLabel={commonTranslation.back}
              onClick={backButtonHandler}
            />
            <p className="event-title">Event Details</p>
          </Flex>
        )}
        {eventData && Object.values(eventData).length ? (
          <div>
            <div style={{ paddingBottom: "24px" }}>
              <EventHead coverImage={coverImage}>
                <HeadTitle>{eventData.title}</HeadTitle>
                {eventData?.linkTitle && eventData?.linkURL && (
                  <Button
                    style={{
                      background: "#FF7C48",
                      color: "#FFF",
                      width: "fit-content",
                      position: "absolute",
                      top: "10px",
                      right: "10px",
                      border: "none",
                    }}
                  >
                    <a
                      href={
                        eventData.linkURL?.includes("http")
                          ? eventData.linkURL
                          : `http://${eventData.linkURL}`
                      }
                      target="_blank"
                    >
                      {eventData.linkTitle}
                    </a>
                  </Button>
                )}
                <HeadBottom>
                  <Row wrap={true} gutter={12}>
                    <Col>
                      <div className="flex-row">
                        <ResponsiveImage src="/Images/Events/clock-light.svg" />
                        <p className="text">{`${moment(
                          eventData.startDate
                        ).format("MMM Do")} ${moment(
                          eventData.startDate
                        ).format("hh:mm A")} - ${moment(
                          eventData.endDate
                        ).format("MMM Do")} ${moment(eventData.endDate).format(
                          "hh:mm A"
                        )}`}</p>
                      </div>
                    </Col>
                    {eventData &&
                    (!!eventData.isMSTeamMeeting ||
                      !!eventData.isGoogleMeetMeeting) ? (
                      <Col>
                        <div className="flex-row">
                          <ResponsiveImage src="/Images/Events/map-light.svg" />
                          <p className="text">
                            {!!eventData.isMSTeamMeeting
                              ? eventsTranslation.MSTeamsMeeting
                              : eventsTranslation.GoogleMeetMeeting}
                          </p>
                        </div>
                      </Col>
                    ) : (
                      <></>
                    )}
                    {eventData && eventData.eventType ? (
                      <Col style={{ margin: "0 5px" }}>
                        <div
                          className="flex-row"
                          style={{ alignItems: "center" }}
                        >
                          {eventData.eventType === "VIDEO_CONFERENCE" ? (
                            <CallVideoIcon outline style={{ color: "white" }} />
                          ) : eventData.eventType === "AUDIO" ? (
                            <ResponsiveImage src="/Images/Events/mic-light.svg" />
                          ) : (
                            ""
                          )}

                          <p className="text">
                            {eventData.eventType === "VIDEO_CONFERENCE"
                              ? "Video Conference"
                              : eventData.eventType === "AUDIO"
                              ? "Audio"
                              : ""}
                          </p>
                        </div>
                      </Col>
                    ) : (
                      <></>
                    )}

                    {/* {!eventData.isGoogleMeetMeeting && (
                  <Col>
                    <div className="flex-row">
                      {eventData && eventData.availability === "PRIVATE" ? (
                        <>
                          <EyeSlashIcon style={{ color: "white" }} />
                          <p className="text">
                            {eventsTranslation.privateEvent}
                          </p>
                        </>
                      ) : (
                        <>
                          <EyeIcon style={{ color: "white" }} />
                          <p className="text">
                            {eventsTranslation.publicEvent}
                          </p>
                        </>
                      )}
                    </div>
                  </Col>
                )} */}
                  </Row>
                </HeadBottom>
              </EventHead>
            </div>
            <div>
              <RegistrationInfoWrapper vAlign="center" space="between" wrap>
                <Flex
                  wrap
                  gap="gap.large"
                  vAlign="center"
                  className="registration-date-statictics"
                >
                  {!eventData.isRegistrationClosed &&
                    eventData.registrationCloseDate &&
                    moment(eventData.registrationCloseDate).isValid() &&
                    moment(eventData.registrationCloseDate).isAfter(
                      moment()
                    ) && (
                      <RegistrationClosingSection vAlign="center">
                        <LocationIcon outline />
                        <Flex wrap>
                          {eventsTranslation.registrationClosing}:
                          <span>
                            {moment(eventData.registrationCloseDate).format(
                              "hh:mm A | DD, MMM"
                            )}
                          </span>
                        </Flex>
                      </RegistrationClosingSection>
                    )}

                  {eventData.isLimitedSeats && eventData.maxSeats > 0 ? (
                    <RegisteredNumberText vAlign="center">
                      <UserFriendsIcon outline />
                      <Flex wrap>
                        {eventsTranslation.registered}:{" "}
                        <span>
                          {eventData.attendees?.length || 0} of{" "}
                          {eventData.maxSeats}
                        </span>
                      </Flex>
                    </RegisteredNumberText>
                  ) : (
                    ""
                  )}
                </Flex>
                <Flex>
                  {eventData.teacherId !== props.user?.mail && (
                    <RegistrationStatusText>
                      {!eventData.isRegistered &&
                        (eventData.isRegistrationClosed
                          ? eventsTranslation.registrationClosed
                          : eventsTranslation.registrationOpen)}
                    </RegistrationStatusText>
                  )}
                </Flex>
              </RegistrationInfoWrapper>
            </div>
            <Flex wrap space="between" style={{ rowGap: "15px" }}>
              <Flex gap="gap.small" wrap>
                {currentCategories.length
                  ? currentCategories.map((c, index) => (
                      <Col key={c?.id}>
                        <CategoryButton
                          size="small"
                          style={{
                            background:
                              index % 3 === 0
                                ? `#5E17EB`
                                : index % 3 === 1
                                ? "#ffbe3d"
                                : "#50b5ff",
                            color: "#FFF",
                          }}
                        >
                          {c?.title}
                        </CategoryButton>
                      </Col>
                    ))
                  : null}
              </Flex>
              <Flex>
                {eventTags?.length ? (
                  <Flex wrap vAlign="center" gap="gap.small">
                    <TagIcon outline size="medium" />
                    {eventTags.map((tag, index) => (
                      <Col key={tag?.id}>
                        <EventTagText weight="bold" size="medium">
                          {`${tag?.title}${
                            index === eventTags.length - 1 ? "" : ","
                          }`}
                        </EventTagText>
                      </Col>
                    ))}
                  </Flex>
                ) : null}
              </Flex>
            </Flex>
            <div className="ms-Grid" dir="ltr">
              <div
                className="ms-Grid-row"
                style={{
                  flexWrap: "wrap",
                  marginTop: "15px",
                  columnGap: "15px",
                  rowGap: "15px",
                }}
              >
                <div
                  className="ms-Grid-col ms-sm12 ms-lg7"
                  // style={{ marginBottom: "15px" }}
                >
                  <Flex column>
                    {eventData.description && (
                      <>
                        <DetailTitle>{eventsTranslation.details}</DetailTitle>
                        <DetailDesc>
                          <p
                            dangerouslySetInnerHTML={{
                              __html: JSON.parse(eventData.description),
                            }}
                          />
                        </DetailDesc>
                      </>
                    )}
                    <Flex wrap style={{ rowGap: "15px" }}>
                      <FluentUIButton
                        primary={isSpeakers}
                        text
                        // content="Event Speakers"
                        size="medium"
                        onClick={() => setIsSpeakers(true)}
                        style={{ fontSize: "18px" }}
                      >
                        <Text size="large" weight="semibold">
                          {eventsTranslation.eventSpeakers}
                        </Text>
                      </FluentUIButton>
                      <FluentUIButton
                        text
                        primary={!isSpeakers}
                        size="medium"
                        onClick={() => setIsSpeakers(false)}
                        styles={{ fontSize: "18px" }}
                      >
                        <Text size="large" weight="semibold">
                          {eventsTranslation.attendees}
                        </Text>
                      </FluentUIButton>
                    </Flex>
                    <div style={{ maxWidth: "750px" }}>
                      {isSpeakers ? (
                        eventData.speakers && eventData.teacherData?.length ? (
                          <Swiper
                            className="event-speakers-swiper"
                            itemsLength={eventData.teacherData.length}
                          >
                            {(eventData.teacherData || []).map((d, index) => {
                              return (
                                <Flex
                                  key={index}
                                  style={{ width: "150px", margin: "0 10px" }}
                                >
                                  <SpeakerWidget
                                    data={{
                                      email: d.TeacherEmailID,
                                      type: "Speaker",
                                      UrlPhoto: d.UrlPhoto,
                                    }}
                                  />
                                </Flex>
                              );
                            })}
                          </Swiper>
                        ) : (
                          <Text
                            style={{ margin: "15px 0 0 15px", color: "gray" }}
                            align="center"
                          >
                            {eventsTranslation.noSpeakers}
                          </Text>
                        )
                      ) : eventData.attendees && eventData.attendees?.length ? (
                        <Swiper
                          className="event-attendees-swiper"
                          itemsLength={eventData.attendees.length}
                        >
                          {(eventData.attendees || []).map((d, index) => (
                            <Flex key={index} style={{ width: "150px" }}>
                              <SpeakerWidget
                                data={{
                                  ...d,
                                  email:
                                    d.email || d.type === "Teacher"
                                      ? d.teacherId
                                      : "",
                                }}
                                userToken={props.user.SASToken}
                              />
                            </Flex>
                          ))}
                        </Swiper>
                      ) : (
                        <Text
                          style={{ margin: "15px 0 0 15px", color: "gray" }}
                          align="center"
                        >
                          {eventsTranslation.noRegistrations}
                        </Text>
                      )}
                    </div>
                  </Flex>
                </div>
                <div className="ms-Grid-col ms-sm12 ms-lg5">
                  <Flex column style={{ marginTop: "24px" }}>
                    {eventData?.status?.toLowerCase() !== "draft" && (
                      <Flex fill wrap style={{ columnGap: "10px" }}>
                        {!eventData.isRegistered &&
                          !eventData.isRegistrationClosed &&
                          moment().isSameOrBefore(
                            moment(
                              moment.utc(eventData.endDate).local().format()
                            )
                          ) &&
                          eventData.teacherId !== props.user?.mail && (
                            <CustomFluentUIButton
                              primary
                              disabled={isSubmitting}
                              style={{ color: "#FFF", marginBottom: "10px" }}
                              onClick={() => {
                                registerEvent(eventData, "ATTENDING");
                              }}
                            >
                              <div>
                                <EditIcon />
                                <p>{eventsTranslation.register}</p>
                              </div>
                            </CustomFluentUIButton>
                          )}

                        {eventData.teacherId !== props.user?.mail &&
                        !!eventData.isRegistered &&
                        moment().isSameOrBefore(
                          moment(moment.utc(eventData.endDate).local().format())
                        ) ? (
                          !!formattedMeetingURL && isEventLive ? (
                            <CustomFluentUIButton
                              primary
                              style={{ color: "#FFF", marginBottom: "10px" }}
                              onClick={() => {
                                window.open(formattedMeetingURL);
                              }}
                            >
                              <div>
                                <CallVideoIcon />
                                <p>{eventsTranslation.joinNow}</p>
                              </div>
                            </CustomFluentUIButton>
                          ) : (
                            <Flex column>
                              <CustomFluentUIButton
                                style={{
                                  color: "#FFF",
                                  backgroundColor: "#51c397",
                                }}
                              >
                                <div>
                                  <CheckmarkCircleIcon outline />
                                  <p style={{ marginTop: "5px" }}>
                                    {eventsTranslation.attending}
                                  </p>
                                </div>
                              </CustomFluentUIButton>
                              <EventModal
                                button={({ isOpen, setIsOpen }) => {
                                  return (
                                    <CancelRegistrationButton
                                      text
                                      disabled={isSubmitting}
                                      icon={<CloseIcon size="medium" />}
                                      content={
                                        eventsTranslation.cancelRegistration
                                      }
                                      onClick={() => {
                                        setIsOpen(true);
                                      }}
                                    />
                                  );
                                }}
                                closeOnOutsideClick={false}
                                confirmButton={
                                  eventsTranslation.cancelConfirmation
                                }
                                header={`${eventsTranslation.cancelRegistration}?`}
                                onConfirm={({ setIsOpen }) => {
                                  setIsOpen(false);
                                  registerEvent(eventData, "CANCELLED");
                                }}
                              >
                                {() => {
                                  return (
                                    <Flex>
                                      <Text>
                                        {
                                          eventsTranslation.cancelConfirmationMessage
                                        }
                                      </Text>
                                    </Flex>
                                  );
                                }}
                              </EventModal>
                            </Flex>
                          )
                        ) : null}

                        {moment().isSameOrBefore(
                          moment(moment.utc(eventData.endDate).local().format())
                        ) && eventData.teacherId === props.user?.mail ? (
                          !!eventData.isMSTeamMeeting ? (
                            !!eventData.meetingURL && (
                              <Dialog
                                confirmButton={eventsTranslation.ok}
                                content={
                                  <div>
                                    <Text size="large">
                                      {eventsTranslation.startEventMessage}
                                    </Text>
                                  </div>
                                }
                                onConfirm={() => {
                                  window.open(eventData.meetingURL);
                                }}
                                trigger={
                                  <CustomFluentUIButton
                                    style={{
                                      marginBottom: "10px",
                                    }}
                                  >
                                    <Flex wrap hAlign="center">
                                      {eventData.eventType ===
                                      "VIDEO_CONFERENCE" ? (
                                        <CallVideoIcon
                                          outline
                                          style={{ marginRight: "5px" }}
                                        />
                                      ) : eventData.eventType === "AUDIO" ? (
                                        <MicIcon
                                          outline
                                          style={{ marginRight: "5px" }}
                                        />
                                      ) : (
                                        ""
                                      )}

                                      {eventsTranslation.startEvent}
                                    </Flex>
                                  </CustomFluentUIButton>
                                }
                                cancelButton={translation.common.cancel}
                              />
                            )
                          ) : !!eventData.isGoogleMeetMeeting &&
                            !!eventData.googleMeetURL ? (
                            <CustomFluentUIButton
                              style={{
                                marginBottom: "10px",
                              }}
                              onClick={() => {
                                window.open(eventData.googleMeetURL);
                              }}
                            >
                              <Flex wrap hAlign="center">
                                <CallVideoIcon
                                  outline
                                  style={{ marginRight: "5px" }}
                                />
                                {eventsTranslation.startEvent}
                              </Flex>
                            </CustomFluentUIButton>
                          ) : null
                        ) : null}

                        <ShareEvent
                          eventId={eventData.id}
                          eventTitle={eventData.title}
                          shareButton={({ onShareButtonClickhandler }) => (
                            <CustomFluentUIButton
                              style={{ background: "#FFF" }}
                              onClick={() => {
                                onShareButtonClickhandler();
                              }}
                            >
                              <div>
                                <ResponsiveImage
                                  src="/Images/Events/share.svg"
                                  style={{ width: 15 }}
                                />
                                <p>{eventsTranslation.shareEvent}</p>
                              </div>
                            </CustomFluentUIButton>
                          )}
                          {...props}
                        />

                        {/* <CustomFluentUIButton
                        style={{ background: "#FFF" }}
                        onClick={shareEventHandler}
                      >
                        <div>
                          <ResponsiveImage
                            src="/Images/Events/share.svg"
                            style={{ width: 15 }}
                          />
                          <p>{eventsTranslation.shareEvent}</p>
                        </div>
                      </CustomFluentUIButton> */}
                      </Flex>
                    )}
                    <div className="resources-wrapper">
                      <Text
                        weight="semibold"
                        size="large"
                        content="Resources"
                      />
                      <AttachmentOption
                        eventData={eventData}
                        isEditable={false}
                      />
                    </div>
                  </Flex>
                </div>
              </div>
            </div>
          </div>
        ) : (
          !isLoading && (
            <div
              className="wrapper"
              style={{ textAlign: "center", marginTop: 10 }}
            >
              <ResponsiveImage
                className="events-pana"
                src={"/Images/Events/startled-pana.svg"}
              />
              <h2 style={{ marginTop: 20 }}>
                {eventsTranslation.noEventFound}
              </h2>
            </div>
          )
        )}
      </div>
    </div>
  );
};

export default withTranslation()(EventDetails);
