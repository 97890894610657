import React, { useEffect, useRef, useState } from "react";
import Slider from "react-slick";
import { Button } from "antd";
import { ArrowLeftOutlined, ArrowRightOutlined } from "@ant-design/icons";
import { Tooltip, Box } from "@fluentui/react-northstar";

const Swiper = (props) => {
  const { itemsLength } = props;
  const [index, setIndex] = useState(0);
  const [isShowButtons, setIsShowButtons] = useState(itemsLength > 4);
  const [isMobileView, setIsMobileView] = useState(window.innerWidth <= 767);

  const sliderRef = useRef(null);

  const handleNext = () => {
    if (index < itemsLength - 1) {
      setIndex(index + 1);
      sliderRef.current.slickNext();
    }
  };

  const handlePrev = () => {
    if (index > 0) {
      sliderRef.current.slickPrev();
      setIndex(index - 1);
    }
  };

  const settings = {
    dots: isMobileView,
    infinite: false,
    speed: 500,
    slidesToShow: Math.min(4, itemsLength),
    slidesToScroll: 1,
    afterChange: (current) => setIndex(current),
    responsive: [
      {
        breakpoint: 1160,
        settings: {
          slidesToShow: Math.min(3, itemsLength),
        },
      },
      {
        breakpoint: 1026,
        settings: {
          slidesToShow: Math.min(2, itemsLength),
        },
      },
      {
        breakpoint: 680,
        settings: {
          slidesToShow: 1,
        },
      },
      {
        breakpoint: 639,
        settings: {
          slidesToShow: Math.min(2, itemsLength),
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  };

  useEffect(() => {
    const handleResize = () => {
      setIsMobileView(window.innerWidth <= 767);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <div style={{ display: "flex", alignItems: "center", width: "100%" }}>
      {!isMobileView && isShowButtons && (
        <Tooltip
          trigger={
            <Button
              shape="circle"
              icon={<ArrowLeftOutlined />}
              onClick={handlePrev}
              disabled={index === 0}
            />
          }
          content={"previous"}
        />
      )}
      <Box style={{ width: "100%" }}>
        <Slider
          {...settings}
          ref={sliderRef}
          className={props.className}
          onReInit={() => {
            const currentSlidesToShow = sliderRef?.current?.innerSlider?.props?.slidesToShow || 4;
            setIsShowButtons(itemsLength > currentSlidesToShow);
          }}
        >
          {React.Children.map(props.children, (child, i) => (
            <div key={i}>{child}</div>
          ))}
        </Slider>
      </Box>
      {!isMobileView && isShowButtons && (
        <Tooltip
          trigger={
            <Button
              shape="circle"
              icon={<ArrowRightOutlined />}
              onClick={handleNext}
              disabled={index >= itemsLength - 1}
            />
          }
          content={"next"}
        />
      )}
    </div>
  );
};

export default Swiper;
