import axios from "axios";
import { useEffect, useState } from "react";
import { NotificationAlert } from "../../../../components/Notification/Notification";
import { updateUserGptCredit } from "../../../../Assets/api/api";

const API_URL = process.env.REACT_APP_EP_URL;

const END_POINTS = {
  SINGLE: `generate-event-content`,
  BULK: `generate-bulk-events`,
};

const useMagicBar = ({
  responseHandler,
  isOpen,
  user,
  onCloseHandler,
  type,
  setUser,
}) => {
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [language, setLanguage] = useState("English");

  const [title, setTitle] = useState("");

  const [isOpenValidationDialog, setIsOpenValidationDialog] = useState(false);
  const [prompList, setPrompList] = useState([]);

  useEffect(() => {
    setTitle("");
    setLanguage("English");
  }, [isOpen]);

  useEffect(() => {
    fetchGptPrompt();
  }, []);

  const languages = [
    "English",
    "Español",
    "Português",
    "Français",
    "Deutsch",
    "हिन्दी",
  ];

  const handleUpdateUserCredit = async () => {
    try {
      const payload = {
        credit: user.credit - 1,
        mail: user.mail,
        role: user.role,
      };
      await updateUserGptCredit(payload, user.slug, user.accessToken);
      setUser((prev) => ({
        ...prev,
        credit: user.credit - 1,
      }));
    } catch (error) {
      console.log("error from handleUpdateUserCredit", error.message);
    }
  };

  async function generatePrompt(event) {
    // Alert user about adding text

    if (!title) return setIsOpenValidationDialog(true);

    setIsLoading(true);

    axios
      .post(`${API_URL}/api/gpt/${END_POINTS[type]}${user.slug}`, {
        title,
        language,
      })
      .then(async ({ data }) => {
        if (data?.data) {
          handleUpdateUserCredit();
          responseHandler(data.data, { title, language });
          onCloseHandler();
        }
      })
      .catch((err) => {
        NotificationAlert("Timeout error, Please try again later", "warning");
        console.log("GptError:", err);
      })
      .finally(() => {
        setIsLoading(false);
      });
  }

  const fetchGptPrompt = async () => {
    try {
      const { data } = await axios(
        `${API_URL}/api/gpt/get-gpt-prompts${user.slug}&category=event`
      );
      setPrompList(data?.data?.length ? data.data : []);
    } catch (error) {
      console.log(error);
    }
  };

  return {
    generatePrompt,
    languages,

    isLoading,
    setIsLoading,
    language,
    setLanguage,

    title,
    setTitle,

    isOpenValidationDialog,
    setIsOpenValidationDialog,
    prompList,
  };
};

export default useMagicBar;
