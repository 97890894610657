import {
  Button,
  ExcelIcon,
  FilesUploadIcon,
  Flex,
  Text,
} from "@fluentui/react-northstar";
import React from "react";
import { useTranslation } from "react-i18next";
const ImportHomeStep = ({
  onFileSelectHandler,
  selectedFile,
  downloadSampleFile,
}) => {
  const { t } = useTranslation();

  const translation = t("events");
  const eventsTranslation = translation.body.bundles.importFromExcel;
  const multipleParentsDialog = t("setting").parent.multipleParentsDialog;

  return (
    <Flex column>
      <Flex hAlign="center">
        <ExcelIcon size={"largest"} />
      </Flex>
      <Flex column gap="gap.small">
        <Text as="h1" weight="bold" align="center" style={{ margin: 0 }}>
          {multipleParentsDialog.title}
        </Text>
        <Flex hAlign="start">
          <ul className="text-left">
            {multipleParentsDialog.infoMessages.map((info, i) => {
              return <li key={`info-${i}`}>{info}</li>;
            })}
          </ul>
        </Flex>
        <Flex hAlign="center" vAlign="center" gap="gap.small">
          <Button
            icon={<ExcelIcon />}
            content={multipleParentsDialog.downloadSample}
            onClick={downloadSampleFile}
          />
          <input
            type="file"
            id="file-lsn"
            name="file1"
            onChange={(event) => {
              onFileSelectHandler(event.target.files?.[0]);
            }}
            style={{ display: "none" }}
            accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
          />
          <Button
            icon={<FilesUploadIcon />}
            content={
              selectedFile ? selectedFile.name : multipleParentsDialog.selectFile
            }
            onClick={() => document.getElementById("file-lsn").click()}
            title={
              selectedFile ? selectedFile.name : multipleParentsDialog.selectFile
            }
            style={{
              maxWidth: "209px",
            }}
          />
        </Flex>
      </Flex>
    </Flex>
  );
};

export default ImportHomeStep;
