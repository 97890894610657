import React, { useState, useEffect } from "react";

import {
  DefaultButton,
  TextField,
  ComboBox,
  SelectableOptionMenuItemType,
} from "@fluentui/react";
import { Button, CloseIcon, Flex } from "@fluentui/react-northstar";
import { withTranslation } from "react-i18next";
import { Dropdown } from "@fluentui/react/lib/Dropdown";
import axios from "axios";

import { newRubricData } from "../rubricBankConstant";

import RubricConfirmationDialog from "./RubricConfirmationDialog";
import { NotificationAlert } from "../../../../../../components/Notification/Notification";
import { AlertDialog } from "../../../../../../components/Dialog/AlertDialog";

const levelOptions = [
  { key: 1, text: 1 },
  { key: 2, text: 2 },
  { key: 3, text: 3 },
  { key: 4, text: 4 },
  { key: 5, text: 5 },
  { key: 6, text: 6 },
];

const url = process.env.REACT_APP_EP_URL;

const RubricSection = ({
  data: selectedRubricData,
  isNew,
  user,
  onSubmit,
  onCancel,
  lessonOptions,
  isLessonLoading,
  t,
}) => {
  const translation = t("assignment").body;
  const rubricTranslation = translation.rubricPage;

  const [rubricData, setRubricData] = useState([]);
  const [selectedLevels, setSelectedLevels] = useState(0);

  const [rubric, setRubric] = useState(null);
  const [loading, setLoading] = useState({
    isSaving: false,
    isDeleting: false,
    isDeleteModalOpen: false,
  });

  const [rubricTitle, setRubricTitle] = useState("");

  const [isValidateFields, setIsValidateFields] = useState(false);
  const [selectedLessons, setSelectedLessons] = useState([]);

  // array of selectable options for convenience
  const selectableOptions = lessonOptions.filter(
    (option) =>
      (option.itemType === SelectableOptionMenuItemType.Normal ||
        option.itemType === undefined) &&
      !option.disabled
  );

  const onChange = (event, option, index, value) => {
    const selected = option?.selected;
    const currentSelectedOptionKeys = selectedLessons.filter(
      (key) => key !== "selectAll"
    );
    const selectAllState =
      currentSelectedOptionKeys.length === selectableOptions.length;

    if (option) {
      if (option?.itemType === SelectableOptionMenuItemType.SelectAll) {
        selectAllState
          ? setSelectedLessons([])
          : setSelectedLessons([
              "selectAll",
              ...selectableOptions.map((o) => o.key),
            ]);
      } else {
        const updatedKeys = selected
          ? [...currentSelectedOptionKeys, option.key]
          : currentSelectedOptionKeys.filter((k) => k !== option.key);
        if (updatedKeys.length === selectableOptions.length) {
          updatedKeys.push("selectAll");
        }
        setSelectedLessons(updatedKeys);
      }
    }
  };

  useEffect(() => {
    if (!isNew) {
      setRubricData(
        selectedRubricData?.rubricItems?.length
          ? selectedRubricData.rubricItems
          : []
      );
      setSelectedLevels(
        selectedRubricData?.rubricItems?.length
          ? selectedRubricData?.rubricItems[0].levels?.length ?? 0
          : 0
      );
      setRubric(selectedRubricData ? selectedRubricData : null);
      setRubricTitle(selectedRubricData?.rubricTitle ?? "");

      setSelectedLessons(
        selectedRubricData?.class?.length
          ? lessonOptions?.length - 1 === selectedRubricData.class?.length
            ? ["selectAll", ...selectedRubricData.class]
            : selectedRubricData.class
          : []
      );
    } else {
      setRubricData(newRubricData.rubricItems);
      setSelectedLevels(
        newRubricData.rubricItems?.length
          ? newRubricData.rubricItems[0].levels?.length ?? 0
          : 0
      );
      setRubric(newRubricData);
      setSelectedLessons([]);
    }
  }, [selectedRubricData, isNew]);

  const getNewCustomId = (data) => {
    return Math.random() * 10000 + 1;
  };

  const onValueChange = ({ parentId, id, element, field, value }) => {
    setRubricData((prevRubricData) =>
      prevRubricData.map((item) => {
        if (element?.toLowerCase() === "parent") {
          if (item.id === id) {
            return {
              ...item,
              [field]: value,
              isChanged: true,
            };
          } else {
            return item;
          }
        } else if (
          element?.toLowerCase() === "child" &&
          item.levels?.length &&
          parentId === item.id
        ) {
          return {
            ...item,
            levels: item.levels.map((data) => {
              if (data.id === id) {
                return { ...data, [field]: value, isChanged: true };
              } else {
                return data;
              }
            }),
          };
        } else return item;
      })
    );
  };

  const duplicateBtnHandler = ({ id, index }) => {
    rubricData.splice(index + 1, 0, {
      ...rubricData[index],
      id: getNewCustomId(rubricData) + 1,
    });
    setRubricData([...rubricData]);
  };

  const deleteBtnHandler = ({ id }) => {
    setRubricData((prevRubricData) =>
      prevRubricData.filter((item) => item.id !== id)
    );
  };

  const addMoreCriteriaBtnHandler = () => {
    let levels = [];
    for (let i = 0; i < selectedLevels; i++) {
      levels.push({
        id: i + 1,
        levelTitle: "",
        description: "",
        element: "child",
      });
    }
    setRubricData((prevRubricData) => [
      ...prevRubricData,
      {
        id: getNewCustomId(rubricData) + 1,
        title: "",
        description: "",
        element: "parent",
        levels: levels,
      },
    ]);
  };

  const clearAllBtnHandler = ({ parentId, id }) => {
    setRubricData((prevRubricData) => {
      return [
        ...prevRubricData.map((item) => {
          if (item.id === parentId) {
            return {
              ...item,
              levels: [
                ...item.levels?.map((data) => {
                  if (data.id === id) {
                    return {
                      ...data,
                      isChanged: true,
                      levelTitle: "",
                      description: "",
                    };
                  } else {
                    return data;
                  }
                }),
              ],
            };
          } else {
            return item;
          }
        }),
      ];
    });
  };

  const saveRubricHandler = async () => {
    setIsValidateFields(true);

    if (!rubricTitle.replace(/\s/g, "")?.length) {
      return;
    }

    const payload = {
      rubricBankInfo: {
        rubricTitle,
        class: selectedLessons.length
          ? selectedLessons.filter((item) => item !== "selectAll")
          : [],
        rubricBankItem: rubricData.map(
          ({ description, element, id, levels, title }) => ({
            rubricBankItemId: id,
            description,
            element: element,
            rubricBankItemTitle: title,
            rubricBankCriteria: levels?.length
              ? levels.map(
                  ({ description, element, id: criteriaId, levelTitle }) => ({
                    rubricBankCriteriaId: id,
                    rubricBankItemId: criteriaId,
                    criteriaTitle: levelTitle,
                    description,
                    element,
                  })
                )
              : [],
          })
        ),
      },
    };

    try {
      setLoading((prev) => ({ ...prev, isSaving: true }));

      let response = null;
      if (isNew) {
        const { data } = await axios({
          url: `${url}/api/rubricBank/create-rubric-bank${user.slug}`,
          method: "post",
          data: payload,
          headers: {
            Authorization: "Bearer " + user.accessToken,
          },
        });
        response = data;
      } else {
        const { data } = await axios({
          url: `${url}/api/rubricBank/update-rubric-bank/${selectedRubricData.rubricBankId}${user.slug}`,
          method: "PUT",
          data: payload,
          headers: {
            Authorization: "Bearer " + user.accessToken,
          },
        });
        response = data;
      }
      setLoading((prev) => ({ ...prev, isSaving: false }));
      if (response) {
        NotificationAlert(response.message, response.status?.toLowerCase());
        if (response.status?.toLowerCase() === "success") {
          onSubmit();
        }
      }
    } catch (error) {
      setLoading((prev) => ({ ...prev, isSaving: false }));
      console.log(error);
      if (error.response?.data) {
        NotificationAlert(
          error.response.data.message,
          error.response.data.status?.toLowerCase()
        );
      }
    }
  };

  const deleteRubricHandler = async () => {
    try {
      setLoading((prev) => ({ ...prev, isDeleting: true }));
      const { data } = await axios({
        url: `${url}/api/rubricBank/delete-rubric-bank/${selectedRubricData.rubricBankId}${user.slug}`,
        method: "delete",
        headers: {
          Authorization: "Bearer " + user.accessToken,
        },
      });
      setLoading((prev) => ({ ...prev, isDeleting: false }));
      if (data) {
        NotificationAlert(data.message, data.status?.toLowerCase());
        if (data.status?.toLowerCase() === "success") {
          onSubmit();
        }
      }
    } catch (error) {
      setLoading((prev) => ({ ...prev, isDeleting: false }));
      if (error.response?.data) {
        NotificationAlert(
          error.response.data.message,
          error.response.data.status?.toLowerCase()
        );
      }
    }
  };

  const getLevelPlaceholder = (index) => {
    switch (index) {
      case 0:
        return rubricTranslation.excellent;
      case 1:
        return rubricTranslation.aboveAverage;
      case 2:
        return rubricTranslation.belowAverage;
      case 3:
        return rubricTranslation.needImprovements;
      default:
        return rubricTranslation.levelName;
    }
  };

  return (
    <>
      <AlertDialog
        handleClose={() => {
          setLoading((prev) => ({
            ...prev,
            isDeleteModalOpen: false,
          }));
        }}
        handleOk={() => {
          deleteRubricHandler();
        }}
        open={loading.isDeleteModalOpen}
      />
      <div className="setting-create-rubric-section">
        <div hAlign="center">
          <div className="create-rubric-header-section">
            <Flex
              className="rubric-nav-wrapper"
              hAlign="center"
              wrap
              vAlign="center"
            >
              <Flex className="rubric-title-input-wrapper">
                <TextField
                  className="rubric-title-input"
                  value={rubricTitle}
                  onChange={(e, value) => {
                    setRubricTitle(value);
                  }}
                  style={{
                    background: "#F5F5F5",
                    borderRadius: "5px",
                  }}
                  styles={{
                    root: {
                      width: "100%",
                    },
                  }}
                  placeholder={rubricTranslation.rubricBankTitleHeader}
                  borderless={true}
                  label={rubricTranslation.rubricBankTitleHeader}
                  required
                  errorMessage={
                    isValidateFields
                      ? !rubricTitle.replace(/\s/g, "")?.length
                        ? rubricTranslation.rubricBankTitleValidation
                        : ""
                      : ""
                  }
                />
              </Flex>
              <Flex className="lesson-dropdown-wrapper">
                <ComboBox
                  className="lesson-dropdown"
                  label={rubricTranslation.selectClass}
                  multiSelect
                  options={lessonOptions}
                  selectedKey={selectedLessons}
                  onChange={onChange}
                  allowFreeform
                  placeholder={
                    isLessonLoading
                      ? translation.loading
                      : rubricTranslation.selectClass
                  }
                />
              </Flex>
              <Flex className="rubric-level-dropdown-wrapper">
                <Dropdown
                  className="rubric-level-dropdown"
                  selectedKey={selectedLevels ? selectedLevels : ""}
                  onChange={(e, value) => {
                    setSelectedLevels(value.key);

                    setRubricData(
                      rubricData.map((data) => {
                        let currentLevels = data.levels;
                        let currentLevelsLength = data.levels?.length;
                        if (value.key > currentLevelsLength) {
                          for (
                            let i = 0;
                            i < value.key - currentLevelsLength;
                            i++
                          ) {
                            currentLevels.push({
                              id: getNewCustomId(rubricData) + 1,
                              levelTitle: "",
                              description: "",
                              element: "child",
                            });
                          }
                        } else if (value.key < currentLevelsLength) {
                          currentLevels.splice(
                            currentLevelsLength -
                              (currentLevelsLength - value.key),
                            currentLevelsLength - value.key
                          );
                        }
                        return {
                          ...data,
                          title: data.title,
                          levels: [
                            ...currentLevels.map((item) => ({
                              ...item,
                              levelTitle: item.levelTitle,
                            })),
                          ],
                        };
                      })
                    );
                  }}
                  placeholder={rubricTranslation.dropdownPlaceholder}
                  options={levelOptions}
                  label={rubricTranslation.selectLevel}
                />
              </Flex>
            </Flex>
          </div>
          <div className="create-rubric-section-container">
            <Flex column hAlign="center">
              {rubricData?.map((data, index) => {
                return (
                  <Flex
                    key={index}
                    wrap
                    hAlign="center"
                    styles={{ gap: "15px" }}
                  >
                    <div className="ms-depth-4 rubric-item">
                      <div>
                        <TextField
                          value={data.title}
                          name="title"
                          className="create-rubric-input-text"
                          style={{
                            backgroundColor: "#f3f2f1",
                          }}
                          placeholder={rubricTranslation.criteriaName}
                          onChange={(e, v) =>
                            onValueChange({
                              id: data.id,
                              element: data.element,
                              field: "title",
                              value: v,
                            })
                          }
                        />
                      </div>
                      <div
                        style={{
                          marginTop: 15,
                        }}
                      >
                        <TextField
                          grow
                          value={data.description}
                          name="description"
                          multiline
                          className="create-rubric-input-text"
                          style={{
                            backgroundColor: "#f3f2f1",
                          }}
                          placeholder={rubricTranslation.description}
                          resizable={false}
                          onChange={(e, v) =>
                            onValueChange({
                              id: data.id,
                              element: data.element,
                              field: "description",
                              value: v,
                            })
                          }
                        />
                      </div>
                      <div
                        style={{
                          marginTop: 15,
                        }}
                      >
                        <div style={{ display: "flex", justifyContent: "end" }}>
                          <DefaultButton
                            text={rubricTranslation.duplicate}
                            style={{
                              border: "none",
                              color: "blue",
                            }}
                            onClick={() =>
                              duplicateBtnHandler({ id: data.id, index })
                            }
                          />
                          <RubricConfirmationDialog
                            onConfirm={() => {
                              deleteBtnHandler({ id: data.id });
                            }}
                            confirmButton={rubricTranslation.yesDelete}
                            cancelButton={translation.cancel}
                            headerAction={{
                              icon: <CloseIcon />,
                              title: translation.close,
                              onClick: () => {},
                            }}
                            trigger={
                              <DefaultButton
                                text={translation.delete}
                                style={{ border: "none", color: "red" }}
                              />
                            }
                            header={rubricTranslation.deleteConfirmation}
                          />
                        </div>
                      </div>
                    </div>

                    {data?.levels?.map((childData, ind) => {
                      return (
                        <div
                          key={`${index}${ind}`}
                          className="ms-depth-4 rubric-criteria"
                        >
                          <div>
                            <TextField
                              value={childData.levelTitle}
                              className="create-rubric-input-text"
                              style={{
                                backgroundColor: "#f3f2f1",
                              }}
                              placeholder={getLevelPlaceholder(ind)}
                              onChange={(e, v) =>
                                onValueChange({
                                  parentId: data.id,
                                  id: childData.id,
                                  element: childData.element,
                                  field: "levelTitle",
                                  value: v,
                                })
                              }
                            />
                          </div>

                          <div
                            style={{
                              marginTop: 15,
                            }}
                          >
                            <TextField
                              grow
                              value={childData.description}
                              multiline
                              className="create-rubric-input-text"
                              resizable={false}
                              style={{
                                backgroundColor: "#f3f2f1",
                              }}
                              placeholder={rubricTranslation.description}
                              onChange={(e, v) =>
                                onValueChange({
                                  parentId: data.id,
                                  id: childData.id,
                                  element: childData.element,
                                  field: "description",
                                  value: v,
                                })
                              }
                            />
                          </div>
                          <div
                            style={{
                              marginTop: 15,
                            }}
                          >
                            <div
                              style={{ display: "flex", justifyContent: "end" }}
                            >
                              <DefaultButton
                                text={rubricTranslation.clearAll}
                                style={{ border: "none", color: "red" }}
                                onClick={() =>
                                  clearAllBtnHandler({
                                    parentId: data.id,
                                    id: childData.id,
                                  })
                                }
                              />
                            </div>
                          </div>
                        </div>
                      );
                    })}
                  </Flex>
                );
              })}
            </Flex>
            <div className="add-more-criteria-section">
              <Flex hAlign="center">
                <DefaultButton
                  text={rubricTranslation.addMoreCriteria}
                  className="ms-depth-8"
                  style={{ borderRadius: "5px" }}
                  onClick={addMoreCriteriaBtnHandler}
                />
              </Flex>
            </div>
          </div>
          <div className="create-rubric-footer-section">
            <Flex
              hAlign="center"
              vAlign="center"
              wrap
              className="rubric-action-wrapper"
            >
              <DefaultButton
                text={translation.cancel}
                className="ms-depth-8 cancel-rubric-bank-button"
                onClick={onCancel}
                disabled={loading.isSaving || loading.isDeleting}
              />

              <Button
                content={
                  loading.isSaving ? translation.loading : translation.save
                }
                loading={loading.isSaving}
                disabled={loading.isSaving || loading.isDeleting}
                className="ms-depth-8 save-rubric-bank-button"
                onClick={() => {
                  saveRubricHandler();
                }}
                primary
              />
              {!isNew && (
                <Button
                  content={
                    loading.isDeleting
                      ? translation.loading
                      : translation.delete
                  }
                  loading={loading.isDeleting}
                  disabled={loading.isSaving || loading.isDeleting}
                  className={`ms-depth-8 delete-rubric-bank-button ${
                    loading.isDeleting || loading.isSaving ? "disabled" : ""
                  }`}
                  onClick={() => {
                    setLoading((prev) => ({
                      ...prev,
                      isDeleteModalOpen: true,
                    }));
                  }}
                  primary
                />
              )}
            </Flex>
          </div>
        </div>
      </div>
    </>
  );
};

export default withTranslation()(RubricSection);
