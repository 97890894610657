import React, { useEffect, useState } from "react";
import UploadParentsModel from "./UploadParentsModel";
import ParentsTable from "./ParentsTable/ParentsTable";
import "./index.scss";
import {
  Button,
  ExcelIcon,
  FilesUploadIcon,
  Flex,
} from "@fluentui/react-northstar";
import { Plus } from "react-feather";
import UploadSingleParentModel from "./UploadSingleParentModel";
import axios from "axios";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";

const Parents = (props) => {
  const [isOpenAddParent, setIsOpenAddParent] = useState(false);
  const [isOpenAddMultipleParents, setIsOpenAddMultipleParents] =
    useState(false);
  const [studentsWithoutParents, setStudentsWithoutParents] = useState([]);
  const [parentsWithStudents, setParentsWithStudents] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const { t } = useTranslation();
  const parent = t("setting").parent;

  const getAllStudent = () => {
    axios
      .get(
        `${process.env.REACT_APP_EP_URL}/api/student/get-students${
          props.user.slug
        }&onlyNameAndEmail=${true}&withoutParent=${true}`,
        {
          headers: {
            Authorization: "Bearer " + props.user.accessToken,
          },
        }
      )
      .then((res) => {
        if (res.data.length) {
          const students = res.data;
          const modifiedStudentsData = students.map((student) => {
            return { key: student.StudentEmailID, header: student.StudentName };
          });
          setStudentsWithoutParents(modifiedStudentsData);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const getAllParents = () => {
    setIsLoading(true);
    axios
      .get(`${process.env.REACT_APP_EP_URL}/api/parents${props.user.slug}`, {
        headers: {
          Authorization: "Bearer " + props.user.accessToken,
        },
      })
      .then((res) => {
        if (res.data.length) {
          const parentsWithStudents = res.data;
          setParentsWithStudents(parentsWithStudents);
        } else {
          parentsWithStudents.length && setParentsWithStudents([]);
        }
        setIsLoading(false);
      })
      .catch((err) => {
        setIsLoading(false);
        console.log(err);
      });
  };

  useEffect(() => {
    getAllStudent();
    getAllParents();
  }, []);

  return (
    <div className="parent-container ">
      <UploadParentsModel
        isOpen={isOpenAddMultipleParents}
        user={props.user}
        onClose={() => {
          setIsOpenAddMultipleParents(false);
        }}
        getAllParents={getAllParents}
      />
      <UploadSingleParentModel
        user={props.user}
        isOpen={isOpenAddParent}
        studentsWithoutParents={studentsWithoutParents}
        onClose={() => {
          setIsOpenAddParent(false);
        }}
        getAllParents={getAllParents}
        setStudentsWithoutParents={setStudentsWithoutParents}
      />
      <div>
        <Flex hAlign="end" style={{ marginBottom: "20px" }}>
          <Button
            icon={<Plus />}
            content={parent.addParentButton}
            onClick={() => {
              setIsOpenAddParent(true);
            }}
            title={parent.addParentButton}
            style={{
              maxWidth: "209px",
              marginRight: "10px",
            }}
            primary
          />
          <Button
            icon={<ExcelIcon />}
            content={parent.addMultipleParentsButton}
            onClick={() => {
              setIsOpenAddMultipleParents(true);
            }}
            title={parent.addMultipleParentsButton}
            style={{
              maxWidth: "209px",
            }}
            secondary
          />
        </Flex>
        <ParentsTable
          user={props.user}
          studentsWithoutParents={studentsWithoutParents}
          setStudentsWithoutParents={setStudentsWithoutParents}
          data={parentsWithStudents}
          isLoading={isLoading}
          getAllParents={getAllParents}
        />
      </div>
    </div>
  );
};

export default Parents;
