import { Button } from "@fluentui/react-northstar";

const AssignmentButton = (props) => {
  const { primary, content, onClick, ...rest } = props;
  return (
    <Button
      primary={primary}
      style={{
        borderRadius: "24px",
        backgroundColor: primary ? "" : "lightgray",
        margin: "5px",
      }}
      content={content}
      onClick={() => {
        onClick();
      }}
      {...rest}
    />
  );
};

export default AssignmentButton;
