import { useState } from "react";
import { Button, Flex, Text } from "@fluentui/react-northstar";
import { withTranslation } from "react-i18next";
import { TextField } from "@fluentui/react/lib/TextField";

const LinkAttachment = ({ val, setVal, linkVal, handleAdd, t }) => {
  const translation = t("assignment").body;
  const attachmentTranslation = translation.attachmentOption;

  const [isValid, setIsValid] = useState(true);

  const onAddClickHandler = () => {
    if (!val?.trim()?.length) {
      return setIsValid(false);
    } else {
      handleAdd();
    }
  };

  return (
    <div className="event-link-attachment-wrapper">
      <Text content={attachmentTranslation.link.header} />
      <Flex
        wrap
        space="between"
        style={{ marginTop: "10px", rowGap: "15px", columnGap: "15px" }}
      >
        <TextField
          className="link-input-textfield"
          styles={{
            root: {
              flexGrow: 1,
            },
          }}
          value={val}
          onChange={(e) => {
            setIsValid(true);
            setVal(e.target.value);
          }}
          borderless
          placeholder={attachmentTranslation.link.placeholder}
          errorMessage={!isValid ? attachmentTranslation.link.validation : ""}
        />

        <Button
          content={attachmentTranslation.add}
          style={{ width: "30px" }}
          onClick={onAddClickHandler}
        />
      </Flex>
    </div>
  );
};

export default withTranslation()(LinkAttachment);
