import React from "react";
import { withTranslation } from "react-i18next";
import "./switch.css";

function Switch(props) {
  const { value = "active", onChange, states, t } = props;

  const handleChange = (value) => {
    onChange(value);
  };

  return Array.isArray(states) ? (
    <div className="switch">
      {states.map(({ title, value: stateValue }) => {
        return (
          <div
            key={stateValue}
            className={value === stateValue ? "enable" : "disable"}
            onClick={() => handleChange(stateValue)}
          >
            {title}
          </div>
        );
      })}
    </div>
  ) : null;
}
export default withTranslation()(Switch);
