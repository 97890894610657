import React from "react";
import { Route, Switch } from "react-router-dom";

import SubmitFeedback from "./SubmitFeedback";
import SubmitFeedbackResponse from "./SubmitFeedbackResponse";

const SubmitFeedbackHome = ({
  isSiteEmbeded,
  user,
  globalSearch,
  logout,
  isMobileView,
  setFeedbackLimitDialog,
}) => {
  return (
    <div className="submit-feedback">
      <Switch>
        <Route exact path="/feedback/submit-feedback">
          <SubmitFeedback
            user={user}
            globalSearch={globalSearch}
            isMobileView={isMobileView}
          />
        </Route>
        <Route exact path="/feedback/submit-feedback/:feedbackId">
          <SubmitFeedbackResponse
            user={user}
            logout={logout}
            isSiteEmbeded={isSiteEmbeded}
            setFeedbackLimitDialog={setFeedbackLimitDialog}
          />
        </Route>
      </Switch>
    </div>
  );
};

export default SubmitFeedbackHome;
