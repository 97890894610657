import axios from "axios";
import { useState } from "react";
import { toast } from "react-toastify";

const URL = process.env.REACT_APP_EP_URL;

const useExcelImport = ({ user }) => {
  const [step, setStep] = useState(1);
  const [selectedFile, setSelectedFile] = useState(null);
  const [rows, setRows] = useState([]);
  const [title, setTitle] = useState("");
  const [isCreatingBundle, setIsCreatingBundle] = useState(false);
  const [shouldShowErrors, setShouldShowErrors] = useState(false);

  const columns = [
    { field: "id", headerName: "ID", width: 90 },
    {
      field: "title",
      headerName: "Title",
      width: 150,
      //   editable: true,
    },
    {
      field: "description",
      headerName: "Description",
      width: 150,
      //   editable: true,
    },
    {
      field: "startDate",
      headerName: "StartDate",
      width: 110,
      //   editable: true,
    },
    {
      field: "endDate",
      headerName: "EndDate",
      width: 110,
      //   editable: true,
    },
    {
      field: "categories",
      headerName: "Categories",
      width: 110,
      //   editable: true,
    },
    {
      field: "tags",
      headerName: "Tags",
      width: 110,
      //   editable: true,
    },
    {
      field: "speakers",
      headerName: "Speakers",
      width: 110,
      //   editable: true,
    },
    {
      field: "linkTitle",
      headerName: "LinkTitle",
      width: 110,
      //   editable: true,
    },
    {
      field: "linkURL",
      headerName: "LinkURL",
      width: 110,
      //   editable: true,
    },
    {
      field: "location",
      headerName: "Location",
      width: 110,
      //   editable: true,
    },
    {
      field: "meetingType",
      headerName: "Meeting Type",
      width: 110,
      //   editable: true,
    },
  ];

  const onStepChangeHandler = (stepNumber) => {
    setStep(stepNumber);
  };

  const onFileSelectHandler = (file) => {
    setSelectedFile(file);
  };

  const validateEvent = () => {
    let isValid = true;

    if (!title?.length) {
      isValid = false;
    }

    return isValid;
  };

  const saveClickHandler = async () => {
    setShouldShowErrors(true);
    const isValid = validateEvent();

    if (!isValid) {
      return;
    }

    try {
      setIsCreatingBundle(true);
      const { data } = await axios.post(
        `${URL}/api/bulk-events/create-events-from-excel${user.slug}`,
        { events: rows, title },
        {
          headers: {
            Authorization: "Bearer " + user.accessToken,
          },
        }
      );

      if (data?.status?.toLowerCase() === "success") {
        toast.success(data?.message);
      } else if (data?.status?.toLowerCase() === "warning") {
        toast.warning(data?.message);
      }

      setStep(3);
    } catch (error) {
      console.log("error");
      toast.error(error?.response?.data?.message);
    } finally {
      setIsCreatingBundle(false);
    }
  };

  return {
    step,
    selectedFile,
    onStepChangeHandler,
    onFileSelectHandler,
    isSavingData: isCreatingBundle,
    saveClickHandler,
    rows,
    setRows,
    columns,
    title,
    setTitle,
    shouldShowErrors,
  };
};

export default useExcelImport;
