import React, { useEffect } from "react";
import Calender from "../Calender/Calender";
import Slots from "../Slots/Slots";
import {
  Button,
  Checkbox,
  Dropdown,
  Flex,
  Loader,
} from "@fluentui/react-northstar";
import { useBooking } from "../../../../context/BookingContext";
import MonthSlider from "../MonthSlider/MonthSlider";
import AccessTimeFilledIcon from "@mui/icons-material/AccessTimeFilled";
import { useTranslation } from "react-i18next";

const Schedules = ({ isMobileView }) => {
  const { t } = useTranslation();
  const {
    date,
    handleScheduleSlots,
    isScheduling,
    isShowingScheduledSlots,
    isEditing,
    handleUpdateScheduledSlots,
    handleStartEditingSlots,
    days,
    handleToggleDay,
    getAllDaysOfMonth,
    durationOfSlot,
    handleDurationChange,
    handleCancelEditing,
    scheduledSlots,
  } = useBooking();

  const translation = t("booking").setMyAvailability;
  const commonTranslation = t("booking").common;

  return (
    <div>
      <Flex style={{ justifyContent: "space-between" }} vAlign="center">
        <Flex gap="gap.medium" vAlign="center">
          <h1 style={{ color: "#6264a7", fontWeight: "bold" }}>
            {translation.header}
          </h1>
          <AccessTimeFilledIcon htmlColor="#6264a7" fontSize="large" />
        </Flex>
        {!isMobileView ? (
          isEditing ? (
            <Flex gap="gap.medium">
              <Button
                secondary
                style={{ borderRadius: "3px" }}
                disabled={isScheduling}
                onClick={() => !isScheduling && handleCancelEditing()}
              >
                {commonTranslation.cancel}
              </Button>
              <Button
                primary
                style={{ borderRadius: "3px" }}
                disabled={isScheduling}
                onClick={() => !isScheduling && handleUpdateScheduledSlots()}
              >
                {isScheduling ? (
                  <Flex gap="gap.medium" vAlign="center">
                    <span>{translation.saving}</span> <Loader size="small" />
                  </Flex>
                ) : (
                  translation.saveChanges
                )}
              </Button>
            </Flex>
          ) : isShowingScheduledSlots ? (
            <Button
              primary
              style={{ borderRadius: "3px" }}
              onClick={() => handleStartEditingSlots()}
            >
              {translation.editButton}
            </Button>
          ) : (
            <Button
              primary
              onClick={handleScheduleSlots}
              disabled={isScheduling}
              style={{ borderRadius: "3px" }}
            >
              {isScheduling ? (
                <Flex gap="gap.medium" vAlign="center">
                  <span>{translation.scheduling}</span> <Loader size="small" />
                </Flex>
              ) : (
                translation.scheduleSlotsButton
              )}
            </Button>
          )
        ) : null}
      </Flex>

      <Flex
        hAlign="center"
        gap="gap.large"
        style={{ justifyContent: "space-around" }}
        wrap
      >
        <div>
          <MonthSlider />
          <Calender />
        </div>
        <div>
          {scheduledSlots?.length === 0 && (
            <Flex column className="select-duration-wrapper" gap="gap.smaller">
              <p className="select-duration-title">
                {translation.selectSessionDuration}
              </p>
              <Dropdown
                items={["15 min", "30 min", "45 min", "60 min"]}
                placeholder="Select Duration"
                noResultsMessage="No results"
                className="select-duration-dropdown"
                value={`${durationOfSlot} min`}
                onChange={handleDurationChange}
              />
            </Flex>
          )}
          <Flex
            gap="gap.medium"
            className="days-select-section"
            wrap
            style={{
              paddingBottom: "20px",
              paddingTop: isMobileView ? "20px" : "",
            }}
          >
            {days[date.month()].map((day, i) => {
              return (
                <Checkbox
                  label={day.label}
                  key={`days-${i + 1}`}
                  checked={day.isSelected}
                  disabled={isShowingScheduledSlots ? !isEditing : isScheduling}
                  onClick={(e) => {
                    handleToggleDay(date.month(), i);
                    getAllDaysOfMonth(
                      day.value,
                      date.month() + 1,
                      date.year(),
                      day.isSelected
                    );
                  }}
                />
              );
            })}
          </Flex>

          <Slots isMobileView={isMobileView} />
          <Flex hAlign="end" style={{ marginTop: "20px" }}>
            {isMobileView ? (
              isEditing ? (
                <Flex gap="gap.medium">
                  <Button
                    secondary
                    style={{ borderRadius: "3px" }}
                    disabled={isScheduling}
                    onClick={() => !isScheduling && handleCancelEditing()}
                  >
                    {commonTranslation.cancel}
                  </Button>
                  <Button
                    primary
                    style={{ borderRadius: "3px" }}
                    disabled={isScheduling}
                    onClick={() =>
                      !isScheduling && handleUpdateScheduledSlots()
                    }
                  >
                    {isScheduling ? (
                      <Flex gap="gap.medium" vAlign="center">
                        <span>{translation.saving}</span>{" "}
                        <Loader size="small" />
                      </Flex>
                    ) : (
                      translation.saveChanges
                    )}
                  </Button>
                </Flex>
              ) : isShowingScheduledSlots ? (
                <Button
                  primary
                  style={{ borderRadius: "3px" }}
                  onClick={() => handleStartEditingSlots()}
                >
                  {translation.editButton}
                </Button>
              ) : (
                <Button
                  primary
                  onClick={handleScheduleSlots}
                  disabled={isScheduling}
                  style={{ borderRadius: "3px" }}
                >
                  {isScheduling ? (
                    <Flex gap="gap.medium" vAlign="center">
                      <span>{translation.scheduling}</span>{" "}
                      <Loader size="small" />
                    </Flex>
                  ) : (
                    translation.scheduleSlotsButton
                  )}
                </Button>
              )
            ) : null}
          </Flex>
        </div>
      </Flex>
    </div>
  );
};

export default Schedules;
