import React, { useEffect, useState } from "react";
import { IconButton, initializeIcons, Label } from "@fluentui/react";
import { Dropdown } from "@fluentui/react/lib/Dropdown";
import { Checkbox, Flex, Text } from "@fluentui/react-northstar";
import { useLocation } from "react-router-dom";
import { withTranslation } from "react-i18next";

initializeIcons();

const levelOptions = [
  { key: 1, text: 1 },
  { key: 2, text: 2 },
  { key: 3, text: 3 },
  { key: 4, text: 4 },
  { key: 5, text: 5 },
  { key: 6, text: 6 },
];

const CreateRubricNav = ({
  selectedLevels,
  setSelectedLevels,
  havePoints,
  setHavePoints,
  rubricData,
  props,
  setRubricData,
  rubricBankData,
  subjectRubrics,
  currentAssignmentData,
  loading,
  t,
  state,
  setState,
}) => {
  // const { state } = useLocation();

  const [totalPoints, setTotalPoints] = useState(0);

  const translation = t("assignment").body;
  const rubricTranslation = translation.rubricPage;

  useEffect(() => {
    let points = 0;
    rubricData.forEach((data) => {
      points += Number(data.levels[0]?.points) || 0;
    });
    setTotalPoints(points);
  }, [rubricData]);

  const rubricBanksId = Math.floor(
    Math.random() * Math.floor(Math.random() * Date.now())
  );
  const havePointId = Math.floor(
    Math.random() * Math.floor(Math.random() * Date.now())
  );
  return (
    <div className="create-rubric-nav">
      <Flex
        space="between"
        vAlign="center"
        wrap
        className="create-rubric-nav-container"
      >
        <Flex className=" ms-fontSize-20 create-rubric-title ">
          <IconButton
            iconProps={{ iconName: "ChromeBack" }}
            title={translation.back}
            ariaLabel="back"
            onClick={() => {
              props.setState({ isCreatingRubric: false });
              // props.history.push(
              //   {
              //     pathname: "/assignments/",
              //   },
              //   {
              //     callBackPage: state?.callBackPage,
              //     selectedSubmission: state?.selectedSubmission,
              //   }
              // );
            }}
          />
          {currentAssignmentData?.rubric?.rubricItem?.length
            ? rubricTranslation.editRubric
            : rubricTranslation.createRubric}
        </Flex>
        <Flex className="create-rubric-bank-dropdown" wrap gap="gap.medium">
          <Label style={{ width: "fit-content" }}>
            {rubricTranslation.rubricBank}:
          </Label>

          <Flex.Item grow>
            <Dropdown
              className={
                props.isMobileView || props.isTabletView
                  ? "mobile-rubric-bank-dropdown"
                  : "rubric-bank-dropdown"
              }
              placeholder={
                loading.isRubricBankLoading
                  ? translation.loading
                  : rubricTranslation.dropdownPlaceholder
              }
              options={
                rubricBankData?.length
                  ? rubricBankData?.map((rubric) => ({
                      key: rubric.rubricBankId,
                      text: rubric.rubricTitle,
                    }))
                  : [{ text: rubricTranslation.noDataAvilable, disabled: true }]
              }
              onChange={(e, value) => {
                let data = rubricBankData.find(
                  (rubric) => rubric.rubricBankId === value.key
                )?.rubricBankItem;
                if (data?.length) {
                  setRubricData(
                    data.map((item) => ({
                      id: item.rubricBankItemId,
                      title: item.rubricTitle,
                      description: item.description,
                      element: item.element,
                      havePoints: !!havePoints,
                      levels: item.rubricBankCriteria.map((e) => ({
                        id: e.rubricBankCriteriaId,
                        levelTitle: e.criteriaTitle,
                        description: e.description,
                        element: "Child",
                        havePoints: !!havePoints,
                      })),
                    }))
                  );
                } else {
                  setRubricData([]);
                }
              }}
            />
          </Flex.Item>
        </Flex>
        <Flex
          className="create-rubric-subject-rubrics-dropdown"
          wrap
          gap="gap.medium"
        >
          <Label style={{ width: "fit-content" }}>
            {rubricTranslation.myRubrics}:
          </Label>

          <Flex.Item grow>
            <Dropdown
              className={
                props.isMobileView || props.isTabletView
                  ? "mobile-rubric-bank-dropdown"
                  : "rubric-bank-dropdown"
              }
              placeholder={
                loading.isMyRubricLoading
                  ? translation.loading
                  : rubricTranslation.dropdownPlaceholder
              }
              options={
                subjectRubrics?.length
                  ? subjectRubrics?.map((rubric) => ({
                      key: rubric.rubricId,
                      text: rubric.rubricTitle,
                    }))
                  : [{ text: rubricTranslation.noDataAvilable, disabled: true }]
              }
              onChange={(e, value) => {
                let data = subjectRubrics.find(
                  (rubric) => rubric.rubricId === value.key
                )?.rubricItem;
                if (data?.length) {
                  setRubricData(
                    data.map((item) => ({
                      id: item.rubricItemId,
                      title: item.rubricItemTitle,
                      description: item.description,
                      element: item.element,
                      havePoints: !!havePoints,
                      levels: item.rubricCriteria.map((e) => ({
                        id: e.criteriaId,
                        levelTitle: e.criteriaTitle,
                        description: e.description,
                        element: "Child",
                        havePoints: !!havePoints,
                      })),
                    }))
                  );
                } else {
                  setRubricData([]);
                }
              }}
            />
          </Flex.Item>
        </Flex>
        <Flex className="create-rubric-total-levels" wrap gap="gap.medium">
          <Label style={{ width: "fit-content" }}>
            {rubricTranslation.totalLevels}:
          </Label>

          <Flex.Item grow>
            <Dropdown
              className={
                props.isMobileView || props.isTabletView
                  ? "mobile-rubric-bank-dropdown"
                  : "total-levels-dropdown"
              }
              selectedKey={selectedLevels ? selectedLevels : ""}
              onChange={(e, value) => {
                setSelectedLevels(value.key);

                setRubricData(
                  rubricData.map((data) => {
                    let currentLevels = data.levels;
                    let currentLevelsLength = data.levels?.length;
                    if (value.key > currentLevelsLength) {
                      for (
                        let i = 0;
                        i < value.key - currentLevelsLength;
                        i++
                      ) {
                        currentLevels.push({
                          id: currentLevels[currentLevels?.length - 1]?.id + 1,
                          levelTitle: "",
                          description: "",
                          element: "child",
                        });
                      }
                    } else if (value.key < currentLevelsLength) {
                      currentLevels.splice(
                        currentLevelsLength - (currentLevelsLength - value.key),
                        currentLevelsLength - value.key
                      );
                    }
                    return {
                      ...data,
                      title: data.title,
                      havePoints: !!havePoints,
                      levels: [
                        ...currentLevels.map((item) => ({
                          ...item,
                          levelTitle: item.levelTitle,
                        })),
                      ],
                    };
                  })
                );
              }}
              placeholder={rubricTranslation.dropdownPlaceholder}
              options={levelOptions}
            />
          </Flex.Item>
        </Flex>
        <Flex vAlign="center" className="create-rubric-have-points">
          <Flex vAlign="center">
            <Flex.Item>
              <Checkbox
                id={havePointId}
                label={
                  <Text
                    style={{
                      width: "fit-content",
                      minWidth: "85px",
                      display: "block",
                      fontWeight: "600",
                    }}
                  >
                    {rubricTranslation.havePoints}
                  </Text>
                }
                checked={havePoints}
                onChange={(e, { checked }) => {
                  setHavePoints(checked);
                  setRubricData((prevData) =>
                    prevData.map((item) => ({
                      ...item,
                      havePoints: checked,
                      isChanged: true,
                      levels: item.levels.map((childItem) => {
                        // if (checked) {
                        return {
                          ...childItem,
                          points: 0,
                          isChanged: true,
                          hasPoint: checked,
                        };
                        // } else {
                        //   const { points, ...rest} = childItem;
                        //   return rest;
                        // }
                      }),
                    }))
                  );
                }}
                styles={({ checked }) => {
                  return {
                    text: {
                      fontWeight: "bold",
                    },
                  };
                }}
              />
            </Flex.Item>
            {havePoints ? (
              <Flex className="total-points" hAlign="center" vAlign="center">
                {rubricTranslation.totalPoints}: {totalPoints}
              </Flex>
            ) : (
              ""
            )}
          </Flex>
        </Flex>
      </Flex>
    </div>
  );
};

export default withTranslation()(CreateRubricNav);
