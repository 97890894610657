import subscriptionImg from "../../Assets/images/subscription.png";

export const Subscription = ({ pageName, isExpire, role }) => {
  return (
    <>
      <div
        style={{
          display: "grid",
          margin: "auto",
          justifyContent: "center",
          placeItems: "center",
          height: "600px",
          maxWidth: "600px",
          width: "100%",
        }}
        className="btsp"
      >
        <div className="text-center">
          <img
            src={subscriptionImg}
            alt="subscription"
            height="300px"
            width="300px"
          />
          <h6 className="text-center">
            {(role === "Teacher" || role === "Student") && isExpire
              ? "The plan you have been using is expired, please contact to the administrator."
              : (role === "Teacher" || role === "Student") && !isExpire
              ? "This feature is not available for your organization yet, in case if any question connect to the administrator"
              : isExpire
              ? "Your plan has been expired, upgrade your plan from "
              : ` You don't have permission for access ${pageName}, If you want to access
            it, upgrade your plan from `}
            {role !== "Teacher" && role !== "Student" && (
              <a
                className="text-danger cursor-pointer"
                href="https://www.acv.app/renew"
                target="_blank"
              >
                <u>here</u>
              </a>
            )}
          </h6>
        </div>
      </div>
    </>
  );
};
