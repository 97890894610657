import React from "react";
import { Route, Switch } from "react-router-dom";
import Group from "./Group";
import Groups from "./Groups";

const GroupHome = (props) => {
  return (
    <div className="groups-wrapper">
      <Switch>
        <Route exact path="/feedback/groups">
          <Groups {...props} />
        </Route>
        <Route exact path="/feedback/groups/group">
          <Group {...props} />
        </Route>
      </Switch>
    </div>
  );
};

export default GroupHome;
