import React, { useState } from "react";
import { Tab } from "./Tab";

export const Tabs = ({ children }) => {
  const [activeTab, setActiveTab] = useState("Skills");

  const onClickTabItem = (tab) => {
    setActiveTab(tab);
  };

  return (
    <div className="tabs setting-profile-container">
      <div className="setting-profile-wrapper">
        <ol className="tab-list">
          {children.map((child) => {
            const { label } = child.props;

            return (
              <Tab
                activeTab={activeTab}
                key={label}
                label={label}
                onClick={onClickTabItem}
              />
            );
          })}
        </ol>
        <div className="tab-content">
          {children.map((child) => {
            if (child.props.label !== activeTab) return undefined;
            return child.props.children;
          })}
        </div>
      </div>
    </div>
  );
};

export default Tabs;
