import React from "react";
import useListEvents from "./useListEvents";

import { ExcelIcon, Flex, Input, Text } from "@fluentui/react-northstar";
import { DataGrid } from "@mui/x-data-grid";

import { useTranslation } from "react-i18next";
import "./ListEvents.scss";

const ListEvents = ({
  selectedFile,
  rows,
  setRows,
  columns,
  title,
  setTitle,
  shouldShowErrors,
}) => {
  const { t } = useTranslation();

  const translation = t("events");
  const commonTranslation = translation.common;
  const eventsTranslation = translation.body.bundles.importFromExcel;

  const {} = useListEvents({ selectedFile, setRows, title });

  console.log("rows", rows);

  return (
    <Flex column className="list-events-wrapper">
      <Flex hAlign="center">
        <ExcelIcon size={"largest"} />
      </Flex>
      <Flex column gap="gap.small">
        <Text as="h1" weight="bold" align="center" style={{ margin: 0 }}>
          {commonTranslation.events}
        </Text>
        <Flex>
          <Input
            placeholder={eventsTranslation.enterTitlePlaceholder}
            label={eventsTranslation.bundleTitle}
            input={{
              styles: {
                width: "100%",
              },
            }}
            style={{
              width: "100%",
              maxWidth: "350px",
            }}
            value={title}
            onChange={(e, { value }) => {
              setTitle(value);
            }}
            error={
              shouldShowErrors && !title?.length
                ? eventsTranslation.bundleTitleValidation
                : ""
            }
          />
        </Flex>
        <Flex hAlign="start">
          <DataGrid
            rows={rows}
            columns={columns}
            initialState={{
              pagination: {
                paginationModel: {
                  pageSize: 5,
                },
              },
            }}
            pageSizeOptions={[5]}
            checkboxSelection
            disableRowSelectionOnClick
          />
        </Flex>
      </Flex>
    </Flex>
  );
};

export default ListEvents;
