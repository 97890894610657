import React from "react";
import "../event.css";

export const Tab = ({ activeTab, label, onClick, classes = "" }) => {
  const onTabClick = () => {
    onClick(label);
  };
  let className = `tab-list-item ${classes}`;

  if (activeTab === label) {
    className += " tab-list-active";
  }
  return (
    <li className={className} onClick={onTabClick}>
      {label}
    </li>
  );
};
