import React from "react";
import {
  Checkbox,
  Flex,
  Text,
  CardHeader,
  CardBody,
  Card,
} from "@fluentui/react-northstar";
import moment from "moment";
import { withTranslation } from "react-i18next";

import FeedbackResponseExcelExport from "../FeedbackResponseExcelExport";

import "./FeedbackResponseCard.scss";

const progress = "Progress";
const completed = "Completed";

const FeedbackResponseCard = (props) => {
  const {
    isDataLoading = false,
    hasVideo,
    videoUrl,
    title,
    status,
    endsOn,
    isResponseRecieved,
    responseRecieved,
    isResponseRemaining,
    responseRemaining,
    showStopRecievingResponse,
    stopAcceptingResponse,
    stopRecievingResponseHandler,
    stopRecievingResponseLoading = false,
    description,
    showExcelExport,
    feedbackData,
    isShowQuestionNumber,
    currentQuestion,
    totalQuestion,
    t,
  } = props;

  const translation = t("feedbackModule");

  const commonTranslation = translation.common;
  const feedbackTranslation = translation.body.feedbackResponseCard;

  const feedbackStatusTranslation = translation.body.feedbackStatus;

  return (
    <div className="feedback-response-card-container">
      <Card
        elevated
        className="fit-content view-response-deatil-card"
        fluid
        styles={{
          borderRadius: "10px",
          ":hover": { backgroundColor: "white" },
          backgroundColor: "white",
          boxShadow: "0px 4px 14px rgba(0, 9, 86, 0.07)",
        }}
      >
        <CardHeader
          className="view-response-deatil-card-header"
          style={{ borderRadius: "10px 10px 0 0" }}
        >
          <Flex gap="gap.small" column>
            <Flex.Item styles={{ padding: "10px" }}>
              <Text size="large" color="white" align="center">
                {title}
              </Text>
            </Flex.Item>
          </Flex>
        </CardHeader>
        <CardBody style={{ padding: "10px", minHeight: "150px" }}>
          <Flex column gap="gap.medium">
            {description ? (
              <Flex.Item flexDirection="column" align="center">
                <Text content={description} className="description-text" />
              </Flex.Item>
            ) : null}
            {videoUrl ? (
              <Flex.Item flexDirection="column" align="center">
                <video
                  className="video-question"
                  src={videoUrl}
                  controls
                  style={{ borderRadius: "12px", width: "100%" }}
                />
              </Flex.Item>
            ) : null}
            {status ? (
              <Flex vAlign="center" hAlign="center" gap="gap.medium">
                <Text>{feedbackTranslation.status}:</Text>
                <Flex
                  style={{
                    minWidth: "100px",
                    padding: "3px 0",
                    textAlign: "center",
                    borderRadius: " 13px",
                    color: "white",
                    backgroundColor:
                      status === completed ? "#1db9c3" : "#ffb900",
                  }}
                  hAlign="center"
                  vAlign="center"
                >
                  {status === completed
                    ? feedbackStatusTranslation.completed
                    : feedbackStatusTranslation.progress}
                </Flex>
              </Flex>
            ) : null}
            {endsOn ? (
              <Flex vAlign="center" hAlign="center" gap="gap.medium">
                <Text>{feedbackTranslation.ends}:</Text>
                <Text weight="bold">
                  {endsOn
                    ? moment(endsOn).isValid()
                      ? moment(endsOn).format("MMM DD | hh:mm A")
                      : commonTranslation.notApplicable
                    : commonTranslation.notApplicable}
                </Text>
              </Flex>
            ) : null}
            {isShowQuestionNumber ? (
              <Flex vAlign="center" hAlign="center" gap="gap.medium">
                <Text>{feedbackTranslation.question}:</Text>
                <Text weight="bold">
                  {currentQuestion} / {totalQuestion}
                </Text>
              </Flex>
            ) : null}
            {isResponseRecieved ? (
              <Flex hAlign="center">
                <Card
                  // elevated
                  className="fit-content response-statistics-card"
                  fluid
                >
                  <CardBody style={{ padding: "10px", marginBottom: "0px" }}>
                    <Flex vAlign="center" hAlign="center" space="between">
                      <Text style={{ fontSize: "16px" }} weight="semibold">
                        {feedbackTranslation.responseReceived}
                      </Text>
                      <Text
                        size="larger"
                        weight="bold"
                        style={{ color: "#00DE81", marginRight: "7px" }}
                      >
                        {responseRecieved || 0}
                      </Text>
                    </Flex>
                  </CardBody>
                </Card>
              </Flex>
            ) : null}
            {isResponseRemaining ? (
              <Flex hAlign="center" vAlign="center">
                <Card
                  // elevated
                  className="fit-content response-statistics-card"
                  fluid
                >
                  <CardBody style={{ padding: "10px", marginBottom: "0px" }}>
                    <Flex vAlign="center" hAlign="center" space="between">
                      <Text style={{ fontSize: "16px" }} weight="semibold">
                        {feedbackTranslation.remaining}
                      </Text>
                      <Text
                        size="larger"
                        weight="bold"
                        style={{ color: "#FE644D", marginRight: "7px" }}
                      >
                        {responseRemaining || 0}
                      </Text>
                    </Flex>
                  </CardBody>
                </Card>
              </Flex>
            ) : null}
            {showExcelExport && feedbackData ? (
              <FeedbackResponseExcelExport feedbackData={feedbackData} />
            ) : null}
            {showStopRecievingResponse ? (
              <Flex hAlign="center" vAlign="center">
                <Checkbox
                  styles={{ alignItems: "center" }}
                  label={feedbackTranslation.stopAcceptingResponse}
                  toggle
                  checked={stopAcceptingResponse}
                  onChange={stopRecievingResponseHandler}
                  disabled={stopRecievingResponseLoading || isDataLoading}
                />
              </Flex>
            ) : null}
          </Flex>
        </CardBody>
      </Card>
    </div>
  );
};

export default withTranslation()(FeedbackResponseCard);
