import React, { useEffect, useState } from "react";
import { CollectionImagePreview } from "./collectionImagePreview";
import "./LibraryCarousel.css";

const LibraryCarousel = ({ images, name }) => {
  const [currentIndex, setCurrentIndex] = useState(0);

  useEffect(() => {
    const slideshow = document.getElementById(`slideshow-${name}`);
    const pagination = document.getElementById(`pagination-${name}`);

    if (slideshow && pagination) {
      images.forEach(() => {
        const slide = document.createElement("div");
        slide.className = `slide-${name}`;
        slideshow.appendChild(slide);
      });
    }
  }, [images]);

  const showImage = (imgIndex) => {
    setCurrentIndex(imgIndex);

    const slides = document.querySelectorAll(`.slide-${name}`);
    const dots = document.querySelectorAll(`.pagination span`);

    slides.forEach((slide, i) => {
      slide.style.display = i === imgIndex ? "block" : "none";
    });

    dots.forEach((dot, i) => {
      dot.classList.toggle("active", i === imgIndex);
    });
  };

  return (
    <div className="preview-center">
      <div className="container">
        <div className={`slideshow`} id={`slideshow-${name}`}>
          {images.map((img, imgIndex) => (
            <div
              key={imgIndex}
              className={`slide-${name}`}
              style={{ display: imgIndex === 0 ? "block" : "none" }}
            >
              <CollectionImagePreview previewURL={img} index={imgIndex} />
            </div>
          ))}
        </div>
        <div className={`pagination d-flex`} id={`pagination-${name}`}>
          {images.map((_, imgIndex) => (
            <span
              key={imgIndex}
              onClick={() => showImage(imgIndex)}
              className={`dot ${currentIndex === imgIndex && "active"}`}
            ></span>
          ))}
        </div>
      </div>
    </div>
  );
};
export default LibraryCarousel;
