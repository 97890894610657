import {
  CloseIcon,
  MicIcon,
  PauseIcon,
  PlayIcon,
  StarIcon,
} from "@fluentui/react-icons-northstar";
import React, { useState } from "react";
import styled from "styled-components";

import { Button, Dialog, Flex, Text } from "@fluentui/react-northstar";

import { useAudioRecorder } from "@sarafhbk/react-audio-recorder";

import "./DialogVoice.css";

const DialogComponent = styled(Dialog)`
  @media screen and (max-width: 400px) {
    padding: 10px;
  }
`;

const DialogVoice = (props) => {
  const [open, setOpen] = useState(false);

  return (
    <div>
      <DialogComponent
        open={props.open}
        onOpen={props.onOpen}
        onCancel={props.onCancel}
        onConfirm={props.onConfirm}
        confirmButton={props.confirmButton}
        content={props.content}
        header={props.header}
        headerAction={props.headerAction}
        trigger={props.button}
        cancelButton={props.cancelButton}
      />
    </div>
  );
};

export default DialogVoice;
