import { Flex } from "@fluentui/react-northstar";
import React from "react";
import positiveVote from "../../../../../../../Assets/images/positive-vote.png";

const SuccessConfirmation = () => {
  return (
    <Flex hAlign="center">
      <img
        src={positiveVote}
        alt="ACV - Asistente de Aula Virtual | Congretulations"
        style={{
          padding: "10px",
          margin: "20px",
          width: "200px",
          height: "200px",
        }}
      />
    </Flex>
  );
};

export default SuccessConfirmation;
