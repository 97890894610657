import {
  Flex,
  MicIcon,
  PauseIcon,
  PlayIcon,
  Text,
} from "@fluentui/react-northstar";
import { withTranslation } from "react-i18next";

const VoiceAttachment = ({
  isEditable,
  voiceState,
  setVoiceState,
  timer,
  resumeAudioRecording,
  pauseAudioRecording,
  startAudioRecording,
  t,
}) => {
  const translation = t("assignment").body;
  const attachmentTranslation = translation.attachmentOption;

  return (
    <>
      {!isEditable ? (
        <div className="justify-center">
          <audio controls src={voiceState.audioUrl} />
        </div>
      ) : (
        <div className="justify-center">
          {(voiceState.isSaved || voiceState.isDone) && voiceState.audioUrl ? (
            <audio
              controls
              src={voiceState.audioUrl ? voiceState.audioUrl : ""}
            />
          ) : voiceState.isPause ? (
            <>
              <Flex column gap="gap.smaller">
                <Text content={timer} className="justify-center" />
                <div className="justify-center">
                  <PlayIcon
                    className="play-bg-color icon-padding"
                    circular
                    size="large"
                    onClick={() => {
                      setVoiceState({
                        ...voiceState,
                        isStart: true,
                        isPause: false,
                      });
                      resumeAudioRecording();
                    }}
                  />
                </div>
                <Text
                  content={attachmentTranslation.voice.resume}
                  className="justify-center"
                />
              </Flex>
            </>
          ) : voiceState.isStart ? (
            <>
              <Flex column gap="gap.smaller">
                <Text content={timer} className="justify-center" />
                <div className="justify-center">
                  <PauseIcon
                    className="pause-bg-color icon-padding"
                    circular
                    size="large"
                    onClick={() => {
                      setVoiceState({
                        ...voiceState,
                        isStart: false,
                        isPause: true,
                      });
                      pauseAudioRecording();
                    }}
                  />
                </div>
                <Text
                  content={attachmentTranslation.voice.pause}
                  className="justify-center"
                />
              </Flex>
            </>
          ) : (
            <>
              <Flex column gap="gap.smaller">
                <Text content={timer} className="justify-center" />
                <div className="justify-center">
                  <MicIcon
                    className="mic-bg-color icon-padding"
                    circular
                    size="large"
                    onClick={() => {
                      setVoiceState({ ...voiceState, isStart: true });
                      startAudioRecording();
                    }}
                  />
                </div>
                <Text
                  content={attachmentTranslation.voice.start}
                  className="justify-center"
                />
              </Flex>
            </>
          )}
        </div>
      )}
    </>
  );
};

export default withTranslation()(VoiceAttachment);
