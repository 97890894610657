import React from "react";

import { ExcelIcon, Flex } from "@fluentui/react-northstar";
import { DataGrid } from "@mui/x-data-grid";

import { useTranslation } from "react-i18next";
import "./ListParents.scss";
import { getUniqueId } from "../../../../../../Assignment/Components/AttachmentOption/AttachmentOption";
import { Typography } from "antd";

const ListParents = ({ rows, inCompletedRows }) => {
  const { t } = useTranslation();

  const parentTable = t("setting").parent.multipleParentsDialog.previewTable;

  const columns = [
    {
      field: "Parent_Mail",
      headerName: parentTable.parentMail,
      width: 200,

      //   editable: true,
    },
    {
      field: "Parent_Name",
      headerName: parentTable.parentName,
      width: 150,
      //   editable: true,
    },
    {
      field: "Parent_Number",
      headerName: parentTable.parentNumber,
      width: 150,
      //   editable: true,
    },
    {
      field: "Parent_Expiration_Date",
      headerName: parentTable.expirationDate,
      width: 150,
      //   editable: true,
    },
    {
      field: "Student_Mail",
      headerName: parentTable.studentMail,
      width: 300,
      //   editable: true,
    },
    {
      field: "Provider",
      headerName: parentTable.Provider,
      width: 110,
      //   editable: true,
    },
  ];

  return (
    <Flex column className="list-events-wrapper">
      <Flex column gap="gap.small">
        <Flex hAlign="center">
          <ExcelIcon size={"largest"} />
        </Flex>
        <Flex hAlign="start">
          <DataGrid
            rows={rows}
            columns={columns}
            getRowId={(row) => getUniqueId()}
            initialState={{
              pagination: {
                paginationModel: {
                  pageSize: 5,
                },
              },
            }}
            pageSizeOptions={[5]}
          />
        </Flex>
        {inCompletedRows !== 0 && (
          <Typography.Text type="warning" style={{ textAlign: "center" }}>
            {inCompletedRows +
              " records with missing details are not considered."}
          </Typography.Text>
        )}
      </Flex>
    </Flex>
  );
};

export default ListParents;
