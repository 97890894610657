import React, { useState } from "react";
import {
  Button,
  Dialog,
  Divider,
  Flex,
  Image,
  Loader,
  Text,
  Tooltip,
} from "@fluentui/react-northstar";
import axios from "axios";
import { withTranslation } from "react-i18next";
import { toast } from "react-toastify";

import {
  FlagIcon,
  PresenterIcon,
  PollIcon,
  AcceptIcon,
  CloseIcon,
  ShiftActivityIcon,
  SurveyIcon,
  RetryIcon,
  CallVideoIcon,
  VolumeIcon,
  ItalicIcon,
  BookmarkIcon,
  TranscriptIcon,
  ChevronEndIcon,
  ChevronStartIcon,
} from "@fluentui/react-icons-northstar";

import userSvg from "../../Assets/images/svg/user.svg";
import ProgressChart from "./ProgressChart";
import "./index.scss";

const url = process.env.REACT_APP_EP_URL;

const chartTypes = {
  attendance: "attendance",
  assignment: "assignment",
  event: "event",
  feedback: "feedback",
};

const StudentProgress = ({ user, triggerButton, headerText, t }) => {
  const [isLoadingData, setIsLoadingData] = useState(false);
  const [isMyProgressModalOpen, setIsMyProgressModalOpen] = useState(false);
  const [progressData, setProgressData] = useState(null);
  const [customFieldInfo, setCustomFieldInfo] = useState(null);
  const [selectedChart, setSelectedChart] = React.useState(
    chartTypes.attendance
  );

  const translation = t("studentProgress");

  const getInfo = async (email) => {
    setIsLoadingData(true);
    try {
      const res = await axios.put(
        `${url}/api/admin/get-student-info/${email}${user.slug}`,
        null,
        {
          headers: {
            Authorization: "Bearer " + user.accessToken,
          },
        }
      );

      if (res.data) {
        const response = res.data?.result;
        const { assignment, feedback, event, info, chart } = res.data;
        setProgressData({
          ...response,
          Ausente: response?.Ausente ?? 0,
          Justificado: response?.Justificado ?? 0,
          Presente: response?.Presente ?? 0,
          Retardo: response?.Retardo ?? 0,
          StudentData: response?.StudentData ?? 0,
          StudentEmailId: response?.StudentEmailId ?? 0,
          StudentName: response?.StudentName ?? 0,
          Total: response?.Total ?? 0,
          assignment: {
            total: assignment?.total ?? 0,
            reSubmit: assignment?.Resubmit ?? 0,
            completed: assignment?.Completed ?? 0,
            pending: assignment?.Pending ?? 0,
            gradesPending: assignment?.["Grades Pending"] ?? 0,
          },
          event: {
            registered: event?.registered ?? 0,
            bookmarked: event?.bookmarked ?? 0,
            organized: event?.organized ?? 0,
            total: event?.total ?? 0,
          },
          feedback: {
            total: feedback?.total ?? 0,
            poll: feedback?.poll ?? 0,
            text: feedback?.text ?? 0,
            audio: feedback?.audio ?? 0,
            video: feedback?.video ?? 0,
            mixed: feedback?.mixed ?? 0,
            any: feedback?.any ?? 0,
          },
          info: {
            ...info,
            subjects:
              info.subjects && Array.isArray(info.subjects)
                ? info.subjects
                : [],
            classNumber: info.classNumber ?? "",
            attendanceAverage: info.attendanceAverage ?? 0,
            assignmentAverage: info.assignmentAverage ?? 0,
            studentAccountInfo: info.studentAccountInfo,
          },
          chart,
        });
        setCustomFieldInfo(res.data.custom_field);
      }
    } catch (error) {
      toast.error(error?.response?.data?.message);
      console.log(error);
    } finally {
      setIsLoadingData(false);
    }
  };

  const myProgressBtnHandler = (email) => {
    setIsMyProgressModalOpen(true);
    getInfo(email);
  };

  const chartSelectHandler = (type) => {
    setSelectedChart(type);
  };

  const scrollButtonHandler = (isScrollToRight) => {
    document.getElementById("scroll-statictics-container").scrollBy({
      left: isScrollToRight ? 150 : -150,
      top: 0,
      behavior: "smooth",
    });
  };

  return (
    <>
      <Dialog
        className="student-progress-dialog"
        open={isMyProgressModalOpen}
        confirmButton={t("setting").init[3]}
        onConfirm={() => {
          setIsMyProgressModalOpen(false);
        }}
        header={<Text content={headerText} className="Karla fw6 m1" />}
        content={
          <Flex
            style={{ minHeight: "200px" }}
            className="student-data-wrapper student-teacher-progress-wrapper"
          >
            {progressData ? (
              <>
                <Flex
                  column
                  className="student-image-info-wrapper"
                  gap="gap.medium"
                >
                  <Image
                    src={
                      progressData.info?.studentAccountInfo?.studPhoto?.Foto
                        ? progressData.info.studentAccountInfo.studPhoto.Foto +
                          user.SASToken
                        : userSvg
                    }
                    className="m-img"
                  />

                  <Flex
                    column
                    className="student-info-wrapper"
                    gap="gap.smaller"
                  >
                    <Flex gap="gap.small" wrap>
                      <Text
                        content={`${t("setting").student.info[0]}:`}
                        className="Karla fw6 tcap"
                      />
                      <Tooltip
                        content={
                          progressData.info?.studentAccountInfo.StudentName
                        }
                        trigger={
                          <Text
                            content={
                              progressData.info?.studentAccountInfo.StudentName
                            }
                            className="Karla tcap field-value"
                          />
                        }
                      />
                    </Flex>
                    <Flex gap="gap.small" wrap>
                      <Text
                        content={`${t("setting").student.info[1]}:`}
                        className="Karla fw6 tcap"
                      />
                      <Tooltip
                        content={
                          progressData.info?.studentAccountInfo.StudentEmailID
                        }
                        trigger={
                          <Text
                            content={
                              progressData.info?.studentAccountInfo
                                .StudentEmailID
                            }
                            className="Karla tlow field-value"
                          />
                        }
                      />
                    </Flex>
                    <Flex gap="gap.small" wrap>
                      <Text
                        content={`${t("setting").student.info[2]}:`}
                        className="Karla fw6 tcap"
                      />
                      <Text
                        content={
                          progressData?.info?.classNumber?.join(", ") ?? ""
                        }
                        className="Karla tcap"
                      />
                    </Flex>
                    <Flex gap="gap.small" wrap>
                      <Text
                        content={`${t("setting").student.info[3]}:`}
                        className="Karla fw6 tcap"
                      />
                      <Text
                        content={progressData?.info?.attendanceAverage ?? ""}
                        className="Karla tcap"
                      />
                    </Flex>
                    <Flex gap="gap.small" wrap>
                      <Text
                        content={`${t("setting").student.info[4]}:`}
                        className="Karla fw6 tcap"
                      />
                      <Text
                        content={progressData?.info?.assignmentAverage ?? ""}
                        className="Karla tcap"
                      />
                    </Flex>
                    <Flex gap="gap.small" wrap>
                      <Text
                        content={`${t("setting").student.info[5]}:`}
                        className="Karla fw6 tcap"
                      />
                      <div className="Karla tcap">
                        {Array.isArray(progressData?.info?.subjects) ? (
                          <Flex column className="subjects-wrapper">
                            {progressData.info.subjects.map((item) => (
                              <span key={item}>{item}</span>
                            ))}
                          </Flex>
                        ) : (
                          <Text
                            content={`${t("setting").student.info[6]}`}
                            className="Karla tcap"
                          />
                        )}
                      </div>
                    </Flex>
                  </Flex>

                  {!!progressData?.StudentData && (
                    <Flex wrap column>
                      <Divider content={t("setting").student.attendance[1]} />
                      {customFieldInfo?.map((cf) => {
                        return (
                          <Text
                            content={
                              <>
                                <FlagIcon outline className="pb2 pr2" />
                                <Text
                                  content={" " + cf.Name}
                                  color="brand"
                                  className="fw6"
                                  disabled={!cf.IsEnable}
                                />{" "}
                                -{" "}
                                {
                                  progressData.StudentData[
                                    "CF_" + cf.CustomFieldID
                                  ]
                                }
                              </>
                            }
                            className="Karla m1 tcap"
                            key={cf.CustomFieldID}
                            disabled={!cf.IsEnable}
                          />
                        );
                      })}
                    </Flex>
                  )}
                </Flex>
                <Flex column className="info-chart-wrapper">
                  <Flex vAlign="center">
                    <div className="scroll-button-wrapper">
                      <Button
                        className="scroll-button"
                        onClick={() => {
                          scrollButtonHandler(false);
                        }}
                      >
                        <ChevronStartIcon size="large" />
                      </Button>
                    </div>
                    <div
                      className="statictics-container"
                      id="scroll-statictics-container"
                    >
                      <Flex wrap className="statictics-wrapper">
                        <Flex wrap column>
                          <Divider
                            content={t("setting").student.attendance[0]}
                            onClick={() =>
                              chartSelectHandler(chartTypes.attendance)
                            }
                            className={`selectable-option ${
                              selectedChart === chartTypes.attendance
                                ? "active-chart"
                                : ""
                            }`}
                          />
                          <Text
                            content={
                              <>
                                <PollIcon outline className="pb2 pr2" />
                                <Text
                                  content={t("setting").student.attendance[2]}
                                  color="brand"
                                  className="fw6"
                                />{" "}
                                - {progressData.Total}
                              </>
                            }
                            className="Karla m1 tcap"
                          />
                          <Text
                            content={
                              <>
                                <AcceptIcon outline className="pb2 pr2" />
                                <Text
                                  content={t("setting").student.attendance[3]}
                                  color="brand"
                                  className="fw6"
                                />{" "}
                                - {parseInt(progressData.Presente)}
                              </>
                            }
                            className="Karla m1 tcap"
                          />
                          <Text
                            content={
                              <>
                                <CloseIcon outline className="pb2 pr2" />
                                <Text
                                  content={t("setting").student.attendance[4]}
                                  color="brand"
                                  className="fw6"
                                />{" "}
                                - {parseInt(progressData.Ausente)}
                              </>
                            }
                            className="Karla m1 tcap"
                          />
                          <Text
                            content={
                              <>
                                <ShiftActivityIcon
                                  outline
                                  className="pb2 pr2"
                                />
                                <Text
                                  content={t("setting").student.attendance[5]}
                                  color="brand"
                                  className="fw6"
                                />{" "}
                                - {parseInt(progressData.Retardo)}
                              </>
                            }
                            className="Karla m1 tcap"
                          />
                          <Text
                            content={
                              <>
                                <SurveyIcon outline className="pb2 pr2" />
                                <Text
                                  content={t("setting").student.attendance[6]}
                                  color="brand"
                                  className="fw6"
                                />{" "}
                                - {parseInt(progressData.Justificado)}
                              </>
                            }
                            className="Karla m1 tcap"
                          />
                        </Flex>

                        <Flex column>
                          <Divider
                            content={t("setting").student.assignment[0]}
                            onClick={() =>
                              chartSelectHandler(chartTypes.assignment)
                            }
                            className={`selectable-option ${
                              selectedChart === chartTypes.assignment
                                ? "active-chart"
                                : ""
                            }`}
                          />
                          <Text
                            content={
                              <>
                                <PollIcon outline className="pb2 pr2" />
                                <Text
                                  content={t("setting").student.assignment[1]}
                                  color="brand"
                                  className="fw6"
                                />{" "}
                                - {progressData.assignment.total}
                              </>
                            }
                            className="Karla m1 tcap"
                          />
                          <Text
                            content={
                              <>
                                <AcceptIcon outline className="pb2 pr2" />
                                <Text
                                  content={t("setting").student.assignment[2]}
                                  color="brand"
                                  className="fw6"
                                />{" "}
                                - {parseInt(progressData.assignment.completed)}
                              </>
                            }
                            className="Karla m1 tcap"
                          />
                          <Text
                            content={
                              <>
                                <RetryIcon outline className="pb2 pr2" />
                                <Text
                                  content={t("setting").student.assignment[3]}
                                  color="brand"
                                  className="fw6"
                                />{" "}
                                - {parseInt(progressData.assignment.reSubmit)}
                              </>
                            }
                            className="Karla m1 tcap"
                          />
                          <Text
                            content={
                              <>
                                <ShiftActivityIcon
                                  outline
                                  className="pb2 pr2"
                                />
                                <Text
                                  content={t("setting").student.assignment[4]}
                                  color="brand"
                                  className="fw6"
                                />{" "}
                                - {parseInt(progressData.assignment.pending)}
                              </>
                            }
                            className="Karla m1 tcap"
                          />
                          <Text
                            content={
                              <>
                                <SurveyIcon outline className="pb2 pr2" />
                                <Text
                                  content={t("setting").student.assignment[5]}
                                  color="brand"
                                  className="fw6"
                                />{" "}
                                -{" "}
                                {parseInt(
                                  progressData.assignment.gradesPending
                                )}
                              </>
                            }
                            className="Karla m1 tcap"
                          />
                        </Flex>
                        <Flex wrap column>
                          <Divider
                            content={t("setting").student.event[0]}
                            onClick={() => chartSelectHandler(chartTypes.event)}
                            className={`selectable-option ${
                              selectedChart === chartTypes.event
                                ? "active-chart"
                                : ""
                            }`}
                          />
                          {/* <Text
                  content={
                    <>
                      <PollIcon outline className="pb2 pr2" />
                      <Text
                        content={t("setting").student.event[1]}
                        color="brand"
                        className="fw6"
                      />{" "}
                      - {progressData.event.total}
                    </>
                  }
                  className="Karla m1 tcap"
                /> */}
                          <Text
                            content={
                              <>
                                <AcceptIcon outline className="pb2 pr2" />
                                <Text
                                  content={t("setting").student.event[2]}
                                  color="brand"
                                  className="fw6"
                                />{" "}
                                - {parseInt(progressData.event.registered)}
                              </>
                            }
                            className="Karla m1 tcap"
                          />
                          <Text
                            content={
                              <>
                                <BookmarkIcon outline className="pb2 pr2" />
                                <Text
                                  content={t("setting").student.event[3]}
                                  color="brand"
                                  className="fw6"
                                />{" "}
                                - {parseInt(progressData.event.bookmarked)}
                              </>
                            }
                            className="Karla m1 tcap"
                          />
                          <Text
                            content={
                              <>
                                <PresenterIcon outline className="pb2 pr2" />
                                <Text
                                  content={t("setting").student.event[4]}
                                  color="brand"
                                  className="fw6"
                                />{" "}
                                - {parseInt(progressData.event.organized)}
                              </>
                            }
                            className="Karla m1 tcap"
                          />
                        </Flex>

                        <Flex column>
                          <Divider
                            content={t("setting").student.feedback[0]}
                            onClick={() =>
                              chartSelectHandler(chartTypes.feedback)
                            }
                            className={`selectable-option ${
                              selectedChart === chartTypes.feedback
                                ? "active-chart"
                                : ""
                            }`}
                          />
                          <Text
                            content={
                              <>
                                <PollIcon outline className="pb2 pr2" />
                                <Text
                                  content={t("setting").student.feedback[1]}
                                  color="brand"
                                  className="fw6"
                                />{" "}
                                - {progressData.feedback.total}
                              </>
                            }
                            className="Karla m1 tcap"
                          />
                          <Text
                            content={
                              <>
                                <PollIcon outline className="pb2 pr2" />
                                <Text
                                  content={t("setting").student.feedback[2]}
                                  color="brand"
                                  className="fw6"
                                />{" "}
                                - {parseInt(progressData.feedback.poll)}
                              </>
                            }
                            className="Karla m1 tcap"
                          />
                          <Text
                            content={
                              <>
                                <ItalicIcon outline className="pb2 pr2" />
                                <Text
                                  content={t("setting").student.feedback[3]}
                                  color="brand"
                                  className="fw6"
                                />{" "}
                                - {parseInt(progressData.feedback.text)}
                              </>
                            }
                            className="Karla m1 tcap"
                          />
                          <Text
                            content={
                              <>
                                <VolumeIcon outline className="pb2 pr2" />
                                <Text
                                  content={t("setting").student.feedback[4]}
                                  color="brand"
                                  className="fw6"
                                />{" "}
                                - {parseInt(progressData.feedback.audio)}
                              </>
                            }
                            className="Karla m1 tcap"
                          />
                          <Text
                            content={
                              <>
                                <CallVideoIcon outline className="pb2 pr2" />
                                <Text
                                  content={t("setting").student.feedback[5]}
                                  color="brand"
                                  className="fw6"
                                />{" "}
                                - {parseInt(progressData.feedback.video)}
                              </>
                            }
                            className="Karla m1 tcap"
                          />
                          {/* <Text
                content={
                  <>
                    <Icon
                      iconName="PieSingle"
                      className="pb2 pr2"
                    />
                    <Text
                      content={t("setting").student.feedback[6]}
                      color="brand"
                      className="fw6"
                    />{" "}
                    - {parseInt(progressData.feedback.any)}
                  </>
                }
                className="Karla m1 tcap"
              />
              <Text
                content={
                  <>
                    <Text
                      style={{
                        fontStyle: "italic",
                        marginRight: "5px",
                        lineHeight: 1,
                      }}
                      size="large"
                      weight="semilight"
                    >
                      M
                    </Text>
                    <Text
                      content={t("setting").student.feedback[7]}
                      color="brand"
                      className="fw6"
                    />{" "}
                    - {parseInt(progressData.feedback.mixed)}
                  </>
                }
                className="Karla m1 tcap"
              /> */}
                        </Flex>
                      </Flex>
                    </div>
                    <div className="scroll-button-wrapper">
                      <Button
                        className="scroll-button"
                        onClick={() => {
                          scrollButtonHandler(true);
                        }}
                      >
                        <ChevronEndIcon size="large" />
                      </Button>
                    </div>
                  </Flex>
                  <ProgressChart
                    selectedChart={selectedChart}
                    data={progressData.chart}
                    chartTypes={chartTypes}
                  />
                </Flex>
              </>
            ) : isLoadingData ? (
              <Flex
                style={{ flexGrow: 1 }}
                hAlign="center"
                vAlign="center"
                column
              >
                <Loader size="small" />
              </Flex>
            ) : (
              ""
            )}
          </Flex>
        }
        trigger={triggerButton((email) => myProgressBtnHandler(email))}
        headerAction={{
          icon: <CloseIcon />,
          title: translation.common.close,
          onClick: () => {
            setIsMyProgressModalOpen(false);
          },
        }}
      />
    </>
  );
};

export default withTranslation()(StudentProgress);
