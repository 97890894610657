import React, { useEffect, useReducer, memo } from "react";
import JoyRide, { ACTIONS, EVENTS, STATUS } from "react-joyride";
import { InfoIcon } from "@fluentui/react-icons-northstar";
import { Button } from "@fluentui/react-northstar";
import { withTranslation } from "react-i18next";

const ATTENDANCE_NO_LESSONS_TOUR_STEPS = [
  {
    target: ".tour-attendence-no-class-available",
    content: "No lessons available for this date. Please select another date.",
    disableBeacon: true, // This makes the tour to start automatically without clicking
  },
];
const ATTENDANCE_HOME_TOUR_STEPS = [
  {
    target: ".tour-attendence-find-class",
    content: "Here you can find class by date, classname or teacher name.",
    disableBeacon: true, // This makes the tour to start automatically without clicking
  },
  {
    target: ".tour-select-lesson",
    content:
      "This is where you can find all classes. Select one class to take attendance of students.",
    placement: "right",
  },
];

const ATTENDANCE_CLASS_TOUR_STEPS = [
  {
    target: ".tour-attendence-students",
    content:
      "Here you can find all students for selected class. For attendance there are 3 options which are Present, Absent (Illness, Family Emergency, Not feeling well) and Late. You can also add notes for the student.",
    disableBeacon: true, // This makes the tour to start automatically without clicking
  },
  {
    target: ".tour-attendence-topic",
    content: "Click here to add Topic of this class.",
  },
  {
    target: ".tour-attendence-homework",
    content: "Click here to add Homework of this class.",
  },
  {
    target: ".tour-attendence-summary",
    content: "This is where you can view the Attendance summary of class.",
  },
  {
    target: ".tour-attendence-generate-report",
    content: "Click here to generate an Attendance Report in Excel.",
  },
];

// const WHITEBOARD_HOME_TOUR_STEPS = [
//   {
//     target: ".tour-whiteboard-find-class",
//     content: "Here you can find class by date, classname or teacher name.",
//     disableBeacon: true,
//   },
//   {
//     target: ".tour-select-lesson",
//     content:
//       "This is where you can find all classes. Select one class to join or start collaboration.",
//     placement: "right",
//   },
// ];

// const WHITEBOARD_CLASS_TOUR_STEPS = [
//   {
//     target: ".frameTag section .top-menu-section",
//     content: "From here you can add different shapes, text and images.",
//     disableBeacon: true, // This makes the tour to start automatically without clicking
//   },
//   {
//     target: "iframe .CollabButton",
//     content:
//       "From here you can invite people to your current scene to collaborate with you.",
//     placement: "right",
//   },
// ];

// Tour component
const Tour = ({ isClassSelected, isLessonsAvailable, page, t, i18n }) => {
  // Tour state is the state which control the JoyRide component

  // useEffect(() => {
  //   // Auto start the tour if the tour is not viewed before
  //   if (!isVisited) {
  //     setTimeout(() => {
  //       dispatch({ type: "START" });
  //     }, 5000);
  //   }
  // }, [isVisited]);

  const translation = t("attendance").tour;

  useEffect(() => {
    dispatch({ type: "CHANGE_STEPS", payload: getTourSteps(isClassSelected) });
  }, [i18n.language, isLessonsAvailable, isClassSelected]);

  const getTourSteps = (isClassSelected) => {
    if (isClassSelected) {
      return ATTENDANCE_CLASS_TOUR_STEPS.map((item, index) => ({
        ...item,
        content: translation.class[index],
      }));
    } else if (isLessonsAvailable) {
      return ATTENDANCE_HOME_TOUR_STEPS.map((item, index) => ({
        ...item,
        content: translation.home[index],
      }));
    }
    return ATTENDANCE_NO_LESSONS_TOUR_STEPS.map((item, index) => ({
      ...item,
      content: translation.noClassAvailable[index],
    }));
  };

  const INITIAL_STATE = {
    key: new Date(), // This field makes the tour to re-render when we restart the tour
    run: false,
    continuous: true,
    loading: false,
    stepIndex: 0,
    steps: getTourSteps(),
  };

  // Reducer will manage updating the local state
  const reducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
      case "START":
        return { ...state, run: true };
      case "RESET":
        return { ...state, stepIndex: 0 };
      case "STOP":
        return { ...state, run: false };
      case "NEXT_OR_PREV":
        return { ...state, ...action.payload };
      case "RESTART":
        return {
          ...state,
          stepIndex: 0,
          run: true,
          loading: false,
          key: new Date(),
        };

      case "CHANGE_STEPS":
        return { ...state, steps: action.payload };
      default:
        return state;
    }
  };
  const [tourState, dispatch] = useReducer(reducer, INITIAL_STATE);

  // Set once tour is viewed, skipped or closed
  const setTourViewed = () => {
    // localStorage.setItem("tour", "1");
  };

  const callback = (data) => {
    const { action, index, type, status } = data;

    if (
      // If close button clicked, then close the tour
      action === ACTIONS.CLOSE ||
      // If skipped or end tour, then close the tour
      (status === STATUS.SKIPPED && tourState.run) ||
      status === STATUS.FINISHED
    ) {
      setTourViewed();
      dispatch({ type: "STOP" });
    } else if (type === EVENTS.STEP_AFTER || type === EVENTS.TARGET_NOT_FOUND) {
      // Check whether next or back button click and update the step.
      dispatch({
        type: "NEXT_OR_PREV",
        payload: { stepIndex: index + (action === ACTIONS.PREV ? -1 : 1) },
      });
    }
  };

  const startTour = () => {
    // Start the tour manually
    dispatch({ type: "RESTART" });
  };


  return (
    <>
      <Button
        icon={<InfoIcon circular outline size="large" />}
        iconOnly
        title={translation.tour}
        onClick={startTour}
      />
      <JoyRide
        {...tourState}
        callback={callback}
        styles={{
          tooltipContainer: {
            textAlign: "left",
          },
          buttonBack: {
            marginRight: 10,
          },
          buttonNext: {
            borderRadius: 4,
            color: "red",
            border: "1px solid #EB5757",
            backgroundColor: "#fff",
          },
        }}
        locale={{
          last: translation.endTour,
          next: translation.next,
          back: translation.back,
        }}
        showProgress
        disableScrolling={true}
      />
    </>
  );
};

export default withTranslation()(memo(Tour));
