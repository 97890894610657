export const generateRandomChar = (min, max) =>
  String.fromCharCode(Math.floor(Math.random() * (max - min + 1) + min));

export const generateCaptchaText = () => {
  let captcha = "";
  for (let i = 0; i < 2; i++) {
    captcha += generateRandomChar(65, 90);
    captcha += generateRandomChar(97, 122);
    captcha += generateRandomChar(48, 57);
  }
  return captcha
    .split("")
    .sort(() => Math.random() - 0.5)
    .join("");
};

export const drawCaptchaOnCanvas = (ctx, captcha) => {
  ctx.clearRect(0, 0, ctx.canvas.width, ctx.canvas.height);
  const textColors = ["rgb(0,0,0)", "rgb(130,130,130)"];
  const letterSpace = 150 / captcha.length;
  for (let i = 0; i < captcha.length; i++) {
    const xInitialSpace = 25;
    ctx.font = "20px Roboto Mono";
    ctx.fillStyle = textColors[Math.floor(Math.random() * 2)];
    ctx.fillText(
      captcha[i],
      xInitialSpace + i * letterSpace,

      // Randomize Y position slightly
      Math.floor(Math.random() * 16 + 25),
      100
    );
  }
};
