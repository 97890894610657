import { useEffect, useState } from "react";
import {
  Button,
  Text,
  Flex,
  ArrowLeftIcon,
  CardHeader,
  CardFooter,
  CardBody,
  Card,
  CardColumn,
  Loader,
  Dialog,
  Grid,
  InfoIcon,
  Alert,
} from "@fluentui/react-northstar";
import { useHistory, useLocation, useParams } from "react-router-dom";
import AssignmentButton from "./AssignmentButton";
import { IconButton } from "@fluentui/react";
import axios from "axios";
import moment from "moment";
import styled from "styled-components";
import { withTranslation } from "react-i18next";

import { useStudentSubmission } from "../../../../../context/StudentSubmissionContext";

import NoAssignmentFound from "../../../../../Assets/images/svg/NoAssignmentFound.svg";

import "./AssignmentList.css";

const url = process.env.REACT_APP_EP_URL;

const isLast30DaysAssignment = "isLast30DaysAssignment";
const isAllAssignment = "isAllAssignment";

const AssignmentsSection = styled(Grid)`
  grid-template-columns: repeat(auto-fill, minmax(270px, 1fr));
  grid-gap: 10px;
  margin-top: 15px;
`;

const SkeletonCard = styled(Flex)`
  height: 140px;
  border-radius: 7px !important;
  cursor: pointer;
  @media screen and (max-width: 880px) {
    &.card-md-none {
      display: none;
    }
  }
  @media screen and (max-width: 600px) {
    &.card-sm-none {
      display: none;
    }
  }
`;

const AssignmentList = (props) => {
  const { selectedLesson, user, t } = props;
  const history = useHistory();

  const { subjectCode } = useParams();

  const {
    setCurrentAssignmentData,
    currentSubjectData,
    setStudentAttachmentsForAssignment,
    setCurrentPage,
    globalSearch,
    dateFilter,
    defaultDateHandler,
    isFilteredByCreationDate,
  } = useStudentSubmission();

  const translation = t("assignment").body;
  const statusTranslation = translation.studentSubmissionStatus;
  const assignmentListTranslation = translation.assignmentList;

  const [currentSubjectCode, setCurrentSubjectCode] = useState(null);
  const [assignmentList, setAssignmentList] = useState([]);
  const [filteredAssignmentList, setFilteredAssignmentList] = useState([]);
  const [assignmentStatusList, setAssignmentStatusList] = useState({});
  const [currentAssignmentFilter, setCurrentAssignmentFilter] = useState("All");
  const [isLoading, setIsLoading] = useState(false);
  const [showAlert, setShowAlert] = useState(false);

  useEffect(() => {
    defaultDateHandler();
    setCurrentPage("assignmentList");
  }, []);

  useEffect(() => {
    const fetchAssignmentList = async () => {
      setIsLoading(true);
      try {
        let response = await axios({
          method: "get",
          url: `${url}/api/assignment/get-assignment-for-student-by-subject${
            user.slug
          }&studentEmailId=${
            user.username || user.mail
          }&subject=${subjectCode}`,
          headers: {
            Authorization: "Bearer " + user.accessToken,
          },
        });

        if (response.data.results?.length) {
          let data = response.data.results
            .filter(
              (assignment) =>
                assignment.AssingmentStudents?.[0]?.submissionStatus
            )
            .map((assignment) => {
              let attachmentOfStudent = assignment.Attachments?.filter(
                (item) => item.role === "student"
              );
              let attachmentOfTeacher = assignment.Attachments?.filter(
                (item) => item.role === "teacher"
              );
              return {
                ...assignment,
                id: assignment.id,
                dueDate: convertToLocalDateString(assignment.dueDate),

                assignmentTitle: assignment.title,
                instruction: assignment.instruction,

                rubricId: assignment.rubricBankId,

                studentAttachments: [
                  {
                    type: "text",
                    info: attachmentOfStudent?.find(
                      (item) => item.type == "text"
                    )?.url,
                    id:
                      attachmentOfStudent?.find((item) => item.type == "text")
                        ?.id || 0,
                  },
                  {
                    type: "voice",
                    info:
                      attachmentOfStudent?.find((item) => item.type == "voice")
                        ?.url || "",
                    id:
                      attachmentOfStudent?.find((item) => item.type == "voice")
                        ?.id || 0,
                  },
                  {
                    type: "video",
                    info:
                      attachmentOfStudent?.find((item) => item.type == "video")
                        ?.url || "",
                    id:
                      attachmentOfStudent?.find((item) => item.type == "video")
                        ?.id || 0,
                  },
                  {
                    type: "link",
                    info:
                      attachmentOfStudent?.find((item) => item.type == "link")
                        ?.url || "",
                    id:
                      attachmentOfStudent?.find((item) => item.type == "link")
                        ?.id || 0,
                  },
                  {
                    type: "file",
                    info: attachmentOfStudent
                      ?.find((item) => item.type == "file")
                      ?.url?.split(","),
                    id:
                      attachmentOfStudent?.find((item) => item.type == "file")
                        ?.id || 0,
                  },
                ],

                attachments: [
                  {
                    type: "text",
                    info: attachmentOfTeacher?.find(
                      (item) => item.type == "text"
                    )?.url,
                  },
                  {
                    type: "voice",
                    info:
                      attachmentOfTeacher?.find((item) => item.type == "voice")
                        ?.url || "",
                  },
                  {
                    type: "video",
                    info:
                      attachmentOfTeacher?.find((item) => item.type == "video")
                        ?.url || "",
                  },
                  {
                    type: "link",
                    info: attachmentOfTeacher?.find(
                      (item) => item.type == "link"
                    )?.url,
                  },
                  {
                    type: "file",
                    info: attachmentOfTeacher
                      ?.find((item) => item.type == "file")
                      ?.url?.split(","),
                  },
                ],

                submittedOn: convertToLocalDateString(
                  assignment.AssingmentStudents[0]?.submissionDate
                ),

                gradedOn: convertToLocalDateString(
                  assignment.AssingmentStudents[0]?.gradedOn
                ),
                submissionStatus:
                  assignment.AssingmentStudents[0]?.submissionStatus,
              };
            });
          let groupedData = data.reduce((group, assignment) => {
            const { submissionStatus } = assignment;
            let newStatus = ["Pending", "Resubmit"].includes(submissionStatus)
              ? "Remaining"
              : "Submitted";
            group[newStatus] = group[newStatus] ?? [];
            group[newStatus].push(assignment);
            return group;
          }, {});
          const sortedRemaining =
            groupedData.Remaining?.sort(
              (a, b) => new Date(a.dueDate) - new Date(b.dueDate)
            ) || [];
          const sortedSubmitted =
            groupedData.Submitted?.sort(
              (a, b) => new Date(a.dueDate) - new Date(b.dueDate)
            ) || [];
          let sortedData = [...sortedRemaining, ...sortedSubmitted];
          setAssignmentList(sortedData);
          setFilteredAssignmentList(sortedData);
        } else {
          setAssignmentList([]);
          setFilteredAssignmentList([]);
        }
      } catch (error) {
        console.log("error", error);
      } finally {
        setIsLoading(false);
        setCurrentAssignmentData(null);
      }
    };
    fetchAssignmentList();
    setCurrentSubjectCode(subjectCode);
  }, [subjectCode]);

  useEffect(() => {
    setFilteredAssignmentList(() => {
      let data = assignmentList;

      if (globalSearch) {
        data = data.filter((item) =>
          item.title?.toLowerCase()?.includes(globalSearch.toLowerCase())
        );
      }
      if (dateFilter?.start && moment(dateFilter.start).isValid()) {
        data = data.filter((item) => {
          const date = isFilteredByCreationDate
            ? item.pusblishedDate
            : item.dueDate;
          return date && moment(date).isValid()
            ? moment(date) >= moment(dateFilter.start)
            : false;
        });
      }
      if (dateFilter?.end && moment(dateFilter.end).isValid()) {
        data = data.filter((item) => {
          const date = isFilteredByCreationDate
            ? item.pusblishedDate
            : item.dueDate;
          return date && moment(date).isValid()
            ? moment(date) <= moment(dateFilter.end)
            : false;
        });
      }
      if (
        dateFilter?.start &&
        moment(dateFilter.start).isValid() &&
        dateFilter?.end &&
        moment(dateFilter.end).isValid()
      ) {
        let startDate = moment(dateFilter.start);
        let endDate = moment(dateFilter.end);
        let diff = startDate.diff(endDate, "days");
        setShowAlert(diff === -29 ? isLast30DaysAssignment : null);
      } else if (!dateFilter?.start && !dateFilter?.end) {
        setShowAlert(isAllAssignment);
      } else {
        setShowAlert(null);
      }
      let statusObj = data.reduce((group, assignment) => {
        const { submissionStatus } = assignment;
        group[submissionStatus] = group[submissionStatus]
          ? group[submissionStatus] + 1
          : 1;
        return group;
      }, {});
      statusObj.Pending = Number(statusObj.Pending) || 0;
      statusObj.Completed = Number(statusObj.Completed) || 0;
      statusObj.Resubmit = Number(statusObj.Resubmit) || 0;
      statusObj["Grades Pending"] = Number(statusObj["Grades Pending"]) || 0;
      delete statusObj.null;
      delete statusObj[""];
      setAssignmentStatusList(statusObj);

      if (currentAssignmentFilter && currentAssignmentFilter !== "All") {
        data = data.filter(
          (assignment) =>
            currentAssignmentFilter === assignment.submissionStatus
        );
      }

      return data;
    });
  }, [
    currentAssignmentFilter,
    assignmentList,
    globalSearch,
    dateFilter,
    isFilteredByCreationDate,
  ]);

  const convertToLocalDateString = (date) => {
    // let time = moment.utc().format("HH:mm");
    // return date && moment(date).isValid()
    //   ? moment.utc(`${date} ${time}`).local().format("YYYY-MM-DD")
    //   : null;
    return date && moment(date).isValid() ? moment.utc(date).local() : null;
  };

  const getTextContentFromUrl = (url) => {
    if (url) {
      try {
        return fetch(url)
          .then((response) => response.text())
          .then((data) => data);
      } catch (error) {
        return "";
      }
    } else {
      return "";
    }
  };

  const getLinkContentFromUrl = (url) => {
    if (url) {
      try {
        return fetch(url)
          .then((response) => response.text())
          .then((data) => data.split(","));
      } catch (error) {
        return "";
      }
    } else {
      return "";
    }
  };

  const formatAttachmentData = async (attachments) => {
    let attachment = [];
    try {
      const textInfo = await getTextContentFromUrl(
        attachments?.find((item) => item.type == "text")?.info
      );
      const linkInfo = await getLinkContentFromUrl(
        attachments?.find((item) => item.type == "link")?.info
      );
      attachment = [
        {
          type: "text",
          info: textInfo,
          id: attachments?.find((item) => item.type == "text")?.id,
        },
        {
          type: "voice",
          info: attachments?.find((item) => item.type == "voice")?.info || "",
          id: attachments?.find((item) => item.type == "voice")?.id,
        },
        {
          type: "video",
          info: attachments?.find((item) => item.type == "video")?.info || "",
          id: attachments?.find((item) => item.type == "video")?.id,
        },
        {
          type: "link",
          info: linkInfo,
          id: attachments?.find((item) => item.type == "link")?.id,
        },
        {
          type: "file",
          info: attachments?.find((item) => item.type == "file")?.info
            ? [...attachments?.find((item) => item.type == "file")?.info]
            : [],
          id: attachments?.find((item) => item.type == "file")?.id,
        },
      ];
    } catch (error) {
      console.log(error);
    } finally {
      return attachment;
    }
  };

  const formatCurrentAssignmentData = async (assign) => {
    try {
      setIsLoading(true);
      let attachments = await formatAttachmentData(assign.attachments);
      setCurrentAssignmentData({
        ...assign,
        attachments,
      });
      let studentAttachments = await formatAttachmentData(
        assign.studentAttachments
      );
      setStudentAttachmentsForAssignment(studentAttachments);
    } catch (error) {
      console.log(error);
    } finally {
      setIsLoading(false);
    }
  };

  const fetchFormattedData = async (assignData) => {
    history.push(`/${subjectCode}/assignments/${assignData.id}`);
  };

  const getSubmissionStatus = (submissionStatus) => {
    switch (submissionStatus) {
      case "Pending":
        return statusTranslation.pending;
      case "Grades Pending":
        return statusTranslation.gradesPending;
      case "Resubmit":
        return statusTranslation.resubmit;
      case "Completed":
        return statusTranslation.completed;
      default:
        return submissionStatus;
    }
  };

  const myCard = (assign) => {
    return (
      <Card
        className="mt-3"
        elevated
        styles={{
          borderRadius: "10px",
          ":hover": { backgroundColor: "white" },
          backgroundColor: "white",
          cursor: "pointer",
          boxShadow:
            "0 3.2px 7.2px 0 rgb(0 0 0 / 13%), 0 0.6px 1.8px 0 rgb(0 0 0 / 11%)",
        }}
        onClick={async () => {
          await fetchFormattedData(assign);
        }}
        fluid
      >
        <CardColumn>
          <CardHeader>
            <Text
              content={assign.title}
              size="large"
              weight="bold"
              style={{
                marginTop: "6px",
                textOverflow: "ellipsis",
                whiteSpace: "nowrap",
                overflow: "hidden",
                height: "24px",
              }}
            />
          </CardHeader>
          <CardBody>
            <div>
              <Flex column>
                <Flex gap="gap.smaller">
                  <Text
                    className="text-blue"
                    weight="semibold"
                    content={
                      assign.submissionStatus === "Completed"
                        ? `${assignmentListTranslation.gradedOn}: `
                        : assign.submissionStatus === "Grades Pending"
                        ? `${assignmentListTranslation.submittedOn}: `
                        : `${assignmentListTranslation.due}: `
                    }
                    size="small"
                    style={{ marginTop: "6px" }}
                  />
                  <Text
                    weight="semibold"
                    content={
                      moment(
                        assign.submissionStatus === "Completed"
                          ? assign.gradedOn
                          : assign.submissionStatus === "Grades Pending"
                          ? assign.submittedOn
                          : assign.dueDate
                      ).isValid()
                        ? moment(
                            assign.submissionStatus === "Completed"
                              ? assign.gradedOn
                              : assign.submissionStatus === "Grades Pending"
                              ? assign.submittedOn
                              : assign.dueDate
                          ).format("MMM DD, YYYY | HH:mm")
                        : translation.NA
                    }
                    size="small"
                    style={{ marginTop: "6px" }}
                  />
                </Flex>
              </Flex>
            </div>
          </CardBody>
          <CardFooter>
            <Text
              align="start"
              className={
                assign.submissionStatus == "Pending"
                  ? "submission-pending"
                  : assign.submissionStatus == "Grades Pending"
                  ? "grades-pending"
                  : assign.submissionStatus == "Resubmit"
                  ? "resubmit"
                  : "completed"
              }
              weight="semibold"
              content={
                assign.submissionStatus === "Pending"
                  ? assignmentListTranslation.submissionPending
                  : getSubmissionStatus(assign.submissionStatus)
              }
              size="medium"
              style={{ marginTop: "6px", height: "20px" }}
            />
          </CardFooter>
        </CardColumn>
      </Card>
    );
  };

  return (
    <div className="student-assignment-list">
      <div className="tour-all-assignments assignment-list p-2">
        <Flex vAlign="center" wrap>
          <IconButton
            iconProps={{ iconName: "ChromeBack" }}
            title={translation.back}
            ariaLabel={translation.back}
            onClick={() => {
              history.push({
                pathname: "/",
              });
            }}
          />
          <Text
            color="brand"
            size="larger"
            weight="semibold"
            style={{ marginLeft: "10px", fontSize: "1.4rem" }}
          >
            {currentSubjectData.ClassName}
          </Text>
        </Flex>
        <Flex column>
          <Flex wrap className="assignment-status-filter mt-2">
            <Flex.Item>
              <AssignmentButton
                primary={currentAssignmentFilter === "All"}
                content={translation.all}
                onClick={() => {
                  setCurrentAssignmentFilter("All");
                }}
              />
            </Flex.Item>
            <Flex.Item>
              <AssignmentButton
                primary={currentAssignmentFilter === "Pending"}
                content={`${statusTranslation.pending}  (${
                  assignmentStatusList.Pending || 0
                })`}
                onClick={() => {
                  setCurrentAssignmentFilter("Pending");
                }}
              />
            </Flex.Item>
            <Flex.Item>
              <AssignmentButton
                primary={currentAssignmentFilter === "Completed"}
                content={`${statusTranslation.completed}  (${
                  assignmentStatusList.Completed || 0
                })`}
                onClick={() => {
                  setCurrentAssignmentFilter("Completed");
                }}
              />
            </Flex.Item>
            <Flex.Item>
              <AssignmentButton
                primary={currentAssignmentFilter === "Grades Pending"}
                content={`${statusTranslation.gradesPending}  (${
                  assignmentStatusList["Grades Pending"] || 0
                })`}
                onClick={() => {
                  setCurrentAssignmentFilter("Grades Pending");
                }}
              />
            </Flex.Item>

            <Flex.Item>
              <AssignmentButton
                primary={currentAssignmentFilter === "Resubmit"}
                content={`${statusTranslation.resubmit}  (${
                  assignmentStatusList.Resubmit || 0
                })`}
                onClick={() => {
                  setCurrentAssignmentFilter("Resubmit");
                }}
              />
            </Flex.Item>
          </Flex>

          {isLoading ? (
            <AssignmentsSection>
              <SkeletonCard className="skeleton" />
              <SkeletonCard className="card-md-none card-sm-none skeleton" />
              <SkeletonCard className="card-sm-none skeleton" />
            </AssignmentsSection>
          ) : null}
          {filteredAssignmentList?.length && !isLoading ? (
            <div>
              {showAlert ? (
                <Flex hAlign="end">
                  <Alert
                    style={{
                      backgroundColor: "#e6f7ff",
                      border: "1px solid #91d5ff",
                    }}
                    content={
                      <Text>
                        <InfoIcon outline style={{ marginRight: "7px" }} />
                        {showAlert === isLast30DaysAssignment
                          ? assignmentListTranslation.defaultDateAssignments
                          : assignmentListTranslation.allAssignments}
                      </Text>
                    }
                    fitted
                  />
                </Flex>
              ) : null}
              <AssignmentsSection>
                {filteredAssignmentList.map((assignment) => {
                  return (
                    <Flex fill key={assignment.id}>
                      {myCard(assignment)}
                    </Flex>
                  );
                })}
              </AssignmentsSection>
            </div>
          ) : assignmentList.length && !isLoading ? (
            <div>
              <figure
                className="w-100 mt-5 mb-2"
                style={{
                  display: "flex",
                  height: "100%",
                  margin: "auto",
                  justifyContent: "center",
                }}
              >
                <img
                  alt="ACV - Tangible IT"
                  src={NoAssignmentFound}
                  style={{
                    maxWidth: "50%",
                  }}
                  draggable="false"
                />
              </figure>
              <Text
                size="larger"
                weight="semibold"
                content={assignmentListTranslation.noAssignmentForFilter}
                align="center"
              />
            </div>
          ) : !isLoading ? (
            <div>
              <figure
                className="w-100 mt-5 mb-2"
                style={{
                  display: "flex",
                  height: "100%",
                  margin: "auto",
                  justifyContent: "center",
                }}
              >
                <img
                  alt="ACV - Tangible IT"
                  src={NoAssignmentFound}
                  style={{
                    maxWidth: "50%",
                  }}
                  draggable="false"
                />
              </figure>
              <Text
                size="large"
                weight="semibold"
                content={assignmentListTranslation.noAssignmentForSubject}
                align="center"
              />
            </div>
          ) : null}
        </Flex>
      </div>
    </div>
  );
};
export default withTranslation()(AssignmentList);
