import { Checkbox, Flex } from "@fluentui/react-northstar";
import React, { useEffect, useState } from "react";
import "./Slots.scss";
import moment from "moment";
import { useBooking } from "../../../../context/BookingContext";
import SlotList from "./SlotList";

const Slots = ({ isMobileView }) => {
  const {
    slots,
    generateTimeSlots,
    durationOfSlot,
    getScheduledSlots,
    selectedDates,
    user,
  } = useBooking();

  useEffect(() => {
    generateTimeSlots(moment(), durationOfSlot, 0);
  }, []);

  useEffect(() => {
    if (slots.every(({ data }) => data.length)) {
      getScheduledSlots(
        moment().format("YYYY-MM-DD"),
        moment().format("YYYY-MM-DD"),
        selectedDates,
        user.mail
      );
    }
  }, [slots]);

  return (
    <div style={{}}>
      <Flex className="slots-wrapper" gap="gap.large">
        {slots.map(({ shift, data }, i) => (
          <Flex column className="slot-shift" key={`${shift}-slots-${i + 1}`}>
            <div className="slot-shift-header">{shift}</div>
            {data.length && (
              <SlotList
                shift={shift}
                slots={data}
                isMobileView={isMobileView}
              />
            )}
          </Flex>
        ))}
      </Flex>
    </div>
  );
};

export default Slots;
