import { useState, useEffect } from "react";
import axios from "axios";
import { Flex, Loader, Text } from "@fluentui/react-northstar";
import { toast } from "react-toastify";
import { LoadingButton } from "@mui/lab";
import { Trash } from "react-feather";
import styled from "styled-components";

import { AlertDialog } from "../../../../../components/Dialog/AlertDialog";
import { useTranslation } from "react-i18next";
import EditRBAC from "./EditRBAC";
import AddRBAC from "./AddRBAC";

const Card = styled(Flex)`
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
  padding: 20px;
  border-radius: 8px;
  flex-wrap: wrap;
  align-items: center;
`;

const END_POINT = process.env.REACT_APP_EP_URL;

const DashbaordRBACSetting = (props) => {
  const [dashboardRBACData, setDashboardRBACData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [deleteReportArea, setDeleteReportArea] = useState({
    isOpen: false,
    area: null,
    isLoading: false,
  });

  const [refreshData, setRefreshData] = useState(false);

  const { t } = useTranslation();

  const translation = t("setting").RBAC;
  const dashboardTranslation = translation.dashboard;

  useEffect(() => {
    if (props.user.slug && props.user.accessToken) {
      getRBACData();
    }
  }, [props, refreshData]);

  const getRBACData = () => {
    setIsLoading(true);
    const slug = props.user.slug;
    const token = props.user.accessToken;
    axios
      .get(`${END_POINT}/api/rbac/get-rbac-data${slug}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then(({ data }) => {
        if (data?.data?.length) {
          const dashboardOptions = data.data.filter(
            (data) => data.section === "dashboard"
          );
          let formattedData = dashboardOptions.reduce((acc, curr) => {
            acc[curr.area] = acc[curr.area] ?? [];

            acc[curr.area].push(curr);
            return acc;
          }, {});
          formattedData = Object.entries(formattedData).map(
            ([title, reportOptions]) => ({ title, reportOptions })
          );
          setDashboardRBACData(formattedData);
        } else {
          setDashboardRBACData([]);
        }
      })
      .catch((err) => {
        toast.error(err?.response?.data?.message);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const handleDelete = () => {
    let isDeletedSuccessFully = false;
    setDeleteReportArea((prev) => ({ ...prev, isLoading: true }));
    axios
      .delete(
        `${END_POINT}/api/rbac/delete-rbac-data-by-area${props.user.slug}&section=dashboard&area=${deleteReportArea.area}`,
        {
          headers: {
            Authorization: `Bearer ${props.user.accessToken}`,
          },
        }
      )
      .then((res) => {
        if (res.data.status === "success") {
          isDeletedSuccessFully = true;
          toast.success(res.data.message);
        } else {
          toast.warning(res.data.message);
        }
        setRefreshData((prev) => !prev);
      })
      .catch((err) => {
        toast.error(err?.response?.data?.message);
      })
      .finally(() => {
        setDeleteReportArea({ isOpen: false, area: null, isLoading: false });
      });
  };

  const handleClose = () => {
    setDeleteReportArea((prev) => ({ ...prev, isOpen: false }));
  };

  return isLoading ? (
    <Loader size="medium" style={{ paddingTop: "50px" }} />
  ) : (
    <>
      <AlertDialog
        handleClose={handleClose}
        handleOk={handleDelete}
        open={deleteReportArea.isOpen}
      />

      <div
        style={{ width: "100%", margin: "20px auto" }}
        className="btsp rbac-dashboard-container"
      >
        <div className="pt-3 rbac-list-wrapper">
          <div className="ms-Grid" dir="ltr">
            {/* <Flex hAlign="end">
              <AddRBAC {...props} setRefreshData={setRefreshData} />
            </Flex> */}
            {!!dashboardRBACData.length && (
              <Card className="ms-Grid-row my-3">
                <div className="ms-Grid-col ms-sm12 ms-lg6">
                  {dashboardTranslation.title}
                </div>
                {/* <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg4 text-center">
                  Category
                </div> */}
                <div className="ms-Grid-col ms-sm12 ms-lg6 text-right">
                  {dashboardTranslation.action}
                </div>
              </Card>
            )}
            {!!dashboardRBACData.length ? (
              dashboardRBACData.map((data) => {
                return (
                  <Card key={data.title} className="ms-Grid-row my-3 rbac-card">
                    <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg6">
                      {data.title?.length
                        ? data.title
                            .split(" ")
                            .map((word) =>
                              word?.length
                                ? word[0].toUpperCase() + word.substr(1)
                                : ""
                            )
                            .join(" ")
                        : ""}
                    </div>
                    {/* <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg4 text-center">
                          {data.category}
                        </div> */}
                    <div className="ms-Grid-col ms-sm12 ms-lg6 text-right">
                      <EditRBAC
                        {...props}
                        data={data}
                        setRefreshData={setRefreshData}
                      />

                      {/* <LoadingButton
                        loading={
                          deleteReportArea.area === data.title
                            ? deleteReportArea.isLoading
                            : false
                        }
                        startIcon={
                          !(
                            deleteReportArea.area === data.title &&
                            deleteReportArea.isLoading
                          ) && (
                            <Trash
                              height={18}
                              width={18}
                              style={{ margin: 0 }}
                            />
                          )
                        }
                        iconOnly
                        title="Delete"
                        onClick={() => {
                          setDeleteReportArea({
                            isOpen: true,
                            area: data.title,
                            isLoading: false,
                          });
                        }}
                        className="delete-rbac-button"
                        sx={{ imageResolution: 10 }}
                      /> */}
                    </div>
                  </Card>
                );
              })
            ) : (
              <Text align="center" weight="semibold" className="mt-5">
                {dashboardTranslation.noDataFound}
              </Text>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default DashbaordRBACSetting;
