import React, { useEffect, useState } from "react";
import axios from "axios";

import Logo from "../../Assets/images/logo.png";
import "./SwitchDB.css";
import { Avatar } from "@fluentui/react-northstar";

const SwitchDB = ({ token, switchDB, handleLogout }) => {
  const [dbData, setDBData] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    async function getDbData() {
      const res = await axios.get(
        `${process.env.REACT_APP_EP_URL}/api/user/get-user-data`,
        {
          headers: {
            Authorization: "Bearer " + token,
          },
        }
      );
      setLoading(false);

      res && res.data && setDBData(res.data.result);
    }
    getDbData();
  }, [token]);

  return (
    <div className="btsp main_div">
      <div className="container-fluid">
        <div className="d-flex align-items-center justify-content-end my-3 profile_logo_right_div">
          <div className="profile_info ml-2">
            <h6
              className={`profile_info_rol mb-0 mr-3 ${
                loading ? `skeleton skeleton-text` : ""
              } `}
            >
              {dbData.length ? dbData[0].mail : ""}
            </h6>
          </div>
          <div className="profile_logout">
            <input
              type="button"
              value={"Logout"}
              className="btn btn-danger py-1"
              onClick={handleLogout}
            />
            {/* <img
              src="https://preview.webpixels.io/quick-website-ui-kit/assets/img/theme/light/person-auth.jpg"
              className="w-100 profile_logo"
            /> */}
          </div>
        </div>
      </div>
      <div className="container card_div">
        <div className="role-login-card-container ">
          <div className="row justify-content-center">
            {loading && (
              <>
                <div className="col-lg-3 col-sm-6 card skeleton skeleton-card"></div>
                <div className="d-sm-none d-lg-block col-lg-3 card skeleton skeleton-card"></div>
                <div className="d-sm-none d-lg-block col-lg-3 card skeleton skeleton-card"></div>
              </>
            )}
            {dbData?.length > 0 &&
              dbData.map((data, key) => {
                return (
                  <div
                    className="col-lg-4 col-sm-6"
                    onClick={async () => {
                      const res = await axios.post(
                        `${process.env.REACT_APP_EP_URL}/api/startup/get-DB-data?slug=${data?.data?.database}`,
                        {
                          tenantId: "12d105e3-e51a-4c7f-843b-50046ad42224",
                        },
                        {
                          headers: {
                            Authorization: "Bearer " + token,
                          },
                        }
                      );
                      localStorage.setItem(
                        "subscription",
                        JSON.stringify(res.data.subscription)
                      );
                      switchDB(
                        data?.data?.database,
                        dbData.length ? dbData[0].mail : ""
                      );
                    }}
                    key={key}
                  >
                    {!loading && (
                      <div
                        className="card role-login-card shadow-lg"
                        title={data?.data?.database}
                      >
                        <div className="card-body">
                          <div className="school_logo_div mx-auto d-flex justify-content-center flex-column">
                            <img
                              src={
                                data?.data?.schoolInfo?.SchoolLogo
                                  ? `${data?.data?.schoolInfo?.SchoolLogo}?${data?.SASToken}`
                                  : Logo
                              }
                              className="w-100 img-fluid rounded-1"
                            />
                          </div>
                          <div className="d-flex  align-items-center mt-4">
                            <div className="profile_logo_div">
                              {/* <img
                                src={
                                  data?.image
                                    ? data?.image + data?.SASToken
                                    : "https://preview.webpixels.io/quick-website-ui-kit/assets/img/theme/light/person-auth.jpg"
                                }
                                className="profile_logo"
                              /> */}
                              <Avatar
                                image={
                                  data?.image
                                    ? `${data?.image}?${data?.SASToken}`
                                    : null
                                }
                                name={data?.Name?.toUpperCase()}
                                className="profile_logo"
                              />
                            </div>
                            <div className="role_login ml-3">
                              <h6 className="mb-0 text-uppercase fw-bolder">
                                {data?.data?.schoolInfo?.School}
                              </h6>
                              <p className="fst-italic mb-0 pb-0">
                                {data?.data?.database}
                              </p>
                              <span
                                className={`pl-3 dot ${
                                  data?.role === "SuperAdmin"
                                    ? "role_red"
                                    : data?.role === "Admin"
                                    ? "role_warm"
                                    : data?.role === "Teacher"
                                    ? "role_blue"
                                    : data?.role === "Student"
                                    ? "role_info"
                                    : ""
                                }`}
                              >
                                {data?.role}
                              </span>
                            </div>
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                );
              })}
          </div>
        </div>
      </div>
      {/*  */}
    </div>
  );
};

export default SwitchDB;
