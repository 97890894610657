import React from "react";

export function AssignmentImage({ color }) {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      style={{
        fill: color || "white",
      }}
    >
      <path
        d="M10.5396 21.94C10.3096 21.94 10.0895 21.84 9.93954 21.64L8.92953 20.29C8.71953 20.01 8.43956 19.85 8.13956 19.83C7.82956 19.81 7.53953 19.95 7.29953 20.2C5.84953 21.75 4.74954 21.62 4.21954 21.42C3.67954 21.21 2.76953 20.52 2.76953 18.3V7.04001C2.76953 2.60001 4.04953 1.25 8.23953 1.25H15.7896C19.9796 1.25 21.2596 2.60001 21.2596 7.04001V11.3C21.2596 11.71 20.9196 12.05 20.5096 12.05C20.0996 12.05 19.7596 11.71 19.7596 11.3V7.04001C19.7596 3.43001 19.1296 2.75 15.7896 2.75H8.23953C4.89953 2.75 4.26953 3.43001 4.26953 7.04001V18.3C4.26953 19.35 4.52953 19.93 4.76953 20.02C4.94953 20.09 5.43955 19.99 6.19955 19.18C6.74955 18.6 7.46954 18.29 8.21954 18.33C8.95954 18.37 9.65955 18.76 10.1295 19.39L11.1495 20.74C11.3995 21.07 11.3295 21.54 10.9995 21.79C10.8495 21.9 10.6896 21.94 10.5396 21.94Z"
        fill={color || "white"}
      />
      <path
        d="M16 7.75H8C7.59 7.75 7.25 7.41 7.25 7C7.25 6.59 7.59 6.25 8 6.25H16C16.41 6.25 16.75 6.59 16.75 7C16.75 7.41 16.41 7.75 16 7.75Z"
        fill={color || "white"}
      />
      <path
        d="M15 11.75H9C8.59 11.75 8.25 11.41 8.25 11C8.25 10.59 8.59 10.25 9 10.25H15C15.41 10.25 15.75 10.59 15.75 11C15.75 11.41 15.41 11.75 15 11.75Z"
        fill={color || "white"}
      />
      <path
        d="M14.8196 21.7801C14.4396 21.7801 14.0796 21.6401 13.8196 21.3801C13.5096 21.0701 13.3696 20.6201 13.4396 20.1501L13.6296 18.8001C13.6796 18.4501 13.8896 18.0301 14.1396 17.7801L17.6796 14.2401C18.1596 13.7601 18.6296 13.5101 19.1396 13.4601C19.7596 13.4001 20.3796 13.6601 20.9596 14.2401C21.5396 14.8201 21.7996 15.4301 21.7396 16.0601C21.6896 16.5601 21.4296 17.0401 20.9596 17.5201L17.4196 21.0601C17.1696 21.3101 16.7496 21.5201 16.3996 21.5701L15.0495 21.7601C14.9695 21.7701 14.8996 21.7801 14.8196 21.7801ZM19.3096 14.9501C19.2996 14.9501 19.2896 14.9501 19.2796 14.9501C19.1396 14.9601 18.9496 15.0901 18.7396 15.3001L15.1996 18.8401C15.1696 18.8701 15.1196 18.9701 15.1196 19.0101L14.9396 20.2601L16.1896 20.0801C16.2296 20.0701 16.3295 20.0201 16.3595 19.9901L19.8996 16.4501C20.1096 16.2301 20.2396 16.0501 20.2496 15.9101C20.2696 15.7101 20.0696 15.4701 19.8996 15.3001C19.7396 15.1401 19.5096 14.9501 19.3096 14.9501Z"
        fill={color || "white"}
      />
      <path
        d="M19.9206 18.2499C19.8506 18.2499 19.7806 18.2399 19.7206 18.2199C18.4006 17.8499 17.3506 16.7999 16.9806 15.4799C16.8706 15.0799 17.1006 14.6699 17.5006 14.5499C17.9006 14.4399 18.3106 14.6699 18.4206 15.0699C18.6506 15.8899 19.3006 16.5399 20.1206 16.7699C20.5206 16.8799 20.7506 17.2999 20.6406 17.6999C20.5506 18.0299 20.2506 18.2499 19.9206 18.2499Z"
        fill={color || "white"}
      />
    </svg>
  );
}

export function CustomFeilds({ color }) {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      style={{
        fill: color || "white",
      }}
    >
      <path
        d="M18.6508 8.95C16.6608 8.95 15.0508 7.33 15.0508 5.35C15.0508 4.54 15.3208 3.77 15.8208 3.13C15.8408 3.1 15.8708 3.08 15.8908 3.05C16.5708 2.22 17.5708 1.75 18.6508 1.75C20.6308 1.75 22.2508 3.37 22.2508 5.35C22.2508 6.46 21.7508 7.49 20.8708 8.18C20.2308 8.68 19.4608 8.95 18.6508 8.95ZM16.9508 4.12C16.6908 4.48 16.5508 4.9 16.5508 5.35C16.5508 6.51 17.4908 7.45 18.6508 7.45C19.1208 7.45 19.5708 7.29 19.9408 7C20.4508 6.6 20.7508 6 20.7508 5.35C20.7508 4.19 19.8108 3.25 18.6508 3.25C18.0008 3.25 17.4008 3.54 17.0108 4.05C16.9908 4.08 16.9708 4.1 16.9508 4.12Z"
        fill={color || "white"}
      />
      <path
        d="M5.35 8.95C4.54 8.95 3.77 8.68 3.13 8.18C2.25 7.49 1.75 6.46 1.75 5.35C1.75 3.37 3.37 1.75 5.35 1.75C6.46 1.75 7.49 2.25 8.18 3.13C8.68 3.77 8.95 4.54 8.95 5.35C8.95 7.33 7.33 8.95 5.35 8.95ZM5.35 3.25C4.19 3.25 3.25 4.19 3.25 5.35C3.25 6 3.54 6.6 4.06 7C4.43 7.29 4.88 7.45 5.35 7.45C6.51 7.45 7.45 6.51 7.45 5.35C7.45 4.88 7.29 4.43 7 4.06C6.6 3.54 6 3.25 5.35 3.25Z"
        fill={color || "white"}
      />
      <path
        d="M18.6508 22.2498C17.5708 22.2498 16.5708 21.7798 15.8908 20.9498C15.8608 20.9298 15.8408 20.8998 15.8208 20.8698C15.3208 20.2298 15.0508 19.4598 15.0508 18.6498C15.0508 16.6598 16.6708 15.0498 18.6508 15.0498C19.4608 15.0498 20.2308 15.3198 20.8708 15.8198C20.9008 15.8398 20.9208 15.8698 20.9508 15.8898C21.7808 16.5698 22.2508 17.5698 22.2508 18.6498C22.2508 20.6298 20.6308 22.2498 18.6508 22.2498ZM16.9508 19.8798C16.9708 19.8998 16.9908 19.9198 17.0108 19.9498C17.4008 20.4598 18.0008 20.7498 18.6508 20.7498C19.8108 20.7498 20.7508 19.8098 20.7508 18.6498C20.7508 17.9998 20.4608 17.3998 19.9508 17.0098C19.9208 16.9898 19.9008 16.9698 19.8808 16.9498C19.5208 16.6898 19.1008 16.5498 18.6508 16.5498C17.4908 16.5498 16.5508 17.4898 16.5508 18.6498C16.5508 19.0998 16.6908 19.5198 16.9508 19.8798Z"
        fill={color || "white"}
      />
      <path
        d="M5.35 22.2498C3.37 22.2498 1.75 20.6298 1.75 18.6498C1.75 17.5698 2.22 16.5698 3.05 15.8898C3.07 15.8598 3.1 15.8398 3.13 15.8198C3.77 15.3198 4.54 15.0498 5.35 15.0498C7.34 15.0498 8.95 16.6698 8.95 18.6498C8.95 19.4598 8.68 20.2298 8.18 20.8698C7.49 21.7498 6.46 22.2498 5.35 22.2498ZM4.12 16.9498C4.1 16.9698 4.08 16.9898 4.05 17.0098C3.54 17.3998 3.25 17.9998 3.25 18.6498C3.25 19.8098 4.19 20.7498 5.35 20.7498C6 20.7498 6.6 20.4598 7 19.9398C7.29 19.5698 7.45 19.1198 7.45 18.6498C7.45 17.4898 6.51 16.5498 5.35 16.5498C4.9 16.5498 4.48 16.6898 4.12 16.9498Z"
        fill={color || "white"}
      />
      <path
        d="M12 22.25C10.34 22.25 8.69 21.84 7.24 21.07C7.04 20.97 6.91 20.78 6.86 20.56C6.81 20.34 6.86 20.12 7 19.94C7.29 19.57 7.45 19.12 7.45 18.65C7.45 17.49 6.51 16.55 5.35 16.55C4.88 16.55 4.43 16.71 4.06 17C3.89 17.14 3.66 17.19 3.44 17.14C3.22 17.09 3.04 16.96 2.93 16.76C2.15 15.29 1.75 13.69 1.75 12C1.75 10.34 2.16 8.69 2.93 7.24C3.03 7.04 3.22 6.91 3.44 6.86C3.66 6.81 3.88 6.87 4.06 7C4.43 7.29 4.88 7.45 5.35 7.45C6.51 7.45 7.45 6.51 7.45 5.35C7.45 4.88 7.29 4.43 7 4.06C6.86 3.89 6.81 3.66 6.86 3.44C6.91 3.22 7.04 3.04 7.24 2.93C8.69 2.16 10.34 1.75 12 1.75C13.69 1.75 15.29 2.15 16.76 2.93C16.96 3.03 17.09 3.22 17.14 3.44C17.19 3.66 17.13 3.88 17 4.06C16.71 4.43 16.55 4.88 16.55 5.35C16.55 6.51 17.49 7.45 18.65 7.45C19.12 7.45 19.57 7.29 19.94 7C20.11 6.86 20.34 6.81 20.56 6.86C20.78 6.91 20.96 7.04 21.07 7.24C21.84 8.69 22.25 10.34 22.25 12C22.25 13.69 21.85 15.29 21.07 16.76C20.97 16.96 20.78 17.09 20.56 17.14C20.34 17.19 20.12 17.13 19.94 17C19.57 16.71 19.12 16.55 18.65 16.55C17.49 16.55 16.55 17.49 16.55 18.65C16.55 19.12 16.71 19.57 17 19.94C17.14 20.11 17.19 20.34 17.14 20.56C17.09 20.78 16.96 20.96 16.76 21.07C15.29 21.85 13.69 22.25 12 22.25ZM8.65 20.08C10.77 20.97 13.25 20.96 15.34 20.08C15.14 19.63 15.04 19.15 15.04 18.65C15.04 16.66 16.66 15.05 18.64 15.05C19.14 15.05 19.62 15.15 20.07 15.35C20.51 14.3 20.74 13.18 20.74 12C20.74 10.85 20.51 9.7 20.07 8.65C19.62 8.85 19.14 8.95 18.64 8.95C16.65 8.95 15.04 7.33 15.04 5.35C15.04 4.85 15.14 4.37 15.34 3.92C13.25 3.04 10.76 3.04 8.65 3.92C8.85 4.37 8.95 4.85 8.95 5.35C8.95 7.34 7.33 8.95 5.35 8.95C4.85 8.95 4.37 8.85 3.92 8.65C3.48 9.7 3.25 10.85 3.25 12C3.25 13.17 3.47 14.29 3.92 15.35C4.37 15.15 4.85 15.05 5.35 15.05C7.34 15.05 8.95 16.67 8.95 18.65C8.95 19.15 8.85 19.63 8.65 20.08Z"
        fill={color || "white"}
      />
    </svg>
  );
}

export function Event({ color }) {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      style={{
        fill: color || "white",
      }}
    >
      <path
        d="M12.1504 19.35C11.7404 19.35 11.4004 19.01 11.4004 18.6V16.5C11.4004 16.09 11.7404 15.75 12.1504 15.75C12.5604 15.75 12.9004 16.09 12.9004 16.5V18.6C12.9004 19.01 12.5604 19.35 12.1504 19.35Z"
        fill={color || "white"}
      />
      <path
        d="M17.9004 22.75H6.40039V21C6.40039 19.48 7.63039 18.25 9.15039 18.25H15.1504C16.6704 18.25 17.9004 19.48 17.9004 21V22.75ZM7.90039 21.25H16.4004V21C16.4004 20.31 15.8404 19.75 15.1504 19.75H9.15039C8.46039 19.75 7.90039 20.31 7.90039 21V21.25Z"
        fill={color || "white"}
      />
      <path
        d="M18.1504 22.75H6.15039C5.74039 22.75 5.40039 22.41 5.40039 22C5.40039 21.59 5.74039 21.25 6.15039 21.25H18.1504C18.5604 21.25 18.9004 21.59 18.9004 22C18.9004 22.41 18.5604 22.75 18.1504 22.75Z"
        fill={color || "white"}
      />
      <path
        d="M18.4307 12.4401C18.2207 12.4401 18.0107 12.3501 17.8607 12.1801C17.6707 11.9601 17.6207 11.6501 17.7407 11.3901C18.0807 10.6101 18.2507 9.78006 18.2507 8.91006V5.91006C18.2507 5.56006 18.1907 5.22006 18.0707 4.86006C18.0607 4.83006 18.0507 4.79006 18.0407 4.75006C18.0107 4.60006 18.0007 4.45006 18.0007 4.31006C18.0007 3.90006 18.3407 3.56006 18.7507 3.56006H19.3507C21.1407 3.56006 22.6007 5.06006 22.6007 6.91006C22.6007 8.44006 21.9707 9.95006 20.8807 11.0401C20.8607 11.0601 20.8007 11.1101 20.7907 11.1201C20.2007 11.6101 19.5307 12.1601 18.6307 12.4101C18.5607 12.4301 18.5007 12.4401 18.4307 12.4401ZM19.6807 5.09006C19.7307 5.36006 19.7507 5.64006 19.7507 5.91006V8.91006C19.7507 9.32006 19.7207 9.71006 19.6607 10.1101C19.7207 10.0601 19.7707 10.0201 19.8307 9.97006C20.6307 9.17006 21.1007 8.05006 21.1007 6.91006C21.1007 6.01006 20.4907 5.25006 19.6807 5.09006Z"
        fill={color || "white"}
      />
      <path
        d="M5.58039 12.4001C5.50039 12.4001 5.43039 12.3901 5.35039 12.3601C4.53039 12.1001 3.76039 11.6201 3.12039 10.9801C1.97039 9.7101 1.40039 8.3201 1.40039 6.8501C1.40039 5.0301 2.83039 3.6001 4.65039 3.6001H5.30039C5.55039 3.6001 5.79039 3.7301 5.93039 3.9401C6.07039 4.1501 6.09039 4.4201 5.99039 4.6501C5.83039 5.0101 5.75039 5.4201 5.75039 5.8501V8.8501C5.75039 9.7101 5.92039 10.5501 6.27039 11.3501C6.39039 11.6201 6.33039 11.9301 6.14039 12.1501C5.99039 12.3101 5.79039 12.4001 5.58039 12.4001ZM4.30039 5.1301C3.49039 5.2901 2.90039 5.9901 2.90039 6.8501C2.90039 7.9401 3.34039 8.9901 4.21039 9.9501C4.25039 10.0001 4.30039 10.0401 4.35039 10.0801C4.28039 9.6701 4.25039 9.2601 4.25039 8.8501V5.8501C4.25039 5.6101 4.27039 5.3701 4.30039 5.1301Z"
        fill={color || "white"}
      />
      <path
        d="M12 16.75C7.73 16.75 4.25 13.27 4.25 9V6C4.25 3.38 6.38 1.25 9 1.25H15C17.62 1.25 19.75 3.38 19.75 6V9C19.75 13.27 16.27 16.75 12 16.75ZM9 2.75C7.21 2.75 5.75 4.21 5.75 6V9C5.75 12.45 8.55 15.25 12 15.25C15.45 15.25 18.25 12.45 18.25 9V6C18.25 4.21 16.79 2.75 15 2.75H9Z"
        fill={color || "white"}
      />
    </svg>
  );
}

export function Lessons({ color }) {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      style={{
        fill: color || "white",
      }}
    >
      <path
        d="M15 12.9502H8C7.59 12.9502 7.25 12.6102 7.25 12.2002C7.25 11.7902 7.59 11.4502 8 11.4502H15C15.41 11.4502 15.75 11.7902 15.75 12.2002C15.75 12.6102 15.41 12.9502 15 12.9502Z"
        fill={color || "white"}
      />
      <path
        d="M12.38 16.9502H8C7.59 16.9502 7.25 16.6102 7.25 16.2002C7.25 15.7902 7.59 15.4502 8 15.4502H12.38C12.79 15.4502 13.13 15.7902 13.13 16.2002C13.13 16.6102 12.79 16.9502 12.38 16.9502Z"
        fill={color || "white"}
      />
      <path
        d="M14 6.75H10C9.04 6.75 7.25 6.75 7.25 4C7.25 1.25 9.04 1.25 10 1.25H14C14.96 1.25 16.75 1.25 16.75 4C16.75 4.96 16.75 6.75 14 6.75ZM10 2.75C9.01 2.75 8.75 2.75 8.75 4C8.75 5.25 9.01 5.25 10 5.25H14C15.25 5.25 15.25 4.99 15.25 4C15.25 2.75 14.99 2.75 14 2.75H10Z"
        fill={color || "white"}
      />
      <path
        d="M15 22.7499H9C3.38 22.7499 2.25 20.1699 2.25 15.9999V9.99993C2.25 5.43993 3.9 3.48993 7.96 3.27993C8.36 3.25993 8.73 3.56993 8.75 3.98993C8.77 4.40993 8.45 4.74993 8.04 4.76993C5.2 4.92993 3.75 5.77993 3.75 9.99993V15.9999C3.75 19.6999 4.48 21.2499 9 21.2499H15C19.52 21.2499 20.25 19.6999 20.25 15.9999V9.99993C20.25 5.77993 18.8 4.92993 15.96 4.76993C15.55 4.74993 15.23 4.38993 15.25 3.97993C15.27 3.56993 15.63 3.24993 16.04 3.26993C20.1 3.48993 21.75 5.43993 21.75 9.98993V15.9899C21.75 20.1699 20.62 22.7499 15 22.7499Z"
        fill={color || "white"}
      />
    </svg>
  );
}

export function Profile({ color }) {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      style={{
        fill: color || "white",
      }}
    >
      <path
        d="M12.1205 13.53C12.1005 13.53 12.0705 13.53 12.0505 13.53C12.0205 13.53 11.9805 13.53 11.9505 13.53C9.68047 13.46 7.98047 11.69 7.98047 9.50998C7.98047 7.28998 9.79047 5.47998 12.0105 5.47998C14.2305 5.47998 16.0405 7.28998 16.0405 9.50998C16.0305 11.7 14.3205 13.46 12.1505 13.53C12.1305 13.53 12.1305 13.53 12.1205 13.53ZM12.0005 6.96998C10.6005 6.96998 9.47047 8.10998 9.47047 9.49998C9.47047 10.87 10.5405 11.98 11.9005 12.03C11.9305 12.02 12.0305 12.02 12.1305 12.03C13.4705 11.96 14.5205 10.86 14.5305 9.49998C14.5305 8.10998 13.4005 6.96998 12.0005 6.96998Z"
        fill={color || "white"}
      />
      <path
        d="M12.0008 22.7501C9.31081 22.7501 6.74081 21.7501 4.75081 19.9301C4.57081 19.7701 4.49081 19.5301 4.51081 19.3001C4.64081 18.1101 5.38081 17.0001 6.61081 16.1801C9.59081 14.2001 14.4208 14.2001 17.3908 16.1801C18.6208 17.0101 19.3608 18.1101 19.4908 19.3001C19.5208 19.5401 19.4308 19.7701 19.2508 19.9301C17.2608 21.7501 14.6908 22.7501 12.0008 22.7501ZM6.08081 19.1001C7.74081 20.4901 9.83081 21.2501 12.0008 21.2501C14.1708 21.2501 16.2608 20.4901 17.9208 19.1001C17.7408 18.4901 17.2608 17.9001 16.5508 17.4201C14.0908 15.7801 9.92081 15.7801 7.44081 17.4201C6.73081 17.9001 6.26081 18.4901 6.08081 19.1001Z"
        fill={color || "white"}
      />
      <path
        d="M12 22.75C6.07 22.75 1.25 17.93 1.25 12C1.25 6.07 6.07 1.25 12 1.25C17.93 1.25 22.75 6.07 22.75 12C22.75 17.93 17.93 22.75 12 22.75ZM12 2.75C6.9 2.75 2.75 6.9 2.75 12C2.75 17.1 6.9 21.25 12 21.25C17.1 21.25 21.25 17.1 21.25 12C21.25 6.9 17.1 2.75 12 2.75Z"
        fill={color || "white"}
      />
    </svg>
  );
}

export function SchoolImage({ color }) {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      style={{
        fill: color || "white",
      }}
    >
      <path
        d="M21 11.7501H3C2.04 11.7501 1.25 10.9601 1.25 10.0001V6.68013C1.25 6.00013 1.71998 5.31013 2.34998 5.06013L11.35 1.46016C11.73 1.31016 12.27 1.31016 12.65 1.46016L21.65 5.06013C22.28 5.31013 22.75 6.01013 22.75 6.68013V10.0001C22.75 10.9601 21.96 11.7501 21 11.7501ZM12 2.84016C11.96 2.84016 11.92 2.84011 11.9 2.85011L2.90997 6.45015C2.84997 6.48015 2.75 6.61013 2.75 6.68013V10.0001C2.75 10.1401 2.86 10.2501 3 10.2501H21C21.14 10.2501 21.25 10.1401 21.25 10.0001V6.68013C21.25 6.61013 21.16 6.48015 21.09 6.45015L12.09 2.85011C12.07 2.84011 12.04 2.84016 12 2.84016Z"
        fill={color || "white"}
      />
      <path
        d="M22 22.75H2C1.59 22.75 1.25 22.41 1.25 22V19C1.25 18.04 2.04 17.25 3 17.25H21C21.96 17.25 22.75 18.04 22.75 19V22C22.75 22.41 22.41 22.75 22 22.75ZM2.75 21.25H21.25V19C21.25 18.86 21.14 18.75 21 18.75H3C2.86 18.75 2.75 18.86 2.75 19V21.25Z"
        fill={color || "white"}
      />
      <path
        d="M4 18.75C3.59 18.75 3.25 18.41 3.25 18V11C3.25 10.59 3.59 10.25 4 10.25C4.41 10.25 4.75 10.59 4.75 11V18C4.75 18.41 4.41 18.75 4 18.75Z"
        fill={color || "white"}
      />
      <path
        d="M8 18.75C7.59 18.75 7.25 18.41 7.25 18V11C7.25 10.59 7.59 10.25 8 10.25C8.41 10.25 8.75 10.59 8.75 11V18C8.75 18.41 8.41 18.75 8 18.75Z"
        fill={color || "white"}
      />
      <path
        d="M12 18.75C11.59 18.75 11.25 18.41 11.25 18V11C11.25 10.59 11.59 10.25 12 10.25C12.41 10.25 12.75 10.59 12.75 11V18C12.75 18.41 12.41 18.75 12 18.75Z"
        fill={color || "white"}
      />
      <path
        d="M16 18.75C15.59 18.75 15.25 18.41 15.25 18V11C15.25 10.59 15.59 10.25 16 10.25C16.41 10.25 16.75 10.59 16.75 11V18C16.75 18.41 16.41 18.75 16 18.75Z"
        fill={color || "white"}
      />
      <path
        d="M20 18.75C19.59 18.75 19.25 18.41 19.25 18V11C19.25 10.59 19.59 10.25 20 10.25C20.41 10.25 20.75 10.59 20.75 11V18C20.75 18.41 20.41 18.75 20 18.75Z"
        fill={color || "white"}
      />
      <path
        d="M23 22.75H1C0.59 22.75 0.25 22.41 0.25 22C0.25 21.59 0.59 21.25 1 21.25H23C23.41 21.25 23.75 21.59 23.75 22C23.75 22.41 23.41 22.75 23 22.75Z"
        fill={color || "white"}
      />
      <path
        d="M12 9.25C10.76 9.25 9.75 8.24 9.75 7C9.75 5.76 10.76 4.75 12 4.75C13.24 4.75 14.25 5.76 14.25 7C14.25 8.24 13.24 9.25 12 9.25ZM12 6.25C11.59 6.25 11.25 6.59 11.25 7C11.25 7.41 11.59 7.75 12 7.75C12.41 7.75 12.75 7.41 12.75 7C12.75 6.59 12.41 6.25 12 6.25Z"
        fill={color || "white"}
      />
    </svg>
  );
}

export function Student({ color }) {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      style={{
        fill: color || "white",
      }}
    >
      <path
        d="M12.0103 17C11.1603 17 10.3003 16.78 9.63031 16.35L3.61031 12.42C2.49031 11.69 1.82031 10.46 1.82031 9.12004C1.82031 7.78004 2.49031 6.55003 3.61031 5.82003L9.64031 1.90003C10.9803 1.03003 13.0703 1.03003 14.4003 1.91003L20.3903 5.84003C21.5003 6.57003 22.1703 7.80003 22.1703 9.13003C22.1703 10.46 21.5003 11.69 20.3903 12.42L14.4003 16.35C13.7303 16.79 12.8703 17 12.0103 17ZM12.0103 2.75003C11.4403 2.75003 10.8703 2.88003 10.4603 3.16003L4.44031 7.08003C3.74031 7.54003 3.33031 8.28004 3.33031 9.12004C3.33031 9.96004 3.73031 10.7 4.44031 11.16L10.4603 15.09C11.2903 15.63 12.7503 15.63 13.5803 15.09L19.5703 11.16C20.2703 10.7 20.6703 9.96004 20.6703 9.12004C20.6703 8.28004 20.2703 7.54003 19.5703 7.08003L13.5803 3.15003C13.1603 2.89003 12.5903 2.75003 12.0103 2.75003Z"
        fill={color || "white"}
      />
      <path
        d="M11.9992 22.7501C11.5592 22.7501 11.1092 22.6901 10.7492 22.5701L7.5592 21.5101C6.0492 21.0101 4.8592 19.3601 4.8692 17.7701L4.8792 13.0801C4.8792 12.6701 5.2192 12.3301 5.6292 12.3301C6.0392 12.3301 6.3792 12.6701 6.3792 13.0801L6.3692 17.7701C6.3692 18.7101 7.1492 19.7901 8.0392 20.0901L11.2292 21.1501C11.6292 21.2801 12.3692 21.2801 12.7692 21.1501L15.9592 20.0901C16.8492 19.7901 17.6292 18.7101 17.6292 17.7801V13.1401C17.6292 12.7301 17.9692 12.3901 18.3792 12.3901C18.7892 12.3901 19.1292 12.7301 19.1292 13.1401V17.7801C19.1292 19.3701 17.9492 21.0101 16.4392 21.5201L13.2492 22.5801C12.8892 22.6901 12.4392 22.7501 11.9992 22.7501Z"
        fill={color || "white"}
      />
      <path
        d="M21.4004 15.75C20.9904 15.75 20.6504 15.41 20.6504 15V9C20.6504 8.59 20.9904 8.25 21.4004 8.25C21.8104 8.25 22.1504 8.59 22.1504 9V15C22.1504 15.41 21.8104 15.75 21.4004 15.75Z"
        fill={color || "white"}
      />
    </svg>
  );
}

export function Teacher({ color }) {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      style={{
        fill: color || "white",
      }}
    >
      <path
        d="M18.0003 7.91002C17.9703 7.91002 17.9503 7.91002 17.9203 7.91002H17.8703C15.9803 7.85002 14.5703 6.39001 14.5703 4.59001C14.5703 2.75001 16.0703 1.26001 17.9003 1.26001C19.7303 1.26001 21.2303 2.76001 21.2303 4.59001C21.2203 6.40001 19.8103 7.86001 18.0103 7.92001C18.0103 7.91001 18.0103 7.91002 18.0003 7.91002ZM17.9003 2.75002C16.8903 2.75002 16.0703 3.57002 16.0703 4.58002C16.0703 5.57002 16.8403 6.37002 17.8303 6.41002C17.8403 6.40002 17.9203 6.40002 18.0103 6.41002C18.9803 6.36002 19.7303 5.56002 19.7403 4.58002C19.7403 3.57002 18.9203 2.75002 17.9003 2.75002Z"
        fill={color || "white"}
      />
      <path
        d="M18.0105 15.2801C17.6205 15.2801 17.2305 15.2501 16.8405 15.1801C16.4305 15.1101 16.1605 14.7201 16.2305 14.3101C16.3005 13.9001 16.6905 13.6301 17.1005 13.7001C18.3305 13.9101 19.6305 13.6802 20.5005 13.1002C20.9705 12.7902 21.2205 12.4001 21.2205 12.0101C21.2205 11.6201 20.9605 11.2401 20.5005 10.9301C19.6305 10.3501 18.3105 10.1201 17.0705 10.3401C16.6605 10.4201 16.2705 10.1401 16.2005 9.73015C16.1305 9.32015 16.4005 8.93015 16.8105 8.86015C18.4405 8.57015 20.1305 8.88014 21.3305 9.68014C22.2105 10.2701 22.7205 11.1101 22.7205 12.0101C22.7205 12.9001 22.2205 13.7502 21.3305 14.3502C20.4205 14.9502 19.2405 15.2801 18.0105 15.2801Z"
        fill={color || "white"}
      />
      <path
        d="M5.97047 7.91C5.96047 7.91 5.95047 7.91 5.95047 7.91C4.15047 7.85 2.74047 6.39 2.73047 4.59C2.73047 2.75 4.23047 1.25 6.06047 1.25C7.89047 1.25 9.39047 2.75 9.39047 4.58C9.39047 6.39 7.98047 7.85 6.18047 7.91L5.97047 7.16L6.04047 7.91C6.02047 7.91 5.99047 7.91 5.97047 7.91ZM6.07047 6.41C6.13047 6.41 6.18047 6.41 6.24047 6.42C7.13047 6.38 7.91047 5.58 7.91047 4.59C7.91047 3.58 7.09047 2.75999 6.08047 2.75999C5.07047 2.75999 4.25047 3.58 4.25047 4.59C4.25047 5.57 5.01047 6.36 5.98047 6.42C5.99047 6.41 6.03047 6.41 6.07047 6.41Z"
        fill={color || "white"}
      />
      <path
        d="M5.96 15.2801C4.73 15.2801 3.55 14.9502 2.64 14.3502C1.76 13.7602 1.25 12.9101 1.25 12.0101C1.25 11.1201 1.76 10.2701 2.64 9.68014C3.84 8.88014 5.53 8.57015 7.16 8.86015C7.57 8.93015 7.84 9.32015 7.77 9.73015C7.7 10.1401 7.31 10.4201 6.9 10.3401C5.66 10.1201 4.35 10.3501 3.47 10.9301C3 11.2401 2.75 11.6201 2.75 12.0101C2.75 12.4001 3.01 12.7902 3.47 13.1002C4.34 13.6802 5.64 13.9101 6.87 13.7001C7.28 13.6301 7.67 13.9101 7.74 14.3101C7.81 14.7201 7.54 15.1101 7.13 15.1801C6.74 15.2501 6.35 15.2801 5.96 15.2801Z"
        fill={color || "white"}
      />
      <path
        d="M12.0003 15.38C11.9703 15.38 11.9503 15.38 11.9203 15.38H11.8703C9.98031 15.32 8.57031 13.86 8.57031 12.06C8.57031 10.22 10.0703 8.72998 11.9003 8.72998C13.7303 8.72998 15.2303 10.23 15.2303 12.06C15.2203 13.87 13.8103 15.33 12.0103 15.39C12.0103 15.38 12.0103 15.38 12.0003 15.38ZM11.9003 10.22C10.8903 10.22 10.0703 11.04 10.0703 12.05C10.0703 13.04 10.8403 13.84 11.8303 13.88C11.8403 13.87 11.9203 13.87 12.0103 13.88C12.9803 13.83 13.7303 13.03 13.7403 12.05C13.7403 11.05 12.9203 10.22 11.9003 10.22Z"
        fill={color || "white"}
      />
      <path
        d="M11.9993 22.76C10.7993 22.76 9.5993 22.45 8.6693 21.82C7.7893 21.23 7.2793 20.39 7.2793 19.49C7.2793 18.6 7.7793 17.74 8.6693 17.15C10.5393 15.91 13.4693 15.91 15.3293 17.15C16.2093 17.74 16.7193 18.58 16.7193 19.48C16.7193 20.37 16.2193 21.23 15.3293 21.82C14.3993 22.44 13.1993 22.76 11.9993 22.76ZM9.4993 18.41C9.0293 18.72 8.7793 19.11 8.7793 19.5C8.7793 19.89 9.0393 20.27 9.4993 20.58C10.8493 21.49 13.1393 21.49 14.4893 20.58C14.9593 20.27 15.2093 19.88 15.2093 19.49C15.2093 19.1 14.9493 18.72 14.4893 18.41C13.1493 17.5 10.8593 17.51 9.4993 18.41Z"
        fill={color || "white"}
      />
    </svg>
  );
}

export function Whiteboard({ color }) {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      style={{
        fill: color || "white",
      }}
    >
      <path
        d="M16 22.75H3C2.04 22.75 1.25 21.96 1.25 21V8C1.25 3.58 3.58 1.25 8 1.25H16C20.42 1.25 22.75 3.58 22.75 8V16C22.75 20.42 20.42 22.75 16 22.75ZM8 2.75C4.42 2.75 2.75 4.42 2.75 8V21C2.75 21.14 2.86 21.25 3 21.25H16C19.58 21.25 21.25 19.58 21.25 16V8C21.25 4.42 19.58 2.75 16 2.75H8Z"
        fill={color || "white"}
      />
      <path
        d="M7.94975 17.7498C7.47975 17.7498 7.04975 17.5798 6.72975 17.2698C6.34975 16.8898 6.17972 16.3498 6.26972 15.7698L6.54972 13.7898C6.60972 13.3598 6.87974 12.8098 7.18974 12.4998L12.3797 7.30981C14.1597 5.52981 15.7097 6.32981 16.6897 7.30981C17.4597 8.07981 17.8097 8.88981 17.7297 9.69981C17.6697 10.3598 17.3197 10.9798 16.6897 11.6198L11.4997 16.8098C11.1897 17.1198 10.6497 17.3898 10.2097 17.4598L8.22975 17.7398C8.13975 17.7398 8.03975 17.7498 7.94975 17.7498ZM14.5297 7.74981C14.1597 7.74981 13.8297 7.98981 13.4497 8.35981L8.25974 13.5498C8.17974 13.6298 8.05974 13.8798 8.03974 13.9898L7.75974 15.9698C7.74974 16.0698 7.75972 16.1598 7.79972 16.1998C7.83972 16.2398 7.92973 16.2498 8.02973 16.2398L10.0097 15.9598C10.1297 15.9398 10.3697 15.8198 10.4497 15.7398L15.6397 10.5498C16.0197 10.1698 16.2197 9.83981 16.2497 9.53981C16.2797 9.19981 16.0797 8.79981 15.6397 8.35981C15.1997 7.93981 14.8497 7.74981 14.5297 7.74981Z"
        fill={color || "white"}
      />
      <path
        d="M15.4193 12.5797C15.3493 12.5797 15.2793 12.5697 15.2193 12.5497C13.4093 12.0397 11.9593 10.5997 11.4493 8.77972C11.3393 8.37972 11.5693 7.96972 11.9693 7.84972C12.3693 7.73972 12.7793 7.96972 12.8893 8.36972C13.2593 9.67972 14.3093 10.7297 15.6193 11.0997C16.0193 11.2097 16.2493 11.6297 16.1393 12.0297C16.0493 12.3597 15.7493 12.5797 15.4193 12.5797Z"
        fill={color || "white"}
      />
    </svg>
  );
}
export function CoinIcon() {
  return (
    <svg
      aria-hidden="true"
      focusable="false"
      role="img"
      viewBox={`0 0 17 16`}
      className="rtl-mirror"
    >
      <path
        d="M8.5 0C4.08448 0 0.5 3.58448 0.5 8C0.5 12.4155 4.08448 16 8.5 16C12.9155 16 16.5 12.4155 16.5 8C16.49 3.58448 12.9155 0 8.5 0ZM7.55882 6.8786L9.97184 7.71965C10.8429 8.03004 11.2635 8.6408 11.2635 9.58198C11.2635 10.6633 10.4024 11.5544 9.35106 11.5544H9.26095V11.6045C9.26095 12.015 8.92053 12.3554 8.51001 12.3554C8.0995 12.3554 7.75907 12.015 7.75907 11.6045V11.5444C6.64768 11.4944 5.75657 10.5632 5.75657 9.40175C5.75657 8.99124 6.097 8.65081 6.50751 8.65081C6.91802 8.65081 7.25845 8.99124 7.25845 9.40175C7.25845 9.7622 7.51877 10.0526 7.83917 10.0526H9.34105C9.57134 10.0526 9.75156 9.8423 9.75156 9.58198C9.75156 9.23154 9.69149 9.21151 9.4612 9.13141L7.04819 8.29036C6.18711 7.98999 5.75657 7.37922 5.75657 6.42804C5.75657 5.34668 6.61765 4.45557 7.66896 4.45557H7.75907V4.41552C7.75907 4.00501 8.0995 3.66458 8.51001 3.66458C8.92053 3.66458 9.26095 4.00501 9.26095 4.41552V4.47559C10.3723 4.52566 11.2635 5.45682 11.2635 6.61827C11.2635 7.02879 10.923 7.36921 10.5125 7.36921C10.102 7.36921 9.76158 7.02879 9.76158 6.61827C9.76158 6.25782 9.50125 5.96746 9.18085 5.96746H7.67897C7.44869 5.96746 7.26846 6.17772 7.26846 6.43805C7.25845 6.77847 7.31852 6.7985 7.55882 6.8786Z"
        fill="#FFD949"
      />
    </svg>
  );
}
