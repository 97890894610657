import React from "react";
import ImportHomeStep from "./ImportHomeStep/ImportHomeStep";

import "./ImportSteps.scss";
import ListEvents from "./ListEvents/ListEvents";
import SuccessConfirmation from "./SuccessConfirmation/SuccessConfirmation";

const ImportSteps = ({ step, onFileSelectHandler, selectedFile, ...rest }) => {
  const getStepContent = () => {
    switch (step) {
      case 1:
        return (
          <ImportHomeStep
            onFileSelectHandler={onFileSelectHandler}
            selectedFile={selectedFile}
            {...rest}
          />
        );
      case 2:
        return <ListEvents selectedFile={selectedFile} {...rest} />;
      case 3:
        return <SuccessConfirmation />;
    }
  };

  return (
    <div className="import-steps-wrapper">
      <div>{getStepContent()}</div>
    </div>
  );
};

export default ImportSteps;
