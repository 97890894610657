import React from "react";
import { Stack, TextField } from "@fluentui/react";
import { Flex, Text } from "@fluentui/react-northstar";

import "./Rubric.css";

const RubricSection = ({ rubricData, havePoints }) => {
  return (
    <div className="rubric-section ms-Grid" dir="ltr">
      <div className="rubric-section-container">
        {rubricData.map((data, index) => {
          return (
            <div key={data.id}>
              <Stack
                horizontal
                tokens={{ childrenGap: 15 }}
                style={{ flexWrap: "wrap" }}
              >
                <Stack>
                  <div className="ms-depth-4 main-rubric">
                    <div>
                      <TextField
                        value={data.title}
                        name="title"
                        className="rubric-input-text"
                        style={{
                          backgroundColor: "#f3f2f1",
                        }}
                        disabled
                      />
                    </div>
                    <div
                      style={{
                        marginTop: 15,
                      }}
                    >
                      <TextField
                        grow
                        value={data.description}
                        name="description"
                        multiline
                        className="rubric-input-text"
                        style={{
                          backgroundColor: "#f3f2f1",
                        }}
                        resizable={false}
                        disabled
                      />
                    </div>
                    <div
                      style={{
                        marginTop: 15,
                      }}
                    ></div>
                  </div>
                </Stack>

                <Stack horizontal style={{ flexWrap: "wrap" }}>
                  {data?.levels?.length &&
                    data.levels.map((childData) => {
                      return (
                        <div
                          key={childData.id}
                          className="ms-depth-4 child-rubric"
                        >
                          <div>
                            <TextField
                              value={childData.levelTitle}
                              className="rubric-input-text"
                              style={{
                                backgroundColor: "#f3f2f1",
                              }}
                              disabled
                            />
                          </div>
                          <div
                            style={{
                              marginTop: 15,
                            }}
                          >
                            {havePoints && (
                              <TextField
                                value={childData.points || 0}
                                className="rubric-input-text"
                                style={{
                                  backgroundColor: "#f3f2f1",
                                }}
                                disabled
                              />
                            )}
                          </div>
                          <div
                            style={{
                              marginTop: 15,
                            }}
                          >
                            <TextField
                              grow
                              value={childData.description}
                              multiline
                              className="rubric-input-text"
                              resizable={false}
                              style={{
                                backgroundColor: "#f3f2f1",
                              }}
                              disabled
                            />
                          </div>
                          <div
                            style={{
                              marginTop: 15,
                            }}
                          ></div>
                        </div>
                      );
                    })}
                </Stack>
              </Stack>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default RubricSection;
