import { DefaultButton, PrimaryButton } from "@fluentui/react";
import {
  Flex,
  Input,
  SearchIcon,
  Segment,
  Dropdown,
  Checkbox,
} from "@fluentui/react-northstar";
import { Col, Row } from "antd";
import React, { useCallback, useEffect, useState } from "react";
import { withTranslation } from "react-i18next";

import { FilterIcon } from "@fluentui/react-icons-northstar";
import { Button } from "@fluentui/react-northstar/dist/es";
import { Grid } from "@mui/material";
import { ResponsiveImage } from "../../../../components/blocks/Images";
import { useBooking } from "../../../../context/BookingContext";

import "./BookingFilter.scss";

export const EventDateFilterTypes = {
  today: "today",
  week: "week",
  month: "month",
  all: "all",
};

const BookingFilter = (props) => {
  const {
    // startDate,
    // endDate,
    // changeStartDate,
    // changeEndDate,
    onHandlePortal,
    currentPage,
    t,
    i18n,
    isMobileView,
    isTabletView,

    isDiscoverEventsPage = false,
    events = [],
  } = props;

  const {
    globalSearch,
    setGlobalSearch,
    filterTutors,
    setSelectedFilterTutorsParameters,
    selectedFilterTutorsParameters,
  } = useBooking();
  const [isFilterOpen, setIsFilterOpen] = useState(false);
  const [eventCreaters, setEventCreaters] = useState([]);
  const [selectedCreater, setSelectedCreater] = useState([]);

  const translation = t("events");
  const controlsTranslation = translation.controls;

  const bookingTranslation = t("booking");

  const dateFilterTranslation = translation.body.dateFilter;

  const onRenderFooterContent = () => (
    <div>
      <PrimaryButton onClick={() => setIsFilterOpen(false)}>
        {translation.common.save}
      </PrimaryButton>
      <DefaultButton onClick={() => setIsFilterOpen(false)}>
        {translation.common.cancel}
      </DefaultButton>
    </div>
  );

  const eventCreatersHandler = () => {
    let eventCreaters = [];
    if (events.length) {
      for (let i = 0; i < events.length; i++) {
        let event = events[i];
        if (!eventCreaters.includes(event.teacherId)) {
          eventCreaters.push(event.teacherId);
        }
      }
    }
    setEventCreaters(eventCreaters);
  };

  useEffect(() => {
    if (isDiscoverEventsPage && events.length) {
      eventCreatersHandler();
    }
  }, [isDiscoverEventsPage, events]);

  const debounce = (func) => {
    let timer;
    return function (...args) {
      const context = this;
      if (timer) clearTimeout(timer);
      timer = setTimeout(() => {
        timer = null;
        func.apply(context, args);
      }, 500);
    };
  };

  const handleChange = (value) => {
    setGlobalSearch(value);
  };

  const optimizedFn = useCallback(debounce(handleChange), []);

  return (
    <div
      className="Controls booking-filters"
      style={{ boxShadow: "0 15px 18px 0px rgb(200 200 200 / 30%)" }}
    >
      <Segment
        style={{
          width: "100%",
          background: "#FFF",
          filter: "drop-shadow(rgba(0, 0, 0, 0.03) 0px 0px)",
          // boxShadow: "0 15px 18px 0px rgb(200 200 200 / 30%)",
        }}
        content={
          <div>
            {currentPage === "createEvent" ? (
              <Row justify="end" gutter={[12, 12]}>
                <Col></Col>
              </Row>
            ) : (
              <>
                {isMobileView ? (
                  <div>
                    <Grid container>
                      <Grid
                        item
                        xs={12}
                        style={{
                          display: "flex",
                          gap: "5px",
                          justifyContent: "center",
                        }}
                      >
                        <div
                          style={{
                            display: "flex",
                            cursor: "pointer",
                            height: "100%",
                            width: "100%",
                          }}
                        >
                          <Input
                            icon={<SearchIcon />}
                            clearable
                            defaultValue={globalSearch}
                            placeholder={controlsTranslation.globalSearch}
                            onChange={(e) => optimizedFn(e.target.value)}
                            style={{ width: "100%" }}
                            className="search-input"
                          />
                        </div>
                        {onHandlePortal ? (
                          <div
                            style={{
                              display: "flex",
                              cursor: "pointer",
                              height: "100%",
                            }}
                            onClick={() => onHandlePortal(true)}
                          >
                            <Button
                              icon={
                                <FilterIcon circular outline size="large" />
                              }
                              iconOnly
                            />
                            <p
                              style={{ margin: 0, padding: 5, display: "none" }}
                            >
                              {controlsTranslation.filter}
                            </p>
                          </div>
                        ) : null}
                      </Grid>
                    </Grid>
                    {eventCreaters.length ? (
                      <div
                        className="creator-filter"
                        style={{
                          display: "flex",
                          cursor: "pointer",
                          height: "100%",
                          width: "100%",
                        }}
                      >
                        <Segment
                          style={{ padding: 0 }}
                          content={
                            <Dropdown
                              clearable
                              onTouchCancelCapture={() => alert("cancel")}
                              search
                              items={eventCreaters.sort()}
                              placeholder={"select creator"}
                              onChange={(e, { value }) => {
                                setSelectedCreater(value);
                              }}
                              noResultsMessage="We couldn't find any matches."
                            />
                          }
                          className="seg2"
                        />
                      </div>
                    ) : null}
                  </div>
                ) : (
                  <Row justify="end" gutter={[12, 12]}>
                    <Col style={{ display: "flex", alignItems: "center" }}>
                      <Checkbox
                        toggle
                        label={bookingTranslation.tutors.availableToday}
                        checked={
                          selectedFilterTutorsParameters.isAvailableToday
                        }
                        onChange={(e, value) => {
                          setSelectedFilterTutorsParameters({
                            ...selectedFilterTutorsParameters,
                            isAvailableToday: value.checked,
                          });
                          filterTutors(value.checked);
                        }}
                      />
                    </Col>
                    {onHandlePortal ? (
                      <Col>
                        <div
                          style={{
                            display: "flex",
                            marginLeft: 20,
                            marginRight: 20,
                            cursor: "pointer",
                            height: "100%",
                          }}
                          onClick={() => onHandlePortal(true)}
                        >
                          <ResponsiveImage
                            style={{ marginRight: 10, width: 15 }}
                            src={"/Images/Events/filter.svg"}
                          />
                          <p style={{ margin: 0, padding: 5 }}>
                            {controlsTranslation.filter}
                          </p>
                        </div>
                      </Col>
                    ) : null}

                    <Col>
                      {eventCreaters.length ? (
                        <div className="">
                          <Segment
                            style={{ padding: 0 }}
                            content={
                              <Dropdown
                                clearable
                                onTouchCancelCapture={() => alert("cancel")}
                                search
                                items={eventCreaters.sort()}
                                placeholder={"select creator"}
                                onChange={(e, { value }) => {
                                  setSelectedCreater(value);
                                }}
                                noResultsMessage="We couldn't find any matches."
                              />
                            }
                            className="seg2"
                          />
                        </div>
                      ) : null}
                    </Col>

                    <Col>
                      <div
                        style={{
                          display: "flex",
                          cursor: "pointer",
                          height: "100%",
                        }}
                      >
                        <Input
                          icon={<SearchIcon />}
                          clearable
                          defaultValue={globalSearch}
                          placeholder={controlsTranslation.globalSearch}
                          onChange={(e) => optimizedFn(e.target.value)}
                        />
                      </div>
                    </Col>
                  </Row>
                )}
              </>
            )}
          </div>
        }
        className="seg2"
      />
    </div>
  );
};

export default withTranslation()(BookingFilter);
