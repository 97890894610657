import { Flex } from "@fluentui/react-northstar";
import { withTranslation } from "react-i18next";
import VideoRecorder from "react-video-recorder";

const VideoAttachment = ({ isEditable, videoState, setVideoState, t }) => {
  const translation = t("assignment").body;
  const attachmentTranslation = translation.attachmentOption;

  return (
    <>
      {!isEditable ? (
        <div className="justify-center">
          <video
            controls
            src={videoState.videoUrl}
            style={{ width: "100%", maxWidth: "500px" }}
          />
        </div>
      ) : (
        <div>
          {(videoState.isVideoRecorded ||
            videoState.isDone ||
            videoState.isSaved) &&
          videoState.videoUrl ? (
            <div className="justify-center">
              <video
                controls
                src={videoState.videoUrl}
                style={{ width: "100%", maxWidth: "500px" }}
              />
            </div>
          ) : (
            <div>
              <Flex>
                <VideoRecorder
                  onRecordingComplete={(videoBlob) => {
                    if ({ ...videoState }.isClosedWithoutSaved) {
                      setVideoState((prevState) => ({
                        ...prevState,
                        isVideoRecorded: true,
                        isDone: false,
                        isSaved: false,
                        videoUrl: "",
                        isClosedWithoutSaved: false,
                      }));
                    } else {
                      setVideoState((prevState) => ({
                        ...prevState,
                        videoUrl: videoBlob
                          ? URL.createObjectURL(videoBlob)
                          : "",
                        isVideoRecorded: true,
                      }));
                    }
                  }}
                  isFlipped={false}
                  className="videonote-box"
                  renderDisconnectedView={() => <div></div>}
                  replayVideoAutoplayAndLoopOff
                  timeLimit={300000}
                />
              </Flex>
            </div>
          )}
        </div>
      )}
    </>
  );
};

export default withTranslation()(VideoAttachment);
