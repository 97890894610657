import React from "react";
import { withTranslation } from "react-i18next";

import Charts from "../Container/Charts";

const Dashboard = (props) => {
  const {
    user,
    logout,
    switchDb,
    getDb,
    match,
    t,
    selectedExtension,
    setSelectedExtension,
  } = props;
  return (
    <Charts
      isLoading={false}
      showControls={false}
      user={user}
      logout={() => logout()}
      switchDb={switchDb}
      getDb={() => getDb()}
      path={match.path}
      header={{
        report: t("chart").screens.header.reports[0],
        lable: t("chart").screens.header.lable,
      }}
      boxData={[]}
      isEmptyReport={true}
      selectedExtension={selectedExtension}
      setSelectedExtension={setSelectedExtension}
    />
  );
};

export default withTranslation()(Dashboard);
