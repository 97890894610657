import { Button, Tooltip } from "@fluentui/react-northstar";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { withTranslation } from "react-i18next";

import Chart from "../../Container/Charts";
import { reportTypes } from "../constant";

const url = process.env.REACT_APP_EP_URL;

let d = new Date();

const FeedbackReport = (props) => {
  const { user, t } = props;
  const { slug } = user;
  const [chartData, setChartData] = useState({});
  const [filterBy, setFilterBy] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [dateFilter, setDateFilter] = useState({
    startDate: new Date(
      d.getFullYear(),
      d.getMonth() - 3,
      d.getDate(),
      0,
      0,
      0,
      0
    ),
    endDate: new Date(d.getFullYear(), d.getMonth(), d.getDate(), 23, 59, 0, 0),
  });

  const getFormattedTitle = (type) => {
    const feedbackTypesTranslation =
      t("chart").screens.feedback.typeTranslation;
    switch (type) {
      case "TEXT":
        return feedbackTypesTranslation[0];
      case "AUDIO":
        return feedbackTypesTranslation[1];
      case "VIDEO":
        return feedbackTypesTranslation[2];
      case "POLL":
        return feedbackTypesTranslation[3];
      case "ANY":
        return feedbackTypesTranslation[4];
      default:
        return "";
    }
  };

  const fetchReportData = async () => {
    try {
      setIsLoading(true);
      const response = await axios({
        url: `${url}/api/chart/get-feedback-chart${slug}`,
        method: "post",
        data: {
          startDate: dateFilter.startDate,
          endDate: dateFilter.endDate,
        },
        headers: {
          Authorization: "Bearer " + user.accessToken,
        },
      });
      if (response.data?.result) {
        let { feedbacks } = response.data.result;

        let feedbackData = feedbacks.length
          ? feedbacks.map((feedback) => {
              return {
                ...feedback,
                type: getFormattedTitle(feedback.type),
                total: Number(feedback.total) ?? 0,
                progress: Number(feedback.progress) ?? 0,
                completed: Number(feedback.completed) ?? 0,
              };
            })
          : [];

        let formattedData = feedbackData.length
          ? feedbackData.reduce(
              (prev, curr) => {
                const { type, total, progress, completed } = curr;
                prev[type] = total;
                prev.totalFeedbacks = prev.totalFeedbacks ?? 0;
                prev.totalFeedbacks += total;
                prev.inProgressFeedback = prev.inProgressFeedback ?? 0;
                prev.inProgressFeedback += progress;
                prev.completedFeedback = prev.completedFeedback ?? 0;
                prev.completedFeedback += completed;
                return prev;
              },
              { totalFeedbacks: 0, inProgressFeedback: 0, completedFeedback: 0 }
            )
          : {};

        let boxData = {
          totalFeedbacks: formattedData.totalFeedbacks ?? 0,
          textFeedbacks: formattedData.Text ?? 0,
          audioFeedbacks: formattedData.Audio ?? 0,
          videoFeedbacks: formattedData.Video ?? 0,
          pollFeedbacks: formattedData.Poll ?? 0,
          anyFeedbacks: formattedData.Any ?? 0,
        };

        const {
          totalFeedbacks = 0,
          inProgressFeedback = 0,
          completedFeedback = 0,
          ...rest
        } = formattedData;
        let pieData = {
          pie1: rest,
          pie2: {
            inProgressFeedback,
            completedFeedback,
          },
        };

        setChartData({
          boxData,
          pieData,
          barData: feedbackData,
          tableData: feedbackData,
        });
      } else {
        setChartData({});
      }
    } catch (error) {
      console.log(error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchReportData();
  }, [dateFilter]);

  const filterByHandler = (option) => {
    setFilterBy(option);

    setChartData((prevData) => ({
      ...prevData,
      tableData: prevData.tableData
        .slice()
        .sort((a, b) => b[option] - a[option]),
    }));
  };

  return (
    <Chart
      mainPath={props.mainPath}
      showControls={true}
      isLoading={isLoading}
      report={reportTypes.feedback}
      user={props.user}
      logout={() => props.logout()}
      switchDb={props.switchDb}
      getDb={() => props.getDb()}
      path={props.match.path}
      header={{
        report: t("chart").screens.header.reports[0],
        lable: t("chart").screens.header.lable,
      }}
      startDate={dateFilter.startDate}
      endDate={dateFilter.endDate}
      startDateLabel={t("chart").screens.feedback.controls.date.date2[0]}
      startChangeHandler={(selectedDate) => {
        setDateFilter({ ...dateFilter, startDate: selectedDate });
      }}
      endChangeHandler={(selectedDate) => {
        setDateFilter({ ...dateFilter, endDate: selectedDate });
      }}
      showClassType={false}
      boxData={
        chartData.boxData
          ? {
              [t("chart").screens.feedback.boxs[0]]:
                chartData.boxData.totalFeedbacks,
              [t("chart").screens.feedback.boxs[1]]:
                chartData.boxData.textFeedbacks,
              [t("chart").screens.feedback.boxs[2]]:
                chartData.boxData.audioFeedbacks,
              [t("chart").screens.feedback.boxs[3]]:
                chartData.boxData.videoFeedbacks,
              [t("chart").screens.feedback.boxs[4]]:
                chartData.boxData.pollFeedbacks,
              [t("chart").screens.feedback.boxs[5]]:
                chartData.boxData.anyFeedbacks,
            }
          : null
      }
      pieData={{
        pie1: {
          lable: t("chart").screens.feedback.pie.pie1.lbl,
          data: chartData.pieData?.pie1,
        },
        pie2: {
          lable: t("chart").screens.feedback.pie.pie2.lbl,
          data: chartData.pieData?.pie2
            ? {
                [t("chart").screens.feedback.pie.pie2.dataLbl[0]]:
                  chartData.pieData.pie2.inProgressFeedback,
                [t("chart").screens.feedback.pie.pie2.dataLbl[1]]:
                  chartData.pieData.pie2.completedFeedback,
              }
            : null,
        },
      }}
      columnData={{
        stacked: false,
        radius: 10,
        title: t("chart").screens.feedback.column.title,
        lableY: t("chart").screens.feedback.column.lableY,
        lableX: t("chart").screens.feedback.column.lableX,
        series: chartData.barData
          ? [
              {
                name: [t("chart").screens.feedback.column.series[0]],
                data: chartData.barData.map((feedback) => {
                  return feedback.progress;
                }),
              },
              {
                name: [t("chart").screens.feedback.column.series[1]],
                data: chartData.barData.map((feedback) => {
                  return feedback.completed;
                }),
              },
            ]
          : [],
        categories: chartData.barData
          ? chartData.barData.map((feedback) => {
              return document.documentElement.clientWidth < 500
                ? feedback.type + "..."
                : document.documentElement.clientWidth < 700
                ? [feedback.type]
                : [feedback.type];
            })
          : [],
      }}
      tableData={{
        header: {
          key: "Headers",
          items: t("chart").screens.feedback.table.header.map((header, key) => {
            return {
              content:
                key && key === 1 ? (
                  <Button
                    text
                    style={{
                      padding: 0,
                      margin: 0,
                      minWidth: "30px",
                      color: filterBy === "progress" ? "#6264A7" : "black",
                    }}
                    content={
                      <Tooltip
                        content={header}
                        position={"below"}
                        trapFocus
                        trigger={
                          <div
                            style={{
                              maxWidth: "70px",
                              textAlign: "left",
                              textOverflow: "ellipsis",
                              whiteSpace: "nowrap",
                              overflow: "hidden",
                            }}
                          >
                            {header}
                          </div>
                        }
                      ></Tooltip>
                    }
                    onClick={() => filterByHandler("progress")}
                  />
                ) : key === 2 ? (
                  <Button
                    text
                    style={{
                      padding: 0,
                      margin: 0,
                      minWidth: "30px",
                      color: filterBy === "completed" ? "#6264A7" : "black",
                    }}
                    content={
                      <Tooltip
                        content={header}
                        position={"below"}
                        trapFocus
                        trigger={
                          <div
                            style={{
                              maxWidth: "70px",
                              textAlign: "left",
                              textOverflow: "ellipsis",
                              whiteSpace: "nowrap",
                              overflow: "hidden",
                            }}
                          >
                            {header}
                          </div>
                        }
                      ></Tooltip>
                    }
                    onClick={() => filterByHandler("completed")}
                  />
                ) : key === 3 ? (
                  <Button
                    text
                    style={{
                      padding: 0,
                      margin: 0,
                      minWidth: "30px",
                      color: filterBy === "total" ? "#6264A7" : "black",
                    }}
                    content={
                      <Tooltip
                        content={header}
                        position={"below"}
                        trapFocus
                        trigger={
                          <div
                            style={{
                              maxWidth: "70px",
                              textAlign: "left",
                              textOverflow: "ellipsis",
                              whiteSpace: "nowrap",
                              overflow: "hidden",
                            }}
                          >
                            {header}
                          </div>
                        }
                      ></Tooltip>
                    }
                    onClick={() => filterByHandler("total")}
                  />
                ) : (
                  header
                ),
              key: "Header-" + header,
            };
          }),
        },
        rows: chartData.tableData
          ? chartData.tableData.map((feedback, key) => {
              return {
                key: feedback.type + "key-" + key,
                items: [
                  {
                    content: feedback.type,
                    key: feedback.type + "type" + key,
                  },
                  {
                    content: feedback.progress,
                    key: feedback.type + "progress" + key,
                  },
                  {
                    content: feedback.completed,
                    key: feedback.type + "completed" + key,
                  },

                  {
                    content: feedback.total,
                    key: feedback.type + "Total" + key,
                  },
                ],
              };
            })
          : [],
      }}
      selectedExtension={props.selectedExtension}
      setSelectedExtension={props.setSelectedExtension}
    />
  );
};

export default withTranslation()(FeedbackReport);
