import React, { useEffect } from "react";
import {
  Dialog,
  Input,
  TextArea,
  Form,
  Button,
  Flex,
  Checkbox,
} from "@fluentui/react-northstar";
import axios from "axios";
import { NotificationAlert } from "../../../../components/Notification/Notification";
import Logo from "../../../../Assets/images/logo.png";
import LogoLight from "../../../../Assets/images/logo.png";
import { toast } from "react-toastify";

import "./School.css";
import { useTranslation } from "react-i18next";

const School = (props) => {
  const url = process.env.REACT_APP_EP_URL;
  const [state, setState] = React.useState({});
  const [load, setLoad] = React.useState(true);
  const [schoolData, setSchoolData] = React.useState(null);
  const [accDlg, setAccDlg] = React.useState(false);
  const [dataSaved, setDataSaved] = React.useState(false);
  const [onUpload, setOnUpload] = React.useState(false);
  const [contentEditable, setContentEditable] = React.useState(false);
  const [isTrusted, setIsTrusted] = React.useState(false);

  const handleChange = () => {
    setIsTrusted(!isTrusted);
    updateTrustedEveryone();
  };

  const updateTrustedEveryone = () => {
    axios
      .post(`${url}/api/school/update-trusted-everyone${props.user.slug}`, {
        headers: {
          Authorization: "Bearer " + props.user.accessToken,
        },
      })
      .then((res) => {
        if (res.data.result.isTrusted) {
          setIsTrusted(true);
        } else {
          setIsTrusted(false);
        }
      })
      .catch((err) => {
        console.log("erroe", err);
      });
  };

  const { t } = useTranslation();

  React.useEffect(() => {
    setIsTrusted(state.isTrusted ? true : false);
  }, [state]);

  React.useEffect(() => {
    getSchoolInfo();
    setIsTrusted(state.isTrusted ? true : false);
  }, []);

  const getSchoolInfo = () => {
    axios
      .get(`${url}/api/school/get-school-info${props.user.slug}`, {
        headers: {
          Authorization: "Bearer " + props.user.accessToken,
        },
      })
      .then((res) => {
        setSchoolData(res.data?.result[0] || null);
        setLoad(false);
        setState({
          SchoolLogo: res.data?.result[0]?.SchoolLogo,
          NewSchoolLogo: null,
          SchoolName: res.data?.result[0]?.School,
          ContactEmail: res.data?.result[0]?.ContactEmail,
          SchoolAddress: res.data?.result[0]?.SchoolAddress,
          SchoolPhone: res.data?.result[0]?.ContactPhone,
          isTrusted: res.data?.result[0]?.isTrusted,
        });
      })
      .catch((err) => {
        toast.error(err?.response?.data?.message);
        console.log(err);
        if (err.response?.status === 401) {
          setAccDlg(true);
          setLoad(false);
        }
      });
  };

  return (
    <div
      className="School container"
      style={{
        position: "relative",
        overflow: "auto",
        height: props.isTabletOrMobileView
          ? "calc(100vh - 170px)"
          : "calc(100vh - 170px)",
        paddingTop: props.isTabletOrMobileView ? "0px" : "30px",
      }}
    >
      <Dialog
        open={accDlg}
        onConfirm={() => location.reload()}
        confirmButton="Refresh Now"
        content={
          <p className="refreshMsg">
            No big deal — but we need to refresh to show your content.
          </p>
        }
        header="Authorization expire"
      />

      <Form
        onSubmit={() => {
          setOnUpload(true);
          const data = new FormData();
          data.append(
            "SchoolLogo",
            state.NewSchoolLogo ? state.NewSchoolLogo : null
          );
          data.append("SchoolName", state.SchoolName);
          data.append("SchoolEmail", state.ContactEmail);
          data.append("SchoolAddress", state.SchoolAddress);
          data.append("SchoolPhone", state.SchoolPhone);
          axios
            .put(
              `${url}/api/school/update-school-info${props.user.slug}`,
              data,
              {
                headers: {
                  Authorization: "Bearer " + props.user.accessToken,
                },
              }
            )
            .then((res) => {
              // console.log(res.data.dataw);
              setContentEditable(false);
              setSchoolData(res.data.data || null);
              props.getDb();
              setDataSaved(true);
              setOnUpload(false);
            })
            .catch((err) => {
              setContentEditable(false);
              toast.error(err?.response?.data?.message);
              NotificationAlert(err.response.data.message, "error");
              setOnUpload(false);
            });
        }}
        style={{ height: "auto" }}
      >
        <div style={{ display: "flex", alignItems: "center" }}>
          <label
            htmlFor={"schoolLogo"}
            className="fw6"
            style={{ width: "100%" }}
          >
            {t("setting").school.title[0]}
          </label>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              width: "100%",
              justifyContent: "end",
            }}
          >
            <Checkbox
              toggle
              id="newCustomFieldChk"
              style={{ alignItems: "center" }}
              checked={isTrusted}
              onChange={handleChange}
            />
            <label
              className="form-check-label"
              htmlFor="flexSwitchCheckChecked"
            >
              Trusted Everyone
            </label>
          </div>
        </div>
        <img
          htmlFor="schoolLogo"
          id="schoolLogo"
          src={
            state.SchoolLogo
              ? state.SchoolLogo + props.user.SASToken
              : schoolData?.SchoolLogo
              ? schoolData?.SchoolLogo + props.user.SASToken
              : props?.mode === "dark"
              ? LogoLight
              : Logo
          }
          className="schoolLogo"
          title={schoolData?.School || "School Logo"}
        />
        <Input
          type="file"
          id="schoolLogo"
          name="SchoolLogo"
          accept="image/png, image/svg, image/jpeg"
          onChange={async (event) => {
            if (contentEditable) {
              const selectedFile = event.target.files[0] || state.NewSchoolLogo;
              const reader = new FileReader();

              const imgtag = document.getElementById("schoolLogo");
              imgtag.title = selectedFile?.name || "School Logo";

              reader.onload = function (event) {
                imgtag.src = event.target.result;
              };

              reader.readAsDataURL(selectedFile);
              setState({
                ...state,
                NewSchoolLogo: await event.target.files[0],
              });
            }
          }}
          fluid
          disabled={!contentEditable}
        />
        <label htmlFor={"schoolName"} className="fw6">
          {t("setting").school.title[1]}
        </label>
        <Input
          type="text"
          id="schoolName"
          placeholder="Enter school name"
          name="SchoolName"
          value={state.SchoolName}
          onChange={(event) => {
            contentEditable &&
              setState({ ...state, SchoolName: event.target.value });
          }}
          disabled={!contentEditable}
          fluid
        />
        <label htmlFor={"schoolEmail"} className="fw6">
          {t("setting").school.title[2]}
        </label>
        <Input
          type="text"
          id="schoolEmail"
          placeholder="Enter school email"
          name="SchoolEmail"
          value={state.ContactEmail}
          onChange={(event) => {
            contentEditable &&
              setState({ ...state, ContactEmail: event.target.value });
          }}
          fluid
          disabled={!contentEditable}
        />
        <label htmlFor={"schoolPhone"} className="fw6">
          {t("setting").school.title[3]}
        </label>
        <Input
          type="text"
          id="schoolPhone"
          placeholder="Enter school phone"
          name="SchoolPhone"
          value={state.SchoolPhone}
          onChange={(event) => {
            contentEditable &&
              setState({ ...state, SchoolPhone: event.target.value });
          }}
          disabled={!contentEditable}
          fluid
        />
        <label htmlFor={"schoolAddress"} className="fw6">
          {t("setting").school.title[4]}
        </label>
        <TextArea
          type="text"
          id="schoolAddress"
          placeholder="Enter school address"
          name="SchoolAddress"
          value={state.SchoolAddress}
          onChange={(event) => {
            contentEditable &&
              setState({ ...state, SchoolAddress: event.target.value });
          }}
          disabled={!contentEditable}
          fluid
          // style={{ paddingBottom: "25px" }}
        />

        <Flex gap="gap.smaller">
          {contentEditable ? (
            <Button
              content="Cancel"
              secondary
              className="mb-xl-4"
              onClick={(e) => {
                e.preventDefault();
                setContentEditable(false);
                getSchoolInfo();
              }}
            />
          ) : (
            <Button
              content="Edit"
              primary
              className="btn-blue mb-xl-4"
              onClick={(e) => {
                e.preventDefault();
                setContentEditable(true);
              }}
            />
          )}
          {contentEditable && (
            <Button
              content="Save"
              primary
              loading={onUpload}
              className="btn-blue mb-xl-4"
            />
          )}
        </Flex>
      </Form>
    </div>
  );
};

export default School;
