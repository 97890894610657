import React, { useEffect, useState } from "react";
import { Tab } from "./Tab";
import "./Tabs.scss";

export const Tabs = ({ children, currentTab }) => {
  const [activeTab, setActiveTab] = useState(currentTab);

  const onClickTabItem = (tab) => {
    setActiveTab(tab);
  };

  return (
    <div className="group-tabs">
      <ol className="group-tab-list">
        {children.map((child) => {
          const { label, tabId, className = "" } = child.props;

          return (
            <Tab
              tabId={tabId}
              activeTab={activeTab}
              key={label}
              label={label}
              onClick={onClickTabItem}
              classes={className}
            />
          );
        })}
      </ol>
      <div className="group-tab-content">
        {children.map((child) => {
          if (child.props.tabId !== activeTab) return undefined;
          return child.props.children;
        })}
      </div>
    </div>
  );
};

export default Tabs;
