import {
  Button,
  ExcelIcon,
  FilesUploadIcon,
  Flex,
  Text,
} from "@fluentui/react-northstar";
import React from "react";
import { useTranslation } from "react-i18next";
import { BulkEventTemplate } from "../../../../../../Startup/Helper/ExportTemplate";
import useImportHomeStep from "./useImportHomeStep";

const ImportHomeStep = ({ onFileSelectHandler, selectedFile, user }) => {
  const { categories, tags } = useImportHomeStep({ user });

  const { t } = useTranslation();

  const translation = t("events");
  const eventsTranslation = translation.body.bundles.importFromExcel;

  return (
    <Flex column>
      <Flex hAlign="center">
        <ExcelIcon size={"largest"} />
      </Flex>
      <Flex column gap="gap.small">
        <Text as="h1" weight="bold" align="center" style={{ margin: 0 }}>
          {eventsTranslation.importEvents}
        </Text>
        <Flex hAlign="start">
          <ul className="text-left">
            <li>{eventsTranslation.infoMessages[0]}</li>
            <li>{eventsTranslation.infoMessages[1]}</li>
            <li>{eventsTranslation.infoMessages[2]}</li>
            <li>{eventsTranslation.infoMessages[3]}</li>
          </ul>
        </Flex>
        <Flex hAlign="center" vAlign="center" gap="gap.small">
          <BulkEventTemplate categories={categories} tags={tags} />
          <input
            type="file"
            id="file-lsn"
            name="file1"
            onChange={(event) => {
              onFileSelectHandler(event.target.files?.[0]);
            }}
            style={{ display: "none" }}
            accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
          />
          <Button
            icon={<FilesUploadIcon />}
            content={
              selectedFile ? selectedFile.name : eventsTranslation.selectFile
            }
            onClick={() => document.getElementById("file-lsn").click()}
            title={
              selectedFile ? selectedFile.name : eventsTranslation.selectFile
            }
            style={{
              maxWidth: "209px",
            }}
          />
        </Flex>
      </Flex>
    </Flex>
  );
};

export default ImportHomeStep;
