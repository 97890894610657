import React, { useRef, useState } from "react";

import axios from "axios";

import {
  ArrowLeftIcon,
  ContentIcon,
  FilesUploadIcon,
} from "@fluentui/react-icons-northstar";

import {
  Button,
  Dialog,
  Flex,
  Image,
  Segment,
} from "@fluentui/react-northstar";

import image from "../../../../Assets/images/Icon.png";

import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { toast } from "react-toastify";

import { ReCaptcha } from "../../../../components/ReCaptcha";
import {
  drawCaptchaOnCanvas,
  generateCaptchaText,
} from "../../../../utils/reCaptcha";
import "./SelectDB.css";

const url = process.env.REACT_APP_EP_URL;

const SelectDB = (props) => {
  const { t } = useTranslation();

  const [open, setOpen] = useState(false);
  const [open2, setOpen2] = useState(false);
  const [err, setErr] = useState(null);
  const [captchaText, setCaptchaText] = useState("");
  const [userInput, setUserInput] = useState("");
  const canvasRef = useRef(null);
  const history = useHistory();
  const [checkInputCaptchaBlankOrNot, setCheckInputCaptchaBlankOrNot] =
    useState(false);

  const initializeCaptcha = (ctx) => {
    setUserInput("");
    const newCaptcha = generateCaptchaText();
    setCaptchaText(newCaptcha);
    drawCaptchaOnCanvas(ctx, newCaptcha);
  };

  const onBackBtnHander = () => {
    axios
      .post(`${url}/api/startup/update-DB-data${props.data.user.slug}`, {
        plan: "Current",
        email: props.data?.user?.username
          ? props.data?.user?.username
          : props.data?.user.mail,
        isFirstTime: 0,
      })
      .then((res) => {
        // props.getDb();
        history.push("/");
      })
      .catch((err) => {
        toast.error(err?.response?.data?.message);
        setErr(t("startUp").initial[8]);
        console.log(err);
      });
  };

  return (
    <Segment
      content={
        <>
          <Image
            src={image}
            style={{ maxWidth: "150px", borderRadius: "10px" }}
            className="image-logo"
          />
          <br />
          <br />
          <h1 style={{ color: "black" }}>{t("startUp").initial[0]}</h1>
          <br />
          <Flex wrap vAlign="center" hAlign="center">
            <Flex.Item>
              {/* <div className="back=btn"> */}
              <Button
                content={"Back"}
                onClick={onBackBtnHander}
                // onClick={() => history.goBack()}
                icon={<ArrowLeftIcon size="small" />}
                // className="back-btn"
                className="SelectDB btn"
              />
              {/* </div> */}
            </Flex.Item>
            <Flex.Item>
              <Dialog
                open={open}
                cancelButton={t("startUp").initial[7]}
                confirmButton={
                  <div className="button-confirm">
                    {err ? t("startUp").initial[8] : t("startUp").initial[6]}
                    {props.loader && (
                      <IconButton className="loader-button">
                        <CircularProgress className="progressbar-loader" />
                      </IconButton>
                    )}
                  </div>
                }
                content={
                  <div className="captcha-content">
                    <ReCaptcha
                      captchaText={captchaText}
                      setCaptchaText={setCaptchaText}
                      userInput={userInput}
                      setUserInput={setUserInput}
                      canvasRef={canvasRef}
                      initializeCaptcha={initializeCaptcha}
                      setCheckInputCaptchaBlankOrNot={
                        setCheckInputCaptchaBlankOrNot
                      }
                      checkInputCaptchaBlankOrNot={checkInputCaptchaBlankOrNot}
                    />
                    {err ? err : t("startUp").initial[3]}
                  </div>
                }
                header={t("startUp").initial[10]}
                onConfirm={() => {
                  if (!userInput) {
                    setCheckInputCaptchaBlankOrNot(true);
                  } else {
                    setCheckInputCaptchaBlankOrNot(false);
                    if (userInput === captchaText) {
                      const mail = props.data?.user?.username
                        ? props.data?.user?.username
                        : props.data?.user.mail;
                      axios
                        .post(
                          `${url}/api/startup/update-DB-data${props.data.user.slug}`,
                          {
                            plan: "Trial",
                            email: mail,
                          }
                        )
                        .then(() => {
                          props.getDb(mail);
                          history.push("/");
                        })
                        .catch((err) => {
                          toast.error(err?.response?.data?.message);
                          setErr(t("startUp").initial[9]);
                          console.log(err);
                        });
                    } else {
                      setCheckInputCaptchaBlankOrNot(true);
                      const canvas = canvasRef.current;
                      const ctx = canvas.getContext("2d");
                      initializeCaptcha(ctx);
                    }
                  }
                }}
                onOpen={() => {
                  setOpen(true);
                }}
                onCancel={() => {
                  setOpen(false);
                }}
                trigger={
                  <Button
                    icon={<ContentIcon outline size="large" />}
                    content={t("startUp").initial[1]}
                    className="btn"
                    onClick={() => {
                      setErr(null);
                    }}
                  />
                }
              />
            </Flex.Item>
            <Flex.Item>
              <Dialog
                cancelButton={t("startUp").initial[7]}
                confirmButton={
                  err ? t("startUp").initial[8] : t("startUp").initial[6]
                }
                onCancel={() => {
                  setOpen2(false);
                }}
                onConfirm={() => {
                  axios
                    .post(
                      `${url}/api/startup/update-DB-data${props.data.user.slug}`,
                      {
                        plan: "Current",
                        email: props.data?.user?.username
                          ? props.data?.user?.username
                          : props.data?.user.mail,
                      }
                    )
                    .then((res) => {
                      props.getDb();
                      history.push("/start-up/info");
                      // history.push("/start-up/upload");        // need to verify
                      setOpen2(false);
                    })
                    .catch((err) => {
                      toast.error(err?.response?.data?.message);
                      setErr(t("startUp").initial[9]);
                      console.log(err);
                    });
                }}
                onOpen={() => {
                  setOpen2(true);
                }}
                open={open2}
                header={t("startUp").initial[4]}
                content={err ? err : t("startUp").initial[5]}
                trigger={
                  <Button
                    icon={<FilesUploadIcon outline size="large" />}
                    content={t("startUp").initial[2]}
                    primary
                    className="btn"
                    onClick={() => {
                      setErr(null);
                    }}
                  />
                }
              />
            </Flex.Item>
          </Flex>
        </>
      }
      style={{
        textAlign: "center",
      }}
      className="SelectDB"
    />
  );
};

export default SelectDB;
