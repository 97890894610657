import React, { useEffect } from "react";
import { useLocation } from "react-router-dom";
import {
  TeamsMonochromeIcon,
  LeaveIcon,
} from "@fluentui/react-icons-northstar";
import { Button, Image, Flex, Segment } from "@fluentui/react-northstar";

import image from "../../Assets/images/Icon.png";
import "./Auth.css";
import { GoogleLogin } from "react-google-login";
import { gapi, loadAuth2 } from "gapi-script";

const Auth = (props) => {
  useEffect(() => {
    const setAuth2 = async () => {
      await loadAuth2(
        gapi,
        process.env.REACT_APP_GOOGLE_CLIENT_ID,
        "https://www.googleapis.com/auth/calendar.events"
      );
    };
    setAuth2();
  }, []);

  const onFailure = (err) => {
    console.log(err);
  };
  const onSuccess = (res) => {
    props.login("google", res);
  };

  return (
    <div className="Auth btsp">
      <Flex
        gap="gap.small"
        padding="padding.medium"
        className="flexBox"
        styles={{
          backgroundImage: `url(
            https://acvstorageprod.blob.core.windows.net/public/background.png
          )`,
          backgroundSize: "cover",
        }}
      >
        <Flex.Item hAlign="center" vAlign="center">
          <Segment
            content={
              <>
                <Image
                  src={image}
                  style={{ maxWidth: "150px", borderRadius: "4px" }}
                />
                <br />
                <br />
                <h1 style={{ color: "black" }}>
                  {props.isNonSubscriber
                    ? "Subscribe to ACV"
                    : "Authenticate your self"}
                </h1>

                {props.isNonSubscriber ? (
                  <p>
                    {/* The account you are using does not have access privileges to
                    the ACV menu, if you are a student, you can ask your teacher
                    for a collaboration link. */}
                    Sorry, we can't find an account with this email address.
                    Please try again or create a new account.
                    {/* It seems like you are not registered at ACV. <br />
                    But don't worry, you can subscribe ACV using button below. ↓ */}
                  </p>
                ) : props?.inactiveParent ? (
                  <p>
                    {/* The account you are using does not have access privileges to
                  the ACV menu, if you are a student, you can ask your teacher
                  for a collaboration link. */}
                    Your account is inactive. Contact your child's school to
                    activate it.
                    {/* It seems like you are not registered at ACV. <br />
                  But don't worry, you can subscribe ACV using button below. ↓ */}
                  </p>
                ) : (
                  <p>
                    It seems like you are not signed in. If you have an account
                    you can sign in.{" "}
                    <span className="d-none d-lg-inline">
                      Or you can create one using sign up.
                    </span>
                  </p>
                )}

                <br />
                <div className="row" style={{ justifyContent: "center" }}>
                  {!props.isNonSubscriber && !props.inactiveParent && (
                    <Button
                      icon={
                        <TeamsMonochromeIcon
                          size="larger"
                          style={{ paddingRight: "3px" }}
                        />
                      }
                      content={
                        props?.insideTeam
                          ? "Sign in with Teams"
                          : "Sign in with Microsoft"
                      }
                      iconPosition="before"
                      primary
                      onClick={() => props.login("microsoft")}
                    />
                  )}
                  {(props.isNonSubscriber || props.inactiveParent) && (
                    <Button
                      icon={<LeaveIcon />}
                      content={`Sign out from ${props?.user}`}
                      iconPosition="before"
                      primary
                      onClick={() => props.logout()}
                    />
                  )}
                  {!props.isNonSubscriber && !props.inactiveParent && (
                    <GoogleLogin
                      clientId={process.env.REACT_APP_GOOGLE_CLIENT_ID}
                      buttonText="Sign in with Google"
                      onSuccess={onSuccess}
                      onFailure={onFailure}
                      cookiePolicy={"single_host_origin"}
                      className="google_btn mt-2 mt-lg-0"
                      scope="profile"
                      redirectUri={process.env.REACT_APP_URI}
                    />
                  )}
                  <Button
                    content={
                      props.isNonSubscriber
                        ? "How to Subscribe ACV"
                        : "How to Sign Up"
                    }
                    onClick={() =>
                      window.open("https://docs.acv.app/#acv-account")
                    }
                    className="btn d-none d-lg-inline"
                  />
                </div>
              </>
            }
            style={{
              textAlign: "center",
              maxWidth: "720px",
              background: "transparent",
            }}
          />
        </Flex.Item>
      </Flex>
    </div>
  );
};
export default Auth;
