const d = new Date();
const moment = require("moment");
let lessionData = [
  {
    LessonDate: moment.utc(d).format("YYYY/MM/DD"),
    StartTime: "8:00",
    EndTime: "9:00",
    // ClassName: "Ciencias Naturales - Biologia 1 - 301",
    SubjectType: "Ciencias Naturales",
    SubjectName: "Biologia 1",
    SubjectCode: 301,
    Title:"03d0ba37-ce60-58e6-af07-4bc8468a3ca9",
    TeacherEmailAlias: "janesmith@tangibleitdemo.com",
    FacultadMateria: "Tangible IT k12",
    Tema: "Topic note!",
    Tarea: "chepeter 2",
    ClassType: "Hybrid",
  },
];

let lessionDayData = [
  {
    StartDate: moment.utc(d).format("MM/DD/YYYY"),
    EndDate: moment
      .utc(new Date(new Date().setMonth(new Date().getMonth() + 2)))
      .format("MM/DD/YYYY"),
    // .toISOString()
    // .slice(0, 10),
    // ClassName: "Ciencias Naturales - Biologia 1 - 301",
    SubjectType: "Ciencias Naturales",
    SubjectName: "Biologia 1",
    SubjectCode: 301,
    TeacherEmailAlias: "janesmith@tangibleitdemo.com",
    StartTime: "7:00",
    EndTime: "8:00",
    Sunday: "No",
    Monday: "Yes",
    TuesDay: "Yes",
    Wednesday: "Yes",
    Thursday: "Yes",
    Friday: "Yes",
    Saturday: "No",
    ClassType: "Hybrid",
    FacultadMateria: "Tangible IT k12",
  },
];

let membersData = [
  {
    Teams_Name: "Ciencias Naturales - Biologia 1 - 301",
    SubjectType: "Ciencias Naturales",
    SubjectName: "Biologia 1",
    SubjectCode: 301,
    Member_Name: "Jane Smith",
    Member_Mail: "janesmith@tangibleitdemo.com",
    Role: "Teacher",
    Provider: "microsoft",
  },
  {
    Teams_Name: "Ciencias Naturales - Biologia 1 - 301",
    SubjectType: "Ciencias Naturales",
    SubjectName: "Biologia 1",
    SubjectCode: 301,
    Member_Name: "Michael Jones",
    Member_Mail: "michaeljones0739@gmail.com",
    Role: "Student",
    Provider: "google",
  },
];

let profileData = [
  {
    title: "Microsoft Office",
    category: "microsoft",
  },
  {
    title: "Data Analysis",
    category: "business",
  },
];
let bulkEventImportData = [];
let bulkEventImportTemplateData = [
  {
    title: "Clean Up Our Surroundings: A Community Awareness Event",
    description: `Join us for a day of learning and action as we come together to raise awareness about the importance of cleanliness in our surroundings. This event will feature informative talks from local experts, interactive workshops, and a community clean-up activity. We will discuss the impact of littering and pollution on our environment and how we can all make a difference by practicing proper waste management. Don't miss this opportunity to be a part of positive change and help create a cleaner, healthier community for all. See you there!`,
    startDate: "2024-02-15T18:30:00",
    endDate: "2024-02-15T21:50:00",
    categories: "Social",
    tags: "Awareness",
    speakers: "",
    linkTitle: "Event Link",
    linkURL: "www.yourEventLink.com",
    location: "",
    meetingType: "Google-meet",
  },
  {
    title: "Clean Up Our Surroundings: A Community Awareness Event",
    description: `Join us for a day of learning and action as we come together to raise awareness about the importance of cleanliness in our surroundings. This event will feature informative talks from local experts, interactive workshops, and a community clean-up activity. We will discuss the impact of littering and pollution on our environment and how we can all make a difference by practicing proper waste management. Don't miss this opportunity to be a part of positive change and help create a cleaner, healthier community for all. See you there!`,
    startDate: "2024-02-15T18:30:00",
    endDate: "2024-02-15T21:50:00",
    categories: "Social",
    tags: "Awareness",
    speakers: "",
    linkTitle: "Event Link",
    linkURL: "www.yourEventLink.com",
    location: "",
    meetingType: "microsoft-teams",
  },
  {
    title: "Clean Up Our Surroundings: A Community Awareness Event",
    description: `Join us for a day of learning and action as we come together to raise awareness about the importance of cleanliness in our surroundings. This event will feature informative talks from local experts, interactive workshops, and a community clean-up activity. We will discuss the impact of littering and pollution on our environment and how we can all make a difference by practicing proper waste management. Don't miss this opportunity to be a part of positive change and help create a cleaner, healthier community for all. See you there!`,
    startDate: "2024-02-15T18:30:00",
    endDate: "2024-02-15T21:50:00",
    categories: "Social",
    tags: "Awareness",
    speakers: "",
    linkTitle: "Event Link",
    linkURL: "www.yourEventLink.com",
    location: "Seminar hall",
    meetingType: "offline",
  },
];

export {
  bulkEventImportData,
  bulkEventImportTemplateData,
  lessionData,
  lessionDayData,
  membersData,
  profileData,
};
