import { Checkbox, Flex } from "@fluentui/react-northstar";
import React, { useEffect, useState } from "react";
import "./Slots.scss";
import SlotList from "./SlotList";
import { useBooking } from "../../../../../context/BookingContext";
import moment from "moment";

const Slots = ({ isEditing, isMobileView }) => {
  const { scheduledSlots } = useBooking();

  const slots = [
    {
      shift: "morning",
      data:
        scheduledSlots.filter(
          ({ shift, startTime }) =>
            shift === "morning" && moment(startTime).isAfter(moment())
        ) || [],
    },
    {
      shift: "afternoon",
      data:
        scheduledSlots.filter(
          ({ shift, startTime }) =>
            shift === "afternoon" && moment(startTime).isAfter(moment())
        ) || [],
    },
    {
      shift: "evening",
      data:
        scheduledSlots.filter(
          ({ shift, startTime }) =>
            shift === "evening" && moment(startTime).isAfter(moment())
        ) || [],
    },
  ];
  return (
    <div style={{}}>
      <Flex className="slots-wrapper" gap="gap.large">
        {slots.map(({ shift, data }, i) => (
          <Flex column className="slot-shift" key={`${shift}-slots-${i + 1}`}>
            <div className="slot-shift-header">{shift}</div>
            {data.length ? (
              <SlotList
                shift={shift}
                slots={data}
                isEditing={isEditing}
                isMobileView={isMobileView}
              />
            ) : null}
          </Flex>
        ))}
      </Flex>
    </div>
  );
};

export default Slots;
