import { ProgressIndicator } from "@fluentui/react";
import React from "react";

const CustomProgressIndicator = ({ isLoading, style = {}, ...rest }) => {
  return (
    <ProgressIndicator
      progressHidden={!isLoading}
      styles={{
        root: {
          height: "5px",
        },
        itemProgress: {
          paddingTop: 0,
        },
        progressBar: {
          background:
            "linear-gradient(to right, rgb(237, 235, 233) 0%, #5a5b96 50%, rgb(237, 235, 233) 100%)",
        },
        ...style,
      }}
      {...rest}
    />
  );
};

export default CustomProgressIndicator;
