import React, { useState } from "react";
import { toast } from "react-toastify";
import "./Stud.css";

const Stud = ({ data: user }) => {
  const [link, setLink] = useState("");
  const [lessonId, setLessonId] = useState("");
  const [showFrame, setShowFrame] = useState(false);
  const handleClick = () => {
    const splitLesson = link.split("&lessonId=");
    splitLesson.length > 0 && setLessonId(splitLesson[1]);

    if (!splitLesson[1]) return toast.error("Lesson id not Found!!");

    const data = {
      access_token: user.accessToken,
      user: user,
      lesson: lessonId,
    };

    setShowFrame(true);
    localStorage.setItem("msg", JSON.stringify(data));
    const iframeEl = document.getElementsByClassName("frameTag");
    // Make sure you are sending a string, and to stringify JSON
    iframeEl[0]?.contentWindow?.postMessage(
      {
        source: "whiteboard",
        message: data,
      },
      process.env.REACT_APP_EP_WHITEBOARD
    );
  };

  return (
    <div className="container stud">
      {!showFrame ? (
        <div className="container__item">
          <h1>Welcome {user.givenName}</h1>
          <form className="form">
            <input
              type="text"
              className="form__field"
              placeholder="Paste your collaboration link"
              onChange={(e) => setLink(e.target.value)}
            />
            <button
              type="button"
              className="btn btn--primary btn--inside uppercase"
              onClick={handleClick}
            >
              Explore Whiteboard
            </button>
          </form>
        </div>
      ) : (
        <iframe
          // src={`https://localhost:3001/${
          src={`${process.env.REACT_APP_EP_WHITEBOARD}/${user.slug}&lessonId=${
            lessonId || "LessonId"
          }`}
          title="Whiteboard"
          className="frameTag"
        ></iframe>
      )}
    </div>
  );
};

export default Stud;
