import React, { useState, useEffect } from "react";

import {
  Button,
  FilterIcon,
  Flex,
  Loader,
  Text,
} from "@fluentui/react-northstar";
import { withTranslation } from "react-i18next";
import axios from "axios";

import { LoadingButton } from "@mui/lab";
import { Edit, Trash } from "react-feather";
import styled from "styled-components";
import { ComboBox, SelectableOptionMenuItemType } from "@fluentui/react";
import { Tooltip } from "antd";

import { AlertDialog } from "../../../../../components/Dialog/AlertDialog";
import { NotificationAlert } from "../../../../../components/Notification/Notification";

import RubricSection from "./components/RubricSection";

import "./RubricBank.scss";

const Card = styled(Flex)`
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
  padding: 20px;
  border-radius: 8px;
  flex-wrap: wrap;
  align-items: center;
`;

const url = process.env.REACT_APP_EP_URL;

const RubricBank = ({ user, t, isMobileView, isNew, setIsNew }) => {
  const translation = t("assignment").body;
  const rubricTranslation = translation.rubricPage;

  const [rubrics, setRubrics] = useState([]);
  const [filteredRubrics, setFilteredRubrics] = useState([]);
  const [selectedRubricData, setSelectedRubricData] = useState(null);
  const [refreshData, setRefreshData] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [deleteRubric, setDeleteRubric] = useState({
    id: null,
    isOpen: false,
    isLoading: false,
  });
  const [lessons, setLessons] = useState([]);
  const [isLessonLoading, setIsLessonLoading] = useState(false);
  const [selectedLessons, setSelectedLessons] = useState([]);
  const [isTabletOrMobileView, setIsTabletOrMobileView] = useState(
    window.innerWidth <= 991
  );

  useEffect(() => {
    const handleResize = () => {
      setIsTabletOrMobileView(window.innerWidth <= 991);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  // array of selectable options for convenience
  const selectableOptions = lessons.filter(
    (option) =>
      (option.itemType === SelectableOptionMenuItemType.Normal ||
        option.itemType === undefined) &&
      !option.disabled
  );

  const onChange = (event, option, index, value) => {
    const selected = option?.selected;
    const currentSelectedOptionKeys = selectedLessons.filter(
      (key) => key !== "selectAll"
    );
    const selectAllState =
      currentSelectedOptionKeys.length === selectableOptions.length;

    if (option) {
      if (option?.itemType === SelectableOptionMenuItemType.SelectAll) {
        selectAllState
          ? setSelectedLessons([])
          : setSelectedLessons([
              "selectAll",
              ...selectableOptions.map((o) => o.key),
            ]);
      } else {
        const updatedKeys = selected
          ? [...currentSelectedOptionKeys, option.key]
          : currentSelectedOptionKeys.filter((k) => k !== option.key);
        if (updatedKeys.length === selectableOptions.length) {
          updatedKeys.push("selectAll");
        }
        setSelectedLessons(updatedKeys);
      }
    }
  };

  const getRubricBankData = async () => {
    try {
      setIsLoading(true);
      let response = await axios.get(
        `${url}/api/rubric/get-rubric-bank-item${user.slug}`,
        {
          headers: {
            Authorization: "Bearer " + user.accessToken,
          },
        }
      );
      if (response.data?.results?.length) {
        setRubrics(
          response.data.results.map(
            ({ rubricBankItem, rubricBankId, rubricTitle, ...rest }) => ({
              ...rest,
              rubricBankId,
              rubricTitle,
              rubricItems: rubricBankItem?.map(
                ({
                  rubricBankCriteria,
                  rubricBankItemId,
                  rubricTitle,
                  ...rest
                }) => ({
                  ...rest,
                  id: rubricBankItemId,
                  levels: rubricBankCriteria?.map(
                    ({ criteriaTitle, rubricBankCriteriaId, ...rest }) => ({
                      ...rest,
                      id: rubricBankCriteriaId,
                      levelTitle: criteriaTitle,
                    })
                  ),
                  title: rubricTitle,
                })
              ),
            })
          )
        );
      } else {
        setRubrics([]);
      }
    } catch (error) {
      console.log(error);
    } finally {
      setIsLoading(false);
    }
  };

  const getAllLessons = async () => {
    setIsLessonLoading(true);
    try {
      const { data } = await axios.get(
        `${url}/api/lession/get-lessions${user.slug}`,
        {
          headers: {
            Authorization: "Bearer " + user.accessToken,
          },
        }
      );

      if (data?.length) {
        let lessonsData = data.map((d) =>
          d.ClassName?.substring(0, d.ClassName.lastIndexOf("-") + 1)
            .slice(0, -1)
            .trim()
        );

        let formattedLessons = new Map(
          lessonsData.map((item) => [item, { key: item, text: item }])
        ).values();
        let options = [
          {
            key: "selectAll",
            text: rubricTranslation.selectAll,
            itemType: SelectableOptionMenuItemType.SelectAll,
          },
          ...formattedLessons,
        ];
        setLessons(options);
      } else {
        setLessons([]);
      }
    } catch (error) {
      console.log(error);
      if (error?.response?.data) {
        let errorData = error?.response?.data;
        NotificationAlert(errorData?.message, errorData?.status?.toLowerCase());
      }
    } finally {
      setIsLessonLoading(false);
    }
  };

  useEffect(() => {
    getAllLessons();
  }, []);

  useEffect(() => {
    getRubricBankData();
  }, [refreshData]);

  useEffect(() => {
    let rubricData = rubrics;
    if (selectedLessons.length) {
      let formattedLessons = selectedLessons.filter(
        (item) => item !== "selectAll"
      );
      rubricData = rubricData.filter((rubric) => {
        const classData = rubric.class?.length ? rubric.class : [];
        return formattedLessons.some((item) => classData.includes(item));
      });
    }
    setFilteredRubrics(rubricData);
  }, [rubrics, selectedLessons]);

  const deleteRubricHandler = async () => {
    try {
      setDeleteRubric((prev) => ({
        ...prev,
        isOpen: false,
        isLoading: true,
      }));
      const { data } = await axios({
        url: `${url}/api/rubricBank/delete-rubric-bank/${deleteRubric.id}${user.slug}`,
        method: "delete",
        headers: {
          Authorization: "Bearer " + user.accessToken,
        },
      });

      if (data) {
        NotificationAlert(data.message, data.status?.toLowerCase());
        if (data.status?.toLowerCase() === "success") {
          setRefreshData((prev) => !prev);
        }
      }
    } catch (error) {
      if (error.response?.data) {
        NotificationAlert(
          error.response.data.message,
          error.response.data.status?.toLowerCase()
        );
      }
    } finally {
      setDeleteRubric({
        isOpen: false,
        id: null,
        isLoading: false,
      });
    }
  };

  return (
    <>
      <AlertDialog
        handleClose={() => {
          setDeleteRubric((prev) => ({
            ...prev,
            isOpen: false,
          }));
        }}
        handleOk={() => {
          deleteRubricHandler();
        }}
        open={deleteRubric.isOpen}
      />
      <div
        style={{
          width: "100%",
          margin: isTabletOrMobileView ? "0px auto" : "20px auto",
          overflow: "auto",
          height: isMobileView ? "calc(100vh - 135px)" : "calc(100vh - 163px)",
        }}
        className="btsp rubric-bank-container"
      >
        {selectedRubricData || isNew ? (
          <div>
            <RubricSection
              data={selectedRubricData}
              isNew={isNew}
              user={user}
              onSubmit={() => {
                setSelectedRubricData(null);
                setRefreshData((prev) => !prev);
                setIsNew(false);
              }}
              onCancel={() => {
                setSelectedRubricData(null);
                setIsNew(false);
              }}
              lessonOptions={lessons}
              isLessonLoading={isLessonLoading}
            />
          </div>
        ) : null}
        {!(!!selectedRubricData || isNew) && (
          <div className="ms-Grid rubric-list-section">
            <div>
              <div>
                <Flex
                  hAlign="end"
                  vAlign="center"
                  wrap
                  className="lesson-create-button-wrapper"
                >
                  <Flex className="lesson-dropdown-wrapper">
                    <FilterIcon className="filter-icon" size="large" />
                    <ComboBox
                      className="lesson-dropdown"
                      label={rubricTranslation.filterByClass}
                      multiSelect
                      options={lessons}
                      selectedKey={selectedLessons}
                      onChange={onChange}
                      allowFreeform
                      placeholder={
                        isLessonLoading
                          ? translation.loading
                          : rubricTranslation.selectClass
                      }
                    />
                  </Flex>
                  <Flex style={{ alignSelf: "flex-end" }}>
                    <Button
                      style={{ borderRadius: "5px" }}
                      content={rubricTranslation.addRubric}
                      primary
                      onClick={() => {
                        setIsNew(true);
                      }}
                    />
                  </Flex>
                </Flex>
              </div>
              <Card className="ms-Grid-row my-3">
                <div className="ms-Grid-col ms-sm12 ms-lg4">
                  {rubricTranslation.rubricTitle}
                </div>
                <div className="ms-Grid-col ms-sm12 ms-lg4 text-center">
                  {rubricTranslation.class}
                </div>
                <div className="ms-Grid-col ms-sm12 ms-lg4 text-right pr-3">
                  {rubricTranslation.action}
                </div>
              </Card>
              {isLoading && !rubrics.length ? (
                <Loader style={{ paddingTop: "50px" }} />
              ) : filteredRubrics.length ? (
                <div>
                  {filteredRubrics.map((rubric) => (
                    <Card
                      key={rubric.rubricBankId}
                      className="ms-Grid-row my-3"
                    >
                      <div className="ms-Grid-col ms-sm12 ms-lg4">
                        {rubric.rubricTitle ?? ""}
                      </div>
                      <div className="ms-Grid-col ms-sm12 ms-lg4 class-names">
                        <Tooltip
                          title={
                            rubric.class?.length
                              ? rubric.class.map((item) => (
                                  <p style={{ margin: 0 }}>{item}</p>
                                ))
                              : ""
                          }
                        >
                          {rubric.class?.length ? rubric.class.join(",") : ""}
                        </Tooltip>
                      </div>
                      <div className="ms-Grid-col ms-sm12 ms-lg4 text-right">
                        <Button
                          icon={<Edit />}
                          iconOnly
                          title={rubricTranslation.editRubric}
                          onClick={() => {
                            setSelectedRubricData(
                              rubrics.find(
                                (item) =>
                                  item.rubricBankId === rubric.rubricBankId
                              )
                            );
                          }}
                          style={{ color: "#585A96", borderRadius: 5 }}
                        />

                        <LoadingButton
                          loading={
                            rubric.rubricBankId === deleteRubric.id &&
                            deleteRubric.isLoading
                          }
                          startIcon={
                            (rubric.rubricBankId !== deleteRubric.id ||
                              !deleteRubric.isLoading) && (
                              <Trash
                                height={18}
                                width={18}
                                style={{ margin: 0 }}
                              />
                            )
                          }
                          iconOnly
                          title={translation.delete}
                          onClick={() => {
                            setDeleteRubric({
                              id: rubric.rubricBankId,
                              isOpen: true,
                              isLoading: false,
                            });
                          }}
                          style={{
                            color: "#DE5854",
                            borderRadius: 5,
                            boxShadow:
                              "rgba(0, 0, 0, 0.1) 0px 0.2rem 0.4rem -0.075rem",
                            border: "1px solid rgb(225, 223, 221)",
                            width: 33,
                            minWidth: 0,
                            height: 31,
                          }}
                          sx={{ imageResolution: 10 }}
                        />
                      </div>
                    </Card>
                  ))}
                </div>
              ) : (
                <Text align="center" weight="semibold" className="mt-5">
                  {rubrics.length
                    ? rubricTranslation.noRubricDataForFilter
                    : rubricTranslation.noRubricDataAvailable}
                </Text>
              )}
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default withTranslation()(RubricBank);
