import React, { useCallback, useEffect, useState } from "react";

import {
  Flex,
  Datepicker,
  Dropdown,
  Button,
  Input,
  Segment,
  SearchIcon,
  CloseIcon,
  Checkbox,
} from "@fluentui/react-northstar";

import axios from "axios";
import { ArrowLeftCircle, ArrowRightCircle } from "react-feather";
import { useTranslation, withTranslation } from "react-i18next";
import moment from "moment";

import { useStudentSubmission } from "../../../../../context/StudentSubmissionContext";

import Tour from "../../../Tour";

import "./Controls.css";

const Controls = (props) => {
  const {
    currentPage,
    subjectListForDropdown,
    teacherList,
    setTeacherList,
    setSelectedSubject,
    setSelectedTeacher,
    setGlobalSearch,
    setDateFilter,
    dateFilter,
    isFilteredByCreationDate,
    setIsFilteredByCreationDate,
  } = useStudentSubmission();

  const { user, t } = props;

  const translation = t("assignment").controls;

  const url = process.env.REACT_APP_EP_URL;
  const [isVisited, setIsVisited] = useState(true);

  const headers = {
    Authorization: "Bearer " + props.state.accessToken,
  };

  useEffect(() => {
    // fetchUserVisit();
  }, [user]);

  const fetchUserVisit = async () => {
    try {
      const response = await axios({
        url: `${process.env.REACT_APP_EP_URL}/api/assignment/check-user-visit${user.slug}&userId=${user.mail}&module=assignment`,
      });
      setIsVisited(!!response.data?.results);
    } catch {}
  };

  const debounce = (func) => {
    let timer;
    return function (...args) {
      const context = this;
      if (timer) clearTimeout(timer);
      timer = setTimeout(() => {
        timer = null;
        func.apply(context, args);
      }, 500);
    };
  };

  const handleChange = (value) => {
    setGlobalSearch(value);
  };

  const optimizedFn = useCallback(debounce(handleChange), []);

  const getA11ySelectionMessage = {
    onAdd: (item) =>
      `${item.header} selected. Press left or right arrow keys to navigate selected items.`,
    onRemove: (item) => `${item.header} has been removed.`,
  };

  const clearDateFilterHandler = () => {
    setDateFilter({ start: null, end: null });
  };

  return (
    <div className="Controls">
      <Flex
        gap="gap.smaller"
        wrap
        space="between"
        className="student-assignment-controls"
      >
        <Flex wrap>
          {currentPage === "subjects" || currentPage === "todos" ? (
            <>
              <>
                <div
                  className="leftControls"
                  style={{ margin: 0, marginLeft: "31px" }}
                >
                  <Segment
                    content={translation.filterBySubject}
                    className="seg1"
                  />
                  <Segment
                    content={
                      <Dropdown
                        items={
                          subjectListForDropdown?.length
                            ? subjectListForDropdown
                            : []
                        }
                        clearable
                        placeholder={translation.selectSubject}
                        noResultsMessage={translation.noMatches}
                        getA11ySelectionMessage={{
                          onAdd: (item) =>
                            `${item} ${translation.hasBeenSelected}.`,
                        }}
                        autoSize
                        onChange={(e, { value }) => setSelectedSubject(value)}
                        search
                      />
                    }
                    className="seg2 student-assignment-subject-select tour-student-assignment-subject-select"
                  />
                </div>
                <div
                  className="leftControls"
                  style={{ margin: 0, marginLeft: "31px" }}
                >
                  <Segment
                    content={translation.filterByTeacher}
                    className="seg1"
                  />
                  <Segment
                    content={
                      <Dropdown
                        clearable
                        items={
                          teacherList?.length
                            ? teacherList
                                .map((teacher) => teacher.TeacherName)
                                .filter((teacher) => teacher)
                            : []
                        }
                        placeholder={translation.selectTeacher}
                        noResultsMessage={translation.noMatches}
                        getA11ySelectionMessage={{
                          onAdd: (item) =>
                            `${item} ${translation.hasBeenSelected}.`,
                        }}
                        onChange={(e, { value }) => setSelectedTeacher(value)}
                      />
                    }
                    className="seg2 student-assignment-teacher-select tour-student-assignment-teacher-select"
                  />
                </div>
              </>
            </>
          ) : (
            <></>
          )}
          {currentPage === "assignmentList" || currentPage === "todos" ? (
            <Flex wrap className="tour-start-end-date-filter">
              <div
                className="leftControls"
                style={{ margin: 0, marginLeft: "31px" }}
              >
                <Segment content={translation.from} className="seg1" />
                <Segment
                  content={
                    <Datepicker
                      // input={{
                      //   clearable: true,
                      // }}
                      inputPlaceholder={translation.date}
                      selectedDate={dateFilter?.start}
                      onDateChange={(e, { value }) => {
                        let formattedDate = null;
                        if (value) {
                          formattedDate = new Date(
                            moment(
                              moment(value).format("YYYY-MM-DD") + " 00:00:00"
                            ).format("YYYY-MM-DDTHH:mm:ss")
                          );
                        }

                        setDateFilter((prevDateFilter) => ({
                          ...prevDateFilter,
                          start: formattedDate,
                        }));
                      }}
                      id="datePicker-1"
                    />
                  }
                  className="seg2"
                />
              </div>
              <div
                className="leftControls"
                style={{ margin: 0, marginLeft: "31px" }}
              >
                <Segment content={translation.to} className="seg1" />
                <Segment
                  content={
                    <Datepicker
                      // input={{
                      //   clearable: true,
                      // }}
                      defaultSelectedDate={null}
                      selectedDate={dateFilter?.end}
                      inputPlaceholder={translation.date}
                      onDateChange={(e, { value }) => {
                        let formattedDate = null;
                        if (value) {
                          formattedDate = new Date(
                            moment(
                              moment(value).format("YYYY-MM-DD") + " 23:59:59"
                            ).format("YYYY-MM-DDTHH:mm:ss")
                          );
                        }
                        setDateFilter((prevDateFilter) => ({
                          ...prevDateFilter,
                          end: formattedDate,
                        }));
                      }}
                      id="datePicker-1"
                    />
                  }
                  className="seg2"
                />
              </div>
              {dateFilter.start || dateFilter.end ? (
                <div className="leftControls" style={{ marginLeft: "31px" }}>
                  <Segment content={""} className="seg1" />
                  <Segment
                    content={
                      <Button
                        icon={<CloseIcon circular outline size="large" />}
                        iconOnly
                        title={translation.clearDate}
                        onClick={clearDateFilterHandler}
                      />
                    }
                    className="seg2"
                  />
                </div>
              ) : null}
              <Checkbox
                styles={{ alignItems: "center", marginLeft: "0.8rem" }}
                label={"Filter by creation date"}
                toggle
                checked={isFilteredByCreationDate}
                onChange={(e, { checked }) =>
                  setIsFilteredByCreationDate(checked)
                }
              />
            </Flex>
          ) : (
            <></>
          )}
        </Flex>
        <Flex wrap>
          <div className="leftControls">
            <Segment
              content={
                <Flex wrap className="student-assignment-tour-search-wrapper">
                  <Input
                    icon={<SearchIcon />}
                    clearable
                    placeholder={translation.globalSearch}
                    onChange={(e) => optimizedFn(e.target.value)}
                    className="mr-3 tour-student-assignment-global-search"
                  />
                  {window.innerWidth > 504 && (
                    <div className="mr-3">
                      <Tour
                        currentPage={currentPage}
                        isStudentRole={props.user?.role === "Student"}
                        isVisited={isVisited}
                      />
                    </div>
                  )}
                </Flex>
              }
              className="seg2"
            />
          </div>
          <div className="andy">
            <Flex gap="gap.medium" wrap className="ctnr">
              <Flex
                gap="gap.smaller"
                vAlign="center"
                hAlign="center"
                className="nextBtn"
              >
                <Button
                  icon={<ArrowRightCircle />}
                  iconOnly
                  primary
                  onClick={() => {
                    document
                      .querySelector(".Attendance.s-ctr")
                      .classList.add("h-ctr");
                    document
                      .querySelector(".Attendance.s-ctr")
                      .classList.remove("s-ctr");
                  }}
                />
              </Flex>
              <Flex
                gap="gap.smaller"
                vAlign="center"
                hAlign="center"
                className="prevBtn"
              >
                <Button
                  icon={<ArrowLeftCircle />}
                  iconOnly
                  primary
                  onClick={() => {
                    document
                      .querySelector(".Attendance.h-ctr")
                      .classList.add("s-ctr");
                    document
                      .querySelector(".Attendance.h-ctr")
                      .classList.remove("h-ctr");
                  }}
                />
              </Flex>
            </Flex>
          </div>
        </Flex>
      </Flex>
    </div>
  );
};

export default withTranslation()(Controls);
