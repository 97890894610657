import React, { useState, useEffect } from "react";
import { Flex, Loader, Text } from "@fluentui/react-northstar";
import { useHistory, useLocation, useParams } from "react-router-dom";
import axios from "axios";
import { IconButton } from "@fluentui/react";
import styled from "styled-components";
import { withTranslation } from "react-i18next";

import { NotificationAlert } from "../../../../components/Notification/Notification";

import FeedbackResponseCard from "../shared/FeedbackResponseCard/FeedbackResponseCard";
import { uploadFileToBlob } from "../../utils/uploadData";
import AllQuestionResponses from "./AllQuestionResponses";

import { ResponsiveImage } from "../../../../components/blocks/Images";
import noFeedbackImage from "../../../../Assets/images/conversation.png";

import "./SubmitFeedback.scss";

const url = process.env.REACT_APP_EP_URL;

const FEEDBACK_TYPES = {
  all: "all",
  any: "ANY",
  text: "TEXT",
  audio: "AUDIO",
  video: "VIDEO",
  poll: "POLL",
};

const completed = "Completed";
const progress = "Progress";

const BackButtonSection = styled.div`
  margin-top: 10px;
`;

const SchoolNameWrapper = styled(Text)`
  margin: 0 5px;
`;

const NoQuestionsTextWrapper = styled(Flex)`
  margin-top: 100px;
  text-align: center;
  flex-grow: 1;
  justify-content: center;
`;
const NoQuestionsText = styled(Text)`
  font-size: 21px;
  color: #d1d1d1;
`;

const SubmitFeedbackResponse = ({
  user,
  logout = () => {},
  isSiteEmbeded,
  t,
  setFeedbackLimitDialog,
}) => {
  const history = useHistory();
  const { feedbackId } = useParams();
  const { state } = useLocation();

  const [feedbackData, setFeedbackData] = useState(null);

  const [isLoading, setIsLoading] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const [isResponseAlreadyGiven, setIsResponseAlreadyGiven] = useState(false);
  const [feedbackExistInOtherSlug, setFeedbackExistInOtherSlug] =
    useState(null);
  const [isFeedbackCreator, setIsFeedbackCreator] = useState(false);
  const [questionResponses, setQuestionResponses] = useState([]);
  const [currentQuestion, setCurrentQuestion] = useState(0);

  const translation = t("feedbackModule");

  const commonTranslation = translation.common;
  const feedbackTranslation = translation.body.submitFeedback;

  const getFeedback = async () => {
    setIsLoading(true);
    try {
      const { data } = await axios({
        url: `${url}/api/feedback/assignedUserFeedbackById/${feedbackId}${user.slug}`,
        headers: {
          Authorization: `Bearer ${user.accessToken}`,
        },
      });
      if (data?.result?.data && Object.entries(data.result.data).length) {
        let {
          feedbackRequestId,
          feedbackType,
          title,
          userID,
          requestedDate,
          endDate,
          isStopAcceptingResponse,
          everyoneInOrganization,
          status,
          videoQuestionUrl,
          groupId,
          classId,
          pollOptions,
          FeedbackSubmissions,
          feedbackRequestUser,
          description,
          isAutoLogoutAfterSubmission,
        } = data.result.data;
        let formattedData = {
          submission: FeedbackSubmissions?.length ? FeedbackSubmissions[0] : {},
          ...data.result.data,
          id: feedbackRequestId,
          dateCreated: requestedDate,
          feedbackEndsOn: endDate,
          videoUrl: videoQuestionUrl,
          pollOptions:
            Array.isArray(pollOptions) && pollOptions.length ? pollOptions : [],
          createdBy: {
            ...feedbackRequestUser,
            name: `${feedbackRequestUser.firstName} ${
              feedbackRequestUser.lastName ?? ""
            }`,
            imageUrl: feedbackRequestUser.photo,
            role: feedbackRequestUser.role,
          },
          description,
          isAutoLogoutAfterSubmission,
        };
        setFeedbackData({ ...formattedData });
        if (data?.result?.isFeedbackCreator) {
          setIsFeedbackCreator(true);
        } else {
          setIsFeedbackCreator(false);
        }
      } else if (
        data?.result?.dataInOtherSlug &&
        Object.entries(data.result.dataInOtherSlug).length
      ) {
        setFeedbackExistInOtherSlug(data.result.dataInOtherSlug);
        setIsFeedbackCreator(false);
      }
    } catch (error) {
      console.log(error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (feedbackId) {
      // caching issue. Not showing already given feedback message
      // if (state?.feedback) {
      //   setFeedbackData({ ...state.feedback });
      // } else {
      getFeedback();
      // }
    }
  }, []);

  useEffect(() => {
    let submission = feedbackData?.submission;
    if (submission) {
      let questionSubmissions = submission.FeedbackQueSubmissions?.length
        ? submission.FeedbackQueSubmissions
        : [];

      setQuestionResponses(questionSubmissions);
      setIsResponseAlreadyGiven(submission.isResponseSubmitted);
      //   setIsResponseAlreadyGiven(
      //     submission.videoResponse ||
      //       submission.audioResponse ||
      //       submission.textResponse ||
      //       submission.pollResponse
      //   );
      //   if (submission.videoResponse) {
      //     setVideoState({
      //       isSaved: true,
      //       isStart: false,
      //       videoUrl: submission.videoResponse,
      //     });
      //   }
      //   if (submission.audioResponse) {
      //     setVoiceState({
      //       open: false,
      //       isStart: false,
      //       isSaved: true,
      //       isPause: false,
      //       isDone: true,
      //       audioUrl: submission.audioResponse,
      //     });
      //   }
      //   setTextResponse(submission.textResponse);
      //   setSelectedOption(
      //     submission.pollResponse
      //       ? submission.pollResponse
      //       : feedbackData.pollOptions?.[0]?.value
      //   );
    } else {
      setQuestionResponses([]);
    }
    // else {
    //   // setVideoState({
    //   //   isSaved: false,
    //   //   isStart: false,
    //   //   videoUrl: null,
    //   // });
    //   // setVoiceState({
    //   //   open: false,
    //   //   isStart: false,
    //   //   isSaved: false,
    //   //   isPause: false,
    //   //   isDone: false,
    //   //   audioUrl: "",
    //   // });
    //   // setTextResponse("");
    //   // setSelectedOption(null);
    // }
  }, [feedbackData]);

  const validateResponse = () => {
    let isValid = false;
    if (feedbackData.feedbackType === FEEDBACK_TYPES.text) {
      isValid = !!textResponse?.replace(/\s/g, "")?.length;
    }
    if (feedbackData.feedbackType === FEEDBACK_TYPES.video) {
      isValid = videoState.isSaved && videoState.videoUrl?.length;
    }
    if (feedbackData.feedbackType === FEEDBACK_TYPES.audio) {
      isValid = voiceState.isDone && voiceState.audioUrl?.length;
    }
    if (feedbackData.feedbackType === FEEDBACK_TYPES.poll) {
      isValid = selectedOption;
    }
    if (feedbackData.feedbackType === FEEDBACK_TYPES.any) {
      isValid =
        !!textResponse?.replace(/\s/g, "")?.length ||
        (videoState.isSaved && videoState.videoUrl?.length) ||
        (voiceState.isDone && voiceState.audioUrl?.length);
    }

    return isValid;
  };

  const submitFeedbackHandler = async (finalQuestionResponse) => {
    // const isValid = validateResponse();
    // if (!isValid) {
    //   return;
    // }
    let isSuccessFull = false;
    // const payload = {
    //   textResponse: textResponse,
    //   audioResponse: null,
    //   pollResponse: selectedOption,
    //   videoResponse: null,
    //   feedbackRequestId: feedbackData.id,
    //   submittedBy: user.mail,
    // };
    try {
      setIsSubmitting(true);

      let payload = [
        ...questionResponses.map((item) =>
          item.questionId ===
          finalQuestionResponse.FeedbackQuestion?.feedbackQuestionId
            ? finalQuestionResponse
            : item
        ),
      ];

      let basePath = `feedback/submit-feedback/${user.mail}/${questionResponses[0].FeedbackQuestion.feedbackRequestId}`;

      let formattedPayload = await Promise.all(
        payload.map(async (questionResponse) => {
          let question = questionResponse.FeedbackQuestion;
          let storagePath = `${basePath}/${
            questionResponse.feedbackQuestionSubmissionId
          }-${Date.now()}`;
          if (
            (question.questionType === FEEDBACK_TYPES.video ||
              question.questionType === FEEDBACK_TYPES.any) &&
            questionResponse.videoResponse?.length
          ) {
            questionResponse.videoResponse = await uploadFileToBlob(
              await fetch(questionResponse.videoResponse).then((r) => r.blob()),
              `${storagePath}/video`
            );
          }
          if (
            (question.questionType === FEEDBACK_TYPES.audio ||
              question.questionType === FEEDBACK_TYPES.any) &&
            questionResponse.audioResponse?.length
          ) {
            questionResponse.audioResponse = await uploadFileToBlob(
              await fetch(questionResponse.audioResponse).then((r) => r.blob()),
              `${storagePath}/audio`
            );
          }
          return questionResponse;
        })
      );

      const { data } = await axios({
        url: `${url}/api/feedback/submission/submit${user.slug}`,
        method: "POST",
        data: {
          questionsResponses: formattedPayload,
          feedbackRequestId:
            questionResponses[0].FeedbackQuestion.feedbackRequestId,
        },
        headers: {
          Authorization: `Bearer ${user.accessToken}`,
        },
      });

      if (data?.status?.toLowerCase() === "success") {
        NotificationAlert(
          data.result === "Feedback submit successfully"
            ? feedbackTranslation.feedbackSubmitSuccess
            : data.result,
          "success"
        );
        isSuccessFull = true;
      } else if (data?.status?.toLowerCase() === "error") {
        if (data?.showPopup) {
          setFeedbackLimitDialog({ show: true, message: data.message });
        } else {
          NotificationAlert(data.message, "error");
        }
      }
    } catch (error) {
      console.log(error);
    } finally {
      setIsSubmitting(false);

      if (isSuccessFull) {
        if (feedbackData.isAutoLogoutAfterSubmission) {
          setTimeout(() => {
            logout();
          }, 500);
        } else {
          history.push("/feedback/submit-feedback");
        }
      }
    }
  };

  return (
    <div className="submit-feedback-response">
      <div className="submit-feedback-response-container">
        <div className="ms-Grid">
          {!isSiteEmbeded && (
            <div>
              <Flex vAlign="center">
                <IconButton
                  iconProps={{ iconName: "ChromeBack" }}
                  title={commonTranslation.back}
                  ariaLabel={commonTranslation.back}
                  onClick={() => {
                    history.push("/feedback/submit-feedback");
                  }}
                />
                <Text
                  size="large"
                  weight="semibold"
                  style={{ marginRight: "10px" }}
                >
                  {feedbackTranslation.allFeedbacks}
                </Text>
              </Flex>
            </div>
          )}
          {feedbackData ? (
            isResponseAlreadyGiven ||
            feedbackData.status === completed ||
            feedbackData.isStopAcceptingResponse ? (
              <Flex hAlign="center" column fill style={{ marginTop: "40px" }}>
                <ResponsiveImage
                  src={noFeedbackImage}
                  style={{ width: "252px" }}
                />
                <Text size="large" align="center">
                  {isResponseAlreadyGiven
                    ? feedbackTranslation.alreadyGivenResponseToFeedback
                    : feedbackData.status === completed
                    ? feedbackTranslation.feedbackCompleted
                    : feedbackTranslation.feedbackResponseStopped}
                </Text>
              </Flex>
            ) : (
              <div className="ms-Grid-row submit-feedback-section">
                <div
                  className="ms-Grid-col ms-sm12 ms-md12 ms-lg6 ms-xl4 ms-xxl3"
                  style={{ marginBottom: "15px" }}
                >
                  <FeedbackResponseCard
                    title={feedbackData.title}
                    videoUrl={feedbackData.videoUrl}
                    status={feedbackData.status}
                    endsOn={feedbackData.feedbackEndsOn}
                    description={feedbackData.description}
                    showExcelExport={false}
                    isShowQuestionNumber={questionResponses.length > 1}
                    currentQuestion={
                      questionResponses.length ? currentQuestion + 1 : 0
                    }
                    totalQuestion={questionResponses.length}
                  />
                </div>
                <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg6 ms-xl8 ms-xxl9">
                  {questionResponses.length ? (
                    <Flex column>
                      <AllQuestionResponses
                        {...{
                          feedbackData,
                          FEEDBACK_TYPES,
                          currentQuestion,
                          setCurrentQuestion,
                          isSubmitting,
                          completed,
                          isFeedbackCreator,
                          questionResponses,
                          setQuestionResponses,
                          submitFeedbackHandler,
                        }}
                      />
                    </Flex>
                  ) : (
                    <NoQuestionsTextWrapper>
                      <NoQuestionsText>
                        {feedbackTranslation.noQuestionAvailable}
                      </NoQuestionsText>
                    </NoQuestionsTextWrapper>
                  )}
                </div>
              </div>
            )
          ) : isLoading ? (
            <Loader style={{ paddingTop: "50px" }} />
          ) : feedbackExistInOtherSlug ? (
            <Flex hAlign="center" column fill style={{ marginTop: "40px" }}>
              <ResponsiveImage
                src={noFeedbackImage}
                style={{ width: "252px" }}
              />
              <Flex hAlign="center">
                <Text align="center" size="large">
                  <Text>{feedbackTranslation.feedbackExistInOtherSlug}:</Text>
                  <SchoolNameWrapper weight="semibold">
                    {feedbackExistInOtherSlug.School}
                  </SchoolNameWrapper>
                  <Text>({feedbackExistInOtherSlug.OwnStgUrl})</Text>
                </Text>
              </Flex>
            </Flex>
          ) : (
            <Flex hAlign="center" column fill style={{ marginTop: "40px" }}>
              <ResponsiveImage
                src={noFeedbackImage}
                style={{ width: "252px" }}
              />
              <Text size="large">{feedbackTranslation.noFeedbackData}</Text>
            </Flex>
          )}
        </div>
      </div>
    </div>
  );
};

export default withTranslation()(SubmitFeedbackResponse);
