import React, { useState, useRef, useEffect } from "react";
import axios from "axios";
import { toast } from "react-toastify";
import ReactCrop from "react-image-crop";
import "react-image-crop/dist/ReactCrop.css";
import { Button, Loader, Flex, Dropdown } from "@fluentui/react-northstar";
import styled from "styled-components";
import { Avatar } from "@fluentui/react-northstar";
import InstagramIcon from "@mui/icons-material/Instagram";
import FacebookIcon from "@mui/icons-material/Facebook";
import TwitterIcon from "@mui/icons-material/Twitter";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import { withTranslation } from "react-i18next";

import "./StudentProfile.scss";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import Profile from "../../Account/Components/Profile";
import { NotificationAlert } from "../../../components/Notification/Notification";
import userSvg from "../../../Assets/images/svg/user.svg";

const ImageEdit = "ImageEdit";

const EditButton = styled(Button)`
  padding: 5px 10px;
  border-radius: 3px !important;
`;

const SocialMediaButtonConatiner = styled(Flex)`
  margin-top: 5px;
  row-gap: 5px;
  column-gap: 5px;
`;

const SocialMediaButton = styled(Button)`
  border-radius: 50% !important;
`;

const MyProgressButton = styled(Button)`
  padding: 5px 10px;
  border-radius: 3px !important;
`;

const url = process.env.REACT_APP_EP_URL;

const StudentProfile = (props) => {
  const { refreshUserProfileData, t } = props;

  const translation = t("feedback");
  const feedbackTranslation = translation.body.mainPage;

  const inputRef = useRef(null);
  const [fileData, setFileData] = useState(props.user.UrlPhoto);
  const [fileObject, setFileObject] = useState(null);
  const [croppedFileObject, setCroppedFileObject] = useState(null);
  const [croppedImage, setCroppedImage] = useState(props.user.UrlPhoto);
  const [openModal, setOpenModal] = useState(false);
  const [isEditProfile, setIsEditProfile] = useState(false);
  const [userProfileData, setUserProfileData] = useState({});
  const [isRefreshData, setIsRefreshData] = useState(false);
  const [isLoadingData, setIsLoadingData] = useState(false);
  const [loadingImage, setLoadingImage] = useState({
    type: null,
    isLoading: false,
  });
  const history = useHistory();

  const headers = {
    Authorization: "Bearer " + props.user.accessToken,
    "content-type": "multipart/form-data",
  };

  const [imageRef, setImageRef] = useState(null);

  function getCroppedImage(sourceImage, cropConfig) {
    // creating the cropped image from the source image
    const canvas = document.createElement("canvas");
    const scaleX = sourceImage.naturalWidth / sourceImage.width;
    const scaleY = sourceImage.naturalHeight / sourceImage.height;
    canvas.width = cropConfig.width;
    canvas.height = cropConfig.height;
    const ctx = canvas.getContext("2d");

    ctx.drawImage(
      sourceImage,
      cropConfig.x * scaleX,
      cropConfig.y * scaleY,
      cropConfig.width * scaleX,
      cropConfig.height * scaleY,
      0,
      0,
      cropConfig.width,
      cropConfig.height
    );

    return new Promise((resolve, reject) => {
      canvas.toBlob((blob) => {
        // returning an error
        if (!blob) {
          reject(new Error(feedbackTranslation.emptyCanvasError));
          return;
        }

        //  Split the file name into parts using the period (.) as the delimiter
        const parts = fileObject.name.split(".");

        // The last part of the split result is the file extension
        const file_extension = parts.pop();

        const fileName = `${props.user?.mail}.${file_extension}`;
        const file = new File([blob], fileName, {
          type: fileObject.type,
        });
        setCroppedFileObject(file);
        blob.name = fileName;
        const croppedImageUrl = window.URL.createObjectURL(blob);
        resolve(croppedImageUrl);
      }, fileObject.type);
    });
  }

  async function cropImage(crop) {
    if (imageRef && crop.width && crop.height) {
      const croppedImage = await getCroppedImage(imageRef, crop);
      setCroppedImage(croppedImage);
    }
  }

  const getProfileData = async () => {
    try {
      setIsLoadingData(true);
      const { slug, mail, accessToken } = props.user;
      const { data } = await axios.get(
        `${url}/api/profile/get-profile/${mail}${slug}`,
        {
          headers: {
            Authorization: "Bearer " + accessToken,
          },
        }
      );
      if (data?.data) {
        let profileData = {
          ...data.data,
          skills: data.data.ProfileSkills,
          interests: data.data.ProfileInterests,
        };
        setUserProfileData(profileData);
      } else {
        setUserProfileData({});
      }
    } catch (error) {
      NotificationAlert(
        typeof error?.response?.data?.message === "string"
          ? error.response.data.message
          : feedbackTranslation.saveError,
        "error"
      );
    } finally {
      setIsLoadingData(false);
    }
  };

  useEffect(() => {
    getProfileData();
  }, [isRefreshData, props.selectedStudentForProfile]);

  useEffect(() => {
    setFileData(props.user.UrlPhoto);
    setCroppedImage(
      props.user.UrlPhoto
        ? props.user.UrlPhoto + `&date=${new Date().getSeconds()}`
        : null
    );
  }, [props.user, props.selectedStudentForProfile]);

  return (
    <>
      <div className="btsp account-container">
        <div className="container">
          <div>
            <Flex
              className="profile_block_1 p-4"
              style={{ justifyContent: "space-between" }}
            >
              <Flex>
                <div className="avatar_div">
                  <div className="avatar_profile">
                    {!croppedImage ? (
                      <img
                        src={userSvg}
                        alt={feedbackTranslation.userProfile}
                        className="w-100 h-100 shade"
                      />
                    ) : (
                      croppedImage && (
                        <Avatar
                          image={croppedImage ? croppedImage : ""}
                          size="largest"
                          className="shade"
                          name={props.user?.name.toUpperCase()}
                        />
                      )
                    )}
                  </div>
                </div>
                <div className="profile_info">
                  {/* <h2 className="profile_name text-center"> */}
                  <h2 className="profile_name">
                    <b>{props?.user?.displayName ?? props?.user?.name}</b>
                  </h2>
                  <span>{props.user.role}</span>
                  <p>{props.user.mail}</p>

                  {/* social media buttons */}
                  <SocialMediaButtonConatiner wrap>
                    {!!userProfileData?.instagramProfile?.length && (
                      <SocialMediaButton
                        circular
                        icon={<InstagramIcon style={{ color: "#8134AF" }} />}
                        title={feedbackTranslation.socialProfile.instagram}
                        onClick={() => {
                          window.open(
                            userProfileData.instagramProfile,
                            "_blank"
                          );
                        }}
                      />
                    )}
                    {!!userProfileData?.facebookProfile?.length && (
                      <SocialMediaButton
                        circular
                        icon={<FacebookIcon style={{ color: "#4267B2" }} />}
                        title={feedbackTranslation.socialProfile.facebook}
                        onClick={() => {
                          window.open(
                            userProfileData.facebookProfile,
                            "_blank"
                          );
                        }}
                      />
                    )}
                    {!!userProfileData?.linkedinProfile?.length && (
                      <SocialMediaButton
                        circular
                        icon={<LinkedInIcon style={{ color: "#0077b5" }} />}
                        title={feedbackTranslation.socialProfile.linkedIn}
                        onClick={() => {
                          window.open(
                            userProfileData.linkedinProfile,
                            "_blank"
                          );
                        }}
                      />
                    )}
                    {!!userProfileData?.twitterProfile?.length && (
                      <SocialMediaButton
                        circular
                        icon={<TwitterIcon style={{ color: "#1DA1F2" }} />}
                        title={feedbackTranslation.socialProfile.twitter}
                        onClick={() => {
                          window.open(userProfileData.twitterProfile, "_blank");
                        }}
                      />
                    )}
                  </SocialMediaButtonConatiner>
                </div>
              </Flex>
              <div>
                <Dropdown
                  disabled={false}
                  fluid
                  items={props.students}
                  placeholder={"Select Student"}
                  value={props.selectedStudentForProfile}
                  onChange={(e, { value }) => {
                    props.setSelectedStudentForProfile(value);
                  }}
                  noResultsMessage={"No results"}
                  className="select-student-dropdown"
                  style={{ width: "200px" }}
                />
              </div>
            </Flex>

            {/* cropped User Profile modal */}

            <div>
              <hr />
            </div>
            {isLoadingData ? (
              <div>
                <Loader size="medium" style={{ paddingTop: "25px" }} />
              </div>
            ) : (
              <div className="pt-1 pb-4">
                <Profile
                  {...{
                    data: userProfileData,
                    isEditProfile,
                    setIsEditProfile,
                    user: props.user,
                  }}
                />
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
};
export default withTranslation()(StudentProfile);
