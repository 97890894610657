import React, { useEffect, useState } from "react";
import { IconButton } from "@fluentui/react";
import {
  Dropdown,
  Flex,
  Input,
  SearchIcon,
  Text,
  CardHeader,
  CardBody,
  Card,
  Segment,
  Image,
  Divider,
  Loader,
  Tooltip,
} from "@fluentui/react-northstar";
import { useCallback } from "react";
import axios from "axios";
import { useHistory, useLocation, useParams } from "react-router-dom";
import ReactPaginate from "react-paginate";
import { withTranslation } from "react-i18next";

import { ResponsiveImage } from "../../../../components/blocks/Images";
import NoClassRooms from "../../../../Assets/images/svg/school.svg";
import userSvg from "../../../../Assets/images/svg/user.svg";

import "./Classrooms.scss";
import { Col, Row } from "antd";

const inputItems = [25, 50, 75, 100];

const url = process.env.REACT_APP_EP_URL;

const SelectedClassroom = (props) => {
  const { user, t } = props;

  const { classroomId } = useParams();
  const { state } = useLocation();
  const history = useHistory();

  const [studentsList, setStudentsList] = useState([]);
  const [searchText, setSearchText] = useState("");
  const [classRoomData, setClassRoomData] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [pagination, setPagination] = useState({
    totalPages: 1,
    currentPage: 1,
    limit: 25,
  });

  const translation = t("feedbackModule");

  const feedbackTranslation = translation.body.classroom;

  const getClassroom = async (page, limit, search = "") => {
    setIsLoading(true);
    try {
      const { data } = await axios({
        url: `${url}/api/classroom/${classroomId}${user.slug}&page=${page}&limit=${limit}&search=${search}`,
        headers: {
          Authorization: `Bearer ${user.accessToken}`,
        },
      });
      if (data?.result) {
        const { ClassID, ClassName, StudentData, ImageURL } = data.result.data;

        const classroomData = {
          classroomId: ClassID,
          classroomTitle: ClassName,
          students: StudentData?.length
            ? StudentData.map(
                ({ firstName, lastName, email, photo, ...rest }) => ({
                  ...rest,
                  studentEmailId: email,
                  name: `${firstName}${lastName ?? ""}`,
                  studentImageUrl: photo
                    ? photo +
                      process.env.REACT_APP_AZURE_SAS_TOKEN.replace(/"/g, "")
                    : null,
                })
              )
            : [],
          classroomsImageUrl: ImageURL,
        };
        setPagination(data.result.pagination);
        setStudentsList([...classroomData.students]);
        setClassRoomData(classroomData);
      } else {
        setClassRoomData(null);
      }
    } catch (error) {
      console.log(error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (classroomId) {
      if (
        state?.classroom &&
        Object.entries(state.classroom).length &&
        state?.classroom?.students?.length
      ) {
        setClassRoomData({ ...state.classroom });
      } else {
        getClassroom(1, pagination.limit, searchText);
      }
    }
  }, [classroomId]);

  const debounce = (func) => {
    let timer;
    return function (...args) {
      const context = this;
      if (timer) clearTimeout(timer);
      timer = setTimeout(() => {
        timer = null;
        func.apply(context, args);
      }, 1000);
    };
  };

  const handleChange = (value) => {
    getClassroom(1, pagination.limit, value);
  };

  const handleCountChange = (value) => {
    setPagination({ ...pagination, limit: value, currentPage: 1 });
    getClassroom(1, value, searchText);
  };

  const optimizedFn = useCallback(debounce(handleChange), []);

  const handlePaginationChange = (value) => {
    setPagination({ ...pagination, currentPage: value.selected + 1 });
    getClassroom(value.selected + 1, pagination.limit, searchText);
  };

  return (
    <div style={{ marginTop: "30px" }}>
      {isLoading ? (
        <Loader style={{ paddingTop: "50px" }} />
      ) : classRoomData ? (
        <div className="classrooms-container">
          <Flex style={{ padding: "0 0 0 25px" }}>
            <IconButton
              iconProps={{ iconName: "ChromeBack" }}
              title={translation.common.back}
              ariaLabel={translation.common.back}
              onClick={() => {
                history.push({
                  pathname: "/feedback/classrooms",
                  state: { from: "ClassRoom" }, // pass the state here
                });
              }}
            />
            <Text
              color="brand"
              size="larger"
              weight="semibold"
              style={{ marginRight: "10px" }}
            >
              {classRoomData.classroomTitle}
            </Text>
          </Flex>
          <div style={{ marginTop: "10px" }}>
            <Flex space="around" vAlign="center" hAlign="center" wrap>
              <Input
                icon={<SearchIcon />}
                clearable
                placeholder={feedbackTranslation.search}
                onChange={(e, { value }) => {
                  optimizedFn(value);
                  setSearchText(value);
                }}
                className="search-input"
                value={searchText}
              />
              <ReactPaginate
                previousLabel={feedbackTranslation.previous}
                nextLabel={feedbackTranslation.next}
                breakLabel={"..."}
                breakClassName={"break-me"}
                pageCount={pagination.totalPages}
                marginPagesDisplayed={2}
                pageRangeDisplayed={5}
                onPageChange={handlePaginationChange}
                containerClassName={"pagination"}
                activeClassName={"active"}
                forcePage={pagination.currentPage ? pagination.currentPage - 1 : 0}
              />
              <Dropdown
                items={inputItems}
                checkable
                value={pagination.limit}
                getA11ySelectionMessage={{
                  onAdd: (item) => {
                    handleCountChange(item);
                  },
                }}
                className="pagination-dropdown"
                placeholder={feedbackTranslation.pageLimit}
              />
            </Flex>
          </div>
          <Divider />
          <div
            style={{ marginTop: "10px" }}
            className="classroom-students-cards-wrapper"
          >
            {studentsList?.length ? (
              <Row gutter={[10, 10]} style={{ margin: 0 }}>
                {studentsList.map((student, index) => (
                  <Col xxl={4} xl={6} sm={12} xs={24}>
                    <Segment
                      style={{ width: "100%", margin: "0px !important" }}
                      key={student.studentEmailId + index}
                      content={
                        <Flex
                          style={{ flexDirection: "column", maxWidth: "100% " }}
                        >
                          <Image
                            src={
                              student.studentImageUrl
                                ? student.studentImageUrl
                                : userSvg
                            }
                            width="100%"
                            height="200px"
                            className="img"
                          />
                          <Tooltip
                            trigger={
                              <Text
                                content={
                                  student.name ??
                                  student.studentEmailId?.split("@")?.[0]
                                }
                                className="Karla fw6 m1 tcap"
                                style={{
                                  maxWidth: "100%",
                                  overflow: "hidden",
                                  textOverflow: "ellipsis",
                                  whiteSpace: "nowrap",
                                }}
                              />
                            }
                            content={
                              student.name ??
                              student.studentEmailId?.split("@")?.[0]
                            }
                          />
                        </Flex>
                      }
                      className="seg"
                    />
                  </Col>
                ))}
              </Row>
            ) : (
              <Flex fill column hAlign="center" vAlign="center">
                <Text
                  style={{ marginTop: "50px" }}
                  size="larger"
                  align="center"
                >
                  {studentsList.length
                    ? feedbackTranslation.noStudentForFilter
                    : feedbackTranslation.noStudent}
                </Text>
              </Flex>
            )}
          </div>
        </div>
      ) : (
        <Flex hAlign="center" column fill style={{ marginTop: "40px" }}>
          <ResponsiveImage src={NoClassRooms} style={{ width: "450px" }} />
          <Text weight="semibold" size="large">
            {feedbackTranslation.noClassroom}
          </Text>
        </Flex>
      )}
    </div>
  );
};

export default withTranslation()(SelectedClassroom);
