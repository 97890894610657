import React, { useEffect, useState } from "react";
import {
  Button,
  CloseIcon,
  Dialog,
  Dropdown,
  Flex,
  Input,
  Loader,
} from "@fluentui/react-northstar";
import { useTranslation } from "react-i18next";
import axios from "axios";
import { toast } from "react-toastify";

const AddTutorsModel = (props) => {
  const {
    selectedTeachers,
    setSelectedTeachers,
    selectedStudents,
    setSelectedStudents,
  } = props;

  const [showError, setShowError] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const { t } = useTranslation();

  const addTutorsDialogTranslation = t("booking").addTutorsDialog;
  const commonTranslation = t("booking").common;

  const handleAddTutors = async () => {
    if (selectedStudents.length || selectedTeachers.length) {
      setIsLoading(true);
      const payload = {
        studentsForTutor: selectedStudents.map((student) => student.key),
        teachersForTutor: selectedTeachers.map((teacher) => teacher.key),
      };
      try {
        let config = {
          method: "post",
          url: `${process.env.REACT_APP_EP_URL}/api/tutor${props.user.slug}`,
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + props.user.accessToken,
          },
          data: payload,
        };

        const response = await axios.request(config);
        setIsLoading(false);
        if (response?.data?.success) {
          const { tutors = [] } = response.data.result;
          props.addNewTutors(tutors);
          setSelectedStudents([]);
          setSelectedTeachers([]);
          toast.success("Tutors added Successfully!");
        }
        props.onClose();
      } catch (error) {
        toast.error("Tutors adding Failed!");
        setIsLoading(false);
        console.log(error);
      }
    } else {
      setShowError(true);
    }
  };

  const isSelectedTeachersHasBookedSlot = selectedTeachers.some(
    (teacher) => teacher.isBookedSlots
  );
  const isSelectedStudentsHasBookedSlot = selectedStudents.some(
    (student) => student.isBookedSlots
  );

  return (
    <Dialog
      open={props.isOpen}
      content={
        <div className="add-tutor-model-content">
          <div>
            <p className="sub-title">
              {addTutorsDialogTranslation.students.header}
            </p>

            <Dropdown
              disabled={false}
              search
              multiple
              fluid
              items={props.students}
              placeholder={addTutorsDialogTranslation.students.placeholder}
              value={selectedStudents}
              defaultValue={selectedStudents}
              getA11ySelectionMessage={{
                onAdd: (item) => {
                  let clonedSelectedStudents = [...selectedStudents];
                  clonedSelectedStudents.push(item);
                  setSelectedStudents(clonedSelectedStudents);
                  let clonedStudentsWithoutParents = props.students.filter(
                    (s) => s?.key && s.key !== item.key
                  );
                  props.setStudents(clonedStudentsWithoutParents);
                },
                onRemove: (item) => {
                  const updatedlist = selectedStudents.filter(
                    (s) => s?.key && s.key !== item.key
                  );
                  setSelectedStudents(updatedlist);
                  let clonedStudentsWithoutParents = [item, ...props.students];
                  props.setStudents(clonedStudentsWithoutParents);
                },
              }}
              noResultsMessage={addTutorsDialogTranslation.students.noResults}
              className="select-students-dropdown"
            />

            <p style={{ color: "orange", fontSize: "13px", marginTop: "5px" }}>
              {addTutorsDialogTranslation.students.message}
            </p>
            {isSelectedStudentsHasBookedSlot && (
              <p style={{ color: "red", fontSize: "13px", marginTop: "5px" }}>
                {addTutorsDialogTranslation.students.warning}
              </p>
            )}
          </div>
          <div>
            <p className="sub-title">
              {addTutorsDialogTranslation.teachers.header}
            </p>

            <Dropdown
              disabled={false}
              search
              multiple
              fluid
              items={props.teachers}
              placeholder={addTutorsDialogTranslation.teachers.placeholder}
              value={selectedTeachers}
              defaultValue={selectedTeachers}
              getA11ySelectionMessage={{
                onAdd: (item) => {
                  let clonedSelectedTeachers = [...selectedTeachers];
                  clonedSelectedTeachers.push(item);
                  setSelectedTeachers(clonedSelectedTeachers);
                  let studentsWhoAreNotTutors = props.students.filter(
                    (s) => s?.key && s.key !== item.key
                  );
                  props.setStudents(studentsWhoAreNotTutors);
                },
                onRemove: (item) => {
                  const updatedlist = selectedTeachers.filter(
                    (s) => s?.key && s.key !== item.key
                  );
                  setSelectedTeachers(updatedlist);
                  let studentsWhoAreNotTutors = [item, ...props.students];
                  props.setStudents(studentsWhoAreNotTutors);
                },
              }}
              noResultsMessage={addTutorsDialogTranslation.teachers.noResults}
              className="select-teachers-dropdown"
            />

            <p style={{ color: "orange", fontSize: "13px", marginTop: "5px" }}>
              {addTutorsDialogTranslation.teachers.message}
            </p>
            {isSelectedTeachersHasBookedSlot && (
              <p style={{ color: "red", fontSize: "13px", marginTop: "5px" }}>
                {addTutorsDialogTranslation.teachers.warning}
              </p>
            )}
            {showError ? (
              selectedTeachers.length === 0 ? (
                <p style={{ color: "red", fontSize: "13px" }}>
                  {addTutorsDialogTranslation.validation}
                </p>
              ) : (
                ""
              )
            ) : (
              ""
            )}
          </div>
          <Flex hAlign="end" style={{ marginTop: "20px" }} gap="gap.small">
            <Button onClick={props.onClose} disabled={isLoading}>
              {commonTranslation.cancel}
            </Button>
            <Button primary onClick={handleAddTutors} disabled={isLoading}>
              {isLoading ? (
                <Flex vAlign="center" gap="gap.small">
                  <Loader size="smallest" />{" "}
                  <span>{`${addTutorsDialogTranslation.adding}...`}</span>
                </Flex>
              ) : (
                commonTranslation.add
              )}
            </Button>
          </Flex>
        </div>
      }
      header={addTutorsDialogTranslation.header}
      className="smallDlg"
      headerAction={{
        icon: <CloseIcon />,
        title: commonTranslation.close,
        onClick: props.onClose,
        disabled: isLoading,
      }}
    />
  );
};

export default AddTutorsModel;
