import { useState } from "react";
import { CloseIcon, Dialog } from "@fluentui/react-northstar";

const EventModal = ({
  button,
  children,
  cancelButton = null,
  confirmButton,
  onCancel = () => {},
  onConfirm,
  onClose = () => {},
  closeOnOutsideClick,
  header,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  return (
    <>
      {button({ isOpen, setIsOpen })}
      <Dialog
        cancelButton={cancelButton}
        confirmButton={confirmButton}
        open={isOpen}
        onCancel={onCancel}
        onConfirm={() => {
          onConfirm({ isOpen, setIsOpen });
        }}
        closeOnOutsideClick={closeOnOutsideClick}
        content={children({ isOpen, setIsOpen })}
        header={header}
        headerAction={{
          icon: <CloseIcon />,
          title: "Close",
          onClick: () => {
            setIsOpen(false);
            onClose();
          },
        }}
      />
    </>
  );
};

export default EventModal;
