import React, { useState } from "react";
import axios from "axios";
import {
  Button,
  Checkbox,
  CloseIcon,
  Dialog,
  Text,
  Tooltip,
} from "@fluentui/react-northstar";
import { Edit } from "react-feather";
import { toast } from "react-toastify";
import { withTranslation } from "react-i18next";

const ACCESSTYPES = {
  admin: "admin",
  teacher: "teacher",
  student: "student",
};

const END_POINT = process.env.REACT_APP_EP_URL;

const EditRBAC = ({
  loading,
  data,
  user,
  setRefreshData,
  t,
  setEventPermissions,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [reportOptionData, setReportOptionData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const translation = t("setting").RBAC;
  const commonTranslation = translation.common;
  const dashboardTranslation = translation.dashboard;

  const editRBACDataHandler = () => {
    setIsOpen(!isOpen);
  };

  const changeAccessHandler = ({ type, value, report }) => {
    setReportOptionData((prevData) =>
      prevData.map((item) =>
        item.id === report.id ? { ...item, [type]: value } : item
      )
    );
  };

  const saveRBACDataHandler = async () => {
    try {
      setIsLoading(true);
      const slug = user.slug;
      const token = user.accessToken;
      const response = await axios({
        method: "POST",
        url: `${END_POINT}/api/rbac/update-rbac-data${slug}`,
        data: reportOptionData,
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      if (response.data?.status === "success") {
        setRefreshData((prev) => !prev);
        toast.success(response.data.message);
      }
    } catch (error) {
      console.log(error);
    } finally {
      setIsLoading(false);
    }
  };

  const editButtonHandler = () => {
    setIsOpen(true);
    setReportOptionData(data.reportOptions);
  };

  return (
    <>
      <Button
        icon={<Edit />}
        iconOnly
        title={`${
          data.title?.length
            ? data.title
                .split(" ")
                .map((word) =>
                  word?.length ? word[0].toUpperCase() + word.substr(1) : ""
                )
                .join(" ")
            : ""
        } ${dashboardTranslation.reports}`}
        onClick={editButtonHandler}
        style={{ color: "#585A96", borderRadius: 5 }}
      />
      <Dialog
        open={isOpen}
        onCancel={() => setIsOpen(false)}
        onConfirm={saveRBACDataHandler}
        confirmButton={{
          primary: !isLoading,
          disabled: isLoading,
          loading: isLoading,
          content: isLoading
            ? commonTranslation.saving
            : commonTranslation.save,
        }}
        cancelButton={commonTranslation.cancel}
        header={`${commonTranslation.edit} ${
          data.title?.length
            ? data.title
                .split(" ")
                .map((word) =>
                  word?.length ? word[0].toUpperCase() + word.substr(1) : ""
                )
                .join(" ")
            : ""
        } ${dashboardTranslation.reportsRBAC}`}
        content={
          <div className="edit-rbac-data-wrapper">
            <div className="report-options-wrapper">
              <div className="ms-Grid " dir="ltr">
                <div className="ms-Grid-row report-option-header">
                  <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg6 ms-xl8">
                    {dashboardTranslation.reportDescription}
                  </div>
                  <div className="ms-Grid-col ms-sm12 ms-md8 ms-lg6 ms-xl4">
                    <div className="ms-Grid-row role-action-wrapper">
                      <div className="ms-Grid-col ms-sm4 role-title">
                        <Tooltip
                          content={commonTranslation.admin}
                          trigger={<Text>{commonTranslation.admin}</Text>}
                        />
                      </div>
                      <div className="ms-Grid-col ms-sm4 role-title">
                        <Tooltip
                          content={commonTranslation.teacher}
                          trigger={<Text>{commonTranslation.teacher}</Text>}
                        />
                      </div>
                      <div className="ms-Grid-col ms-sm4 role-title">
                        <Tooltip
                          content={commonTranslation.student}
                          trigger={<Text>{commonTranslation.student}</Text>}
                        />
                      </div>
                    </div>
                  </div>
                  {/* <div className="ms-Grid-col ms-sm12 ms-md4 ms-lg2 ms-xl1">
                    Action
                  </div> */}
                </div>

                {reportOptionData.map((report) => (
                  <div
                    className="ms-Grid-row report-option"
                    key={report.option}
                  >
                    <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg6 ms-xl8 title-button-description-text-wrapper">
                      <div>
                        <Button primary className="title-button">
                          {report.title}
                        </Button>
                      </div>
                      <div className="description-text">
                        <Text>{report.description}</Text>
                      </div>
                    </div>
                    <div className="ms-Grid-col ms-sm12 ms-md8 ms-lg6 ms-xl4">
                      <div className="ms-Grid-row role-action-wrapper">
                        <div className="ms-Grid-col ms-sm4">
                          <Checkbox
                            toggle
                            checked={report.admin}
                            onChange={(_, { checked }) =>
                              changeAccessHandler({
                                type: ACCESSTYPES.admin,
                                value: checked,
                                report,
                              })
                            }
                            disabled={false}
                            title={commonTranslation.admin}
                          />
                        </div>
                        <div className="ms-Grid-col ms-sm4">
                          <Checkbox
                            toggle
                            checked={report.teacher}
                            onChange={(_, { checked }) =>
                              changeAccessHandler({
                                type: ACCESSTYPES.teacher,
                                value: checked,
                                report,
                              })
                            }
                            disabled={false}
                            title={commonTranslation.teacher}
                          />
                        </div>
                        <div className="ms-Grid-col ms-sm4">
                          <Checkbox
                            toggle
                            checked={report.student}
                            onChange={(_, { checked }) =>
                              changeAccessHandler({
                                type: ACCESSTYPES.student,
                                value: checked,
                                report,
                              })
                            }
                            disabled={false}
                            title={commonTranslation.student}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        }
        headerAction={{
          icon: <CloseIcon />,
          title: commonTranslation.close,
          onClick: () => {
            setIsOpen(false);
          },
        }}
        className="edit-rbac-dialog"
      />
    </>
  );
};

export default withTranslation()(EditRBAC);
