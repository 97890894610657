import {
  AcceptIcon,
  Divider,
  Flex,
  Image,
  Loader,
  PollIcon,
  ShiftActivityIcon,
  SurveyIcon,
  Text,
  Dialog,
  FlagIcon,
  Tooltip,
  Button,
} from "@fluentui/react-northstar";

import {
  CloseIcon,
  RetryIcon,
  CallVideoIcon,
  VolumeIcon,
  ItalicIcon,
  BookmarkIcon,
  PresenterIcon,
  ChevronEndIcon,
  ChevronStartIcon,
} from "@fluentui/react-icons-northstar";

import { Grid } from "@mui/material";
import React from "react";
import ProgressChart from "../../../../components/studentProgress/ProgressChart";

const chartTypes = {
  attendance: "attendance",
  assignment: "assignment",
  event: "event",
  feedback: "feedback",
};

function StudentCard({
  student,
  t,
  loadedInfo,
  userSvg,
  studentKey,
  props,
  getInfo,
  customFieldInfo,
  isMobileView,
  isTabletView,
  user,
}) {
  const [selectedChart, setSelectedChart] = React.useState(
    chartTypes.attendance
  );

  const chartSelectHandler = (type) => {
    setSelectedChart(type);
  };

  const scrollButtonHandler = (isScrollToRight) => {
    document.getElementById("scroll-statictics-container").scrollBy({
      left: isScrollToRight ? 150 : -150,
      top: 0,
      behavior: "smooth",
    });
  };

  return (
    <Dialog
      key={student.StudentEmailID + studentKey}
      confirmButton={t("setting").init[3]}
      content={
        <Flex wrap hAlign="center" column>
          {/* <Flex wrap> */}
          <Flex
            column
            hAlign="center"
            gap="gap.medium"
            className="student-image-info-wrapper"
          >
            <Image
              src={
                student.studPhoto.Foto
                  ? user.slug === "?slug=acvpreprod" ||
                    user.slug === "?slug=acvapp"
                    ? student.studPhoto.Foto
                    : student.studPhoto.Foto + props.user.SASToken
                  : userSvg
              }
              style={{ height: "100px", width: "100px" }}
            />
            {loadedInfo ? (
              <Flex column className="student-info-wrapper" gap="gap.smaller">
                <Flex gap="gap.small" wrap>
                  <Text
                    content={`${t("setting").student.info[0]}:`}
                    className="Karla fw6 tcap"
                  />

                  <Tooltip
                    content={student.StudentName}
                    trigger={
                      <Text
                        content={student.StudentName}
                        className="Karla tcap field-value"
                      />
                    }
                  />
                </Flex>
                <Flex gap="gap.small" wrap>
                  <Text
                    content={`${t("setting").student.info[1]}:`}
                    className="Karla fw6 tcap"
                  />
                  <Tooltip
                    content={student.StudentEmailID}
                    trigger={
                      <Text
                        content={student.StudentEmailID}
                        className="Karla tlow field-value"
                      />
                    }
                  />
                </Flex>
                <Flex gap="gap.small" wrap>
                  <Text
                    content={`${t("setting").student.info[2]}:`}
                    className="Karla fw6 tcap"
                  />
                  <Text
                    content={loadedInfo?.info?.classNumber?.join(', ') ?? ""}
                    className="Karla tcap"
                  />
                </Flex>
                <Flex gap="gap.small" wrap>
                  <Text
                    content={`${t("setting").student.info[3]}:`}
                    className="Karla fw6 tcap"
                  />
                  <Text
                    content={loadedInfo?.info?.attendanceAverage ?? ""}
                    className="Karla tcap"
                  />
                </Flex>
                <Flex gap="gap.small" wrap>
                  <Text
                    content={`${t("setting").student.info[4]}:`}
                    className="Karla fw6 tcap"
                  />
                  <Text
                    content={loadedInfo?.info?.assignmentAverage ?? ""}
                    className="Karla tcap"
                  />
                </Flex>
                <Flex gap="gap.small" wrap>
                  <Text
                    content={`${t("setting").student.info[5]}:`}
                    className="Karla fw6 tcap"
                  />
                  <div className="Karla tcap">
                    {Array.isArray(loadedInfo?.info?.subjects) ? (
                      <Flex column className="subjects-wrapper">
                        {loadedInfo.info.subjects.map((item, index, arr) => (
                          <span key={item}>{item}</span>
                        ))}
                      </Flex>
                    ) : (
                      <Text
                        content={`${t("setting").student.info[6]}`}
                        className="Karla tcap"
                      />
                    )}
                  </div>
                </Flex>
              </Flex>
            ) : (
              ""
            )}
            {loadedInfo?.StudentData ? (
              <Flex column>
                <Divider content={t("setting").student.attendance[1]} />{" "}
                {customFieldInfo?.map((cf) => {
                  return (
                    <Text
                      content={
                        <>
                          <FlagIcon outline className="pb2 pr2" />
                          <Text
                            content={" " + cf.Name}
                            color="brand"
                            className="fw6"
                            disabled={!cf.IsEnable}
                          />{" "}
                          - {loadedInfo.StudentData["CF_" + cf.CustomFieldID]}
                        </>
                      }
                      className="Karla m1 tcap"
                      key={cf.CustomFieldID}
                      disabled={!cf.IsEnable}
                    />
                  );
                })}{" "}
              </Flex>
            ) : null}
          </Flex>

          <Flex column className="info-chart-wrapper">
            {loadedInfo ? (
              <>
                <Flex vAlign="center">
                  <div className="scroll-button-wrapper">
                    <Button
                      className="scroll-button"
                      onClick={() => {
                        scrollButtonHandler(false);
                      }}
                    >
                      <ChevronStartIcon size="large" />
                    </Button>
                  </div>
                  <div
                    className="statictics-container"
                    id="scroll-statictics-container"
                  >
                    <Flex wrap className="statictics-wrapper">
                      <Flex column>
                        <br />
                        <Divider
                          content={
                            t("setting").student.attendance[0] || "Attendance"
                          }
                          onClick={() =>
                            chartSelectHandler(chartTypes.attendance)
                          }
                          className={`selectable-option ${
                            selectedChart === chartTypes.attendance
                              ? "active-chart"
                              : ""
                          }`}
                        />
                        <Text
                          content={
                            <>
                              <PollIcon outline className="pb2 pr2" />
                              <Text
                                content={
                                  t("setting").student.attendance[2] || "Total"
                                }
                                color="brand"
                                className="fw6"
                              />{" "}
                              - {loadedInfo.Total}
                            </>
                          }
                          className="Karla m1 tcap"
                        />
                        <Text
                          content={
                            <>
                              <AcceptIcon outline className="pb2 pr2" />
                              <Text
                                content={
                                  t("setting").student.attendance[3] ||
                                  "Presente"
                                }
                                color="brand"
                                className="fw6"
                              />{" "}
                              - {parseInt(loadedInfo.Presente)}
                            </>
                          }
                          className="Karla m1 tcap"
                        />
                        <Text
                          content={
                            <>
                              <CloseIcon outline className="pb2 pr2" />
                              <Text
                                content={
                                  t("setting").student.attendance[4] ||
                                  "Ausente"
                                }
                                color="brand"
                                className="fw6"
                              />{" "}
                              - {parseInt(loadedInfo.Ausente)}
                            </>
                          }
                          className="Karla m1 tcap"
                        />
                        <Text
                          content={
                            <>
                              <ShiftActivityIcon outline className="pb2 pr2" />
                              <Text
                                content={
                                  t("setting").student.attendance[5] ||
                                  "Retardo"
                                }
                                color="brand"
                                className="fw6"
                              />{" "}
                              - {parseInt(loadedInfo.Retardo)}
                            </>
                          }
                          className="Karla m1 tcap"
                        />
                        <Text
                          content={
                            <>
                              <SurveyIcon outline className="pb2 pr2" />
                              <Text
                                content={
                                  t("setting").student.attendance[6] ||
                                  "Justificado"
                                }
                                color="brand"
                                className="fw6"
                              />{" "}
                              - {parseInt(loadedInfo.Justificado)}
                            </>
                          }
                          className="Karla m1 tcap"
                        />
                      </Flex>

                      <Flex column>
                        <br />
                        <Divider
                          content={t("setting").student.assignment[0]}
                          onClick={() =>
                            chartSelectHandler(chartTypes.assignment)
                          }
                          className={`selectable-option ${
                            selectedChart === chartTypes.assignment
                              ? "active-chart"
                              : ""
                          }`}
                        />
                        <Text
                          content={
                            <>
                              <PollIcon outline className="pb2 pr2" />
                              <Text
                                content={t("setting").student.assignment[1]}
                                color="brand"
                                className="fw6"
                              />{" "}
                              - {loadedInfo.assignment.total}
                            </>
                          }
                          className="Karla m1 tcap"
                        />
                        <Text
                          content={
                            <>
                              <AcceptIcon outline className="pb2 pr2" />
                              <Text
                                content={t("setting").student.assignment[2]}
                                color="brand"
                                className="fw6"
                              />{" "}
                              - {parseInt(loadedInfo.assignment.completed)}
                            </>
                          }
                          className="Karla m1 tcap"
                        />
                        <Text
                          content={
                            <>
                              <RetryIcon outline className="pb2 pr2" />
                              <Text
                                content={t("setting").student.assignment[3]}
                                color="brand"
                                className="fw6"
                              />{" "}
                              - {parseInt(loadedInfo.assignment.reSubmit)}
                            </>
                          }
                          className="Karla m1 tcap"
                        />
                        <Text
                          content={
                            <>
                              <ShiftActivityIcon outline className="pb2 pr2" />
                              <Text
                                content={t("setting").student.assignment[4]}
                                color="brand"
                                className="fw6"
                              />{" "}
                              - {parseInt(loadedInfo.assignment.pending)}
                            </>
                          }
                          className="Karla m1 tcap"
                        />
                        <Text
                          content={
                            <>
                              <SurveyIcon outline className="pb2 pr2" />
                              <Text
                                content={t("setting").student.assignment[5]}
                                color="brand"
                                className="fw6"
                              />{" "}
                              - {parseInt(loadedInfo.assignment.gradesPending)}
                            </>
                          }
                          className="Karla m1 tcap"
                        />
                      </Flex>
                      <Flex column>
                        <br />
                        <Divider
                          content={t("setting").student.event[0]}
                          onClick={() => chartSelectHandler(chartTypes.event)}
                          className={`selectable-option ${
                            selectedChart === chartTypes.event
                              ? "active-chart"
                              : ""
                          }`}
                        />
                        <Text
                          content={
                            <>
                              <AcceptIcon outline className="pb2 pr2" />
                              <Text
                                content={t("setting").student.event[2]}
                                color="brand"
                                className="fw6"
                              />{" "}
                              - {parseInt(loadedInfo.event.registered)}
                            </>
                          }
                          className="Karla m1 tcap"
                        />
                        <Text
                          content={
                            <>
                              <BookmarkIcon outline className="pb2 pr2" />
                              <Text
                                content={t("setting").student.event[3]}
                                color="brand"
                                className="fw6"
                              />{" "}
                              - {parseInt(loadedInfo.event.bookmarked)}
                            </>
                          }
                          className="Karla m1 tcap"
                        />
                        <Text
                          content={
                            <>
                              <PresenterIcon outline className="pb2 pr2" />
                              <Text
                                content={t("setting").student.event[4]}
                                color="brand"
                                className="fw6"
                              />{" "}
                              - {parseInt(loadedInfo.event.organized)}
                            </>
                          }
                          className="Karla m1 tcap"
                        />
                      </Flex>

                      <Flex column>
                        <br />
                        <Divider
                          content={t("setting").student.feedback[0]}
                          onClick={() =>
                            chartSelectHandler(chartTypes.feedback)
                          }
                          className={`selectable-option ${
                            selectedChart === chartTypes.feedback
                              ? "active-chart"
                              : ""
                          }`}
                        />
                        <Text
                          content={
                            <>
                              <PollIcon outline className="pb2 pr2" />
                              <Text
                                content={t("setting").student.feedback[1]}
                                color="brand"
                                className="fw6"
                              />{" "}
                              - {loadedInfo.feedback.total}
                            </>
                          }
                          className="Karla m1 tcap"
                        />
                        <Text
                          content={
                            <>
                              <PollIcon outline className="pb2 pr2" />
                              <Text
                                content={t("setting").student.feedback[2]}
                                color="brand"
                                className="fw6"
                              />{" "}
                              - {parseInt(loadedInfo.feedback.poll)}
                            </>
                          }
                          className="Karla m1 tcap"
                        />
                        <Text
                          content={
                            <>
                              <ItalicIcon outline className="pb2 pr2" />
                              <Text
                                content={t("setting").student.feedback[3]}
                                color="brand"
                                className="fw6"
                              />{" "}
                              - {parseInt(loadedInfo.feedback.text)}
                            </>
                          }
                          className="Karla m1 tcap"
                        />
                        <Text
                          content={
                            <>
                              <VolumeIcon outline className="pb2 pr2" />
                              <Text
                                content={t("setting").student.feedback[4]}
                                color="brand"
                                className="fw6"
                              />{" "}
                              - {parseInt(loadedInfo.feedback.audio)}
                            </>
                          }
                          className="Karla m1 tcap"
                        />
                        <Text
                          content={
                            <>
                              <CallVideoIcon outline className="pb2 pr2" />
                              <Text
                                content={t("setting").student.feedback[5]}
                                color="brand"
                                className="fw6"
                              />{" "}
                              - {parseInt(loadedInfo.feedback.video)}
                            </>
                          }
                          className="Karla m1 tcap"
                        />
                      </Flex>
                    </Flex>
                  </div>
                  <div className="scroll-button-wrapper">
                    <Button
                      className="scroll-button"
                      onClick={() => {
                        scrollButtonHandler(true);
                      }}
                    >
                      <ChevronEndIcon size="large" />
                    </Button>
                  </div>
                </Flex>
                <ProgressChart
                  selectedChart={selectedChart}
                  data={loadedInfo.chart}
                  chartTypes={chartTypes}
                />
              </>
            ) : (
              <Loader size="small" style={{ paddingTop: "50px" }} />
            )}
          </Flex>
        </Flex>
      }
      trigger={
        <Grid
          item
          xs={isMobileView ? 5 : 3}
          key={student.StudentEmailID + "-grid-item"}
          style={{
            cursor: "pointer",
            boxShadow:
              " 0 1px 2px 0 rgb(60 64 67 / 30%), 0 1px 3px 1px rgb(60 64 67 / 15%)",
            borderRadius: "8px",
            margin: "10px",
            alignSelf: "stretch",
            paddingLeft: 0,
          }}
          className="seg"
          onClick={() => getInfo(student.StudentEmailID)}
          title={student.StudentEmailID}
        >
          <Image
            src={
              student.studPhoto.Foto
                ? student.studPhoto.Foto + props.user.SASToken
                : userSvg
            }
            style={{
              // borderRadius: "6px",
              // width: "100%",
              // height: "70px",
              // margin: "10px 0px 0px",
              borderRadius: "6px",
              width: "80%",
              height: "72%",
              margin: "3px 12px 0px",
            }}
          />
          <Text
            content={student.StudentName}
            className="Karla fw6 m1 tcap"
            style={{
              margin: "10px 0px 0",
              overflow: "hidden",
              textOverflow: "ellipsis",
              width: "100%",
              display: "flex",
              justifyContent: "center",
            }}
          />
        </Grid>
      }
    />
  );
}

export default StudentCard;
