import { toDataURL, QRCodeToDataURLOptions } from "qrcode";

export const getQRCode = (value, options) => {
  let qrValue = undefined;

  toDataURL(value, options, (err, url) => {
    if (err) {
      console.error(err);
      return;
    }
    qrValue = url;
  });

  return qrValue;
};
