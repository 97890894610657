import {
  Button,
  Dialog,
  Flex,
  Loader,
  TextArea,
} from "@fluentui/react-northstar";
import React, { useState } from "react";
import { useBooking } from "../../../../context/BookingContext";
import axios from "axios";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";

const ReviewFormModel = (props) => {
  const { t } = useTranslation();

  const [comment, setComment] = useState("");
  const [showError, setShowError] = useState(false);
  const [isSubmittingReview, setIsSubmittingReview] = useState(false);
  const { user, filteredBookings, setFilteredBookings } = useBooking();
  const { meetingData, index } = props;

  const translation = t("booking").reviewDialog;
  const commonTranslation = t("booking").common;

  const handleSubmitReview = async (comment) => {
    try {
      const payload = {
        tutorEmailId: meetingData.slot.tutorEmailId,
        userEmailId: user.mail,
        comment,
        bookingId: meetingData.id,
      };
      setIsSubmittingReview(true);
      const res = await axios.post(
        `${process.env.REACT_APP_EP_URL}/api/booking-review/${user.slug}`,
        payload,
        {
          headers: { Authorization: "Bearer " + user.accessToken },
        }
      );
      setIsSubmittingReview(false);
      props.onClose();
      if (res.data.success) {
        toast.success("Thank you for your feedback!");
        let clonedFilteredBookings = [...filteredBookings];
        clonedFilteredBookings[index].participants = clonedFilteredBookings[
          index
        ].participants.map((participant) => {
          if (participant.email === user.mail) {
            return { ...participant, isReviewSubmitted: true };
          }
          return participant;
        });
        setFilteredBookings(clonedFilteredBookings);
      }
    } catch (error) {
      setIsSubmittingReview(false);
      props.onClose();
    }
  };
  return (
    <Dialog
      open={props.open}
      header={translation.header}
      styles={{
        width: "400px !important", // Set a fixed width
        // height: "400px", // Set the height equal to width for 1:1 aspect ratio
        maxWidth: "90vw", // Ensure responsiveness
        maxHeight: "90vh",
      }}
      content={
        <Flex column gap="gap.medium">
          <Flex column gap="gap.small">
            <p className="sub-header">{translation.comment}</p>
            <TextArea
              fluid
              resize="vertical"
              value={comment}
              rows={5}
              onChange={(e) => {
                setComment(e.target.value);
              }}
              disabled={isSubmittingReview}
            />
            {showError && !comment && (
              <Text error content={translation.commentValidation} />
            )}
          </Flex>
          <Flex gap="gap.small" hAlign="end">
            <Button onClick={props.onClose}>{commonTranslation.cancel}</Button>
            <Button
              primary
              disabled={isSubmittingReview || !comment.trim()}
              onClick={() => handleSubmitReview(comment)}
            >
              {isSubmittingReview ? (
                <Flex gap="gap.smaller" vAlign="center">
                  <span>{`${translation.submitting}...`}</span>
                  <Loader size="smallest" />
                </Flex>
              ) : (
                translation.submitButton
              )}
            </Button>
          </Flex>
        </Flex>
      }
    />
  );
};

export default ReviewFormModel;
