import React from "react";
import { AddIcon, Button } from "@fluentui/react-northstar";

const AssignmentTypeFilterButton = (props) => {
  return (
    <Button
      primary={props.primary}
      style={{ borderRadius: "24px", padding: "15px 10px" }}
      content={props.content}
      size={props.isMobileView ? "small" : "medium"}
      onClick={() => {
        props.onClick();
      }}
    />
  );
};

export default React.memo(AssignmentTypeFilterButton);
