import { useEffect, useState } from "react";
import { CloseIcon, Dialog, Flex, Pill } from "@fluentui/react-northstar";

const SelectorModal = ({
  children,
  items,
  selectedItems,
  header,
  confirmButton,
  clearButton,
  onSubmit,
}) => {
  const [open, setOpen] = useState(false);
  const [changedItems, setChangedItems] = useState([]);

  useEffect(() => {
    setChangedItems(selectedItems ?? []);
  }, [items, selectedItems, open]);

  const onChangeHandler = ({ mode, value }) => {
    setChangedItems((prevData) =>
      prevData.some((item) => item.id === value.id)
        ? prevData.filter((item) => item.id !== value.id)
        : [...prevData, value]
    );
  };

  const clearButtonHandler = () => {
    setChangedItems([]);
  };

  const applyButtonHandler = () => {
    const res = onSubmit(changedItems);
    if (res === "limit reached") {
      return;
    }
    setOpen(false);
    setChangedItems([]);
  };

  return (
    <div style={{ marginBottom: "10px" }}>
      {children({ open, setOpen })}
      <Dialog
        cancelButton={clearButton}
        confirmButton={confirmButton}
        open={open}
        onCancel={clearButtonHandler}
        onConfirm={applyButtonHandler}
        closeOnOutsideClick={false}
        content={
          <div>
            <Flex wrap>
              {items.map((item) => {
                let isSelected = !!changedItems.find(
                  (data) => data.id === item.id
                );

                return (
                  <Pill
                    key={item.id}
                    selectable
                    selected={isSelected}
                    onClick={() =>
                      onChangeHandler({ mode: "update", value: item })
                    }
                    style={{
                      minWidth: "initial",
                      backgroundColor: isSelected && "#6264A7",
                      color: isSelected ? "#fff" : "#000",
                    }}
                  >
                    {item.title}
                  </Pill>
                );
              })}
            </Flex>
          </div>
        }
        header={header}
        headerAction={{
          icon: <CloseIcon />,
          title: "Close",
          onClick: () => {
            setOpen(false);
            onSubmit(selectedItems);
            setChangedItems([]);
          },
        }}
      />
    </div>
  );
};

export default SelectorModal;
