import { Flex } from "@fluentui/react-northstar";
import { useBooking } from "../../../../context/BookingContext";
import moment from "moment";

const SlotList = ({ shift, slots, isMobileView }) => {
  const {
    isSlotSelected,
    isScheduledSlot,
    setSelectedSlots,
    selectedSlots,
    isShowingScheduledSlots,
    isScheduling,
    isEditing,
    selectedDates,
    isSlotBooked,
  } = useBooking();

  // Calculate the midpoint of the slots array
  const midpoint = Math.ceil(slots.length / 2);

  // Split the slots array into two halves
  const leftSlots = isMobileView ? slots : slots.slice(0, midpoint);
  const rightSlots = isMobileView ? [] : slots.slice(midpoint);
  return (
    <Flex
      className="slot-shift-times-wrapper"
      hAlign="center"
      gap="gap.small"
      wrap
    >
      <Flex column gap="gap.small" className="slot-column">
        {leftSlots.map((slot, i) => {
          const isSelected = isSlotSelected(slot.startTime);
          const isScheduled = isScheduledSlot(slot.startTime);
          const isBooked = isSlotBooked(isScheduled, slot.startTime);
          const isFromPast = moment(slot.startTime).isBefore();

          return (
            <button
              className={`slot-shift-time ${
                isEditing
                  ? isSelected
                    ? "selected"
                    : ""
                  : isSelected || isScheduled
                  ? "selected"
                  : ""
              }${
                isShowingScheduledSlots && !isScheduled ? "notScheduled" : ""
              }`}
              key={`${shift}-slot-left-${i + 1}`}
              onClick={() => {
                if (selectedDates.length) {
                  if (
                    !isScheduling ||
                    selectedDates.length ||
                    (!isShowingScheduledSlots && isEditing)
                  ) {
                    isSelected
                      ? setSelectedSlots(
                          selectedSlots.filter(
                            (selectedSlot) =>
                              selectedSlot.startTime !== slot.startTime
                          )
                        )
                      : setSelectedSlots((prev) => [...prev, slot]);
                  }
                }
              }}
              disabled={
                isScheduling ||
                !selectedDates.length ||
                (isShowingScheduledSlots && !isEditing)
              }
            >
              {slot.startTime}
            </button>
          );
        })}
      </Flex>
      <Flex column gap="gap.small" className="slot-column">
        {rightSlots.map((slot, i) => {
          const isSelected = isSlotSelected(slot.startTime);
          const isScheduled = isScheduledSlot(slot.startTime);
          const isBooked = isSlotBooked(isScheduled, slot.startTime);
          return (
            <button
              className={`slot-shift-time ${
                isEditing
                  ? isSelected
                    ? "selected"
                    : ""
                  : isSelected || isScheduled
                  ? "selected"
                  : ""
              }${
                isShowingScheduledSlots && !isScheduled ? " notScheduled" : ""
              }`}
              key={`${shift}-slot-right-${i + 1}`}
              onClick={() => {
                if (selectedDates.length) {
                  if (
                    !isScheduling ||
                    selectedDates.length ||
                    (!isShowingScheduledSlots && isEditing)
                  ) {
                    isSelected
                      ? setSelectedSlots(
                          selectedSlots.filter(
                            (selectedSlot) =>
                              selectedSlot.startTime !== slot.startTime
                          )
                        )
                      : setSelectedSlots((prev) => [...prev, slot]);
                  }
                }
              }}
              disabled={
                isScheduling ||
                !selectedDates.length ||
                (isShowingScheduledSlots && !isEditing)
              }
            >
              {slot.startTime}
            </button>
          );
        })}
      </Flex>
    </Flex>
  );
};

export default SlotList;
