import ProfileInterest from "./Interests";
import ProfileSkills from "./Skills";
import Tabs from "./Tabs/Tabs";

import "./Profile.scss";
import { useTranslation } from "react-i18next";

const ProfileSetting = ({ user, addSkill, setAddSkill,addInterest,setAddInterest }) => {
  const { t } = useTranslation();
  return (
    <Tabs>
      <div label={t("setting").profile[0]}>
        <ProfileSkills
          user={user}
          addSkill={addSkill}
          setAddSkill={setAddSkill}
        />
      </div>
      <div label={t("setting").profile[1]}>
        <ProfileInterest
          user={user}
          addInterest={addInterest}
          setAddInterest={setAddInterest}
        />
      </div>
    </Tabs>
  );
};
export default ProfileSetting;
