import {
  Button,
  EditIcon,
  Flex,
  Loader,
  Tooltip,
  TrashCanIcon,
} from "@fluentui/react-northstar";
import React, { useState } from "react";
import styled from "styled-components";
// import UpdateParentModel from "../UpdateParentModel";
// import DeleteParentModel from "../DeleteParentModel";
import { useTranslation } from "react-i18next";
import "./TutorsTable.scss";
import DeleteTutorModel from "../DeleteTutorModel/DeleteTutorModel";

const Card = styled(Flex)`
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
  padding: 20px;
  border-radius: 8px;
  flex-wrap: wrap;
  align-items: center;
`;

const TutorsTable = (props) => {
  const [tutorToDelete, setTutorToDelete] = useState(null);
  const [isOpenDeleteTutor, setIsOpenDeleteTutor] = useState(false);

  const { t } = useTranslation();

  const bookingTranslation = t("booking").manageTutors;

  return (
    <>
      <DeleteTutorModel
        tutorToDelete={tutorToDelete}
        setTutorToDelete={setTutorToDelete}
        isOpen={isOpenDeleteTutor}
        user={props.user}
        onClose={() => {
          setIsOpenDeleteTutor(false);
        }}
        students={props.students}
        setStudents={props.setStudents}
        teachers={props.teachers}
        setTeachers={props.setTeachers}
        tutors={props.data}
        setTutors={props.setTutors}
      />
      {/* <UpdateParentModel
        parentDetails={parentDetails}
        setParentDetails={setParentDetails}
        selectedStudents={selectedStudents}
        setSelectedStudents={setSelectedStudents}
        isOpen={isOpenUpdateParent}
        studentsWithoutParents={props.studentsWithoutParents}
        setStudentsWithoutParents={props.setStudentsWithoutParents}
        getAllParents={props.getAllParents}
        user={props.user}
        onClose={() => {
          setIsOpenUpdateParent(false);
        }}
      />
      <DeleteParentModel
        isOpen={isOpenDeleteParent}
        getAllParents={props.getAllParents}
        parentToDelete={parentToDelete}
        setParentToDelete={setParentToDelete}
        studentsWithoutParents={props.studentsWithoutParents}
        setStudentsWithoutParents={props.setStudentsWithoutParents}
        user={props.user}
        onClose={() => {
          setIsOpenDeleteParent(false);
        }}
      /> */}
      <Flex column>
        <div style={{ padding: "0 20px 20px" }}>
          <Card className="ms-Grid-row my-3">
            <div className="ms-Grid-col ms-sm12 ms-lg3">
              {bookingTranslation.name}
            </div>
            <div className="ms-Grid-col ms-sm12 ms-lg5">
              {bookingTranslation.email}
            </div>
            <div className="ms-Grid-col ms-sm12 ms-lg2">Role</div>
            <Flex hAlign="center" className="ms-Grid-col ms-sm12 ms-lg2">
              {bookingTranslation.actions}
            </Flex>
          </Card>
        </div>
        <div
          style={{ padding: "0 20px 20px" }}
          className="parents-lists-wrapper"
        >
          {props.isLoading ? (
            <Flex
              hAlign="center"
              vAlign="center"
              style={{ height: "150px", width: "100%" }}
            >
              <Loader />
            </Flex>
          ) : props.data.length ? (
            props.data.map((tutor, i) => {
              return (
                <Card className="ms-Grid-row my-3" key={`tutor-${i}`}>
                  <div className="ms-Grid-col ms-sm12 ms-lg3">
                    <Tooltip
                      content={tutor.name}
                      trigger={<p className="parent-info-text">{tutor.name}</p>}
                    />
                  </div>
                  <div className="ms-Grid-col ms-sm12 ms-lg5">
                    <Tooltip
                      content={tutor.email}
                      trigger={
                        <p className="parent-info-text">{tutor.email}</p>
                      }
                    />
                  </div>
                  <div className="ms-Grid-col ms-sm12 ms-lg2">
                    <p className="parent-info-text">{tutor.role}</p>
                  </div>

                  <div className="ms-Grid-col ms-sm12 ms-lg2 text-right pr-3">
                    <Flex gap="gap.smaller" hAlign="center">
                      <Button
                        iconOnly
                        icon={<TrashCanIcon />}
                        onClick={() => {
                          setTutorToDelete(tutor);
                          setIsOpenDeleteTutor(true);
                        }}
                      />
                    </Flex>
                  </div>
                </Card>
              );
            })
          ) : (
            <Flex
              hAlign="center"
              vAlign="center"
              style={{ height: "150px", width: "100%" }}
            >
              {bookingTranslation.noResults}
            </Flex>
          )}
        </div>
      </Flex>
    </>
  );
};

export default TutorsTable;
