import { useEffect, useState } from "react";
import { NotificationAlert as callNotificationAlert } from "../../../../components/Notification/Notification";
import { useTranslation } from "react-i18next";
import { useAudioRecorder } from "@sarafhbk/react-audio-recorder";

export const getUniqueId = () => {
  return Math.floor(Math.random() * Math.floor(Math.random() * Date.now()));
};

const useAttachmentOption = ({
  eventData,
  setAttachmentData,
  isEditable = false, // Make Attachment editable
}) => {
  const attachmentsData = eventData.attachments;
  const setAttachmentsData = (data) => {
    if (setAttachmentData) setAttachmentData(data);
  };

  const {
    isShowTextAttachment,
    isShowAudioAttachment,
    isShowVideoAttachment,
    isShowLinkAttachment,
    isShowPdfAttachment,
  } = {
    isShowTextAttachment: false,
    isShowAudioAttachment: false,
    isShowVideoAttachment: false,
    isShowLinkAttachment: true,
    isShowPdfAttachment: true,
  };

  const { t } = useTranslation();

  const translation = t("assignment").body;
  const attachmentTranslation = translation.attachmentOption;

  const [linkText, setLinkText] = useState("");
  const [links, setLinks] = useState([]);
  const [linkState, setLinkState] = useState({
    open: false,
    isSave: false,
  });

  const [text, setText] = useState("");
  const [textState, setTextState] = useState({
    open: false,
    isSave: false,
    isChanged: false,
  });
  const [file, setFile] = useState([]);
  const [fileState, setFileState] = useState({
    open: false,
    isSave: false,
  });

  const [voiceState, setVoiceState] = useState({
    open: false,
    isStart: false,
    isSaved: false,
    isPause: false,
    isDone: false,
    audioUrl: "",
  });

  const [videoState, setVideoState] = useState({
    open: false,
    isStart: false,
    isSaved: false,
    isDone: false,
    videoUrl: "",
  });

  const [isConfirmDelete, setIsConfirmDelete] = useState(false);

  const [confirmTextDelete, setConfirmTextDelete] = useState(false);
  const [confirmVideoDelete, setConfirmVideoDelete] = useState(false);

  const {
    audioResult,
    timer: audioTimer,
    startRecording: startAudioRecording,
    stopRecording: stopAudioRecording,
    pauseRecording: pauseAudioRecording,
    resumeRecording: resumeAudioRecording,
  } = useAudioRecorder();

  useEffect(() => {
    setLinks(attachmentsData?.find((item) => item.type === "link")?.info || []);

    setLinkState({
      open: false,
      isSave: !!attachmentsData?.find((item) => item.type === "link")?.info
        ?.length,
    });

    setText(attachmentsData?.find((item) => item.type === "text")?.info || "");
    setTextState(() =>
      attachmentsData?.find((item) => item.type === "text")?.info
        ? {
            open: false,
            isSave: true,
            isChanged: false,
          }
        : {
            open: false,
            isSave: false,
            isChanged: false,
          }
    );
    setFile(attachmentsData?.find((item) => item.type === "file")?.info || []);
    setFileState({
      open: false,
      isSave: false,
    });

    setVoiceState(() => {
      let voiceInfoFromEventData = attachmentsData?.find(
        (item) => item.type === "voice"
      )?.info;
      return voiceInfoFromEventData
        ? {
            open: false,
            isStart: false,
            isSaved: true,
            isPause: false,
            isDone: true,
            audioUrl: voiceInfoFromEventData,
          }
        : {
            open: false,
            isStart: false,
            isSaved: false,
            isPause: false,
            isDone: false,
            audioUrl: "",
          };
    });

    setVideoState(() => {
      let videoInfoFromEventData = attachmentsData?.find(
        (item) => item.type === "video"
      )?.info;
      return videoInfoFromEventData
        ? {
            open: false,
            isStart: false,
            isSaved: true,
            isDone: true,
            videoUrl: videoInfoFromEventData,
          }
        : {
            open: false,
            isStart: false,
            isSaved: false,
            isDone: false,
            videoUrl: "",
          };
    });
  }, []);

  useEffect(() => {
    if (audioResult) {
      setVoiceState((prev) => ({
        ...prev,
        audioUrl: audioResult,
      }));
    }
  }, [audioResult]);

  useEffect(() => {
    if (attachmentsData?.find((item) => item.type === "text")?.info != text) {
      setTextState((prevState) => ({ ...prevState, isChanged: true }));
    }
  }, [text]);

  const handleFileChange = (newFile) => {
    Array.from(newFile)?.forEach((item) => {
      if (typeof item === "object" && item?.size / 1048576 > 25) {
        callNotificationAlert(
          attachmentTranslation.file.sizeValidation,
          "warning"
        );
      } else {
        setFile((prevData) => [
          ...prevData,
          {
            id: getUniqueId(),
            file: item,
          },
        ]);
      }
    });
  };

  const handleVoiceRecordConfirm = async () => {
    if (!isEditable) {
      if (voiceState.isDone) {
        setIsConfirmDelete(true);
      } else if (voiceState.isSaved) {
        setVoiceState({
          ...voiceState,
          isDone: true,
          open: false,
          audioUrl: audioResult,
        });
        callNotificationAlert(attachmentTranslation.voice.added, "success");
        setAttachmentsData([
          ...attachmentsData?.map((item) =>
            item.type === "voice" ? { ...item, info: audioResult } : item
          ),
        ]);
      } else {
        stopAudioRecording();
        if (audioTimer && audioTimer / 60 > 5) {
          setVoiceState({
            ...voiceState,
            isDone: false,
            open: false,
            isSaved: false,
            isPause: false,
            isStart: false,
            audioUrl: "",
          });
          callNotificationAlert(
            attachmentTranslation.voice.sizeValidation,
            "warning"
          );
        } else {
          setVoiceState({
            ...voiceState,
            isSaved: true,
            isStart: false,
            isPause: false,
            timer: audioTimer,
          });
        }
      }
    }
  };

  const handleVideoRecordConfirm = () => {
    if (!isEditable) {
      if (videoState.isDone) {
        setConfirmVideoDelete(true);
      } else if (!videoState.isSaved) {
        setVideoState({
          ...videoState,
          isSaved: true,
          isClosedWithoutSaved: false,
        });
      } else if (videoState.videoUrl) {
        setVideoState({
          ...videoState,
          isDone: true,
          open: false,
          isClosedWithoutSaved: false,
          isVideoRecorded: false,
        });
        callNotificationAlert(attachmentTranslation.video.added, "success");
        setAttachmentsData([
          ...attachmentsData?.map((item) =>
            item.type === "video"
              ? { ...item, info: videoState.videoUrl }
              : item
          ),
        ]);
      } else {
        setVideoState({
          ...videoState,
          isDone: false,
          isSaved: false,
          open: false,
        });
      }
    }
  };

  const handleDeleteText = (val) => {
    setText("");
    setAttachmentsData([
      ...attachmentsData?.map((item) =>
        item.type === "text" ? { ...item, info: "" } : item
      ),
    ]);
    setTextState({ ...textState, isSave: false, open: false });
    setConfirmTextDelete(false);
    callNotificationAlert(attachmentTranslation.text.deleted, "success");
  };

  const handleTextDeleteConfirm = (val) => {
    if (
      textState.isSave &&
      !textState.isChanged &&
      attachmentsData?.find((item) => item.type === "text")?.info
    ) {
      setConfirmTextDelete(true);
    } else {
      setAttachmentsData([
        ...attachmentsData?.map((item) =>
          item.type === "text" ? { ...item, info: text } : item
        ),
      ]);
      setTextState({ ...textState, isSave: true, open: false });
      callNotificationAlert(attachmentTranslation.text.added, "success");
    }
  };

  const getConfirmFlagForAudio = (val) => {
    setIsConfirmDelete(false);

    if (!val) {
      setVoiceState({
        ...voiceState,
        open: false,
      });

      return;
    }

    setVoiceState({
      ...voiceState,
      isStart: false,
      isPause: false,
      isSaved: false,
      isDone: false,
      open: false,
    });
    callNotificationAlert(attachmentTranslation.voice.deleted, "success");
    setAttachmentsData([
      ...attachmentsData?.map((item) =>
        item.type === "voice" ? { ...item, info: "" } : item
      ),
    ]);
  };

  const getConfirmFlagForVideo = (val) => {
    setConfirmVideoDelete(false);

    if (!val) {
      setVideoState({
        ...videoState,
        open: false,
      });

      // return;
    } else {
      setVideoState({
        ...videoState,
        isStart: false,
        isSaved: false,
        isDone: false,
        open: false,
        videoUrl: "",
        isClosedWithoutSaved: false,
        isVideoRecorded: false,
      });
      callNotificationAlert(attachmentTranslation.video.deleted, "success");
      setAttachmentsData([
        ...attachmentsData?.map((item) =>
          item.type === "video" ? { ...item, info: "" } : item
        ),
      ]);
    }
  };

  return {
    attachmentsData,
    setAttachmentsData,

    // Attachments States
    linkText,
    setLinkText,
    links,
    setLinks,
    linkState,
    setLinkState,
    text,
    setText,
    textState,
    setTextState,
    file,
    setFile,
    fileState,
    setFileState,
    voiceState,
    setVoiceState,
    videoState,
    setVideoState,
    isConfirmDelete,
    setisConfirmDelete: setIsConfirmDelete,
    confirmTextDelete,
    setConfirmTextDelete,
    confirmVideoDelete,
    setConfirmVideoDelete,

    // Audio Recording states
    audioResult,
    audioTimer,
    startAudioRecording,
    stopAudioRecording,
    pauseAudioRecording,
    resumeAudioRecording,

    //
    isEditable,
    handleFileChange,

    //delete handler
    handleVoiceRecordConfirm,
    handleVideoRecordConfirm,
    handleTextDeleteConfirm,
    handleDeleteText,
    getConfirmFlagForAudio,
    getConfirmFlagForVideo,

    // display attachments
    isShowTextAttachment,
    isShowAudioAttachment,
    isShowVideoAttachment,
    isShowLinkAttachment,
    isShowPdfAttachment,
  };
};

export default useAttachmentOption;
