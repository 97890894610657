import React, { useEffect, useState } from "react";

import {
  ArrowLeftIcon,
  Button,
  CloseIcon,
  Dialog,
  Flex,
  Loader,
  SplitButton,
  Text,
} from "@fluentui/react-northstar";
import { DatePicker, defaultDatePickerStrings } from "@fluentui/react";
import { TextField } from "@fluentui/react/lib/TextField";
import { Editor } from "react-draft-wysiwyg";
import { Dropdown } from "@fluentui/react/lib/Dropdown";
import draftToHtml from "draftjs-to-html";
import htmlToDraft from "html-to-draftjs";
import { EditorState, convertToRaw, ContentState } from "draft-js";
import moment from "moment";
import axios from "axios";
import { BlobServiceClient } from "@azure/storage-blob";
import { withTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";

import AttachmentOption from "../AttachmentOption/AttachmentOption";
import { assignmentCreationStatus } from "../../constant";
import { markingType as mark } from "../../constant";
import ConfirmDialog from "../ConfirmDialog/ConfirmDialog";
import { useAssignment } from "../../../../context/AssignmentContext";
import { NotificationAlert } from "../../../../components/Notification/Notification";
import GradingPage from "./GradingPage";
import StudentSubmissions from "./StudentSubmissions";
import CustomProgressIndicator from "../../../../components/Loader/CustomProgressIndicator";

import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import "../AssignmentHome/AssignmentHome.css";
import "react-toastify/dist/ReactToastify.css";

const url = process.env.REACT_APP_EP_URL;

const CreateNewAssignment = (props) => {
  const marking = mark;

  const { state: locationState } = useLocation();

  const {
    currentAssignmentData,
    setCurrentAssignmentData,
    currentLessionStudentList,
    isSubmissionsPage,
    setIsSubmissionsPage,
    isGradingPage,
    setIsGradingPage,
    setCurrentPage,
  } = useAssignment();

  const {
    data,
    setCurrentExCard,
    user,
    showLesson,
    setRefreshAssignmentList,
    refreshAssignmentList,
    selectedLesson,
    history,
    showRecords,
    t,
    isMobileView,
    isTabletView,
  } = props;

  const translation = t("assignment").body;
  const assignmentDetailsTranslation = translation.assignmentDetails;

  const [dueDate, setDueDate] = useState(
    currentAssignmentData?.dueDate &&
      moment(currentAssignmentData.dueDate).isValid()
      ? new Date(moment(currentAssignmentData.dueDate).format("MM-DD-YYYY"))
      : null
  );

  const [assignmentTitle, setAssignmentTitle] = useState(
    currentAssignmentData?.assignmentTitle || data?.assignmentTitle || ""
  );

  const [attachmentList, setAttachmentList] = useState([]);

  const [instruction, setInstruction] = useState(() => {
    const blocksFromHtml = htmlToDraft(
      currentAssignmentData?.instructionStyle?.instructionHtmlString || ""
    );
    const { contentBlocks, entityMap } = blocksFromHtml;
    const contentState = ContentState.createFromBlockArray(
      contentBlocks,
      entityMap
    );
    return (
      EditorState.createWithContent(contentState) ||
      currentAssignmentData?.instruction
    );
  });
  const [selectedSubmission, setSelectedSubmission] = useState();

  const [markingType, setMarkingType] = useState(
    currentAssignmentData?.markingType || 0
  );
  const [selectedStudentForSubmission, setSelectedStudentForSubmission] =
    useState(() => {
      return currentAssignmentData?.assignmentFor?.length
        ? currentAssignmentData.assignmentFor.length === showRecords?.length
          ? [0, ...currentAssignmentData.assignmentFor]
          : [...currentAssignmentData.assignmentFor]
        : [];
    });
  const [deleteAssignmentConfirm, setDeleteAssignmentConfirm] = useState(false);

  const [publishLaterState, setPublishLaterState] = useState(false);
  const [publishLaterDate, setPublishLaterDate] = useState(
    new Date(moment(selectedLesson?.LessonDate).format("MM-DD-YYYY"))
  );
  const [selectedGrade, setselectedGrade] = useState();

  const [confirmUpdateChanges, setConfirmUpdateChanges] = useState(false);
  const [isCreateUpdateAssignmentLoading, setIsCreateUpdateAssignmentLoading] =
    useState(false);
  const [errorCheckobj, setErrorCheckobj] = useState({
    title: false,
    assignmentFor: false,
    dueDate: false,
  });

  const [formattedCreationStatus, setFormattedCreationStatus] = useState([]);
  const [selectedAvatarVideo, setSelectedAvatarVideo] = useState(
    currentAssignmentData?.attachments?.find(
      (item) => item.type === "avatarVideo"
    )?.info || ""
  );

  useEffect(() => {
    if (locationState?.callBackPage === "gradingPage") {
      setIsSubmissionsPage(false);
      setIsGradingPage(true);
      setSelectedSubmission(locationState?.selectedSubmission);
    } else {
      setIsSubmissionsPage(false);
      setIsGradingPage(false);
    }

    getAssignmentCreationStatus();

    return () => {
      setIsSubmissionsPage(false);
      setIsGradingPage(false);
    };
  }, []);

  useEffect(() => {
    if (!(isGradingPage || isSubmissionsPage)) {
      if (currentAssignmentData?.id) {
        setCurrentPage("createdAssignmentDetails");
      } else {
        setCurrentPage("newAssignmentDetails");
      }
    }
  }, [isGradingPage, isSubmissionsPage]);

  const onStudentForSubmissionSelectChange = (event, item) => {
    if (item) {
      if (item.key === 0) {
        if (item.selected) {
          setSelectedStudentForSubmission(
            currentLessionStudentList.map((student) => student.key)
          );
          setCurrentAssignmentData((prevData) => ({
            ...prevData,
            assignmentFor:
              currentLessionStudentList
                .filter((student) => student.key !== 0)
                ?.map((student) => student.key) || [],
            isChanged: true,
          }));
        } else {
          setSelectedStudentForSubmission([]);
          setCurrentAssignmentData((prevData) => ({
            ...prevData,
            assignmentFor: [],
            isChanged: true,
          }));
        }
      } else {
        if (item.selected) {
          let students = [...selectedStudentForSubmission, item.key];
          setSelectedStudentForSubmission(
            students.filter((item) => item).length === showRecords?.length
              ? [0, ...students]
              : students
          );
          setCurrentAssignmentData((prevData) => ({
            ...prevData,
            assignmentFor:
              students.filter((item) => item).length === showRecords?.length
                ? [0, ...students]
                : students,
            isChanged: true,
          }));
        } else {
          const data = selectedStudentForSubmission.filter(
            (key) => key !== item.key && key !== 0
          );
          setCurrentAssignmentData((prevData) => ({
            ...prevData,
            assignmentFor: data,
            isChanged: true,
          }));
          setSelectedStudentForSubmission(data);
        }
      }
    }
  };

  const onEditorStateChange = (editorState) => {
    setInstruction(editorState);
    setCurrentAssignmentData((prevData) => ({
      ...prevData,
      instructionStyle: {
        ...prevData.instructionStyle,
        instructionHtmlString: draftToHtml(
          convertToRaw(editorState.getCurrentContent())
        ),
      },
      isChanged: true,
    }));
  };

  const createRubricHandler = async ({ rubric }) => {
    let className = "";
    if (selectedLesson?.ClassName) {
      className = selectedLesson.ClassName.substring(
        0,
        selectedLesson.ClassName.lastIndexOf("-")
      ).trim();
    }

    const { rubricItems, ...rest } = rubric;
    let rubricItem = rubricItems?.map((item) => ({
      ...item,
      rubricItemTitle: item.title,
      element: item.element,
      description: item.description,
      havePoints: !!item.havePoints,

      rubricCriteria: item.levels?.map((e) => ({
        ...e,
        criteriaTitle: e.levelTitle,
        points: e.points,
        description: e.description,
        element: e.element,
        hasPoint: !!item.havePoints,
      })),
    }));
    let payload = {
      rubricInfo: { ...rest, class: className, rubricItem },
    };
    try {
      let response = await axios({
        method: "post",
        url: `${url}/api/rubric/create-rubric${user.slug}`,
        data: payload,
        headers: {
          Authorization: "Bearer " + user.accessToken,
        },
      });
      if (response.data?.status === "success") {
        return response.data.results;
      }
    } catch (error) {
      console.log("error", error);
    }
  };

  const deleteRubricHandler = async (id) => {
    try {
      let response = await axios({
        method: "delete",
        url: `${url}/api/rubric/delete-rubric/${id}${user.slug}`,
        headers: {
          Authorization: "Bearer " + user.accessToken,
        },
      });
    } catch (error) {
      console.log(error);
    }
  };

  const validateAssignmentData = (payload) => {
    let isValid = true;
    if (!payload.assignmentTitle?.length) {
      isValid = false;
    }
    if (!payload.assignmentFor?.length) {
      isValid = false;
    }

    let lessonDate = moment(
      moment.utc(selectedLesson?.LessonDate).local().format("MM-DD-YYYY")
    );
    let dueDate = moment(
      moment(payload.dueDate).format("YYYY-MM-DD") + " 23:59:59"
    );
    if (!moment(payload.dueDate).isValid()) {
      isValid = false;
    } else if (lessonDate.isAfter(dueDate)) {
      isValid = false;
      NotificationAlert(
        assignmentDetailsTranslation.dueDateValidation,
        "warning"
      );
    }

    return isValid;
  };

  const createAssignmentHandler_ = async ({ status }) => {
    // 'Published', 'Draft', 'Scheduled'
    let payload = currentAssignmentData;
    delete payload.isChanged;
    setErrorCheckobj({ title: true, dueDate: true, assignmentFor: true });
    if (!validateAssignmentData(payload)) {
      return;
    }
    if (
      payload.rubric?.rubricItems?.length ||
      Number(payload.markingType) > 0
    ) {
      let rubricItems = payload.rubric?.rubricItems;
      let sum = 0;
      rubricItems?.forEach((item) => {
        if (item.levels?.[0]?.points) {
          sum += item.levels?.[0]?.points;
        }
      });
      if (Number(payload.markingType) !== sum) {
        NotificationAlert("Rubric Points and Grades must be same", "warning");
        return;
      }
    }

    try {
      setIsCreateUpdateAssignmentLoading(true);
      if (payload.rubric?.rubricItems?.length) {
        let rubricId = await createRubricHandler({ rubric: payload.rubric });
        payload.rubricBankId = rubricId || null;
      }
      let finalPayload = {
        title: payload.assignmentTitle,
        instruction: payload.instructionStyle.instructionHtmlString,
        subjectId: showLesson?.Title,
        lessonId: selectedLesson.Title,
        createdBy: user.mail || user.username,
        markingType: Number(payload.markingType) || 0,

        studentIds: payload.assignmentFor.filter((item) => item),

        dueDate: moment(payload.dueDate).isValid()
          ? moment(
              moment(payload.dueDate).format("YYYY-MM-DD") + " 23:59:59"
            ).format("YYYY-MM-DDTHH:mm:ssZ")
          : null,
        pusblishedDate:
          status === "Published"
            ? moment(new Date()).format("YYYY-MM-DDTHH:mm:ssZ")
            : null,
        scheduledOn:
          status === "Scheduled"
            ? publishLaterDate
              ? moment(publishLaterDate).format("YYYY-MM-DDTHH:mm:ssZ")
              : selectedLesson?.LessonDate
            : null,
        creationStatus: status,
        rubricBankId: payload.rubricBankId,
        attachments: [],
        role: user.role.toLowerCase(),
      };

      const uploadFileToBlob = async (file, storagePath, attachmentType) => {
        const containerName = `assignments`;
        const storageAccountName = process.env.REACT_APP_STORAGE;
        const sasToken = process.env.REACT_APP_AZURE_SAS_TOKEN;

        if (!file) return [];

        const blobService = new BlobServiceClient(
          `https://${storageAccountName}.blob.core.windows.net/${sasToken}`
        );

        const containerClient = blobService.getContainerClient(containerName);

        const blobClient = containerClient.getBlockBlobClient(storagePath);

        const option = { blobHTTPHeaders: { blobContentType: file.type } };

        let response = await blobClient.uploadBrowserData(file, option);

        return response._response.request.url;
      };
      let storagePath = `teacher-${Date.now()}-${
        finalPayload.title?.replace(/\s/g, "")?.replace("/", "-") || "title"
      }`;
      if (payload.attachments.length) {
        let attachments = payload.attachments;
        let newAttachments = [];
        for (const media of attachments) {
          if (media.type === "file") {
            if (media.info?.length) {
              let urlArray = [];
              for (const fileItem of media.info) {
                let url = await uploadFileToBlob(
                  fileItem.file,
                  `${storagePath}/file/${`${fileItem.file?.name}`}`
                );
                urlArray.push(url);
              }

              // have to add array of urls
              newAttachments.push({
                type: "file",
                url: urlArray.join(","),
              });
            }
          } else if (media.type === "video") {
            if (media.info) {
              let url = await uploadFileToBlob(
                await fetch(media.info).then((r) => r.blob()),
                `${storagePath}/video`
              );
              newAttachments.push({
                type: "video",
                url: url,
              });
            }
          } else if (media.type === "voice") {
            if (media.info) {
              let url = await uploadFileToBlob(
                await fetch(media.info).then((r) => r.blob()),
                `${storagePath}/audio`
              );
              newAttachments.push({
                type: "voice",
                url: url,
              });
            }
          } else if (media.type === "text") {
            if (media.info?.length) {
              let url = await uploadFileToBlob(
                new Blob([media.info], { type: "text/plain" }),
                `${storagePath}/note`
              );
              newAttachments.push({
                type: "text",
                url: url,
              });
            }
          } else if (media.type === "link") {
            if (media.info?.length) {
              let url = await uploadFileToBlob(
                new Blob([media.info], { type: "text/plain" }),
                `${storagePath}/link`
              );
              newAttachments.push({
                type: "link",
                url: url,
              });
            }
          }
        }
        finalPayload.attachments = newAttachments;
      }

      if (selectedAvatarVideo) {
        let clonedAttachments = [...payload.attachments];
        clonedAttachments.push({
          type: "avatarVideo",
          url: selectedAvatarVideo,
        });
        finalPayload.attachments = clonedAttachments;
      }

      let response = await axios({
        method: "post",
        url: `${url}/api/assignment/create-assingment${user.slug}`,
        data: finalPayload,
        headers: {
          Authorization: "Bearer " + user.accessToken,
        },
      });

      if (response.data?.status === "Success") {
        setCurrentExCard(null);
        if (status === "Draft") {
          NotificationAlert("Assignment Saved as Draft", "success");
        } else if (status === "Scheduled") {
          NotificationAlert("Assignment Published Later", "success");
        } else {
          NotificationAlert(response.data.message, "success");
        }
        setRefreshAssignmentList(!refreshAssignmentList);
        setIsCreateUpdateAssignmentLoading(false);
        setCurrentPage("assignmentHome");
        setCurrentExCard(null);
        // history.push({
        //   pathname: "/assignments",
        // });
        props.setIsCreatingNew(false);
      } else {
        setIsCreateUpdateAssignmentLoading(false);
      }
    } catch (error) {
      console.log("error", error);
      setIsCreateUpdateAssignmentLoading(false);
    }
  };
  const createAssignmentHandler = async ({ status }) => {
    // 'Published', 'Draft', 'Scheduled'
    let payload = currentAssignmentData;
    delete payload.isChanged;
    setErrorCheckobj({ title: true, dueDate: true, assignmentFor: true });
    if (!validateAssignmentData(payload)) {
      return;
    }
    if (
      payload.rubric?.rubricItems?.length ||
      Number(payload.markingType) > 0
    ) {
      let rubricItems = payload.rubric?.rubricItems;
      let sum = 0;
      rubricItems?.forEach((item) => {
        if (item.levels?.[0]?.points) {
          sum += item.levels?.[0]?.points;
        }
      });
      if (Number(payload.markingType) !== sum) {
        NotificationAlert("Rubric Points and Grades must be same", "warning");
        return;
      }
    }

    try {
      setIsCreateUpdateAssignmentLoading(true);
      if (payload.rubric?.rubricItems?.length) {
        // let rubricId = await createRubricHandler({ rubric: payload.rubric });
        payload.rubricBankId = rubricId || null;
      }
      let finalPayload = {
        title: payload.assignmentTitle,
        instruction: payload.instructionStyle.instructionHtmlString,
        subjectId: showLesson?.Title || null,
        lessonId: selectedLesson.Title || null,
        createdBy: user.mail || user.username,
        markingType: Number(payload.markingType) || 0,
        studentIds: payload.assignmentFor.filter((item) => item),
        dueDate: moment(payload.dueDate).isValid()
          ? moment(
              moment(payload.dueDate).format("YYYY-MM-DD") + " 23:59:59"
            ).format("YYYY-MM-DDTHH:mm:ssZ")
          : null,
        pusblishedDate:
          status === "Published"
            ? moment(new Date()).format("YYYY-MM-DDTHH:mm:ssZ")
            : null,
        scheduledOn:
          status === "Scheduled"
            ? publishLaterDate
              ? moment(publishLaterDate).format("YYYY-MM-DDTHH:mm:ssZ")
              : selectedLesson?.LessonDate
            : null,
        creationStatus: status,
        rubricBankId: payload.rubricBankId || null,
        role: user.role.toLowerCase(),
      };

      let formData = new FormData();
      let storagePath = `teacher-${Date.now()}-${
        finalPayload.title?.replace(/\s/g, "")?.replace("/", "-") || "title"
      }`;
      // Append all the assignment data in formData
      Object.keys(finalPayload).forEach((key) => {
        formData.append(key, JSON.stringify(finalPayload[key]));
      });

      if (payload.attachments.length) {
        let attachments = payload.attachments;
        for (const media of attachments) {
          if (media.type === "file") {
            if (media.info?.length) {
              for (const fileItem of media.info) {
                formData.append("file", fileItem.file);
              }
            }
          } else if (media.type === "video") {
            if (media.info) {
              formData.append("video", media.info);
            }
          } else if (media.type === "voice") {
            if (media.info) {
              formData.append("voice", media.info);
            }
          } else if (media.type === "text") {
            if (media.info?.length) {
              formData.append("text", media.info);
            }
          } else if (media.type === "link") {
            if (media.info?.length) {
              formData.append("link", JSON.stringify(media.info));
            }
          }
        }
      }

      if (selectedAvatarVideo) {
        formData.append("avatarVideo", selectedAvatarVideo);
      }

      let response = await axios({
        method: "post",
        url: `${url}/api/assignment/create-assingment${user.slug}`,
        data: formData,
        headers: {
          Authorization: "Bearer " + user.accessToken,
          "Content-Type": "multipart/form-data",
        },
      });

      if (response.data?.status === "Success") {
        setCurrentExCard(null);
        if (status === "Draft") {
          NotificationAlert("Assignment Saved as Draft", "success");
        } else if (status === "Scheduled") {
          NotificationAlert("Assignment Published Later", "success");
        } else {
          NotificationAlert(response.data.message, "success");
        }
        setRefreshAssignmentList(!refreshAssignmentList);
        setIsCreateUpdateAssignmentLoading(false);
        setCurrentPage("assignmentHome");
        setCurrentExCard(null);
        // history.push({
        //   pathname: "/assignments",
        // });
        props.setIsCreatingNew(false);
      } else {
        setIsCreateUpdateAssignmentLoading(false);
      }
    } catch (error) {
      console.log("error", error);
      setIsCreateUpdateAssignmentLoading(false);
    }
  };

  const updateAssignmentHandler_ = async ({ status }) => {
    setConfirmUpdateChanges(false);
    let payload = currentAssignmentData;
    setErrorCheckobj({ title: true, dueDate: true, assignmentFor: true });
    if (!validateAssignmentData(payload)) {
      return;
    }
    setIsCreateUpdateAssignmentLoading(true);
    if (
      payload.rubric &&
      !payload.rubric.isRubricItemDeleted &&
      payload.rubric?.rubricItems?.length ===
        payload.rubric?.rubricItem?.length &&
      payload.rubric?.rubricItems?.[0]?.levels?.length ===
        payload.rubric?.rubricItem?.[0]?.rubricCriteria?.length
    ) {
      let rubricItems = payload.rubric.rubricItems?.length
        ? payload.rubric.rubricItems.map((item) => ({
            ...item,
            rubricItemTitle: item.title,
            element: item.element,
            description: item.description,
            havePoints: !!item.havePoints,

            rubricCriteria: item.levels?.map((itemChild) => ({
              ...itemChild,
              criteriaTitle: itemChild.levelTitle,
              description: itemChild.description,
              element: itemChild.element,
              hasPoint: !!item.havePoints,
              points: Number(itemChild.points) || 0,
            })),
          }))
        : [];

      const rubricPayload = {
        rubricTitle: payload.rubric.rubricTitle,
        rubricItems,
      };

      if (rubricItems.length) {
        try {
          let response = await axios({
            method: "put",
            url: `${url}/api/rubric/update-rubric-items-criteria/${payload.rubric.rubricId}${user.slug}`,
            data: rubricPayload,
            headers: {
              Authorization: "Bearer " + user.accessToken,
            },
          });
        } catch (error) {
          console.log(error);
        }
      }
    } else {
      if (payload.rubric?.rubricItems?.length) {
        let rubricId = await createRubricHandler({ rubric: payload.rubric });
        payload.rubricBankId = rubricId || null;
      } else {
        if (payload.rubric?.rubricId && payload.rubric.rubricId?.length > 0) {
          await deleteRubricHandler(payload.rubric.rubricId);
        }
        payload.rubricBankId = null;
      }
    }
    try {
      let finalPayload = {
        title: payload.assignmentTitle,
        instruction: payload.instructionStyle.instructionHtmlString,
        subjectId: showLesson?.Title,
        lessonId: selectedLesson.Title,
        createdBy: user.mail || user.username,
        markingType: Number(payload.markingType) || 0,

        studentIds: payload.assignmentFor?.filter((item) => item) || [],

        dueDate: moment(payload.dueDate).isValid()
          ? moment(
              moment(payload.dueDate).format("YYYY-MM-DD") + " 23:59:59"
            ).format("YYYY-MM-DDTHH:mm:ssZ")
          : null,

        pusblishedDate:
          status === "Published"
            ? moment(new Date()).format("YYYY-MM-DDTHH:mm:ssZ")
            : null,
        scheduledOn:
          status === "Scheduled"
            ? publishLaterDate
              ? moment(publishLaterDate).format("YYYY-MM-DDTHH:mm:ssZ")
              : selectedLesson?.LessonDate
            : null,
        creationStatus: status,
        rubricBankId: payload.rubricBankId,
        attachments: [],
        role: user.role.toLowerCase(),
      };

      const uploadFileToBlob = async (file, storagePath, attachmentType) => {
        const containerName = `assignments`;
        const storageAccountName = process.env.REACT_APP_STORAGE;
        const sasToken = process.env.REACT_APP_AZURE_SAS_TOKEN;

        if (!file) return [];

        const blobService = new BlobServiceClient(
          `https://${storageAccountName}.blob.core.windows.net/${sasToken}`
        );

        const containerClient = blobService.getContainerClient(containerName);

        const blobClient = containerClient.getBlockBlobClient(storagePath);

        const option = { blobHTTPHeaders: { blobContentType: file.type } };

        let response = await blobClient.uploadBrowserData(file, option);

        return response._response.request.url;
      };
      let storagePath = `teacher-${Date.now()}-${
        finalPayload.title?.replace(/\s/g, "")?.replace("/", "-") || "title"
      }`;
      if (payload.attachments.length) {
        let attachments = payload.attachments;
        let newAttachments = [];
        for (const media of attachments) {
          if (media.type === "file") {
            if (media.info?.length) {
              let urlArray = [];
              for (const fileItem of media.info) {
                if (
                  typeof fileItem === "string" &&
                  fileItem?.includes("blob.core.windows.net")
                ) {
                  urlArray.push(fileItem);
                } else {
                  let azureUrl = await uploadFileToBlob(
                    fileItem.file,
                    `${storagePath}/file/${`${fileItem.file?.name}`}`
                  );
                  urlArray.push(azureUrl);
                }
              }

              newAttachments.push({
                type: "file",
                url: urlArray.join(","),
              });
            }
          } else if (media.type === "video") {
            if (media.info) {
              if (
                typeof media.info === "string" &&
                media.info?.includes("blob.core.windows.net")
              ) {
                newAttachments.push({
                  type: "video",
                  url: media.info,
                });
              } else {
                let azureUrl = await uploadFileToBlob(
                  await fetch(media.info).then((r) => r.blob()),
                  `${storagePath}/video`
                );
                newAttachments.push({
                  type: "video",
                  url: azureUrl,
                });
              }
            }
          } else if (media.type === "voice") {
            if (media.info) {
              if (
                typeof media.info === "string" &&
                media.info?.includes("blob.core.windows.net")
              ) {
                newAttachments.push({
                  type: "voice",
                  url: media.info,
                });
              } else {
                let azureUrl = await uploadFileToBlob(
                  await fetch(media.info).then((r) => r.blob()),
                  `${storagePath}/audio`
                );
                newAttachments.push({
                  type: "voice",
                  url: azureUrl,
                });
              }
            }
          } else if (media.type === "text") {
            if (media.info?.length) {
              let azureUrl = await uploadFileToBlob(
                new Blob([media.info], { type: "text/plain" }),
                `${storagePath}/note`
              );
              newAttachments.push({
                type: "text",
                url: azureUrl,
              });
            }
          } else if (media.type === "link") {
            if (media.info?.length) {
              let azureUrl = await uploadFileToBlob(
                new Blob([media.info], { type: "text/plain" }),
                `${storagePath}/link`
              );
              newAttachments.push({
                type: "link",
                url: azureUrl,
              });
            }
          }
        }
        finalPayload.attachments = newAttachments;
      }

      let response = await axios({
        method: "PUT",
        url: `${url}/api/assignment/update-assingment${user.slug}&id=${currentAssignmentData.id}`,
        data: finalPayload,
        headers: {
          Authorization: "Bearer " + user.accessToken,
        },
      });
      if (response.data?.status === "Success") {
        setCurrentAssignmentData((prevData) => ({
          ...prevData,
          isChanged: false,
        }));
        NotificationAlert(response.data.message, "success");
        setRefreshAssignmentList(!refreshAssignmentList);
        setIsCreateUpdateAssignmentLoading(false);
      } else {
        setIsCreateUpdateAssignmentLoading(false);
      }
    } catch (error) {
      console.log("error", error);
      setIsCreateUpdateAssignmentLoading(false);
    } finally {
      setCurrentPage("assignmentHome");
      setCurrentExCard(null);
      props.history.push({
        pathname: "/assignments",
      });
    }
  };
  const updateAssignmentHandler = async ({ status }) => {
    setConfirmUpdateChanges(false);
    let payload = currentAssignmentData;
    setErrorCheckobj({ title: true, dueDate: true, assignmentFor: true });
    if (!validateAssignmentData(payload)) {
      return;
    }
    setIsCreateUpdateAssignmentLoading(true);

    if (
      payload.rubric &&
      !payload.rubric.isRubricItemDeleted &&
      payload.rubric?.rubricItems?.length ===
        payload.rubric?.rubricItem?.length &&
      payload.rubric?.rubricItems?.[0]?.levels?.length ===
        payload.rubric?.rubricItem?.[0]?.rubricCriteria?.length
    ) {
      let rubricItems = payload.rubric.rubricItems?.length
        ? payload.rubric.rubricItems.map((item) => ({
            ...item,
            rubricItemTitle: item.title,
            element: item.element,
            description: item.description,
            havePoints: !!item.havePoints,
            rubricCriteria: item.levels?.map((itemChild) => ({
              ...itemChild,
              criteriaTitle: itemChild.levelTitle,
              description: itemChild.description,
              element: itemChild.element,
              hasPoint: !!item.havePoints,
              points: Number(itemChild.points) || 0,
            })),
          }))
        : [];

      const rubricPayload = {
        rubricTitle: payload.rubric.rubricTitle,
        rubricItems,
      };

      if (rubricItems.length) {
        try {
          await axios.put(
            `${url}/api/rubric/update-rubric-items-criteria/${payload.rubric.rubricId}${user.slug}`,
            rubricPayload,
            {
              headers: { Authorization: `Bearer ${user.accessToken}` },
            }
          );
        } catch (error) {
          console.log(error);
        }
      }
    } else {
      if (payload.rubric?.rubricItems?.length) {
        let rubricId = await createRubricHandler({ rubric: payload.rubric });
        payload.rubricBankId = rubricId || null;
      } else if (
        payload.rubric?.rubricId &&
        payload.rubric.rubricId.length > 0
      ) {
        await deleteRubricHandler(payload.rubric.rubricId);
        payload.rubricBankId = null;
      }
    }

    try {
      let finalPayload = {
        title: payload.assignmentTitle,
        instruction: payload.instructionStyle.instructionHtmlString,
        subjectId: showLesson?.Title,
        lessonId: selectedLesson.Title,
        createdBy: user.mail || user.username,
        markingType: Number(payload.markingType) || 0,
        studentIds: payload.assignmentFor?.filter((item) => item) || [],
        dueDate: moment(payload.dueDate).isValid()
          ? moment(
              moment(payload.dueDate).format("YYYY-MM-DD") + " 23:59:59"
            ).format("YYYY-MM-DDTHH:mm:ssZ")
          : null,
        pusblishedDate:
          status === "Published"
            ? moment(new Date()).format("YYYY-MM-DDTHH:mm:ssZ")
            : null,
        scheduledOn:
          status === "Scheduled"
            ? publishLaterDate
              ? moment(publishLaterDate).format("YYYY-MM-DDTHH:mm:ssZ")
              : selectedLesson?.LessonDate
            : null,
        creationStatus: status,
        rubricBankId: payload.rubricBankId,
        role: user.role.toLowerCase(),
      };

      let formData = new FormData();

      // Append each field in finalPayload to formData
      Object.keys(finalPayload).forEach((key) => {
        formData.append(key, JSON.stringify(finalPayload[key]));
      });

      // Handle attachments
      if (payload.attachments.length) {
        let attachments = payload.attachments;
        for (const media of attachments) {
          if (media.type === "file") {
            if (media.info?.length) {
              for (const fileItem of media.info) {
                formData.append("file", fileItem.file);
              }
            }
          } else if (media.type === "video") {
            if (media.info) {
              formData.append("video", media.info);
            }
          } else if (media.type === "voice") {
            if (media.info) {
              formData.append("voice", media.info);
            }
          } else if (media.type === "text") {
            if (media.info?.length) {
              formData.append("text", media.info);
            }
          } else if (media.type === "link") {
            if (media.info?.length) {
              formData.append("link", JSON.stringify(media.info));
            }
          }
        }
      }

      let response = await axios({
        method: "PUT",
        url: `${url}/api/assignment/update-assingment${user.slug}&id=${currentAssignmentData.id}`,
        data: formData,
        headers: {
          Authorization: `Bearer ${user.accessToken}`,
          "Content-Type": "multipart/form-data",
        },
      });

      if (response.data?.status === "Success") {
        setCurrentAssignmentData((prevData) => ({
          ...prevData,
          isChanged: false,
        }));
        NotificationAlert(response.data.message, "success");
        setRefreshAssignmentList(!refreshAssignmentList);
        setIsCreateUpdateAssignmentLoading(false);
      } else {
        setIsCreateUpdateAssignmentLoading(false);
      }
    } catch (error) {
      console.log("error", error);
      setIsCreateUpdateAssignmentLoading(false);
    } finally {
      setCurrentPage("assignmentHome");
      setCurrentExCard(null);
      props.history.push({ pathname: "/assignments" });
    }
  };

  const onAssignmentDeleteConfirm = async () => {
    setDeleteAssignmentConfirm(false);
    setIsCreateUpdateAssignmentLoading(true);
    try {
      let response = await axios({
        method: "delete",
        url: `${url}/api/assignment/delete-assingment${user.slug}&id=${currentAssignmentData.id}`,
        headers: {
          Authorization: "Bearer " + user.accessToken,
        },
      });
      if (response.data?.status === "success") {
        setCurrentExCard(null);
        NotificationAlert("Assignment Deleted", "warning");
        setRefreshAssignmentList(!refreshAssignmentList);
        setCurrentPage("assignmentHome");
        props.history.push({
          pathname: "/assignments",
        });
      }
    } catch (error) {
      console.log("error", error);
    } finally {
      setIsCreateUpdateAssignmentLoading(false);
    }
  };

  const uploadImageCallBack_ = async (file) => {
    const uploadFileToBlob = async (file) => {
      try {
        let storagePath = `teacher-${user.mail}-${Date.now()}`;
        const containerName = `assignments`;
        const storageAccountName = process.env.REACT_APP_STORAGE;
        const sasToken = process.env.REACT_APP_AZURE_SAS_TOKEN;

        if (!file) return [];

        const blobService = new BlobServiceClient(
          `https://${storageAccountName}.blob.core.windows.net/${sasToken}`
        );

        const containerClient = blobService.getContainerClient(containerName);

        const blobClient = containerClient.getBlockBlobClient(storagePath);

        const option = { blobHTTPHeaders: { blobContentType: file.type } };

        let response = await blobClient.uploadBrowserData(file, option);

        return response;
      } catch (error) {
        console.log(error);
      }
    };

    return new Promise(async (resolve, reject) => {
      if (file.size / 1048576 > 5) {
        NotificationAlert("Image size must be less than 5 MB", "warning");
        reject({ data: { link: null } });
      } else {
        let response = await uploadFileToBlob(
          file,
          `teacher-${user.mail}-${Date.now()}`
        );
        if (response?._response?.status === 201) {
          resolve({ data: { link: response._response.request.url } });
        } else {
          reject({ data: { link: null } });
        }
      }
    });
  };
  const uploadImageCallBack = async (file) => {
    const uploadFileToBlob = async (file) => {
      try {
        let formData = new FormData();
        formData.append("image", file);
        let response = await axios.post(
          `${url}/api/assignment/upload-image${user.slug}`,
          formData,
          {
            headers: {
              Authorization: `Bearer ${user.accessToken}`,
            },
          }
        );
        return response?.data || {};
      } catch (error) {
        console.log(error);
      }
    };

    return new Promise(async (resolve, reject) => {
      if (file.size / 1048576 > 5) {
        NotificationAlert("Image size must be less than 5 MB", "warning");
        reject({ data: { link: null } });
      } else {
        let response = await uploadFileToBlob(
          file,
          `teacher-${user.mail}-${Date.now()}`
        );

        if (response?.status === "Success") {
          console.log("url", response.url);
          resolve({ data: { link: response.url } });
        } else {
          reject({ data: { link: null } });
        }
      }
    });
  };

  const getAssignmentCreationStatus = () => {
    //style current lesson
    const isCurrentLesson =
      moment().format("YYYY-MM-DD") ===
      moment(selectedLesson?.LessonDate).format("YYYY-MM-DD");

    const creationStatus = translation.creationStatus;
    let localCreationStatus = assignmentCreationStatus.map((item) => {
      switch (item.key) {
        case "Published":
          return { ...item, content: creationStatus.published };
        case "Draft":
          return { ...item, content: creationStatus.draft };
        case "Scheduled":
          return { ...item, content: creationStatus.scheduled };
      }
    });
    setFormattedCreationStatus(
      localCreationStatus.map((item) =>
        isCurrentLesson
          ? item.key === "Scheduled"
            ? { ...item, styles: { display: "none" } }
            : item
          : item.key === "Published"
          ? { ...item, styles: { display: "none" } }
          : item
      )
    );
  };

  return (
    <>
      {!isGradingPage && !isSubmissionsPage && (
        <CustomProgressIndicator
          isLoading={isCreateUpdateAssignmentLoading}
          style={{
            progressTrack: {
              backgroundColor: "#ffffff",
            },
          }}
        />
      )}
      <div>
        <ConfirmDialog
          isConfirmDelete={deleteAssignmentConfirm}
          setisConfirmDelete={setDeleteAssignmentConfirm}
          onConfirm={() => onAssignmentDeleteConfirm()}
          onCancel={() => setDeleteAssignmentConfirm(false)}
          header={assignmentDetailsTranslation.deleteConfirmation}
          confirmButton={assignmentDetailsTranslation.deleteConfirm}
          cancelButton={assignmentDetailsTranslation.deleteCancel}
        />
        <ConfirmDialog
          isConfirmDelete={confirmUpdateChanges}
          setisConfirmDelete={setConfirmUpdateChanges}
          onConfirm={(val) => {
            updateAssignmentHandler({ status: "Published" });
          }}
          onCancel={(val) => setConfirmUpdateChanges(val)}
          header={assignmentDetailsTranslation.updateConfirmation}
          confirmButton={assignmentDetailsTranslation.updateConfirm}
          cancelButton={assignmentDetailsTranslation.updateCancel}
        />
        <Dialog
          open={publishLaterState}
          cancelButton={translation.cancel}
          confirmButton={translation.done}
          onCancel={() => setPublishLaterState(false)}
          onConfirm={() => {
            setCurrentAssignmentData((prevData) => ({
              ...prevData,
              publishedDate: publishLaterDate,
              isChanged: true,
            }));

            if (currentAssignmentData.id) {
              updateAssignmentHandler({ status: "Scheduled" });
            } else {
              createAssignmentHandler({ status: "Scheduled" });
            }
            setPublishLaterState(false);
          }}
          content={
            <>
              <DatePicker
                className="date-picker-width"
                value={
                  currentAssignmentData?.status === "later"
                    ? currentAssignmentData?.publishedDate
                    : publishLaterDate
                }
                onSelectDate={(value) => {
                  setPublishLaterDate(value);
                }}
                strings={defaultDatePickerStrings}
                minDate={
                  new Date(
                    moment(selectedLesson?.LessonDate).format("MM-DD-YYYY")
                  )
                }
                maxDate={
                  new Date(
                    moment(selectedLesson?.LessonDate).format("MM-DD-YYYY")
                  )
                }
              />
            </>
          }
          header={assignmentDetailsTranslation.publishLaterHeader}
          headerAction={{
            icon: <CloseIcon />,
            title: translation.close,
            onClick: () => {
              setPublishLaterState(false);
            },
          }}
        />
        {isMobileView || isTabletView ? (
          <>
            {!isGradingPage && !isSubmissionsPage ? (
              <Flex
                space="between"
                wrap
                className="create-assignment-title-button-wrapper mt-4"
              >
                <Flex gap="gap.small" vAlign="center">
                  <ArrowLeftIcon
                    title={translation.back}
                    className="ml-3"
                    onClick={() => {
                      setCurrentExCard(null);
                      setCurrentPage("assignmentHome");
                      props.setIsCreatingNew(false);
                      // props.history.push({
                      //   pathname: "/assignments",
                      // });
                    }}
                    style={{ cursor: "pointer" }}
                  />
                  <Text weight="bold" size="large">
                    {!currentAssignmentData?.isNew
                      ? selectedLesson.ClassName?.split("-")[0] || ""
                      : assignmentDetailsTranslation.newAssignment}
                  </Text>
                </Flex>

                {currentAssignmentData.status === "Published" ? (
                  <Flex
                    gap="gap.small"
                    wrap
                    hAlign="center"
                    style={{
                      rowGap: "10px",
                      padding: isMobileView ? "0 15px" : "",
                    }}
                  >
                    <Button
                      className={`tour-view-submission-button ${
                        isSubmissionsPage ? "btn-bg-primary" : ""
                      }`}
                      content={assignmentDetailsTranslation.submissionsBtn}
                      onClick={() => {
                        setIsSubmissionsPage(true);
                      }}
                    />
                    <Button
                      className={!isSubmissionsPage ? "btn-bg-primary" : ""}
                      content={assignmentDetailsTranslation.assignmentDetailBtn}
                      onClick={() => {
                        setIsSubmissionsPage(false);
                      }}
                      style={{ color: "white" }}
                    />
                  </Flex>
                ) : (
                  <SplitButton
                    className="tour-create-assignment-publish-draft"
                    style={{ display: "flex", gap: "6px", right: "2%" }}
                    menu={formattedCreationStatus}
                    button={{
                      content:
                        assignmentDetailsTranslation.createAssignmentOptions
                          .publishNow,
                      "aria-roledescription": "splitbutton",
                      "aria-describedby": "instruction-message-primary-button",
                      disabled:
                        moment().format("YYYY-MM-DD") !==
                          moment(selectedLesson?.LessonDate).format(
                            "YYYY-MM-DD"
                          ) || isCreateUpdateAssignmentLoading,
                    }}
                    primary
                    toggleButton={{
                      "aria-label": assignmentDetailsTranslation.moreOptions,
                    }}
                    onMainButtonClick={() => {
                      if (currentAssignmentData.id) {
                        updateAssignmentHandler({ status: "Published" });
                      } else {
                        createAssignmentHandler({ status: "Published" });
                      }
                    }}
                    onMenuItemClick={(e, data) => {
                      const { index } = data;

                      if (currentAssignmentData.id) {
                        if (index === 0) {
                          updateAssignmentHandler({ status: "Published" });
                        } else if (index === 1) {
                          updateAssignmentHandler({ status: "Draft" });
                        } else if (index === 2) {
                          setPublishLaterState(true);
                          // createAssignmentHandler({ status: "Scheduled" });
                        }
                      } else {
                        if (index === 0) {
                          createAssignmentHandler({ status: "Published" });
                        } else if (index === 1) {
                          createAssignmentHandler({ status: "Draft" });
                        } else if (index === 2) {
                          setPublishLaterState(true);
                          // createAssignmentHandler({ status: "Scheduled" });
                        }
                      }
                    }}
                    disabled={isCreateUpdateAssignmentLoading}
                  />
                )}
              </Flex>
            ) : null}
          </>
        ) : (
          <>
            {!isGradingPage && !isSubmissionsPage ? (
              <Flex
                space="between"
                wrap
                className="create-assignment-title-button-wrapper mt-4"
              >
                <Flex gap="gap.small" vAlign="center">
                  <ArrowLeftIcon
                    title={translation.back}
                    className="ml-3"
                    onClick={() => {
                      setCurrentExCard(null);
                      setCurrentPage("assignmentHome");
                      // props.history.push({
                      //   pathname: "/assignments",
                      // });
                      props.setIsCreatingNew(false);
                    }}
                    style={{ cursor: "pointer" }}
                  />
                  <Text weight="bold" size="large">
                    {!currentAssignmentData?.isNew
                      ? selectedLesson.ClassName?.split("-")[0] || ""
                      : assignmentDetailsTranslation.newAssignment}
                  </Text>
                </Flex>

                {currentAssignmentData.status === "Published" ? (
                  <Flex
                    gap="gap.small"
                    wrap
                    hAlign="center"
                    style={{ rowGap: "10px" }}
                  >
                    <Button
                      className={`tour-view-submission-button ${
                        isSubmissionsPage ? "btn-bg-primary" : ""
                      }`}
                      content={assignmentDetailsTranslation.submissionsBtn}
                      onClick={() => {
                        setIsSubmissionsPage(true);
                      }}
                    />
                    <Button
                      className={!isSubmissionsPage ? "btn-bg-primary" : ""}
                      content={assignmentDetailsTranslation.assignmentDetailBtn}
                      onClick={() => {
                        setIsSubmissionsPage(false);
                      }}
                      style={{ color: "white" }}
                    />
                  </Flex>
                ) : (
                  <SplitButton
                    className="tour-create-assignment-publish-draft"
                    menu={formattedCreationStatus}
                    button={{
                      content:
                        assignmentDetailsTranslation.createAssignmentOptions
                          .publishNow,
                      "aria-roledescription": "splitbutton",
                      "aria-describedby": "instruction-message-primary-button",
                      disabled:
                        moment().format("YYYY-MM-DD") !==
                          moment(selectedLesson?.LessonDate).format(
                            "YYYY-MM-DD"
                          ) || isCreateUpdateAssignmentLoading,
                    }}
                    primary
                    toggleButton={{
                      "aria-label": assignmentDetailsTranslation.moreOptions,
                    }}
                    onMainButtonClick={() => {
                      if (currentAssignmentData.id) {
                        updateAssignmentHandler({ status: "Published" });
                      } else {
                        createAssignmentHandler({ status: "Published" });
                      }
                    }}
                    onMenuItemClick={(e, data) => {
                      const { index } = data;

                      if (currentAssignmentData.id) {
                        if (index === 0) {
                          updateAssignmentHandler({ status: "Published" });
                        } else if (index === 1) {
                          updateAssignmentHandler({ status: "Draft" });
                        } else if (index === 2) {
                          setPublishLaterState(true);
                          // createAssignmentHandler({ status: "Scheduled" });
                        }
                      } else {
                        if (index === 0) {
                          createAssignmentHandler({ status: "Published" });
                        } else if (index === 1) {
                          createAssignmentHandler({ status: "Draft" });
                        } else if (index === 2) {
                          setPublishLaterState(true);
                          // createAssignmentHandler({ status: "Scheduled" });
                        }
                      }
                    }}
                    disabled={isCreateUpdateAssignmentLoading}
                  />
                )}
              </Flex>
            ) : null}
          </>
        )}
        {isGradingPage && !isSubmissionsPage ? (
          <GradingPage
            selectedSubmission={selectedSubmission}
            user={user}
            setState={props.setState}
            settings={props.settings}
          />
        ) : isSubmissionsPage && !isGradingPage ? (
          <StudentSubmissions
            selectedLesson={selectedLesson}
            user={user}
            setSelectedSubmission={setSelectedSubmission}
          />
        ) : (
          <div
            className="ms-Grid"
            style={{
              padding:
                isMobileView || isTabletView ? "15px 15px 0px 15px" : "0 8px",
            }}
          >
            <div className="ms-Grid-row" style={{ marginBottom: "10px" }}>
              <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12">
                <div className="ms-Grid-row">
                  <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12 ms-xl8">
                    <div className="tour-create-assignment-title-instruction">
                      <TextField
                        value={assignmentTitle}
                        onChange={(e, value) => {
                          setAssignmentTitle(value);
                          setCurrentAssignmentData((prevData) => ({
                            ...prevData,
                            assignmentTitle: value,
                            isChanged: true,
                          }));
                        }}
                        style={{ background: "#F5F5F5", borderRadius: "5px" }}
                        borderless={true}
                        label={assignmentDetailsTranslation.assignmentTitle}
                        required
                        errorMessage={
                          errorCheckobj.title
                            ? assignmentTitle?.length
                              ? ""
                              : assignmentDetailsTranslation.assignmentTitleValidation
                            : null
                        }
                      />
                      {/* </div> */}
                      <div>
                        <div className="mt-3 mb-2">
                          <Text
                            className="text-field-padding"
                            weight="semibold"
                          >
                            {assignmentDetailsTranslation.instructions}
                          </Text>
                        </div>

                        <div className="draft-editor">
                          <Editor
                            toolbarClassName="toolbarClassName"
                            wrapperClassName="wrapperClassName"
                            editorClassName="editorClassName"
                            editorState={instruction}
                            onEditorStateChange={onEditorStateChange}
                            // editorState={instruction}
                            // contentState={instruction}
                            wrapperStyle={{ borderRadius: "5px" }}
                            toolbarStyle={{
                              borderRadius: "5px",
                            }}
                            editorStyle={{ minHeight: "100px" }}
                            toolbar={{
                              inline: {
                                options: ["bold", "italic", "underline"],
                              },

                              list: {
                                options: ["unordered", "ordered"],
                              },
                              textAlign: {},
                              link: {},
                              image: {
                                uploadCallback: uploadImageCallBack,
                                urlEnabled: true,
                                uploadEnabled: true,
                                alignmentEnabled: true,
                                previewImage: true,
                                inputAccept:
                                  "image/gif,image/jpeg,image/jpg,image/png,image/svg",
                                alt: { present: false, mandatory: false },
                                defaultSize: {
                                  height: "auto",
                                  width: "auto",
                                },
                              },

                              emoji: {},
                              options: [
                                "inline",
                                "list",
                                "textAlign",
                                "link",
                                "image",
                                "emoji",
                              ],
                            }}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="tour-create-assignment-attachments">
                      <div className="mt-3">
                        <Text weight="semibold">
                          {assignmentDetailsTranslation.addAttachment}
                        </Text>
                      </div>
                      <div>
                        <AttachmentOption
                          attachmentList={attachmentList}
                          setAttachmentList={setAttachmentList}
                          user={props?.user}
                          setSelectedAvatarVideo={setSelectedAvatarVideo}
                          selectedAvatarVideo={selectedAvatarVideo}
                          setState={props.setState}
                          state={props.state}
                          settings={props.settings}
                          selectedExtension={props.selectedExtension}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12 ms-xl4">
                    <div className="tour-create-assignment-students-duedate-grade">
                      <Dropdown
                        placeholder={
                          assignmentDetailsTranslation.assignmentForPlaceholder
                        }
                        label={assignmentDetailsTranslation.assignmentFor}
                        selectedKeys={selectedStudentForSubmission}
                        onChange={onStudentForSubmissionSelectChange}
                        multiSelect
                        options={currentLessionStudentList}
                        errorMessage={
                          errorCheckobj.assignmentFor
                            ? selectedStudentForSubmission?.length
                              ? ""
                              : assignmentDetailsTranslation.assignmentForValidation
                            : ""
                        }
                        required
                      />

                      <div className="mt-3">
                        <DatePicker
                          value={dueDate}
                          onSelectDate={(value) => {
                            setDueDate(value);
                            setCurrentAssignmentData((prevData) => ({
                              ...prevData,
                              dueDate: value,
                              isChanged: true,
                            }));
                          }}
                          label={
                            <Text>
                              {assignmentDetailsTranslation.dueDate}{" "}
                              {!errorCheckobj.dueDate ? (
                                <Text style={{ color: "#a4262c" }}>*</Text>
                              ) : null}
                            </Text>
                          }
                          ariaLabel={
                            assignmentDetailsTranslation.dueDatePlaceholder
                          }
                          strings={{
                            ...defaultDatePickerStrings,
                            isOutOfBoundsErrorMessage:
                              assignmentDetailsTranslation.dueDateValidation,
                          }}
                          placeholder={
                            assignmentDetailsTranslation.dueDatePlaceholder
                          }
                          minDate={
                            new Date(
                              moment
                                .utc(selectedLesson?.LessonDate)
                                .local()
                                .format("MM-DD-YYYY")
                            )
                          }
                          isRequired={errorCheckobj.dueDate}
                        />
                      </div>

                      <div className="mt-3">
                        <TextField
                          value={markingType}
                          onChange={(e, value) => {
                            let marking = value >= 0 ? value : 0;
                            setMarkingType(marking);
                            setCurrentAssignmentData((prevData) => ({
                              ...prevData,
                              markingType: marking,
                              isChanged: true,
                            }));
                          }}
                          style={{ background: "#F5F5F5", borderRadius: "5px" }}
                          borderless={true}
                          label={assignmentDetailsTranslation.grade}
                          placeholder={
                            assignmentDetailsTranslation.gradePlaceholder
                          }
                          type="number"
                        />
                      </div>
                    </div>
                    <div className="mt-4">
                      <Flex
                        wrap
                        gap="gap.medium"
                        className="assignment-action-button-wrapper"
                      >
                        <Flex.Item>
                          <Button
                            content={
                              props.data?.rubric?.rubricItems?.length ||
                              currentAssignmentData?.rubric?.rubricItems?.length
                                ? assignmentDetailsTranslation.viewRubric
                                : assignmentDetailsTranslation.createRubric
                            }
                            className="tour-create-assignment-rubric"
                            onClick={() => {
                              // props.history.push({
                              //   pathname: "/assignments/assignmnet/rubric",
                              // });
                              props.setState({ isCreatingRubric: true });
                            }}
                          />
                        </Flex.Item>
                        <Flex.Item>
                          {props.data ||
                          (!currentAssignmentData?.isNew &&
                            (currentAssignmentData?.status == "Published" ||
                              currentAssignmentData?.status == "save" ||
                              currentAssignmentData?.status ==
                                "Publish Later")) ? (
                            <Button
                              className={`delete-btn-bg ${
                                isCreateUpdateAssignmentLoading
                                  ? "disabled"
                                  : ""
                              }`}
                              content={
                                assignmentDetailsTranslation.deleteAssignment
                              }
                              onClick={() => {
                                setDeleteAssignmentConfirm(true);
                              }}
                              disabled={isCreateUpdateAssignmentLoading}
                            />
                          ) : null}
                        </Flex.Item>
                        {currentAssignmentData?.status === "Published" ? (
                          <Flex.Item>
                            <Button
                              content={
                                assignmentDetailsTranslation.updateChanges
                              }
                              onClick={() => {
                                setConfirmUpdateChanges(true);
                              }}
                              disabled={
                                !currentAssignmentData.isChanged ||
                                isCreateUpdateAssignmentLoading
                              }
                            />
                          </Flex.Item>
                        ) : null}
                      </Flex>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default withTranslation()(CreateNewAssignment);
