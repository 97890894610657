import React from "react";

function AvailbleClass({
  isCompactMode,
  state,
  myCard,
  isMobileView,
  isFullscreen,
}) {
  return (
    <div
      className={`${isFullscreen ? "class-wrapper w-125" : "class-wrapper"}`}
    >
      <ul
        className={isCompactMode || isFullscreen ? "pl-10" : "pl-sm-1"}
        style={{ padding: isMobileView && 0 }}
      >
        {state.showLesson && state.showLesson.length
          ? state.showLesson.map((lsn) => myCard(lsn))
          : state.selectedDate !== null
          ? state.showLesson.map((lsn) => myCard(lsn))
          : state.selectedClass !== null
          ? state.showLesson.map((lsn) => myCard(lsn))
          : state.lessions.length
          ? state.lessions.map((lsn) => myCard(lsn))
          : null}
      </ul>
    </div>
  );
}

export default AvailbleClass;
