import React, { useState } from "react";
import { CloseIcon, Dialog, Dropdown, Input } from "@fluentui/react-northstar";
import { useTranslation } from "react-i18next";
import "../index.scss";
import axios from "axios";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import moment from "moment";
import { TextField } from "@mui/material";
import { toast } from "react-toastify";

const UpdateParentModel = (props) => {
  const {
    parentDetails,
    setParentDetails,
    selectedStudents,
    setSelectedStudents,
  } = props;

  const [showError, setShowError] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const status = ["Active", "InActive"];

  const { t } = useTranslation();

  const parentDialog = t("setting").parent.parentDialog;
  const selectedStatus = parentDetails.isActive ? status[0] : status[1];

  const handleUpdateParent = async () => {
    if (
      !!parentDetails.name.trim() &&
      !!parentDetails.email.trim() &&
      !!parentDetails.number &&
      !!parentDetails.expirationDate &&
      selectedStudents.length
    ) {
      setIsLoading(true);
      const payload = {
        parentData: {
          parentName: parentDetails.name,
          parentEmail: parentDetails.email,
          number: parentDetails.number,
          expirationDate: parentDetails.expirationDate,
          isActive: parentDetails.isActive,
        },
        students: selectedStudents.map((student) => student.key),
      };
      try {
        let config = {
          method: "put",
          url: `${process.env.REACT_APP_EP_URL}/api/parents${props.user.slug}`,
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + props.user.accessToken,
          },
          data: payload,
        };

        const response = await axios.request(config);
        props.getAllParents();
        setIsLoading(false);
        setSelectedStudents([]);
        setParentDetails({
          name: "",
          email: "",
          isActive: true,
          number: NaN,
          expirationDate: "",
        });
        toast.success("Parent Updated Successfully!");
        props.onClose();
      } catch (error) {
        toast.error("Parent Update Failed!");
        setIsLoading(false);
        console.log(error);
      }
    } else {
      setShowError(true);
    }
  };

  return (
    <Dialog
      open={props.isOpen}
      content={
        <div className="upload-single-parent-model-content">
          <div style={{ width: "100%" }}>
            <p className="sub-title">{parentDialog.parentName.title}</p>
            <Input
              placeholder={parentDialog.parentName.placeholder}
              name="parentName"
              className="parent-input"
              value={parentDetails.name}
              onChange={(e) => {
                setParentDetails({ ...parentDetails, name: e.target.value });
              }}
              status={
                showError ? (!parentDetails.name?.length ? "error" : "") : ""
              }
              style={{ width: "100%" }}

              // disabled={isLoading}
            />
            {showError ? (
              !parentDetails.name?.length ? (
                <p style={{ color: "red", fontSize: "13px" }}>
                  {parentDialog.parentName.validationMsg}
                </p>
              ) : (
                ""
              )
            ) : (
              ""
            )}
          </div>
          <div style={{ width: "100%" }}>
            <p className="sub-title">{parentDialog.parentNumber.title}</p>
            <Input
              placeholder={parentDialog.parentNumber.placeholder}
              name="parentNumber"
              type="number"
              className="parent-input"
              value={parentDetails.number}
              onChange={(e) => {
                setParentDetails({ ...parentDetails, number: e.target.value });
              }}
              status={
                showError ? (!parentDetails.number?.length ? "error" : "") : ""
              }
              // disabled={isLoading}
              style={{ width: "100%" }}
            />
            {showError ? (
              !parentDetails.number?.length ? (
                <p style={{ color: "red", fontSize: "13px" }}>
                  {parentDialog.parentNumber.validationMsg}
                </p>
              ) : (
                ""
              )
            ) : (
              ""
            )}
          </div>
          <div style={{ width: "100%" }}>
            <p className="sub-title">{parentDialog.expiryDate.title}</p>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DatePicker
                value={
                  !!parentDetails.expirationDate
                    ? moment(parentDetails.expirationDate)
                    : null
                }
                disablePast
                disabled={isLoading}
                onChange={(v) => {
                  const date = moment.utc(moment(v.$d).format("YYYY-MM-DD"));
                  setParentDetails({
                    ...parentDetails,
                    expirationDate: date,
                  });
                }}
                renderInput={(params) => (
                  <TextField
                    disabled={isLoading}
                    {...params}
                    size="small"
                    variant="outlined"
                    value={
                      parentDetails.expirationDate &&
                      moment
                        .utc(moment(parentDetails.expirationDate))
                        .format("YYYY-MM-DD")
                    }
                    inputProps={{ style: { fontSize: 13 } }}
                    error={showError ? !parentDetails.expirationDate : false}
                  />
                )}
              />
            </LocalizationProvider>
            {/* <Input
              placeholder={"Enter Parent Number"}
              name="parentNumber"
              type="number"
              className="parent-input"
              value={parentDetails.number}
              onChange={(e) => {
                setParentDetails({ ...parentDetails, number: e.target.value });
              }}
              status={
                showError ? (!parentDetails.number?.length ? "error" : "") : ""
              }
              // disabled={isLoading}
              style={{ width: "100%" }}
            /> */}
            {showError ? (
              !parentDetails.expirationDate ? (
                <p style={{ color: "red", fontSize: "13px" }}>
                  {parentDialog.expiryDate.validationMsg}
                </p>
              ) : (
                ""
              )
            ) : (
              ""
            )}
          </div>
          <div>
            <p className="sub-title">Students</p>

            {props.studentsWithoutParents.length > 0 && (
              <Dropdown
                disabled={false}
                search
                multiple
                fluid
                items={props.studentsWithoutParents}
                placeholder={"Select Students"}
                value={selectedStudents}
                defaultValue={selectedStudents}
                getA11ySelectionMessage={{
                  onAdd: (item) => {
                    let clonedSelectedStudents = [...selectedStudents];
                    clonedSelectedStudents.push(item);
                    setSelectedStudents(clonedSelectedStudents);
                    let clonedStudentsWithoutParents =
                      props.studentsWithoutParents.filter(
                        (s) => s?.key && s.key !== item.key
                      );
                    props.setStudentsWithoutParents(
                      clonedStudentsWithoutParents
                    );
                  },
                  onRemove: (item) => {
                    const updatedlist = selectedStudents.filter(
                      (s) => s?.key && s.key !== item.key
                    );
                    setSelectedStudents(updatedlist);
                    let clonedStudentsWithoutParents = [
                      item,
                      ...props.studentsWithoutParents,
                    ];
                    props.setStudentsWithoutParents(
                      clonedStudentsWithoutParents
                    );
                  },
                }}
                noResultsMessage={"No results"}
                className="select-students-dropdown"
              />
            )}
            {showError ? (
              selectedStudents.length === 0 ? (
                <p style={{ color: "red", fontSize: "13px" }}>
                  {"Select atLeast one student."}
                </p>
              ) : (
                ""
              )
            ) : (
              ""
            )}
          </div>
          <div>
            <p className="sub-title">{parentDialog.status.title}</p>

            {status.length > 0 && (
              <Dropdown
                disabled={false}
                // multiple
                fluid
                items={status}
                placeholder={parentDialog.status.placeholder}
                value={selectedStatus}
                onChange={(e, { value }) => {
                  setParentDetails({
                    ...parentDetails,
                    isActive: value.toLowerCase() === "active" ? true : false,
                  });
                }}
                noResultsMessage={"No results"}
                className="select-status-dropdown"
              />
            )}
            {!parentDetails.isActive && (
              <p
                style={{ color: "orange", fontSize: "13px", paddingTop: "5px" }}
              >
                {parentDialog.status.warningMsg}
              </p>
            )}
            {showError ? (
              !parentDetails.provider.trim() ? (
                <p style={{ color: "red", fontSize: "13px" }}>
                  {parentDialog.status.validationMsg}
                </p>
              ) : (
                ""
              )
            ) : (
              ""
            )}
          </div>
        </div>
      }
      header={parentDialog.UpdateParentTitle}
      className="smallDlg"
      confirmButton={
        isLoading
          ? {
              text: parentDialog.UpdateButton,
              disabled: true,
              loading: true,
            }
          : parentDialog.UpdateButton
      }
      cancelButton={
        isLoading
          ? { text: parentDialog.cancelButton, disabled: true }
          : parentDialog.cancelButton
      }
      onConfirm={handleUpdateParent}
      onCancel={props.onClose}
      headerAction={{
        icon: <CloseIcon />,
        title: "Close",
        onClick: props.onClose,
        disabled: isLoading,
      }}
    />
  );
};

export default UpdateParentModel;
