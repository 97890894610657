import React from "react";
import { Button, Flex } from "@fluentui/react-northstar";
import moment from "moment";
import ReactExport from "react-export-excel";
import { withTranslation } from "react-i18next";

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;

const all = "all";
const any = "ANY";
const text = "TEXT";
const audio = "AUDIO";
const video = "VIDEO";
const poll = "POLL";

const FeedbackResponseExcelExport = ({ feedbackData, t }) => {
  const translation = t("feedbackModule");

  const feedbackTranslation = translation.body.feedbackResponseCard;
  const ExcelFileTranslation = feedbackTranslation.excelFile;

  return (
    <>
      <ExcelFile
        filename={ExcelFileTranslation.fileName}
        element={
          <Flex column hAlign="center">
            <Button primary content={feedbackTranslation.exportToExcel} />
          </Flex>
        }
      >
        {feedbackData && (
          <ExcelSheet
            dataSet={[
              {
                columns: [],
                data: [
                  [
                    {
                      value: ExcelFileTranslation.title,
                      style: { font: { bold: true }, width: 200 },
                    },
                    ,
                    feedbackData.title,
                  ],
                  [
                    {
                      value: ExcelFileTranslation.totalResponse,
                      style: { font: { bold: true } },
                    },
                    ,
                    feedbackData.totalResponse,
                  ],
                  [
                    {
                      value: ExcelFileTranslation.responseRecieved,
                      style: { font: { bold: true } },
                    },
                    ,
                    feedbackData.response?.length ?? null,
                  ],
                  [
                    {
                      value: ExcelFileTranslation.feedbackCreationDate,
                      style: { font: { bold: true } },
                    },
                    ,
                    feedbackData.dateCreated &&
                    moment(feedbackData.dateCreated).isValid()
                      ? moment(feedbackData.dateCreated).format(
                          "DD-MM-yyyy hh:mm"
                        )
                      : null,
                  ],
                ],
              },
              feedbackData?.response?.length > 0
                ? {
                    ySteps: 2,
                    columns: [
                      ExcelFileTranslation.email,
                      ExcelFileTranslation.name,
                      ExcelFileTranslation.responseDateTime,
                      ExcelFileTranslation.response,
                    ],
                    data: feedbackData.response.map((response) => [
                      response.studentEmailId,
                      response.name,
                      response.submitDate &&
                      moment(response.submitDate).isValid()
                        ? moment(response.submitDate).format("DD-MM-yyyy hh:mm")
                        : null,
                      feedbackData.feedbackType === text
                        ? response.textResponse
                        : feedbackData.feedbackType === poll
                        ? response.pollResponse
                        : null,
                    ]),
                  }
                : { columns: [], data: [] },
            ]}
            name={ExcelFileTranslation.sheetName}
          ></ExcelSheet>
        )}
      </ExcelFile>
    </>
  );
};

export default withTranslation()(FeedbackResponseExcelExport);
