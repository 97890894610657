import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

export const NotificationAlert = (msg, type) => {
  toast[type](msg, {
    position: "bottom-center",
    autoClose: 5000,
    hideProgressBar: true,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
  });
};
