import React from "react";
import ReactExport from "react-export-excel";
import axios from "axios";
import { Button, DownloadIcon } from "@fluentui/react-northstar";
import { useTranslation } from "react-i18next";

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;
const url = process.env.REACT_APP_EP_URL;

const DownloadCFTemplate = (props) => {
  const { t } = useTranslation();
  const [data, setData] = React.useState([]);
  const buttonRef = React.useRef(null);

  let customFields = props?.CustomFields.map((field) => field.Name);

  function onSubmit() {
    axios
      .get(`${url}/api/startup/get-student-email-CF${props.data.user.slug}`)
      .then((res) => {
        setData(res.data.studentData);
        buttonRef.current.click();
      });
  }
  document.querySelector(".rm-parent")?.parentElement.classList.add("d-cnt");
  return (
    <>
      <Button
        icon={<DownloadIcon />}
        title="Download CustomFields Data"
        content={t("startUp").controls.action[0]}
        className="i-btn"
        onClick={onSubmit}
      />
      <ExcelFile
        filename="CustomFieldsData"
        element={<div ref={buttonRef} className="rm-parent" />}
      >
        {data && (
          <ExcelSheet data={data} name="Student CustomFields Data">
            <ExcelColumn
              label="StudentEmailID"
              value={(col) => col.StudentEmailID}
            />

            {Array.from({ length: props?.CustomFields.length }, (_, index) => (
              <ExcelColumn
                label={customFields[index]}
                value={(col) => col[`CF_${index + 1}`]}
              />
            ))}
          </ExcelSheet>
        )}
      </ExcelFile>
    </>
  );
};

export default DownloadCFTemplate;
