import React, { useEffect, useState } from "react";
import axios from "axios";
import FeedbackForm from "./Feedback";

import CustomProgressIndicator from "../../../../components/Loader/CustomProgressIndicator";

const url = process.env.REACT_APP_EP_URL;

const FeedbackWrapper = (props) => {
  const { type, user } = props;
  const [groups, setGroups] = useState([]);
  const [groupNames, setGroupNames] = useState([]);
  const [filteredGroups, setFilteredGroups] = useState([]);
  const [classrooms, setClassrooms] = useState([]);
  const [filteredClassRooms, setFilteredClassrooms] = useState([]);
  const [classroomNames, setClassroomNames] = useState([]);
  const [isLoading, setIsLoading] = useState({
    groupClass: false,
    feedback: false,
  });

  const getGroups = async () => {
    try {
      const { data } = await axios({
        method: "GET",
        url: `${url}/api/feedback/group/get-group-by-userid/${user.mail}${user.slug}`,
        headers: {
          Authorization: `Bearer ${user.accessToken}`,
        },
      });
      if (Array.isArray(data?.result) && data.result.length) {
        const groupNames = data.result.map(({ groupName }) => groupName)
        setGroupNames(groupNames)
        let formattedData = data.result.map(
          ({ groupId, groupImage, groupName, GroupUsers, ...rest }) => {
            return {
              ...rest,
              id: groupId,
              groupTitle: groupName,
              students: GroupUsers?.length
                ? GroupUsers.map(({ users, ...rest }) => {
                  let { email, firstName, lastName, photo } = users ?? {};

                  return {
                    ...rest,
                    studentEmailId: email,
                    name: `${firstName} ${lastName ?? ""}`,
                    imageUrl: photo,
                  };
                })
                : [],
              groupsImageUrl: groupImage,
            };
          }
        );
        setGroups(formattedData);
        setFilteredGroups(formattedData)
      } else {
        setGroups([]);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const getClassrooms = async () => {
    try {
      const { data } = await axios({
        url: `${url}/api/classroom${user.slug}`,
        headers: {
          Authorization: `Bearer ${user.accessToken}`,
        },
      });
      if (data?.result?.length) {
        const classroomNames = data.result.map(({ ClassName }) => ClassName)
        setClassroomNames(classroomNames)
        const classRooms = data.result.map(
          ({ ClassID, ClassName, StudentData, ImageURL, ...rest }) => ({
            ...rest,
            id: ClassID,
            classroomTitle: ClassName,
            students: StudentData?.length
              ? StudentData.map(
                ({ firstName, lastName, email, photo, ...rest }) => ({
                  ...rest,
                  studentEmailId: email,
                  name: `${firstName}${lastName ?? ""}`,
                  imageUrl: photo,
                })
              )
              : [],

            classroomsImageUrl: ImageURL,
          })
        );
        setClassrooms(
          classRooms
        );
        setFilteredClassrooms(classRooms)
      } else {
        setClassrooms([]);
      }
    } catch (error) {
      console.log("error", error);
    }
  };

  const getData = async () => {
    try {
      setIsLoading((prev) => ({ ...prev, groupClass: true }));
      await getGroups();
      if (user?.role?.toLowerCase() !== "student") await getClassrooms();
    } catch (error) {
      console.log(error);
    } finally {
      setIsLoading((prev) => ({ ...prev, groupClass: false }));
    }
  };

  useEffect(() => {
    getData();
  }, []);

  const handleFilterClassrooms = (selectedClass) => {
    if (selectedClass) {
      const filteredClassRooms = classrooms.filter((classroom) => classroom.classroomTitle.includes(selectedClass))
      setFilteredClassrooms(filteredClassRooms)
    } else {
      setFilteredClassrooms(classrooms)
    }
  }
  const handleFilterGroups = (selectedgroup) => {
    if (selectedgroup) {
      const filteredGroups = groups.filter((group) => group.groupTitle.includes(selectedgroup))
      setFilteredGroups(filteredGroups)
    } else {
      setFilteredGroups(groups)
    }
  }

  // const getComponent = () => {
  //   switch (type) {
  //     case "text":
  //       return (
  //         <FeedbackForm {...props} classrooms={classrooms} groups={groups} />
  //       );
  //     case "audio":
  //       return (
  //         <FeedbackForm {...props} classrooms={classrooms} groups={groups} />
  //       );
  //     case "video":
  //       return (
  //         <FeedbackForm {...props} classrooms={classrooms} groups={groups} />
  //       );
  //     case "poll":
  //       return (
  //         <FeedbackForm {...props} classrooms={classrooms} groups={groups} />
  //       );
  //     case "any":
  //       return (
  //         <FeedbackForm {...props} classrooms={classrooms} groups={groups} />
  //       );
  //     default:
  //       return <></>;
  //   }
  // };

  const getComponent = () => {
    // if (type === "poll") {
    //   return (
    //     <PollFeedback {...props} classrooms={classrooms} groups={groups} />
    //   );
    // }
    return (
      <FeedbackForm
        {...props}
        classrooms={classrooms}
        groups={groups}
        isLoadingData={isLoading}
        setIsLoadingData={setIsLoading}
        classroomNames={classroomNames}
        handleFilterClassrooms={handleFilterClassrooms}
        filteredClassRooms={filteredClassRooms}
        filteredGroups={filteredGroups}
        groupNames={groupNames}
        handleFilterGroups={handleFilterGroups}
        setFilteredClassrooms={setFilteredClassrooms}
      />
    );
  };

  return (
    <>
      <CustomProgressIndicator
        isLoading={!isLoading.feedback && isLoading.groupClass}
        style={{
          progressTrack: {
            backgroundColor: "#ffffff",
          },
        }}
      />
      {getComponent()}
    </>
  );
};

export default FeedbackWrapper;
