import React, { Component, useRef } from "react";

import {
  Button,
  Image,
  Segment,
  Dialog,
  Flex,
  Checkbox,
  Datepicker,
  addDays,
  CloseIcon,
} from "@fluentui/react-northstar";

import {
  FilesZipIcon,
  ExcelIcon,
  FilesUploadIcon,
  DownloadIcon,
  ArrowLeftIcon,
  ArrowRightIcon,
  ContactGroupIcon,
  ShiftActivityIcon,
  GeofenceLeavesIcon,
  QuestionCircleIcon,
  ExcelColorIcon,
  FilesImageIcon,
  AcceptIcon,
  EditIcon,
} from "@fluentui/react-northstar";

import * as Icon from "react-feather";

import { withTranslation } from "react-i18next";

import parse from "html-react-parser";

import moment, { months } from "moment";

import { Progress } from "react-sweet-progress";
import { toast } from "react-toastify";

import Background from "../../../../Assets/images/startup-bg.jpg";
import positiveVote from "../../../../Assets/images/positive-vote.png";
import greenTick from "../../../../Assets/images/green-tick.png";
import error from "../../../../Assets/images/svg/error.svg";

import {
  LessonTemplate,
  LessonDayTemplate,
  MembersTemplate,
} from "../../Helper/ExportTemplate";

import { LessonExport } from "../../../../helper/ExportData";

import XLSX from "xlsx";
import XLSXColor from "xlsx-color";
import { saveAs } from "file-saver";

import "react-sweet-progress/lib/style.css";
import "./UploadData.css";
import axios from "axios";

import studentSmpl from "../UploadData/Sample-Zip/student-sample.zip";
import teacherSmpl from "../UploadData/Sample-Zip/teacher-sample.zip";

import { DateRangePicker } from "react-date-range";
import { addMonths } from "date-fns";
import { Link } from "react-router-dom";
import { Stepper, Step, StepLabel } from "@mui/material";

const url = process.env.REACT_APP_EP_URL;
let d = new Date();
const EXCEL_TYPE =
  "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
const EXCEL_EXTENSION = ".xlsx";

class UploadData extends Component {
  constructor(props) {
    super(props);
    this.state = {
      file1: null,
      file2: null,
      file3: null,
      studentZip: null,
      teacherZip: null,
      data1: [],
      data2: [],
      data3: [],
      dialog: false,
      show: 1,
      onSave: false,
      filesSaved: false,
      updatedLessonSaved: false,
      studentZipSaved: false,
      teacherZipSaved: false,
      percent: 0,
      tm: 0,
      progStatus: "progress",
      dayMode: true,
      showUpdateLesson: false,
      showDateRange: false,
      isSmall: false,
      isDayWise: false,
      LessonDate: [
        new Date(d.getFullYear(), d.getMonth() - 2, d.getDate(), 0, 0, 0, 0),
        new Date(),
      ],
      selectionRange: {
        startDate: new Date(),
        endDate: new Date(),
        minDate: new Date(),
        maxDate: addMonths(new Date(), 12),
      },
      isDateRangeSelect: false,
      selectRange: null,
      comparedBothFileData: {
        isEqual: false,
        isOpenModal: false,
        message: "",
        missingRecords: [],
        header: "",
      },
      isCompleteExcelFile: {
        lessonFile: false,
        memberFile: false,
      },
      isUploading: false,
      accDlg: false,
      isSmallScreen: window.matchMedia("(max-width: 989px)").matches,
    };
    this.handleFile = this.handleFile.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.increase = this.increase.bind(this);
    this.handleUpdateFile = this.handleUpdateFile.bind(this);
    this.isCompleteExcelData = this.isCompleteExcelData.bind(this);
    this.downloadExcelWithEmptyFilledData =
      this.downloadExcelWithEmptyFilledData.bind(this);
    this.getStepCurrentDetails = this.getStepCurrentDetails.bind(this);
    this.compareLessonAndMemberData =
      this.compareLessonAndMemberData.bind(this);
    this.handleMediaQueryChange = this.handleMediaQueryChange.bind(this);
  }

  componentDidMount() {
    this.handleResize();
    window
      .matchMedia("(max-width: 989px)")
      .addListener(this.handleMediaQueryChange);
  }

  componentWillUnmount() {
    window
      .matchMedia("(max-width: 989px)")
      .removeListener(this.handleMediaQueryChange);
  }

  handleMediaQueryChange(e) {
    this.setState({ isSmallScreen: e.matches });
  }

  increase() {
    const { percent, tm } = this.state;
    if (percent === 80) {
      if (this.state.progStatus === "success") {
        const newPercent = percent + 20;
        this.setState({ percent: newPercent });
        setTimeout(this.increase, 100);
      } else {
        clearTimeout(this.state.tm);
        return;
      }
    } else if (percent >= 100) {
      this.setState({ progStatus: "success" });
      clearTimeout(this.state.tm);
      return;
    } else {
      const newPercent = percent + 10;
      this.setState({ percent: newPercent });
      setTimeout(this.increase, 100);
    }
  }

  handleChange = async (e) => {
    this.setState({
      isDateRangeSelect: true, // isDateRangeSelect set true beacuse of when select file set default today's date in selected date range
    });

    const { name } = e.target;
    const files = e.target.files;
    if (files && files[0]) {
      this.setState({
        [name]: files[0],
        progStatus: "progress",
        percent: 0,
        filesSaved: false,
      });

      //convert file to json
      let data = await this.convertFileToJson(files[0]);

      if (this.state.show === 1) {
        if (
          data[0].TeacherEmailAlias &&
          data[0].SubjectType &&
          data[0].SubjectName &&
          data[0].SubjectCode
        ) {
          this.setState({
            comparedBothFileData: {
              isEqual: true,
              isOpenModal: false,
              message: "",
              missingRecords: [],
              header: "",
            },
          });
          //validating this excel json data
          const isCompleteExcelData = this.isCompleteExcelData(
            data,
            true,
            false
          );
          this.setState({ lessonData: data });
          if (!isCompleteExcelData) return;
          moment.defaultFormat = "MM/DD/YYYY";
          data = data.map((da) => {
            let StartDate = moment(this.state.selectionRange.startDate);
            let EndDate = moment(this.state.selectionRange.endDate);
            StartDate.set({
              hour: da?.StartTime?.split(":")[0],
              minute: da?.StartTime?.split(":")[1],
              second: 0,
            });
            EndDate.set({
              hour: da?.EndTime?.split(":")[0],
              minute: da?.EndTime?.split(":")[1],
              second: 0,
            });

            return {
              ...da,
              StartDate: StartDate.utc().format(),
              EndDate: EndDate.utc().format(),
              StartTime: StartDate.utc().format("HH:mm"),
              EndTime: EndDate.utc().format("HH:mm"),
            };
          });
          return;
          // }
        } else {
          this.setState({
            comparedBothFileData: {
              isEqual: false,
              isOpenModal: true,
              message:
                "Please check your lesson data Excel file. The format appears to be incorrect, as some fields are missing or improperly formatted.",
              missingRecords: [],
              header: "Incorrect Lesson Data Format in Excel File",
            },
          });
        }
      }

      if (this.state.show === 2) {
        if (
          data[0].SubjectType &&
          data[0].SubjectName &&
          data[0].SubjectCode &&
          data[0].Member_Mail &&
          data[0].Role
        ) {
          this.setState({
            comparedBothFileData: {
              isEqual: true,
              isOpenModal: false,
              message: "",
              missingRecords: [],
              header: "",
            },
          });
          this.setState({ memberData: data });
          const isCompleteExcelData = this.isCompleteExcelData(data);
          if (!isCompleteExcelData) return;
          const isEqual = this.compareLessonAndMemberData(
            this.state.lessonData,
            data
          );
        } else {
          this.setState({
            comparedBothFileData: {
              isEqual: false,
              isOpenModal: true,
              message:
                "Please check your member data Excel file. The format appears to be incorrect, as some fields are missing or improperly formatted.",
              missingRecords: [],
              header: "Incorrect Member Data Format in Excel File",
            },
          });
        }
        // this.onSaveHandler();
      }
    }
  };

  trimValuesOfArrayOfObject = (array) => {
    const trimedData = array.map((object) => {
      let clonedObject = { ...Object };
      Object.entries(object).map((field) => {
        clonedObject[field[0]] = field[1].trim();
      });
      return clonedObject;
    });
    return trimedData;
  };

  convertFileToJson = (file) => {
    if (file) {
      return new Promise((res, rej) => {
        const reader = new FileReader();
        const rABS = !!reader.readAsBinaryString;

        reader.onload = (e) => {
          // Date wise file converting
          const bstr = e.target.result;
          const wb = XLSX.read(bstr, {
            type: rABS ? "binary" : "array",
            bookVBA: true,
            cellDates: true,
          });
          /* Get first worksheet */
          const wsname = wb.SheetNames[0];
          const ws = wb.Sheets[wsname];

          /* Convert array of arrays */
          let data = XLSX.utils.sheet_to_json(ws, {
            defval: "",
          });

          /* Update state */
          res(this.trimValuesOfArrayOfObject(data));
        };
        if (rABS) {
          reader.readAsBinaryString(file);
        }
      });
    }
  };

  isCompleteExcelData = (excelData = []) => {
    let emptyField = [];
    const fieldsForLessonData = [
      /SubjectType/i,
      /SubjectName/i,
      /SubjectCode/i,
      /StartTime/i,
      /EndTime/i,
    ];
    const fieldsForMemberData = [
      /SubjectType/i,
      /SubjectName/i,
      /SubjectCode/i,
    ];
    if (this.state.show === 1) {
      excelData.forEach((data, rowIndex) => {
        Object.entries(data).map((field, columnIndex) => {
          fieldsForLessonData.map((fieldNameRegex) => {
            const regex = new RegExp(fieldNameRegex, "i");
            if (regex.test(field[0]) && !String(field[1]).trim()) {
              emptyField.push({
                rowIndex: rowIndex + 1,
                columnIndex,
              });
            }
          });
        });
      });
      if (emptyField.length > 0) {
        this.setState({
          emptyLessonDataField: emptyField,
          isCompleteExcelFile: {
            ...this.state.isCompleteExcelFile,
            lessonFile: false,
          },
        });
      } else {
        this.setState({
          isCompleteExcelFile: {
            ...this.state.isCompleteExcelFile,
            lessonFile: true,
          },
        });
      }
    }

    if (this.state.show === 2) {
      excelData.forEach((data, rowIndex) => {
        Object.entries(data).map((field, columnIndex) => {
          fieldsForMemberData.map((fieldNameRegex) => {
            const regex = new RegExp(fieldNameRegex, "i");
            if (regex.test(field[0]) && !String(field[1]).trim()) {
              emptyField.push({
                rowIndex: rowIndex + 1,
                columnIndex,
              });
            }
          });
        });
      });
      // if member data is uploading
      if (emptyField.length > 0) {
        this.setState({
          emptyMemberDataField: emptyField,
          isCompleteExcelFile: {
            ...this.state.isCompleteExcelFile,
            memberFile: false,
          },
        });
      } else {
        this.setState({
          isCompleteExcelFile: {
            ...this.state.isCompleteExcelFile,
            memberFile: true,
          },
        });
      }
    }
    if (emptyField.length > 0) {
      this.setState({
        isOpenMissingDataModal: true,
      });
      return false;
    }
    return true;
  };

  getStepCurrentDetails = () => {
    if (this.state.show === 1) {
      return {
        emptyDataField: this.state.emptyLessonDataField,
        fileData: this.state.lessonData,
        name: "Lesson data",
        fileName: "Incomplete-lesson-data",
      };
    }
    if (this.state.show === 2) {
      return {
        emptyDataField: this.state.emptyMemberDataField,
        fileData: this.state.memberData,
        name: "Member data",
        fileName: "Incomplete-member-data",
      };
    }
  };

  downloadExcelWithEmptyFilledData = () => {
    const currentStep = this.getStepCurrentDetails();
    const ws = XLSXColor.utils.json_to_sheet(currentStep.fileData);
    currentStep?.emptyDataField?.map((emptyCell) => {
      let cellAddress = XLSX.utils.encode_cell({
        r: emptyCell.rowIndex,
        c: emptyCell.columnIndex,
      });
      ws[cellAddress].s = {
        border: {
          top: { style: "medium", color: { rgb: "FF0000" } },
          bottom: { style: "medium", color: { rgb: "FF0000" } },
          left: { style: "medium", color: { rgb: "FF0000" } },
          right: { style: "medium", color: { rgb: "FF0000" } },
        },
      };
    });

    const wb = {
      Sheets: {
        data: ws,
      },
      SheetNames: ["data"],
      Workbook: { Views: [{ RTL: false }] },
    };

    const eb = XLSXColor.write(wb, { bookType: "xlsx", type: "array" });
    const blob = new Blob([eb], { type: EXCEL_TYPE });
    saveAs(blob, currentStep.fileName + EXCEL_EXTENSION);
  };

  compareLessonAndMemberData = (lessonData, memberData) => {
    const { t } = this.props;
    if (!(memberData.length > 1)) {
      const { t } = this.props;
      this.setState({
        comparedBothFileData: {
          isOpenModal: true,
          message: t("startUp").missingRecordDialog.noMemberData,
          missingRecords: [],
        },
      });
    }

    const missingRecords = lessonData.filter((lessonItem) => {
      return !memberData.some((memberItem) => {
        return (
          lessonItem?.SubjectName?.trim()?.toLowerCase() ===
            memberItem?.SubjectName?.trim()?.toLowerCase() &&
          lessonItem?.SubjectType?.trim()?.toLowerCase() ===
            memberItem?.SubjectType?.trim()?.toLowerCase() &&
          lessonItem.SubjectCode === memberItem.SubjectCode
        );
      });
    });

    if (missingRecords.length > 0) {
      if (
        !lessonData[0].SubjectType &&
        !lessonData[0].SubjectName &&
        !lessonData[0].SubjectCode &&
        !lessonData[0].TeacherEmailAlias
      ) {
        this.setState({
          comparedBothFileData: {
            isEqual: false,
            isOpenModal: true,
            message:
              "Please check your lesson data Excel file. The format appears to be incorrect, as some fields are missing or improperly formatted.",
            missingRecords: [],
            header: "Incorrect Lesson Data Format in Excel File",
          },
        });
      } else if (
        !memberData[0].SubjectType &&
        !memberData[0].SubjectName &&
        !memberData[0].SubjectCode &&
        !memberData[0].Member_Mail &&
        !memberData[0].Role
      ) {
        this.setState({
          comparedBothFileData: {
            isEqual: false,
            isOpenModal: true,
            message:
              "Please check your member data Excel file. The format appears to be incorrect, as some fields are missing or improperly formatted.",
            missingRecords: [],
            header: "Incorrect Member Data Format in Excel File",
          },
        });
      } else {
        this.setState({
          comparedBothFileData: {
            isEqual: false,
            isOpenModal: true,
            message: t("startUp").missingRecordDialog.message,
            missingRecords: missingRecords,
            header: t("startUp").missingRecordDialog.title,
          },
        });
      }
    } else {
      this.setState({
        comparedBothFileData: {
          isEqual: true,
          isOpenModal: false,
          message: "",
          missingRecords: [],
          header: "",
        },
      });
    }
  };

  handleFile = (updateProgress = true) => {
    if (updateProgress) {
      this.setState({ onSave: true, percent: 0, progStatus: "progress" }, () =>
        this.increase()
      );
    }
    if (this.state.file1 === null || this.state.file2 === null) {
      this.setState({
        dialog: true,
        msg: "Please upload both the files before saving.",
      });
    } else {
      /* Boilerplate to set up FileReader */
      const reader1 = new FileReader();
      const reader2 = new FileReader();
      const rABS1 = !!reader1.readAsBinaryString;
      const rABS2 = !!reader2.readAsBinaryString;

      reader1.onload = (e) => {
        /* Parse data */
        const bstr = e.target.result;
        const wb = XLSX.read(bstr, {
          type: rABS1 ? "binary" : "array",
          bookVBA: true,
          cellDates: true,
        });
        /* Get first worksheet */
        const wsname = wb.SheetNames[0];
        const ws = wb.Sheets[wsname];
        /* Convert array of arrays */
        let data = XLSX.utils.sheet_to_json(ws);
        moment.defaultFormat = "MM/DD/YYYY";

        // data = data.map((da) => {
        //   return {
        //     ...da,
        //     StartDate: moment(
        //       this.state.selectionRange.startDate,
        //       moment.defaultFormat
        //     ).format(),

        //     EndDate: moment(
        //       this.state.selectionRange.endDate,
        //       moment.defaultFormat
        //     ).format(),
        //   };
        // });

        data = data.map((da) => {
          let StartDate = moment(this.state.selectionRange.startDate);
          let EndDate = moment(this.state.selectionRange.endDate);
          StartDate.set({
            hour: da.StartTime.split(":")[0],
            minute: da.StartTime.split(":")[1],
            second: 0,
          });
          EndDate.set({
            hour: da.EndTime.split(":")[0],
            minute: da.EndTime.split(":")[1],
            second: 0,
          });

          return {
            ...da,
            StartDate: StartDate.utc().format(),
            EndDate: EndDate.utc().format(),
            StartTime: StartDate.utc().format("HH:mm"),
            EndTime: EndDate.utc().format("HH:mm"),
          };
        });
        //Remove whitespace from values
        data = this.trimValuesOfArrayOfObject(data);

        this.setState({ data1: data });
      };
      reader2.onload = (e) => {
        /* Parse data */
        const bstr = e.target.result;
        const wb = XLSX.read(bstr, {
          type: rABS2 ? "binary" : "array",
          bookVBA: true,
          cellDates: true,
        });
        /* Get first worksheet */
        const wsname = wb.SheetNames[0];
        const ws = wb.Sheets[wsname];
        /* Convert array of arrays */
        let data = XLSX.utils.sheet_to_json(ws);
        /* Update state */

        // remove whitespace
        data = this.trimValuesOfArrayOfObject(data);

        this.setState({ data2: data });
        this.onSaveHandler();
      };

      if (rABS1) {
        reader1.readAsBinaryString(this.state.file1);
      } else {
        reader1.readAsArrayBuffer(this.state.file1);
      }
      if (rABS2) {
        reader2.readAsBinaryString(this.state.file2);
      } else {
        reader2.readAsArrayBuffer(this.state.file2);
      }
    }
  };

  handleUpdateFile = () => {
    this.setState({ onSave: true, percent: 0, progStatus: "progress" }, () =>
      this.increase()
    );
    if (this.state.file3 === null) {
      this.setState({
        dialog: true,
        msg: "Please upload both the files before saving.",
      });
    } else {
      /* Boilerplate to set up FileReader */
      const reader3 = new FileReader();
      const rABS3 = !!reader3.readAsBinaryString;

      reader3.onload = (e) => {
        /* Parse data */
        const bstr = e.target.result;
        const wb = XLSX.read(bstr, {
          type: rABS3 ? "binary" : "array",
          bookVBA: true,
          cellDates: true,
        });
        /* Get first worksheet */
        const wsname = wb.SheetNames[0];
        const ws = wb.Sheets[wsname];
        /* Convert array of arrays */
        let data = XLSX.utils.sheet_to_json(ws);
        data = this.trimValuesOfArrayOfObject(data);
        this.setState({ data3: data });
        this.onSaveUpdateHandler();
      };

      if (rABS3) {
        reader3.readAsBinaryString(this.state.file3);
      } else {
        reader3.readAsArrayBuffer(this.state.file3);
      }
    }
  };

  checkProgressHandler = () => {
    if (
      (this.state.showUpdateLesson === true &&
        this.state.show === 1 &&
        this.state.updatedLessonSaved) ||
      ((this.state.show === 1 || this.state.show === 2) &&
        this.state.filesSaved) ||
      (this.state.show === 3 && this.state.studentZipSaved) ||
      (this.state.show === 4 && this.state.teacherZipSaved)
    ) {
      this.setState({ percent: 100, progStatus: "success" });
    } else {
      this.setState({ percent: 0, progStatus: "progress", onSave: false });
    }
  };

  datewiseUploadHandler = (postData) => {
    this.setState({
      isUploading: true,
    });

    axios
      .post(
        `${url}/api/startup/populate-record${this.props.data.user.slug}`,
        postData
      )
      .then((res) => {
        const { studentData, teacherData, lessonObj } = res.data;
        this.setState(
          {
            dialog: true,
            msg: `${studentData?.length} Students, ${teacherData?.length} Teachers and ${lessonObj?.length} Lessons has been saved successfully.`,
            onSave: false,
            filesSaved: true,
            progStatus: "success",
            isUploading: false,
          },
          () => {
            this.checkProgressHandler();
          }
        );
      })
      .catch((err) => {
        toast.error(err?.response?.data?.message);
        console.log("err : ", err, err.response.data.message);
        this.setState({
          dialog: true,
          msg: err?.response?.data?.message
            ? err?.response?.data?.message
            : err.message,
          onSave: false,
          progStatus: "error",
          isUploading: false,
        });
      });
  };
  daywiseUploadHandler = (postData) => {
    this.setState({
      isUploading: true,
    });

    const id = toast.loading("Uploading lessons...", {
      theme: "light",
      position: "bottom-right",
    });
    axios
      .post(
        `${url}/api/startup/populate-record-with-multiple-day${this.props.data.user.slug}`,
        postData
      )
      .then((res) => {
        const { studentData, adminData, teacherData, allLessons } = res.data;
        this.setState(
          {
            dialog: true,
            msg: `${studentData} Students, ${teacherData} Teachers, ${adminData} Admins and ${allLessons} Lessons has been saved successfully.`,
            onSave: false,
            filesSaved: true,
            progStatus: "success",
            isUploading: false,
          },
          () => {
            this.checkProgressHandler();
          }
        );
        toast.update(id, {
          render: "Lessons uploaded Successfully!",
          type: "success",
          theme: "light",
          closeButton: true,
          isLoading: false,
          autoClose: 6000,
          pauseOnHover: true,
          progress: undefined,
          hideProgressBar: false,
        });
      })
      .catch((err) => {
        console.log("err", err.response);
        console.log("status", err.response?.status);
        if (err.response?.status === 401 || "undefined") {
          toast.update(id, {
            render: "Lessons uploading Failed!",
            type: "error",
            theme: "light",
            closeButton: true,
            isLoading: false,
            autoClose: 6000,
            pauseOnHover: true,
            progress: undefined,
            hideProgressBar: true,
          });
          this.setState({
            accDlg: true,
            onSave: false,
            progStatus: "error",
            isUploading: false,
          });
        } else {
          toast.update(id, {
            render: "Lessons uploading Failed!",
            type: "error",
            theme: "light",
            closeButton: true,
            isLoading: false,
            autoClose: 6000,
            pauseOnHover: true,
            progress: undefined,
            hideProgressBar: false,
          });
          this.setState({
            dialog: true,
            msg: err?.response?.data?.message
              ? err?.response?.data?.message
              : err.message,
            onSave: false,
            progStatus: "error",
            isUploading: false,
          });
        }
      });
  };
  onSaveHandler = () => {
    const { data1, data2 } = this.state;
    const postData = { lessonData: data1, allMember: data2 };
    this.state.dayMode
      ? this.daywiseUploadHandler(postData)
      : this.datewiseUploadHandler(postData);
  };
  onSaveUpdateHandler = () => {
    const { data3 } = this.state;
    axios
      .post(
        `${url}/api/startup/update-lesson-topic${this.props.data.user.slug}`,
        data3
      )
      .then((res) => {
        this.setState(
          {
            dialog: true,
            msg: `Lessons has been updated successfully.`,
            onSave: false,
            updatedLessonSaved: true,
            progStatus: "success",
          },
          () => {
            this.checkProgressHandler();
          }
        );
      })
      .catch((err) => {
        toast.error(err?.response?.data?.message);
        console.log("err : ", err, err.response.data.message);
        this.setState({
          dialog: true,
          msg: err?.response?.data?.message
            ? err?.response?.data?.message
            : err.message,
          onSave: false,
          progStatus: "error",
        });
      });
  };

  progressRender = () => {
    return (
      <>
        <br />
        <br />
        {this.state.progStatus !== "progress" ? (
          <Progress
            percent={this.state.percent}
            status={this.state.progStatus}
          />
        ) : (
          <Progress percent={this.state.percent} />
        )}
      </>
    );
  };

  handleSelect(e) {
    const { range1 } = e;
    const diff = moment(range1.startDate).diff(moment(range1.endDate), "days");
    const isDayWise = diff !== 0 ? true : false;
    const updatedDate = {
      startDate: range1.startDate,
      endDate: range1.endDate,
      minDate: range1.startDate,
      // maxDate: new Date(moment(range1.startDate).add(1, "months").format()),
      maxDate: addMonths(range1.startDate, 12),
    };
    this.setState({
      selectionRange: updatedDate,
      selectRange: null,
      isDayWise,
    });
  }

  handleSelectMonth(month) {
    this.setState({ selectRange: month });
    const sDate = this.state.selectionRange.startDate;
    const eDate = new Date(moment(sDate).add(month, "months").format());

    const updatedDate = {
      startDate: sDate,
      endDate: eDate,
      minDate: sDate,
      maxDate: this.state.selectionRange.maxDate,
    };
    this.setState({ selectionRange: updatedDate });
  }

  handleResize() {
    if (window.innerWidth < 1000) {
      this.setState({ isSmall: true });
    } else {
      this.setState({ isSmall: false });
    }
  }

  render() {
    const { t } = this.props;
    const { selectionRange } = this.state;

    const GreenTick = (
      <span style={{ marginRight: "-20px" }}>
        {" "}
        <svg
          version="1.1"
          viewBox="0 0 512 512"
          style={{
            width: "20px",
            height: "20px",
            left: "-48px",
            position: "relative",
          }}
        >
          <path
            fill="#049e51"
            d="M489,255.9c0-0.2,0-0.5,0-0.7c0-1.6,0-3.2-0.1-4.7c0-0.9-0.1-1.8-0.1-2.8c0-0.9-0.1-1.8-0.1-2.7  c-0.1-1.1-0.1-2.2-0.2-3.3c0-0.7-0.1-1.4-0.1-2.1c-0.1-1.2-0.2-2.4-0.3-3.6c0-0.5-0.1-1.1-0.1-1.6c-0.1-1.3-0.3-2.6-0.4-4  c0-0.3-0.1-0.7-0.1-1C474.3,113.2,375.7,22.9,256,22.9S37.7,113.2,24.5,229.5c0,0.3-0.1,0.7-0.1,1c-0.1,1.3-0.3,2.6-0.4,4  c-0.1,0.5-0.1,1.1-0.1,1.6c-0.1,1.2-0.2,2.4-0.3,3.6c0,0.7-0.1,1.4-0.1,2.1c-0.1,1.1-0.1,2.2-0.2,3.3c0,0.9-0.1,1.8-0.1,2.7  c0,0.9-0.1,1.8-0.1,2.8c0,1.6-0.1,3.2-0.1,4.7c0,0.2,0,0.5,0,0.7c0,0,0,0,0,0.1s0,0,0,0.1c0,0.2,0,0.5,0,0.7c0,1.6,0,3.2,0.1,4.7  c0,0.9,0.1,1.8,0.1,2.8c0,0.9,0.1,1.8,0.1,2.7c0.1,1.1,0.1,2.2,0.2,3.3c0,0.7,0.1,1.4,0.1,2.1c0.1,1.2,0.2,2.4,0.3,3.6  c0,0.5,0.1,1.1,0.1,1.6c0.1,1.3,0.3,2.6,0.4,4c0,0.3,0.1,0.7,0.1,1C37.7,398.8,136.3,489.1,256,489.1s218.3-90.3,231.5-206.5  c0-0.3,0.1-0.7,0.1-1c0.1-1.3,0.3-2.6,0.4-4c0.1-0.5,0.1-1.1,0.1-1.6c0.1-1.2,0.2-2.4,0.3-3.6c0-0.7,0.1-1.4,0.1-2.1  c0.1-1.1,0.1-2.2,0.2-3.3c0-0.9,0.1-1.8,0.1-2.7c0-0.9,0.1-1.8,0.1-2.8c0-1.6,0.1-3.2,0.1-4.7c0-0.2,0-0.5,0-0.7  C489,256,489,256,489,255.9C489,256,489,256,489,255.9z"
            id="XMLID_3_"
          ></path>
          <g
            fill="none"
            stroke="#FFFFFF"
            strokeWidth="30"
            strokeMiterlimit="10"
          >
            <line x1="213.6" x2="369.7" y1="344.2" y2="188.2"></line>
            <line x1="233.8" x2="154.7" y1="345.2" y2="266.1"></line>
          </g>
        </svg>{" "}
      </span>
    );
    return (
      <>
        <Dialog
          open={this.state.accDlg}
          onConfirm={() => window.location.reload()}
          confirmButton="Refresh Now"
          content={
            <p
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
                fontSize: "18px",
                textAlign: "center",
                marginBottom: "0px",
              }}
            >
              <Image src={error} style={{ width: "200px" }} />
              Slow or no internet connection. Please check your internet
              settings
            </p>
          }
          header={"Network Error"}
          className="internet-connection"
        />
        <Segment
          content={
            <Flex
              gap="gap.small"
              hAlign="center"
              vAlign="center"
              padding="padding.medium"
              wrap
              style={{ flexDirection: "column", marginTop: "-30px" }}
            >
              {this.state.isSmallScreen ? (
                <Stepper activeStep={this.state.show - 1} alternativeLabel>
                  <Step>
                    <StepLabel>{t("startUp").controls.navigation[0]}</StepLabel>
                  </Step>
                  <Step>
                    <StepLabel>{t("startUp").controls.navigation[1]}</StepLabel>
                  </Step>
                  <Step>
                    <StepLabel>{t("startUp").controls.navigation[2]}</StepLabel>
                  </Step>
                  <Step>
                    <StepLabel>{t("startUp").controls.navigation[3]}</StepLabel>
                  </Step>
                  <Step>
                    <StepLabel>{t("startUp").controls.navigation[4]}</StepLabel>
                  </Step>
                </Stepper>
              ) : (
                <Flex gap="gap.small" wrap className="processProgress">
                  <Progress
                    percent={
                      this.state.show === 1
                        ? 10
                        : this.state.show === 2
                        ? 30
                        : 100 - (100 - 20 * this.state.show)
                    }
                    status="default"
                    theme={{
                      default: {
                        color: "#6264a7",
                      },
                    }}
                  />
                  {this.state.isSmall && (
                    <div className="prodgressBtnDiv">
                      <p
                        className={`progress_btn ${
                          this.state.show >= 1 && this.state.file1 && "active"
                        }`}
                      >
                        {t("startUp").controls.navigation[0]}
                      </p>
                      <p
                        className={`progress_btn ${
                          this.state.show >= 2 && this.state.file2 && "active"
                        }`}
                      >
                        {t("startUp").controls.navigation[1]}
                      </p>
                      <p
                        className={`progress_btn ${
                          this.state.show >= 3 &&
                          this.state.studentZip &&
                          "active"
                        }`}
                      >
                        {t("startUp").controls.navigation[2]}
                      </p>
                      <p
                        className={`progress_btn ${
                          this.state.show >= 4 &&
                          this.state.teacherZip &&
                          "active"
                        }`}
                      >
                        {t("startUp").controls.navigation[3]}
                      </p>
                      <p
                        className={`progress_btn ${
                          this.state.show >= 5 && "active"
                        }`}
                      >
                        {t("startUp").controls.navigation[4]}
                      </p>
                    </div>
                  )}
                  {!this.state.isSmall && (
                    <Button
                      primary={
                        this.state.show >= 1 && this.state.file1 ? true : false
                      }
                      content={t("startUp").controls.navigation[0]}
                      icon={<ExcelColorIcon size="larger" />}
                      className="NavBtn"
                    />
                  )}
                  {this.state.filesSaved ? GreenTick : null}
                  {!this.state.isSmall && (
                    <Button
                      primary={
                        this.state.show >= 1 && this.state.file2 ? true : false
                      }
                      content={t("startUp").controls.navigation[1]}
                      icon={<ExcelColorIcon size="larger" />}
                      className="NavBtn"
                    />
                  )}
                  {this.state.filesSaved ? GreenTick : null}
                  {!this.state.isSmall && (
                    <Button
                      primary={
                        this.state.show >= 3 && this.state.studentZip
                          ? true
                          : false
                      }
                      content={t("startUp").controls.navigation[2]}
                      icon={<FilesImageIcon outline size="larger" />}
                      className="NavBtn"
                    />
                  )}
                  {this.state.studentZipSaved ? GreenTick : null}
                  {!this.state.isSmall && (
                    <Button
                      primary={
                        this.state.show >= 4 && this.state.teacherZip
                          ? true
                          : false
                      }
                      content={t("startUp").controls.navigation[3]}
                      icon={<FilesImageIcon outline size="larger" />}
                      className="NavBtn"
                    />
                  )}
                  {this.state.teacherZipSaved ? GreenTick : null}
                  {!this.state.isSmall && (
                    <Button
                      primary={
                        this.state.show >= 5 && this.state.show >= 5
                          ? true
                          : false
                      }
                      content={t("startUp").controls.navigation[4]}
                      icon={<AcceptIcon outline size="large" />}
                      className="NavBtn"
                    />
                  )}
                </Flex>
              )}

              <Segment
                content={
                  <>
                    <Flex
                      gap="gap.small"
                      hAlign="center"
                      vAlign="center"
                      padding="padding.medium"
                      wrap
                    >
                      <Dialog
                        open={this.state.dialog}
                        onConfirm={() => {
                          this.setState({ dialog: false });
                        }}
                        confirmButton={t("startUp").controls.modal[0]}
                        content={this.state.msg}
                        header="File Uploading"
                        className="smallDlg"
                      />
                      {this.state.show === 1 ? (
                        <Flex.Item>
                          <Segment
                            content={
                              !this.state.showUpdateLesson ? (
                                <>
                                  <Segment
                                    content={<ExcelIcon size={"largest"} />}
                                    className="bg-trans"
                                  />
                                  {parse(t("startUp").content[0])}
                                  <div className="fw6 lsn-format-optn">
                                    <span
                                      onClick={() =>
                                        this.setState({ dayMode: false })
                                      }
                                    >
                                      Date Wise Lessons
                                    </span>
                                    <Checkbox
                                      toggle
                                      className="checkSet"
                                      checked={this.state.dayMode}
                                      onChange={(e, { checked }) => {
                                        this.setState({ dayMode: checked });
                                      }}
                                    />
                                    <span
                                      onClick={() =>
                                        this.setState({ dayMode: true })
                                      }
                                    >
                                      Day Wise Lessons
                                    </span>
                                  </div>
                                  <Button
                                    icon={<EditIcon outline />}
                                    content="Update Lessons"
                                    onClick={() =>
                                      this.setState({ showUpdateLesson: true })
                                    }
                                  />
                                  {this.state.dayMode && (
                                    <Dialog
                                      cancelButton="Cancel"
                                      confirmButton={
                                        t("startUp").controls.modal[0]
                                      }
                                      onConfirm={() => {
                                        this.setState({
                                          isDateRangeSelect: true,
                                        });
                                      }}
                                      onCancel={() => {
                                        this.setState({
                                          isDateRangeSelect: false,
                                        });
                                      }}
                                      content={
                                        this.state.showDateRange && (
                                          <DateRangePicker
                                            ranges={[selectionRange]}
                                            onChange={(e) =>
                                              this.handleSelect(e)
                                            }
                                            // showDateDisplay={false}
                                            maxDate={selectionRange.maxDate}
                                          />
                                        )
                                      }
                                      header={
                                        <>
                                          <p>Select A Date Ranges </p>
                                          <Button
                                            primary
                                            style={{
                                              margin: "1px",
                                              position: "relative",
                                            }}
                                            onClick={() =>
                                              this.handleSelectMonth(1)
                                            }
                                            iconPosition="after"
                                          >
                                            <span className="btnFontSize">
                                              Month
                                            </span>
                                            {this.state.selectRange === 1 && (
                                              <span className="greenTick">
                                                <img src={greenTick} />
                                              </span>
                                            )}
                                          </Button>
                                          <Button
                                            primary
                                            style={{
                                              margin: "1px",
                                              position: "relative",
                                            }}
                                            onClick={() =>
                                              this.handleSelectMonth(3)
                                            }
                                          >
                                            <span className="btnFontSize">
                                              3 Month
                                            </span>
                                            {this.state.selectRange === 3 && (
                                              <span className="greenTick">
                                                <img
                                                  // src={thumb}
                                                  src={greenTick}
                                                />
                                              </span>
                                            )}
                                          </Button>
                                          <Button
                                            primary
                                            style={{
                                              margin: "1px",
                                              position: "relative",
                                            }}
                                            onClick={() =>
                                              this.handleSelectMonth(6)
                                            }
                                          >
                                            <span className="btnFontSize">
                                              6 Month
                                            </span>
                                            {this.state.selectRange === 6 && (
                                              <span className="greenTick">
                                                <img
                                                  // src={thumb}
                                                  src={greenTick}
                                                />
                                              </span>
                                            )}
                                          </Button>
                                          <Button
                                            primary
                                            style={{
                                              margin: "1px",
                                              position: "relative",
                                            }}
                                            onClick={() =>
                                              this.handleSelectMonth(12)
                                            }
                                          >
                                            <span className="btnFontSize">
                                              12 Month
                                            </span>
                                            {this.state.selectRange === 12 && (
                                              <span className="greenTick">
                                                <img
                                                  // src={thumb}
                                                  src={greenTick}
                                                />
                                              </span>
                                            )}
                                          </Button>
                                        </>
                                      }
                                      trigger={
                                        <Button
                                          icon={<Icon.Calendar />}
                                          content={
                                            <>
                                              <span className="btnFontSize">
                                                Select Date Range
                                              </span>
                                              {this.state.isDateRangeSelect && (
                                                <span className="greenTick">
                                                  <img src={greenTick} />
                                                </span>
                                              )}
                                            </>
                                          }
                                          onClick={() =>
                                            this.setState({
                                              showDateRange: true,
                                            })
                                          }
                                        />
                                      }
                                      className="rangePicker"
                                    />
                                  )}
                                  {this.state.dayMode ? (
                                    <LessonDayTemplate />
                                  ) : (
                                    <LessonTemplate />
                                  )}
                                  <input
                                    type="file"
                                    id="file-lsn"
                                    name="file1"
                                    onChange={this.handleChange}
                                    style={{ display: "none" }}
                                    accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                                    onClick={(e) => {
                                      e.currentTarget.value = "";
                                    }}
                                  />
                                  <Button
                                    icon={<FilesUploadIcon />}
                                    content={
                                      this.state.file1
                                        ? this.state.file1.name
                                        : t("startUp").controls.action[1]
                                    }
                                    onClick={() =>
                                      document
                                        .getElementById("file-lsn")
                                        .click()
                                    }
                                    title={
                                      this.state.file1
                                        ? this.state.file1.name
                                        : t("startUp").controls.action[1]
                                    }
                                    style={{
                                      maxWidth: "209px",
                                    }}
                                  />
                                  {this.state.file1 && this.state.file2
                                    ? this.progressRender()
                                    : null}
                                </>
                              ) : (
                                <>
                                  <Segment
                                    content={<ExcelIcon size={"largest"} />}
                                    className="bg-trans"
                                  />
                                  <h1>Update Lesson data</h1>
                                  <ul className="Karla list">
                                    <li>
                                      Download the data between Starting date
                                      and Ending date.
                                    </li>
                                    <li>
                                      Upload the data after completion of
                                      modification.
                                    </li>
                                  </ul>
                                  <div className="ctrl">
                                    <b className="fw6">
                                      <Segment
                                        content={
                                          t("AttendanceHistory").controls
                                            .date[0]
                                        }
                                        className="seg1 lh44 mt-0"
                                      />
                                    </b>
                                    <Segment
                                      content={
                                        <Datepicker
                                          inputPlaceholder={
                                            t("AttendanceHistory").controls
                                              .date[0]
                                          }
                                          defaultSelectedDate={
                                            this.state.LessonDate[0]
                                          }
                                          style={{
                                            margin: 0,
                                            marginLeft: "31px",
                                          }}
                                          onDateChange={(e, v) => {
                                            let LessonDate = {
                                              ...this.state.LessonDate,
                                            };
                                            LessonDate[0] = v.value;
                                            this.setState({ LessonDate });
                                          }}
                                        />
                                      }
                                      className="seg2 mt-0"
                                    />
                                  </div>
                                  <div className="ctrl">
                                    <b className="fw6">
                                      <Segment
                                        content={
                                          t("AttendanceHistory").controls
                                            .date[1]
                                        }
                                        className="seg1 lh44 mt-0"
                                      />
                                    </b>
                                    <Segment
                                      content={
                                        <Datepicker
                                          inputPlaceholder={
                                            t("AttendanceHistory").controls
                                              .date[1]
                                          }
                                          defaultSelectedDate={
                                            this.state.LessonDate[1]
                                          }
                                          style={{
                                            margin: 0,
                                            marginLeft: "31px",
                                          }}
                                          onDateChange={(e, v) => {
                                            let LessonDate = {
                                              ...this.state.LessonDate,
                                            };
                                            LessonDate[1] = v.value;
                                            this.setState({ LessonDate });
                                          }}
                                        />
                                      }
                                      className="seg2 mt-0"
                                    />
                                  </div>
                                  <br />
                                  <LessonExport
                                    dataset={{
                                      dateData: {
                                        startDate: this.state.LessonDate[0],
                                        endDate: this.state.LessonDate[1],
                                      },
                                      head: {
                                        headers: {
                                          Authorization:
                                            "Bearer " +
                                            this.props.data.user.accessToken,
                                        },
                                      },
                                      slug: this.props.data.user.slug,
                                    }}
                                  />
                                  {/* {this.state.dayMode ? (
                                    <LessonDayTemplate />
                                  ) : (
                                    <LessonTemplate />
                                  )} */}
                                  <input
                                    type="file"
                                    id="file-lsn-updated"
                                    name="file3"
                                    onChange={this.handleChange}
                                    style={{ display: "none" }}
                                    accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                                  />
                                  <Button
                                    icon={<FilesUploadIcon />}
                                    content={
                                      this.state.file3
                                        ? this.state.file3.name
                                        : t("startUp").controls.action[1]
                                    }
                                    onClick={() =>
                                      document
                                        .getElementById("file-lsn-updated")
                                        .click()
                                    }
                                    title={
                                      this.state.file3
                                        ? this.state.file3.name
                                        : t("startUp").controls.action[1]
                                    }
                                    style={{
                                      maxWidth: "209px",
                                    }}
                                  />
                                  <Button
                                    content={t("startUp").controls.action[2]}
                                    loading={this.state.onSave}
                                    primary
                                    onClick={this.handleUpdateFile}
                                  />
                                  <Button
                                    icon={<ArrowLeftIcon outline />}
                                    iconOnly
                                    // content="Back to Lessons"
                                    onClick={() =>
                                      this.setState({ showUpdateLesson: false })
                                    }
                                    style={{ float: "left" }}
                                  />
                                  {this.state.file3
                                    ? this.progressRender()
                                    : null}
                                </>
                              )
                            }
                            className="seg-card Karla bg-trans2"
                          />
                        </Flex.Item>
                      ) : null}
                      {this.state.show === 2 ? (
                        <Flex.Item>
                          <Segment
                            content={
                              <>
                                <Segment
                                  content={<ExcelIcon size={"largest"} />}
                                  className="bg-trans"
                                />
                                {parse(t("startUp").content[1])}
                                {/* <h1>Datos de los miembros</h1>
                              <ul className="Karla list">
                                <li>
                                  Descargue la plantilla y complete todos los detalles.
                                </li>
                                <li>
                                  No use caracteres especiales en los correos electrónicos.
                                </li>
                                <li>
                                  Puede asignar estos roles a los usuarios.
                                </li>
                                <ul>
                                  <li><b className="fw6">Administrador</b>: Supervisa y toma asistencia a nombre de cualquier maestro.</li>
                                  <li><b className="fw6">Maestro</b>: Toma su propia asistencia.</li>
                                  <li><b className="fw6">Estudiante</b>: Es sujeto de asistencia.</li>
                                </ul>
                              </ul> */}
                                <MembersTemplate
                                  lessonExcelData={this.state.lessonData}
                                />
                                <input
                                  type="file"
                                  id="file-mbr"
                                  name="file2"
                                  onChange={this.handleChange}
                                  style={{ display: "none" }}
                                  accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                                />
                                <Button
                                  icon={<FilesUploadIcon />}
                                  content={
                                    this.state.file2
                                      ? this.state.file2.name
                                      : t("startUp").controls.action[1]
                                  }
                                  onClick={() =>
                                    document.getElementById("file-mbr").click()
                                  }
                                  title={
                                    this.state.file2
                                      ? this.state.file2.name
                                      : t("startUp").controls.action[1]
                                  }
                                  style={{
                                    maxWidth: "209px",
                                  }}
                                />
                                {this.state.show === 2 &&
                                this.state.file1 &&
                                this.state.file2 &&
                                this.state.comparedBothFileData.isEqual ? (
                                  <Button
                                    content={t("startUp").controls.action[2]}
                                    loading={this.state.onSave}
                                    primary
                                    onClick={this.handleFile}
                                  />
                                ) : null}
                                {this.state.file1 && this.state.file2
                                  ? this.progressRender()
                                  : null}
                                {this.state.isUploading && (
                                  <p className="mb-0">
                                    To upload lesson and user data in the
                                    background, click <Link to="/">here</Link>.
                                  </p>
                                )}
                              </>
                            }
                            className="seg-card"
                          />
                        </Flex.Item>
                      ) : null}
                      {this.state.show === 3 ? (
                        <Flex.Item>
                          <Segment
                            content={
                              <>
                                <Segment
                                  content={<FilesZipIcon size={"largest"} />}
                                  className="bg-trans"
                                />
                                {parse(t("startUp").content[2])}
                                <a
                                  href={studentSmpl}
                                  target="_blank"
                                  id="zip-std"
                                  style={{ display: "none" }}
                                  download
                                >
                                  {t("startUp").controls.action[0]}
                                </a>

                                <Button
                                  icon={<DownloadIcon />}
                                  content={t("startUp").controls.action[0]}
                                  onClick={() => {
                                    const anchor =
                                      document.getElementById("zip-std");
                                    if (anchor) {
                                      anchor.click();
                                    }
                                  }}
                                />
                                <input
                                  type="file"
                                  id="zip-upload-std"
                                  name="zip1"
                                  onChange={(e) => {
                                    this.setState({
                                      progStatus: "progress",
                                      percent: 0,
                                      studentZip: event.target.files[0],
                                    });
                                  }}
                                  style={{ display: "none" }}
                                  accept="application/zip"
                                />
                                <Button
                                  icon={<FilesUploadIcon />}
                                  content={
                                    this.state.studentZip
                                      ? this.state.studentZip.name
                                      : t("startUp").controls.action[1]
                                  }
                                  onClick={() =>
                                    document
                                      .getElementById("zip-upload-std")
                                      .click()
                                  }
                                  title={
                                    this.state.studentZip
                                      ? this.state.studentZip.name
                                      : t("startUp").controls.action[1]
                                  }
                                  style={{
                                    maxWidth: "209px",
                                  }}
                                />
                                <Button
                                  content={t("startUp").controls.action[2]}
                                  primary
                                  disabled={!this.state.studentZip}
                                  loading={this.state.onSave}
                                  onClick={() => {
                                    this.setState(
                                      {
                                        onSave: true,
                                        percent: 0,
                                        progStatus: "progress",
                                      },
                                      () => this.increase()
                                    );
                                    const data = new FormData();
                                    data.append("file", this.state.studentZip);
                                    axios
                                      .post(
                                        `${url}/api/startup/upload-student-images${this.props.data.user.slug}`,
                                        data
                                      )
                                      .then((res) => {
                                        if (
                                          res.data.error &&
                                          res.data.error.length
                                        ) {
                                          this.setState({
                                            dialog: true,
                                            msg: (
                                              <ul className="Karla">
                                                {res.data.error?.map(
                                                  (err, key) => (
                                                    <li key={key}>{err}</li>
                                                  )
                                                )}
                                              </ul>
                                            ),
                                            onSave: false,
                                            progStatus: "error",
                                          });
                                        } else {
                                          this.setState(
                                            {
                                              dialog: true,
                                              msg:
                                                res.data.studPhotos[0]?.length +
                                                " " +
                                                res.data.message,
                                              studentZipSaved: true,
                                              onSave: false,
                                              progStatus: "success",
                                            },
                                            () => this.checkProgressHandler()
                                          );
                                        }
                                      })
                                      .catch((err) => {
                                        toast.error(
                                          err?.response?.data?.message
                                        );
                                        console.log(err);
                                        if (err.response?.status === 400) {
                                          this.setState({
                                            dialog: true,
                                            msg: "Please upload file before saving.",
                                            onSave: false,
                                          });
                                        } else {
                                          this.setState({
                                            dialog: true,
                                            msg: err.message,
                                            onSave: false,
                                          });
                                        }
                                      });
                                  }}
                                />
                                {this.state.studentZip
                                  ? this.progressRender()
                                  : null}
                              </>
                            }
                            className="seg-card"
                          />
                        </Flex.Item>
                      ) : null}
                      {this.state.show === 4 ? (
                        <Flex.Item>
                          <Segment
                            content={
                              <>
                                <Segment
                                  content={<FilesZipIcon size={"largest"} />}
                                  className="bg-trans"
                                />
                                {parse(t("startUp").content[3])}
                                <a
                                  href={teacherSmpl}
                                  target="_blank"
                                  id="zip-tch"
                                  style={{ display: "none" }}
                                  download
                                >
                                  {t("startUp").controls.action[0]}
                                </a>

                                <Button
                                  icon={<DownloadIcon />}
                                  content={t("startUp").controls.action[0]}
                                  onClick={() => {
                                    const anchor =
                                      document.getElementById("zip-tch");
                                    if (anchor) {
                                      anchor.click();
                                    }
                                  }}
                                />
                                <input
                                  type="file"
                                  id="zip-upload-tch"
                                  name="zip1"
                                  onChange={(e) => {
                                    this.setState({
                                      progStatus: "progress",
                                      percent: 0,
                                      teacherZip: e.target.files[0],
                                    });
                                  }}
                                  style={{ display: "none" }}
                                  accept="application/zip"
                                />
                                <Button
                                  icon={<FilesUploadIcon />}
                                  content={
                                    this.state.teacherZip
                                      ? this.state.teacherZip.name
                                      : t("startUp").controls.action[1]
                                  }
                                  onClick={() =>
                                    document
                                      .getElementById("zip-upload-tch")
                                      .click()
                                  }
                                  title={
                                    this.state.teacherZip
                                      ? this.state.teacherZip.name
                                      : t("startUp").controls.action[1]
                                  }
                                  style={{
                                    maxWidth: "209px",
                                  }}
                                />
                                <Button
                                  content={t("startUp").controls.action[2]}
                                  primary
                                  disabled={!this.state.teacherZip}
                                  loading={this.state.onSave}
                                  onClick={() => {
                                    this.setState(
                                      {
                                        onSave: true,
                                        percent: 0,
                                        progStatus: "progress",
                                      },
                                      () => this.increase()
                                    );
                                    const data = new FormData();
                                    data.append("file", this.state.teacherZip);
                                    axios
                                      .post(
                                        `${url}/api/startup/upload-teacher-images${this.props.data.user.slug}`,
                                        data
                                      )
                                      .then((res) => {
                                        if (
                                          res.data.error &&
                                          res.data.error.length
                                        ) {
                                          this.setState({
                                            dialog: true,
                                            msg: (
                                              <ul className="Karla">
                                                {res.data.error?.map(
                                                  (err, key) => (
                                                    <li key={key}>{err}</li>
                                                  )
                                                )}
                                              </ul>
                                            ),
                                            onSave: false,
                                            progStatus: "error",
                                          });
                                        } else {
                                          this.setState(
                                            {
                                              dialog: true,
                                              msg:
                                                res.data.teachPhoto?.length +
                                                " " +
                                                res.data.message,
                                              teacherZipSaved: true,
                                              onSave: false,
                                              show: 5,
                                              progStatus: "success",
                                            },
                                            () => this.checkProgressHandler()
                                          );
                                        }
                                      })
                                      .catch((err) => {
                                        toast.error(
                                          err?.response?.data?.message
                                        );
                                        console.log(err);
                                        if (err.response?.status === 400) {
                                          this.setState({
                                            dialog: true,
                                            msg: "Please upload file before saving.",
                                            onSave: false,
                                          });
                                        } else {
                                          this.setState({
                                            dialog: true,
                                            msg: err.message,
                                            onSave: false,
                                          });
                                        }
                                      });
                                  }}
                                />
                                {this.state.teacherZip
                                  ? this.progressRender()
                                  : null}
                              </>
                            }
                            className="seg-card"
                          />
                        </Flex.Item>
                      ) : null}
                      {this.state.show === 5 ? (
                        <Flex.Item>
                          <Segment
                            content={
                              <>
                                <img
                                  // src={thumb}
                                  src={positiveVote}
                                  alt="ACV - Asistente de Aula Virtual | Congretulations"
                                  style={{
                                    padding: "10px",
                                    margin: "20px",
                                    width: "200px",
                                    height: "200px",
                                  }}
                                />
                                {/* <LikeIcon outline size="largest" style={{ color: "#101010", width:"" }}/> */}
                                <h1 style={{ color: "#101010" }}>
                                  {t("startUp").controls.action[7]}
                                </h1>
                              </>
                            }
                            style={{ minWidth: "25rem", minHeight: "25rem" }}
                          />
                        </Flex.Item>
                      ) : null}
                    </Flex>
                    {this.state.show === 5 ? (
                      <Button
                        icon={<Icon.Home size="20" />}
                        content={t("startUp").controls.action[3]}
                        onClick={() => (window.location.href = "/attendance")}
                      />
                    ) : (
                      <Dialog
                        cancelButton={t("startUp").controls.modal[2]}
                        confirmButton={t("startUp").controls.modal[1]}
                        onConfirm={() => (window.location.href = "/attendance")}
                        content={t("startUp").controls.modal[4]}
                        header={t("startUp").controls.modal[3]}
                        trigger={
                          <Button
                            icon={<Icon.Home size="20" />}
                            content={t("startUp").controls.action[3]}
                          />
                        }
                      />
                    )}
                    <Button
                      icon={<ArrowLeftIcon />}
                      content={t("startUp").controls.action[4]}
                      disabled={this.state.show === 1 || this.state.show === 5}
                      onClick={() => {
                        if (this.state.show === 2) {
                          this.setState(
                            { show: 1, file2: false, filesSaved: false },
                            () => this.checkProgressHandler()
                          );
                        } else if (this.state.show === 3) {
                          this.setState(
                            {
                              show: 2,
                              studentZipSaved: false,
                              studentZip: null,
                              filesSaved: false,
                            },
                            () => this.checkProgressHandler()
                          );
                        } else if (this.state.show === 4) {
                          this.setState(
                            {
                              show: 3,
                              studentZipSaved: false,
                              teacherZipSaved: false,
                              teacherZip: null,
                            },
                            () => this.checkProgressHandler()
                          );
                        }
                        //  else {
                        //   let show = this.state.show;
                        //   show--;
                        //   this.setState({ show }, () => this.checkProgressHandler());
                        // }
                      }}
                    />
                    <Button
                      icon={<ArrowRightIcon />}
                      content={t("startUp").controls.action[5]}
                      disabled={
                        (this.state.show === 1 &&
                          (!this.state.file1 ||
                            (this.state.isDateRangeSelect === false &&
                              this.state.dayMode === true))) ||
                        !this.state.isCompleteExcelFile.lessonFile ||
                        (this.state.show === 2 &&
                          (!this.state.file1 || !this.state.file2)) ||
                        (this.state.show === 2 &&
                          !this.state.isCompleteExcelFile.memberFile) ||
                        (this.state.show === 2 &&
                          this.state.filesSaved === false) ||
                        (this.state.show === 3 && !this.state.studentZip) ||
                        (this.state.show === 3 &&
                          this.state.studentZipSaved === false) ||
                        (this.state.show === 4 && !this.state.teacherZip) ||
                        (this.state.show === 4 &&
                          this.state.teacherZipSaved === false) ||
                        this.state.show > 4
                      }
                      onClick={() => {
                        // console.log((this.state.show===1&&!this.state.file1)||(this.state.show===2&&(!this.state.file1||!this.state.file2)));
                        let show = this.state.show;
                        if (
                          (this.state.show === 2 &&
                            this.state.filesSaved === false) ||
                          (this.state.show === 3 &&
                            this.state.studentZipSaved === false) ||
                          (this.state.show === 4 &&
                            this.state.teacherZipSaved === false)
                        ) {
                          console.log("please save the files first");
                        } else if (this.state.show >= 5) {
                          window.location.href = "/attendance";
                        } else {
                          show++;
                        }
                        this.setState({ show }, () =>
                          this.checkProgressHandler()
                        );
                        // console.log(this.state.show);
                      }}
                    />
                    {this.state.show <= 4 ? (
                      <Button
                        icon={<GeofenceLeavesIcon />}
                        content={t("startUp").controls.action[6]}
                        onClick={() => {
                          if (this.state.show === 3) {
                            this.setState({ show: 4 });
                          } else if (this.state.show === 4) {
                            this.setState({ show: 5 });
                          } else {
                            this.setState({ show: 3 });
                          }
                        }}
                      />
                    ) : null}
                    <Dialog
                      style={{ width: "100%", maxWidth: "410px" }}
                      className="emptyExcelFileDataModal"
                      content={
                        <>
                          <p>
                            There is some missing data in excel file. Please
                            fill and re-upload.
                          </p>
                          <Flex gap="gap.small">
                            <Button
                              onClick={this.downloadExcelWithEmptyFilledData}
                            >
                              Download excel
                            </Button>
                          </Flex>
                        </>
                      }
                      header={`Incomplete ${
                        this.state.show === 1 ? "lesson" : ""
                      } ${this.state.show === 2 ? "member" : ""} data`}
                      headerAction={{
                        icon: <CloseIcon />,
                        title: "Close",
                        onClick: () => {
                          this.setState({
                            isOpenMissingDataModal: false,
                          });
                        },
                      }}
                      open={this.state.isOpenMissingDataModal}
                    />
                    <Dialog
                      style={{ width: "100%", maxWidth: "600px" }}
                      className="emptyExcelFileDataModal"
                      content={
                        <>
                          <p>{this.state.comparedBothFileData.message}</p>
                          <div>
                            {this.state.comparedBothFileData.missingRecords
                              .length > 0 ? (
                              <div>
                                <ul>
                                  {this.state.comparedBothFileData?.missingRecords?.map(
                                    (record, index) => (
                                      <li
                                        key={`mismatched-member-data-${index}`}
                                      >
                                        <span>{record["SubjectType"]}</span>
                                        {" - "}
                                        <span>{record["SubjectName"]}</span>
                                        {" - "}
                                        <span>{record["SubjectCode"]}</span>
                                      </li>
                                    )
                                  )}
                                </ul>
                              </div>
                            ) : (
                              ""
                            )}
                          </div>
                        </>
                      }
                      footer={
                        <div className="text-start">
                          <p>
                            Please download the sample file provided below and
                            import your data into it.
                          </p>
                          <Flex gap="gap.small">
                            {this.state.show === 1 ? (
                              this.state.dayMode ? (
                                <LessonDayTemplate />
                              ) : (
                                <LessonTemplate />
                              )
                            ) : (
                              <MembersTemplate
                                lessonExcelData={this.state.lessonData}
                              />
                            )}
                          </Flex>
                          {/* {t("startUp").missingRecordDialog.footer.messages[0]}{" "}
                          <a
                            onClick={() => {
                              if (this.state.show === 1) {
                                document.getElementById("file-lsn").click();
                              } else {
                                document.getElementById("file-mbr").click();
                              }
                            }}
                          >
                            {
                              t("startUp").missingRecordDialog.footer
                                .messages[1]
                            }
                          </a>{" "}
                          {t("startUp").missingRecordDialog.footer.messages[2]} */}
                        </div>
                      }
                      header={this.state.comparedBothFileData.header}
                      headerAction={{
                        icon: <CloseIcon />,
                        title: "Close",
                        onClick: () => {
                          this.setState({
                            comparedBothFileData: {
                              isOpenModal: false,
                              missingRecords: [],
                              header: "",
                            },
                          });
                        },
                      }}
                      open={this.state.comparedBothFileData.isOpenModal}
                    />
                  </>
                }
                className="bg-gr "
                style={{
                  // background: `url(${Background}) no-repeat center center fixed`,
                  border: "none",
                  width: "100%",
                  height: "560px",
                }}
                // style={{
                //   border: "none", padding: "20px", width: "100%", height: "550px"
                // }}
              />
            </Flex>
          }
          style={{ textAlign: "center" }}
          className="UploadData"
        />
      </>
    );
  }
}

export default withTranslation()(UploadData);
