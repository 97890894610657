import React, { useCallback, useEffect, useState } from "react";

import {
  Flex,
  Datepicker,
  Button,
  RetryIcon,
  Input,
  Segment,
  Dialog,
  SearchIcon,
  Dropdown,
} from "@fluentui/react-northstar";
import moment from "moment";

import { ArrowLeftCircle, ArrowRightCircle } from "react-feather";
import { useAssignment } from "../../../../context/AssignmentContext";

import Tour from "../../Tour";

import "./Controls.css";
import { Box, Grid, TextField } from "@mui/material";
import Drawer from "../../../Attendance/Drawer";
import { DesktopDatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import axios from "axios";
import { toast } from "react-toastify";
import userSvg from "../../../../Assets/images/svg/user.svg";

const Controls = (props) => {
  const {
    setGlobalSearchText,
    currentPage,
    selectedLesson,
    selectedLessonAssignments,
    isRubricHasPoints,
  } = useAssignment();

  const url = process.env.REACT_APP_EP_URL;

  const [accDlg, setAccDlg] = useState(false);
  const [isMobileView, setIsMobileView] = useState(window.innerWidth <= 767);
  const [isTabletView, setIsTabletView] = useState(window.innerWidth <= 991);
  const [selectedDate, setSelectedDate] = useState(new Date());
  const [teacherData, setTeacherData] = useState([]);
  const [teachersNames, setTeachersNames] = useState([]);
  const [dropdownKeys, setDropdownKeys] = useState([]);
  const [teacherClassData, setTeacherClassData] = useState(null);
  const [classNames, setClassNames] = useState([]);

  useEffect(() => {
    const handleResize = () => {
      setIsMobileView(window.innerWidth <= 767);
      setIsTabletView(window.innerWidth <= 991);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const headers = {
    Authorization: "Bearer " + props.state.accessToken,
  };
  useEffect(() => {
    props.user.role === "Admin" || props.user.role === "SuperAdmin"
      ? axios
          .post(
            `${url}/api/teacher/get-teachers${props.user.slug}`,
            {
              date: props?.state?.selectedDate,
            },
            {
              headers: headers,
            }
          )
          .then((res) => {
            setTeacherData(res.data.result || null);
          })
          .catch((err) => {
            toast.error(err?.response?.data?.message);
            console.log(err, err.response);
            if (err.response?.status === 401) {
              setAccDlg(true);
            }
          })
      : "";
  }, [props?.state?.selectedDate]);

  const debounce = (func) => {
    let timer;
    return function (...args) {
      const context = this;
      if (timer) clearTimeout(timer);
      timer = setTimeout(() => {
        timer = null;
        func.apply(context, args);
      }, 500);
    };
  };

  const handleChange = (value) => {
    setGlobalSearchText(value);
  };

  const optimizedFn = useCallback(debounce(handleChange), []);

  const classNameHandler = (lessions) => {
    const dropdownKeys = [];
    lessions &&
      setClassNames(
        lessions.length
          ? lessions
              .map((lsn) => {
                dropdownKeys.push(lsn.Title);
                setDropdownKeys(dropdownKeys);
                return lsn.ClassName;
              })
              .filter((value, index, self) => self.indexOf(value) === index)
          : []
      );
  };

  const teachersNamesHandler = (teachers) => {
    const dropdownKeys = [];

    const teachersNames =
      teachers && teachers.length
        ? teachers
            .map((teacher) => {
              dropdownKeys.push(teacher.TeacherEmailID);
              setDropdownKeys(dropdownKeys);
              return {
                header: teacher.TeacherName,
                image: teacher.UrlPhoto
                  ? teacher.UrlPhoto + props.user.SASToken
                  : userSvg,
                content: teacher.Facultad,
                key: teacher.TeacherEmailID,
              };
            })
            .filter((value, index, self) => self.indexOf(value) === index)
        : [];
    setTeachersNames(teachersNames);
  };
  const dropdownChangedHandler = (className) => {
    props.changeLsnByClass(className);
  };
  const teacherChangedHandler = (teacher) => {
    axios
      .get(`${url}/api/teacher/get-teacher/${teacher.key}${props.user.slug}`, {
        headers: headers,
      })
      .then((res) => {
        const lessonData = res.data.result ? res.data.result.LessonData : null;
        lessonData && classNameHandler(lessonData);
        setTeacherClassData(lessonData);
      })
      .catch((err) => {
        toast.error(err?.response?.data?.message);
        console.log(err, err.response);
        if (err.response?.status === 401) {
          setAccDlg(true);
        }
      });
    props.changeLsnByTeacher(teacher.key);
  };

  useEffect(() => {
    if (teacherData && teacherData?.length) {
      teachersNamesHandler(teacherData);
    }
  }, [teacherData]);

  useEffect(() => {
    if (teacherClassData === null) {
      classNameHandler(props.state.showLesson);
    }
  }, [props?.state, teacherClassData]);

  const getA11ySelectionMessage = {
    onAdd: (item) =>
      `${item.header} selected. Press left or right arrow keys to navigate selected items.`,
    onRemove: (item) => `${item.header} has been removed.`,
  };
  return (
    <>
      {isMobileView || isTabletView ? (
        <div className="Controls" style={{ position: "static" }}>
          <Dialog
            open={accDlg}
            onConfirm={() => location.reload()}
            confirmButton="Refresh Now"
            content={
              <p className="refreshMsg">
                No big deal — but we need to refresh to show your content.
              </p>
            }
            header="Authorization expire"
          />
          <Flex gap="gap.smaller" wrap space="between">
            <Flex
              className={
                props.isLessonsAvailable
                  ? "tour-find-class"
                  : "tour-no-lessons-available"
              }
            >
              <Grid container>
                <Grid item xs={12}>
                  <Segment
                    content={
                      <Flex gap="gap.smaller" wrap>
                        {props.showNote && (
                          <Drawer
                            isCompactMode={props.isCompactMode}
                            state={props.state}
                            myCard={props.myCard}
                            isOpen={props.isOpen}
                            openPanel={props.openPanel}
                            dismissPanel={props.dismissPanel}
                          />
                        )}
                        {isMobileView ? (
                          <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <DesktopDatePicker
                              className="date-picker-assignments-mobileview-view"
                              inputFormat="MM/DD/YYYY"
                              value={selectedDate}
                              onChange={(value) => {
                                let date = new Date(value);
                                date.setHours(0);
                                date.setMinutes(0);
                                date.setSeconds(0);
                                let dt = date.getDate();
                                let month = date.getMonth() + 1;
                                let year = date.getFullYear();
                                if (dt < 10) {
                                  dt = "0" + dt;
                                }
                                if (month < 10) {
                                  month = "0" + month;
                                }

                                props.onDateDataChange(
                                  props.user.slug === "?slug=acvpreprod" ||
                                    props.user.slug === "?slug=acvapp"
                                    ? value.$d
                                    : date.toISOString()
                                );
                                setSelectedDate(date);
                              }}
                              renderInput={(params) => (
                                <TextField {...params} />
                              )}
                            />
                          </LocalizationProvider>
                        ) : (
                          <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <DesktopDatePicker
                              className="date-picker-assignments-mobileview-view"
                              // id="datePicker-1"
                              inputFormat="MM/DD/YYYY"
                              value={selectedDate}
                              onChange={(value) => {
                                let date = new Date(value);
                                date.setHours(0);
                                date.setMinutes(0);
                                date.setSeconds(0);
                                let dt = date.getDate();
                                let month = date.getMonth() + 1;
                                let year = date.getFullYear();
                                if (dt < 10) {
                                  dt = "0" + dt;
                                }
                                if (month < 10) {
                                  month = "0" + month;
                                }

                                props.onDateDataChange(
                                  props.user.slug === "?slug=acvpreprod" ||
                                    props.user.slug === "?slug=acvapp"
                                    ? value.$d
                                    : date.toISOString()
                                );
                                setSelectedDate(date);
                              }}
                              renderInput={(params) => (
                                <TextField {...params} />
                              )}
                            />
                          </LocalizationProvider>
                        )}

                        <Input
                          icon={<SearchIcon />}
                          clearable
                          placeholder={props.labels.globalSearch}
                          onChange={(e) => optimizedFn(e.target.value)}
                          className="mr-3"
                        />
                        <Flex>
                          <Segment
                            className="seg2"
                            content={
                              <Tour
                                currentPage={currentPage}
                                isLessonsAvailable={props.isLessonsAvailable}
                                canCreateAssignment={
                                  selectedLesson
                                    ? moment().isSameOrBefore(
                                        selectedLesson.LessonDate
                                      )
                                    : false
                                }
                                selectedLessonAssignments={
                                  selectedLessonAssignments?.length || 0
                                }
                                isRubricHasPoints={isRubricHasPoints}
                              />
                            }
                          />
                          <div className="leftControls">
                            <Segment
                              content={
                                <Button
                                  icon={<RetryIcon />}
                                  iconOnly
                                  title="Refresh teacher and class"
                                  onClick={() => {
                                    props.resetSelected();
                                  }}
                                />
                              }
                              className="seg2"
                            />
                          </div>
                          {classNames.length ? (
                            <div className="leftControls">
                              <Segment
                                content={
                                  <Dropdown
                                    // inverted
                                    clearable
                                    onTouchCancelCapture={() => alert("cancel")}
                                    search
                                    items={classNames.sort()}
                                    placeholder={props.cLabels.class}
                                    onChange={(e, { value }) => {
                                      dropdownChangedHandler(value);
                                    }}
                                    noResultsMessage="We couldn't find any matches."
                                    // checkable
                                    // getA11ySelectionMessage={{
                                    //   onAdd: (item) => dropdownChangedHandler(item),
                                    // }}
                                  />
                                }
                                className="seg2"
                              />
                            </div>
                          ) : null}
                        </Flex>
                      </Flex>
                    }
                    className="seg2"
                  />
                </Grid>
              </Grid>
            </Flex>
          </Flex>
        </div>
      ) : (
        <div className="Controls" style={{ position: "static" }}>
          <Dialog
            open={accDlg}
            onConfirm={() => location.reload()}
            confirmButton="Refresh Now"
            content={
              <p className="refreshMsg">
                No big deal — but we need to refresh to show your content.
              </p>
            }
            header="Authorization expire"
          />
          <Flex gap="gap.smaller" wrap space="between">
            <Flex
              className={
                props.isLessonsAvailable
                  ? "tour-find-class"
                  : "tour-no-lessons-available"
              }
            >
              <div
                className="leftControls"
                style={{ margin: 0, marginLeft: "31px" }}
              >
                <Segment content={props.labels.date} className="seg1" />
                <Segment
                  content={
                    // <Datepicker
                    //   defaultSelectedDate={new Date()}
                    //   inputPlaceholder={props.labels.date}
                    //   onDateChange={(e, v) => {
                    //     // let time = moment().local().format("HH:mm");
                    //     // let dateString = moment(v.value).format("MM-DD-YYYY");

                    //     // let date = moment
                    //     //   .utc(moment(`${dateString} ${time}`).toISOString())
                    //     //   .format("YYYY-MM-DD");

                    //     // let localDateString = moment(v.value).format("YYYY-MM-DD");

                    //     // let year = date.getFullYear();
                    //     // let month = date.getMonth() + 1;
                    //     // let dt = date.getDate();

                    //     // if (dt < 10) {
                    //     //   dt = "0" + dt;
                    //     // }
                    //     // if (month < 10) {
                    //     //   month = "0" + month;
                    //     // }
                    //     // -----------
                    //     // let selectedDate =
                    //     //   v.value !== null
                    //     //     ? v.value.toISOString().substring(0, 10)
                    //     //     : null;
                    //     // -------------
                    //     // console.log(e,v, selectedDate, v.value.toString(),date.getFullYear()+'-' + (date.getMonth()+1) + '-'+date.getDate());
                    //     // console.log(e, selectedDate); // 2021-04-24 =>Required

                    //     // props.onDateDataChange(year + "-" + month + "-" + dt);
                    //     // props.onDateDataChange(date);
                    //     // props.onDateDataChange(selectedDate);

                    //     // props.onDateDataChange(v.value.toISOString());
                    //     if (v.value) {
                    //       props.onDateDataChange(
                    //         new Date(v.value.setHours(0, 0, 0, 0))
                    //         // new Date(moment(v.value).format("YYYY-MM-DD"))
                    //       );
                    //     } else {
                    //       props.onDateDataChange(
                    //         new Date(new Date().setHours(0, 0, 0, 0))
                    //       );
                    //     }

                    //     // console.log(v.value.toISOString()); // 2021-04-24T18:30:00.000Z =>Standard
                    //     // console.log(v.value); // Fri Apr 16 2021 00:00:00 GMT+0530 (India Standard Time) =>Local
                    //     // console.log(document.getElementById('ui-input-1').value); // April 11, 2021 =>should used in callback
                    //   }}
                    //   id="datePicker-1"
                    // />

                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <DesktopDatePicker
                        className="date-picker-attendance"
                        inputFormat="MMMM D, YYYY"
                        value={selectedDate}
                        onChange={(value) => {
                          let date = new Date(value);
                          date.setHours(0);
                          date.setMinutes(0);
                          date.setSeconds(0);
                          let dt = date.getDate();
                          let month = date.getMonth() + 1;
                          let year = date.getFullYear();
                          if (dt < 10) {
                            dt = "0" + dt;
                          }
                          if (month < 10) {
                            month = "0" + month;
                          }
                          props.onDateDataChange(
                            props.user.slug === "?slug=acvpreprod" ||
                              props.user.slug === "?slug=acvapp"
                              ? value.$d
                              : date.toISOString()
                          );
                          setSelectedDate(date);
                        }}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            sx={{
                              "& fieldset": { border: "none" },
                            }}
                          />
                        )}
                      />
                    </LocalizationProvider>
                  }
                  className="seg2"
                />
              </div>
              <div className="leftControls">
                <Segment content="" className="seg1" style={{ padding: 0 }} />
                <Segment
                  content={
                    <Button
                      icon={<RetryIcon />}
                      iconOnly
                      title="Refresh teacher and class"
                      onClick={() => {
                        props.resetSelected();
                      }}
                    />
                  }
                  className="seg2"
                />
              </div>
              {teachersNames.length ? (
                <div className="leftControls">
                  <Segment
                    content={props.cLabels.teacher[0]}
                    className="seg1"
                  />
                  <Segment
                    content={
                      <Dropdown
                        clearable
                        onTouchCancelCapture={() => alert("cancel")}
                        // multiple
                        search
                        // inverted
                        items={teachersNames}
                        placeholder={props.cLabels.teacher[1]}
                        // getA11ySelectionMessage={{
                        //   onAdd: (item) => teacherChangedHandler(item),
                        // }}
                        onChange={(e, { value }) => {
                          teacherChangedHandler(value || "");
                        }}
                        noResultsMessage="We couldn't find any matches."
                        // a11ySelectedItemsMessage="Press Delete or Backspace to remove"
                      />
                    }
                    className="seg2 selTeacher"
                  />
                </div>
              ) : null}
              {classNames.length ? (
                <div className="leftControls">
                  <Segment content={props.cLabels.class} className="seg1" />
                  <Segment
                    content={
                      <Dropdown
                        // inverted
                        clearable
                        onTouchCancelCapture={() => alert("cancel")}
                        search
                        items={classNames.sort()}
                        placeholder={props.cLabels.class}
                        onChange={(e, { value }) => {
                          dropdownChangedHandler(value);
                        }}
                        noResultsMessage="We couldn't find any matches."
                        // checkable
                        // getA11ySelectionMessage={{
                        //   onAdd: (item) => dropdownChangedHandler(item),
                        // }}
                      />
                    }
                    className="seg2"
                  />
                </div>
              ) : null}
            </Flex>
            <Flex wrap className="assignment-tour-search-next-wrapper">
              <div className="andy">
                <Flex gap="gap.medium" wrap className="ctnr">
                  <Flex
                    gap="gap.smaller"
                    vAlign="center"
                    hAlign="center"
                    className="nextBtn"
                  >
                    <Button
                      icon={<ArrowRightCircle />}
                      iconOnly
                      primary
                      onClick={() => {
                        document
                          .querySelector(".Attendance.s-ctr")
                          .classList.add("h-ctr");
                        document
                          .querySelector(".Attendance.s-ctr")
                          .classList.remove("s-ctr");
                      }}
                    />
                  </Flex>
                  <Flex
                    gap="gap.smaller"
                    vAlign="center"
                    hAlign="center"
                    className="prevBtn"
                  >
                    <Button
                      icon={<ArrowLeftCircle />}
                      iconOnly
                      primary
                      onClick={() => {
                        document
                          .querySelector(".Attendance.h-ctr")
                          .classList.add("s-ctr");
                        document
                          .querySelector(".Attendance.h-ctr")
                          .classList.remove("h-ctr");
                      }}
                    />
                  </Flex>
                </Flex>
              </div>
              {props.assignmentSearch ? (
                <Flex>
                  <div className="leftControls">
                    <Segment
                      content={
                        <Flex wrap className="assignment-tour-search-wrapper">
                          {/* <Icon.Filter
                      size="20"
                      className="ctrl mt-2 mr-3"
                      onClick={() => {}}
                    /> */}
                          <Input
                            icon={<SearchIcon />}
                            clearable
                            placeholder={props.labels.globalSearch}
                            onChange={(e) => optimizedFn(e.target.value)}
                            className="mr-3"
                          />
                          <div className="assignment-tour">
                            <Tour
                              currentPage={currentPage}
                              isLessonsAvailable={props.isLessonsAvailable}
                              canCreateAssignment={
                                selectedLesson
                                  ? moment().isSameOrBefore(
                                      selectedLesson.LessonDate
                                    )
                                  : false
                              }
                              selectedLessonAssignments={
                                selectedLessonAssignments?.length || 0
                              }
                              isRubricHasPoints={isRubricHasPoints}
                            />
                          </div>
                        </Flex>
                      }
                      className="seg2"
                    />
                  </div>
                </Flex>
              ) : null}
            </Flex>
          </Flex>
        </div>
      )}
    </>
  );
};

export default Controls;
