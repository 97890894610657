import {
  Button,
  CloseIcon,
  Dialog,
  Dropdown,
  Flex,
  Input,
  Text,
} from "@fluentui/react-northstar";
import React from "react";
import { useExternalTutors } from "../../context/ExternalTutorsContext";
import { useForm, Controller } from "react-hook-form";
import "./AddExternalTutorModel.scss";
import { useTranslation } from "react-i18next";

const providers = ["Microsoft", "Google"];

const AddExternalTutorModel = () => {
  const {
    isOpenAddExternalTutorsModel,
    setIsOpenAddExternalTutorsModel,
    createExternalTutor,
    isLoading,
  } = useExternalTutors();

  const { t } = useTranslation();

  const translation = t("booking").externalTutors.addExternalTutorDialog;
  const formTranslation = translation.form

  const {
    control,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm();

  const onSubmit = (data) => createExternalTutor(data, reset);

  return (
    <Dialog
      open={isOpenAddExternalTutorsModel}
      header={translation.heading}
      className="smallDlg"
      content={
        <form onSubmit={handleSubmit(onSubmit)}>
          <Flex column gap="gap.large">
            <Flex column gap="gap.small">
              {/* Email Field */}
              <Flex column gap="gap.smaller" style={{ width: "100%" }}>
                <p className="sub-title">{formTranslation.email.label}</p>
                <Controller
                  control={control}
                  name="tutorEmail"
                  rules={{
                    required: formTranslation.email.emailIsRequired,
                    pattern: {
                      value: /^[^\s@]+@[^\s@]+\.[^\s@]+$/,
                      message: formTranslation.email.enterValidEmail,
                    },
                  }}
                  render={({ field }) => (
                    <Input
                      {...field}
                      placeholder={formTranslation.email.placeholder}
                      fluid
                      className="email-input"
                      disabled={isLoading}
                      error={!!errors.tutorEmail}
                      style={{ width: "100%" }}
                    />
                  )}
                />
                {errors.tutorEmail && (
                  <Text content={errors.tutorEmail.message} error />
                )}
              </Flex>

              {/* Provider Dropdown */}
              <Flex column gap="gap.smaller">
                <p className="sub-title">{formTranslation.provider.label}</p>
                {providers.length > 0 && (
                  <Controller
                    control={control}
                    name="provider"
                    rules={{ required: formTranslation.provider.providerIsRequired }}
                    render={({ field }) => (
                      <Dropdown
                        {...field}
                        fluid
                        items={providers}
                        disabled={isLoading}
                        onChange={(_, { value }) => field.onChange(value)}
                        placeholder={formTranslation.provider.placeholder}
                        noResultsMessage={formTranslation.provider.noResult}
                        className="select-provider-dropdown"
                      />
                    )}
                  />
                )}
                {errors.provider && (
                  <Text content={errors.provider.message} error />
                )}
              </Flex>
            </Flex>
            {/* Action Buttons */}
            <Flex gap="gap.small" hAlign="end">
              <Button
                content={translation.cancelButtonTitle}
                disabled={isLoading}
                onClick={() => {
                  setIsOpenAddExternalTutorsModel(false);
                  reset();
                }}
              />
              <Button
                primary
                content={isLoading ? `${translation.adding}...` : translation.submitButtonTitle}
                type="submit"
                loading={isLoading}
                disabled={isLoading}
              />
            </Flex>
          </Flex>
        </form>
      }
      headerAction={{
        icon: <CloseIcon />,
        title: "close",
        disabled: isLoading,
        onClick: () => {
          setIsOpenAddExternalTutorsModel(false);
          reset();
        },
      }}
    />
  );
};

export default AddExternalTutorModel;
