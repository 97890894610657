import {
  Button,
  Card,
  CardBody,
  CloseIcon,
  Dialog,
  Flex,
  MicIcon,
  PauseIcon,
  PlayIcon,
  RadioGroup,
  Text,
  TextArea,
} from "@fluentui/react-northstar";
import React, { useEffect, useState } from "react";
import { FileUploader } from "react-drag-drop-files";
import { withTranslation } from "react-i18next";
import VideoRecorder from "react-video-recorder";

import FeedbackDialog from "../shared/FeedbackDialog";
import { useAudioRecorder } from "@sarafhbk/react-audio-recorder";
import { NotificationAlert } from "../../../../components/Notification/Notification";

const QuestionResponse = ({
  FEEDBACK_TYPES,
  response,
  isSubmitting,
  feedbackData,
  completed,
  currentQuestion,
  isFeedbackCreator,
  setCurrentQuestion,
  questionResponses,
  setQuestionResponses,
  submitFeedbackHandler,
  t,
}) => {
  const { FeedbackQuestion: feedbackQuestion = {} } = response;

  const [isValidateResponse, setIsValidateResponse] = useState(false);
  const [textResponse, setTextResponse] = useState("");

  const [voiceState, setVoiceState] = useState(() => ({
    open: false,
    isStart: false,
    isSaved: false,
    isPause: false,
    isDone: false,
    audioUrl: "",
  }));

  const [videoState, setVideoState] = useState({
    isStart: false,
    isSaved: false,
    videoUrl: null,
  });
  const [confirmVideoDelete, setConfirmVideoDelete] = useState(false);

  const [isConfirmDeleteAudio, setIsConfirmDeleteAudio] = useState(false);
  const [selectedOption, setSelectedOption] = useState(null);

  const translation = t("feedbackModule");

  const commonTranslation = translation.common;
  const feedbackTranslation = translation.body.submitFeedback;

  useEffect(() => {
    if (response.videoResponse) {
      setVideoState({
        isSaved: true,
        isStart: false,
        videoUrl: response.videoResponse,
      });
    }
    if (response.audioResponse) {
      setVoiceState({
        open: false,
        isStart: false,
        isSaved: true,
        isPause: false,
        isDone: true,
        audioUrl: response.audioResponse,
      });
    }
    setTextResponse(response.textResponse);
    setSelectedOption(
      response.pollResponse
        ? response.pollResponse
        : feedbackQuestion?.pollOptions?.[0]?.value
    );
  }, [response]);

  const {
    audioResult,
    timer,
    startRecording,
    stopRecording,
    pauseRecording,
    resumeRecording,
  } = useAudioRecorder();

  useEffect(() => {
    if (audioResult) {
      setVoiceState((prev) => ({
        ...prev,
        audioUrl: audioResult,
      }));
    }
  }, [audioResult]);

  const getConfirmFlagForVideo = (val) => {
    setConfirmVideoDelete(false);

    if (!val) {
      setVideoState({
        ...videoState,
        open: false,
      });
    } else {
      setVideoState({
        ...videoState,
        isStart: false,
        isSaved: false,
        open: false,
        videoUrl: null,
        isClosedWithoutSaved: false,
        isVideoRecorded: false,
      });
      NotificationAlert(feedbackTranslation.videoResponseDeleted, "success");
    }
  };

  const getConfirmFlag = (val) => {
    setIsConfirmDeleteAudio(false);

    if (!val) {
      setVoiceState({
        ...voiceState,
        open: false,
      });

      return;
    }

    setVoiceState({
      ...voiceState,
      isStart: false,
      isPause: false,
      isSaved: false,
      isDone: false,
      open: false,
      audioUrl: null,
    });
    NotificationAlert(feedbackTranslation.voiceResponseDeleted, "success");
  };

  const handleVideoRecordConfirm = () => {
    if (videoState.isVideoRecorded) {


      if (videoState.isSaved) {
        setConfirmVideoDelete(true);
      } else if (!videoState.isSaved) {
        setVideoState({
          ...videoState,
          isSaved: true,
          open: false,
          isClosedWithoutSaved: false,
          isVideoRecorded: false,
        });
        NotificationAlert(feedbackTranslation.videoResponseAdded, "success");
      } else {
        setVideoState({
          ...videoState,
          isSaved: false,
          open: false,
        });
      }
    } else {
      NotificationAlert("Please record a video before submitting.", "error");
    }
  };

  const handleRecordConfirm = async () => {
    if (voiceState.isDone) {
      setIsConfirmDeleteAudio(true);
    } else if (voiceState.isSaved) {
      setVoiceState({
        ...voiceState,
        isDone: true,
        open: false,
        audioUrl: audioResult,
      });
      NotificationAlert(feedbackTranslation.voiceResponseAdded, "success");
    } else {
      stopRecording();
      if (timer && timer / 60 > 5) {
        setVoiceState({
          ...voiceState,
          isDone: false,
          open: false,
          isSaved: false,
          isPause: false,
          isStart: false,
          audioUrl: "",
        });
        NotificationAlert(
          feedbackTranslation.voiceResponseValidation,
          "warning"
        );
      } else {
        setVoiceState((prevData) => ({
          ...prevData,
          isSaved: true,
          isStart: false,
          isPause: false,
          timer,
        }));
      }
    }
  };

  const ReturnVideoContent = () => {
    return (
      <div className="submit-video-response-wrapper">
        {(videoState.isSaved || !videoState.isClosedWithoutSaved) &&
          videoState.videoUrl ? (
          <div className="justify-center">
            <video controls src={videoState.videoUrl} />
          </div>
        ) : (
          <div>
            <Flex className="vider-recorder-wrapper">
              <VideoRecorder
                onRecordingComplete={(videoBlob) => {
                  if (
                    JSON.parse(JSON.stringify(videoState)).isClosedWithoutSaved
                  ) {
                    setVideoState((prevState) => ({
                      isVideoRecorded: true,
                      isSaved: false,
                      videoUrl: "",
                      isClosedWithoutSaved: false,
                    }));
                  } else {
                    setVideoState((prevState) => ({
                      ...prevState,
                      videoUrl: videoBlob ? URL.createObjectURL(videoBlob) : "",
                      isVideoRecorded: true,
                    }));
                  }
                  // }
                }}
                isFlipped={false}
                className="videonote-box"
                renderDisconnectedView={() => <div></div>}
                replayVideoAutoplayAndLoopOff
                timeLimit={300000}
              />
            </Flex>
          </div>
        )}
      </div>
    );
  };

  const ReturnVoiceContent = () => {
    return (
      <div className="justify-center">
        {(voiceState.isSaved || voiceState.isDone) && voiceState.audioUrl ? (
          <audio
            controls
            src={voiceState.audioUrl ? voiceState.audioUrl : ""}
          />
        ) : voiceState.isPause ? (
          <>
            <Flex column gap="gap.smaller">
              <Text content={timer} className="justify-center" />
              <div className="justify-center">
                <PlayIcon
                  className="play-bg-color icon-padding"
                  circular
                  size="large"
                  onClick={() => {
                    setVoiceState({
                      ...voiceState,
                      isStart: true,
                      isPause: false,
                    });
                    resumeRecording();
                  }}
                />
              </div>
              <Text
                content={feedbackTranslation.resume}
                className="justify-center"
              />
            </Flex>
          </>
        ) : voiceState.isStart ? (
          <>
            <Flex column gap="gap.smaller">
              <Text content={timer} className="justify-center" />
              <div className="justify-center">
                <PauseIcon
                  className="pause-bg-color icon-padding"
                  circular
                  size="large"
                  onClick={() => {
                    setVoiceState({
                      ...voiceState,
                      isStart: false,
                      isPause: true,
                    });
                    pauseRecording();
                  }}
                />
              </div>
              <Text
                content={feedbackTranslation.pause}
                className="justify-center"
              />
            </Flex>
          </>
        ) : (
          <>
            <Flex column gap="gap.smaller">
              <Text content={timer} className="justify-center" />
              <div className="justify-center">
                <MicIcon
                  className="mic-bg-color icon-padding"
                  circular
                  size="large"
                  onClick={() => {
                    setVoiceState({ ...voiceState, isStart: true });
                    startRecording();
                  }}
                />
              </div>
              <Text
                content={feedbackTranslation.start}
                className="justify-center"
              />
            </Flex>
          </>
        )}
      </div>
    );
  };

  const debounce = (func) => {
    let timer;
    return function (...args) {
      const context = this;
      if (timer) clearTimeout(timer);
      timer = setTimeout(() => {
        timer = null;
        func.apply(context, args);
      }, 500);
    };
  };

  const validateResponse = () => {
    let isValid = false;
    if (feedbackQuestion.questionType === FEEDBACK_TYPES.text) {
      isValid = !!textResponse?.replace(/\s/g, "")?.length;
    }
    if (feedbackQuestion.questionType === FEEDBACK_TYPES.video) {
      isValid = videoState.isSaved && videoState.videoUrl?.length;
    }
    if (feedbackQuestion.questionType === FEEDBACK_TYPES.audio) {
      isValid = voiceState.isDone && voiceState.audioUrl?.length;
    }
    if (feedbackQuestion.questionType === FEEDBACK_TYPES.poll) {
      isValid = selectedOption;
    }
    if (feedbackQuestion.questionType === FEEDBACK_TYPES.any) {
      isValid =
        !!textResponse?.replace(/\s/g, "")?.length ||
        (videoState.isSaved && videoState.videoUrl?.length) ||
        (voiceState.isDone && voiceState.audioUrl?.length);
    }

    return isValid;
  };

  const nextBtnHandler = () => {
    setIsValidateResponse(true);

    let isValid = validateResponse();

    if (isValid) {
      setQuestionResponses((prev) =>
        prev.map((item) =>
          item.questionId === feedbackQuestion.feedbackQuestionId
            ? {
              ...item,
              textResponse: textResponse,
              audioResponse: voiceState.audioUrl,
              pollResponse:
                feedbackQuestion.questionType === FEEDBACK_TYPES.poll
                  ? selectedOption
                  : null,
              videoResponse: videoState.videoUrl,
              // feedbackRequestId: feedbackData.id,
              // submittedBy: user.mail,
            }
            : item
        )
      );
      if (currentQuestion === questionResponses.length - 1) {
        let finalQuestionResponse = questionResponses.find(
          (item) => item.questionId === feedbackQuestion.feedbackQuestionId
        );
        submitFeedbackHandler({
          ...finalQuestionResponse,
          textResponse: textResponse,
          audioResponse: voiceState.audioUrl,
          pollResponse:
            feedbackQuestion.questionType === FEEDBACK_TYPES.poll
              ? selectedOption
              : null,
          videoResponse: videoState.videoUrl,
        });
      } else {
        setCurrentQuestion((prev) => prev + 1);
      }
      setIsValidateResponse(false);
    }
  };

  const previousBtnHandler = () => {
    setQuestionResponses((prev) =>
      prev.map((item) =>
        item.questionId === feedbackQuestion.feedbackQuestionId
          ? {
            ...item,
            textResponse: textResponse,
            audioResponse: voiceState.audioUrl,
            pollResponse:
              feedbackQuestion.questionType === FEEDBACK_TYPES.poll
                ? selectedOption
                : null,
            videoResponse: videoState.videoUrl,
            // feedbackRequestId: feedbackData.id,
            // submittedBy: user.mail,
          }
          : item
      )
    );
    setCurrentQuestion((prev) => prev - 1);
  };

  return (
    <>
      <Dialog
        open={confirmVideoDelete}
        onOpen={() => setConfirmVideoDelete(true)}
        onCancel={() => getConfirmFlagForVideo(false)}
        onConfirm={() => getConfirmFlagForVideo(true)}
        confirmButton={commonTranslation.yes}
        cancelButton={commonTranslation.no}
        header={feedbackTranslation.deleteVideo}
        content={
          <Flex column>
            <Text>{feedbackTranslation.videoDeleteConfirmation}</Text>
          </Flex>
        }
        headerAction={{
          icon: <CloseIcon />,
          title: commonTranslation.close,
          onClick: () => {
            setConfirmVideoDelete(false);
          },
        }}
        styles={{ maxWidth: "600px" }}
      />
      <Dialog
        open={isConfirmDeleteAudio}
        onOpen={() => setIsConfirmDeleteAudio(true)}
        onCancel={() => getConfirmFlag(false)}
        onConfirm={() => getConfirmFlag(true)}
        confirmButton={commonTranslation.yes}
        cancelButton={commonTranslation.no}
        header={feedbackTranslation.deleteVoiceResponse}
        content={
          <Flex column>
            <Text>{feedbackTranslation.voiceDeleteConfirmation}</Text>
          </Flex>
        }
        headerAction={{
          icon: <CloseIcon />,
          title: commonTranslation.close,
          onClick: () => {
            setIsConfirmDeleteAudio(false);
          },
        }}
        styles={{ maxWidth: "600px" }}
      />

      <Flex column>
        {((feedbackData?.feedbackType === FEEDBACK_TYPES.any &&
          feedbackData?.isMixMode) ||
          [FEEDBACK_TYPES.text, FEEDBACK_TYPES.poll].includes(
            feedbackData?.feedbackType
          )) && (
            <div>
              <Text weight="semibold" size="large">
                {feedbackQuestion.questionTitle ?? ""}
              </Text>
            </div>
          )}
        {feedbackQuestion.questionType === FEEDBACK_TYPES.text ||
          feedbackQuestion.questionType === FEEDBACK_TYPES.any ? (
          <Flex column>
            <Flex column style={{ margin: "15px 0" }} gap="gap.small">
              <Text weight="semibold">
                {feedbackTranslation.writeDownResponse}
                <Text style={{ color: "red" }}>*</Text>
              </Text>
              <TextArea
                fluid
                // onChange={(e, { value }) => optimizedFn(value)}
                value={textResponse}
                onChange={(e, { value }) => setTextResponse(value)}
                rows="8"
                error={
                  isValidateResponse &&
                  feedbackQuestion.questionType === FEEDBACK_TYPES.text &&
                  !validateResponse()
                }
              />
              {isValidateResponse &&
                feedbackQuestion.questionType === FEEDBACK_TYPES.text &&
                !validateResponse() ? (
                <Text className="response-validation-text">
                  {feedbackTranslation.textResponseSubmitValidation}
                </Text>
              ) : null}
            </Flex>
          </Flex>
        ) : null}
        {feedbackQuestion.questionType === FEEDBACK_TYPES.video ||
          feedbackQuestion.questionType === FEEDBACK_TYPES.any ? (
          <Flex column style={{ margin: "15px 0" }}>
            <div>
              <Text weight="semibold">
                {feedbackTranslation.submitResponseByVideo}
              </Text>
            </div>
            <Flex>
              {videoState.isSaved && videoState.videoUrl ? (
                <Flex style={{ marginTop: "10px" }}>
                  <Flex column style={{ maxWidth: "400px" }}>
                    <video
                      src={videoState.videoUrl}
                      controls
                      style={{
                        width: "100%",
                        borderRadius: "12px",
                      }}
                    />
                    <Flex style={{ margin: "10px 0 10px 10px" }}>
                      <Button
                        primary
                        onClick={() => {
                          // setVideoState({
                          //   ...videoState,
                          //   videoUrl: "",
                          //   isSaved: false,
                          // });
                          setConfirmVideoDelete(true);
                        }}
                      >
                        {feedbackTranslation.deleteVideo}
                      </Button>
                    </Flex>
                  </Flex>
                </Flex>
              ) : (
                <Flex column>
                  <Flex vAlign="center" wrap>
                    <Flex style={{ margin: "5px" }}>
                      <Card
                        elevated
                        className="file-browse-section-card"
                        fluid
                        styles={{
                          ":hover": { backgroundColor: "white" },
                          backgroundColor: "white",
                          width: "275px",
                          maxHeight: "170px",
                          borderRadius: "4px",
                          padding: "3px",
                        }}
                      >
                        <CardBody
                          style={{
                            marginBottom: "0px",
                            marginTop: "0px",
                            height: "100%",
                          }}
                        >
                          <FileUploader
                            multiple={false}
                            handleChange={(file) => {
                              let blobUrl = URL.createObjectURL(file);
                              setVideoState({
                                ...videoState,
                                videoUrl: blobUrl,
                                isSaved: true,
                              });
                            }}
                            types={[
                              "webm",
                              "mp4",
                              "mkv",
                              "3gp",
                              "mov",
                            ]}
                            className="file-browse-section-outer-main"
                            //   value={file}
                            children={
                              <Flex
                                hAlign="center"
                                style={{
                                  border: " 1px dashed gray",
                                }}
                              >
                                <div style={{ padding: "65px 0" }}>
                                  <Flex column vAlign="center">
                                    <Text
                                      align="center"
                                      size="small"
                                      weight="bold"
                                    >
                                      {feedbackTranslation.uploadVideo + " :"}
                                    </Text>
                                    <Text align="center" size="small">
                                      WEBM, MP4, MKV, 3GP, MOV.
                                    </Text>
                                  </Flex>
                                </div>
                              </Flex>
                            }
                            name="file"
                            fluid
                          />
                        </CardBody>
                      </Card>
                    </Flex>
                    <Flex>
                      <Text
                        style={{ fontSize: "12px", margin: "5px" }}
                        weight="bold"
                      >
                        {feedbackTranslation.or}
                      </Text>
                    </Flex>
                    <Flex style={{ margin: "5px" }}>
                      <Card
                        elevated
                        className="file-browse-section-card"
                        fluid
                        styles={{
                          ":hover": { backgroundColor: "white" },
                          backgroundColor: "white",
                          width: "275px",
                          maxHeight: "170px",
                          borderRadius: "4px",
                          padding: "3px",
                        }}
                      >
                        <CardBody
                          style={{
                            marginBottom: "0px",
                            marginTop: "0px",
                            height: "100%",
                          }}
                        >
                          <Flex
                            hAlign="center"
                            style={{ border: "1px dashed gray" }}
                          >
                            <div style={{ padding: "61px 0" }}>
                              <Flex column vAlign="center">
                                <Text align="center" size="small" weight="bold">
                                  {feedbackTranslation.recordVideo}
                                </Text>

                                <FeedbackDialog
                                  button={
                                    <Button size="small">
                                      {feedbackTranslation.recordNow}
                                    </Button>
                                  }
                                  confirmButton={
                                    videoState.isSaved
                                      ? feedbackTranslation.deleteThisVideo
                                      : commonTranslation.save
                                  }
                                  cancelButton={"Retake Video"}
                                  header={
                                    <Flex column>
                                      <Text align="start">
                                        {feedbackTranslation.recordVideo}
                                      </Text>
                                      <Text weight="light" size="small">
                                        {feedbackTranslation.videoValidation}
                                      </Text>
                                    </Flex>
                                  }
                                  onConfirm={() => {
                                    handleVideoRecordConfirm();
                                  }}
                                  onOpen={() =>
                                    setVideoState({
                                      ...videoState,
                                      open: true,
                                      isClosedWithoutSaved: false,
                                    })
                                  }
                                  onCancel={() =>
                                    videoState?.isVideoRecorded && setVideoState({
                                      ...videoState,
                                      videoUrl: null,
                                      isVideoRecorded: false
                                    })
                                  }
                                  content={<ReturnVideoContent />}
                                  headerAction={{
                                    icon: <CloseIcon />,
                                    title: commonTranslation.close,
                                    onClick: () => {
                                      setVideoState({
                                        ...videoState,
                                        open: false,
                                        // isStart: false,
                                        // isStop: false,
                                        isClosedWithoutSaved: true,
                                      });
                                    },
                                  }}
                                />
                              </Flex>
                            </div>
                          </Flex>
                        </CardBody>
                      </Card>
                    </Flex>
                  </Flex>
                  {isValidateResponse &&
                    feedbackQuestion.questionType === FEEDBACK_TYPES.video &&
                    !validateResponse() ? (
                    <Text className="response-validation-text">
                      {feedbackTranslation.videoResponseSubmitValidation}
                    </Text>
                  ) : null}
                </Flex>
              )}
            </Flex>
          </Flex>
        ) : null}
        {feedbackQuestion.questionType === FEEDBACK_TYPES.audio ||
          feedbackQuestion.questionType === FEEDBACK_TYPES.any ? (
          <Flex column style={{ margin: "15px 0" }}>
            <div>
              <Text weight="semibold">
                {feedbackTranslation.submitResponseByAudio}
              </Text>
            </div>
            <Flex vAlign="center" column>
              <Flex style={{ margin: "5px" }}>
                <Card
                  elevated
                  className="file-browse-section-card"
                  fluid
                  styles={{
                    ":hover": { backgroundColor: "white" },
                    backgroundColor: "white",
                    width: "275px",
                    maxHeight: "170px",
                    borderRadius: "4px",
                    padding: "3px",
                  }}
                >
                  <CardBody
                    style={{
                      marginBottom: "0px",
                      marginTop: "0px",
                      height: "100%",
                    }}
                  >
                    <Flex hAlign="center" style={{ border: "1px dashed gray" }}>
                      <div style={{ padding: "61px 0" }}>
                        <Flex column vAlign="center">
                          <Text align="center" size="small" weight="bold">
                            {voiceState.isDone && voiceState.audioUrl
                              ? ""
                              : feedbackTranslation.recordAudio}
                          </Text>

                          <Dialog
                            open={voiceState.open}
                            trigger={
                              <Button size="small">
                                {voiceState.isDone && voiceState.audioUrl
                                  ? feedbackTranslation.listenRecording
                                  : feedbackTranslation.recordNow}
                              </Button>
                            }
                            confirmButton={
                              voiceState.isDone
                                ? feedbackTranslation.deleteResponse
                                : voiceState.isSaved
                                  ? commonTranslation.done
                                  : commonTranslation.save
                            }
                            onConfirm={() => {
                              handleRecordConfirm();
                            }}
                            onOpen={() =>
                              setVoiceState({
                                ...voiceState,
                                open: true,
                              })
                            }
                            onCancel={() =>
                              setVoiceState({
                                ...voiceState,
                                open: false,
                              })
                            }
                            header={
                              <Flex column>
                                <Text>
                                  {feedbackTranslation.addVoiceFeedback}
                                </Text>
                                <Text weight="light" size="small">
                                  {feedbackTranslation.voiceFeedbackValidation}
                                </Text>
                              </Flex>
                            }
                            content={<ReturnVoiceContent />}
                            headerAction={{
                              icon: <CloseIcon />,
                              title: commonTranslation.close,
                              onClick: () => {
                                stopRecording();
                                setVoiceState({
                                  ...voiceState,
                                  open: false,
                                });
                              },
                            }}
                          />
                        </Flex>
                      </div>
                    </Flex>
                  </CardBody>
                </Card>
              </Flex>
              {isValidateResponse &&
                feedbackQuestion.questionType === FEEDBACK_TYPES.audio &&
                !validateResponse() ? (
                <Text className="response-validation-text">
                  {feedbackTranslation.voiceResponseSubmitValidation}
                </Text>
              ) : null}
            </Flex>
          </Flex>
        ) : null}
        {isValidateResponse &&
          feedbackQuestion.questionType === FEEDBACK_TYPES.any &&
          !validateResponse() ? (
          <Text className="response-validation-text">
            {feedbackTranslation.anyResponseSubmitValidation}
          </Text>
        ) : null}

        {feedbackQuestion.questionType === FEEDBACK_TYPES.poll ? (
          <Flex column style={{ margin: "15px 0" }}>
            <div>
              <Text weight="semibold">
                {feedbackTranslation.submitResponseByPoll}
              </Text>
            </div>
            <Flex vAlign="center" style={{ marginTop: "15px" }}>
              <RadioGroup
                vertical
                items={
                  feedbackQuestion.pollOptions?.length
                    ? feedbackQuestion.pollOptions.map((item) => ({
                      name: "poll",
                      key: item.value,
                      label: item.value,
                      value: item.value,
                    }))
                    : []
                }
                checkedValue={selectedOption}
                onCheckedValueChange={(e, { value }) => {
                  setSelectedOption(value);
                }}
              />
            </Flex>

            {isValidateResponse && !validateResponse() ? (
              <Text className="response-validation-text">
                {feedbackTranslation.pollResponseSubmitValidation}
              </Text>
            ) : null}
          </Flex>
        ) : null}
      </Flex>
      <Flex style={{ margin: "10px 0 10px 10px" }} hAlign="end">
        <Flex gap="gap.medium">
          {questionResponses?.length > 1 && (
            <Button
              primary={!isSubmitting}
              disabled={
                isSubmitting ||
                feedbackData.status === completed ||
                currentQuestion === 0
              }
              onClick={() => {
                previousBtnHandler();
              }}
              content={commonTranslation.previous}
            />
          )}
          {currentQuestion === questionResponses.length - 1 ? (
            <Button
              primary={!isSubmitting}
              loading={isSubmitting}
              disabled={
                isSubmitting ||
                feedbackData.status === completed ||
                isFeedbackCreator
              }
              onClick={() => {
                nextBtnHandler();
              }}
              content={
                isSubmitting
                  ? feedbackTranslation.saving
                  : feedbackTranslation.submitMyResponse
              }
            />
          ) : (
            <Button
              primary={!isSubmitting}
              loading={isSubmitting}
              disabled={isSubmitting || feedbackData.status === completed}
              onClick={() => {
                nextBtnHandler();
              }}
              content={commonTranslation.next}
            />
          )}
        </Flex>
      </Flex>
    </>
  );
};

export default withTranslation()(QuestionResponse);
