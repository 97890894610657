import React, { useState } from "react";
import axios from "axios";
import {
  Button,
  Card,
  Flex,
  Checkbox,
  Text,
  Dialog,
  Input,
  TextArea,
  Divider,
  Dropdown,
  CloseIcon,
} from "@fluentui/react-northstar";
import {
  AddIcon,
  EditIcon,
  SaveIcon,
  FilesUploadIcon,
} from "@fluentui/react-icons-northstar";
import DownloadCFTemplate from "./Downloads/DownloadCFTemplate";
import { useTranslation } from "react-i18next";
import XLSX from "xlsx";
import parse from "html-react-parser";
import "./CustomFields.scss";
import { toast } from "react-toastify";

const CustomFields = (props) => {
  const { t } = useTranslation();
  const url = process.env.REACT_APP_EP_URL;
  const [CustomFields, setCustomFields] = useState([]);
  const [file, setFile] = useState(null);
  const [state, setState] = useState({});
  const [data, setData] = useState([]);
  const [accDlg, setAccDlg] = React.useState(false);
  const [selCFT, setSelCFT] = React.useState(null);
  const [newSelCFT, setNewSelCFT] = React.useState(null);
  const [openModal, setOpenModal] = React.useState(false);
  const [incorrectMessage, setInCorrectMessage] = React.useState("");

  const getCFData = () => {
    axios
      .get(`${url}/api/startup/get-custom-fields${props.user.slug}`)
      .then((res) => {
        setCustomFields(res.data.result || []);
      })
      .catch((err) => {
        toast.error(err?.response?.data?.message);
        console.log(err);
        if (err.response?.status === 401) {
          setAccDlg(true);
        }
      });
  };
  React.useEffect(() => {
    getCFData();
  }, []);

  const GetExcelColumnName = (colIndex) => {
    let letter = "";
    while (colIndex > 0) {
      let remainder = colIndex % 26;
      if (remainder === 0) {
        remainder = 26;
      }
      letter = String.fromCharCode(64 + remainder) + letter;
      colIndex = Math.floor((colIndex - 1) / 26);
    }
    return letter;
  };

  const convertFileToJson = (file) => {
    if (file) {
      return new Promise((res, rej) => {
        const reader = new FileReader();
        const rABS = !!reader.readAsBinaryString;

        reader.onload = (e) => {
          // Date wise file converting
          const bstr = e.target.result;
          const wb = XLSX.read(bstr, {
            type: rABS ? "binary" : "array",
            bookVBA: true,
            cellDates: true,
          });

          /* Get first worksheet */
          const wsname = wb.SheetNames[0];
          const ws = wb.Sheets[wsname];

          /* Convert array of arrays */
          let data = XLSX.utils.sheet_to_json(ws, {
            defval: "",
          });

          // let columnNames = [];
          // Object.keys(data[0]).map((key, index) => {
          //   const columnName = GetExcelColumnName(index + 1);
          //   columnNames.push(columnName);
          // });

          // if (columnNames.length > 0) {
          //   columnNames.forEach((_, index) => {
          //     const nextColumnName = columnNames[index + 1];
          //     if (!nextColumnName || nextColumnName === undefined) return;
          //     if (
          //       !ws[`A${data.length + 1}`] ||
          //       !ws[`${nextColumnName}${data.length + 1}`]
          //     ) {
          //       setOpenModal(true);
          //     }
          //   });
          // }

          /* Update state */
          res(trimValuesOfArrayOfObject(data));
        };
        if (rABS) {
          reader.readAsBinaryString(file);
        }
      });
    }
  };

  const trimValuesOfArrayOfObject = (array) => {
    const trimedData = array.map((object) => {
      let clonedObject = { ...Object };
      Object.entries(object).map((field) => {
        clonedObject[field[0]] = field[1].trim();
      });
      return clonedObject;
    });
    return trimedData;
  };

  const handleChange = async (e) => {
    const { name } = e.target;
    const files = e.target.files;
    let data = await convertFileToJson(files[0]);

    if (data[0].StudentEmailID) {
      if (files && files[0]) {
        setFile(files[0]);
      }
    } else {
      setOpenModal(true);
    }
  };

  const handleFile = () => {
    if (file === null) {
      setState({
        dialog: true,
        msg: "Please upload the file before saving.",
      });
    } else {
      const reader = new FileReader();
      const rABS = !!reader.readAsBinaryString;

      reader.onload = (e) => {
        const bstr = e.target.result;
        const wb = XLSX.read(bstr, {
          type: rABS ? "binary" : "array",
          bookVBA: true,
          cellDates: true,
        });

        const wsname = wb.SheetNames[0];
        const ws = wb.Sheets[wsname];

        const data = XLSX.utils.sheet_to_json(ws);
        // if (CustomFields.length + 1 === Object.keys(data[0]).length) {
        setData(data);
        onSaveHandler(data);
        // }
        // else {
        //   setOpenModal(true);
        // }
      };

      if (rABS) {
        reader.readAsBinaryString(file);
      } else {
        reader.readAsArrayBuffer(file);
      }
    }
  };

  const onSaveHandler = async (data) => {
    data.map((value, index) => {
      const keys = Object.keys(data[index]);
      const newArray = keys.filter((item) => item !== "StudentEmailID");
      newArray.map((key, index) => {
        value[`CF_${index + 1}`] = value[key];
        delete value[key];
        return value;
      });
    });

    const postData = { studData: data };

    axios
      .post(
        `${url}/api/startup/update-studentData-CF${props.user.slug}`,
        postData
      )
      .then((res) => {
        setState({
          dialog: true,
          msg: "Your data has been saved successfully.",
        });
        // console.log(res);
        setFile(null);
        setData(null);
      })
      .catch((err) => {
        toast.error(err?.response?.data?.message);
        console.log(err);
        if (err.response?.status === 401) {
          setAccDlg(true);
        } else {
          setState({
            dialog: true,
            msg: "Something went wrong! please try to save again.",
          });
        }
      });
  };

  const handleEnable = (e, data, customField) => {
    let EnableField;
    let condition = false;
    EnableField = CustomFields.filter((obj) => {
      if (obj.IsEnable) {
        return true;
      }
      return false;
    }).length;

    if (data.checked) {
      if (props.user.dbData.Subscription === "Gold") {
        if (EnableField < 10) condition = true;
      }
      if (props.user.dbData.Subscription === "Bronze") {
        if (EnableField < 7) condition = true;
      }
      if (props.user.dbData.Subscription === "Silver") {
        if (EnableField < 5) condition = true;
      }
    } else {
      condition = true;
    }
    if (condition)
      axios
        .put(
          `${url}/api/startup/update-custom-field${props.user.slug}&CustomFieldID=${customField.CustomFieldID}`,
          {
            Name: customField.Name,
            Type: customField.Type,
            IsEnable: data.checked,
            Description: customField.Description,
          }
        )
        .then((res) => {
          getCFData();
        })
        .catch((err) => {
          toast.error(err?.response?.data?.message);
          console.log(err);
          if (err.response?.status === 401) {
            setAccDlg(true);
          } else {
            setState({
              dialog: true,
              msg: "Something went wrong!",
            });
          }
        });
    else return toast.warning("You exceed your limit!");
  };

  return (
    <div className="CustomFields">
      <Dialog
        style={{ width: "100%", maxWidth: "600px" }}
        className="emptyExcelFileDataModal"
        content={
          // <p>
          //   Please check your Custom field data Excel file. The format appears
          //   to be incorrect, as some fields are missing or improperly formatted.
          // </p>
          <p>
            Please check your Custom field data Excel file. The format appears
            to be incorrect.
          </p>
        }
        footer={
          <div className="text-start">
            <p>
              Please download the sample file provided below and import your
              data into it.
            </p>
            <DownloadCFTemplate data={props} CustomFields={CustomFields} />
          </div>
        }
        header="Incorrect Custom field Data Format in Excel File"
        headerAction={{
          icon: <CloseIcon />,
          title: "Close",
          onClick: () => {
            setOpenModal(false);
          },
        }}
        open={openModal}
      />
      <Dialog
        open={accDlg}
        onConfirm={() => location.reload()}
        confirmButton="Refresh Now"
        content={
          <p className="refreshMsg">
            No big deal — but we need to refresh to show your content.
          </p>
        }
        header="Authorization expire"
      />
      <div className="container">
        <div
          style={{
            overflow: "auto",
            height: props.isMobileView
              ? "calc(100vh - 170px)"
              : "calc(100vh - 180px)",
          }}
        >
          <Dialog
            open={state.dialog}
            onConfirm={() => setState({ dialog: false })}
            confirmButton="Okay"
            content={state.msg}
            header="Custom Fields"
          />
          <Flex space="between" wrap>
            <Dialog
              onConfirm={() => {
                const name = document.querySelector(
                  "#newCustomFieldName"
                ).value;
                const des = document.querySelector("#newCustomFieldDes").value;
                const chk =
                  document.querySelector("#newCustomFieldChk").ariaChecked;
                const type = newSelCFT ? newSelCFT : "Text";
                axios
                  .post(
                    `${url}/api/startup/get-custom-field-column${props.user.slug}`,
                    {
                      field: [
                        {
                          Name: name,
                          Type: type,
                          IsEnable: chk,
                          Description: des,
                        },
                      ],
                    },
                    {
                      headers: {
                        Authorization: "Beerar" + " " + props.user.accessToken,
                      },
                    }
                  )
                  .then((res) => {
                    getCFData();
                  })
                  .catch((err) => {
                    toast.error(err?.response?.data?.message);
                    console.log(err);
                    if (err.response?.status === 401) {
                      setAccDlg(true);
                    }
                  });
              }}
              confirmButton={t("setting").customField.modal.saveBtn}
              cancelButton={t("setting").customField.modal.cancelBtn}
              content={
                <Flex gap="gap.small" column>
                  <label htmlFor={"newCustomFieldName"}>
                    {t("setting").customField.modal.fieldName}
                  </label>
                  <Input
                    type="text"
                    id="newCustomFieldName"
                    placeholder={t("setting").customField.modal.fieldName}
                    fluid
                  />
                  <label htmlFor={"newCustomFieldType"}>
                    {t("setting").customField.modal.fieldType}
                  </label>

                  <Dropdown
                    id={"newCustomFieldType"}
                    items={["Text", "Boolean"]}
                    placeholder={t("setting").customField.modal.fieldType}
                    checkable
                    getA11ySelectionMessage={{
                      onAdd: (item) => {
                        setNewSelCFT(item);
                      },
                    }}
                    fluid
                  />
                  <label htmlFor={"newCustomFieldDes"}>
                    {t("setting").customField.modal.fieldDescription}
                  </label>
                  <TextArea
                    type="text"
                    id="newCustomFieldDes"
                    placeholder={
                      t("setting").customField.modal.fieldDescription
                    }
                    fluid
                  />
                  <label htmlFor={"newCustomFieldChk"}>
                    {t("setting").customField.modal.enableLable}
                  </label>
                  <Checkbox
                    toggle
                    id="newCustomFieldChk"
                    style={{ alignItems: "center" }}
                  />
                </Flex>
              }
              header={t("setting").customField.modal.addModalTitle}
              trigger={
                <Button
                  icon={<AddIcon />}
                  title="Upload"
                  content={t("setting").customField.modal.addModalBtn}
                  className="i-btn"
                  disabled={
                    CustomFields && CustomFields.length === 10 ? true : false
                  }
                />
              }
            />
          </Flex>

          <Flex gap="gap.small" wrap>
            {CustomFields &&
              CustomFields?.map((customField) => {
                return (
                  <Card
                    className="m-2"
                    aria-roledescription="custom field handling"
                    fluid
                    key={customField.CustomFieldID}
                  >
                    <Flex gap="gap.medium" vAlign="center">
                      <Flex.Item grow>
                        <Flex vAlign="center">
                          <Button
                            content={customField.Name}
                            title={"CF_" + customField.CustomFieldID}
                            primary={customField.IsEnable}
                            className="cfBtn"
                          />

                          <Flex.Item grow>
                            <Text
                              content={customField.Description}
                              style={{ margin: "10px" }}
                            />
                          </Flex.Item>
                        </Flex>
                      </Flex.Item>
                      <Flex style={{ flexDirection: "row" }}>
                        <Checkbox
                          toggle
                          id={"customFieldCheck" + customField.CustomFieldID}
                          style={{ alignItems: "center" }}
                          defaultChecked={customField.IsEnable}
                          checked={customField.IsEnable}
                          disabled={customField.CustomFieldID === 1}
                          onChange={(e, data) =>
                            handleEnable(e, data, customField)
                          }
                        />
                        <Dialog
                          onConfirm={() => {
                            const name = document.querySelector(
                              `#${
                                "customFieldName" + customField.CustomFieldID
                              }`
                            ).value;
                            const des = document.querySelector(
                              `#${"customFieldDes" + customField.CustomFieldID}`
                            ).value;
                            const type = selCFT ? selCFT : customField.Type;
                            // console.log(type);
                            if (
                              name !== customField.Name ||
                              des !== customField.Description ||
                              type !== customField.Type
                            ) {
                              axios
                                .put(
                                  `${url}/api/startup/update-custom-field${props.user.slug}&CustomFieldID=${customField.CustomFieldID}`,
                                  {
                                    Name: name,
                                    Type: type,
                                    IsEnable: customField.IsEnable,
                                    Description: des,
                                  }
                                )
                                .then((res) => {
                                  setSelCFT(null);
                                  getCFData();
                                })
                                .catch((err) => {
                                  toast.error(err?.response?.data?.message);
                                  console.log(err);

                                  if (err.response?.status === 401) {
                                    setAccDlg(true);
                                  } else {
                                    setState({
                                      dialog: true,
                                      msg: "Something went wrong!",
                                    });
                                  }
                                });
                            }
                            setSelCFT(null);
                          }}
                          onCancel={() => {
                            setSelCFT(null);
                          }}
                          confirmButton={t("setting").customField.modal.saveBtn}
                          cancelButton={
                            t("setting").customField.modal.cancelBtn
                          }
                          content={
                            <Flex gap="gap.small" column>
                              <label
                                htmlFor={
                                  "customFieldName" + customField.CustomFieldID
                                }
                              >
                                {t("setting").customField.modal.fieldName}
                              </label>
                              <Input
                                type="text"
                                id={
                                  "customFieldName" + customField.CustomFieldID
                                }
                                placeholder={
                                  t("setting").customField.modal.fieldType
                                }
                                defaultValue={customField.Name}
                                fluid
                              />
                              <label
                                htmlFor={
                                  "customFieldType" + customField.CustomFieldID
                                }
                              >
                                {t("setting").customField.modal.fieldType}
                              </label>
                              <Dropdown
                                id={
                                  "customFieldType" + customField.CustomFieldID
                                }
                                items={
                                  customField.CustomFieldID === 1
                                    ? ["Text"]
                                    : ["Text", "Boolean"]
                                }
                                placeholder={customField.Type}
                                checkable
                                getA11ySelectionMessage={{
                                  onAdd: (item) => {
                                    setSelCFT(item);
                                  },
                                }}
                                fluid
                              />
                              <label
                                htmlFor={
                                  "customFieldDes" + customField.CustomFieldID
                                }
                              >
                                {
                                  t("setting").customField.modal
                                    .fieldDescription
                                }
                              </label>
                              <TextArea
                                type="text"
                                id={
                                  "customFieldDes" + customField.CustomFieldID
                                }
                                placeholder={
                                  t("setting").customField.modal
                                    .fieldDescription
                                }
                                defaultValue={customField.Description}
                                fluid
                              />
                            </Flex>
                          }
                          header={t("setting").customField.modal.addModalTitle}
                          trigger={
                            <Button
                              icon={<EditIcon />}
                              iconOnly
                              text
                              title="Edit"
                            />
                          }
                        />
                      </Flex>
                    </Flex>
                    {/* <Flex space="between" vAlign="center" wrap>
                                        <Button content={customField.Name} title="CF1" primary={customField.IsEnable} className="cfBtn"/>
                                        <Text content={customField.Description} style={{margin:"2px 10px"}}/>
                                        <Flex gap="gap.small">
                                            <Checkbox toggle id={"customFieldCheck"+customField.CustomFieldID} style={{alignItems:"center"}} defaultChecked={customField.IsEnable} onChange={(e,data)=>{
                                                axios
                                                    .put(`${url}/api/startup/update-custom-field${props.user.slug}&CustomFieldID=${customField.CustomFieldID}`,
                                                        {
                                                            Name: customField.Name,
                                                            Type: customField.Type,
                                                            IsEnable: data.checked,
                                                            Description: customField.Description
                                                        })
                                                    .then((res) => {
                                                        getCFData();
                                                    })
                                                    .catch((err) => {
        toast.error(err?.response?.data?.message);
                                                        console.log(err);
                                                        // setState({ dialog: true, msg: "Something went wrong!" });
                                                    });
                                                }}/>
                                            <Dialog
                                                onConfirm={() => {
                                                    const name = document.querySelector(`#${"customFieldName"+customField.CustomFieldID}`).value
                                                    const des = document.querySelector(`#${"customFieldDes"+customField.CustomFieldID}`).value
                                                    axios
                                                        .put(`${url}/api/startup/update-custom-field${props.user.slug}&CustomFieldID=${customField.CustomFieldID}`,
                                                            {
                                                                Name: name,
                                                                Type: customField.Type,
                                                                IsEnable: customField.IsEnable,
                                                                Description: des
                                                            })
                                                        .then((res) => {
                                                            getCFData();
                                                        })
                                                        .catch((err) => {
        toast.error(err?.response?.data?.message);
                                                            console.log(err);
                                                            // setState({ dialog: true, msg: "Something went wrong!" });
                                                        });
                                                }}
                                                confirmButton="Save"
                                                cancelButton="cancel"
                                                content={
                                                    <Flex gap="gap.small" column>
                                                        <label htmlFor={"customFieldName"+customField.CustomFieldID}>Custom Field</label>
                                                        <Input type="text" id={"customFieldName"+customField.CustomFieldID} placeholder="Type Custom Field Name" defaultValue={customField.Name} fluid/>
                                                        <label htmlFor={"customFieldDes"+customField.CustomFieldID}>Custom Field Description</label>
                                                        <TextArea type="text" id={"customFieldDes"+customField.CustomFieldID} placeholder="Type Custom Field Description" defaultValue={customField.Description} fluid/>
                                                    </Flex>
                                                }
                                                header="Edit Custom Field"
                                                trigger={
                                                    <Button icon={<EditIcon />} iconOnly text title="Edit" />
                                                }
                                            />
                                        </Flex>
                                    </Flex> */}
                  </Card>
                );
              })}
          </Flex>
          {/* <br/> */}
          {/* <br/> */}
          <Divider />
          {/* <br/> */}
          <Flex column gap="gap.small">
            <ul
              className="Karla"
              style={{
                width: "auto",
                marginLeft: "auto",
                fontSize: "16px",
                position: "relative",
                overflow: "auto",
                height: props.isTabletOrMobileView && "calc(100vh - 279px)",
              }}
            >
              {parse(t("setting").customField.content)}
            </ul>
            <input
              type="file"
              id="file-lsn"
              name="file1"
              onChange={handleChange}
              style={{ display: "none" }}
              accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
              onClick={(event) => {
                // @ts-ignore -- this allows input to be able to choose the same file
                event.target.value = null;
              }}
            />
            <Flex wrap gap="gap.small" hAlign="end">
              <DownloadCFTemplate data={props} CustomFields={CustomFields} />
              {/* <Button icon={<FilesUploadIcon />} title="Upload" content="Upload Data" className="i-btn"/> */}
              <Button
                icon={<FilesUploadIcon />}
                content={file ? file.name : t("startUp").controls.action[1]}
                title={t("startUp").controls.action[1]}
                onClick={() => document.getElementById("file-lsn").click()}
                className="i-btn"
              />
              <Button
                icon={<SaveIcon outline size="large" />}
                content={t("startUp").controls.action[2]}
                onClick={handleFile}
                title={t("startUp").controls.action[2]}
                className="i-btn"
                primary={file ? true : false}
              />
            </Flex>
          </Flex>
        </div>
      </div>
    </div>
  );
};

export default CustomFields;
