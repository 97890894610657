import { Button, Flex, Header, Popup, Text } from "@fluentui/react-northstar";
import styled from "styled-components";
import { useStudentSubmission } from "../../../../../context/StudentSubmissionContext";

const titleColor = (status) => {
  switch (status) {
    case "Completed":
      return "#4adcea";
    case "Resubmit":
      return "#7a80eb";
    case "Grades Pending":
      return "#dab600";
    case "Pending":
      return "#cc4a31";
    default:
      return "#7a80eb";
  }
};

const AssignmentTitle = styled(Header)`
  margin: 10px;
  color: ${({ status }) => titleColor(status)};
`;

const AssignmentPopup = ({
  trigger,
  subject,
  viewAssignmentHandler,
  status,
  title,
}) => {
  const { setCurrentSubjectData } = useStudentSubmission();
  return (
    <Popup
      trigger={trigger}
      pointing
      content={
        subject.Assingments?.length && subject.assignmentStatusObj?.[status] ? (
          <Flex column onClick={(e) => e.stopPropagation()}>
            <AssignmentTitle as={"h3"} status={status}>
              {title}
            </AssignmentTitle>
            <Flex column>
              {subject.Assingments.filter(
                (assignment) =>
                  assignment.AssingmentStudents?.[0]?.submissionStatus ===
                  status
              )?.map((assignment) => (
                <Flex hAlign="start" key={assignment.id}>
                  <Button
                    text
                    style={{ justifyContent: "flex-start", fontSize: "16px" }}
                    primary
                    onClick={() => {
                      setCurrentSubjectData(subject);
                      viewAssignmentHandler(assignment, subject);
                    }}
                  >
                    {assignment.title}
                  </Button>
                </Flex>
              ))}
            </Flex>
          </Flex>
        ) : null
      }
      on={"hover"}
      mouseLeaveDelay={0}
    />
  );
};

export default AssignmentPopup;
