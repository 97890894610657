import React, { useEffect, useState } from "react";
import { Modal, Input, Row, Col, Image, Empty, Spin, Skeleton } from "antd";
import { CloseOutlined } from "@ant-design/icons";
import "antd/dist/antd.css";
import axios from "axios";
import { Flex, Button } from "@fluentui/react-northstar";

const { Search } = Input;

const StockImagesModel = ({ open, onClose, onSelectImage, eventTitle }) => {
  const [images, setImages] = useState([]);
  const [searchText, setSearchText] = useState(eventTitle || "nature");
  const [isLoading, setIsLoading] = useState(false);

  const getStockImages = async (searchQuery) => {
    setIsLoading(true);
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_EP_URL_WHITEBOARD_API}/get-stock-images`,
        {
          query: searchQuery,
        }
      );
      if (
        response.data.status === "Success" &&
        response.data.results?.photos?.length
      ) {
        const images = response.data.results?.photos.map((image) => {
          return {
            id: image.id,
            src: image.src.landscape,
            title: image.alt,
          };
        });
        setImages(images);
      }
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    getStockImages(eventTitle || "nature");
  }, []);

  const handleSearch = (value) => {
    setSearchText(value);
    getStockImages(value);
  };

  return (
    <Modal
      title="Stock images"
      open={open}
      onCancel={onClose}
      footer={null}
      closeIcon={<CloseOutlined />}
      centered
      width="80%"
    >
      <Flex hAlign="end">
        <Search
          placeholder="Search images"
          onSearch={handleSearch}
          enterButton
          style={{ width: 300 }}
          allowClear
          size="middle"
        />
      </Flex>
      <div
        style={{
          height: "calc(100vh - 400px)",
          overflow: "auto",
          marginTop: "15px",
        }}
      >
        {isLoading ? (
          <Flex vAlign="center" hAlign="center" style={{ height: "100%" }}>
            <Spin size="large" />
          </Flex>
        ) : images.length ? (
          <Row gutter={[5, 5]} style={{ width: "100%" }}>
            {images.map((image, index) => (
              <Col key={index} xs={24} sm={12} md={8} lg={6} xl={6}>
                <div
                  style={{
                    padding: "5px",
                    textAlign: "center",
                    minWidth: "100%",
                    height: "100%",
                  }}
                >
                  <Image
                    src={image.src}
                    alt={image.title}
                    style={{
                      width: "100%",
                      height: "auto",
                      objectFit: "cover",
                    }}
                    loading="lazy"
                    placeholder={
                      <Skeleton.Image
                        active={true}
                        style={{ width: "100%", height: "100%" }}
                      />
                    }
                  />
                  <Flex hAlign="center" style={{ paddingTop: "5px" }}>
                    <Button
                      style={{ borderRadius: "3px" }}
                      primary
                      onClick={() => {
                        onSelectImage(image);
                      }}
                    >
                      Select cover image
                    </Button>
                  </Flex>
                </div>
              </Col>
            ))}
          </Row>
        ) : (
          <Flex vAlign="center" hAlign="center" style={{ height: "100%" }}>
            <Empty
              image={Empty.PRESENTED_IMAGE_SIMPLE}
              description={"No images found"}
            />
          </Flex>
        )}
      </div>
    </Modal>
  );
};

export default StockImagesModel;
