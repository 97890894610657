import {
  AppsIcon,
  BookmarkIcon,
  Button,
  CallVideoIcon,
  ExcelIcon,
  Flex,
  GridIcon,
  MeetingTimeIcon,
  SplitButton,
  Text,
} from "@fluentui/react-northstar";
import { Layout, Menu } from "antd";
import * as React from "react";
import { withTranslation } from "react-i18next";
import { ReactComponent as ChatGPTIcon } from "../../Assets/images/svg/chatGpt.svg";
import { useEvent } from "../../context/EventContext";

import { useHistory } from "react-router-dom";
import ExcelImport from "../../pages/Events/Bundles/BulkImport/ExcelImport/ExcelImport";
import ImportWithAI from "../../pages/Events/Bundles/BulkImport/ImportWithAI/ImportWithAI";
import "./EventsNavBar.scss";

const { Sider } = Layout;

export function EventsNavBar(props) {
  const { t } = props;

  const history = useHistory();

  const translation = t("events");
  const navTranslation = translation.body.eventNavbar;

  const style = {
    background: "#F5F5F5",
    boxShadow: "2px 0px rgba(0, 0, 0, 0.03)",
    marginTop: 2,
    position: props.isMobileView && "absolute",
    zIndex: props.isMobileView && "2",
    // height: props.isMobileView && "calc(100% - 172px)",
  };

  const {
    setIsExcelImportOpen,
    isExcelImportOpen,
    isAIImportOpen,
    setIsAIImportOpen,
    handleCreateEventOptions,
    createEventOptions,
  } = useEvent();
  const subscription = JSON.parse(localStorage.getItem("subscription"));

  const { bulkCreateWithAi, bulkCreateWithExcel } = props.eventPermissions;
  const handleCreateEventPermissions = () => {
    let createEventOptions = [];

    if (subscription.EventsFromAI && bulkCreateWithAi) {
      createEventOptions.push({
        key: "AI",
        status: "AI",
        content: (
          <Flex vAlign="center" gap="gap.small">
            <ChatGPTIcon style={{ height: "23px" }} />
            <Text>Create multiple events from AI</Text>
          </Flex>
        ),
      });
    }
    if (subscription.EventsFromExcel && bulkCreateWithExcel) {
      createEventOptions.push({
        key: "Excel",
        status: "Excel",
        content: (
          <Flex vAlign="center" gap="gap.small">
            <ExcelIcon size="large" />
            <Text>Import events from Excel</Text>
          </Flex>
        ),
      });
    }
    handleCreateEventOptions(createEventOptions);
  };

  React.useEffect(() => {
    if (bulkCreateWithAi || bulkCreateWithExcel) {
      handleCreateEventPermissions();
    }
  }, [props.eventPermissions]);

  return (
    <Sider
      breakpoint="md"
      collapsedWidth={"0"}
      theme={"light"}
      style={style}
      className="tour-event-sidebar event-navbar-wrapper"
    >
      {props.user.role !== "Parent" && (
        <Flex column hAlign="center">
          {bulkCreateWithAi || bulkCreateWithExcel ? (
            <SplitButton
              className="create-event-button tour-my-event-create-event"
              style={{}}
              menu={createEventOptions}
              button={{
                content: navTranslation.createEvent,
              }}
              primary
              onMainButtonClick={() => {
                history.push("/event/new");
              }}
              onMenuItemClick={(e, data) => {
                if (data.status === "Excel") {
                  setIsExcelImportOpen(true);
                } else if (data.status === "AI") {
                  setIsAIImportOpen(true);
                }
              }}
              disabled={false}
            />
          ) : (
            <Button
              className="create-event-button tour-my-event-create-event"
              content={navTranslation.createEvent}
              primary
              onClick={() => {
                history.push("/event/new");
              }}
              disabled={false}
            />
          )}
        </Flex>
      )}

      {isExcelImportOpen && <ExcelImport {...props} />}

      <ImportWithAI
        {...props}
        isOpen={isAIImportOpen}
        onCloseHandler={() => {
          setIsAIImportOpen(false);
        }}
      />

      <Menu
        theme="light"
        style={{ background: "#F5F5F5", paddingTop: "10px" }}
        mode="inline"
        selectedKeys={props.location.pathname}
      >
        <Menu.Item
          key="/events/discover"
          icon={<AppsIcon outline />}
          onClick={() => props.history.push(`/events/discover`)}
        >
          {navTranslation.discoverEvents}
        </Menu.Item>
        {props.user.role !== "Parent" && (
          <Menu.Item
            key="/events/my-events"
            icon={<CallVideoIcon outline />}
            onClick={() => props.history.push(`/events/my-events`)}
          >
            {navTranslation.myEvents}
          </Menu.Item>
        )}
        <Menu.Item
          key="/events/registrations"
          icon={<MeetingTimeIcon outline />}
          onClick={() => props.history.push(`/events/registrations`)}
        >
          {navTranslation.registrations}
        </Menu.Item>
        {props.user.role !== "Parent" && (
          <Menu.Item
            key="/events/favourites"
            icon={<BookmarkIcon outline />}
            onClick={() => props.history.push(`/events/favourites`)}
          >
            {navTranslation.bookmarked}
          </Menu.Item>
        )}
        {props.user.role !== "Parent" && (
          <Menu.Item
            key="/events/bundles"
            icon={<GridIcon outline />}
            onClick={() => props.history.push(`/events/bundles`)}
          >
            {navTranslation.myBundles}
          </Menu.Item>
        )}
      </Menu>
    </Sider>
  );
}
export default withTranslation()(EventsNavBar);
