import { Box, IconButton, Tooltip } from "@mui/material";
import { Flex, Input, Dropdown } from "@fluentui/react-northstar";
import { SearchIcon } from "@fluentui/react-icons-northstar";
import React from "react";
// import CustomPagination from "../CustomPagination/CustomPagination";
import { useTranslation } from "react-i18next";
import "./TabMobileViewMenu.css";

function TabMobileViewMenu(props) {
  const { t } = useTranslation();
  return (
    <div
      style={{
        width: "100%",
        display: "flex",
        justifyContent: "center",
        height: "35px",
      }}
    >
      {props.isTabletOrMobileView && (
        <Flex
          styles={{ width: "100%" }}
          space="around"
          vAlign="center"
          hAlign="center"
          wrap
        >
          {/* <Tooltip title="Search" placement="right">
                <IconButton>
                  <SearchIcon />
                </IconButton>
              </Tooltip> */}
          <Input
            className="input"
            clearable
            placeholder={t("setting").init[2]}
            onChange={props.onSearchStudentHandler}
            styles={{
              width: "100%",
            }}
          />

        </Flex>
      )}
    </div>
  );
}

export default TabMobileViewMenu;
