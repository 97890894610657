import { useEffect, useState } from "react";
import { useEvent } from "../../../context/EventContext";

import { ExcelIcon } from "@fluentui/react-northstar";
import { ReactComponent as ChatGPTIcon } from "../../../Assets/images/svg/chatGpt.svg";

import axios from "axios";
import { useTranslation } from "react-i18next";
import { useHistory, useLocation } from "react-router-dom";
import { toast } from "react-toastify";
import { exportExcel } from "../../../utils/excel";

export const BUNDLE_IMPORT_TYPE = {
  excel: "excel",
  AI: "AI",
};

export const BUNDLE_STATUS = {
  published: "published",
  draft: "draft",
};

const URL = process.env.REACT_APP_EP_URL;

const useBundles = ({ user }) => {
  const history = useHistory();
  const { state } = useLocation();
  const { t } = useTranslation();

  const eventTranslation = t("events").body.bundles;

  const [selectedBundles, setSelectedBundles] = useState([]);

  const [allBundles, setAllBundles] = useState([]);
  const [filteredBundles, setFilteredBundles] = useState([]);

  const [isMobileView, setIsMobileView] = useState(window.innerWidth <= 767);
  const [isTabletView, setIsTabletView] = useState(
    window.innerWidth > 767 && window.innerWidth <= 991
  );
  const [isLoading, setIsLoading] = useState(false);
  const [isExportingExcel, setIsExportingExcel] = useState(false);
  const [isUpdating, setIsUpdating] = useState(false);
  const [deleteConfirmation, setDeleteConfirmation] = useState({
    isOpen: false,
    data: null,
    selectedBundlesIds: [],
    isDeleting: false,
  });
  const [isDeleteBundleEvents, setIsDeleteBundleEvents] = useState(false);

  const filterModalProps = useEvent();
  const { globalSearch, selectedDateFilter } = filterModalProps;

  const fetchBundles = async () => {
    try {
      const params = {};
      if (selectedDateFilter) {
        params.dateFilter = selectedDateFilter;
        params.isFutureDateFilter = false;
      }

      setIsLoading(true);
      const { data } = await axios.get(
        `${URL}/api/bulk-events/get-bundles${user.slug}`,
        {
          headers: {
            Authorization: `Bearer ${user.accessToken}`,
          },
          params,
        }
      );
      if (Array.isArray(data?.result)) {
        const formattedData = data.result.map(
          ({ id, title, createdAt, status, generatedFrom, eventCounts }) => ({
            id: id,
            title: title,
            createdDate: createdAt,
            type:
              generatedFrom === "EXCEL"
                ? BUNDLE_IMPORT_TYPE.excel
                : BUNDLE_IMPORT_TYPE.AI,
            status:
              status === "PUBLISHED"
                ? BUNDLE_STATUS.published
                : BUNDLE_STATUS.draft,
            totalEvents: eventCounts,
          })
        );

        setAllBundles(formattedData);
      }
    } catch (error) {
      toast.error(error?.response?.data?.message);
    } finally {
      setIsLoading(false);
    }
  };

  const handleScrollButtonVisibility = () => {
    let containerElement = document.getElementById("scroll-events-container");
    let buttonElements = document.querySelectorAll(".scroll-button-wrapper");

    const isElementHasScrollbar =
      !!containerElement &&
      containerElement.scrollWidth > containerElement.clientWidth;

    buttonElements.forEach((buttonElement) => {
      if (isElementHasScrollbar) {
        buttonElement.classList.remove("hidden");
      } else {
        buttonElement.classList.add("hidden");
      }
    });
  };

  const getCategories = async () => {
    try {
      const {
        data: { result },
      } = await axios.get(`${URL}/api/category/all${user.slug}`);
      return Array.isArray(result) ? result : [];
    } catch (error) {
      toast.error(error?.response?.data?.message);
    }
  };

  const getTags = async () => {
    try {
      const {
        data: { result },
      } = await axios.get(`${URL}/api/tag/all${user.slug}`);
      return Array.isArray(result) ? result : [];
    } catch (error) {
      toast.error(error?.response?.data?.message);
    }
  };

  const getBundleEvents = async (bundleId) => {
    try {
      const teacherId = user.mail;
      const { data } = await axios.get(
        `${URL}/api/events/${teacherId}/all${user.slug}`,
        {
          headers: {
            Authorization: `Bearer ${user.accessToken}`,
          },
          params: { bundleId },
        }
      );

      const events =
        Array.isArray(data?.result) && data.result.length ? data.result : [];
      return events;
    } catch (error) {
      console.log("error from getBundleEvents", error);
      return [];
    }
  };

  const exportBundleEventsToExcel = async (bundle) => {
    try {
      setIsExportingExcel(true);
      const allCategories = await getCategories();
      const allTags = await getTags();
      const events = await getBundleEvents(bundle.id);
      if (events.length) {
        const headers = [
          "title",
          "description",
          "startDate",
          "endDate",
          "categories",
          "tags",
          "speakers",
          "linkTitle",
          "linkURL",
          "location",
          "meetingType",
        ];
        const rows = events.map((event) => {
          const meetingType = event.isGoogleMeetMeeting
            ? "Google-meet"
            : event.isMSTeamMeeting
            ? "microsoft-teams"
            : "offline";
          const speakers = JSON.parse(event.speakers || "[]").length
            ? JSON.parse(event.speakers).join()
            : "";

          const currentEventCategories = Array.isArray(event.categories)
            ? event.categories
            : [event.categories];

          let categories = [];

          allCategories.map((category) => {
            if (currentEventCategories.includes(category.id)) {
              categories.push(category.title);
            }
          });
          categories = categories.join();
          const currentEventTags = Array.isArray(event.tags)
            ? event.tags
            : [event.tags];

          let tags = [];

          allTags.map((tag) => {
            if (currentEventTags.includes(tag.id)) {
              tags.push(tag.title);
            }
          });
          tags = tags.join();
          return [
            event.title || "",
            event.description || "",
            event.startDate || "",
            event.endDate || "",
            categories || "",
            tags || "",
            speakers,
            event.linkTitle || "",
            event.linkURL || "",
            event.location || "",
            meetingType,
          ];
        });
        exportExcel(headers, rows, bundle.title);
      }
    } catch (error) {
      toast.error("Try again later");
    } finally {
      setIsExportingExcel(false);
    }
  };

  useEffect(() => {
    const handleResize = () => {
      setIsMobileView(window.innerWidth <= 767);
      setIsTabletView(window.innerWidth <= 991);
      handleScrollButtonVisibility();
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    fetchBundles();
  }, [state, selectedDateFilter]);

  useEffect(() => {
    let filteredData = allBundles;
    const trimmedSearch = globalSearch?.trim()?.toLowerCase();
    if (trimmedSearch) {
      filteredData = filteredData.filter((item) =>
        item.title?.toLowerCase()?.includes(trimmedSearch)
      );
    }
    setFilteredBundles(filteredData);
  }, [allBundles, globalSearch]);

  const getEventImportTypeIcons = (type) => {
    switch (type) {
      case BUNDLE_IMPORT_TYPE.excel:
        return {
          title: eventTranslation.excelImport,
          icon: (
            <ExcelIcon style={{ width: "100%", height: "100%" }} size="large" />
          ),
        };
      case BUNDLE_IMPORT_TYPE.AI:
        return {
          title: eventTranslation.usingAI,
          icon: <ChatGPTIcon style={{ height: "23px" }} />,
        };
    }
  };

  const editBundleHandler = (bundle) => {
    history.push("/events/bundles/:bundleId".replace(":bundleId", bundle.id));
  };

  const deleteBundleHandler = async (bundle) => {
    try {
      setDeleteConfirmation((prev) => ({
        ...prev,
        isOpen: false,
        isDeleting: true,
      }));
      const { data } = await axios.delete(
        `${URL}/api/bulk-events/delete-bundle/${bundle.id}${user.slug}&isDeleteEvents=${isDeleteBundleEvents}`,
        {
          headers: {
            Authorization: `Bearer ${user.accessToken}`,
          },
        }
      );

      if (data?.status?.toLowerCase() === "success") {
        toast.success(data?.message);
      }
    } catch (error) {
      toast.error(error?.response?.data?.message);
    } finally {
      fetchBundles();
      setDeleteConfirmation({ isOpen: false, data: null, isDeleting: false });
    }
  };
  const deleteMultipleBundlesHandler = async (bundleIds) => {
    try {
      setDeleteConfirmation((prev) => ({
        ...prev,
        isOpen: false,
        isDeleting: true,
      }));
      const { data } = await axios.delete(
        `${URL}/api/bulk-events/delete-bundles${user.slug}&isDeleteEvents=${isDeleteBundleEvents}`,
        {
          headers: {
            Authorization: `Bearer ${user.accessToken}`,
          },
          data: {
            bundleIds, // Pass the bundleIds array in the body
          },
        }
      );

      if (data?.status?.toLowerCase() === "success") {
        toast.success(data?.message);
      }
    } catch (error) {
      toast.error(error?.response?.data?.message);
    } finally {
      fetchBundles();
      setDeleteConfirmation({
        isOpen: false,
        data: null,
        selectedBundlesIds: [],
        isDeleting: false,
      });
      setSelectedBundles([]);
    }
  };

  return {
    selectedBundles,
    setSelectedBundles,
    allBundles,
    filteredBundles,
    setFilteredBundles,

    //
    isTabletView,
    isMobileView,
    filterModalProps,
    isLoading,
    isUpdating,
    deleteConfirmation,
    setDeleteConfirmation,

    //
    getEventImportTypeIcons,
    editBundleHandler,

    deleteBundleHandler,
    deleteMultipleBundlesHandler,
    selectedDateFilter,
    isDeleteBundleEvents,
    setIsDeleteBundleEvents,
    exportBundleEventsToExcel,isExportingExcel
  };
};

export default useBundles;
