import { Flex } from "@fluentui/react-northstar";
import { useBooking } from "../../../../../context/BookingContext";
import moment from "moment";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";

const SlotList = ({ shift, slots, isMobileView }) => {
  const { slotId = "" } = useParams();
  const { selectedSlotId, setSelectedSlotId, isSubmitting } = useBooking();

  // Calculate the midpoint of the slots array
  const midpoint =
    Math.ceil(slots.length / 2) > 6 ? Math.ceil(slots.length / 2) : 6;

  // Split the slots array into two halves
  const leftSlots = isMobileView ? slots : slots.slice(0, midpoint);
  const rightSlots = isMobileView ? [] : slots.slice(midpoint);
  return (
    <Flex
      className="slot-shift-times-wrapper"
      hAlign="center"
      gap="gap.small"
      wrap
    >
      <Flex column gap="gap.small" className="slot-column">
        {leftSlots.map((slot, i) => {
          const isSelected = Number(selectedSlotId) === Number(slot.id);
          const isBooked = String(slot?.status).trim() === "booked";
          const isCurrentlyEditingSlot = Number(slotId) === Number(slot.id);
          return (
            <button
              className={`slot-shift-time  ${isSelected ? "selected" : ""}`}
              key={`${shift}-slot-left-${i + 1}`}
              onClick={() => {
                if (isCurrentlyEditingSlot || !isBooked) {
                  setSelectedSlotId(slot.id);
                }
              }}
              disabled={(isBooked && !isCurrentlyEditingSlot) || isSubmitting}
            >
              {moment(slot.startTime).format("hh:mm A")}
            </button>
          );
        })}
      </Flex>
      {rightSlots.length > 0 && (
        <Flex column gap="gap.small" className="slot-column">
          {rightSlots.map((slot, i) => {
            const isSelected = Number(selectedSlotId) === Number(slot.id);
            const isBooked = String(slot?.status).trim() === "booked";
            const isCurrentlyEditingSlot = Number(slotId) === Number(slot.id);

            return (
              <button
                className={`slot-shift-time ${isSelected ? "selected" : ""}`}
                key={`${shift}-slot-left-${i + 1}`}
                onClick={() => {
                  if (isCurrentlyEditingSlot || !isBooked) {
                    setSelectedSlotId(slot.id);
                  }
                }}
                disabled={(isBooked && !isCurrentlyEditingSlot) || isSubmitting}
              >
                {moment(slot.startTime).format("hh:mm A")}
              </button>
            );
          })}
        </Flex>
      )}
    </Flex>
  );
};

export default SlotList;
