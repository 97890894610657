import React, { useRef, useState } from "react";
import "../ACVExtension/ACVExtension.css";
import whiteboard from "../../Assets/images/svg/whiteboard.svg";
import assignment from "../../Assets/images/svg/assignment.svg";
import dashboard from "../../Assets/images/svg/dashboard.svg";
import avatar from "../../Assets/images/boy.png";
import booking from "../../Assets/images/booking_1.png";
import aiTutor from "../../Assets/images/aiTutor.JPG";
import events from "../../Assets/images/svg/event.svg";
import attendance from "../../Assets/images/svg/attendance.svg";
import feedback from "../../Assets/images/svg/feedback.svg";
import ConfirmDialog from "../Assignment/Components/ConfirmDialog/ConfirmDialog";
import { useTranslation } from "react-i18next";
import { ReCaptcha } from "../../components/ReCaptcha";
import { CircularProgress, IconButton } from "@mui/material";
import {
  drawCaptchaOnCanvas,
  generateCaptchaText,
} from "../../utils/reCaptcha";

const extensionsName = {
  ASSIGNMENT: "assignment",
  ATTANDANCE: "attendance",
  DASHBOARD: "dashboard",
  WHITEBOARD: "whiteBoard",
  EVENTS: "events",
  FEEDBACK: "feedback",
  GENERATEAVATAR: "generateAvatar",
  BOOKING: "booking",
  AITUTOR: "aiTutor",
};

function ACVExtension(props) {
  const subscription = JSON.parse(localStorage.getItem("subscription"));
  const extensions = [
    {
      id: 1,
      name: extensionsName.ATTANDANCE,
      image: attendance,
      background: "bg-primary",
      shadow: "shadow-primary",
      isSelected: true,
      isAvailable: subscription?.attendance,
    },
    {
      id: 2,
      name: extensionsName.ASSIGNMENT,
      image: assignment,
      background: "bg-warning",
      shadow: "shadow-warning",
      isSelected: true,
      isAvailable: subscription?.assingment,
    },
    {
      id: 3,
      name: extensionsName.DASHBOARD,
      image: dashboard,
      background: "bg-danger",
      shadow: "shadow-danger",
      isSelected: true,
      isAvailable: subscription?.dashboard,
    },
    {
      id: 4,
      name: extensionsName.WHITEBOARD,
      image: whiteboard,
      background: "bg-info",
      shadow: "shadow-info",
      isSelected: true,
      isAvailable: subscription?.whiteBoard,
    },
    {
      id: 5,
      name: extensionsName.EVENTS,
      image: events,
      background: "bg-success",
      shadow: "shadow-success",
      isSelected: true,
      isAvailable: subscription?.event,
    },
    {
      id: 6,
      name: extensionsName.FEEDBACK,
      image: feedback,
      background: "bg-dark",
      shadow: "shadow-dark",
      isSelected: true,
      isAvailable: subscription?.feedback,
    },
    {
      id: 7,
      name: extensionsName.GENERATEAVATAR,
      image: avatar,
      background: "bg-secondary",
      shadow: "shadow-secondary",
      isSelected: true,
      isAvailable: subscription?.GAN,
    },
    {
      id: 8,
      name: extensionsName.BOOKING,
      image: booking,
      background: "bg-light",
      shadow: "shadow-light",
      isSelected: true,
      isAvailable: subscription?.booking,
    },
    {
      id: 9,
      name: extensionsName.AITUTOR,
      image: aiTutor,
      background: "bg-light",
      shadow: "shadow-light",
      isSelected: true,
      isAvailable: subscription?.AITutor,
    },
  ];
  const [isOpenConfirmationModal, setIsOpenConfirmationModal] = useState(false);
  const [storeExtention, setStoreExtention] = useState({});
  const [captchaText, setCaptchaText] = useState("");
  const [userInput, setUserInput] = useState("");
  const canvasRef = useRef(null);
  const { t } = useTranslation();

  const initializeCaptcha = (ctx) => {
    setUserInput("");
    const newCaptcha = generateCaptchaText();
    setCaptchaText(newCaptcha);
    drawCaptchaOnCanvas(ctx, newCaptcha);
  };

  return (
    <>
      {isOpenConfirmationModal && (
        <ConfirmDialog
          id="extension-dialog"
          isConfirmDelete={isOpenConfirmationModal}
          setisConfirmDelete={() => {
            setIsOpenConfirmationModal(false);
            props.setCheckInputCaptchaBlankOrNot(false);
          }}
          cancelButton={t("extention").cancel}
          confirmButton={
            <div className="button-confirm">
              {t("extention").continue}
              {props.loader && (
                <IconButton className="loader-button">
                  <CircularProgress className="progressbar-loader" />
                </IconButton>
              )}
            </div>
          }
          content={
            <div className="captcha-content">
              <ReCaptcha
                captchaText={captchaText}
                setCaptchaText={setCaptchaText}
                userInput={userInput}
                setUserInput={setUserInput}
                canvasRef={canvasRef}
                initializeCaptcha={initializeCaptcha}
                setCheckInputCaptchaBlankOrNot={
                  props.setCheckInputCaptchaBlankOrNot
                }
                checkInputCaptchaBlankOrNot={props.checkInputCaptchaBlankOrNot}
              />
              {t("extention").content}
            </div>
          }
          header={`${
            props.selectedExtension[storeExtention.name]
              ? t("extention").deactivate
              : t("extention").activate
          } ${t("extention").extension}`}
          onConfirm={() => {
            props.updateExtension(
              {
                [storeExtention.name]:
                  !props.selectedExtension[storeExtention.name],
              },
              setIsOpenConfirmationModal,
              storeExtention,
              userInput,
              captchaText,
              canvasRef,
              initializeCaptcha
            );
          }}
          onCancel={() => {
            setIsOpenConfirmationModal(false);
            props.setCheckInputCaptchaBlankOrNot(false);
          }}
        />
      )}
      <div className="container">
        <div className="row mx-n2 btsp">
          {extensions
            .filter((data) => data.isAvailable) // Only show available extensions
            .map((data, index) => {
              return (
                <>
                  <div className="col-lg-2 col-sm-3 px-2">
                    <div
                      className={`card hover-shadow-lg mb-3 hover-card ${
                        props.selectedExtension[data.name] ? "active" : ""
                      }`}
                      onClick={() => {
                        setIsOpenConfirmationModal(true);
                        setStoreExtention(data);
                      }}
                    >
                      <span
                        className={`${
                          props.selectedExtension[data.name]
                            ? "active-round position-absolute top-1 left-1 p-2 border border-light rounded-circle"
                            : ""
                        }`}
                      ></span>
                      <div
                        className="card-body text-center py-4 px-0"
                        style={{ height: data.id === 9 && "166px" }}
                      >
                        <div
                          className="mb-3 mt-2"
                          style={{ height: data.id === 9 && "100%" }}
                        >
                          {data.id === 9 ? (
                            <div style={{ height: "100%", width: "100%" }}>
                              <img
                                src={data.image}
                                alt="dashboard-image"
                                className="img-fluid"
                                style={{
                                  width: "100%",
                                  height: "100%",
                                  objectFit: "contain",
                                }}
                              />
                            </div>
                          ) : (
                            <div
                              className={`icon icon-shape icon-lg text-white overflow-hidden ${data.background} ${data.shadow}`}
                            >
                              <img
                                src={data.image}
                                alt="dashboard-image"
                                style={{ width: data.id === 7 && "50px" }}
                              />
                            </div>
                          )}
                        </div>
                        {data.id !== 9 && (
                          <h5 className="h5 font-weight-bolder mb-0 text-capitalize extention_name">
                            {data.name}
                          </h5>
                        )}
                      </div>
                    </div>
                  </div>
                </>
              );
            })}
        </div>
      </div>
    </>
  );
}

export default ACVExtension;
