import { useEffect, useState } from "react";
import {
  Avatar,
  Button,
  CallVideoIcon,
  Card,
  CardBody,
  CardHeader,
  Flex,
  ItalicIcon,
  Loader,
  PollIcon,
  Text,
  VolumeIcon,
  Divider,
} from "@fluentui/react-northstar";
import { ActionButton, Icon } from "@fluentui/react";
import moment from "moment";
import { useHistory } from "react-router-dom";
import axios from "axios";
import { withTranslation } from "react-i18next";

import { ResponsiveImage } from "../../../../components/blocks/Images";
import noFeedbackImage from "../../../../Assets/images/conversation.png";
import userSvg from "../../../../Assets/images/svg/user.svg";

import "./SubmitFeedback.scss";
import { Col, Row } from "antd";

const all = "all";
const text = "TEXT";
const audio = "AUDIO";
const video = "VIDEO";
const poll = "POLL";
const any = "ANY";
const mix = "mix";

const DateFilterTypes = {
  today: "today",
  last3Days: "last3Days",
  last7Days: "last7Days",
  last15Days: "last15Days",
  last30Days: "last30Days",
  last90Days: "last90Days",
  all: "all",
};

const url = process.env.REACT_APP_EP_URL;

const SubmitFeedback = ({ user, globalSearch, t, isMobileView }) => {
  const history = useHistory();
  const [selectedType, setSelectedType] = useState(all);
  const [feedbacks, setFeedbacks] = useState([]);
  const [filteredFeedbacks, setFilteredFeedbacks] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [selectedDateFilter, setSelectedDateFilter] = useState(
    DateFilterTypes.today
  );

  const translation = t("feedbackModule");

  const commonTranslation = translation.common;
  const feedbackTranslation = translation.body.submitFeedback;
  const feedbackFilterTranslation = translation.body.feedbackFilter;

  const getFeedbacks = async () => {
    setIsLoading(true);
    try {
      const { data } = await axios({
        url: `${url}/api/feedback/assignedUserFeedback/${user.mail}${user.slug}`,
        headers: {
          Authorization: `Bearer ${user.accessToken}`,
        },
      });
      if (data?.result?.length) {
        let formattedData = data.result.map(({ FeedbackRequest, ...rest }) => {
          const {
            feedbackRequestId,
            feedbackType,
            title,
            userID,
            requestedDate,
            endDate,
            isStopAcceptingResponse,
            everyoneInOrganization,
            status,
            videoQuestionUrl,
            groupId,
            classId,
            pollOptions,
            feedbackRequestUser,
          } = FeedbackRequest;
          return {
            ...FeedbackRequest,
            submission: rest,
            id: feedbackRequestId,
            title,
            dateCreated: requestedDate,
            feedbackEndsOn: endDate,
            feedbackType,
            status,
            pollOptions:
              Array.isArray(pollOptions) && pollOptions.length
                ? pollOptions
                : [],
            videoUrl: videoQuestionUrl,
            createdBy: {
              ...feedbackRequestUser,
              name: `${feedbackRequestUser.firstName} ${
                feedbackRequestUser.lastName ?? ""
              }`,
              imageUrl: feedbackRequestUser.photo,
              role: feedbackRequestUser.role,
            },
          };
        });
        setFeedbacks(formattedData);
      } else {
        setFeedbacks([]);
      }
    } catch (error) {
      console.log(error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    getFeedbacks();
  }, []);

  useEffect(() => {
    let filteredFeedbacks = [];

    switch (selectedType) {
      case all:
        filteredFeedbacks = feedbacks;
        break;
      case mix:
        filteredFeedbacks = feedbacks.filter(
          (feedback) => feedback.feedbackType === any && feedback.isMixMode
        );
        break;
      default:
        filteredFeedbacks = feedbacks.filter(
          (feedback) =>
            feedback.feedbackType === selectedType ||
            (feedback.feedbackType === any &&
              !feedback.isMixMode &&
              selectedType !== poll)
        );
    }

    if (globalSearch) {
      filteredFeedbacks = filteredFeedbacks.filter((feedback) =>
        feedback.title
          ?.toLowerCase()
          ?.replace(/\s/g, "")
          ?.includes(globalSearch)
      );
    }
    filteredFeedbacks = filterByDateHandler(filteredFeedbacks);
    setFilteredFeedbacks(filteredFeedbacks);
  }, [selectedType, feedbacks, globalSearch, selectedDateFilter]);

  const getFeedbackTypeIcons = (feedback) => {
    switch (feedback.feedbackType) {
      case any:
        return feedback.isMixMode ? (
          <Text className="mixed-mode-icon">M</Text>
        ) : (
          <Icon iconName="PieSingle" />
        );
      case text:
        return <ItalicIcon outline />;
      case audio:
        return <VolumeIcon outline />;
      case video:
        return <CallVideoIcon outline />;
      case poll:
        return <PollIcon outline />;
      default:
        return <Icon iconName="PieSingle" />;
    }
  };

  const feedbackCard = ({ feedback }) => {
    return (
      <Card
        elevated
        className="fit-content submit-feedback-card"
        fluid
        styles={{
          borderRadius: "10px",
          ":hover": { backgroundColor: "white" },
          backgroundColor: "white",
        }}
        // onClick={() => {
        //   history.push(`/feedback/submit-feedback/${feedback.id}`, {
        //     feedback,
        //   });
        // }}
      >
        <CardHeader
          className="submit-feedback-card-header"
          style={{ borderRadius: "10px 10px 0 0" }}
        >
          <Flex gap="gap.small" column>
            <Flex.Item styles={{ padding: "10px" }}>
              <Text size="medium" weight="bold" color="white">
                {feedback.title}
              </Text>
            </Flex.Item>
            <Flex className="feedback-status-type-container" space="between">
              <Flex
                className="feedback-feedback-type"
                hAlign="center"
                vAlign="center"
                style={{ minWidth: "30px", minHeight: "30px" }}
              >
                {getFeedbackTypeIcons(feedback)}
              </Flex>
              <Flex
                className={`feedback-end-date`}
                hAlign="center"
                vAlign="center"
              >
                <Text>
                  {feedback.feedbackEndsOn &&
                  moment(feedback.feedbackEndsOn).isValid
                    ? moment(feedback.feedbackEndsOn).format(
                        "MMMM DD | hh:mm A"
                      )
                    : commonTranslation.notApplicable}
                </Text>
              </Flex>
            </Flex>
          </Flex>
        </CardHeader>
        <CardBody
          className="submit-feedback-card-body"
          style={{ padding: "10px 10px 0 10px" }}
        >
          <Flex
            space="between"
            vAlign="center"
            className="creator-response-section"
          >
            <Flex gap="gap.small">
              <Avatar
                image={
                  feedback.createdBy.imageUrl
                    ? feedback.createdBy.imageUrl + user.SASToken
                    : userSvg
                }
              />
              <Flex column>
                <Text align="start" weight="bold" className="email-text">
                  {feedback.createdBy.name ??
                    feedback.createdBy.email?.split("@")?.[0]}
                </Text>
                <Text
                  align="start"
                  styles={{ lineHeight: "12px" }}
                  size="small"
                >
                  {feedback.createdBy?.role?.[0]?.toUpperCase() +
                    feedback.createdBy?.role?.slice(1)}
                </Text>
              </Flex>
            </Flex>
            <ActionButton
              iconProps={{
                iconName: "Edit",
                style: { fontSize: "16px", color: "black" },
              }}
              style={{ fontWeight: "600", fontSize: "15px", margin: "5px" }}
              onClick={() => {
                history.push(`/feedback/submit-feedback/${feedback.id}`, {
                  feedback,
                });
              }}
              text={feedbackTranslation.giveFeedback}
            ></ActionButton>
          </Flex>
        </CardBody>
      </Card>
    );
  };

  const dateFilters = [
    {
      content: feedbackTranslation.filter.today,
      type: DateFilterTypes.today,
      filterHandler: (filteredData) =>
        filteredData.filter((feedbackData) => {
          return moment().isSame(moment(feedbackData.dateCreated), "date");
        }),
    },
    {
      content: feedbackTranslation.filter.last3Days,
      type: DateFilterTypes.last3Days,
      filterHandler: (filteredData) =>
        filteredData.filter((feedbackData) => {
          return moment(feedbackData.dateCreated).isBetween(
            moment().subtract(3, "days"),
            moment(),
            "date",
            "[]"
          );
        }),
    },
    {
      content: feedbackTranslation.filter.last7Days,
      type: DateFilterTypes.last7Days,
      filterHandler: (filteredData) =>
        filteredData.filter((feedbackData) => {
          return moment(feedbackData.dateCreated).isBetween(
            moment().subtract(7, "days"),
            moment(),
            "date",
            "[]"
          );
        }),
    },
    {
      content: feedbackTranslation.filter.last15Days,
      type: DateFilterTypes.last15Days,
      filterHandler: (filteredData) =>
        filteredData.filter((feedbackData) => {
          return moment(feedbackData.dateCreated).isBetween(
            moment().subtract(15, "days"),
            moment(),
            "date",
            "[]"
          );
        }),
    },
    {
      content: feedbackTranslation.filter.last30Days,
      type: DateFilterTypes.last30Days,
      filterHandler: (filteredData) =>
        filteredData.filter((feedbackData) => {
          return moment(feedbackData.dateCreated).isBetween(
            moment().subtract(30, "days"),
            moment(),
            "date",
            "[]"
          );
        }),
    },
    {
      content: feedbackTranslation.filter.last90Days,
      type: DateFilterTypes.last90Days,
      filterHandler: (filteredData) =>
        filteredData.filter((feedbackData) => {
          return moment(feedbackData.dateCreated).isBetween(
            moment().subtract(90, "days"),
            moment(),
            "date",
            "[]"
          );
        }),
    },
    {
      content: feedbackTranslation.filter.all,
      type: DateFilterTypes.all,
      filterHandler: (filteredData) => filteredData,
    },
  ];

  const filterByDateHandler = (feedbacks) => {
    let filteredData = Array.isArray(feedbacks) ? feedbacks : [];

    if (selectedDateFilter) {
      const filterHandler = dateFilters.find(
        (filterObj) => filterObj.type === selectedDateFilter
      );

      if (filterHandler) {
        filteredData = filterHandler.filterHandler(filteredData);
      }
    }

    return filteredData;
  };

  const onFilterBtnClickHandler = (filter) => {
    setSelectedDateFilter(filter);
  };

  return (
    <div style={{ padding: "20px 10px 0" }}>
      <div className="submit-feedback-container">
        <Text size="larger" weight="semibold" style={{ marginLeft: "10px" }}>
          {feedbackTranslation.feedbackRequests}
        </Text>
        <Flex column>
          {isLoading ? (
            <Loader style={{ paddingTop: "50px" }} />
          ) : (
            <Flex column>
              {isMobileView ? (
                <Flex space="between" wrap style={{ marginTop: "10px" }}>
                  {true ? (
                    <Flex column gap="gap.medium">
                      <Flex column>
                        <Flex hAlign="end">
                          <Text>{feedbackTranslation.filter.filterByType}</Text>
                        </Flex>
                        <Flex wrap style={{ gap: "5px" }}>
                          <Button
                            icon={<Icon iconName="AreaChart" />}
                            content={feedbackFilterTranslation.all}
                            iconPosition="before"
                            primary={selectedType === all}
                            style={{
                              minWidth: "30px",
                              maxWidth: "120px",
                            }}
                            onClick={() => setSelectedType(all)}
                          />
                          <Button
                            icon={<ItalicIcon outline />}
                            content={feedbackFilterTranslation.text}
                            iconPosition="before"
                            primary={selectedType === text}
                            style={{
                              minWidth: "30px",
                              maxWidth: "120px",
                            }}
                            onClick={() => setSelectedType(text)}
                          />
                          <Button
                            icon={<VolumeIcon outline />}
                            content={feedbackFilterTranslation.audio}
                            iconPosition="before"
                            primary={selectedType === audio}
                            style={{
                              minWidth: "30px",
                              maxWidth: "120px",
                            }}
                            onClick={() => setSelectedType(audio)}
                          />
                          <Button
                            icon={<CallVideoIcon outline />}
                            content={feedbackFilterTranslation.video}
                            iconPosition="before"
                            primary={selectedType === video}
                            style={{
                              minWidth: "30px",
                              maxWidth: "120px",
                            }}
                            onClick={() => setSelectedType(video)}
                          />
                          <Button
                            icon={<PollIcon outline />}
                            content={feedbackFilterTranslation.poll}
                            iconPosition="before"
                            primary={selectedType === poll}
                            style={{
                              minWidth: "30px",
                              maxWidth: "120px",
                            }}
                            onClick={() => setSelectedType(poll)}
                          />
                          <Button
                            icon={
                              <Text className="mixed-mode-icon filter-icon">
                                M
                              </Text>
                            }
                            content={feedbackFilterTranslation.mixed}
                            iconPosition="before"
                            primary={selectedType === mix}
                            style={{
                              minWidth: "30px",
                              maxWidth: "120px",
                            }}
                            onClick={() => setSelectedType(mix)}
                          />
                        </Flex>
                      </Flex>
                      <Flex column>
                        <Divider vertical size={1} />
                      </Flex>
                      <Flex column>
                        <Flex hAlign="end">
                          <Text>{feedbackTranslation.filter.filterByAge}</Text>
                        </Flex>
                        <Flex wrap style={{ gap: "5px" }}>
                          {dateFilters.map((filterObj) => (
                            <Button
                              content={filterObj.content}
                              primary={filterObj.type === selectedDateFilter}
                              style={{
                                minWidth: "30px",
                              }}
                              onClick={() =>
                                onFilterBtnClickHandler(filterObj.type)
                              }
                            />
                          ))}
                        </Flex>
                      </Flex>
                    </Flex>
                  ) : null}
                </Flex>
              ) : (
                <Flex space="between" wrap style={{ marginTop: "10px" }}>
                  {true ? (
                    <Flex
                      space="between"
                      gap="gap.medium"
                      className="feedback-action-wrapper"
                    >
                      <Flex column>
                        <Flex hAlign="end">
                          <Text>{feedbackTranslation.filter.filterByType}</Text>
                        </Flex>
                        <Flex wrap style={{ gap: "5px" }}>
                          <Button
                            icon={<Icon iconName="AreaChart" />}
                            content={feedbackFilterTranslation.all}
                            iconPosition="before"
                            primary={selectedType === all}
                            style={{
                              minWidth: "30px",
                              maxWidth: "120px",
                            }}
                            onClick={() => setSelectedType(all)}
                          />
                          <Button
                            icon={<ItalicIcon outline />}
                            content={feedbackFilterTranslation.text}
                            iconPosition="before"
                            primary={selectedType === text}
                            style={{
                              minWidth: "30px",
                              maxWidth: "120px",
                            }}
                            onClick={() => setSelectedType(text)}
                          />
                          <Button
                            icon={<VolumeIcon outline />}
                            content={feedbackFilterTranslation.audio}
                            iconPosition="before"
                            primary={selectedType === audio}
                            style={{
                              minWidth: "30px",
                              maxWidth: "120px",
                            }}
                            onClick={() => setSelectedType(audio)}
                          />
                          <Button
                            icon={<CallVideoIcon outline />}
                            content={feedbackFilterTranslation.video}
                            iconPosition="before"
                            primary={selectedType === video}
                            style={{
                              minWidth: "30px",
                              maxWidth: "120px",
                            }}
                            onClick={() => setSelectedType(video)}
                          />
                          <Button
                            icon={<PollIcon outline />}
                            content={feedbackFilterTranslation.poll}
                            iconPosition="before"
                            primary={selectedType === poll}
                            style={{
                              minWidth: "30px",
                              maxWidth: "120px",
                            }}
                            onClick={() => setSelectedType(poll)}
                          />
                          <Button
                            icon={
                              <Text className="mixed-mode-icon filter-icon">
                                M
                              </Text>
                            }
                            content={feedbackFilterTranslation.mixed}
                            iconPosition="before"
                            primary={selectedType === mix}
                            style={{
                              minWidth: "30px",
                              maxWidth: "120px",
                            }}
                            onClick={() => setSelectedType(mix)}
                          />
                        </Flex>
                      </Flex>
                      <Flex column>
                        <Divider vertical size={1} />
                      </Flex>
                      <Flex column>
                        <Flex hAlign="end">
                          <Text>{feedbackTranslation.filter.filterByAge}</Text>
                        </Flex>
                        <Flex wrap style={{ gap: "5px" }}>
                          {dateFilters.map((filterObj) => (
                            <Button
                              content={filterObj.content}
                              primary={filterObj.type === selectedDateFilter}
                              style={{
                                minWidth: "30px",
                              }}
                              onClick={() =>
                                onFilterBtnClickHandler(filterObj.type)
                              }
                            />
                          ))}
                        </Flex>
                      </Flex>
                    </Flex>
                  ) : null}
                </Flex>
              )}
              {filteredFeedbacks.length ? (
                <div style={{ marginTop: "10px" }}>
                  <Row gutter={[10, 10]} style={{margin:0}} className="submit-feedback-cards-wrapper">
                    {filteredFeedbacks.map((feedback) => (
                      <Col
                        key={feedback.id}
                        xs={24} // 100% width on extra-small screens
                        sm={24} // 50% width on small screens
                        md={12} // 33.33% width on medium screens
                        lg={8} // 25% width on large screens
                        xl={6} // 20% width on extra-large screens
                        xxl={6}
                      >
                        {feedbackCard({ feedback })}
                      </Col>
                    ))}
                  </Row>
                </div>
              ) : feedbacks.length ? (
                <Flex hAlign="center" column fill style={{ marginTop: "40px" }}>
                  <ResponsiveImage src={noFeedbackImage} />
                  <Text weight="semibold">
                    {feedbackTranslation.noFeedbackForThisType}
                  </Text>
                </Flex>
              ) : (
                <Flex hAlign="center" column fill style={{ marginTop: "40px" }}>
                  <ResponsiveImage src={noFeedbackImage} />
                  <Text weight="semibold">
                    {feedbackTranslation.nothingHere}
                  </Text>
                </Flex>
              )}
            </Flex>
          )}
        </Flex>
      </div>
    </div>
  );
};

export default withTranslation()(SubmitFeedback);
