import React from "react";

import { Loader } from "@fluentui/react-northstar";
import CustomProgressIndicator from "../../../../components/Loader/CustomProgressIndicator";
import EventForm from "../../../../components/forms/Event";
import useEditBundleEvent from "./useEditBundleEvent";

import "../../event.css";

const EditBundleEvent = ({
  editEventData,
  tags,
  categories,
  onEditCancelHandler,
  user,
  ...rest
}) => {
  const {
    teacherData,
    deleteEvent,
    onSubmit,
    formattedEvent,
    isLoading,
    isDeleting,
    isSubmitting,
  } = useEditBundleEvent({ editEventData, tags, categories, user, ...rest });
  return (
    <>
      <CustomProgressIndicator
        isLoading={isSubmitting || isDeleting}
        style={{
          progressTrack: {
            backgroundColor: "#ffffff",
          },
        }}
      />

      <div className="bundle-event-form-wrapper">
        {isLoading ? (
          <Loader />
        ) : formattedEvent ? (
          <EventForm
            key={editEventData.id}
            event={formattedEvent}
            // onSubmit={this.onSubmit}
            onSubmit={onSubmit}
            onCancel={() => {
              onEditCancelHandler();
            }}
            onDelete={deleteEvent}
            categories={categories}
            tags={tags}
            teacherData={teacherData}
            isLoading={isSubmitting || isDeleting}
            isNew={false}
            user={user}
          />
        ) : null}
      </div>
    </>
  );
};

export default EditBundleEvent;
