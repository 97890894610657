import {
  Button,
  CloseIcon,
  Dialog,
  Flex,
  Loader,
  Text,
  TrashCanIcon,
} from "@fluentui/react-northstar";
import React from "react";
import { useExternalTutors } from "../../context/ExternalTutorsContext";
import "./ConfirmModel.scss";
import { useTranslation } from "react-i18next";

const ConfirmModel = () => {
  const {
    isOpenConfirmModel,
    setIsOpenConfirmModel,
    isLoadingDelete,
    deleteTutor,
    setTutorToDelete,
  } = useExternalTutors();

  const { t } = useTranslation();

  const translation = t("booking").externalTutors.deleteConfirmationDialog;

  return (
    <Dialog
      open={isOpenConfirmModel}
      content={
        <Flex column gap="gap.medium">
          <Text content={translation.message} />
          <Flex hAlign="end" gap="gap.small">
            <Button
              content={
                <Text
                  content={translation.cancelButtonTitle}
                  weight="semibold"
                />
              }
              onClick={() => {
                setIsOpenConfirmModel(false);
                setTutorToDelete(null);
              }}
              disabled={isLoadingDelete}
            />
            <Button
              onClick={() => {
                deleteTutor();
              }}
              disabled={isLoadingDelete}
              primary
            >
              {isLoadingDelete ? (
                <Flex gap="gap.small" vAlign="center">
                  <Loader size="smaller" />
                  <Text content={`${translation.deleting}...`} />
                </Flex>
              ) : (
                <Text
                  content={translation.confirmButtonTitle}
                  weight="semibold"
                />
              )}
            </Button>
          </Flex>
        </Flex>
      }
      header={translation.heading}
      className="smallDlg delete-tutor-dialog"
      headerAction={{
        icon: <CloseIcon />,
        title: "close",
        disabled: isLoadingDelete,
        onClick: () => {
          setIsOpenConfirmModel(false);
          setTutorToDelete(null);
        },
      }}
    />
  );
};

export default ConfirmModel;
