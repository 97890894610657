import React, { createContext } from "react";
import useSetState from "../utils/useSetState";

export const OnboardingContext = createContext();

const OnboardingProvider = (props) => {
  const [state, setState] = useSetState({
    priceId: null,
    planType: null,
  });
  return (
    <OnboardingContext.Provider
      value={{ onboardingState: state, setOnboardingState: setState }}
    >
      {props.children}
    </OnboardingContext.Provider>
  );
};

export default OnboardingProvider;
