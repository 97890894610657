import React, { useEffect, useState } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import {
  Box,
  Button,
  CloseIcon,
  Dialog,
  Flex,
  Text,
} from "@fluentui/react-northstar";
import "./index.scss";
import { Col, Row } from "antd";
import { useBooking } from "../../../../../context/BookingContext";
import moment from "moment";
import MeetingDetailsForm from "../../ScheduleMeeting/MeetingDetailsForm/MeetingDetailsForm";
import { useTranslation } from "react-i18next";

const AvailableTimeSlots = (props) => {
  const { t } = useTranslation();
  const { scheduledSlots, setSelectedSlotId, selectedSlotId, isSubmitting } =
    useBooking();
  const [isOpenBookSlotDialog, setIsOpenBookSlotDialog] = useState(false);
  const [availableTimes, setAvailableTimes] = useState([]);
  const [selectedDate, setSelectedDate] = useState(
    moment().format("YYYY-MM-DD")
  );
  const sliderSettings = {
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 768, // Adjust for smaller screens
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 578, // Adjust for smaller screens
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 480, // Adjust for very small screens
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
        },
      },
    ],
  };

  function sortByStartTime(scheduleArray) {
    return scheduleArray.sort(
      (a, b) => new Date(a.startTime) - new Date(b.startTime)
    );
  }

  const filterSlotsByDate = (date) => {
    const filteredSlots = scheduledSlots.find((slot) => {
      const slotDate = moment(slot.date).format("YYYY-MM-DD");
      const selectedDate = moment(date).format("YYYY-MM-DD");
      return slotDate === selectedDate;
    });

    if (filteredSlots) {
      setAvailableTimes(sortByStartTime(filteredSlots.slots) || []);
      setSelectedDate(filteredSlots.date);
    } else {
      console.log("No slots found for the selected date");
      setAvailableTimes([]);
      setSelectedDate(date);
    }
  };

  useEffect(() => {
    scheduledSlots && filterSlotsByDate(selectedDate);
  }, [scheduledSlots]);

  const translation = t("booking").tutorProfile.availableTimeSlots;
  const commonTranslation = t("booking").common;
  return (
    <>
      <Dialog
        open={isOpenBookSlotDialog}
        onClose={() => setIsOpenBookSlotDialog(false)}
        header={`${translation.scheduleMeetingOn} ${moment(selectedDate).format(
          "DD MMM"
        )}`}
        className="book-slot-dialog"
        content={
          <MeetingDetailsForm
            {...props}
            setIsOpenBookSlotDialog={setIsOpenBookSlotDialog}
          />
        }
        headerAction={{
          icon: <CloseIcon />,
          title: commonTranslation.close,
          onClick: () => {
            setIsOpenBookSlotDialog(false);
          },
        }}
      />

      <Flex column gap="gap.large">
        <Box className="available-times">
          <Flex column gap="gap.small">
            <Text
              content={translation.availableDates}
              className="section-title"
            />
            {scheduledSlots.length ? (
              <Slider {...sliderSettings} className="date-slider">
                {scheduledSlots.map((data, index) => {
                  const isSelected = moment(data.date).isSame(
                    selectedDate,
                    "date"
                  );
                  return (
                    <Flex
                      column
                      hAlign="center"
                      key={index}
                      className={`available-date-wrapper ${
                        isSelected ? "selected" : ""
                      }`}
                      onClick={() => {
                        setSelectedDate(data.date);
                        filterSlotsByDate(data.date);
                      }}
                    >
                      <Text
                        content={moment(data.date)
                          .format("ddd")
                          .slice(0, 3)
                          .toUpperCase()}
                      />
                      <Text
                        weight="bold"
                        size="medium"
                        content={moment(data.date).format("DD MMM")}
                      />
                      <Text content={`${data.slots.length} Slots`} />
                    </Flex>
                  );
                })}
              </Slider>
            ) : (
              <Flex style={{ height: "100px" }} hAlign="center" vAlign="center">
                <Text content={translation.noSlotsAvailable} />
              </Flex>
            )}
          </Flex>
          <Flex column>
            <Text
              content={translation.availableTimes}
              className="section-title"
            />
            {availableTimes.length ? (
              <Row gutter={[15, 15]}>
                {availableTimes.map((time, index) => (
                  <Col key={index} xxl={8} xl={8} md={8} sm={8} xs={8}>
                    <Button
                      content={moment(time.startTime).format("hh:mm A")}
                      style={{ width: "100%" }}
                      disabled={isSubmitting}
                      primary={selectedSlotId === time.id}
                      onClick={() => {
                        if (selectedSlotId !== time.id) {
                          setSelectedSlotId(time.id);
                        } else {
                          setSelectedSlotId("");
                        }
                      }}
                    />
                  </Col>
                ))}
              </Row>
            ) : (
              <Flex style={{ height: "100px" }} hAlign="center" vAlign="center">
                <Text
                  content="No slots available"
                  style={{ verticalAlign: "middle" }}
                />
              </Flex>
            )}
          </Flex>
        </Box>
        <Button
          content={`${translation.scheduleMeetingOn} ${moment(
            selectedDate
          ).format("DD MMM")}`}
          primary
          fluid
          disabled={!selectedSlotId}
          onClick={() => setIsOpenBookSlotDialog(true)}
        />
      </Flex>
    </>
  );
};

export default AvailableTimeSlots;
