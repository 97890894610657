import { CloseIcon, Dialog, Flex } from "@fluentui/react-northstar";
import React from "react";
import { useEvent } from "../../../../../context/EventContext";
import ImportSteps from "./ImportSteps/ImportSteps";
import useExcelImport from "./useExcelImport";

import { Button } from "@fluentui/react-northstar";
import * as Icon from "react-feather";

import {
  ArrowLeftIcon,
  ArrowRightIcon,
  SaveIcon,
} from "@fluentui/react-northstar";

import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import "./ExcelImport.scss";

const ExcelImport = (props) => {
  const {
    step,
    onFileSelectHandler,
    selectedFile,
    isSavingData,
    saveClickHandler,
    onStepChangeHandler,
    rows,
    setRows,
    columns,
    title,
    setTitle,
    shouldShowErrors,
  } = useExcelImport(props);

  const { isExcelImportOpen, setIsExcelImportOpen } = useEvent();

  const history = useHistory();

  const { t } = useTranslation();

  const translation = t("events");
  const commonTranslation = translation.common;

  const redirectToBundlesHandler = () => {
    history.push("/events/bundles", {
      // Random new state to rerender Bundles page
      date: new Date(),
    });
  };

  const homeBtnHandler = () => {
    if (step === 3) {
      redirectToBundlesHandler();
    }
    setIsExcelImportOpen(false);
  };

  return (
    <Dialog
      open={isExcelImportOpen}
      content={
        <div>
          <ImportSteps
            step={step}
            onFileSelectHandler={onFileSelectHandler}
            selectedFile={selectedFile}
            {...{ rows, setRows, columns, title, setTitle, shouldShowErrors }}
            {...props}
          />

          <Flex hAlign="center" gap="gap.small" className="action-btn-wrapper">
            <Button
              icon={<Icon.Home size="20" />}
              onClick={() => {
                homeBtnHandler();
              }}
              disabled={isSavingData}
            />
            {step === 2 && (
              <Button
                icon={<ArrowLeftIcon />}
                content={commonTranslation.back}
                onClick={() => onStepChangeHandler(1)}
                disabled={isSavingData}
              />
            )}
            {step === 1 ? (
              <Button
                icon={<ArrowRightIcon />}
                content={commonTranslation.next}
                disabled={isSavingData || !selectedFile}
                onClick={() => {
                  onStepChangeHandler(2);
                }}
              />
            ) : (
              step === 2 && (
                <Button
                  icon={<SaveIcon size="large" outline />}
                  content={commonTranslation.save}
                  onClick={() => {
                    saveClickHandler();
                  }}
                  loading={isSavingData}
                  disabled={isSavingData || !rows.length}
                />
              )
            )}
          </Flex>
        </div>
      }
      headerAction={{
        icon: <CloseIcon />,
        title: commonTranslation.close,
        onClick: () => {
          setIsExcelImportOpen(false);
          redirectToBundlesHandler();
        },
      }}
      className="excel-import-dialog"
    />
  );
};

export default ExcelImport;
