import {
  ArchiveIcon,
  Card,
  CardFooter,
  Flex,
  Text,
} from "@fluentui/react-northstar";

const LinkCard = ({ link, isEditable, links, setLinks }) => {
  return (
    <Card elevated style={{ maxWidth: "200px" }}>
      <a
        href={link?.includes("http") ? link : `http://${link}`}
        target={"_blank"}
      >
        <Text style={{ wordBreak: "break-word" }}> {link}</Text>
      </a>
      {isEditable && (
        <CardFooter style={{ marginTop: "10px" }}>
          <Flex hAlign="end">
            <ArchiveIcon
              className="cur-pointer"
              onClick={() => {
                const data = links.filter((a) => a != link);
                setLinks(data);
              }}
            />
          </Flex>
        </CardFooter>
      )}
    </Card>
  );
};

export default LinkCard;
