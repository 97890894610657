import React from "react";

import {
  CallVideoIcon,
  CloseIcon,
  FilesTextColoredIcon,
  Flex,
  LinkIcon,
  MicIcon,
  OcrOnIcon,
  Text,
  Button,
} from "@fluentui/react-northstar";
import { withTranslation } from "react-i18next";

import { NotificationAlert } from "../../../../components/Notification/Notification";

import useAttachmentOption from "./useAttachmentOption";
import FileAttachment from "./components/FileAttachment";
import TextAttachment from "./components/TextAttachment";
import LinkAttachment from "./components/LinkAttachment";
import LinkCard from "./components/LinkCard";
import VoiceAttachment from "./components/VoiceAttachment";
import VideoAttachment from "./components/VideoAttachment";

import "react-toastify/dist/ReactToastify.css";
import "./AttachmentOption.scss";

// Change it too proper dialog
import DialogVoice from "../../../Assignment/Components/Dialog/DialogVoice";
import ConfirmDialog from "../../../Assignment/Components/ConfirmDialog/ConfirmDialog";

const AttachmentOption = ({
  setAttachmentData,
  eventData,
  isEditable,
  t,
  disabled,
}) => {
  const {
    attachmentsData,
    setAttachmentsData, // Attachments States
    linkText,
    setLinkText,
    links,
    setLinks,
    linkState,
    setLinkState,
    text,
    setText,
    textState,
    setTextState,
    file,
    setFile,
    fileState,
    setFileState,
    voiceState,
    setVoiceState,
    videoState,
    setVideoState,
    isConfirmDelete,
    setIsConfirmDelete,
    confirmTextDelete,
    setConfirmTextDelete,
    confirmVideoDelete,
    setConfirmVideoDelete,

    // Audio Recording states
    audioResult,
    audioTimer,
    startAudioRecording,
    stopAudioRecording,
    pauseAudioRecording,
    resumeAudioRecording,

    handleFileChange,

    // delete handler
    handleVoiceRecordConfirm,
    handleVideoRecordConfirm,
    handleTextDeleteConfirm,
    handleDeleteText,
    getConfirmFlagForAudio,
    getConfirmFlagForVideo,

    // display attachments
    isShowTextAttachment,
    isShowAudioAttachment,
    isShowVideoAttachment,
    isShowLinkAttachment,
    isShowPdfAttachment,
  } = useAttachmentOption({
    eventData,
    setAttachmentData,
    isEditable,
  });

  const translation = t("assignment").body;
  const attachmentTranslation = translation.attachmentOption;

  return (
    <div className="event-attachment-option">
      <ConfirmDialog
        isConfirmDelete={isConfirmDelete}
        setisConfirmDelete={setIsConfirmDelete}
        onConfirm={(val) => getConfirmFlagForAudio(val)}
        onCancel={(val) => getConfirmFlagForAudio(val)}
        header={attachmentTranslation.deleteConfirmation}
        confirmButton={translation.yes}
        cancelButton={translation.no}
      />

      <ConfirmDialog
        isConfirmDelete={confirmTextDelete}
        setisConfirmDelete={setConfirmTextDelete}
        onConfirm={(val) => {
          handleDeleteText(val);
        }}
        onCancel={(val) => setConfirmTextDelete(val)}
        header={attachmentTranslation.deleteConfirmation}
        confirmButton={translation.yes}
        cancelButton={translation.no}
      />
      <ConfirmDialog
        isConfirmDelete={confirmVideoDelete}
        setisConfirmDelete={setConfirmVideoDelete}
        onConfirm={(val) => getConfirmFlagForVideo(val)}
        onCancel={(val) => getConfirmFlagForVideo(val)}
        header={attachmentTranslation.deleteConfirmation}
        confirmButton={translation.yes}
        cancelButton={translation.no}
      />

      <div className="mt-2 mb-1">
        <Flex>
          {!isEditable ? (
            <Flex wrap gap="gap.small" style={{ rowGap: "5px" }}>
              {isShowTextAttachment && (
                <DialogVoice
                  button={
                    <Button
                      className={`attachment-btn d-flex justify-content-center align-items-center ${
                        text ? " btn-bg-primary" : ""
                      }`}
                      icon={<OcrOnIcon size="large" />}
                      title={attachmentTranslation.text.textNote}
                      iconOnly
                      style={{
                        color: text ? "white" : "black",
                      }}
                      disabled={disabled}
                    />
                  }
                  open={textState.open}
                  onOpen={() => setTextState({ ...textState, open: !!text })}
                  content={
                    <TextAttachment
                      val={text}
                      setVal={setText}
                      isEditable={isEditable}
                    />
                  }
                  headerAction={{
                    icon: <CloseIcon />,
                    title: translation.close,
                    onClick: () => {
                      setTextState({ ...textState, open: false });
                    },
                  }}
                  header={attachmentTranslation.text.textNote}
                />
              )}

              {isShowAudioAttachment && (
                <DialogVoice
                  button={
                    <Button
                      className={`attachment-btn d-flex justify-content-center align-items-center ${
                        voiceState.audioUrl ? " btn-bg-primary" : ""
                      }`}
                      disabled={disabled}
                      icon={<MicIcon size="large" />}
                      title={attachmentTranslation.voice.voiceNote}
                      iconOnly
                      style={{
                        color: voiceState.audioUrl ? "white" : "black",
                      }}
                    />
                  }
                  open={voiceState.open}
                  onOpen={() =>
                    setVoiceState({
                      ...voiceState,
                      open: !!voiceState.audioUrl,
                    })
                  }
                  onCancel={() => setVoiceState({ ...voiceState, open: false })}
                  header={attachmentTranslation.voice.voiceNote}
                  content={
                    <VoiceAttachment
                      isEditable={isEditable}
                      voiceState={voiceState}
                      timer={audioTimer}
                      setVoiceState={setVoiceState}
                      resumeAudioRecording={resumeAudioRecording}
                      pauseAudioRecording={pauseAudioRecording}
                      startAudioRecording={startAudioRecording}
                    />
                  }
                  headerAction={{
                    icon: <CloseIcon />,
                    title: translation.close,
                    onClick: () => {
                      setVoiceState({
                        ...voiceState,
                        open: false,
                      });
                    },
                  }}
                />
              )}

              {isShowVideoAttachment && (
                <DialogVoice
                  button={
                    <Button
                      className={`attachment-btn d-flex justify-content-center align-items-center
                            ${videoState.videoUrl ? " btn-bg-primary" : ""}`}
                      icon={<CallVideoIcon size="large" />}
                      title={attachmentTranslation.video.videoNote}
                      disabled={disabled}
                      iconOnly
                      style={{
                        color: videoState.videoUrl ? "white" : "black",
                      }}
                    />
                  }
                  open={videoState.open}
                  onOpen={() =>
                    setVideoState({
                      ...videoState,
                      open: !!videoState.videoUrl,
                    })
                  }
                  onCancel={() => setVideoState({ ...videoState, open: false })}
                  // onConfirm={() => {
                  //   setVideoState({ ...videoState, isSaved: true, open:false})
                  // }}
                  header={attachmentTranslation.video.videoNote}
                  content={
                    <VideoAttachment
                      isEditable={isEditable}
                      videoState={videoState}
                      setVideoState={setVideoState}
                    />
                  }
                  headerAction={{
                    icon: <CloseIcon />,
                    title: translation.close,
                    onClick: () => {
                      // stopVideoRecording();
                      setVideoState({
                        ...videoState,
                        open: false,
                        // isStart: false,
                        // isStop: false,
                        // isDone: false,
                      });
                    },
                  }}
                />
              )}

              {isShowLinkAttachment && (
                <DialogVoice
                  button={
                    <Button
                      className={`attachment-btn d-flex justify-content-center align-items-center ${
                        links.length > 0 ? " btn-bg-primary" : ""
                      }`}
                      disabled={disabled}
                      icon={<LinkIcon size="large" />}
                      iconOnly
                      title={attachmentTranslation.link.links}
                      style={{
                        color: links.length ? "white" : "black",
                      }}
                    />
                  }
                  open={linkState.open}
                  onOpen={() => {
                    setLinkState({
                      ...linkState,
                      open: !!links.length,
                    });
                  }}
                  // onConfirm={() => {
                  //   setLinkState({
                  //     ...linkState,
                  //     open: false,
                  //     isSave: true,
                  //   });
                  //   if (links.length && links.length > 0) {
                  //     NotificationAlert("Link added", "success");
                  //   }
                  // }}
                  // confirmButton="Save"
                  content={
                    <>
                      <Flex wrap>
                        {links && links.length > 0
                          ? links.map((link, index) => {
                              return (
                                <Flex key={index} style={{ margin: "10px" }}>
                                  <LinkCard
                                    link={link}
                                    links={links}
                                    setLinks={setLinks}
                                    isEditable={isEditable}
                                  />
                                </Flex>
                              );
                            })
                          : ""}
                      </Flex>
                    </>
                  }
                  headerAction={{
                    icon: <CloseIcon />,
                    title: translation.close,
                    onClick: () => {
                      setLinkState({ ...linkState, open: false });
                    },
                  }}
                  header={attachmentTranslation.link.links}
                />
              )}

              {isShowPdfAttachment && (
                <DialogVoice
                  button={
                    <Button
                      className={`attachment-btn d-flex justify-content-center align-items-center ${
                        file.length > 0 ? " btn-bg-primary" : ""
                      }`}
                      disabled={disabled}
                      iconOnly
                      icon={<FilesTextColoredIcon size="large" />}
                      title={attachmentTranslation.file.Documents}
                      style={{
                        color: file.length ? "white" : "black",
                      }}
                    />
                  }
                  open={fileState.open}
                  onOpen={() => {
                    setFileState({
                      ...fileState,
                      open: !!file.length,
                    });
                  }}
                  content={
                    <>
                      <FileAttachment
                        isEditable={isEditable}
                        handleFileChange={handleFileChange}
                        file={file}
                        setFile={setFile}
                      />
                    </>
                  }
                  headerAction={{
                    icon: <CloseIcon />,
                    title: translation.close,
                    onClick: () => {
                      setFileState({
                        ...fileState,
                        open: false,
                      });
                    },
                  }}
                  header={attachmentTranslation.file.Documents}
                />
              )}
            </Flex>
          ) : (
            <Flex wrap gap="gap.small" style={{ rowGap: "5px" }}>
              {isShowTextAttachment && (
                <DialogVoice
                  button={
                    <Button
                      className={`attachment-btn d-flex justify-content-center align-items-center ${
                        attachmentsData?.find((item) => item.type === "text")
                          ?.info
                          ? " btn-bg-primary"
                          : ""
                      }`}
                      disabled={disabled}
                      icon={<OcrOnIcon size="large" />}
                      title={attachmentTranslation.text.addText}
                      iconOnly
                      style={{
                        color: attachmentsData?.find(
                          (item) => item.type === "text"
                        )?.info
                          ? "white"
                          : "black",
                      }}
                    />
                  }
                  open={textState.open}
                  onOpen={() => setTextState({ ...textState, open: true })}
                  onConfirm={() => {
                    handleTextDeleteConfirm();
                  }}
                  confirmButton={
                    textState.isSave &&
                    !textState.isChanged &&
                    attachmentsData?.find((item) => item.type === "text")?.info
                      ? attachmentTranslation.deleteNote
                      : translation.save
                  }
                  content={
                    <TextAttachment
                      val={text}
                      setVal={(val) => setText(val)}
                      isEditable={isEditable}
                    />
                  }
                  headerAction={{
                    icon: <CloseIcon />,
                    title: translation.close,
                    onClick: () => {
                      let textData = attachmentsData?.find(
                        (item) => item.type === "text"
                      )?.info;
                      if (textData) {
                        setText(textData);
                      } else {
                        setText("");
                      }

                      setTextState({ ...textState, open: false });
                    },
                  }}
                  header={attachmentTranslation.text.addTextNote}
                />
              )}

              {isShowAudioAttachment && (
                <DialogVoice
                  button={
                    <Button
                      className={` attachment-btn d-flex justify-content-center align-items-center ${
                        voiceState.isDone ? " btn-bg-primary" : ""
                      }`}
                      disabled={disabled}
                      icon={<MicIcon size="large" />}
                      title={attachmentTranslation.voice.addVoice}
                      iconOnly
                      style={{
                        color: voiceState.isDone ? "white" : "black",
                      }}
                    />
                  }
                  open={voiceState.open}
                  onOpen={() => setVoiceState({ ...voiceState, open: true })}
                  onCancel={() => setVoiceState({ ...voiceState, open: false })}
                  onConfirm={() => {
                    handleVoiceRecordConfirm();
                  }}
                  confirmButton={
                    !isEditable
                      ? ""
                      : voiceState.isDone
                      ? attachmentTranslation.deleteNote
                      : voiceState.isSaved
                      ? translation.done
                      : translation.save
                  }
                  header={
                    <Flex column>
                      <Text align="start">
                        {attachmentTranslation.voice.addVoiceNote}
                      </Text>
                      <Text weight="light" size="small">
                        {attachmentTranslation.voice.sizeValidation}
                      </Text>
                    </Flex>
                  }
                  content={
                    <VoiceAttachment
                      isEditable={isEditable}
                      voiceState={voiceState}
                      timer={audioTimer}
                      setVoiceState={setVoiceState}
                      resumeAudioRecording={resumeAudioRecording}
                      pauseAudioRecording={pauseAudioRecording}
                      startAudioRecording={startAudioRecording}
                    />
                  }
                  headerAction={{
                    icon: <CloseIcon />,
                    title: translation.close,
                    onClick: () => {
                      stopAudioRecording();
                      setVoiceState({
                        ...voiceState,
                        open: false,
                      });
                    },
                  }}
                />
              )}

              {isShowVideoAttachment && (
                <DialogVoice
                  button={
                    <Button
                      disabled={disabled}
                      className={`attachment-btn d-flex justify-content-center align-items-center 
                      ${
                        (videoState.isDone || videoState.isSaved) &&
                        videoState.videoUrl
                          ? "btn-bg-primary"
                          : ""
                      }
                    `}
                      icon={<CallVideoIcon size="large" />}
                      title={attachmentTranslation.video.addVideo}
                      iconOnly
                      style={{
                        color:
                          (videoState.isDone || videoState.isSaved) &&
                          videoState.videoUrl
                            ? "white"
                            : "black",
                      }}
                    />
                  }
                  open={videoState.open}
                  onOpen={() => setVideoState({ ...videoState, open: true })}
                  onCancel={() => setVideoState({ ...videoState, open: false })}
                  onConfirm={() => {
                    handleVideoRecordConfirm();
                  }}
                  confirmButton={
                    videoState.isDone
                      ? attachmentTranslation.deleteNote
                      : videoState.isSaved
                      ? translation.done
                      : translation.save
                  }
                  header={
                    <Flex column>
                      <Text align="start">
                        {attachmentTranslation.video.recordVideo}
                      </Text>
                      <Text weight="light" size="small">
                        {attachmentTranslation.video.lengthValidation}
                      </Text>
                    </Flex>
                  }
                  content={
                    <VideoAttachment
                      isEditable={isEditable}
                      videoState={videoState}
                      setVideoState={setVideoState}
                    />
                  }
                  headerAction={{
                    icon: <CloseIcon />,
                    title: translation.close,
                    onClick: () => {
                      // stopVideoRecording();
                      setVideoState({
                        ...videoState,
                        open: false,
                        // isStart: false,
                        // isStop: false,
                        isClosedWithoutSaved: true,
                      });
                    },
                  }}
                />
              )}

              {isShowLinkAttachment && (
                <DialogVoice
                  button={
                    <Button
                      disabled={disabled}
                      className={`attachment-btn d-flex justify-content-center align-items-center ${
                        attachmentsData?.find((item) => item.type === "link")
                          ?.info?.length > 0
                          ? " btn-bg-primary"
                          : ""
                      }`}
                      icon={<LinkIcon size="large" />}
                      iconOnly
                      title={attachmentTranslation.link.addLink}
                      style={{
                        color: attachmentsData?.find(
                          (item) => item.type === "link"
                        )?.info?.length
                          ? "white"
                          : "black",
                      }}
                    />
                  }
                  open={linkState.open}
                  onOpen={() => {
                    setLinkState({ ...linkState, open: true });
                  }}
                  onConfirm={() => {
                    setAttachmentsData([
                      ...attachmentsData?.map((item) =>
                        item.type === "link" ? { ...item, info: links } : item
                      ),
                    ]);
                    setLinkState({
                      ...linkState,
                      open: false,
                      isSave: true,
                    });
                    if (links.length && links.length > 0) {
                      NotificationAlert(
                        attachmentTranslation.link.linkAdded,
                        "success"
                      );
                    }
                  }}
                  confirmButton={translation.save}
                  content={
                    <>
                      <LinkAttachment
                        val={linkText}
                        setVal={(val) => setLinkText(val)}
                        linkVal={links}
                        handleAdd={() => {
                          setLinkText("");
                          setLinks([...links, linkText]);
                        }}
                        attachmentTranslation={attachmentTranslation}
                      />

                      <div style={{ marginTop: "10px" }}>
                        <Flex wrap>
                          {links && links.length > 0
                            ? links.map((link, index) => {
                                return (
                                  <Flex key={index} style={{ margin: "10px" }}>
                                    <LinkCard
                                      link={link}
                                      links={links}
                                      setLinks={setLinks}
                                      isEditable={isEditable}
                                    />
                                  </Flex>
                                );
                              })
                            : ""}
                        </Flex>
                      </div>
                    </>
                  }
                  headerAction={{
                    icon: <CloseIcon />,
                    title: translation.close,
                    onClick: () => {
                      let linkData = attachmentsData?.find(
                        (item) => item.type === "link"
                      )?.info;
                      if (linkData?.length) {
                        setLinks(linkData);
                      } else {
                        setLinks([]);
                      }
                      setLinkState({ ...linkState, open: false });
                    },
                  }}
                  header={attachmentTranslation.link.addLink}
                />
              )}

              {isShowPdfAttachment && (
                <DialogVoice
                  button={
                    <Button
                      disabled={disabled}
                      className={`attachment-btn d-flex justify-content-center align-items-center  ${
                        attachmentsData?.find((item) => item.type === "file")
                          ?.info?.length > 0
                          ? "btn-bg-primary"
                          : ""
                      }`}
                      iconOnly
                      icon={<FilesTextColoredIcon size="large" />}
                      title={attachmentTranslation.file.addDocument}
                      style={{
                        color: attachmentsData?.find(
                          (item) => item.type === "file"
                        )?.info?.length
                          ? "white"
                          : "black",
                      }}
                    />
                  }
                  open={fileState.open}
                  onOpen={() => {
                    setFileState({
                      ...fileState,
                      open: true,
                    });
                  }}
                  onConfirm={() => {
                    setAttachmentsData([
                      ...attachmentsData?.map((item) =>
                        item.type === "file" ? { ...item, info: file } : item
                      ),
                    ]);
                    setFileState({
                      ...fileState,
                      open: false,
                      isSave: true,
                    });
                    if (file && file.length > 0) {
                      NotificationAlert(
                        attachmentTranslation.file.documentsAdded,
                        "success"
                      );
                    }
                  }}
                  content={
                    <>
                      <FileAttachment
                        isEditable={isEditable}
                        handleFileChange={handleFileChange}
                        file={file}
                        setFile={setFile}
                      />
                    </>
                  }
                  confirmButton={translation.save}
                  headerAction={{
                    icon: <CloseIcon />,
                    title: translation.close,
                    onClick: () => {
                      let fileData = attachmentsData?.find(
                        (item) => item.type === "file"
                      )?.info;
                      if (fileData?.length) {
                        setFile(fileData);
                      } else {
                        setFile([]);
                      }
                      setFileState({ ...linkState, open: false });

                      setFileState({
                        ...fileState,
                        open: false,
                      });
                    },
                  }}
                  header={attachmentTranslation.file.attachDocument}
                />
              )}
            </Flex>
          )}
        </Flex>
      </div>
    </div>
  );
};

export default withTranslation()(AttachmentOption);
