import React, { useEffect, useState } from "react";
import axios from "axios";

import { useAssignment } from "../../../../context/AssignmentContext";
import CreateRubricFooter from "./CreateRubricFooter";
import CreateRubricNav from "./CreateRubricNav";
import CreateRubricSection from "./CreateRubricSection";
import { newRubricData } from "./RubricConstant";

const url = process.env.REACT_APP_EP_URL;

const CreateRubric = (props) => {
  const {
    currentAssignmentData,
    setCurrentAssignmentData,
    setCurrentPage,
    selectedLesson,
  } = useAssignment();
  const [selectedLevels, setSelectedLevels] = useState(
    currentAssignmentData?.rubric?.rubricItems?.length
      ? currentAssignmentData.rubric?.rubricItems[0].levels?.length
      : 4
  );
  const [rubricData, setRubricData] = useState([]);
  const [havePoints, setHavePoints] = useState(
    currentAssignmentData?.rubric?.rubricItems?.length
      ? !!currentAssignmentData?.rubric?.rubricItems[0]?.havePoints
      : +currentAssignmentData.markingType > 0
  );

  const [rubricBankData, setRubricBankData] = useState([]);
  const [subjectRubrics, setSubjectRubrics] = useState([]);
  const [rubricTitle, setRubricTitle] = useState("");
  const [isValidateFields, setIsValidateFields] = useState(false);
  const [loading, setLoading] = useState({
    isRubricBankLoading: false,
    isMyRubricLoading: false,
  });

  useEffect(() => {
    setRubricData(
      currentAssignmentData?.rubric?.rubricItems?.length
        ? currentAssignmentData.rubric.rubricItems.map((item) => ({
            ...item,
            levels: item.levels,
          }))
        : !currentAssignmentData?.rubric?.isVisited &&
          !(currentAssignmentData?.id?.length > 0)
        ? newRubricData.map((item) => ({
            ...item,
            havePoints: !!havePoints,
            levels: item.levels.map((criteria) => ({
              ...criteria,
              points: 0,
              havePoints: !!havePoints,
            })),
          }))
        : []
    );
    setRubricTitle(currentAssignmentData?.rubric?.rubricTitle ?? "");
    setCurrentPage("rubric");
  }, []);

  const fetchRubricBankData = async (className) => {
    setLoading((prev) => ({ ...prev, isRubricBankLoading: true }));
    try {
      let response = await axios.get(
        `${url}/api/rubric/get-rubric-bank-item${props.user.slug}${
          className ? `&className=${className}` : ""
        }`,
        {
          headers: {
            Authorization: "Bearer " + props.user.accessToken,
          },
        }
      );
      if (response.data.results.length) {
        setRubricBankData([...response.data.results]);
      }
    } catch (error) {
      console.log("error", error);
    } finally {
      setLoading((prev) => ({ ...prev, isRubricBankLoading: false }));
    }
  };
  const fetchMySubjectRubrics = async (className) => {
    setLoading((prev) => ({ ...prev, isMyRubricLoading: true }));
    try {
      let response = await axios.get(
        `${url}/api/rubric/my-subject-rubrics${props.user.slug}${
          className ? `&className=${className}` : ""
        }`,
        {
          headers: {
            Authorization: "Bearer " + props.user.accessToken,
          },
        }
      );
      if (response.data.results.length) {
        setSubjectRubrics([...response.data.results]);
      }
    } catch (error) {
      console.log("error", error);
    } finally {
      setLoading((prev) => ({ ...prev, isMyRubricLoading: false }));
    }
  };

  useEffect(() => {
    let className = "";
    if (selectedLesson?.ClassName) {
      className = selectedLesson.ClassName.substring(
        0,
        selectedLesson.ClassName.lastIndexOf("-")
      ).trim();
    }

    if (props) {
      fetchRubricBankData(className);
      fetchMySubjectRubrics(className);
    }
  }, []);

  return (
    <div className="create-rubric tour-create-assignment-rubric-details mt-4">
      <CreateRubricNav
        {...{
          selectedLevels,
          setSelectedLevels,
          havePoints,
          setHavePoints,
          rubricData,
          props,
          setRubricData,
          rubricBankData,
          subjectRubrics,
          currentAssignmentData,
          loading,

        }}
        state={props.state}
        setState={props.setState}
      />
      <CreateRubricSection
        {...{
          rubricData,
          setRubricData,
          havePoints,
          selectedLevels,
          setCurrentAssignmentData,
          rubricTitle,
          setRubricTitle,
          isValidateFields,
          props,
        }}
      />
      <CreateRubricFooter
        {...{
          rubricData,
          setRubricData,
          havePoints,
          selectedLevels,
          props,
          setCurrentAssignmentData,
          currentAssignmentData,
          setIsValidateFields,
          rubricTitle,
        }}
      />
    </div>
  );
};

export default CreateRubric;
