import React, { useEffect, useState } from "react";
import {
  Card,
  Flex,
  Text,
  Button,
  Box,
  Divider,
  Label,
  Dialog,
  Input,
  Tooltip,
  TextArea,
} from "@fluentui/react-northstar";
import {
  EditIcon,
  CallVideoIcon,
  ShareGenericIcon,
  ClipboardCopiedToIcon,
  CloseIcon,
  ChatIcon,
} from "@fluentui/react-icons-northstar";
import "./MeetingCard.scss";
import moment from "moment";
import { useBooking } from "../../../../../context/BookingContext";
import { toast } from "react-toastify";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import ReviewFormModel from "../../ReviewFormModel/ReviewFormModel";
import { Avatar, Col, Row } from "antd";
import { useTranslation } from "react-i18next";
import userSvg from "../../../../../Assets/images/svg/user.svg";
import axios from "axios";
import { Controller, useForm } from "react-hook-form";

const MeetingCard = ({ data, index }) => {
  const { t } = useTranslation();

  const {
    control,
    reset,
    handleSubmit,
    formState: { errors },
  } = useForm();
  const topics = data?.topics.split(",") ?? [];

  const onSubmit = ({ cancellationReason }) => {
    const payload = {
      bookingId: data.id,
      cancellationReason,
    };
    cancelMeeting(payload);
  };

  const { user, getSchedulesMeetings } = useBooking();
  const history = useHistory();
  const tutorData = data.tutorData;
  const [isOpenShareMeetDialog, setIsOpenShareMeetDialog] = useState(false);
  const [isOpenReviewDialog, setIsOpenReviewDialog] = useState(false);
  const [isOpenCancelMeetingDialog, setIsOpenCancelMeetingDialog] =
    useState(false);

  const [isLoading, setIsLoading] = useState(false);

  const copyMeetLink = async () => {
    if (data?.meetLink) {
      try {
        navigator.clipboard.writeText(data.meetLink);
        toast.success("Link copied!", {
          closeOnClick: true,
        });
      } catch {
        toast.error("Link coping Failed!", {
          closeOnClick: true,
        });
      }
    }
  };

  const cancelMeeting = async (payload) => {
    try {
      setIsLoading(true);
      const res = await axios.delete(
        `${process.env.REACT_APP_EP_URL}/api/bookings/cancel-booking/${user.slug}`,
        { data: payload },
        {
          headers: { Authorization: "Bearer " + user.accessToken },
        }
      );
      setIsLoading(false);
      if (res.data.success) {
        toast.success("Meeting cancelled successfully!");
        setIsOpenCancelMeetingDialog(false);
        reset();
        getSchedulesMeetings();
      } else {
        toast.error("Meeting cancel failed!");
      }
    } catch (error) {
      toast.error("Meeting cancel failed!");
      setIsLoading(false);
    }
  };

  const isReviewSubmitted = data.participants.some(
    (participant) =>
      participant.email === user.mail && participant.isReviewSubmitted
  );

  const translation = t("booking").mySchedules.meetingCard;
  const commonTranslation = t("booking").common;
  const shareMeetingDialogTranslation = t("booking").shareMeetingDialog;
  const cancelMeetingTranslation = t("booking").cancelMeetingDialog

  const isUserIsTutorOfMeeting = user.mail === data.tutorData.email;

  function sliceString(str) {
    if (str.length > 80) {
      return str.slice(0, 80) + "...";
    }
    return str;
  }

  return (
    <>
      <Dialog
        open={isOpenShareMeetDialog}
        closeOnOutsideClick={true}
        content={
          <Flex gap="gap.small" style={{ width: "100%", marginTop: "20px" }}>
            <Input value={data.meetLink} className="meet-link-wrapper" />
            <Button
              iconOnly
              icon={<ClipboardCopiedToIcon />}
              onClick={copyMeetLink}
            />
          </Flex>
        }
        header={shareMeetingDialogTranslation.header}
        style={{ maxWidth: "500px" }}
        headerAction={{
          icon: <CloseIcon />,
          title: commonTranslation.close,
          onClick: () => {
            setIsOpenShareMeetDialog(false);
          },
        }}
      />
      <Dialog
        open={isOpenCancelMeetingDialog}
        className="cancel-meeting-dialog"
        content={
          <Flex column gap="gap.medium">
            <Flex column gap="gap.small">
              <Controller
                control={control}
                name="cancellationReason"
                rules={{
                  required: cancelMeetingTranslation.cancelationReasonValidation,
                  preprocess: (value) => value.trim(),
                }}
                render={({ field }) => (
                  <TextArea
                    {...field}
                    disabled={isLoading}
                    rows={8}
                    error={errors.cancellationReason}
                    placeholder={cancelMeetingTranslation.cancelationReasonPlaceholder}
                  />
                )}
              />
              {errors.cancellationReason && (
                <Text content={errors.cancellationReason?.message} error />
              )}
            </Flex>

            <Flex gap="gap.smaller" hAlign="end">
              <Button
                onClick={() => {
                  setIsOpenCancelMeetingDialog(false);
                  reset();
                }}
                content={cancelMeetingTranslation.cancelButtonTitle}
                disabled={isLoading}
              />
              <Button
                onClick={handleSubmit(onSubmit)}
                style={{ color: "white", backgroundColor: "#c4314b" }}
                content={isLoading ? `${cancelMeetingTranslation.cancelling}...` : cancelMeetingTranslation.confirmButtonTitle}
                loading={isLoading}
                disabled={isLoading}
              />
            </Flex>
          </Flex>
        }
        header={cancelMeetingTranslation.heading}
        style={{ maxWidth: "500px" }}
        headerAction={{
          icon: <CloseIcon />,
          disabled: isLoading,
          title: commonTranslation.close,
          onClick: () => {
            setIsOpenCancelMeetingDialog(false);
            reset();
          },
        }}
      />

      {isOpenReviewDialog && (
        <ReviewFormModel
          open={isOpenReviewDialog}
          onClose={() => setIsOpenReviewDialog(false)}
          meetingData={data}
          index={index}
        />
      )}
      <Card
        aria-roledescription="card with meeting information"
        className="meeting-card"
        size="large"
      >
        <Flex column vAlign="center" hAlign="center" className="date-badge">
          <Text
            content={moment(data.slot.startTime).format("MMM").toUpperCase()}
          />
          <Text
            content={moment(data.slot.startTime).format("DD")}
            weight="regular"
          />
        </Flex>
        <Card.Header>
          <Flex space="between" vAlign="center">
            <Flex gap="gap.small">
              <Text
                content={moment(data.slot.startTime).format("h:mm A")}
                weight="bold"
                color="brand"
              />
              {/* <Text
                content={moment(data.slot.startTime).format("h:mm A")}
                weight="bold"
                color="brand"
              /> */}
            </Flex>
            {/* {!user?.isTutor && (
              <Button
                icon={<ShareGenericIcon />}
                text
                iconOnly
                onClick={() => {
                  setIsOpenShareMeetDialog(true);
                }}
              />
            )} */}
          </Flex>
        </Card.Header>
        <Card.Body>
          <Text content={data?.title ?? ""} weight="bold" size="large" />
          <Row gutter={[5, 5]} style={{ marginTop: "10px" }}>
            {topics.map((topic, i) => {
              const isShow = topics.length > 4 ? i < 3 : true;
              const span = topics.length > 3 ? 6 : undefined;
              return (
                isShow && (
                  <Col xl={span} xxl={span} md={span} key={i}>
                    <Tooltip
                      content={topic}
                      trigger={
                        <Label
                          content={topic}
                          circular
                          color="brand"
                          className="badge"
                          primary
                        />
                      }
                    />
                  </Col>
                )
              );
            })}
            {topics.length > 4 && (
              <Col xl={6} xxl={6} md={6}>
                <Tooltip
                  content={
                    <Flex wrap gap="gap.small">
                      {topics.map((topic, i) => {
                        const isShow = i > 2;
                        return (
                          isShow && (
                            <Tooltip
                              content={topic}
                              trigger={
                                <Label
                                  key={i}
                                  style={{
                                    marginTop: "5px",
                                  }}
                                  content={topic}
                                  circular
                                  color="brand"
                                  className="badge"
                                  primary
                                />
                              }
                            />
                          )
                        );
                      })}
                    </Flex>
                  }
                  trigger={
                    <Label
                      content={`+${topics.length - 3}`}
                      circular
                      color="brand"
                      className="badge"
                      primary
                    />
                  }
                />
              </Col>
            )}
          </Row>

          <Tooltip
            content={data.note}
            trigger={
              <Text
                style={{ minHeight: "40px", width: "fit-content" }}
                content={sliceString(data.note)}
              />
            }
          />
        </Card.Body>
        <Card.Footer>
          <Flex space="between">
            <Flex column gap="gap.smaller">
              {isUserIsTutorOfMeeting ? (
                data?.participants?.length === 1 ? (
                  <Flex gap="gap.small" vAlign="center">
                    <Tooltip
                      content={data?.participants?.[0]?.name || ""}
                      trigger={
                        <Avatar src={data?.participants?.[0]?.image || ""}>
                          {data?.participants?.[0]?.name?.[0] || ""}
                        </Avatar>
                      }
                    />
                  </Flex>
                ) : data?.participants?.length > 1 ? (
                  <Flex gap="gap.small" vAlign="center">
                    <Avatar.Group maxCount={2}>
                      {data?.participants.map((participant, i) => (
                        <Tooltip
                          key={`participant-${i}`}
                          trigger={
                            <Avatar
                              src={
                                participant.image
                                  ? participant.image
                                  : !participant?.name?.[0] && userSvg
                              }
                            >
                              {participant?.name?.[0]}
                            </Avatar>
                          }
                          content={participant.name}
                        />
                      ))}
                    </Avatar.Group>
                  </Flex>
                ) : null
              ) : (
                <Flex
                  gap="gap.small"
                  vAlign="center"
                  style={{ cursor: "pointer" }}
                >
                  <Tooltip
                    content={tutorData?.name || ""}
                    trigger={
                      <Avatar
                        src={
                          tutorData?.image
                            ? tutorData?.image
                            : !tutorData?.name?.[0] && userSvg
                        }
                      >
                        {tutorData?.name?.[0] || ""}
                      </Avatar>
                    }
                  />
                </Flex>
              )}
            </Flex>

            <Flex gap="gap.medium" hAlign="end" vAlign="end">
              {/* {!user?.isTutor && moment(data.slot.endTime).isAfter() && (
                <Button
                  icon={<EditIcon />}
                  text
                  iconOnly
                  onClick={() => {
                    localStorage.setItem(
                      "editingSchedule",
                      JSON.stringify(data)
                    );
                    setEditingScheduleData(data);
                    history.push(
                      `/edit-schedule-meeting/${tutorData.email}/${tutorData.role}/${data.slotId}`
                    );
                  }}
                />
              )} */}
              {isReviewSubmitted ? null : moment(
                  data.slot.endTime
                ).isBefore() ? (
                !isUserIsTutorOfMeeting && (
                  <Button
                    content={translation.giveFeedBackButton}
                    style={{ borderRadius: "5px" }}
                    onClick={() => setIsOpenReviewDialog(true)}
                    primary
                    icon={<ChatIcon />}
                  />
                )
              ) : (
                <Flex>
                  <Button
                    content={"Cancel"}
                    style={{ borderRadius: "5px", color: "#c4314b" }}
                    onClick={() => {
                      setIsOpenCancelMeetingDialog(true);
                    }}
                    text
                    icon={<CloseIcon />}
                    iconPosition="before"
                  />
                  <Button
                    content={
                      isUserIsTutorOfMeeting
                        ? "Join Meeting"
                        : translation.startMeeting
                    }
                    style={{ borderRadius: "5px" }}
                    onClick={() => window.open(data?.meetLink, "_blank")}
                    primary
                    icon={<CallVideoIcon />}
                  />
                </Flex>
              )}
            </Flex>
          </Flex>
        </Card.Footer>
      </Card>
    </>
  );
};

export default MeetingCard;
