import React, { useEffect, useState } from "react";
import {
  Button,
  Flex,
  Text,
  CardHeader,
  CardBody,
  Card,
  Image,
  TextArea,
  Dialog,
  Video,
} from "@fluentui/react-northstar";

import { CloseIcon, BookmarkIcon } from "@fluentui/react-icons-northstar";
import styled from "styled-components";
import { withTranslation } from "react-i18next";

import PollFeedbackResponse from "./PollFeedbackResponse";

import userProfile from "../../../../Assets/images/userprofile.png";

import "./ViewResponse.scss";
import { Col, Row } from "antd";

const any = "ANY";
const text = "TEXT";
const audio = "AUDIO";
const video = "VIDEO";
const poll = "POLL";

const StudentResponseTypeTitle = styled(Text)`
  margin-bottom: 10px;
  color: #5a5b8c;
  font-size: 16px;
`;

const NoResponnseText = styled(Text)`
  margin: 150px 0;
  font-size: 21px;
  color: #d1d1d1;
  text-align: center;

  @media screen and (max-width: 1024px) {
    font-size: 16px;
    margin-top: 100px;
  }
  @media screen and (max-width: 640px) {
    margin: 70px 0;
  }
`;

const Response = ({
  bookmarkResponseHandler,
  allQuestionResponses,
  allQuestions,
  feedback,
  studentSearchText,
  setStudentSearchText,
  currentQuestion,
  isBookmarkedResponseSelected,
  setIsBookmarkedResponseSelected,
  isUpdating,
  isLoading,
  user,
  t,
}) => {
  const [allResponses, setAllResponses] = useState([]);
  const [filteredResponses, setFilteredResponses] = useState([]);
  const [isFeedbackDialogOpen, setIsFeedbackDialogOpen] = useState(false);
  const [selectedFeedback, setSelectedFeedback] = useState({});

  const translation = t("feedbackModule");

  const commonTranslation = translation.common;
  const feedbackTranslation = translation.body.viewResponse;

  useEffect(() => {
    if (allQuestionResponses?.length) {
      let responses = allQuestionResponses.reduce((acc, curr) => {
        const { FeedbackQueSubmissions } = curr;
        return FeedbackQueSubmissions?.length
          ? [
              ...acc,
              {
                ...curr,
                ...(FeedbackQueSubmissions.find(
                  ({ questionId }) =>
                    questionId === currentQuestion.feedbackQuestionId
                ) ?? {}),
              },
            ]
          : acc;
      }, []);
      setAllResponses(responses);
    } else {
      setAllResponses([]);
    }
  }, [allQuestionResponses, currentQuestion, allQuestions]);

  useEffect(() => {
    if (currentQuestion.questionType !== poll) {
      const searchtext = studentSearchText?.toLowerCase()?.replace(/\s/g, "");
      setFilteredResponses(() =>
        allResponses?.length
          ? allResponses.filter((item) => {
              if (studentSearchText && isBookmarkedResponseSelected) {
                return (
                  item.name
                    ?.toLowerCase()
                    .replace(/\s/g, "")
                    .includes(searchtext) && item.isBookmarked
                );
              } else if (studentSearchText) {
                return item.name
                  ?.toLowerCase()
                  .replace(/\s/g, "")
                  .includes(searchtext);
              } else if (isBookmarkedResponseSelected) {
                return item.isBookmarked;
              }
              return true;
            })
          : []
      );
    }
  }, [allResponses, studentSearchText, isBookmarkedResponseSelected]);

  return (
    <div className="question-wrapper">
      <Dialog
        headerAction={{
          icon: <CloseIcon />,
          title: commonTranslation.close,
          onClick: () => {
            setIsFeedbackDialogOpen(false);
          },
        }}
        header={
          <div>
            <Text weight="regular">{feedbackTranslation.feedbackFrom}</Text>
            <Text weight="semibold"> {selectedFeedback.name}</Text>
          </div>
        }
        // confirmButton={commonTranslation.close}
        content={{
          content: (
            <div>
              <Flex column gap="gap.large">
                {currentQuestion.questionType === text ||
                (currentQuestion.questionType === any &&
                  selectedFeedback.textResponse?.length) ? (
                  <Flex column>
                    {currentQuestion.questionType === any && (
                      <Flex hAlign="center">
                        <StudentResponseTypeTitle weight={"semibold"}>
                          {feedbackTranslation.textResponse}
                        </StudentResponseTypeTitle>
                      </Flex>
                    )}
                    <Flex>
                      <TextArea
                        readOnly
                        fluid
                        value={selectedFeedback.textResponse}
                        rows={10}
                      />
                    </Flex>
                  </Flex>
                ) : null}
                {currentQuestion.questionType === video ||
                (currentQuestion.questionType === any &&
                  selectedFeedback.videoResponse?.length) ? (
                  <Flex column>
                    <Flex hAlign="center">
                      <StudentResponseTypeTitle weight={"semibold"}>
                        {feedbackTranslation.videoResponse}
                      </StudentResponseTypeTitle>
                    </Flex>
                    <Flex hAlign="center">
                      <Video
                        src={selectedFeedback.videoResponse}
                        style={{
                          width: "100%",
                          maxHeight: "350px",
                          maxWidth: "467px",
                          borderRadius: "15px",
                        }}
                      />
                    </Flex>
                  </Flex>
                ) : null}
                {currentQuestion.questionType === audio ||
                (currentQuestion.questionType === any &&
                  selectedFeedback.audioResponse?.length) ? (
                  <Flex column hAlign="center">
                    <Flex>
                      <StudentResponseTypeTitle weight={"semibold"}>
                        {feedbackTranslation.audioResponse}
                      </StudentResponseTypeTitle>
                    </Flex>
                    <Flex
                      style={{
                        maxWidth: "350px",
                        width: "100%",
                        marginBottom: "25px",
                      }}
                    >
                      <audio
                        controls
                        src={selectedFeedback.audioResponse}
                        style={{ width: "100%" }}
                      />
                    </Flex>
                  </Flex>
                ) : null}
              </Flex>
            </div>
          ),
          styles: {
            height: "100%",
            maxHeight: "390px",
            overflow: "auto",
          },
        }}
        open={isFeedbackDialogOpen}
      />

      {allResponses?.length ? (
        currentQuestion.questionType === poll ? (
          <PollFeedbackResponse
            feedback={feedback}
            allResponses={allResponses}
            currentQuestion={currentQuestion}
            feedbackTranslation={feedbackTranslation}
            user={user}
          />
        ) : (
          <div className="student-response">
            <div
              className="student-response-data"
              style={{ marginTop: "15px" }}
            >
              {filteredResponses?.length ? (
                <Row
                  gutter={[10, 10]}
                  style={{ margin: 0, minHeight: "200px" }}
                >
                  {filteredResponses.map((item, index) => {
                    return (
                      <Col
                        xs={24}
                        sm={12}
                        md={12}
                        lg={12}
                        xl={6}
                        xxl={6}
                        key={index}
                      >
                        {currentQuestion.questionType === audio ? (
                          <Card
                            elevated
                            key={index}
                            className="audio-feedback-card"
                            fluid
                            styles={{
                              borderRadius: "10px",
                              ":hover": { backgroundColor: "white" },
                              backgroundColor: "white",
                              height: "120px",
                              padding: "0px",
                            }}
                          >
                            <CardHeader
                              style={{
                                borderRadius: "10px 10px 0 0",
                                backgroundColor: "#585A96",
                              }}
                            >
                              <audio
                                controls
                                src={item.audioResponse}
                                style={{ width: "100%" }}
                              />
                            </CardHeader>
                            <CardBody
                              style={{
                                marginBottom: "0px",
                                padding: "5px 10px",
                                height: "100%",
                              }}
                            >
                              <Flex space="between">
                                <Flex>
                                  <Flex
                                    vAlign="center"
                                    style={{
                                      padding: "3px 10px 3px 5px",
                                      borderRadius: "20px",
                                    }}
                                    gap="gap.small"
                                  >
                                    <Flex
                                      style={{
                                        width: "28px",
                                        height: "28px",
                                      }}
                                    >
                                      <Image
                                        src={
                                          item.imageUrl
                                            ? item.imageUrl + user.SASToken
                                            : userProfile
                                        }
                                        onError={(e) => {
                                          e.target.src = userProfile;
                                          e.target.style.backgroundColor =
                                            "white";
                                        }}
                                        style={{
                                          objectFit: "contain",
                                          width: "100%",
                                          height: "100%",
                                          marginRight: "8px",
                                          borderRadius: "50%",
                                          ...(!item.imageUrl
                                            ? {
                                                backgroundColor: "white",
                                              }
                                            : {}),
                                        }}
                                      />
                                    </Flex>
                                    <Text size="medium" weight="bold">
                                      {item.name ??
                                        item.studentEmailId?.split("@")?.[0]}
                                    </Text>
                                  </Flex>
                                </Flex>
                                <Flex>
                                  <Button
                                    circular
                                    size="small"
                                    disabled={isUpdating.bookmark}
                                    icon={
                                      <BookmarkIcon
                                        style={{
                                          color: item.isBookmarked
                                            ? "#FAB500"
                                            : "black",
                                        }}
                                      />
                                    }
                                    title={feedbackTranslation.bookmark}
                                    color="white"
                                    style={{
                                      borderRadius: "50%",
                                    }}
                                    onClick={(e) => {
                                      e.stopPropagation();
                                      e.preventDefault();

                                      bookmarkResponseHandler(item);
                                    }}
                                  />
                                </Flex>
                              </Flex>
                            </CardBody>
                          </Card>
                        ) : (
                          <Card
                            elevated
                            key={index}
                            // className="fit-content"
                            fluid
                            styles={{
                              borderRadius: "10px",
                              ":hover": { backgroundColor: "white" },
                              backgroundColor: "white",
                              height: "183px",
                              padding: "0px",
                            }}
                            onClick={() => {
                              setIsFeedbackDialogOpen(true);
                              setSelectedFeedback(item);
                            }}
                          >
                            <CardBody
                              style={{
                                marginBottom: "0px",
                                position: "relative",
                                height: "100%",
                              }}
                            >
                              <Image
                                src={
                                  item.imageUrl
                                    ? item.imageUrl + user.SASToken
                                    : userProfile
                                }
                                onError={(e) => {
                                  e.target.src = userProfile;
                                  e.target.style.maxWidth = "175px";
                                  e.target.style.margin = "auto";
                                }}
                                style={{
                                  objectFit: "contain",
                                  width: "100%",
                                  height: "100%",
                                  marginRight: "8px",
                                  borderRadius: "10px",
                                  ...(!item.imageUrl
                                    ? {
                                        maxWidth: "175px",
                                        margin: "auto",
                                      }
                                    : {}),
                                }}
                              />
                              <Flex
                                style={{
                                  position: "absolute",
                                  top: "5px",
                                  right: "10px",
                                }}
                              >
                                <Button
                                  circular
                                  size="small"
                                  disabled={isUpdating.bookmark}
                                  icon={
                                    <BookmarkIcon
                                      size="small"
                                      outline={!item.isBookmarked}
                                      style={{
                                        color: item.isBookmarked
                                          ? "#FAB500"
                                          : "",
                                      }}
                                    />
                                  }
                                  title={feedbackTranslation.bookmark}
                                  color="white"
                                  style={{
                                    borderRadius: "50%",
                                  }}
                                  onClick={(e) => {
                                    e.stopPropagation();
                                    e.preventDefault();

                                    bookmarkResponseHandler(item);
                                  }}
                                />
                              </Flex>
                              <Flex
                                style={{
                                  position: "absolute",
                                  bottom: "5px",
                                  left: "10px",
                                }}
                              >
                                <Flex
                                  vAlign="center"
                                  style={{
                                    backgroundColor: "#494B83",
                                    padding: "3px 10px 3px 5px",
                                    borderRadius: "20px",
                                  }}
                                  gap="gap.small"
                                >
                                  <Flex
                                    style={{
                                      width: "28px",
                                      height: "28px",
                                    }}
                                  >
                                    <Image
                                      src={
                                        item.imageUrl
                                          ? item.imageUrl + user.SASToken
                                          : userProfile
                                      }
                                      onError={(e) => {
                                        e.target.src = userProfile;
                                        e.target.style.backgroundColor =
                                          "white";
                                      }}
                                      style={{
                                        width: "100%",
                                        height: "100%",
                                        marginRight: "8px",
                                        borderRadius: "50%",
                                        ...(!item.imageUrl
                                          ? {
                                              backgroundColor: "white",
                                            }
                                          : {}),
                                      }}
                                    />
                                  </Flex>
                                  <Text style={{ color: "white" }}>
                                    {item.name ??
                                      item.studentEmailId?.split("@")?.[0]}
                                  </Text>
                                </Flex>
                              </Flex>
                            </CardBody>
                          </Card>
                        )}
                      </Col>
                    );
                  })}
                </Row>
              ) : !isLoading ? (
                <Flex column hAlign="center" vAlign="center" fill>
                  <NoResponnseText weight="bold">
                    {feedbackTranslation.noStudentResponseForThisFilter}
                  </NoResponnseText>
                </Flex>
              ) : null}
            </div>
          </div>
        )
      ) : (
        <Flex column hAlign="center" vAlign="center" fill>
          <NoResponnseText weight="bold">
            {feedback?.totalSubmissions > 0
              ? feedbackTranslation.responseWillAppearHere
              : feedbackTranslation.noStudentAssigned}
          </NoResponnseText>
        </Flex>
      )}
    </div>
  );
};

export default withTranslation()(Response);
