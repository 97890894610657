import React, { useState, useEffect } from "react";
import {
  Flex,
  CardHeader,
  CardBody,
  Card,
  Text,
  Grid,
  Loader,
  SearchIcon,
  Input,
  Dropdown,
  Divider,
} from "@fluentui/react-northstar";
import axios from "axios";

import { Avatar, Tooltip } from "antd";
import { useHistory } from "react-router-dom";
import styled from "styled-components";
import { withTranslation } from "react-i18next";

import { ResponsiveImage } from "../../../../components/blocks/Images";

import Imgart from "../../../../Assets/images/subjects/art.png";
import Imgcivil from "../../../../Assets/images/subjects/civil.png";
import Imghistory from "../../../../Assets/images/subjects/history.png";
import ImgnaturalScience from "../../../../Assets/images/subjects/natural-science.png";
import Imgphilosophy from "../../../../Assets/images/subjects/philosophy.png";
import ImgscienceExact from "../../../../Assets/images/subjects/science-exact.png";
import Imgtech from "../../../../Assets/images/subjects/tech.png";
import Imgother from "../../../../Assets/images/subjects/other.png";

import NoClassRooms from "../../../../Assets/images/svg/school.svg";

import "./Classrooms.scss";
import { useLocation } from "react-router-dom/cjs/react-router-dom";
import ReactPaginate from "react-paginate";
import { useFeedBack } from "../../../../context/FeedbackContext";

const url = process.env.REACT_APP_EP_URL;

const ClassRoomSection = styled(Grid)`
  grid-template-columns: repeat(auto-fill, minmax(340px, 1fr));

  @media (min-width: 375px) and (max-width: 936px) {
    grid-template-columns: repeat(auto-fill, minmax(280px, 1fr));
  }
  @media screen and (max-width: 375px) {
    grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
  }
`;

const ClassRoomCard = styled(Flex)`
  margin: 10px;
`;

const TitleStudentSection = styled(Flex)`
  .title-wrapper {
    max-width: calc(100% - 110px);
    .title-section {
      width: 100%;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      .title {
        font-size: 16px;
        display: unset;
      }
    }
  }
`;

const inputItems = [20, 50, 100];

function debounce(cb, delay) {
  let timeoutId;
  return function (...args) {
    if (timeoutId) {
      clearTimeout(timeoutId);
    }

    timeoutId = setTimeout(() => {
      cb(...args);
    }, delay);
  };
}

const Classrooms = (props) => {
  const {
    classrooms,
    pagination,
    search,
    setSearch,
    isLoading,
    getClassrooms,
    setPagination,
  } = useFeedBack();
  // Deferred value
  const { user, t, globalSearch } = props;

  const translation = t("feedbackModule");

  const feedbackTranslation = translation.body.classroom;

  const history = useHistory();
  const location = useLocation();
  const { state } = location; // access the state here

  const handlePageClick = (data) => {
    setPagination({ ...pagination, currentPage: data.selected + 1 });
    getClassrooms(pagination.limit, data.selected + 1, "");
  };

  useEffect(() => {
    if (state?.from !== "ClassRoom" || !classrooms.length) {
      getClassrooms(pagination.limit, pagination.currentPage, "");
    }
  }, []);

  const debouncedSearch = debounce(() => {
    getClassrooms(pagination.limit, pagination.currentPage, search);
  }, 1000);

  const debouncedGlobalSearch = debounce(() => {
    getClassrooms(pagination.limit, pagination.currentPage, globalSearch);
  }, 1000);

  useEffect(() => {
    globalSearch && debouncedGlobalSearch();
  }, [globalSearch]);

  const getDefaultClassroomImage = (title) => {
    if (title) {
      return title?.includes("Art")
        ? Imgart
        : title.includes("Civismo")
        ? Imgcivil
        : title.includes("Hist")
        ? Imghistory
        : title.includes("Science")
        ? ImgnaturalScience
        : title.includes("losof")
        ? Imgphilosophy
        : title.includes("Ciencias Exactas")
        ? ImgscienceExact
        : title.includes("Tec")
        ? Imgtech
        : Imgother;
    } else {
      return Imgother;
    }
  };

  const myCard = ({ classroom }) => {
    return (
      <Card
        elevated
        className="fit-content"
        fluid
        styles={{
          borderRadius: "10px",
          ":hover": { backgroundColor: "white" },
          backgroundColor: "white",
        }}
        onClick={() => {
          history.push(`/feedback/classrooms/${classroom.classroomId}`, {
            classroom,
          });
        }}
      >
        <CardHeader>
          <Flex gap="gap.small" column>
            <img
              alt={feedbackTranslation.imgAlt}
              src={
                classroom.classroomsImageUrl
                  ? classroom.classroomsImageUrl + user.SASToken
                  : getDefaultClassroomImage(classroom.classroomTitle)
              }
              onError={(e) => {
                e.target.src = Imgother;
              }}
              style={{
                height: "160px",
                borderRadius: "10px 10px 0 0",
                objectFit: "cover",
              }}
              draggable="false"
              className="img-width"
            />
          </Flex>
        </CardHeader>
        <CardBody>
          <TitleStudentSection
            space="between"
            vAlign="center"
            style={{ padding: "0 10px", minHeight: "32px" }}
          >
            <Flex className="title-wrapper">
              <div className="title-section">
                <Tooltip placement="bottom" title={classroom.classroomTitle}>
                  <Text weight="semibold" align="start" className="title">
                    {classroom.classroomTitle}
                  </Text>
                </Tooltip>
              </div>
            </Flex>
            <Flex>
              {classroom.students?.length ? (
                <Avatar.Group
                  maxPopoverTrigger="hover"
                  maxCount={2}
                  size="default"
                  maxStyle={{
                    color: "#f56a00",
                    backgroundColor: "#fde3cf",
                    cursor: "pointer",
                  }}
                >
                  {classroom.students.map((student, index) => (
                    <Avatar key={index} src={student.studentImageUrl}>
                      <Tooltip title={student.name}>
                        <Text>
                          {student.name?.length
                            ? student.name[0]
                            : student.studentEmailId?.[0]}
                        </Text>
                      </Tooltip>
                    </Avatar>
                  ))}
                </Avatar.Group>
              ) : (
                <div />
              )}
            </Flex>
          </TitleStudentSection>
        </CardBody>
      </Card>
    );
  };

  return (
    <div className="classrooms-container">
      <div className="classrooms">
        <div className="all-classrooms">
          <Text
            size="larger"
            weight="semibold"
            style={{ marginLeft: "10px", color: "#6264a7" }}
          >
            {feedbackTranslation.header}
          </Text>

          <Flex space="around" vAlign="center" hAlign="center" wrap>
            <Input
              icon={<SearchIcon />}
              clearable
              placeholder={feedbackTranslation.search}
              onChange={(e, { value }) => {
                setSearch(value);
                debouncedSearch();
              }}
            />
            <ReactPaginate
              previousLabel={t("setting").init[5]}
              nextLabel={t("setting").init[4]}
              breakLabel={"..."}
              breakClassName={"break-me"}
              pageCount={pagination.totalPages}
              marginPagesDisplayed={2}
              pageRangeDisplayed={5}
              onPageChange={handlePageClick}
              containerClassName={"pagination"}
              activeClassName={"active"}
              forcePage={pagination.currentPage ? pagination.currentPage - 1 : 0}
            />
            <Dropdown
              items={inputItems}
              placeholder={t("setting").init[6]}
              checkable
              value={pagination.limit}
              onChange={(e, { value }) => {
                setPagination({ ...pagination, limit: value });
                getClassrooms(value, 1, search);
              }}
              className="SmallSelector"
            />
          </Flex>
          <Divider />

          {isLoading ? (
            <Loader style={{ paddingTop: "50px" }} />
          ) : classrooms.length ? (
            <div className="classrooms-cards-wrapper">
              <ClassRoomSection>
                {classrooms.map((classroom, i) => {
                  return (
                    <ClassRoomCard key={`${classroom.classroomId}-${i + 1}`}>
                      {myCard({ classroom })}
                    </ClassRoomCard>
                  );
                })}
              </ClassRoomSection>
            </div>
          ) : (
            <Flex hAlign="center" column fill style={{ marginTop: "40px" }}>
              <ResponsiveImage src={NoClassRooms} style={{ width: "450px" }} />
              <Text weight="semibold" size="large">
                {classrooms.length
                  ? feedbackTranslation.noClassroomForFilter
                  : feedbackTranslation.noClassroom}
              </Text>
            </Flex>
          )}
        </div>
      </div>
    </div>
  );
};

export default withTranslation()(Classrooms);
