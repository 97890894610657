import React, { useEffect, useState } from "react";
import {
  Button,
  Text,
  Flex,
  ArrowLeftIcon,
  CardHeader,
  CardFooter,
  CardBody,
  Card,
  CardColumn,
  Dialog,
  CloseIcon,
  FlexItem,
  TextArea,
  InfoIcon,
} from "@fluentui/react-northstar";
import { useHistory, useParams } from "react-router-dom";
import parse from "html-react-parser";
import moment from "moment";
import { withTranslation } from "react-i18next";

const AssignmentDetail = ({ currentAssignmentData, rubricData, t }) => {
  const { assignmentId, subjectCode } = useParams();
  const history = useHistory();

  const translation = t("assignment").body;
  const submissionPageTranslation = translation.submissionPage;

  const [reasonForResubmitDialogOpen, setReasonForResubmitDialogOpen] =
    useState(false);

  const getGradedOrDueDate = () => {
    let student = currentAssignmentData?.AssingmentStudents?.[0];
    return currentAssignmentData.submissionStatus === "Completed"
      ? student?.gradedOn && moment(student?.gradedOn).isValid()
        ? student.gradedOn
        : null
      : currentAssignmentData?.dueDate &&
        moment(currentAssignmentData?.dueDate).isValid()
      ? currentAssignmentData.dueDate
      : null;

    // let date = null;

    // switch (currentAssignmentData.submissionStatus) {
    //   case "Completed":
    //     date = student?.gradedOn;
    //   case "Grades Pending":
    //     date = student?.dueDate;

    // }
  };

  const getSubmissionStatus = (submissionStatus) => {
    const statusTranslation = translation.studentSubmissionStatus;
    switch (submissionStatus) {
      case "Pending":
        return statusTranslation.pending;
      case "Grades Pending":
        return statusTranslation.gradesPending;
      case "Resubmit":
        return statusTranslation.resubmit;
      case "Completed":
        return statusTranslation.completed;
      default:
        return submissionStatus;
    }
  };

  return (
    <>
      <Card elevated fluid className="card-height">
        <Dialog
          headerAction={{
            icon: <CloseIcon />,
            title: translation.close,
            onClick: () => {
              setReasonForResubmitDialogOpen(false);
            },
          }}
          header={
            <Text weight={"regular"}>
              {submissionPageTranslation.reasonForResubmit}
            </Text>
          }
          content={
            <Flex column gap="gap.medium">
              <FlexItem>
                <TextArea
                  readOnly
                  style={{ color: "black" }}
                  fluid
                  value={currentAssignmentData.AssingmentStudents[0]?.reason}
                  rows={5}
                />
              </FlexItem>
            </Flex>
          }
          open={reasonForResubmitDialogOpen}
        />
        <CardHeader>
          {/* currentAssignmentData */}
          <Flex
            space="between"
            vAlign="center"
            className="assignment-detail-card-header"
          >
            <Flex>
              <Text className="text-blue" weight="semibold">
                {currentAssignmentData.submissionStatus === "Completed"
                  ? `${submissionPageTranslation.gradedOn}:`
                  : `${submissionPageTranslation.due}:`}
              </Text>
              <Text>
                {moment(getGradedOrDueDate()).isValid()
                  ? moment
                      .utc(getGradedOrDueDate())
                      .local()
                      .format("MMM DD,YYYY | HH:mm")
                  : translation.NA}
              </Text>
            </Flex>
            <Flex>
              <Text className="text-blue" weight="semibold">
                {submissionPageTranslation.grades}:
              </Text>
              <Text>
                {currentAssignmentData.AssingmentStudents?.[0].givenGrade ? (
                  <Text style={{ marginRight: "3px" }}>
                    {`${currentAssignmentData.AssingmentStudents?.[0].givenGrade} /`}
                  </Text>
                ) : (
                  ""
                )}
                {currentAssignmentData.markingType}
              </Text>
            </Flex>
            <Flex vAlign="center">
              <Text
                weight="semibold"
                className={
                  currentAssignmentData.submissionStatus == "Pending"
                    ? "submission-pending"
                    : currentAssignmentData.submissionStatus == "Grades Pending"
                    ? "grades-pending"
                    : currentAssignmentData.submissionStatus == "Resubmit"
                    ? "resubmit"
                    : "completed"
                }
              >
                {currentAssignmentData.submissionStatus === "Pending"
                  ? translation.assignmentList.submissionPending
                  : getSubmissionStatus(currentAssignmentData.submissionStatus)}
              </Text>
              {currentAssignmentData.submissionStatus === "Resubmit" ? (
                <Button
                  icon={<InfoIcon />}
                  text
                  iconOnly
                  title={submissionPageTranslation.resubmitReason}
                  onClick={() => setReasonForResubmitDialogOpen(true)}
                />
              ) : null}
            </Flex>
            {rubricData?.length ? (
              <Flex.Item>
                <Button
                  style={{ maxWidth: "120px" }}
                  content={translation.assignmentDetails.viewRubric}
                  onClick={() => {
                    // history.push({
                    //   pathname: `/${subjectCode}/assignments/${assignmentId}/rubric`,
                    // });
                    history.push({
                      pathname: `/${subjectCode}/assignments/${assignmentId}/rubric`,
                    });
                  }}
                />
              </Flex.Item>
            ) : null}
          </Flex>
        </CardHeader>
      </Card>
      <Card
        elevated
        fluid
        className="cardbody-height"
        styles={{
          ":hover": { backgroundColor: "white" },
          backgroundColor: "white",
        }}
      >
        <CardBody>
          <Text weight="semibold" size="large" className="text-blue pb-3">
            {currentAssignmentData.assignmentTitle || ""}
          </Text>
          <Text
            className="instruction-wrapper"
            style={{ marginTop: "10px", minHeight: "100px" }}
          >
            {currentAssignmentData.instruction?.length
              ? parse(currentAssignmentData.instruction)
              : ""}
          </Text>
        </CardBody>
      </Card>
    </>
  );
};

export default withTranslation()(AssignmentDetail);
